import axios from 'axios';
import { message } from 'antd';

// export const baseUrl = "http://10.10.1.45:8082/api/v1";
export const baseUrl = process.env.REACT_APP_API_BASE_URL;
// export const baseUrl = process.env.REACT_APP_STAGING_API_BASE_URL;
export const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return baseUrl;
    default:
      return process.env.REACT_APP_STAGING_API_BASE_URL;
  }
};



const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  validateStatus: function (status) {
    return status >= 200 && status < 500; // default
  },
});

export const axiosInstanceOCR = axios.create({
  baseURL: '',
});

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled
    ? false
    : true;
};

//request handler
const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    // inject access token here
    const token = localStorage.getItem('token');
    // const token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbkBtZWRpdGFnZy5jb20iLCJpYXQiOjE3MDExNDkzMDAsImV4cCI6MTcwMTIzNTcwMH0.yvwAm0NjVtYOIu86C0kq9wZ7DTPXVmdY1lDwyjeYvPdWj3l5wILRhMPVGDlM4F6XjJK0flDBqaSELJr3Lg4kHw";
    // 
    if (token)
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      };
  }
  return request;
};

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // Handle errors
  }
  console.log('axios error', error);
  if (!error.response) {
    message.destroy();
    message.error('Unable to connect server');
  } else if (error.response.status == 500) {
    message.destroy();
    message.error('Something went wrong at server side!');
  } else if (error.response.status == 403) {
    message.destroy();
    message.error('Permisson denied');
  } else if (error.response.status == 401) {
    window.location.href = '/login';
    localStorage.clear();
    message.destroy();
    message.error('Token Expired');
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
  }
  return response;
};

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
axiosInstance.interceptors.request.use((request) => requestHandler(request));
export default axiosInstance;
