import styled from "styled-components";
import { Row, Button } from "antd";

export const ModalBodyContainer = styled.div`
  width: 500px;
`;
export const SectionHeading = styled.h1`
  font-size: 30px;
  text-align: center;
  font-weight: 600;
`;
export const Logo = styled.img`
  width: 60%;
  cursor: pointer;
  margin: auto;
`;
export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const SectionMessageContainer = styled.div`
  text-align: center;
  padding: 25px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 17px;
`;
export const SectionFooterContainer = styled.div`
  text-align: center;
  padding: 25px;

  font-size: 14px;
`;
export const SectionButtonContainer = styled.div`
  text-align: center;
`;
export const StyledButton = styled(Button)`
  && {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    width: 230px;
    border-radius: 8px;
  }
`;
export const StyledRow = styled(Row)`
  margin-top: 24px;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  color: ${(props) => props.color};
  text-align: center;
`;

export const UploadTitle = styled.h3`
  font-size: 16px;
  color: ${(props) => props.color};
  text-align: left;
`;

export const DocumentDetailsDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
