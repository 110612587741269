export const religions = () => [
  { code: "MU", text: "Muslim" },
  { code: "CHR", text: "Christianity " },
  { code: "AOG", text: "Christian: Assembly of God" },
  { code: "BAP", text: "Christian: Baptist" },
  { code: "BUD", text: "Buddhist" },
  { code: "CAT", text: "Christian: Roman Catholic" },
  { code: "COC", text: "Christian: Church of Christ" },
  { code: "COG", text: "Christian: Church of God" },
  { code: "GRE", text: "Christian: Greek Orthodox" },
  { code: "HIN", text: "Hindu" },
  { code: "JH", text: "Jewish" },
  { code: "JWN", text: "Christian: Jehovah's Witness" },
  { code: "LUT", text: "Christian: Lutheran" },
  { code: "MET", text: "Christian: Methodist" },
  { code: "MOM", text: "Christian: Latter-day Saints" },
  { code: "NAZ", text: "Christian: Church of the Nazarene" },
  { code: "OTH", text: "Other" },
  { code: "PEN", text: "Christian: Pentecostal" },
  { code: "PRE", text: "Christian: Presbyterian" },
  { code: "SEV", text: "Christian: Seventh Day Adventist" },
  { code: "VAR", text: "Unknown" },
  { code: "EPI", text: "Christian: Episcopalian" },
];
