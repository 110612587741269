import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button } from "antd";
import styled from "styled-components";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";

const ModalBodyContainer = styled.div`
  width: 500px;
`;
const ConfirmationModal = ({
  visible,
  setVistible,
  onConfirm,
  loading,
  status,
}) => {
  const messages = {
    WAITING: "Confirm Submit Claim ?",
    DECLINE: "Confirm Decline Claim ?",
    ACCEPT: "Confirm Accept Claim?",
    SAVE: "Please confirm the approved orders are correct",
    SUBMIT: "Please confirm the approved orders are correct",
  };

  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <CustomAlerts
              type="success"
              title={messages[status]}
              // description="By clicking confirm, The insurance claim will be submitted."
            />
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVistible(false);
              }}
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                onConfirm(status);
                setVistible(false);
              }}
              loading={loading}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default ConfirmationModal;
