import React from "react";
import { useSelector } from "react-redux";
const HasPrivilege = ({ children, privilege, role, restricted }) => {
  const user = useSelector((state) => state.userReducer);
  const checkIfValidRole = (userType, role) => {
    if (Array.isArray(role)) return role.includes(userType);
    else return role == userType;
  };
  if (!restricted) {
    if (
      (user.roles && user.roles.includes(privilege)) ||
      checkIfValidRole(user.userType, role)
    )
      return children;
    else return null;
  } else {
    if (
      (user.roles && user.roles.includes(privilege)) ||
      checkIfValidRole(user.userType, role)
    )
      return null;
    else return children;
  }
};
export default HasPrivilege;
