import React from "react";
import { TableCotainer, StyledTable } from "./styledComponents";
import { columns } from "./tableConfig";
import { Button } from "antd";
import { useSelector } from "react-redux";
const itemRender = (current, type, originalElement) => {
  if (type === "prev") {
    return <Button>Previous</Button>;
  }
  if (type === "next") {
    return <Button>Next</Button>;
  }
  return originalElement;
};
const UserTable = ({
  dataSource,
  setPageNo,
  pageSize,
  pageNo,
  total,
  loading,
  setEditModal,
  setSelectedItem,
  setCreateModal,
  setDeleteModal,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  return (
    <div>
      <StyledTable
        headColor={theme["@primary-color"]}
        loading={loading}
        scroll={{ x: "max-content" }}
        pagination={{
          onChange: (cur) => {
            setPageNo(cur - 1);
          },
          pageSize: pageSize,
          total: total,
          current: pageNo + 1,
          itemRender: itemRender,
        }}
        dataSource={dataSource}
        columns={columns(
          setEditModal,
          setSelectedItem,
          setCreateModal,
          setDeleteModal,
          pageNo,
          pageSize
        )}
      />
    </div>
  );
};

export default UserTable;
