import React from "react";
import { Col, Row, Form, Input, Select, Divider, Button } from "antd";
import {
  RightButton,
  StyledDatePicker,
  StyledH3,
  StyledInputNumber,
} from "./styledComponents";
import * as Sentry from "@sentry/react";
import { getCountries } from "../../../apis/publicApis";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
import moment from "moment";
import FormBuilder from "../../utils/FormBuilder";
import {
  addresses,
  nextOfKinType,
  phoneNumberType,
} from "../PatientForm/formConfig";
import {
  emailType,
  genderList,
  maritalStatus,
  raceList,
} from "../../Datasets/basicDetails";
import { BodyContainer } from "../PatientForm/styledComponents";
import { StyledContent } from "../AdminDashboard/UserManagement/styledComponents";
import { nationality } from "../../Datasets/nationality";
import { citizenship } from "../../Datasets/citizenship";
import { primaryLanguages } from "../../Datasets/primaryLanguage";
import { religions } from "../../Datasets/Religion";
import { fetchFormFieldMeta } from "../../../apis/forms";
const Option = Select.Option;

function PatientProfileForm({
  formInitialValues,
  onSubmit,
  disabled,
  submitLoading,
  logOut,
  selectedItem,
  setSelectedItem,
}) {
  const [countries, setCountries] = useState([]);
  const [bloodGrp, setBloodGrp] = useState([]);
  const theme = useSelector((state) => state.themeReducer);
  const [forms, setForm] = useState();
  const [form] = Form.useForm();
  const capitalize = (camelCaseText) => {
    return (
      camelCaseText.charAt(0).toUpperCase() +
      camelCaseText
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(" ")
    );
  };

  useEffect(() => {
    getCountries().then((resp) => {
      setCountries(
        resp.data.map((country) => ({
          code: country.callingCodes[0],
          name: country.name,
        }))
      );
    });
    fetchFormFieldMeta("BLOOD_GROUP")
      .then((res) => {
        if (res.data.status === 1) {
          const bloodGrpData = res.data.body.map((eachItem) => ({
            key: eachItem.id,
            value: eachItem.value,
          }));
          setBloodGrp(bloodGrpData);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  }, []);

  useEffect(() => {
    var body = selectedItem;
    console.log("selectedItem**", selectedItem, moment(body?.dob));
    selectedItem !== undefined
      ? form.setFieldsValue({
          firstName: body?.firstName,
          lastName: body?.lastName,
          gender: body?.gender,
          dob: moment(body?.dob),
          age: moment().diff(body?.dob, "years", false),
          bloodGroup: body?.bloodGroup,
          maritalStatus: body?.additionalInfo.maritalStatus,
          race: body.additionalInfo.race,
          religion: body.additionalInfo.religion,
          nationality: body.additionalInfo.nationality,
          citizenship: body.additionalInfo.citizenship,
          primaryLanguage: body.additionalInfo.primaryLanguage,
          emailType: body.additionalInfo.emailType,
          mobileNo: body.mobileNo,
          email: body.email,
          phoneNumbers: body.phoneNumbers.map((number) => {
            return {
              phoneType: number.phoneType,
              phoneNumber: {
                countryCode: number.countryCode,
                phoneNumber: number.phoneNumber,
              },
            };
          }),
          alternateContactDetails: body.additionalInfo.alternateContactDetails,
          addresses: body.addresses,
          deathStatus: body.additionalInfo.deathStatus === true ? "Yes" : "No",
          deathDate: body.additionalInfo.deathDate
            ? moment(body.additionalInfo.deathDate)
            : undefined,
          identityDocuments: body.identityDocuments.map((doc) => {
            return {
              documentType: doc.documentType,
              number: doc.number,
              expiryDate: moment(doc.expiryDate),
              documentUrl: doc.documentUrl,
            };
          }),
        })
      : form.setFieldsValue({
          identityDocuments: [{}],
          addresses: [{}],
          phoneNumbers: [{}],
          alternateContactDetails: [{}],
        });
  }, []);

  const onFormValueChange = (changed, all) => {
    if (changed.dob) {
      form.setFieldsValue({ age: moment().diff(changed.dob, "years", false) });
    }
    if (!all.dob) {
      form.setFieldsValue({ age: undefined });
    }
  };
  const disabledDate = (current, past) => {
    // Can not select days before today and today

    if (past) {
      return current && current < moment().endOf("day");
    } else {
      return current && current > moment().endOf("day");
    }
  };

  return (
    <BodyContainer>
      <StyledContent>
        <Form
          className="form-m2-style"
          layout="vertical"
          form={form}
          onValuesChange={onFormValueChange}
          onFinish={(values) => onSubmit(values)}
          initialValues={formInitialValues}
          key={Object.keys(formInitialValues).length !== 0}
          scrollToFirstError={true}
        >
          <Row gutter={[36, 0]}>
            <Col span="12">
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: new RegExp(
                      /^(([A-Za-z])*([\.,] |[-'.,]| )*)+[A-Za-z]+\.?\s*$/
                    ),
                    message: "Please enter alphabets only.",
                  },
                ]}
              >
                <Input size="large" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: new RegExp(
                      /^(([A-Za-z])*([\.,] |[-'.,]| )*)+[A-Za-z]+\.?\s*$/
                    ),
                    message: "Please enter alphabets only.",
                  },
                ]}
              >
                <Input size="large" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="gender"
                label="Gender"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large" disabled={disabled}>
                  {genderList().map((gender) => (
                    <Option value={gender.code}>{gender.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="dob"
                label="Date of Birth"
                rules={[
                  {
                    required: true,
                    message: "Date of Birth is required",
                  },
                ]}
              >
                <StyledDatePicker
                  size="large"
                  disabled={disabled}
                  disabledDate={(current) => disabledDate(current, false)}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="age"
                label="Age"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/[0-9]/),
                    message: "Please Enter digits only",
                  },
                ]}
              >
                <StyledInputNumber disabled={true} size="large" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="bloodGroup"
                label="Blood Group"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large" disabled={disabled}>
                  {bloodGrp.map((item) => (
                    <Option value={item.key}>{item.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="maritalStatus"
                label="MaritalStatus"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large" disabled={disabled}>
                  {maritalStatus().map((item) => (
                    <Option value={item.code}>{item.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="race"
                label="Race"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large" disabled={disabled}>
                  {raceList().map((item) => (
                    <Option value={item.code}>{item.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="religion"
                label="Religion"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large" disabled={disabled}>
                  {religions().map((item) => (
                    <Option value={item.code}>{item.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="nationality"
                label="Nationality"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large" disabled={disabled}>
                  {nationality().map((item) => (
                    <Option value={item.code}>{item.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="citizenship"
                label="Citizenship"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large" disabled={disabled}>
                  {citizenship().map((item) => (
                    <Option value={item.code}>{item.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="primaryLanguage"
                label="Primary Language"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large" disabled={disabled}>
                  {primaryLanguages.map((item) => (
                    <Option value={item.code}>{item.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="emailType"
                label="Email Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select size="large" disabled={disabled}>
                  {emailType().map((item) => (
                    <Option value={item.code}>{item.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="email"
                label="Email"
                type="email"
                rules={[
                  {
                    required: true,
                    message: "Enter a valid email",
                  },
                ]}
              >
                <Input size="large" disabled={disabled} />
              </Form.Item>
            </Col>{" "}
            <Col span={24}>
              {" "}
              <Form.List name="phoneNumbers">
                {(fields, { add, remove }) => (
                  <div>
                    <Form.Item>
                      {!disabled && (
                        <RightButton
                          onClick={() => {
                            add();
                          }}
                          type="primary"
                          ghost
                          size="small"
                        >
                          Add New Phone Number
                        </RightButton>
                      )}
                    </Form.Item>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <FormBuilder
                          formConfig={
                            disabled
                              ? phoneNumberType.map((item) => ({
                                  ...item,
                                  disabled: true,
                                }))
                              : phoneNumberType
                          }
                          index={index}
                          disabled={disabled}
                          // formInstance={form}
                        />
                        {fields.length > 1 && (
                          <Form.Item k>
                            <Divider>
                              <Button
                                onClick={() => {
                                  remove(field.name);
                                }}
                                type="danger"
                                ghost
                                size="small"
                              >
                                Remove
                              </Button>
                            </Divider>
                          </Form.Item>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </Form.List>
            </Col>
            <Col span={24}>
              <Form.List name="addresses">
                {(fields, { add, remove }) => (
                  <div>
                    <Form.Item>
                      {!disabled && (
                        <RightButton
                          onClick={() => {
                            add();
                            console.log("Clicked add");
                          }}
                          type="primary"
                          ghost
                          size="small"
                        >
                          Add New Address
                        </RightButton>
                      )}
                    </Form.Item>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <FormBuilder
                          formConfig={
                            disabled
                              ? addresses.map((item) => ({
                                  ...item,
                                  disabled: true,
                                }))
                              : addresses
                          }
                          index={index}
                          // formInstance={form}
                        />
                        {fields.length > 1 && (
                          <Form.Item k>
                            <Divider>
                              <Button
                                onClick={() => {
                                  remove(field.name);
                                }}
                                type="danger"
                                ghost
                                size="small"
                              >
                                Remove
                              </Button>
                            </Divider>
                          </Form.Item>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </Form.List>
            </Col>
            <Col span={24}>
              <Form.List name="alternateContactDetails">
                {(fields, { add, remove }) => (
                  <div>
                    <Form.Item>
                      {!disabled && (
                        <RightButton
                          onClick={() => {
                            add();
                            console.log("Clicked add");
                          }}
                          type="primary"
                          ghost
                          size="small"
                        >
                          Add New Relationship
                        </RightButton>
                      )}
                    </Form.Item>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <FormBuilder
                          formConfig={
                            disabled
                              ? nextOfKinType.map((item) => ({
                                  ...item,
                                  disabled: true,
                                }))
                              : nextOfKinType
                          }
                          index={index}
                          // formInstance={form}
                        />
                        {fields.length > 1 && (
                          <Form.Item k>
                            <Divider>
                              <Button
                                onClick={() => {
                                  remove(field.name);
                                }}
                                type="danger"
                                ghost
                                size="small"
                              >
                                Remove
                              </Button>
                            </Divider>
                          </Form.Item>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </Form.List>
            </Col>
            <Col span={24}>
              <Form.Item>
                <RightButton
                  className="btn-save"
                  htmlType="submit"
                  type="primary"
                  loading={submitLoading}
                  disabled={disabled}
                >
                  Submit
                </RightButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledContent>
    </BodyContainer>
  );
}

export default PatientProfileForm;
