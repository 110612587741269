import React, { useState } from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button, message, notification } from "antd";
import styled from "styled-components";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";
import { deleteDepartment } from "../../../../apis/departments.js";
import { deleteField } from "../../../../apis/healthRecord.js";
import { deleteLabTest, deleteRadiologyTest } from "../../../../apis/Admin";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
const ModalBodyContainer = styled.div`
  width: 500px;
`;
const ConfirmationModal = ({
  visible,
  setVisible,
  selectedItem,
  setSelectedItem,
  refetch,
  setDeleteErrorModal,
  setErrorMessage,
  fields,
  changeOrder,
}) => {
  const [loading, setLoading] = useState(false);
  const onConfirmHandler = () => {
    setLoading(true);
    if (fields) {
      // message.warning("Not available now");
      // setVisible(false);
      // setLoading(false);
      deleteField(selectedItem.id)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.status == 1) {
              notification.success({
                message: data.body,
              });
              // refetch();
              changeOrder();
              setVisible(false);
              setSelectedItem();
            } else {
              notification.error({
                message: data.body,
              });
              setSelectedItem();
            }
            if (setSelectedItem) setSelectedItem(undefined);
            setLoading(false);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Unable to delete!",
          });
          setLoading(false);
          console.log(err);
          Sentry.captureException(err);
        });
    } else {
      deleteRadiologyTest(selectedItem.id)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.status == 1) {
              notification.success({
                message: data.body,
              });
              refetch();
              setVisible(false);
            } else {
              notification.error({
                message: data.body,
              });
            }
            if (setSelectedItem) setSelectedItem(undefined);
            setLoading(false);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Unable to delete!",
          });
          setLoading(false);
          console.log(err);
          Sentry.captureException(err);
        });
    }
  };

  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <CustomAlerts
              type="delete"
              title="Delete Lab Test ?"
              description={`This action will permanently delete the type ${
                selectedItem
                  ? selectedItem.name
                    ? selectedItem.name
                    : selectedItem.formName
                    ? selectedItem.formName
                    : ""
                  : ""
              }.`}
            />
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVisible(false);
              }}
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                onConfirmHandler();
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default ConfirmationModal;
