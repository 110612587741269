import { Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';

import React from 'react';
import Spinner from '../Spinner';
import { getFormItem } from './buildHelper';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const StyledH3 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
  text-transform: capitalize;
`;
const compare = (a, b) => {
  let comparison = 0;
  if (a.order > b.order) {
    comparison = 1;
  } else if (a.order < b.order) {
    comparison = -1;
  }
  return comparison;
};

const FormBuilder = (props) => {
  console.log('props in builder', props);
  const [formConfig, setFormConfig] = useState([]);

  useEffect(() => {
    if (
      props.formConfig.length > 1 &&
      (props.formConfig[1].order || props.formConfig[1].order == 0)
    ) {
      setFormConfig(props.formConfig.sort(compare));
    } else {
      setFormConfig(props.formConfig);
    }
  }, [props.formConfig]);
  console.log('form config', formConfig);
  const theme = useSelector((state) => state.themeReducer);
  const capitalize = (camelCaseText) => {
    return (
      camelCaseText.charAt(0).toUpperCase() +
      camelCaseText
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(' ')
    );
  };

  return (
    <Row gutter={[36, 0]}>
      {formConfig.map((item) =>
        item.inputType !== 'heading' &&
        item.inputType !== 'dark-heading' &&
        item.inputType !== 'formList' ? (
          <Col {...(item.layout || { xl: 12, lg: 12, md: 24, sm: 24, xs: 24 })}>
            <Form.Item
              className={
                item?.rules && item?.rules[0]?.required ? '' : 'not-required'
              }
              {...{
                ...item,
                label: item.inputType === 'check-box' ? '' : item.label,
                name:
                  props.index || props.index == 0
                    ? Array.isArray(item.name)
                      ? [props.index, ...item.name]
                      : [props.index, item.name]
                    : item.name,
              }}
              valuePropName={
                item.inputType === 'check-box' ||
                item.inputType === 'check-box-default' ||
                item.inputType === 'check-box-with-input-field' ||
                item.inputType === 'check-box-with-tooltip'
                  ? 'checked'
                  : 'value'
              }
            >
              {getFormItem(
                { ...item, disabled: props.disabled ? true : false },
                props.softValidationFields,
                props.formInstance,

                theme
              )}
            </Form.Item>
          </Col>
        ) : item.inputType === 'heading' ? (
          <Col span={24}>
            <StyledH3 color={theme['@primary-color']}>{item.label}</StyledH3>
          </Col>
        ) : item.inputType === 'dark-heading' ? (
          <Col span={24}>
            <StyledH3 color="#5d5d5d">{item.label}</StyledH3>
          </Col>
        ) : (
          getFormItem({ ...item, disabled: props.disabled ? true : false })
          // JSON.stringify({ item: item })
        )
      )}
    </Row>
  );
};

export default FormBuilder;
