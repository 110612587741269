import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as ViewDoc } from '../../../../assets/icons/viewdoc.svg';
import { ReactComponent as DownloadOutside } from '../../../../assets/icons/downloadoutside.svg';

export const previousMedicalHistoryColumns = (
  pageNo,
  pageSize,
  StyledButton,
  theme,
  patient,
  patientId,
  patientFirstName,
  history,
  dispatch,
  addSection,
  consultationReportSection,
  healthRecordBaseUrl
) => [
  {
    width: '1.5vw',
  },
  {
    title: 'Actions',
    key: 'id',
    width: '7vw',
    render: (record) => {
      {
        console.log('patient is here', patient);
      }

      return (
        <StyledButton
          color={theme['@primary-color']}
          size="small"
          type="primary"
          ghost
          onClick={(event) => {
            event.stopPropagation();
            dispatch(
              addSection({
                sectionId: consultationReportSection?.id,
                sectionName: consultationReportSection?.formName,
                // dableDataTypes: tableTypes[data.body[0].formName],
                baseUrl: healthRecordBaseUrl,
                isConsultationReport: true,
              })
            );
            history.push({
              pathname: `/consultion-report/${patientId}/${patientFirstName}`,
            });
          }}
        >
          View Details
        </StyledButton>
      );
    },
  },
  {
    title: 'Sl. No',
    dataIndex: '',
    key: 'no',
    align: 'center',
    width: '7vw',
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: '5vw',
    render: (value) => moment(value).format('DD-MM-YYYY'),
  },
  {
    title: 'Time',
    dataIndex: '',
    key: 'time',
    align: 'center',
    width: '12vw',
    render: (value) => {
      if (value !== null) {
        return (
          <div>
            {moment(value.startTime).format('hh:mm A')} -{' '}
            {moment(value.endTime).format('hh:mm A')}
          </div>
        );
      } else return <div>-</div>;
    },
  },
  {
    title: 'Provider',
    dataIndex: 'doctor',
    key: 'provider',
    width: '8vw',
    align: 'center',
    render: (value) => {
      if (value) {
        return (
          <div>
            Dr. {value.firstName} {value.lastName}
          </div>
        );
      } else return <div>-</div>;
    },
  },
  {
    title: 'Type',
    dataIndex: ['appointmentType', 'name'],
    key: 'type',
    width: '12vw',
    align: 'center',
    render: (value) => {
      if (value) return value;
      else return <div>-</div>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '8vw',
    align: 'center',
    render: (value) => {
      if (value !== null) return value;
      else return <div>-</div>;
    },
  },
];

export const uploadedDocumentsColumns = (
  pageNo,
  pageSize,
  theme,
  setFileViewModal,
  setDocuments,
  downloadFile
) => [
  {
    title: 'Sl. No',
    dataIndex: '',
    key: 'no',
    align: 'center',
    width: 150,
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: 'Consultation Date',
    dataIndex: 'consultationDate',
    key: 'consultationDate',
    width: 200,
    render: (value) => moment(value).format('DD-MM-YYYY'),
  },
  {
    title: 'Uploaded By',
    dataIndex: 'uploadedBy',
    key: 'uploadedBy',
    width: 200,
    render: (value) => {
      if (value !== null) {
        return value;
      } else return <div>-</div>;
    },
  },
  {
    width: 30,
    render: (e) =>
      e.historyDocuments?.length > 0 ? (
        <Tooltip
          title="Preview"
          placement="bottom"
          color={'white'}
          arrowPointAtCenter={true}
          overlayInnerStyle={{
            color: 'InfoText',
            fontSize: '11px',
            borderRadius: '5px',
            width: '60px',
            textAlign: 'center',
          }}
        >
          <Icon
            onClick={() => {
              setFileViewModal(true);
              e.documents = e.historyDocuments;
              setDocuments(e);
            }}
            component={ViewDoc}
            style={{
              color: theme['@primary-color'],
              cursor: 'pointer',
              fontSize: '18px',
            }}
          />
        </Tooltip>
      ) : (
        <Icon
          component={ViewDoc}
          style={{
            color: 'rgba(0,0,0,0.25)',
            cursor: 'not-allowed',
            fontSize: '18px',
          }}
        />
      ),
  },
  {
    width: 200,
    render: (e) =>
      e.historyDocuments?.length > 0 ? (
        <Tooltip
          title="Download"
          placement="bottom"
          color={'white'}
          arrowPointAtCenter={true}
          overlayInnerStyle={{
            color: 'InfoText',
            fontSize: '11px',
            borderRadius: '5px',
            width: '80px',
            textAlign: 'center',
          }}
        >
          <Icon
            onClick={() => downloadFile(e)}
            component={DownloadOutside}
            style={{
              color: theme['@primary-color'],
              cursor: 'pointer',
              fontSize: '18px',
            }}
          />
        </Tooltip>
      ) : (
        <Icon
          component={DownloadOutside}
          style={{
            color: 'rgba(0,0,0,0.25)',
            cursor: 'not-allowed',
            fontSize: '18px',
          }}
        />
      ),
  },
];
