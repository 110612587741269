export const codeTypes = () => [
  "ALL",
  "DISEASE",
  "ALLERGY",
  "CITIZENSHIP",
  "PRIMARY_LANGUAGE",
  "NATIONALITY",
  "RELATIONSHIP",
  "SPECIALITIES",
  "ADDRESS_STATE",
  "ADDRESS_TYPE",
  "ALLERGY_SEVERITY",
  "CONTACT_ROLE",
  "DIAGNOSIS_PRIORITY",
  "DIAGNOSIS_TYPE",
  "EMAIL_TYPE",
  "GENDER",
  "ID_TYPE",
  "MARITAL_STATUS",
  "PHONE_TYPE",
  "RACE_LIST",
  "RELIGION",
  "GENERIC_CODES",
  "ROUTE_OF_ADMINISTRATION",
  "FORM_OF_ADMINISTRATION",
  "DATE_OF_ALLERGY_IDENTIFICATION",
  "NO_OF_DAYS",
];
