import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import {
  getHealthRecordForms,
  addHealthRecord,
  getSectionData,
} from '../../../../apis/healthRecord';
import { Row, Col, Button, message, Form, Empty } from 'antd';
import {
  generateFormFields,
  deserialize,
} from '../../../utils/FormBuilder/valuesSerialisation';
import FormBuilder from '../../../utils/FormBuilder';
import { fetchFormFieldMeta } from '../../../../apis/forms';
import { useCallback } from 'react';
import Spinner from '../../../utils/Spinner';
import { softValidate } from '../helper';

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
`;

export const DiscardSubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledForm = styled(Form)`
  &
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #6d6d6d;
    background: #fff;
    cursor: not-allowed;
  }

  & .ant-input[disabled] {
    color: #6d6d6d;
    background: #fff;
    cursor: not-allowed;
    opacity: 1;
  }
`;
const EmptyMessageContainer = styled.div`
  height: calc(100vh - 450px);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
`;

const columns = (setSelected, setVisible) => [
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'id',
    width: '150px',
    render: (value) => moment(value).format('DD-MM-YYYY'),
  },
  {
    title: 'Vital Informations',
    dataIndex: 'vitalsInformation',
    key: 'vital',
    render: (values) => (
      <Button
        size="small"
        type="primary"
        ghost
        onClick={() => {
          setSelected(values);
          setVisible(true);
        }}
      >
        View Details
      </Button>
    ),
  },
];

const CustomSection = () => {
  const { sectionId } = useParams();
  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  // const [tableData, setTableData] = useState([]);
  const patient = useSelector((state) => state.patientReducer);
  const user = useSelector((state) => state.userReducer);
  const section = useSelector((state) => state.sectionReducer);
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [validationStatus, setValidationStatus] = useState({});
  const [validationCriterias, setValidationCriterias] = useState([]);
  const [dropDownParentIds, setDropDownParentIds] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);

  const [form] = Form.useForm();
  const getPreviousData = () => {
    getSectionData(
      section.sectionId,
      user.useType == 'PATIENT' ? user.id : patient.pId,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            let initialValues = {};
            data.body.forEach((item) => {
              item.fields.forEach((item) => {
                initialValues[item.fieldId] = item.value;
              });
            });
            setFormInitialValues(initialValues);
          }
        }
      })
      .catch(console.err);
  };
  const getForms = useCallback(() => {
    var isData = false;
    getHealthRecordForms(sectionId, patient.pId)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            const tempFormIds = {};
            var tempMetaDataIds = [];

            var tempParentIds = [];
            var validationList = [];
            isData = data.body[0].date ? true : false;
            data.body.forEach((item) => {
              //getting formIds and corresponding field IDs for submit request Generation
              tempFormIds[item.id] = item.fields.map((field) => field.id);

              //finding fields which needs to call external API for fetching values eg:dropdown
              tempMetaDataIds = [
                ...tempMetaDataIds,
                ...item.fields.filter(
                  (field) =>
                    field.dropDownType !== null &&
                    field.dropDownType !== undefined &&
                    Object.keys(field.dropDownType).length !== 0
                ),
              ];
              //collecting parentIds to call dropdown values api when change value of dependend dropdown, eg: fetching filtered medicine doses after selecting a medicine
              tempParentIds = [
                ...tempParentIds,
                ...item.fields.filter(
                  (field) =>
                    field.parentField !== null &&
                    field.parentField !== undefined
                ),
              ];

              //COLLECTING FIELDS HAVING SOFT VALIDATORS
              validationList = [
                ...validationList,
                ...item.fields
                  .filter(
                    (field) =>
                      field.softValidator !== null &&
                      field.softValidator !== undefined &&
                      field.softValidator.length !== 0
                  )
                  .map((item) => {
                    return {
                      fieldId: item.id,
                      validationCrieteria: item.softValidator.map((val) => ({
                        type: val.validator,
                        value: val.value,
                      })),
                    };
                  }),
              ];
            });

            //converting forms into formBuilder readable format
            let FormsTemp = data.body.map((item) => {
              return {
                formName: item.formName,
                fields: generateFormFields(item.fields).map((field) => ({
                  ...field,
                  disabled: user.userType === 'PATIENT' ? true : false,
                })),
              };
            });
            setFormIds(tempFormIds);
            setMetaDataFieldIds(tempMetaDataIds);
            setForms(FormsTemp);
            setValidationCriterias(validationList);
            setFormFetchLoading(false);
            setDropDownParentIds(tempParentIds);
          } else {
            setFormFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        setFormFetchLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  }, [sectionId]);

  const submitFormData = (dynamicFormData) => {
    setSubmitLoading(true);
    addHealthRecord(
      sectionId,
      patient.pId,
      dynamicFormData,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            message.success('Health Record added');
            // getTableData();
            // form.resetFields();
          } else {
            message.error("couldn't add Vital Informations!");
          }
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const formSubmitHandler = (values) => {
    let data = Object.keys(formIds).map((key) => {
      const filtered = {};
      formIds[key].forEach((k) => {
        filtered[k] = values[k];
      });
      return { formId: key, fields: deserialize(filtered) };
    });
    submitFormData(data);
  };

  const onClear = () => {
    // setFormInitialValues({});
    form.resetFields();
    setFormFetchLoading(true);
    setValidationStatus({});
    getForms();
  };

  useEffect(() => {
    setFormFetchLoading(true);
    getForms();
    getPreviousData();
  }, []);

  useEffect(() => {
    setFormFetchLoading(true);
    getForms();
    getPreviousData();
  }, [window?.location?.pathname]);
  useEffect(() => {
    if (forms.length && metaDataFieldIds.length) {
      let fieldMetaPromises = metaDataFieldIds.map((item) => {
        return fetchFormFieldMeta(
          item.dropDownType.dropDownType,
          item.sectionId
        );
      });
      let tempDropDownValues = [...dropDownValues];

      Promise.all(fieldMetaPromises)
        .then((responses) => {
          responses.forEach(({ data, status }, index) => {
            if (status === 200) {
              if (data.status === 1)
                tempDropDownValues = [...tempDropDownValues, ...data.body];
              //Injecting meta data for corresponding  formfields
              forms.forEach((form) => {
                let metaField = form.fields.find(
                  (field) => field.name === metaDataFieldIds[index].id
                );
                if (metaField) {
                  // setDropDownValues([...dropDownValues, ...data.body]);
                  metaField.meta = data.body.map((item) => item.value);
                } else console.log('Meta undefined');
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setMetaDataFieldIds([]);
          setDropDownValues(tempDropDownValues);
          setForms([...forms]);
        });
    }
  }, [metaDataFieldIds, forms]);

  const onFormChangeHandler = (changed) => {
    Object.entries(changed).map(([key, value]) => {
      let changedField = dropDownParentIds.find(
        (item) => item.parentField == key
      );
      if (changedField) {
        let dropDownItem = dropDownValues.filter((item) => item.value == value);

        if (dropDownItem[dropDownItem.length - 1]) {
          changedField.sectionId = dropDownItem[dropDownItem.length - 1].id;

          setMetaDataFieldIds([changedField]);
        }
      }
    });
    Object.keys(changed).forEach((chngd) => {
      let validator = validationCriterias.find((item) => {
        return item.fieldId == chngd;
      });
      if (validator) {
        validationStatus[chngd] = softValidate(validator, changed[chngd]);
      }
    });

    setValidationStatus({ ...validationStatus });
  };

  useEffect(() => {
    onFormChangeHandler(formInitialValues);
    if (Object.keys(formInitialValues).length === 0) form.resetFields();
  }, [formInitialValues]);

  return !formFetchLoading ? (
    <Row gutter={[24, 12]}>
      <Col span={24}>
        {forms.length > 0 ? (
          <FormContainer>
            <StyledForm
              layout="vertical"
              onFinish={formSubmitHandler}
              form={form}
              initialValues={formInitialValues}
              key={Object.keys(formInitialValues).length}
              onValuesChange={onFormChangeHandler}
              scrollToFirstError={true}
            >
              {forms.map((subForm) => (
                <FormBuilder
                  formInstance={form}
                  formConfig={[
                    {
                      inputType: 'heading',
                      label: subForm.formName,
                    },
                    ...subForm.fields,
                  ]}
                  softValidationFields={validationStatus}
                />
              ))}
              {user.userType !== 'PATIENT' && (
                <Form.Item>
                  {forms.length !== 0 && (
                    <DiscardSubmitWrapper>
                      <Button
                        type="primary"
                        ghost
                        htmlType="submit"
                        className="btn-save"
                        onClick={onClear}
                      >
                        Discard
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-save"
                        loading={submitLoading}
                      >
                        Submit
                      </Button>
                    </DiscardSubmitWrapper>
                  )}
                </Form.Item>
              )}
            </StyledForm>
          </FormContainer>
        ) : (
          <EmptyMessageContainer>
            <Empty />
          </EmptyMessageContainer>
        )}
      </Col>
    </Row>
  ) : (
    <Spinner />
  );
};
export default CustomSection;
