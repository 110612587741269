import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { useSelector } from "react-redux";

const Container = styled.div`
  text-align: center;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
  min-height: ${(props) => props.height || "calc(100vh - 300px)"};
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
`;
const Spinner = ({ height }) => {
  const theme = useSelector((state) => state.themeReducer);

  const StyledSpinner = styled(Spin)`
    & .ant-spin-dot-item {
      background-color: ${theme["@primary-color"]};
    }
    & .ant-spin-text {
      color: ${theme["@primary-color"]};
    }
  `;

  return (
    <Container height={height}>
      <StyledSpinner tip="Loading..." size="large" />
    </Container>
  );
};
export default Spinner;
