// import { message } from "antd";
import React, { useState, useEffect } from "react";
import SockJsClient from "react-stomp";
import { useDispatch, useSelector } from "react-redux";
import { setInQueueCount, setActiveCount } from "../redux/actions/activeCount";
import Moment from "moment-timezone";

export const ActiveCount = (e) => {
  const [customHeaders, setCustomHeaders] = useState({
    Authorization:
      "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhbHRoYWZAZGV4bG9jay5jb20iLCJpYXQiOjE2MzA4NjMzMzMsImV4cCI6MTYzMDk0OTczM30.XQKsvv0c3WP6xpmPXWpapWYGZwzKrWbiclFHARBueKcfwV-lTBX5p2dRNnemxMEhrmVGL3WCygEgaWm5j1ewtA",
    ABC: "asd",
  });
  const user = useSelector((state) => state.userReducer);
  // const activePatientCount = useSelector((state) => state.patientReducer)
  const [messages, setMessages] = useState([]);
  const [activeDoctorCount, setActiveDoctorCount] = useState(0);
  const [activeReceptionCount, setActiveReceptionCount] = useState(0);
  const [activeLaboratoryCount, setActiveLaboratoryCount] = useState(0);
  const [activeRadiologyCount, setActiveRadiologyCount] = useState(0);
  const [activePharmacyCount, setActivePharmacyCount] = useState(0);
  const [activeInsuranceCount, setActiveInsuranceCount] = useState(0);
  const [count, setCount] = useState(0);
  const [clientRef, setClientRef] = useState();
  const dispatch = useDispatch();

  const sendCountMessage = () => {
    // if (clientRef)
    if (user.userType == "DOCTOR") {
      clientRef.sendMessage(
        "/app/in-queue-patients",
        JSON.stringify({
          type: "DOCTOR_IN_QUEUE_PATIENTS",
          timeZone: Moment.tz.guess(),
          doctorId: user.id,
          typeId: user.id,
        })
      );
      clientRef.sendMessage(
        "/app/active-patients",
        JSON.stringify({
          type: "DOCTOR_ACTIVE_PATIENTS",
          timeZone: Moment.tz.guess(),
          doctorId: user.id,
          typeId: user.id,
        })
      );
    } else {
      clientRef.sendMessage(
        "/app/active-patients",
        JSON.stringify({
          type: "RECEPTION_ACTIVE_PATIENTS",
          timeZone: Moment.tz.guess(),
          typeId: null,
        })
      );
    }
    if (user.userType == "NURSE") {
      // inQueueCountNurse
      clientRef.sendMessage(
        "/app/in-queue-patients",
        JSON.stringify({
          type: "NURSE_IN_QUEUE_PATIENTS",
          timeZone: Moment.tz.guess(),
          typeId: null,
        })
      );
    }
    if (user.userType == "LABORATORY") {
      // activeCountLab
      clientRef.sendMessage(
        "/app/active-patients",
        JSON.stringify({
          type: "LABORATORY_ACTIVE_PATIENTS",
          timeZone: Moment.tz.guess(),
          typeId: user.id,
        })
      );
    }
    if (user.userType == "RADIOLOGY") {
      // activeCountRadiology
      clientRef.sendMessage(
        "/app/active-patients",
        JSON.stringify({
          type: "RADIOLOGY_ACTIVE_PATIENTS",
          timeZone: Moment.tz.guess(),
          typeId: user.id,
        })
      );
    }
    if (user.userType == "PHARMACY") {
      // activeCountPharmacy
      clientRef.sendMessage(
        "/app/active-patients",
        JSON.stringify({
          type: "PHARMACY_ACTIVE_PATIENTS",
          timeZone: Moment.tz.guess(),
          typeId: null,
        })
      );
    }
    if (user.userType == "INSURANCE") {
      // activeCountInsurance
      clientRef.sendMessage(
        "/app/active-patients",
        JSON.stringify({
          type: "INSURANCE_ACTIVE_PATIENTS",
          timeZone: Moment.tz.guess(),
          typeId: null,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setInQueueCount({ inQueueCount: count }));
    dispatch(
      setActiveCount({
        activeReceptionCount: activeReceptionCount,
        activeDoctorCount: activeDoctorCount,
        activeLaboratoryCount: activeLaboratoryCount,
        activeRadiologyCount: activeRadiologyCount,
        activePharmacyCount: activePharmacyCount,
        activeInsuranceCount: activeInsuranceCount,
      })
    );
  }, [
    count,
    activeDoctorCount,
    activeReceptionCount,
    activeLaboratoryCount,
    activeRadiologyCount,
    activePharmacyCount,
    activeInsuranceCount,
  ]);

  return (
    <div>
      <SockJsClient
        url={`${process.env.REACT_APP_STOMP}/stomp/stomp-endpoint`}
        headers={customHeaders}
        topics={["/topic/active-patients", "/topic/in-queue-patients"]}
        onConnect={() => {
          console.log("connected");
          // message.success("connected");
          sendCountMessage();
        }}
        onDisconnect={() => {
          console.log("Disconnected");
          // message.success("Disconnected");
        }}
        onMessage={(msg) => {
          var jobs = messages;
          var type = msg.type;
          console.log(msg);
          console.log(user);
          // message.success(msg.value)

          if (type === "DOCTOR_IN_QUEUE_PATIENTS") {
            if (user.id == msg.typeId) {
              setCount(msg.value);
              dispatch(setInQueueCount({ inQueueCount: msg.value }));
            }
            // dispatch(setActiveCount())
          }
          if (type === "DOCTOR_ACTIVE_PATIENTS") {
            if (msg.typeId == user.id) {
              setActiveDoctorCount(msg.value);
              console.log(msg);
              jobs.push(msg);
              dispatch(
                setActiveCount({
                  // activeReceptionCount: activeReceptionCount,
                  activeDoctorCount: msg.value,
                })
              );
            }
          } else if (type === "RECEPTION_ACTIVE_PATIENTS") {
            setActiveReceptionCount(msg.value);
            jobs.push(msg);
            dispatch(
              setActiveCount({
                activeReceptionCount: msg.value,
                // activeDoctorCount: activeDoctorCount
              })
            );
          } else if (type == "NURSE_IN_QUEUE_PATIENTS") {
            if (user.userType == "NURSE") {
              setCount(msg.value);
              dispatch(setInQueueCount({ inQueueCount: msg.value }));
            }
          } else if (type == "NURSE_ACTIVE_PATIENTS") {
            if (user.userType == "NURSE") {
              setActiveReceptionCount(msg.value);
              jobs.push(msg);
              dispatch(
                setActiveCount({
                  activeReceptionCount: msg.value,
                  // activeDoctorCount: activeDoctorCount
                })
              );
            }
          }
          if (type === "LABORATORY_ACTIVE_PATIENTS") {
            if (msg.typeId == user.id) {
              setActiveLaboratoryCount(msg.value);
              console.log(msg);
              jobs.push(msg);
              dispatch(
                setActiveCount({
                  activeLaboratoryCount: msg.value,
                })
              );
            }
          }
          if (type === "RADIOLOGY_ACTIVE_PATIENTS") {
            if (msg.typeId == user.id) {
              setActiveRadiologyCount(msg.value);
              console.log(msg);
              jobs.push(msg);
              dispatch(
                setActiveCount({
                  activeRadiologyCount: msg.value,
                })
              );
            }
          }
          if (type === "PHARMACY_ACTIVE_PATIENTS") {
            setActivePharmacyCount(msg.value);
            console.log(msg);
            jobs.push(msg);
            dispatch(
              setActiveCount({
                activePharmacyCount: msg.value,
              })
            );
          }
          if (type === "INSURANCE_ACTIVE_PATIENTS") {
            setActiveInsuranceCount(msg.value);
            console.log(msg);
            jobs.push(msg);
            dispatch(
              setActiveCount({
                activeInsuranceCount: msg.value,
              })
            );
          }
          setMessages(jobs);
          console.log(
            messages,
            activeDoctorCount,
            activeReceptionCount,
            activeLaboratoryCount,
            activeRadiologyCount,
            activePharmacyCount,
            activeInsuranceCount
          );
          // message.success(this.state)
        }}
        ref={(client) => {
          setClientRef(client);
        }}
      />
    </div>
  );
};
