import { ReactComponent as UserRole } from "../assets/icons/user-roles.svg";
import { ReactComponent as UserManagement } from "../assets/icons/user-manage.svg";
import { ReactComponent as Departments } from "../assets/icons/department.svg";
import { ReactComponent as Encounters } from "../assets/icons/encounters.svg";
import { ReactComponent as Drug } from "../assets/icons/drug.svg";
import { ReactComponent as ManageSections } from "../assets/icons/sections.svg";
import { ReactComponent as ConsentForms } from "../assets/icons/consent-manage.svg";
import { ReactComponent as Insurance } from "../assets/icons/insurance.svg";
import { ReactComponent as Dictionory } from "../assets/icons/dictionary.svg";
import { ReactComponent as GeneralSettings } from "../assets/icons/SettingsIcon.svg";
import { ReactComponent as MedicalHistoryIcon } from "../assets/icons/medicalHistory.svg";
import { ReactComponent as AllergyIcon } from "../assets/icons/allergyIcon.svg";
import { ReactComponent as PaientIcon } from "../assets/icons/patientIcon.svg";
import { ReactComponent as VitalIcon } from "../assets/icons/vitalIcon.svg";
import { ReactComponent as RadiologyIcon } from "../assets/icons/Radiology.svg";
import { ReactComponent as LaboratoryIcon } from "../assets/icons/Laboratory.svg";
import { ReactComponent as ExaminationIcon } from "../assets/icons/Examination.svg";
import { ReactComponent as ConsentIcon } from "../assets/icons/concentForm.svg";
import { ReactComponent as CarePlanIcon } from "../assets/icons/CarePlan.svg";
import { ReactComponent as BillingIcon } from "../assets/icons/CarePlan.svg";
import { ReactComponent as MedicineDetails } from "../assets/icons/MedicineDetails.svg";
import { ReactComponent as ConsultationReportIcon } from "../assets/icons/ConsultationReport.svg";
export const sidebarIcons = {
  "user-role": UserRole,
  "user-management": UserManagement,
  departments: Departments,
  encounters: Encounters,
  drug: Drug,
  "manage-sections": ManageSections,
  "consent-form": ConsentForms,
  insurance: Insurance,
  dictionary: Dictionory,
  settings: GeneralSettings,
  "medical-history": MedicalHistoryIcon,
  allergies: AllergyIcon,
  patient: PaientIcon,
  "vital-info": VitalIcon,
  radiology: RadiologyIcon,
  laboratory: LaboratoryIcon,
  examination: ExaminationIcon,
  "care-plan": CarePlanIcon,
  billing: BillingIcon,
  "medicine-details": MedicineDetails,
  "consultation-report": ConsultationReportIcon,
};
