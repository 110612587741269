import React, { useEffect, useState } from "react";
import { Row, Col, message, Button, Checkbox, Form, Radio, Input } from "antd";
import { SectionHeading, SectionHeadContainer } from "../styledComponents";
import {
  findAllPrivilegesByRole,
  updatePrivileges,
} from "../../../../apis/Admin";
import * as Sentry from "@sentry/react";
import Spinner from "../../../utils/Spinner";
import styled from "styled-components";
import ConfirmationModal from "./ConfirmationModal";
import SuccessModal from "../../../utils/StatusModals/SuccessModals";

const StyledCol = styled(Col)``;

const StyledCheckbox = styled(Checkbox)`
  && {
    display: flex;
  }
  & .ant-checkbox-inner {
    height: 22px;
    width: 22px;
  }

  & .ant-checkbox-inner::after {
    left: 25%;
    width: 7px;
    height: 12px;
  }
  & .ant-checkbox {
    margin-right: 15px;
  }
`;

const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;

const SectionTtile = styled.h3`
  font-size: 20px;
`;

const RoleBasedPrivileges = ({
  selectedId,
  setSelected,
  setSelectedId,
  selectedRole,
}) => {
  const [roleDetails, setRoleDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [apiRequest, setApiRequest] = useState({});
  const [isSave, setIsSave] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedId) {
      findAllPrivilegesByRole(selectedId)
        .then((resp) => {
          if (resp.data.status === 1) {
            const data = resp.data.body;
            setRoleDetails(data);
            const filteredData = data.privileges.filter(
              (item) => item.active === true
            );
            setFormInitialValues(filteredData && filteredData.map((i) => i.id));
            setLoading(false);
          } else {
            setLoading(false);
            message.error("Could not fetch User Privileges, Try again");
          }
        })
        .catch((err) => {
          message.error("Could not fetch User Privileges, Try again");
          setLoading(false);
          console.log(err);
          Sentry.captureException(err);
        });
    }
  }, []);

  useEffect(() => {
    console.log(selectedRole);
    form.setFieldsValue({
      privileges: formInitialValues,
      status: selectedRole.status,
      name: selectedRole.name,
    });
    // form.setFieldsValue(selectedRole);
  }, [formInitialValues, selectedRole]);

  const submitForm = (values) => {
    const payload = {
      roleId: selectedId,
      privilegeId: values.privileges,
      status: values.status,
      name: values.name,
    };
    setApiRequest(payload);
    setConfirmationModal(true);
  };

  const addPrivileges = () => {
    setSubmitLoading(true);
    updatePrivileges(apiRequest)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            setSuccessModal(true);
          } else {
            message.error("Couldn't update privilages, Try again");
          }
        }
        setSubmitLoading(false);
        setIsSave(false);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const handleChange = () => {
    setIsSave(true);
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Form
        layout="vertical"
        onFinish={(values) => submitForm(values)}
        onValuesChange={handleChange}
        form={form}
        scrollToFirstError={true}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            size="large"
            disabled={roleDetails.emrRole.name === "Admin" ? true : false}
          />
        </Form.Item>
        <Form.Item name="privileges">
          <Checkbox.Group size="large">
            <Row gutter={[36, 36]}>
              {roleDetails.privileges &&
                roleDetails.privileges.map((privilege) => (
                  <StyledCol xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Checkbox
                      value={privilege.id}
                      defaultChecked={privilege.active}
                      disabled={
                        roleDetails.emrRole.name === "Admin" ? true : false
                      }
                      size="large"
                    >
                      {privilege.name}
                    </Checkbox>
                  </StyledCol>
                ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <SectionTtile>Active Status</SectionTtile>

        <Col span={24}></Col>
        <Form.Item
          name="status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group
            size="large"
            disabled={roleDetails.emrRole.name === "Admin" ? true : false}
          >
            <Radio value={"ACTIVE"}>Active</Radio>
            <Radio value={"INACTIVE"}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <RightButton
            type="primary"
            htmlType="submit"
            disabled={
              roleDetails.emrRole.name === "Admin"
                ? true
                : !isSave
                ? true
                : false
            }
          >
            Save
          </RightButton>
          <RightButton
            type="primary"
            ghost
            onClick={() => {
              setSelected(false);
              setSelectedId(null);
            }}
            disabled={roleDetails.emrRole.name === "Admin" ? true : false}
          >
            Cancel
          </RightButton>
        </Form.Item>
      </Form>

      <SuccessModal
        message="Successfully modified user privileges."
        visible={successModal}
        okText="Done"
        onOk={() => {
          setSuccessModal(false);
        }}
        setVisible={setSuccessModal}
      />
      <ConfirmationModal
        visible={confirmationModal}
        setVistible={setConfirmationModal}
        onConfirm={() => addPrivileges()}
        loading={submitLoading}
      />
    </>
  );
};

export default RoleBasedPrivileges;
