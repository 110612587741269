import axiosInstance from "../config/axiosInstance";

const DEPARTMENT_PREFIX = "/departments";

export const addNewDepartment = (data) => {
  return axiosInstance.post(`${DEPARTMENT_PREFIX}`, data);
};

export const deleteDepartment = (id) => {
  return axiosInstance.delete(`${DEPARTMENT_PREFIX}/${id}`);
};
