import axiosInstance from "../config/axiosInstance";
import Moment from "moment-timezone";

const FORM_PREFIX = "/form";
const SAMPLE_FORM_PREFIX = "/sample-form";

export const fetchFormByType = (type) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/fetch-document-form?type=${type}`
  );
};

export const fetchLabTests = (types) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/fetch-lab-tests?dropDownIds=${types}`
  );
};
export const fetchRadiologTest = (types) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/fetch-radiology-tests?dropDownIds=${types}`
  );
};
export const saveRadiologyTests = (data) => {
  data.timeZone = Moment.tz.guess();
  return axiosInstance.post(`${SAMPLE_FORM_PREFIX}/save-radiology-test`, data);
};

export const saveRadiologyTestsIPD = (data) => {
  data.timeZone = Moment.tz.guess();
  return axiosInstance.post(`${SAMPLE_FORM_PREFIX}/save-radiology-test`, data);
};

export const saveLabTests = (data) => {
  data.timeZone = Moment.tz.guess();
  return axiosInstance.post(`${SAMPLE_FORM_PREFIX}/save-lab-test`, data);
};

export const previousRadiologyRequest = (
  appointmentId,
  pId,
  size,
  pageNo,
  consultationReport
) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/radiology-table-data?appointmentId=${appointmentId}${
      size ? `&pageNo=${pageNo}${`&size=${size}`}` : ``
    }${
      consultationReport ? `&forConsultationReport=${consultationReport}` : ``
    }`
  );
};

export const previousRadiologyRequestIPD = (
  ipdID,
  pId,
  size,
  pageNo,
  consultationReport
) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/radiology-table-data?ipdId=${ipdID}${
      size ? `&pageNo=${pageNo}${`&size=${size}`}` : ``
    }${
      consultationReport ? `&forConsultationReport=${consultationReport}` : ``
    }`
  );
};

export const previousLabTestRequest = (
  appointmentId,
  pId,
  size,
  pageNo,
  consultationReport
) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/laboratory-table-data?appointmentId=${appointmentId}${
      size ? `&pageNo=${pageNo}${`&size=${size}`}` : ``
    }${
      consultationReport ? `&forConsultationReport=${consultationReport}` : ``
    }`
  );
};

export const previousLabTestRequestIPD = (
  ipdID,
  size,
  pageNo
) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/laboratory-table-data?ipdId=${ipdID}&pageNo=${pageNo}&size=${size}&timeZone=Asia/Calcutta`
  );
};

export const fetchFormFieldMeta = (type, sectionId) => {
  console.log(
    "section id in api",
    `${SAMPLE_FORM_PREFIX}/fetch-drop-downs?type=${type}${
      sectionId ? "&section_id=" + sectionId : ""
    }`
  );

  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/fetch-drop-downs?type=${type}${
      sectionId ? "&section_id=" + sectionId : ""
    }`
  );
};

export const fetchChildFormFieldMeta = (parentId, typeId) => {
  return axiosInstance.get(
    `${FORM_PREFIX}/fetch-dynamic-field-data?parentId=${parentId}&typeId=${typeId}`
  );
};

export const addFormFieldForTest = (data) => {
  return axiosInstance.post(`${SAMPLE_FORM_PREFIX}/test/field`, data);
};

export const fetchFieldsOfTest = (testType, testId) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/test/${testType}/${testId}/fields`
  );
};
