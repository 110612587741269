import axiosInstance from '../config/axiosInstance';
import axios from 'axios';

const OTP_PRFIX = '/sms';

export const baseUrl = process.env.REACT_APP_API_BASE_URL;
// export const baseUrl = process.env.REACT_APP_STAGING_API_BASE_URL;
export const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return baseUrl;
    default:
      return process.env.REACT_APP_STAGING_API_BASE_URL;
  }
};

export const createSMSToSinglePerson = (data, queryString) => {
  return axiosInstance.post(
    `${OTP_PRFIX}/smsToSingleperson?${queryString}`,
    data
  );
};

export const getSMSListOfHospitalwithId = (queryString) => {
  // return axiosInstance.get(
  //   `${OTP_PRFIX}/listMessagesOfHospital?${queryString}`
  // );
  console.log('query string', queryString);
  return axios.get(
    `${getBaseUrl()}${OTP_PRFIX}/listMessagesOfHospital?${queryString}`
  );
};

export const resendSMS = (smsId) => {
  return axiosInstance.post(`${OTP_PRFIX}/resentSms/${smsId}`);
};

// export const resendSMS = (smsId) => {
//   //once jwt implemented (jwt in BE) comment 58 and uncomment 56
//   // return axiosInstance.get(`sms/listMessages?${queryString}`);
//   return axios.post(
//     `https://staging-api.meditagg.com/api/v1/sms/resentSms/${smsId}`
//   );
// };
