import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, message, Tooltip, Button, Card } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import HospitalListTable from './HospitalListTable';

export const NewFormContainer = styled(Row)`
  background-color: #fff;
  padding: 20px 20px;
`;
export const StyledRow = styled(Row)`
  margin-top: 24px;
`;

const OrganisationDashboard = () => {
  const history = useHistory();
  const userState = useSelector((state) => state.userReducer);
  console.log('userState', userState.hospitals);

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  return (
    <>
      <NewFormContainer>
        {Object.keys(userState)?.length ? (
          <Card
            title={
              <h2 style={{ textAlign: 'center' }}>
                {userState?.fullName.toUpperCase()}
              </h2>
            }
            size="large"
            style={{ width: '100%' }}
          >
            <h2>Email: &nbsp;{userState?.email} </h2>
          </Card>
        ) : (
          ''
        )}
      </NewFormContainer>
      <HospitalListTable
        hospitals={userState.hospitals}
        // loading={loading}
        setSelectedItem={setSelectedItem}
        setEditModal={setEditModal}
        setDeleteModal={setDeleteModal}
        history={history}
      />
    </>
  );
};
export default OrganisationDashboard;
