import React from "react";
import { Button, Select, Tooltip } from "antd";
import { render } from "@testing-library/react";
import Icon, { CaretDownOutlined, DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as ViewDoc } from "../../../assets/icons/viewdoc.svg";
import styled from "styled-components";
import moment from "moment";
const { Option } = Select;

const StyledSelect = styled(Select)`
  & .ant-select-selector {
    padding: 0 !important;
    width: "90%";
    color: ${(props) => props.theme} !important;
    cursor: ${(props) =>
    props.cursor == "default" ? props.cursor : undefined};
  }
`;

export const columns = (
  theme,
  StyledPreviewIcon,
  history,
  onChange,
  form,
  statusOptions,
  setEditTestResults,
  setSelectedRow,
  setFileViewModal,
  setDocuments,
  downloadFile,
  setVisible
) => [
    { width: "2vw" },
    {
      width: "12vw",
      title: "Actions",
      render: (row) => (
        <>
          {history?.location?.state?.viewHistory == undefined ? (
            <Button
              onClick={() => {
                setEditTestResults(true);
                setSelectedRow(row);
              }}
              type="primary"
              style={{ borderRadius: "5px", padding: "5px" }}
            >
              Add/Edit Report
            </Button>
          ) : (
            <Button
              onClick={() => {
                setSelectedRow(row);
                setVisible(true);
              }}
              type="primary"
              style={{ borderRadius: "5px", padding: "5px" }}
            >
              View Report
            </Button>
          )}
        </>
      ),
    },
    {
      title: "Status",
      // dataIndex: "Status",
      width: "13vw",
      render: (row, index, value) => (
        <StyledSelect
          onChange={(e) => {
            onChange(row, form.getFieldsValue(), e);
          }}
          theme={
            statusOptions?.filter((item) => item.value == row.Status) &&
            statusOptions.filter((item) => item.value == row.Status)[0]?.color
          }
          bordered={false}
          style={{ width: "90%", color: "red" }}
          suffixIcon={<CaretDownOutlined />}
          defaultValue={row.Status}
          cursor={history?.location?.state?.viewHistory && "default"}
          open={history?.location?.state?.viewHistory && false}
          showArrow={history?.location?.state?.viewHistory ? false : true}
        >
          {statusOptions.map((item) => (
            <Option style={{ color: item.color }} value={item.value}>
              {item.value}
            </Option>
          ))}
        </StyledSelect>
      ),
    },
    {
      title: "Sl. No",
      width: "4vw",
      dataIndex: "Sl No.",
    },
    {
      title: "Requested Date",
      // width: "10vw",bruce@meditagg.com
      dataIndex: "Request Date",
      render: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
    },
    {
      title: "Type",
      // width: "7vw",
      dataIndex: "Type",
    },
    {
      title: "Common Test",
      // width: "10vw",
      dataIndex: "Common Test",
    },
    {
      title: "Test Name",
      // width: "10vw",
      dataIndex: "Test Name",
    },
    {
      title: "Last Updated",
      // width: "13vw",
      dataIndex: "Last Updated",
      render: (value) => value ? moment(value).format("DD-MM-YYYY hh:mm A") : "-",
    },
    {
      // width: "3vw",
      render: (e) => (
        <>
          {history?.location?.state?.viewHistory && (
            <>
              {e.documents?.length > 0 ? (
                <Tooltip
                  title="Preview"
                  placement="bottom"
                  color={"white"}
                  arrowPointAtCenter={true}
                  overlayInnerStyle={{
                    color: "InfoText",
                    fontSize: "11px",
                    borderRadius: "5px",
                    width: "60px",
                    textAlign: "center",
                  }}
                >
                  <Icon
                    onClick={() => {
                      setFileViewModal(true);
                      setDocuments(e);
                    }}
                    component={ViewDoc}
                    style={{
                      color: theme["@primary-color"],
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                  />
                </Tooltip>
              ) : (
                <StyledPreviewIcon
                  component={ViewDoc}
                  style={{
                    color: "rgba(0,0,0,0.25)",
                    cursor: "not-allowed",
                    fontSize: "18px",
                  }}
                />
              )}
            </>
          )}
        </>
      ),
      hidden: history.location.state?.viewHistory ? false : true,
    },
    {
      // width: "3vw",
      render: (e) => (
        <>
          {history?.location?.state?.viewHistory && (
            <>
              {e.documents?.length > 0 ? (
                <Tooltip
                  title="Download"
                  placement="bottom"
                  color={"white"}
                  arrowPointAtCenter={true}
                  overlayInnerStyle={{
                    color: "InfoText",
                    fontSize: "11px",
                    borderRadius: "5px",
                    width: "80px",
                    textAlign: "center",
                  }}
                >
                  <Icon
                    onClick={() => {
                      downloadFile(e);
                    }}
                    component={DownloadOutlined}
                    style={{
                      color: theme["@primary-color"],
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                  />
                </Tooltip>
              ) : (
                <Icon
                  component={DownloadOutlined}
                  style={{
                    color: "rgba(0,0,0,0.25)",
                    cursor: "not-allowed",
                    fontSize: "18px",
                  }}
                />
              )}
            </>
          )}
        </>
      ),
      hidden: history.location.state?.viewHistory ? false : true,
    },
  ];

export const columnsTest = (
  fields,
  theme,
  deletedRows,
  setDeletedRows,
  tableData,
  setTableData,
  formDataRequests,
  setFormDataRequests,
  setIsSaveChanges
) => {
  var columns = fields
    ?.filter(
      (item) =>
        item.fieldType !== "SINGLE_UPLOAD" &&
        item.fieldType !== "MULTIPLE_UPLOAD"
    )
    .map((item) => {
      return { title: item.formName, dataIndex: item.formName };
    });
  if (columns)
    columns.push({
      render: (row) => (
        <Button
          onClick={() => {
            setIsSaveChanges(true)
            if (row.fieldDataId) {
              deletedRows.push(row.fieldDataId);
              setDeletedRows(deletedRows);
              let deleted = tableData.filter(
                (data) => row.fieldDataId != data.fieldDataId
              );
              setTableData(deleted);
              console.log(deletedRows);
            } else {
              let deleted = tableData.filter(
                (data) =>
                  ((data.id || data.id == 0) && row.id != data.id) ||
                  data.fieldDataId
              );
              setTableData(deleted);
              let deletedForm = formDataRequests.filter(
                (data) =>
                  ((data.id || data.id == 0) && row.id != data.id) ||
                  data.fieldDataId
              )
              setFormDataRequests(deletedForm)
            }
          }}
          style={{ color: theme["@primary-color"], border: "none" }}
        >
          {" "}
          remove
        </Button >
      ),
    });
  console.log(columns);
  if (columns)
    return [
      { width: "2vw" },
      { title: "Sl. No", render: (value, item, index) => index + 1 },
      ...columns,
    ];
  else return [];
};
