import { Col, Form, Input, Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { StyledDivider, StyledTitle } from "./styledComponents";

const { Option } = Select;

const VisitTypeContainer = ({
  type,
  form,
  deductibleType,
  setAmountTrigger,
  insuranceTypes,
  insuranceTypeMap,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  return (
    <>
      <Col
        span={24}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col>
          <StyledTitle color={theme["@primary-color"]} level={4}>
            {type}
          </StyledTitle>
        </Col>

        <StyledDivider />
      </Col>
      <Col span={11}>
        <Form.Item
          label="Insurance Type"
          name={[type, "insuranceType"]}
          rules={[{ required: true, message: "Selecte Insurance type" }]}
          style={{ width: "100%" }}
        >
          <Select
            size="large"
            placeholder="Select Type"
            onChange={() => setAmountTrigger(Math.random())}
          >
            {insuranceTypes.map((item) => {
              return (
                <Option value={item} key={item}>
                  {insuranceTypeMap.get(item)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      {type !== "Pharmacy" && (
        <Col span={11}>
          <Form.Item
            label={
              type == "Hospital"
                ? "For Insurance Free Follow-up Day(s):"
                : "Waiting Period Day(s)"
            }
            name={[type, "waitingPeriod"]}
            rules={[
              { required: true, message: "Waiting Period cannot be empty" },
              {
                pattern: new RegExp(/^[0-9]+$/),
                message: "Invalid Input!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter Waiting Period" />
          </Form.Item>
        </Col>
      )}
      {(form.getFieldValue([type, "insuranceType"]) == "SELF" ||
        form.getFieldValue([type, "insuranceType"]) == "BOTH") && (
        <>
          <Col span={24}>
            <StyledTitle color={theme["@primary-color"]} level={5}>
              Self
            </StyledTitle>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Amount"
              name={[type, "selfAmount"]}
              rules={[
                {
                  required: true,
                  message: "Amount cannot be empty",
                },
                {
                  pattern: new RegExp(/^\d*\.?\d+$/),
                  message: "Invalid Input!",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input size="large" placeholder="Enter Amount" />
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              label="Type"
              name={[type, "selfType"]}
              rules={[{ required: true, message: "Selecte type" }]}
              style={{ width: "100%" }}
            >
              <Select size="large" placeholder="Select Type">
                {deductibleType.map((item) => {
                  return (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </>
      )}
      {(form.getFieldValue([type, "insuranceType"]) == "COPAY" ||
        form.getFieldValue([type, "insuranceType"]) == "BOTH") && (
        <>
          <Col span={24}>
            <StyledTitle color={theme["@primary-color"]} level={5}>
              CoPay
            </StyledTitle>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Amount"
              name={[type, "copayAmount"]}
              rules={[
                {
                  required: true,
                  message: "Amount cannot be empty",
                },
                {
                  pattern: new RegExp(/^\d*\.?\d+$/),
                  message: "Invalid Input!",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input size="large" placeholder="Enter Amount" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Type"
              name={[type, "copayType"]}
              rules={[{ required: true, message: "Selecte type" }]}
              style={{ width: "100%" }}
            >
              <Select size="large" placeholder="Select Type">
                {deductibleType.map((item) => {
                  return (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
};

export default VisitTypeContainer;
