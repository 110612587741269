import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { message, Col, Form, Row, Input, Button } from "antd";
import { changePassword } from "../../../apis/User";
import CustomAlerts from "../../utils/CustomAlerts/index.jsx";
import { RightButton, StyledModal, FormContainer } from "./styledComponents";
import styled from "styled-components";
import * as Sentry from "@sentry/react";

const StyledRow = styled(Row)`
  .ant-form-item-children-icon {
    right: 35px !important;
  }
`;

function ResetPassword({ id, setPasswordReset }) {
  const history = useHistory();
  const [form] = Form.useForm();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const onFinish = (values) => {
    setButtonLoading(true);
    const payload = {
      newPassword: values.newPassword,
      oldPassword: values.oldPassword,
    };
    changePassword(payload)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 1) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
          form.resetFields();
          setVisible(true);
        } else {
          message.error("Service temporarily unavailable");
        }
        setButtonLoading(false);
      })
      .catch((err) => {
        message.error("Service temporarily unavailable");
        console.log(err);
        Sentry.captureException(err);
        setButtonLoading(false);
      });
  };

  const closeModal = () => {
    setVisible(false);
    if (success) setPasswordReset(false);
    else setPasswordReset(true);
  };

  const modalSubmit = () => {
    setVisible(false);
    if (success) setPasswordReset(false);
    else setPasswordReset(true);
  };

  return (
    <FormContainer className="form-m2-style">
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => onFinish(values)}
        scrollToFirstError={true}
      >
        <StyledRow gutter={[36, 0]}>
          <Col span={12}>
            <Form.Item
              name="oldPassword"
              label="Enter Old Password"
              rules={[
                {
                  required: true,
                  message: "Old password is required",
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <Form.Item
              name="newPassword"
              label="Enter New Password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "New password is required",
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirmPassword"
              label="Confirm New Password"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
          </Col>
          <Col span="24">
            <Form.Item>
              <Row gutter={[12, 12]} justify="end">
                <Col>
                  <RightButton
                    htmlType="submit"
                    type="primary"
                    loading={buttonLoading}
                    className="btn-save"
                  >
                    Change Password
                  </RightButton>
                </Col>
                <Col>
                  <RightButton
                    type="primary"
                    ghost
                    className="btn-cancel"
                    onClick={() => setPasswordReset(false)}
                  >
                    Cancel
                  </RightButton>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </StyledRow>
      </Form>
      <StyledModal
        visible={visible}
        centered={true}
        onCancel={closeModal}
        footer={[
          <Button key="ok" type="primary" onClick={modalSubmit}>
            Done
          </Button>,
        ]}
      >
        {success ? (
          <CustomAlerts
            type={"success"}
            title={"Successfully Password Reset!"}
            description={
              "You can now use your new password to login in to your account"
            }
          />
        ) : (
          <CustomAlerts
            type={"warning"}
            title={"Your password is Incorrect!"}
            description={"Please type your old password correctly"}
          />
        )}
      </StyledModal>
    </FormContainer>
  );
}

export default ResetPassword;
