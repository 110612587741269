import Moment from 'moment-timezone';
import axiosInstance from '../config/axiosInstance';
const APPOINTMENT_PREFIX = '/appointment';

export const getDepartments = (id) => {
  return axiosInstance.get(`/${id}/departments`);
};

export const getNurses = (id) => {
  return axiosInstance.get(`/user/by-user-type?hospital=1&userType=NURSE`);
};

export const getWards = (id) => {
  return axiosInstance.get(`/ward/getWard/${id}`);
};

export const getWardsCategory = () => {
  return axiosInstance.get(`category/fetchWardCategoriesByType/ALL`);
};

export const getWardsCategoryNonOT = () => {
  return axiosInstance.get(`category/fetchWardCategoriesByType/NON-OT`);
};

export const getBeds = (id) => {
  return axiosInstance.get(`/bed/getVaccantBedsInWard/${id}`);
};

// export const getAllBedsInHospital = (id) => {
//   return axiosInstance.get(`/bed/getAllBedsInHospital`);
// };

export const getAllBeds = (id) => {
  return axiosInstance.get(`/bed/getAllBeds/${id}`);
};

export const getAllBedsInHospital = () => {
  return axiosInstance.get(`/bed/getAllBedsInHospital`);
};



export const getDoctors = (departmentId) => {
  return axiosInstance.get(`/departments/${departmentId}`);
};

export const getAllDoctors = (hospitalID) => {
  return axiosInstance.get(`/user/by-user-type?hospital=${hospitalID}&userType=DOCTOR`);
};

export const getAdmitPatient = (d) => {
  return axiosInstance.post(
    `/ipd`, d
  );
};

export const getAvailableTimes = (queryString) => {
  return axiosInstance.get(
    APPOINTMENT_PREFIX + `/available-time?${queryString}`
  );
};

export const getAvailableTimesForLabOrRadiology = (queryString) => {
  return axiosInstance.get(
    APPOINTMENT_PREFIX + `/available-time-lab-or-radiology-user?${queryString}`
  );
};

export const getAppointmentTypes = () => {
  return axiosInstance.get(APPOINTMENT_PREFIX + `/types`);
};

export const scheduleAppointment = (payload) => {
  payload.timeZone = Moment.tz.guess();
  return axiosInstance.post(APPOINTMENT_PREFIX, payload);
};

export const updateAppointment = (payload, appointmentId) => {
  payload.timeZone = Moment.tz.guess();
  return axiosInstance.put(
    APPOINTMENT_PREFIX + `?id=${appointmentId}`,
    payload
  );
};

export const deleteAppointment = (appointmentId) => {
  return axiosInstance.delete(
    APPOINTMENT_PREFIX +
    `/cancel-appointment?appointmentId=${appointmentId}&timeZone=${Moment.tz.guess()}`
  );
};

export const getAppointmentDetails = (appointmentId) => {
  return axiosInstance.get(APPOINTMENT_PREFIX + `/${appointmentId}/details`);
};

export const updateInTime = (appointmentId, inTime) => {
  return axiosInstance.put(
    `${APPOINTMENT_PREFIX}/update-inTime?appointmentId=${appointmentId}&inTime=${inTime}`
  );
};

export const fetchMyAppointments = (queryString) => {
  return axiosInstance.get(
    `${APPOINTMENT_PREFIX}/appointment-search?${queryString}`
  );
};
export const fetchMyMedicalHistory = (queryString) => {
  return axiosInstance.get(
    `${APPOINTMENT_PREFIX}/medical-history?${queryString}`
  );
};

export const moveToQueue = (queryString) => {
  return axiosInstance.put(
    `${APPOINTMENT_PREFIX}/move-to-queue?${queryString}`
  );
};

export const fetchBillingDetails = (appointmentId, patientId) => {
  var searchQuery =
    appointmentId !== null
      ? `appointmentId=${appointmentId}&userId=${patientId}`
      : `&userId=${patientId}`;
  return axiosInstance.get(APPOINTMENT_PREFIX + `/bill-history?${searchQuery}`);
};

export const acceptOrDeclineAppointment = (id, status) => {
  return axiosInstance.post(
    `${APPOINTMENT_PREFIX}/change-status?appointmentId=${id}&status=${status}&timeZone=${Moment.tz.guess()}`
  );
};

export const fetchAppointmentOrders = (appointmentId) => {
  return axiosInstance.get(
    `${APPOINTMENT_PREFIX}/appointment-orders?appointmentId=${appointmentId}`
  );
};

export const confirmLabRadiologyAppointment = (appointmentId) => {
  return axiosInstance.get(
    `${APPOINTMENT_PREFIX}/confirm-lab-radiology-appointment?appointmentId=${appointmentId}&timeZone=${Moment.tz.guess()}`
  );
};

export const saveAppointmentOrders = (data) => {
  return axiosInstance.post(
    `${APPOINTMENT_PREFIX}/save-appointment-orders`,
    data
  );
};
