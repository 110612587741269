export const genderList = () => [
  {
    code: 'F',
    text: 'Female',
  },
  {
    code: 'M',
    text: 'Male',
  },
  {
    code: 'U',
    text: 'Unknown',
  },
  {
    code: 'N',
    text: 'Not Available',
  },
  {
    code: 'O',
    text: 'Other',
  },
];

export const maritalStatus = () => [
  {
    code: 'A',
    text: 'Separated',
  },
  {
    code: 'D',
    text: 'Divorced',
  },
  {
    code: 'M',
    text: 'Married',
  },
  {
    code: 'P',
    text: 'Domestic Partner',
  },
  {
    code: 'S',
    text: 'Single',
  },
  {
    code: 'U',
    text: 'Unknown',
  },
  {
    code: 'W',
    text: 'Widowed',
  },
  {
    code: 'NA',
    text: 'Not Available',
  },
];

export const raceList = () => [
  {
    code: 'NT',
    text: 'National',
  },
  {
    code: 'NN',
    text: 'Non-National',
  },
  {
    code: 'GCC',
    text: 'GCC National',
  },
  {
    code: 'UN',
    text: 'Unknown',
  },
];

export const addressType = () => [
  {
    code: 'O',
    text: 'Office',
  },
  {
    code: 'H',
    text: 'Home',
  },
  {
    code: 'W',
    text: 'Work',
  },
];
export const phoneType = () => [
  {
    code: 'CH',
    text: 'Home',
  },
  {
    code: 'CB',
    text: 'Business',
  },
  {
    code: 'CC',
    text: 'Cellular',
  },
  {
    code: 'CF',
    text: 'Fax',
  },
];

export const emailType = () => [
  {
    code: 'P',
    text: 'Personal',
  },
  {
    code: 'B',
    text: 'Business',
  },

  {
    code: 'S',
    text: 'Social Media',
  },
];

export const vipType = () => [
  {
    code: 'V',
    text: 'Vip',
  },
  {
    code: 'N',
    text: 'None',
  },
];

export const contactRoleType = () => [
  {
    code: 'ER',
    text: 'Emergency',
  },
  {
    code: 'N',
    text: 'Next-of-Kin',
  },
];
export const diagnosisPriority = () => [
  {
    code: '1',
    text: 'Primary Diagnosis',
  },
  {
    code: '2',
    text: 'Secondary Diagnosis',
  },
];
export const diagnosisType = () => [
  {
    code: 'A',
    text: 'Admitting',
  },
  {
    code: 'W',
    text: 'Working',
  },
  {
    code: 'F',
    text: 'Final',
  },
];
export const idType = () => [
  {
    code: '1',
    text: 'Unknown',
  },
  {
    code: '2',
    text: 'Emirates ID',
    pattern: /^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$/,
  },
  {
    code: '7',
    text: 'Iqama ID',
  },
  {
    code: '8',
    text: 'Patient Receipt',
  },
  {
    code: '3',
    text: 'Passport',
  },
  {
    code: '4',
    text: 'Labor card',
    pattern: /^[0-9]{8}$/,
  },
  {
    code: '5',
    text: 'Driving license',
    pattern: /^[0-9]{7}$/,
  },
  {
    code: '6',
    text: 'Non Expat without EID Card',
  },
];
export const allergySeverity = () => [
  {
    code: 'SV',
    text: 'Severe',
  },
  {
    code: 'MO',
    text: 'Moderate',
  },
  {
    code: 'MI',
    text: 'Mild',
  },
  {
    code: 'U',
    text: 'Unknown',
  },
  {
    code: 'NA',
    text: 'Not Available',
  },
];
export const allergyType = () => [
  {
    code: 'DA',
    text: 'Drug allergy',
  },
  {
    code: 'FA',
    text: 'Food allergy',
  },
  {
    code: 'MA',
    text: 'Miscellaneous allergy',
  },
  {
    code: 'MC',
    text: 'Miscellaneous contraindication',
  },
  {
    code: 'EA',
    text: 'Environmental Allergy',
  },
  {
    code: 'AA',
    text: 'Animal Allergy',
  },
  {
    code: 'PA',
    text: 'Plant Allergy',
  },
  {
    code: 'NA',
    text: 'Not Available',
  },
  {
    code: 'LA',
    text: 'Pollen Allergy',
  },
];

export const addressStateType = () => [
  {
    code: '1',
    text: 'Abu Dhabi',
  },
  {
    code: '2',
    text: 'Dubai',
  },
  {
    code: '3',
    text: 'Sharjah',
  },
  {
    code: '4',
    text: 'Ajman',
  },
  {
    code: '5',
    text: 'Ras al Khaimah',
  },
  {
    code: '6',
    text: 'Fujairah',
  },
  {
    code: '7',
    text: 'Um Al Quwain',
  },
  {
    code: '0',
    text: 'Others',
  },
];
