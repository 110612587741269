import React, { useEffect } from "react";
import { Form, message, Button } from "antd";
import FormBuilder from "../../../utils/FormBuilder";
import Moment from "moment";
import { appointmentFormConfig } from "./appointmentFormConfig";
import {
  getAppointmentDetails,
  getAppointmentTypes,
} from "../../../../apis/Appointment";
import { useState } from "react";
import styled from "styled-components";
import IntimeModal from "../../InTimeModal";
import HasPrivilege from "../../../utils/privilegeHandlers/HasPrivilege.jsx";
import Spinner from "../../../utils/Spinner";

const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
  min-width: 100px;
`;

const StyledForm = styled(Form)`
  &
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #6d6d6d;
    background: #fff;
    cursor: not-allowed;
  }

  & .ant-input[disabled] {
    color: #6d6d6d !important;
    background: #fff;
    cursor: not-allowed;
    opacity: 1;
  }
`;

const AppointmentForm = ({ appointmentId }) => {
  const [formInitialValues, setFormInitialValues] = useState({});
  const [formConfig, setFormConfig] = useState(appointmentFormConfig);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [inTimeModalVisible, setIntimeModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAppointmentDetails(appointmentId)
      .then((response) => {
        if (response.data.status === 1) {
          let data = response.data.body;
          data.date = Moment(data.date);
          data.department = data.department.id;
          data.doctor = data.doctor.id;
          data.appointmentType = data.appointmentType.name;
          setFormInitialValues(data);
          setLoading(false);
        } else {
          message.error("Couldnt fetch appointment details, Try again");
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("Couldnt fetch appointment details, Try again");
      });
  }, []);
  return loading ? (
    <Spinner height="400px" />
  ) : (
    <>
      <StyledForm
        className="form-m2-style"
        layout="vertical"
        initialValues={formInitialValues}
        key={Object.keys(formInitialValues).length}
        scrollToFirstError={true}
      >
        <FormBuilder formConfig={formConfig} />
        <HasPrivilege role="NURSE">
          <Form.Item>
            {/* <RightButton size="large" type="primary">
              Call
            </RightButton>
            {!formInitialValues.inTime && (
              <RightButton
                size="large"
                type="primary"
                ghost
                onClick={() => {
                  setIntimeModalVisible(true);
                }}
              >
                In Time
              </RightButton>
            )} */}
          </Form.Item>
        </HasPrivilege>
      </StyledForm>
      <IntimeModal
        visible={inTimeModalVisible}
        setVisible={setIntimeModalVisible}
        id={appointmentId}
      />
    </>
  );
};

export default AppointmentForm;
