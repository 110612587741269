import React, { useEffect, useState } from 'react';
import { Row, Col, Divider } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const StyledH3 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
`;
const ReportView = ({ formFields, fieldData }) => {
  const [newFields, setNewFields] = useState([]);
  useEffect(() => {
    formFields.map((field) => {
      field.fields.map((item) => {
        if (!fieldData[item?.name]) field.formName = undefined;
      });
    });
    setNewFields(formFields);
  }, []);

  const STRING_SEPERATOR = '#$%^&*&^%$';
  const theme = useSelector((state) => state.themeReducer);
  return (
    <Row gutter={[24, 12]}>
      {newFields &&
        newFields.map((form, index) => {
          if (form.formName)
            return (
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    {/* <StyledH3 color={theme["@primary-color"]}>
                      {form.formName}
                    </StyledH3> */}
                  </Col>
                  <Col span={24}>
                    <Row gutter={[12, 12]} align="top">
                      {form.fields.map((field) => {
                        if (fieldData[field?.name])
                          return (
                            <>
                              <Col span={7}>{field?.label}</Col>
                              <Col span={1}>:</Col>
                              <Col span={16}>
                                {Array.isArray(fieldData[field?.name])
                                  ? fieldData[field?.name].join(', ')
                                  : fieldData[field?.name].includes(
                                      STRING_SEPERATOR
                                    )
                                  ? fieldData[field?.name]
                                      .split(STRING_SEPERATOR)
                                      .join(', ')
                                  : fieldData[field?.name]}
                              </Col>
                            </>
                          );
                      })}
                    </Row>
                  </Col>
                </Row>
                {newFields.length !== index + 1 ? (
                  <Divider style={{ margin: '5px 0' }} />
                ) : (
                  <></>
                )}
              </Col>
            );
        })}
    </Row>
  );
};

export default ReportView;
