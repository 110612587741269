import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";

const StyledHeading = styled.h2`
  color: ${(props) => props.color};
  ${(props) => (props.size ? `font-size:${props.size};` : "")}
`;

const LogoContainer = styled.div`
  width: 90%;
  margin: auto;
  transform: scale(${(props) => props.scale});
  & g {
    fill: ${(props) => props.color};
  }
`;

const StyledImage = styled.img`
  height: 90px;
`;

const HospitalLogo = (props) => {
  return (
    <LogoContainer scale={props.scale || 1} color={props.color || "#fff"}>
      <Row align="middle">
        {!props.imageUrl ? (
          <>
            <Col span={6}>{props.icon}</Col>
            <Col span={18}>
              <StyledHeading color={props.color || "#fff"} size={props.size}>
                {props.title}
              </StyledHeading>
            </Col>
          </>
        ) : (
          <Col span={24}>
            <StyledImage src={props.imageUrl} />
          </Col>
        )}
      </Row>
    </LogoContainer>
  );
};
export default HospitalLogo;
