import React, { useState } from "react";
import {
  Table,
  Row,
  Col,
  message,
  Button,
  Image,
  Upload,
  Tooltip,
  List,
  Typography,
} from "antd";
import * as Sentry from "@sentry/react";
import { idType } from "../../Datasets/basicDetails";
import { uploadImage } from "../../../apis/uploadImages";
import {
  deleteUploadedFile,
  uploadPdfOrImage,
} from "../../../apis/uploadPdfOrImage";
import { ConsoleSqlOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  CloseCircleOutlined,
  FileOutlined,
  CloseOutlined,
  FileImageOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { PROFILE_IMAGE_PREFIX } from "../../../constants/StringConstants";
import styled from "styled-components";
import Spinner from "../../utils/Spinner";
import CustomModal from "../../utils/CustomModal.jsx";
import Zoom, { Controlled } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
const { Text } = Typography;

const StyledUploadDragger = styled(Upload.Dragger)`
  &.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
    float: left;
    width: 200px;
  }
`;

const ImageContainer = styled.div`
  width: 240px;
  min-height: 162px;
  background-color: ${(props) => props.color};
  border-radius: 10%;
  background-image: url("${(props) => props.bgImage}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: smaller;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: rgba(0, 0, 0, 0);
  margin: 10px 15px 10px 0;
  flex-direction: column;
  z-index: 1;

  &:hover {
    cursor: pointer;
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
      url(${(props) => props.bgImage});
    color: white;
  }
`;

const DeleteButton = styled(Button)`
  &.ant-btn-circle,
  .ant-btn-circle-outline {
    max-width: 15px;
  }
  &.ant-btn-circle-outline {
    max-width: 15px;
  }
  &.ant-button-primary {
    max-width: 15px;
  }
`;

const StyledImage = styled(Image)``;

const StyledDiv = styled.div`
  z-index: 2;
  span:active {
    z-index: 2;
  }
  span {
    z-index: 2;
  }
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  justify-content: center;
  align-items: center;
  margin: 5px 5px 0 0;
  display: flex;

  &:hover {
    background-color: ${(props) => props.bgColor};
  }
`;

export const DocumentUpload = ({
  value,
  onChange,
  selectedItem,
  deleteSelected,
  validFormats,
  isMultiple,
  setIsSaveChanges,
}) => {
  const [documentUrl, setDocumentUrl] = useState();
  const [singleFile, setSingleFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [docName, setDocName] = useState("");
  const [fileName, setFileName] = useState("");
  const [columnName, setColumnName] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState();
  const theme = useSelector((state) => state.themeReducer);
  const [isZoomed, setIsZoomed] = useState(false);

  const prop = {
    name: "file",
    multiple: isMultiple,
    showUploadList: false,
    beforeUpload: (file, fileList) => {
      setUploading(true);
      console.log("entered into prop in doc", file);
      //Need to  uncomment the code when BE accepts pdf
      var isJpgOrPng = false;
      if (validFormats) {
        validFormats.forEach((item) => {
          if (file.type.split("/")[1] === item) isJpgOrPng = true;
        });
      } else
        isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "application/jpg" ||
          file.type === "application/pdf";
      if (!isJpgOrPng) {
        setUploading(false);
        message.destroy();
        message.error(
          `You can only upload ${validFormats
            .map((item) => " ." + item)
            .toString()} file!`
        );
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        setUploading(false);
        message.destroy();
        message.error("File must smaller than 5MB!");
      }
      if (isJpgOrPng && isLt2M) {
        setSingleFile(true);
        setSelectedFile(file);
        setFileName(file.name);
        handleDocumentUpload(file, fileList);
      } else {
        setSingleFile(false);
        // setUploading(false);
      }
      // setUploading(false)
    },
    // accept: ".jpg,.png,.jpeg",
  };
  const uploadDocument = (e) => {};
  const handleDocumentUpload = (file, fileList) => {
    const promises = [];
    var index = 0;
    // var temp = []
    uploadPdfOrImage(null, file)
      // Promise.all(promises)
      .then((doc) => {
        if (doc.status === 200) {
          if (doc.data.status === 1) {
            var tempData = {
              documentUrl: doc?.data?.body?.documentUrl,
              fileName: doc?.data?.body?.fileName,
            };
            var temp = [...files, tempData];
            setUploading(false);
            setFiles(temp);
            onChange(temp);
            setIsSaveChanges(true);
            // setDocName(doc[index].data.body);
            console.log("handle document upload in doc component", "name", doc);
            // onChange(temp);
          }
          setUploading(false);
        }
        setUploading(false);
        index = index + 1;
      });
    console.log(files);
    console.log("");
  };
  const fetchDocName = () => {
    var documentName = docName;

    selectedItem &&
      idType().map((item) => {
        if (selectedItem.documentType === item.code) {
          documentName = item.text;
        }
      });

    return documentName;
    setDocName(documentName);
  };
  const clearDoc = (e) => {
    var temp = [];
    if (e) {
      files.map((item) => {
        if (item?.documentUrl !== e?.documentUrl) {
          temp = temp.concat(item);
        } else if (!deleteSelected) {
          deleteUploadedFile(item?.documentUrl)
            .then((res) => {
              if (res.status === 200) {
                if (res.data.status === 1) {
                  console.log(res.data);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              Sentry.captureException(err);
            });
        }
      });
    }
    setFiles(temp);
    onChange(temp);
    setIsSaveChanges(true);
  };

  const mouseEnter = (e) => {
    setDeleteVisible(true);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    // setUploading(true)
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    setColumnName([
      {
        render: (row, files) => {
          return (
            <Tooltip title="View document">
              <Image
                bgImage={files ? PROFILE_IMAGE_PREFIX + files.documentUrl : ""}
                src={PROFILE_IMAGE_PREFIX + files.documentUrl}
                width={200}
                preview={true}
                placeholder={
                  <Image
                    preview={false}
                    src={PROFILE_IMAGE_PREFIX + files.documentUrl}
                    width={200}
                  />
                }
              />
            </Tooltip>
          );
        },
      },
      {
        render: (row, files) => {
          return (
            <Tooltip title="Delete document">
              <Button
                shape="circle"
                type="link"
                danger
                icon={<CloseCircleOutlined style={{ color: "grey" }} />}
                onClick={() => clearDoc(row, files)}
              />
            </Tooltip>
          );
        },
      },
    ]);
  }, [files, selectedItem]);

  useEffect(() => {
    console.log(value);
    console.log("selected item in doc upload", selectedItem);

    if (selectedItem) {
      if (selectedItem.documentUrls && selectedItem.documentUrls.length > 0) {
        var temp = selectedItem.documentUrls;
        setFiles(temp);
        console.log(files);
        console.log(selectedItem.documentUrls);
      }
    }
  }, [selectedItem]);

  return (
    <div onChange={(e) => uploadDocument(e)}>
      <Row>
        <Col span={24}>
          <Upload.Dragger
            {...prop}
            disabled={uploading || (!isMultiple && files.length == 1)}
          >
            {uploading ? (
              <Spinner height={"100px"} />
            ) : (
              <>
                <p
                  className="ant-upload-hint"
                  style={{ fontSize: "75px", color: "rgba(0, 0, 0, 0.25)" }}
                >
                  <CloudUploadOutlined />
                </p>
                <Text
                  className="ant-upload-text"
                  style={{ color: "rgba(0, 0, 0, 0.25)", fontWeight: "bold" }}
                >
                  Drag and Drop or
                  <Text
                    style={{
                      color: theme["@primary-color"],
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Browse{" "}
                  </Text>
                  {isMultiple ? "multiple files" : "one file"}
                </Text>
                <br />
                <Text
                  className="ant-upload-text"
                  style={{ color: theme["@primary-color"], fontWeight: "bold" }}
                >
                  Supporting file formats:{" "}
                  {validFormats
                    ? validFormats.map((item) => " ." + item).toString()
                    : ".jpg, .jpeg, .png, .pdf"}{" "}
                  with max file size of 5MB each
                </Text>
              </>
            )}
          </Upload.Dragger>
        </Col>
      </Row>
      <List
        headBg={theme["@primary-color"]}
        // columns={columnName}
        dataSource={files}
        renderItem={(item) => (
          <List.Item>
            <Row>
              {item?.documentUrl?.slice(-3) == "pdf" ? (
                <Tooltip title={item?.fileName}>
                  <ImageContainer style={{ border: "1px solid grey" }}>
                    <StyledDiv onClick={() => clearDoc(item, files)}>
                      <Tooltip title="Delete document">
                        <StyledDeleteOutlined
                          size="small"
                          bgColor={theme["@primary-color"]}
                        />
                      </Tooltip>
                    </StyledDiv>
                    <Text
                      href={
                        item ? PROFILE_IMAGE_PREFIX + item?.documentUrl : ""
                      }
                      style={{ width: "220px", wordBreak: "break-all" }}
                    >
                      <FileOutlined
                        width={"30px"}
                        style={{
                          color: "rgba(0,0,0,0.25)",
                          fontSize: "20px",
                        }}
                      />
                      &nbsp;&nbsp;{item?.fileName}
                    </Text>
                    <span></span>
                  </ImageContainer>
                </Tooltip>
              ) : (
                <ImageContainer
                  bgImage={item ? PROFILE_IMAGE_PREFIX + item?.documentUrl : ""}
                >
                  <StyledDiv onClick={() => clearDoc(item, files)}>
                    <Tooltip title="Delete document">
                      <StyledDeleteOutlined
                        size="small"
                        bgColor={theme["@primary-color"]}
                      />
                    </Tooltip>
                  </StyledDiv>
                  <div
                    title={item?.fileName}
                    value={item?.documentUrl}
                    style={{ display: "flex", width: "220px" }}
                    onClick={() => {
                      setVisible(true);
                      setImage(
                        item ? PROFILE_IMAGE_PREFIX + item?.documentUrl : ""
                      );
                    }}
                  >
                    <p value={item?.documentUrl?.documentUrl}>
                      {item?.fileName}
                    </p>
                  </div>
                </ImageContainer>
              )}
            </Row>
          </List.Item>
        )}
        grid={{ gutter: 16, column: 4 }}
        showHeader={false}
        pagination={false}
        style={{ margin: "10px 0" }}
      />
      {/* <Image
        src={selectedItem ? PROFILE_IMAGE_PREFIX + selectedItem.documentUrl : ""}
        alt="haha"
      /> */}
      {/* <CustomModal visible={visible} setVisible={setVisible} isClosable={true}> */}
      <div style={{ display: visible ? "flex" : "none" }}>
        <Controlled
          isZoomed={visible}
          onZoomChange={setVisible}
          zoomMargin={20}
        >
          {" "}
          <StyledImage src={image} width="100%" preview={false} />{" "}
        </Controlled>
      </div>
      {/* </CustomModal> */}
    </div>
  );
};
