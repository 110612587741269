import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button } from "antd";
import styled from "styled-components";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";

const ModalBodyContainer = styled.div`
  width: 500px;
`;
const ConfirmationModal = ({
  visible,
  setVisible,
  deleteHl7Listener,
  loading,
}) => {
  const onConfirmHandler = () => {
    deleteHl7Listener();
  };

  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <CustomAlerts
              type="delete"
              title="Delete this Listener ?"
              description={`This action will permanently delete the Listener.`}
            />
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVisible(false);
              }}
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                onConfirmHandler();
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default ConfirmationModal;
