import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PatientProfile from "./PatientProfile";
import UserProfile from "./UserProfile";

const Profile = () => {
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {}, [user]);

  return user.userType === "PATIENT" ? (
    <PatientProfile user={user} />
  ) : (
    <UserProfile user={user} />
    
  );
};
export default Profile;
