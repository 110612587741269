import React, { useState, useEffect } from "react";
import { Row, Col, message, Button, notification } from "antd";
import {
  SectionHeading,
  SectionHeadContainer,
  StyledDiv,
} from "../styledComponents";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
// import { BodyContainer } from "../Sections/styledComponents";
import HL7Table from "./HL7Table";
import { fetchHL7ManageStatus, HL7Resend } from "../../../../apis/Hl7";
import { statusColumns } from "./statusColumns";
import styled from "styled-components";

const StyledButton = styled(Button)`
  border-radius: 5px !important;
  padding: 0 15px;
  height: 35px;
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    background: ${(props) => props.color};
    color: white;
  }
`;

const BodyContainer = styled.div`
  background: #fff;
  padding: 20px 0;
  min-height: calc(100vh - 200px);
  border-radius: 10px;
  margin-bottom: 20px;

  & .ant-pagination {
    margin-right: 20px;
  }
`;

const HL7Status = () => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [statusDataLoading, setStatusDataLoading] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [statusDataTotal, setStatusDataTotal] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [params, setParams] = useState("");
  const [resendIds, setResendIds] = useState();
  const theme = useSelector((state) => state.themeReducer);

  const getHL7Status = (searchQuery) => {
    fetchHL7ManageStatus(searchQuery)
      .then((res) => {
        if (res.status == 200) {
          setStatusData(res.data.body);
          setStatusDataTotal(res.data.total);
        }
        setStatusDataLoading(false);
      })
      .catch((err) => {
        setStatusDataLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };
  const resendHL7 = (id) => {
    HL7Resend(id)
      .then((res) => {
        if (res.status == 200) {
          setResendIds();
          let resendStatus = res.data.body.map((item) => item.status);
          if (resendStatus == "Success")
            notification.success({
              message: "HL7 Resent",
              description: "Status: Passed",
            });
          else
            notification.error({
              message: "HL7 Resent",
              description: `${resendStatus ? `Status: ${resendStatus}` : ""}`,
            });
          setStatusDataLoading(true);
          getHL7Status(params);
        }
      })
      .catch((err) => {
        setResendIds();
        message.error("HL7 Resend failed");
        console.log(err);
        Sentry.captureException(err);
      });
  };
  const onClear = () => {
    setPageNo(0);
    setStatusFilter("");
  };

  useEffect(() => {
    setStatusDataLoading(true);
    if (statusFilter) {
      setParams(`pageNo=${pageNo}&size=${pageSize}&status=${statusFilter}`);
    } else setParams(`pageNo=${pageNo}&size=${pageSize}`);
    var searchQuery = statusFilter
      ? `pageNo=${pageNo}&size=${pageSize}&status=${statusFilter}`
      : `pageNo=${pageNo}&size=${pageSize}`;
    getHL7Status(searchQuery);
  }, [pageNo, statusFilter]);

  useEffect(() => {
    if (resendIds !== undefined) {
      console.log(resendIds);
      resendHL7(resendIds);
    }
  }, [resendIds]);
  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading level={3}>HL7 Status</SectionHeading>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <HL7Table
            columns={statusColumns(
              pageNo,
              pageSize,
              StyledButton,
              theme,
              setResendIds
            )}
            loading={statusDataLoading}
            dataSource={statusData}
            pageSize={pageSize}
            setPageNo={setPageNo}
            pageNo={pageNo}
            total={statusDataTotal}
            setStatusFilter={setStatusFilter}
            onClear={onClear}
          />
        </BodyContainer>
      </Col>
    </Row>
  );
};

export default HL7Status;
