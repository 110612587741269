import React from "react";
import styled from "styled-components";
import { Button } from "antd";
const StyledButton = styled(Button)`
  && {
    width: 100%;
    background: ${(props) => props.bgColor};
    color: ${(props) => props.labelColor};
    font-weight: bolder;
    border: none;
  }
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    background: #20598c;
  }

  border-radius: 4px;
`;
const FullWidthButton = (props) => {
  return (
    <StyledButton
      bgColor={props.bgColor || "#0071bc"}
      {...props}
      labelColor={props.labelColor || "white"}
    >
      {props.children}
    </StyledButton>
  );
};
export default FullWidthButton;
