import React from "react";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/DeleteIcon.svg";
export const columns = (setEditModal, setDeleteModal, setselectedItem) => [
  {
    width: "1vw",
  },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
    width: "4vw",
  },
  { title: "Ward Category Name", width: "30vw", dataIndex: "categoryName" },
  {
    title: "Is OT", width: "4vw",  dataIndex: "isOT", render: (text, record) => {
      // You can customize the rendering if needed
      return text ? "Yes" : "No";
    },
  },
  {
    title: "Actions",
    width: "8vw",
    render: (record) => (
      <Row gutter={12}>
        <Col>
          {" "}
          <Icon
            style={{ fontSize: "20px" }}
            component={EditIcon}
            onClick={() => {
              setselectedItem(record);
              setEditModal(true);
            }}
          />
        </Col>
        <Col>
          <Icon
            style={{ fontSize: "22px" }}
            component={DeleteIcon}
            onClick={() => {
              setselectedItem(record);
              setDeleteModal(true);
            }}
          />
        </Col>
      </Row>
    ),
  },
];
