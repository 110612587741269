import { Col, Row } from "antd";
import React from "react";
const ObservationView = ({ data }) => {
  return (
    <Row gutter={[12, 12]}>
      {Object.entries(data).map(([key, value]) => {
        return (
          <>
            <Col span={5}>{key}</Col>
            <Col span={1}>:</Col>
            <Col span={18}>{value}</Col>
          </>
        );
      })}
    </Row>
  );
};
export default ObservationView;
