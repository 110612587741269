import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Radio, Button, message, Select } from 'antd';
import CustomModal from '../../../utils/CustomModal.jsx/index.jsx';
import {
  ModalBodyContainer,
  StyledTitle,
  StyledForm,
  StyledIcon,
} from './styledComponents';

import { addObervationForms } from '../../../../apis/healthRecord.js';
import { getDepartments as fetchDepartments } from '../../../../apis/Appointment';
import { CaretDownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Spinner from '../../../utils/Spinner/index.jsx';
import { getHospitalId } from '../../../utils/LocalStorage/index.js';

const ModalBody = ({
  setVisible,
  selectedSection,
  refetch,
  totalForms,
  selectedForm,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);

  const getDepartments = () => {
    fetchDepartments(getHospitalId())
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) {
            setDepartments(data.body);
            if (selectedForm)
              form.setFieldsValue({
                ...selectedForm,
                name: selectedForm.formName,
                departmentIds: selectedForm.departments.map((item) => item.id),
              });
          }
        } else {
          message.error('Unable to fetch departments');
        }
      })
      .catch(() => console.log)
      .finally(() => {
        setFormLoading(false);
      });
  };

  const addNew = (data) => {
    setLoading(true);
    addObervationForms(data)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) {
            message.success(
              !selectedForm ? 'Form Created Successfully' : 'Form Updated!'
            );
            setVisible(false);
            refetch();
          } else {
            message.error(data.body);
          }
          //   setSelectedItem(undefined);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onFinish = (values) => {
    values.editable = true;
    if (
      !values.order &&
      values.order != 0 &&
      !selectedForm?.formOrder &&
      selectedForm?.formOrder != 0
    )
      values.order = totalForms + 1;
    else if (selectedForm?.formOrder || selectedForm?.formOrder == 0)
      values.order = selectedForm?.formOrder;
    if (selectedForm) {
      values.observationFormId = selectedForm?.id;
    }
    console.log('values here ', values);
    addNew(values);
  };

  useEffect(() => {
    setFormLoading(true);
    getDepartments();
  }, []);
  return (
    <ModalBodyContainer>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <StyledTitle level={3} color={theme['@primary-color']}>
            {!selectedForm ? 'Create New Form' : 'Update Form'}
          </StyledTitle>
        </Col>
        <Col span={24}>
          {formLoading ? (
            <Spinner height={'220px'} />
          ) : (
            <StyledForm
              name="admin-login-form"
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              scrollToFirstError={true}
              layout="vertical"
              size="large"
              wrapperCol={{ gutter: 36 }}
              form={form}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Form name is Required !',
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="Department"
                name="departmentIds"
                rules={[
                  {
                    required: true,
                    message: 'Form name is Required !',
                  },
                ]}
              >
                <Select
                  placeholder="Department"
                  size="large"
                  mode="multiple"
                  showSearch
                  suffixIcon={
                    <CaretDownOutlined style={{ color: '#929292' }} />
                  }
                >
                  {departments
                    ? departments.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.departmentName}
                        </Select.Option>
                      ))
                    : []}
                </Select>
              </Form.Item>
              <Form.Item>
                <Row gutter={12} justify="end">
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      ghost
                      onClick={() => {
                        setVisible(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      loading={loading}
                      htmlType="submit"
                    >
                      {!selectedForm ? 'Create' : 'Update'}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </StyledForm>
          )}
        </Col>
      </Row>
    </ModalBodyContainer>
  );
};

const NewFormModal = ({
  visible,
  setVisible,
  selectedSection,
  totalForms,
  selectedForm,
  refetch,
}) => {
  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBody
        setVisible={setVisible}
        selectedSection={selectedSection}
        totalForms={totalForms}
        refetch={refetch}
        selectedForm={selectedForm}
      />
    </CustomModal>
  );
};
export default NewFormModal;
