import React from 'react';
import { Table as AntdTable, Button, Empty, Row } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import Spinner from '../utils/Spinner';

const Table = styled(AntdTable)`
  & .ant-table-thead > tr > th {
    color: ${(props) => props.headColor};
    font-weight: 600;
    text-align: left;
    background: #ffffff;
    border-bottom: 2px solid #dedede;
  }

  & .ant-table-tbody > tr > td {
    border-bottom: 2px solid #f0f0f0;
  }
  & .ant-table-cell {
    text-transform: capitalize;
  }
`;

const EmptyMessageContainer = styled.div`
  /* height: calc(100vh - 450px); */
  min-height: 250px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
  @media (max-width: 480px) {
    min-height: 250px;
  }
`;
const EmptyMessage = styled.p`
  font-weight: 600;
`;

const EmptyTable = (
  <EmptyMessageContainer>
    <EmptyMessage>No Records</EmptyMessage>
  </EmptyMessageContainer>
);
const PatientsTable = ({
  columns,
  dataSource,
  loading,
  pageSize,
  setPageNo,
  pageNo,
  total,

  onRow,
}) => {
  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button>Previous</Button>;
    }
    if (type === 'next') {
      return <Button>Next</Button>;
    }
    return originalElement;
  };

  const theme = useSelector((state) => state.themeReducer);
  const [pageCount, setPageCount] = useState(pageNo + 1);
  useEffect(() => {
    setPageCount(pageNo + 1);
  }, [pageNo]);
  return (
    <>
      {!loading ? (
        <>
          {dataSource.length > 0 ? (
            <>
              <Table
                headColor={theme['@primary-color']}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                scroll={{ x: 'max-content' }}
                pagination={{
                  onChange: (cur) => {
                    setPageNo(cur - 1);
                    setPageCount(cur);
                  },
                  pageSize: pageSize,
                  total: total,
                  current: pageCount,
                  showSizeChanger: false,
                  itemRender: itemRender,
                }}
                locale={{
                  emptyText: (
                    <EmptyMessageContainer>
                      <Empty />
                    </EmptyMessageContainer>
                  ),
                }}
                onRow={onRow}
              />
              {dataSource?.length < 3 && (
                <Row
                  style={{
                    height: `${(3 - dataSource.length) * 45}px`,
                    width: '20px',
                  }}
                ></Row>
              )}
            </>
          ) : (
            <EmptyMessageContainer>
              <Empty />
            </EmptyMessageContainer>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default PatientsTable;
