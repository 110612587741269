import axiosInstance from "../config/axiosInstance";
const INSURANCE_PREFIX = "/insurance";

export const fetchInsuranceProviders = (visitType) => {
  return axiosInstance.get(
    `${INSURANCE_PREFIX}/insurance-provider${visitType ? `?visitType=${visitType}` : ""
    }`
  );
};

export const fetchAllInsurance = (searchQuery) => {
  return axiosInstance.get(`${INSURANCE_PREFIX}?${searchQuery}`);
};

export const fetchPreApprovalInsurance = (searchQuery) => {
  return axiosInstance.get(
    `${INSURANCE_PREFIX}/pending-pre-approval?${searchQuery}`
  );
};

export const fetchClaimPendingInsurance = (searchQuery) => {
  return axiosInstance.get(`${INSURANCE_PREFIX}/pending?${searchQuery}`);
};

export const fetchHistoryInsurance = (searchQuery) => {
  return axiosInstance.get(`${INSURANCE_PREFIX}/completed?${searchQuery}`);
};

export const fetchInsuranceBilling = (insuranceRequestId, appointmentId) => {
  return axiosInstance.get(
    `${INSURANCE_PREFIX}/billing?insuranceRequestId=${insuranceRequestId}${appointmentId ? '&appointmentId=' + appointmentId : ''}`
  );
};

export const submitInsuranceClaim = (
  insuranceRequestId,
  remainingAmount,
  totalAmount
) => {
  return axiosInstance.post(
    `${INSURANCE_PREFIX}/submit-claim?insuranceRequestId=${insuranceRequestId}&remainingAmount=${remainingAmount}&totalAmount=${totalAmount}`
  );
};

export const changeInsuranceStatus = (payload) => {
  return axiosInstance.post(`${INSURANCE_PREFIX}/change-status`, payload);
};

export const deleteInsuranceProvider = (id) => {
  return axiosInstance.delete(
    `${INSURANCE_PREFIX}/insurance-provider?id=${id}`
  );
};

export const deleteInsurancePlanById = (id) => {
  return axiosInstance.delete(`${INSURANCE_PREFIX}/insurance-plan?id=${id}`);
};

export const addInsuranceProvider = (data) => {
  return axiosInstance.post(`${INSURANCE_PREFIX}/insurance-provider`, data);
};

export const editInsuranceProvider = (id, data) => {
  return axiosInstance.put(
    `${INSURANCE_PREFIX}/insurance-provider?id=${id}`,
    data
  );
};

export const exportHistory = (queryString) => {
  return axiosInstance.get(
    `${INSURANCE_PREFIX}/completed-download?${queryString}`,
    {
      headers: { "Content-Type": "application/octet-stream" },
      responseType: "blob",
    }
  );
};

export const preApproveOrders = (data) => {
  return axiosInstance.post(`${INSURANCE_PREFIX}/pre-approve-orders`, data);
};
export const submitPreApprovalRequest = (appointmentId, insuranceRequestId) => {
  return axiosInstance.get(`${INSURANCE_PREFIX}/submit-pre-approval-request?appointmentId=${appointmentId}&insuranceRequestId=${insuranceRequestId}`);
};