import React, { useState, useEffect, useCallback } from 'react';
import CustomModal from '../../../utils/CustomModal.jsx';
import styled from 'styled-components';
import { Row, Col, Tag, Button, List, message, Checkbox, Input } from 'antd';
import {
  SearchOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { getChiefComplaints } from '../../../../apis/healthRecord';
import { useDebounce } from '../ConcentForm/helper.js';
import { useSelector } from 'react-redux';

const StyledSearch = styled(Input)`
  /* && {
    border-radius: 10px;
    background: #f2f2f2;
  } */
  & .ant-input-group-addon {
    border-radius: 0px;
    background: #f2f2f2;
    padding-left: 31px;
    color: #aeaeae;
    font-size: 15px;
  }

  & .ant-input {
    border-radius: 0px;
    background: #f2f2f2;
    border-left: 0;
  }
`;
const ModalBodyContainer = styled.div`
  width: 650px;
  @media (max-width: 767px) {
    width: unset;
  }
`;

const ModalHead = styled.h3`
  font-weight: 680;
`;
const ListContainer = styled.div`
  height: 300px;
  overflow-y: scroll;
`;

const TagContainer = styled.div`
  padding: 20px;
`;

const BorderedRow = styled(Row)`
  border: 1px solid #d2d2d2;
`;
const BorderedCol = styled(Col)`
  border-right: 1px solid #d2d2d2;
`;

const StyledTag = styled(Tag)`
  background: #e9effd;
  margin-bottom: 10px;
  padding: 4px 7px;
  font-size: 14px;
  & .ant-tag-close-icon {
    font-size: 17px;
    margin-left: 11px;
  }
  border: none;
`;
const StyledChcekBox = styled(Checkbox)`
  margin-left: 20px;
  & .ant-checkbox-inner {
    border-color: ${(props) => props.color};
  }
`;
const ButtonContainer = styled.div`
  border: 1px solid #d2d2d2;
  padding: 20px;
`;

const ChiefComplaintModal = ({
  visible,
  setVisible,
  selected,
  setSelected,
  selectedComplaintsName,
  formInstance,
}) => {
  console.log('form instanc', formInstance);
  const [chiefComplaints, setChiefComplaints] = useState([]);
  const [cachedSelected, setCachedSelected] = useState(selected);
  const [loading, setLoading] = useState(false);
  const [prevSelected, setPrevSelected] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const onChangeHandler = (value) => {
    setSearchParam(value);
  };
  console.log('prev selected value', prevSelected);
  const AddItem = ({ formInstance, fieldId }) => {
    console.log('field id', fieldId);
    const [val, setVal] = useState('');

    return (
      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
        <Input
          size="small"
          value={val}
          onChange={(e) => {
            setVal(e.target.value);
          }}
        />
        <a
          style={{
            flex: 'none',
            padding: '8px',
            display: 'block',
            cursor: 'pointer',
          }}
          onClick={() => {
            formInstance.setFieldsValue({ [fieldId]: val });
            if (val !== '') {
              setPrevSelected((prevValue) => [...prevValue, { name: val }]);
            }
          }}
        >
          <PlusOutlined /> Add
        </a>
      </div>
    );
  };

  const debounceOnChange = useCallback(useDebounce(onChangeHandler, 1000), []);
  const theme = useSelector((state) => state.themeReducer);
  const fetchChiefComplaints = () => {
    setLoading(true);
    getChiefComplaints(searchParam)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            setChiefComplaints(data.body);
          } else message.error('Unable to fetch complaints');
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const ontagClose = (name) => {
    setSelected(selected.filter((item) => item.name != name));
    setPrevSelected(prevSelected.filter((item) => item.name != name));
  };

  const onSubmit = () => {
    setSelected(prevSelected);
    setVisible(false);
  };
  const onCancel = () => {
    setPrevSelected(selected);
    setVisible(false);
  };

  useEffect(() => {
    fetchChiefComplaints();
  }, [searchParam]);
  useEffect(() => {
    setSelected(
      chiefComplaints.filter((item) => {
        return selectedComplaintsName.includes(item.name);
      })
    );
  }, [selectedComplaintsName, chiefComplaints]);
  useEffect(() => {
    if (prevSelected.length === 0) setPrevSelected(selected);
  }, [selected]);
  return (
    <CustomModal visible={visible} setVisible={setVisible}>
      <ModalBodyContainer>
        <Row>
          <Col span={24}>
            <ModalHead>Add Chief Complaints</ModalHead>
          </Col>
          <Col span={24}>
            <BorderedRow>
              <BorderedCol span={11}>
                <Row>
                  <Col span={24}>
                    <StyledSearch
                      addonBefore={<SearchOutlined />}
                      placeholder="Search"
                      onChange={(e) => {
                        debounceOnChange(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter');
                        setSearchParam(e.target.value);
                      }}
                    />
                    <AddItem formInstance={formInstance} />
                  </Col>
                </Row>
                <ListContainer>
                  <List
                    loading={loading}
                    dataSource={chiefComplaints}
                    // bordered
                    renderItem={(item) => {
                      return listItem(
                        item,
                        selected,
                        setSelected,
                        prevSelected.some((one) => {
                          return one.id === item.id;
                        }),
                        theme,
                        prevSelected,
                        setPrevSelected
                      );
                    }}
                  ></List>
                </ListContainer>
              </BorderedCol>
              <Col span={13}>
                <Row style={{ height: '100%' }}>
                  <Col span={24}>
                    <TagContainer>
                      {prevSelected.map((item) => (
                        <StyledTag
                          siz="large"
                          closable
                          closeIcon={<CloseCircleOutlined />}
                          key={item.id}
                          onClose={() => ontagClose(item.name)}
                        >
                          {item.name}
                        </StyledTag>
                      ))}
                    </TagContainer>
                  </Col>
                  <Col span={24}>
                    <Row style={{ height: '100%' }} align="bottom">
                      <Col span={24}>
                        <ButtonContainer>
                          <Row gutter={12} justify="end">
                            <Col>
                              <Button type="primary" ghost onClick={onCancel}>
                                Cancel
                              </Button>
                            </Col>
                            <Col>
                              <Button type="primary" onClick={onSubmit}>
                                Submit
                              </Button>
                            </Col>
                          </Row>
                        </ButtonContainer>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </BorderedRow>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};

const listItem = (
  item,
  selected,
  setSelected,
  checked,
  theme,
  prevSelected,
  setPrevSelected
) => {
  return (
    <List.Item>
      <StyledChcekBox
        size="large"
        checked={checked}
        onChange={(e) => {
          if (e.target.checked) {
            setPrevSelected([...prevSelected, item]);
          } else {
            setPrevSelected(
              prevSelected.filter((selectedItem) => selectedItem.id !== item.id)
            );

            setSelected(
              selected.filter((selectedItem) => selectedItem.id !== item.id)
            );
          }
        }}
        key={item.id}
        color={theme['@primary-color']}
      >
        {item.name}
      </StyledChcekBox>
    </List.Item>
  );
};

export default ChiefComplaintModal;
