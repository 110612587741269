import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import {
  Row,
  Col,
  Button,
  message,
  Form,
  Popconfirm,
  Checkbox,
  Modal,
  Tooltip,
} from "antd";
import Icon, {
  CloseCircleOutlined,
  DownloadOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import Spinner from "../../../utils/Spinner";
import { getHealthRecordForms } from "../../../../apis/healthRecord";
import {
  fetchFormFieldMeta,
  fetchRadiologTest,
  previousRadiologyRequest,
  saveRadiologyTests,
} from "../../../../apis/forms";
import FormBuilder from "../../../utils/FormBuilder";
import { generateFormFields } from "../../../utils/FormBuilder/valuesSerialisation";
import NoRecords from "../../../utils/NoRecord";
import TableContainer from "../TableContainer";
import {
  columns,
  previousDataColumns,
  tableColumns,
  tableColumnsPrint,
} from "./columns";
import CustomeTableForRquests from "../Laboratory/customTable";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";
import { ReactComponent as ViewDoc } from "../../../../assets/icons/viewdoc.svg";
import { ReactComponent as DownloadOutside } from "../../../../assets/icons/downloadoutside.svg";
import FileViewModal from "./fileViewModal";
import CustomModalForRetest from "../Laboratory/customModalForRetest";
import { DiscardSubmitWrapper } from "../VitalInformation";
import { ButtonContainer } from "../styledComponents";
import { getAppointmentDetails } from "../../../../apis/Appointment";
import ViewDetailsModal from "../../LaboratoryDashboard/ViewDetailsModal";

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
  border-radius: 6px !important;
`;

const StyledIcon = styled(Icon)`
  & .cls-1 {
    fill: rgba(0, 0, 0, 0.25);
  }
`;

const Radiology = () => {
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const { sectionId } = useParams();
  const patient = useSelector((state) => state.patientReducer);
  const [tableData, setTableData] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState();
  const [forms, setForms] = useState([]);
  const theme = useSelector((state) => state.themeReducer);
  const [formIds, setFormIds] = useState({});
  const [preSubmitTableData, setPreSubmitTableData] = useState([]);
  const [preSubmitTableColumns, setPreSubmitTableColumns] = useState(columns);
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [subFormData, setSubFormData] = useState([]);
  const [commonTestDropDownValues, setCommonTestDropDownValues] = useState([]);
  const [commontTestField, setCommonTestField] = useState({});
  const [testTypeField, setTestTypeField] = useState({});
  const [testTypeDropDownValues, setTestTypeDropDownValues] = useState([]);
  const [checkGroupValues, setCheckGroupValues] = useState([]);
  const [selectedLength, setSelectedLength] = useState({});
  const user = useSelector((state) => state.userReducer);
  const [form] = Form.useForm();
  const [retestList, setRetestList] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [fileViewModal, setFileViewModal] = useState(false);
  const [documents, setDocuments] = useState();
  const [retestModal, setRetestModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState();
  const [selectedDetails, setSelectedDetails] = useState({});
  const [visible, setVisible] = useState(false);
  const [isCustomModalForRetestLoading, setIsCustomModalForRetestLoading] =
    useState(true);
  const [mainTableLoading, setMainTableLoading] = useState(true);
  const [printData, setPrintData] = useState();
  const [downloadLoading, setDownloadLoading] = useState(false);

  const submitFormData = () => {
    let testsObject = {};
    preSubmitTableData.forEach((item) => {
      testsObject[item.id] = [
        ...(testsObject[item.id] ? testsObject[item.id] : []),
        { id: item.itemId },
      ];
    });

    let request = {
      userId: patient.pId,
      tests: Object.entries(testsObject).map(([key, value]) => {
        return { testTypeId: key, tests: value };
      }),
      appointmentId: patient.appointmentId,
    };
    saveRadiologyTests(request)
      .then(({ data, status }) => {
        console.log("response",data)
        if (status == 200)
          if (data.status == 1) {
            message.success("Tests added!");
            getPreviousTabledata(10);
            setPreSubmitTableData([]);
          } else message.error("Please try again");
      })
      .catch();
  };

  const retestSelected = () => {
    console.log(retestList);
    var list = retestList?.map((e) => {
      return {
        commonTest: e["Common Test"],
        id: e.CommonTestTypeId,
        itemId: e.TestId,
        testName: e["Test Name"],
        type: e.Type,
      };
    });
    let testsObject = {};
    list.forEach((item) => {
      testsObject[item.id] = [
        ...(testsObject[item.id] ? testsObject[item.id] : []),
        { id: item.itemId },
      ];
    });

    let request = {
      userId: patient.pId,
      tests: Object.entries(testsObject).map(([key, value]) => {
        return { testTypeId: key, tests: value };
      }),
      appointmentId: patient.appointmentId,
    };
    saveRadiologyTests(request)
      .then(({ data, status }) => {
        if (status == 200)
          if (data.status == 1) {
            message.success("Tests added!");
            getPreviousTabledata(10);
            // setPreSubmitTableData([]);
            // setPreSubmitTableDataSize(0)
          } else message.error("Please try again");
        setRetestModal(false);
        setMainTableLoading(false);
      })
      .catch();
    setMainTableLoading(false);
    setIsCustomModalForRetestLoading(true);
    setRetestList([]);
    setIsCustomModalForRetestLoading(false);
    // setRetestModal(false)
  };

  const retestSelectedRow = (e) => {
    if (e) {
      var data = [
        {
          commonTest: e["Common Test"],
          id: e.CommonTestTypeId,
          itemId: e.TestId,
          testName: e["Test Name"],
          type: e.Type,
        },
      ];
      preSubmitTableData.length > 0
        ? setPreSubmitTableData([...data, ...preSubmitTableData])
        : setPreSubmitTableData(data);
      // preSubmitTableData.length > 0 ? setPreSubmitTableDataSize(preSubmitTableDataSize + 1) : setPreSubmitTableDataSize(1)
    }
  };

  const getPreviousTabledata = (count) => {
    tableData.length == 0 ? (count = 10) : (count = count);
    previousRadiologyRequest(patient.appointmentId, patient.pId, count, 0)
      .then(({ data, status }) => {
        if (status == 200) {
          if (data.status == 1) {
            var temp = data.body;
            temp.map((item) => {
              item.key = item["Sl No."];
            });
            setTableData(data.body);
            setPageSize(data.total);
            setFormFetchLoading(false);
            setMainTableLoading(false);
          }
          setMainTableLoading(false);
        }
        setMainTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setMainTableLoading(false);
      });
  };

  const getPrintTableData = () => {
    previousRadiologyRequest(patient.appointmentId, patient.pId)
      .then(({ data, status }) => {
        if (status == 200) {
          if (data.status == 1) {
            var temp = data.body;
            temp.map((item) => {
              item.key = item["Sl No."];
            });
            setPrintData(temp);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const downloadFile = (e) => {
    setDownloadLoading(true);
    // Third-Party Report Uploaded
    var zip = new JSZip();

    if (
      e?.status === "Third-Party Report Uploaded" ||
      e?.status == "Report Uploaded"
    ) {
      // var link = []
      var i = 0;
      e.documents.forEach(function (file) {
        var url = PROFILE_IMAGE_PREFIX + file.documentUrl;
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            console.log(err);
            Sentry.captureException(err);
          }
          zip.file(`${i}-` + file.fileName, data, { binary: true });
          i++;
          if (i == e.documents.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, `${patient.pName}-${e["Common Test"]}-${e.Date}`);
            });
          }
        });
      });
    }
    setTimeout(() => {
      setDownloadLoading(false);
    }, 4000);
  };

  const generateTestFields = (testFields) => {
    let checkValues = [];

    let formsItems = testFields.map((item) => {
      let tempFormItem = commonTestDropDownValues.find(
        (value) => item.testTypeId == value.id
      );
      if (tempFormItem) {
        checkValues = item.tests
          ? [...checkValues, ...item.tests]
          : checkValues;
        return {
          fieldId: item.testTypeId,
          formName: tempFormItem.value,
          field: {
            inputType: "check-box-default",
            name: item.testTypeId,
            meta: item.tests ? item.tests.map((each) => each.name) : [],
            layout: { span: 24 },
          },
        };
      } else {
        return null;
      }
    });
    setCheckGroupValues(checkValues);

    return formsItems;
  };
  // fetch VariousTests
  const getLabTestItem = (types) => {
    fetchRadiologTest(types)
      .then(({ data, status }) => {
        if (status == 200)
          if (data.status == 1) setSubFormData(generateTestFields(data.body));
      })
      .catch();
  };

  const appendCloseButton = (data, columns) => {
    columns[preSubmitTableColumns.length - 1].render = (
      value,
      record,
      index
    ) => {
      return (
        <Row justify="space-between" gutter={36}>
          <Col>{value}</Col>
          <Col>
            <Popconfirm
              title="Please confirm to Remove"
              onConfirm={() => {
                setPreSubmitTableData(data.filter((item, i) => i !== index));
              }}
            >
              <CloseCircleOutlined
                style={{
                  fontSize: "20px",
                  color: "red",
                  marginRight: "3vw",
                }}
              />
            </Popconfirm>
          </Col>
        </Row>
      );
    };
    // columns[preSubmitTableColumns.length -1 ].width = "5vw"
    return columns;
  };

  const getForms = useCallback(() => {
    var isData = false;
    getHealthRecordForms(sectionId, patient.pId)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            const tempFormIds = {};
            var tempMetaDataIds = [];
            var tempChildMetaDataIds = {};
            isData = data.body[0].date ? true : false;
            (!isData ? data.body : data.body[0].dynamicHealthRecord).forEach(
              (item) => {
                //getting formIds and corresponding field IDs for submit request Generation
                tempFormIds[item.id] = (
                  isData ? item.form.fields : item.fields
                ).map((field) => field.id);

                //finding fields which needs to call external API for fetching values eg:dropdown
                tempMetaDataIds = [
                  ...tempMetaDataIds,
                  ...item.fields.filter((field) => {
                    if (field.dropDownType.dropDownType == "RADIOLOGY")
                      setTestTypeField(field);
                    else if (field.dropDownType.dropDownType == "LAB_TEST_TYPE")
                      setCommonTestField(field);
                    return (
                      field.dropDownType !== null &&
                      field.dropDownType !== undefined &&
                      Object.keys(field.dropDownType).length !== 0
                    );
                  }),
                ];
              }
            );

            //converting forms into formBuilder readable format
            let FormsTemp = (
              !isData ? data.body : data.body[0].dynamicHealthRecord
            ).map((item) => {
              return {
                formName: isData ? item.form.formName : item.formName,
                fields: generateFormFields(
                  isData ? item.form.fields : item.fields
                ),
              };
            });

            setFormIds(tempFormIds);
            setMetaDataFieldIds(tempMetaDataIds);
            // setChildMetaDataFieldIds(tempChildMetaDataIds);
            setForms(FormsTemp);
            setFormFetchLoading(false);
          } else {
            message.error(data.body);
            setFormFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        setFormFetchLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  }, [sectionId]);

  const onClear = () => {
    // setFormInitialValues({});
    setSubFormData([]);
    form.resetFields();
    setFormFetchLoading(true);
    getForms();
  };

  const closeModal = () => {
    setVisible(false);
  };

  const fetchAppointmentDetails = () => {
    getAppointmentDetails(patient.appointmentId).then((res) => {
      if (res.data.status == 1) setAppointmentDetails(res.data.body);
      else message.error(res.data.body);
    });
  };

  useEffect(() => {
    setMainTableLoading(true);
    setFormFetchLoading(true);
    getForms();
    getPreviousTabledata(10);
    getPrintTableData();
    fetchAppointmentDetails();
  }, []);

  const rowSelection = {
    preserveSelectedRowKeys: false,
    selectedRowKeys: selectedRowKeys,
    columnWidth: "3vw",
    onChange: (selectedRowKeys, selectedRows) => {
      setIsCustomModalForRetestLoading(true);
      setRetestList(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      setIsCustomModalForRetestLoading(false);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
    getCheckboxProps: (record) => ({
      disabled:
        record.status !== "Report Uploaded" || record.testStatus !== "ACTIVE", // Column configuration not to be checked
      // name: record.name,
    }),
  };

  useEffect(() => {
    if (retestModal) {
      var list = [];
      retestList.map((e) => {
        var data = [
          {
            commonTest: e["Common Test"],
            id: e.CommonTestTypeId,
            itemId: e.TestId,
            testName: e["Test Name"],
            type: e.Type,
          },
        ];
        list = [...list, ...data];
        console.log("Inside", list);
        // preSubmitTableData.length > 0 ? setPreSubmitTableDataSize(preSubmitTableDataSize + 1) : setPreSubmitTableDataSize(1)
      });
      console.log("Outside", list);
      preSubmitTableData.length > 0
        ? setPreSubmitTableData([...list, ...preSubmitTableData])
        : setPreSubmitTableData(list);
    }
    setRetestModal(false);
  }, [retestModal]);

  useEffect(() => {
    if (retestList?.length !== selectedRowKeys?.length) {
      var list = retestList.map((item) => {
        return item.key;
      });
      setSelectedRowKeys(list);
    }
    setFormFetchLoading(false);
  }, [retestList]);

  const formSubmitHandler = (values) => {
    let tableData = [];
    if (values[commontTestField.id])
      values[commontTestField.id].forEach((commonTest) => {
        let forum = subFormData.find((item) => item.formName == commonTest);
        if (forum) {
          tableData = [
            ...tableData,
            ...values[forum.fieldId].map((item) => {
              return {
                type: values[testTypeField.id],
                commonTest: commonTest,
                testName: item,
                id: forum.fieldId,
                itemId: checkGroupValues.find((val) => val.name == item).id,
              };
            }),
          ];
        }
      });
    setPreSubmitTableData([...tableData, ...preSubmitTableData]);
    form.resetFields();
    setSubFormData([]);
  };

  const fetchCommonTypes = (sectionId) => {
    fetchFormFieldMeta("", sectionId).then(({ data, status }) => {
      if (status == 200) {
        if (data.status == 1) {
          //Injecting meta data for corresponding  formfields
          forms.forEach((form) => {
            let metaField = form.fields.find(
              (field) => field.name === commontTestField.id
            );
            // setLabTestItems(data.body);
            setCommonTestDropDownValues(data.body);
            if (metaField)
              metaField.meta = data.body.map((item) =>
                item.value ? item.value : item.formName
              );
            else console.log("Meta undefined");
          });
        }
      }
    });

    setForms([...forms]);
  };

  useEffect(() => {
    if (forms.length && metaDataFieldIds.length) {
      let fieldMetaPromises = metaDataFieldIds.map((item) => {
        if (item.dropDownType.dropDownType !== "LAB_TEST_TYPE")
          return fetchFormFieldMeta(item.dropDownType.dropDownType);
      });

      Promise.all(fieldMetaPromises)
        .then((responses) => {
          responses.forEach(({ data, status }, index) => {
            if (status == 200) {
              if (data.status == 1) {
                //Injecting meta data for corresponding  formfields
                forms.forEach((form) => {
                  let metaField = form.fields.find(
                    (field) => field.name === metaDataFieldIds[index].id
                  );
                  // storing the dorpdown values for later use
                  if (
                    data.body.length &&
                    data.body[0].type.dropDownType == "LAB_TEST_TYPE"
                  ) {
                    setCommonTestDropDownValues(data.body);
                  } else if (
                    data.body.length &&
                    data.body[0].type.dropDownType == "RADIOLOGY"
                  ) {
                    setTestTypeDropDownValues(data.body);
                  }
                  if (metaField)
                    metaField.meta = data.body.map((item) =>
                      item.value ? item.value : item.formName
                    );
                  else console.log("Meta undefined");
                });
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setForms([...forms]);
          setMetaDataFieldIds([]);
        });
    }
  }, [metaDataFieldIds, forms]);

  const valuesChange = (changedValues, allValues) => {
    Object.entries(changedValues).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        setSelectedLength({ ...selectedLength, [key]: value.length });
      }
    });
    if (changedValues[testTypeField.id]) {
      let temp = testTypeDropDownValues.find((item) => {
        return item.value === changedValues[testTypeField.id];
      });
      form.resetFields();
      form.setFieldsValue({
        [testTypeField.id]: changedValues[testTypeField.id],
      });
      setSubFormData([]);
      if (temp) fetchCommonTypes(temp.id);
    }
    if (changedValues[commontTestField.id]) {
      let typeIds = commonTestDropDownValues
        .filter((field) => {
          return changedValues[commontTestField.id].includes(field.value);
        })
        .map((item) => item.id);

      getLabTestItem(typeIds);
    }
  };

  return !formFetchLoading ? (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        {!mainTableLoading ? (
          tableData.length > 0 && (
            <CustomeTableForRquests
              title={"Radiology Requests"}
              columns={tableColumns({
                isMain: true,
                retestSelectedRow: retestSelectedRow,
                setFileViewModal: setFileViewModal,
                setDocuments: setDocuments,
                ViewDoc: ViewDoc,
                theme: theme,
                StyledIcon: StyledIcon,
                downloadFile: downloadFile,
                user: user,
                setSelectedDetails: setSelectedDetails,
                setVisible: setVisible,
                downloadLoading: downloadLoading,
              })}
              printColumns={tableColumnsPrint()}
              printData={printData}
              dataSource={tableData}
              tableTitle={"Tests"}
              rowSelection={user.userType !== "PATIENT" && rowSelection}
              isPrintable={true}
              setRetestModal={setRetestModal}
              retestList={retestList}
              retestSelected={retestSelected}
              getPreviousTabledata={getPreviousTabledata}
              retest={user.userType !== "PATIENT"}
              pageSize={pageSize}
              isViewMore={true}
              isLoading={mainTableLoading}
              appointmentDetails={appointmentDetails}
            />
          )
        ) : (
          <div style={{ width: "100%" }}>
            <Spinner />
          </div>
        )}
      </Col>
      {preSubmitTableData.length > 0 && (
        <Col span={24}>
          <Row>
            <Col span={24}>
              {/* <TableContainer
                title={""}
                tableProps={{
                  columns: preSubmitTableColumns.length
                    ? appendCloseButton(
                      preSubmitTableData,
                      preSubmitTableColumns
                    )
                    : [],
                  dataSource: preSubmitTableData,
                }}
                tableTitle={"Radiology Request"}
              /> */}
              <CustomeTableForRquests
                // title={"Laboratory Request"}
                columns={
                  preSubmitTableColumns.length
                    ? appendCloseButton(
                        preSubmitTableData,
                        preSubmitTableColumns
                      )
                    : []
                }
                dataSource={preSubmitTableData}
                tableTitle={"Radiology Request"}
                // rowSelection={rowSelection}
                isPrintable={true}
                // retestSelected={retestSelected}
                getPreviousTabledata={getPreviousTabledata}
                collapse={false}
                isViewMore={false}
                // retest={true}
              />
            </Col>
            <Col span={24}>
              <ButtonContainer>
                <RightButton
                  type="primary"
                  loading={submitLoading}
                  onClick={submitFormData}
                >
                  Request
                </RightButton>
              </ButtonContainer>
            </Col>
          </Row>
        </Col>
      )}
      <Col span={24}>
        {!tableData.length &&
          !mainTableLoading &&
          user.userType === "PATIENT" && (
            <NoRecords bgColor="#fff" message="No details for this visit" />
          )}
        {user.userType !== "PATIENT" && (
          <FormContainer>
            <Form
              layout="vertical"
              onFinish={formSubmitHandler}
              onValuesChange={valuesChange}
              form={form}
              scrollToFirstError={true}
            >
              {forms.map((subForm) => (
                <FormBuilder
                  // formInstance={form}
                  formConfig={[
                    {
                      inputType: "heading",
                      label: subForm.formName,
                    },
                    ...subForm.fields,
                  ]}
                />
              ))}
              {subFormData.map((subForm) =>
                subForm.field?.meta ? (
                  <>
                    <FormBuilder
                      formConfig={[
                        {
                          inputType: "heading",
                          label: subForm.formName,
                        },
                      ]}
                    />

                    {subForm.field?.meta?.length > 0 ? (
                      <>
                        <Form.Item style={{ marginBottom: "0" }}>
                          <Checkbox
                            checked={
                              selectedLength[subForm.field.name] ==
                              subForm.field.meta.length
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                form.setFieldsValue({
                                  [subForm.field.name]: subForm.field.meta.map(
                                    (item) => item
                                  ),
                                });

                                valuesChange({
                                  [subForm.field.name]: subForm.field.meta.map(
                                    (item) => item
                                  ),
                                });
                              } else {
                                form.setFieldsValue({
                                  [subForm.field.name]: [],
                                });
                                valuesChange({
                                  [subForm.field.name]: [],
                                });
                              }
                            }}
                          >
                            <b style={{ color: "rgb(0,0,0)" }}>
                              {selectedLength[subForm.field.name] ==
                              subForm.field.meta.length
                                ? "Unselect All"
                                : "Select All"}
                            </b>
                          </Checkbox>
                        </Form.Item>
                        <FormBuilder formConfig={[subForm.field]} />
                      </>
                    ) : (
                      <p style={{ marginLeft: "20px" }}>No tests available </p>
                    )}
                  </>
                ) : (
                  <></>
                )
              )}
              <Form.Item>
                {forms.length !== 0 && (
                  <DiscardSubmitWrapper>
                    <Button
                      type="primary"
                      ghost
                      htmlType="submit"
                      className="btn-save"
                      onClick={onClear}
                    >
                      Discard
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}
                      className="btn-save"
                    >
                      Add
                    </Button>
                  </DiscardSubmitWrapper>
                )}
              </Form.Item>
            </Form>
          </FormContainer>
        )}
      </Col>
      <FileViewModal
        closable={false}
        visible={fileViewModal}
        setVisible={setFileViewModal}
        isClosable={true}
        data={documents}
        patientId={patient.pId}
        patientName={patient.pName}
        downloadFile={downloadFile}
      />
      {Object.keys(selectedDetails).length ? (
        <ViewDetailsModal
          closeModal={closeModal}
          visible={visible}
          data={selectedDetails}
          recordsType={"Radiology"}
          parentAppointmentId={patient.appointmentId}
          appointmentOrder={{
            technicalNotes: appointmentDetails?.radiologyTechnicalNotes,
          }}
        />
      ) : (
        <></>
      )}
    </Row>
  ) : (
    <Spinner />
  );
};
export default Radiology;
