import Icon, {
  LeftCircleOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Input, message, Row, Table, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import { sidebarIcons } from "../../../constants/sideBarIcons";
import Spinner from "../../utils/Spinner";
import { StyledDiv, StyledIcon } from "../AdminDashboard/styledComponents";
import { UserProfileHead } from "../Appoinment/userProfileHead";
import { moveToQueue } from "../../../apis/Appointment";
import {
  SectionContent,
  SidebarCol,
  StyledButton,
  StyledHead,
  StyledRouterContainer,
} from "../HealthRecords";
import * as Sentry from "@sentry/react";
import {
  HealthRecordMenu,
  HealthRecordMenuItem,
} from "../HealthRecords/styledComponents";
import { ReactComponent as LeftArrow } from "../../../assets/icons/LeftArrow.svg";
import moment from "moment";
import { getAppointmentDetails } from "../../../apis/Appointment";
import {
  getAppointmentDetailsAndTechnologyNotes,
  submitTestReport,
  updateLabOrderDetails,
} from "../../../apis/laboratory";
import { StyledTable } from "../../utils/CustomTable/styledComponents";
import { columns } from "./columns";
import { StyledBodyTitle } from "../AdminDashboard/Sections/styledComponents";
import EditTestResults from "./EditTestResults";
import FileViewModal from "../HealthRecords/Radiology/fileViewModal";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { PROFILE_IMAGE_PREFIX } from "../../../constants/StringConstants";
import {
  getAppointmentDetailsAndTechnologyNotesRadiology,
  submitTestReportRadiology,
  updateLabOrderDetailsRadiology,
} from "../../../apis/radiology";
import ViewDetailsModal from "./ViewDetailsModal";
const { TextArea } = Input;

const StyledRowProfile = styled(Row)`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;

const StyledRow = styled(Row)`
  font-weight: 600;
  color: rgb(93, 93, 93);
`;

const StyledCol = styled(Col)`
  background: #fff;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0 0 15px 0;
  border-radius: 10px;
`;

const SummaryContainer = styled.div`
  background: #fff;
  width: 100%;
  padding-bottom: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    padding: 15px 0 15px 20px;
  }
`;

const StyledP = styled.p`
  display: contents;
  font-size: 12px;
  font-weight: normal;
`;

const CancelSaveSubmitButtonWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: end;
  gap: 10px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const BlueBgTable = styled(Table)`
  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    /* position: relative; */
    padding: 8px !important;
    overflow-wrap: break-word;
    margin: 0px;
    font-size: 15px !important;
  }
  & .ant-select-selector {
    font-size: 15px !important;
    /* color: ${(props) => props.color} !important; */
  }
  & .ant-table-thead > tr > th {
    /* padding: 8px 0 !important; */
    color: #606060;
    font-weight: 600;
  }
  & .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:first-child {
    border-left: 1px solid #f0f0f0;
  }

  & .ant-table-tbody > tr > td {
    margin: 0 10px;
  }
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #f0f0f0;
  }
`;

const StyledTextArea = styled(TextArea)`
  & .ant-input {
    /* border: 1px solid black; */
    border-radius: 5px;
  }
`;

const StyledPreviewIcon = styled(Icon)`
  & .cls-1 {
    fill: rgba(0, 0, 0, 0.25);
  }
`;

const LaboratoryDetails = () => {
  const { pId, pName, appointmentId } = useParams();
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const user = useSelector((state) => state.userReducer);
  const history = useHistory();
  const theme = useSelector((state) => state.themeReducer);
  const [loading, setLoading] = useState(false);
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [mobileMenuVisible, setmobileMenuVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState(["0"]);
  const [appointmentDetails, setAppointmentDetails] = useState();
  const [appointmentOrder, setAppointmentOrders] = useState();
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [editTestResults, setEditTestResults] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [fileViewModal, setFileViewModal] = useState(false);
  const [documents, setDocuments] = useState();

  const [recordsType, setRecordsType] = useState();
  const location = useLocation();
  const [notes, setNotes] = useState();
  const [form] = Form.useForm();

  const menuItems = [
    { id: "0", formName: recordsType, icon: recordsType?.toLowerCase() },
  ];

  const [selectedSection, setSelectedSection] = useState(menuItems[0]);

  const statusOptions = [
    { color: "orange", value: "Pending" },
    { color: theme["@primary-color"], value: "Sample Collected" },
    { color: "green", value: "Report Uploaded" },
  ];

  const handlemobileMenu = (value) => {
    setmobileMenuVisible(!mobileMenuVisible);
  };

  const menuChangeHandler = (e) => {
    console.log(e);
    setSelectedKey(e.selectedKeys);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const onClickMoveToQueue = () => {
    let searchQuery = `appointmentId=${appointmentId}&timeZone=${moment.tz.guess()}`;
    moveToQueue(searchQuery)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 1) {
            history.push("/dashboard");
            message.success(res.data.body);
          } else message.error(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const onFinish = (values) => {
    console.log(values);
    var submit;
    if (recordsType == "Laboratory") submit = submitTestReport(appointmentId);
    else if (recordsType == "Radiology")
      submit = submitTestReportRadiology(appointmentId);
    if (submit)
      submit.then((response) => {
        if (response.data.status == 1) {
          message.success(response.data.body);
          history.push("/dashboard");
        } else message.error(response.data.body);
      });
    else {
      message.error("Sorry! something went wrong");
      history.push("/dashboard");
    }
  };

  const onFormValueChange = (changed, all, status) => {
    if (status) {
      setIsSaveChanges(true);
      var changedTestOrders = appointmentOrder?.testOrders?.map((item) => {
        if (item.testDataId == changed.testDataId) item.Status = status;
        return item;
      });
      appointmentOrder.testOrders = changedTestOrders;
      setDataSource(changedTestOrders);
      setAppointmentOrders(appointmentOrder);
      //   console.log("updated", moment());
    }
    if (changed.visibleToPatient == false || changed.visibleToPatient == true)
      setIsSaveChanges(true);
    if (changed.notes) setIsSaveChanges(true);
  };

  const downloadFile = (e) => {
    var zip = new JSZip();
    var i = 0;
    if (e.documents) {
      e.historyDocuments = e.documents;
    }
    e.historyDocuments.forEach(function (file) {
      var url = PROFILE_IMAGE_PREFIX + file.documentUrl;
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          console.log(err);
          Sentry.captureException(err);
        }
        zip.file(`(${i})` + file.fileName, data, { binary: true });
        i++;
        if (i == e.historyDocuments.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(
              content,
              `${file["medicalDocumentType"]}-${moment(
                file["consultationDate"]
              ).format("DD-MM-YYYY")}`
            );
          });
        }
      });
    });
  };

  useEffect(() => {
    console.log(location);
    if (location.pathname.split("/")[1] == "laboratory-records") {
      setRecordsType("Laboratory");
    } else if (location.pathname.split("/")[1] == "radiology-records")
      setRecordsType("Radiology");
    setSelectedSection(menuItems[0]);
  }, []);

  const saveChanges = () => {
    form
      .validateFields()
      .then((res) => {
        var testAndStatusRequests = appointmentOrder?.testOrders?.map(
          (item) => {
            return { status: item.Status, testDataId: item.testDataId };
          }
        );
        var data = {
          appointmentId: appointmentDetails?.parentAppointment?.id,
          notes: {
            id: appointmentOrder.technicalNotes?.id,
            notes: form.getFieldValue("notes"),
            visibleToPatient: form.getFieldValue("visibleToPatient")
              ? true
              : false,
          },
          testAndStatusRequests: testAndStatusRequests,
        };
        var labOrderDetails;
        if (recordsType == "Laboratory") {
          data.laboratoryAppointmentId = appointmentDetails?.id;
          labOrderDetails = updateLabOrderDetails(data);
        } else if (recordsType == "Radiology") {
          data.radiologyAppointmentId = appointmentDetails?.id;
          labOrderDetails = updateLabOrderDetailsRadiology(data);
        }
        if (labOrderDetails)
          labOrderDetails.then((response) => {
            if (response.data.status == 1) {
              message.success("Updated Succesfully");
            } else message.error(response.data.body);
          });
        else {
          message.error("Sorry! something went wrong");
          history.push("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        if (err.errorFields?.length > 0) {
          console.log(err.errorFields);
        }
      })
      .finally((data) => {
        console.log(data);
        let submit = false;
        let temp = appointmentOrder.testOrders?.map((item) => {
          if (item.Status !== "Report Uploaded") submit = true;
        });
        if (submit) setReadyToSubmit(false);
        else setReadyToSubmit(true);
      });
  };

  useEffect(() => {
    var type;
    if (location.pathname.split("/")[1] == "laboratory-records") {
      setRecordsType("Laboratory");
      type = "Laboratory";
    } else if (location.pathname.split("/")[1] == "radiology-records") {
      setRecordsType("Radiology");
      type = "Radiology";
    }
    setLoading(true);
    setTableLoading(true);
    getAppointmentDetails(appointmentId)
      .then((response) => {
        if (response.data.status == 1) {
          setAppointmentDetails(response.data.body);
          var appointmentDetailsAndTechnologyNotes;
          if (type == "Laboratory")
            appointmentDetailsAndTechnologyNotes =
              getAppointmentDetailsAndTechnologyNotes(response.data.body?.id);
          else if (type == "Radiology")
            appointmentDetailsAndTechnologyNotes =
              getAppointmentDetailsAndTechnologyNotesRadiology(
                response.data.body?.id
              );
          if (appointmentDetailsAndTechnologyNotes)
            appointmentDetailsAndTechnologyNotes
              .then((res) => {
                if (res.data.status == 1) {
                  setDataSource(res.data.body?.testOrders);
                  setAppointmentOrders(res.data.body);
                  form.setFieldsValue({
                    notes: res.data.body.technicalNotes?.notes,
                    visibleToPatient:
                      res.data.body.technicalNotes?.visibleToPatient,
                  });
                  let submit = false;
                  let temp = res.data.body?.testOrders?.map((item) => {
                    if (item.Status !== "Report Uploaded") submit = true;
                  });
                  if (!submit) setReadyToSubmit(true);
                }
              })
              .catch((err) => {
                console.log(err);
                Sentry.captureException(err);
              })
              .finally(() => {
                setTableLoading(false);
              });
          else {
            message.error("Sorry! something went wrong");
            history.push("/dashboard");
          }
        } else {
          message.error(response.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      })
      .finally(() => {
        setLoading(false);
        // setTableLoading(false);
      });
  }, []);

  return loading ? (
    <Spinner height="calc(100vh - 200px)" />
  ) : (
    <Row>
      <SidebarCol
        span={5}
        className={
          mobileMenuVisible
            ? "fixed_sidebar_panel mob-menu-open" //case when the menu is triggered
            : "fixed_sidebar_panel" // case when the menu is closed
        }
      >
        <Col
          className="sidebar_menu_tag"
          onClick={() => {
            handlemobileMenu(false);
          }}
        >
          <div className="menu_bar">
            <span></span>
          </div>
        </Col>
        <HealthRecordMenu
          className="sidepanel_menu_list"
          mode="vertical"
          selectedKeys={selectedKey}
          onSelect={menuChangeHandler}
        >
          {menuItems.map((item, i) => (
            <HealthRecordMenuItem
              key={i}
              icon={
                sidebarIcons[item.icon] ? (
                  <StyledIcon
                    color={theme["@primary-color"]}
                    component={sidebarIcons[item.icon]}
                    isActive={item.id === selectedSection.id}
                  />
                ) : (
                  <ReconciliationOutlined style={{ fontSize: "25px" }} />
                )
              }
            >
              {item.formName}
            </HealthRecordMenuItem>
          ))}
        </HealthRecordMenu>
      </SidebarCol>
      <SectionContent span={19} className="scroll_right_panel">
        <Col style={{ padding: "0px 0px 20px", marginTop: "15px" }}>
          <Row className="panel_row">
            <Col>
              <StyledHead>
                {/* {consultationHistoryButtonVisible == true && ( */}
                <LeftCircleOutlined
                  onClick={() =>
                    editTestResults
                      ? setEditTestResults(false)
                      : history.goBack()
                  }
                />
                &nbsp;&nbsp;
                {editTestResults ? "Update Test Report" : "Request Details"}
              </StyledHead>
            </Col>
            <Col>{/* {"sub"} */}</Col>
            {history?.location?.state?.viewHistory == undefined ? (
              <div className="d_flex_col" style={{ marginLeft: "auto" }}>
                <Row style={{ gap: "20px" }}>
                  <StyledButton
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    color={theme["@primary-color"]}
                    onClick={onClickMoveToQueue}
                  >
                    <Icon style={{ fontSize: "20px" }} component={LeftArrow} />
                    Move To Queue
                  </StyledButton>
                </Row>
              </div>
            ) : (
              <></>
            )}
            <Col span={24}>
              <StyledRowProfile justify="center">
                <UserProfileHead
                  // user={user}
                  patientId={patient?.pId ? patient.pId : pId}
                  patientName={patient?.pName ? patient.pName : pName}
                />
              </StyledRowProfile>
            </Col>
          </Row>
        </Col>
        <StyledRouterContainer className="form-m2-style">
          <Form
            onValuesChange={onFormValueChange}
            onFinish={onFinish}
            form={form}
          >
            <Row>
              <SummaryContainer>
                <Row>
                  <Col span={1}></Col>
                  <h3 style={{ color: theme["@primary-color"] }}>
                    Visit Summary
                  </h3>
                </Row>
                <Row>
                  <Col lg={1} md={1} sm={1} xs={1}></Col>
                  <Col lg={8} md={8} sm={8} xs={23}>
                    <StyledRow>
                      Date:&nbsp;&nbsp;
                      <StyledP>
                        {moment(
                          appointmentDetails?.parentAppointment?.date
                        ).format("DD-MM-YYYY")}
                      </StyledP>
                    </StyledRow>
                    <StyledRow>
                      Time:&nbsp;&nbsp;
                      <StyledP>
                        {moment(
                          appointmentDetails?.parentAppointment?.startTime
                        ).format("hh:mm a")}
                        &nbsp;-&nbsp;
                        {moment(
                          appointmentDetails?.parentAppointment?.endTime
                        ).format("hh:mm a")}
                      </StyledP>
                    </StyledRow>
                  </Col>
                  <Col lg={0} md={0} sm={0} xs={1}></Col>
                  <Col lg={15} md={15} sm={15} xs={23}>
                    <StyledRow>
                      Doctor:&nbsp;&nbsp;
                      <StyledP>
                        Dr.
                        {
                          appointmentDetails?.parentAppointment?.doctor
                            ?.userName
                        }
                      </StyledP>
                    </StyledRow>
                    <Row></Row>
                  </Col>
                </Row>
              </SummaryContainer>
            </Row>
            {editTestResults ? (
              <EditTestResults
                type="opd"
                selectedRow={selectedRow}
                appointmentId={appointmentId}
                parentAppointmentId={appointmentDetails?.parentAppointment?.id}
                recordsType={recordsType}
                setEditTestResults={setEditTestResults}
              />
            ) : (
              <Row>
                <StyledCol>
                  <StyledBodyTitle
                    style={{ padding: "20px 0 0 40px" }}
                    color={theme["@primary-color"]}
                    level={4}
                  >
                    All Requests
                  </StyledBodyTitle>
                  <div style={{ padding: "20px" }}>
                    <StyledDiv bgcolor={theme["@primary-color"]}>
                      <div>&nbsp;&nbsp;Tests</div>
                      <div>&nbsp;&nbsp;</div>
                    </StyledDiv>
                    <Form.Item
                      name={"tableValues"}
                      // label="Upload Document"
                      rules={[{ required: false }]}
                    >
                      {tableLoading ? (
                        <Spinner />
                      ) : (
                        <BlueBgTable
                          scroll={{ x: "max-content" }}
                          dataSource={dataSource}
                          columns={columns(
                            theme,
                            StyledPreviewIcon,
                            history,
                            onFormValueChange,
                            form,
                            statusOptions,
                            setEditTestResults,
                            setSelectedRow,
                            setFileViewModal,
                            setDocuments,
                            downloadFile,
                            setVisible
                          ).filter((item) => !item.hidden)}
                          pagination={false}
                          color={theme["@primary-color"]}
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div>
                    <Row>
                      <StyledBodyTitle
                        style={{ padding: "20px 0 0 20px" }}
                        color={theme["@primary-color"]}
                        level={4}
                      >
                        Technician Notes
                      </StyledBodyTitle>
                    </Row>

                    <div style={{ padding: "0 20px" }}>
                      Notes
                      <Form.Item
                        name={"notes"}
                        // label="Upload Document"
                        rules={[{ required: false }]}
                      >
                        <StyledTextArea
                          onChange={setNotes}
                          value={notes}
                          rows={5}
                          placeholder=""
                          allowClear
                          disabled={
                            history.location.state?.viewHistory ? true : false
                          }
                        />
                      </Form.Item>
                      &nbsp;
                      <br />
                      {history.location.state?.viewHistory == undefined && (
                        <Form.Item
                          name={"visibleToPatient"}
                          // label="Upload Document"
                          rules={[{ required: false }]}
                          valuePropName={"checked"}
                        >
                          <Checkbox>
                            <p
                              style={{
                                display: "contents",
                                color: "black",
                                fontWeight: "normal",
                              }}
                            >
                              Make visible for patient
                            </p>
                          </Checkbox>
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <br />
                  {history.location.state?.viewHistory == undefined && (
                    <CancelSaveSubmitButtonWrapper>
                      <Button
                        className="btn-cancel"
                        type="primary"
                        ghost
                        style={{ border: "none", boxShadow: "none" }}
                        onClick={() => history.push("/dashboard")}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        ghost
                        className="btn-save"
                        disabled={!isSaveChanges}
                        onClick={() => saveChanges()}
                        // htmlType="submit"
                      >
                        Save Changes
                      </Button>
                      <Button
                        disabled={!readyToSubmit}
                        htmlType="submit"
                        type="primary"
                        className="btn-save"
                      >
                        Submit Report
                      </Button>
                      {/* <Button
                        // disabled={!readyToSubmit}
                        // htmlType="submit"
                        onClick={() => setVisible(true)}
                        type="primary"
                      >
                        Test{" "}
                      </Button> */}
                    </CancelSaveSubmitButtonWrapper>
                  )}
                </StyledCol>
                <FileViewModal
                  closable={false}
                  visible={fileViewModal}
                  setVisible={setFileViewModal}
                  isClosable={true}
                  data={documents}
                  patientId={patient?.pId ? patient.pId : pId}
                  patientName={patient?.pName ? patient.pName : pName}
                  downloadFile={downloadFile}
                />
              </Row>
            )}
          </Form>
        </StyledRouterContainer>
      </SectionContent>
      <ViewDetailsModal
        type="opd"
        closeModal={closeModal}
        visible={visible}
        data={selectedRow}
        isLabUser={true}
        recordsType={recordsType}
        parentAppointmentId={appointmentDetails?.parentAppointment?.id}
        appointmentOrder={appointmentOrder}
      />
    </Row>
  );
};
export default LaboratoryDetails;
