import React, { useState } from "react";
import RadiologyTypes from "./RadiologyTypes";
import RadiologyTestTypes from "./RadiologyTestTypes";
import RadiologyTests from "./RadiologyTests";
import TestFieldManagement from "./RadiologyTestsFields";
const Lab = () => {
  const [tab, setTab] = useState("radiology");
  const [selectedSection, setSelectedSection] = useState("section");
  const [totalSections, setTotalSections] = useState(0);
  const [totalForms, setTotalForms] = useState(0);
  const [selectedForm, setSelectedForm] = useState();
  const [labTestType, setSelectedLabTestType] = useState();
  const [selectedField, setSelectedField] = useState();

  switch (tab) {
    case "radiology":
      return (
        <RadiologyTypes
          setTab={setTab}
          setSelectedSection={setSelectedSection}
          setTotalSections={setTotalSections}
          totalSections={totalSections}
          selectedSection={selectedSection}
        />
      );
    case "radiology_test_type":
      return (
        <RadiologyTestTypes
          setTab={setTab}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          setSelectedLabTestTypes={setSelectedForm}
          // totalForms={totalForms}
          // setTotalForms={setTotalForms}
          selectedLabTestTypes={selectedForm}
        />
      );
    case "radiology_test":
      return (
        <RadiologyTests
          setTab={setTab}
          selectedForm={selectedForm}
          selectedSection={selectedSection}
          totalForms={totalForms}
          setSelectedField={setSelectedField}
        />
      );
    case "radiology_test_fields":
      return (
        <TestFieldManagement
          setTab={setTab}
          selectedForm={selectedForm}
          selectedSection={selectedSection}
          totalForms={totalForms}
          selectedField={selectedField}
        />
      );

    default:
      return <RadiologyTypes />;
  }
};

export default Lab;
