import styled from "styled-components";
import { Checkbox, Col, Row, Table, Typography } from "antd";
const { Title } = Typography;

export const ModalBodyContainer = styled.div`
  padding: 5px 15px;
`;

export const StyledRow = styled(Row)`
  margin-top: 24px;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  color: ${(props) => props.color};
  padding-bottom: 10px;
`;

export const StyledTitle = styled(Title)`
  && {
    color: ${(props) => props.color};
  }
`;

export const StyledDivider = styled(Col)`
  height: 5px;
  width: 100%;
  border-bottom: 2px solid #80808040;
  margin-left: 5px;
`;

export const DocumentDetailsDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDiv = styled.div`
  color: #fff;
  background-color: ${(props) => props.bgcolor};
  height: 40px;
  border-radius: 10px 10px 0 0;
  padding: 0 20px;
  font-size: 16px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const StyledTable = styled(Table)`
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    padding: 8px 0;
    overflow-wrap: break-word;
    margin: 0px;
  }
  & .ant-table-thead > tr > th {
    color: #606060;
    padding: 8px 0 !important;
    font-weight: 600;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  & .ant-checkbox + span {
    color: unset;
    font-weight: unset;
  }
`;
