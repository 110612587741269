import { CloudUploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Row,
  Table,
  Form,
  Upload,
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import {
  addTestFieldData,
  deleteTestFieldData,
  fetchFieldsOfTestBasedOnTestType,
  fetchTableDataForTest,
  fetchTableDataForTestIPD,
} from "../../../apis/laboratory";
import {
  addTestFieldDataRadiology,
  fetchFieldsOfTestBasedOnTestTypeRadiology,
  fetchTableDataForTestRadiology,
  fetchTableDataForTestRadiologyIPD,
} from "../../../apis/radiology";
import { uploadPdfOrImage } from "../../../apis/uploadPdfOrImage";
import FormBuilder from "../../utils/FormBuilder";
import { generateFormFields } from "../../utils/FormBuilder/valuesSerialisation";
import Spinner from "../../utils/Spinner";
import { StyledDiv } from "../AdminDashboard/styledComponents";
import { DocumentUpload } from "../PatientForm/DocumentUpload";
import { columnsTest } from "./columns";

const StyledRow = styled(Row)`
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
`;

export const BlueBgTable = styled(Table)`
  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    /* position: relative; */
    padding: 8px;
    overflow-wrap: break-word;
    margin: 0px;
    font-size: 15px !important;
  }
  & .ant-select-selector {
    font-size: 15px !important;
    /* color: ${(props) => props.color} !important; */
  }
  & .ant-table-thead > tr > th {
    /* padding: 8px 0 !important; */
    background: ${(props) => props.color};
    color: #ffffff;
    font-weight: 600;
  }
  & .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    height: 40px;
  }
  & .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
  & .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  & .ant-table-thead > tr > th:first-child {
    border-left: 1px solid #f0f0f0;
  }

  /* & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #9e9292;
  } */
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #f0f0f0;
  }
  & .ant-table {
    padding: 0;
  }
  & .ant-table-measure-row {
    display: none;
  }
`;

const StyledCol = styled(Col)`
  background: #fff;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0 0 15px 0;
  border-radius: 10px;
`;

const StyledSubmit = styled(Button)`
  margin-left: 10px;
  @media (max-width: 600px) {
    margin-left: unset;
  }
`;

const EditTestResults = ({
  type,
  selectedRow,
  appointmentId,
  parentAppointmentId,
  ipdId,
  recordsType,
  setEditTestResults,
}) => {
  const { pId, pName } = useParams();
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const user = useSelector((state) => state.userReducer);
  const history = useHistory();
  const theme = useSelector((state) => state.themeReducer);
  const [loading, setLoading] = useState(false);
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [fields, setFields] = useState();
  const [tableData, setTableData] = useState();
  const [parentChildMetaIds, setParentChildMetaIds] = useState({});
  const [documents, setDocuments] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [forms, setForms] = useState();
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [testTypeField, setTestTypeField] = useState({});
  const [commontTestField, setCommonTestField] = useState({});
  const [commonTestDropDownValues, setCommonTestDropDownValues] = useState([]);
  const [checkGroupValues, setCheckGroupValues] = useState([]);
  const [notes, setNotes] = useState();
  const [uploading, setUploading] = useState(false);
  const [deletedRows, setDeletedRows] = useState([]);
  const [deletedDocuments, setDeletedDocuments] = useState([]);
  const [formDataRequests, setFormDataRequests] = useState([]);
  const [files, setFiles] = useState([]);
  const [isDocumentUpload, setIsDocumentUpload] = useState();
  const [keyForFormRequest, setKeyForFormRequest] = useState(1);
  const [form] = Form.useForm();

  useEffect(() => {
    setFiles([]);
    setIsDocumentUpload();
  }, [parentAppointmentId]);

  useEffect(() => {
    console.log(selectedRow);
    var isData = false;
    setLoading(true);
    var fieldsOfTest;
    if (recordsType == "Laboratory")
      fieldsOfTest = fetchFieldsOfTestBasedOnTestType(selectedRow.testId);
    else if (recordsType == "Radiology")
      fieldsOfTest = fetchFieldsOfTestBasedOnTestTypeRadiology(
        selectedRow.testId
      );
    if (fieldsOfTest)
      fieldsOfTest
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.status === 1) {
              console.log(data.body);
              setFields(data.body);
              const tempFormIds = {};
              var tempMetaDataIds = [];
              var tempChildMetaDataIds = {};
              isData = data.body[0].date ? true : false;
              // data.body.forEach((item) => {
              //getting formIds and corresponding field IDs for submit request Generation
              tempFormIds[0] = data.body.map((field) => field.id);

              //finding fields which needs to call external API for fetching values eg:dropdown
              tempMetaDataIds = [
                ...tempMetaDataIds,
                ...data.body.filter((field) => {
                  if (field.dropDownType?.dropDownType == "LABORATORY")
                    setTestTypeField(field);
                  else if (field.dropDownType?.dropDownType == "LAB_TEST_TYPE")
                    setCommonTestField(field);
                  return (
                    field.dropDownType !== null &&
                    field.dropDownType !== undefined &&
                    Object.keys(field.dropDownType).length !== 0
                  );
                }),
              ];
              // });
              var isUpload;
              let removeUpload = data.body.filter((item) => {
                if (
                  item.fieldType !== "SINGLE_UPLOAD" &&
                  item.fieldType !== "MULTIPLE_UPLOAD"
                )
                  return true;
                else {
                  isUpload = true;
                  return false;
                }
              });
              if (isUpload)
                setIsDocumentUpload(
                  data.body.filter(
                    (item) =>
                      item.fieldType == "SINGLE_UPLOAD" ||
                      item.fieldType == "MULTIPLE_UPLOAD"
                  )[0]
                );

              //converting forms into formBuilder readable format
              let formsTemp = [
                {
                  formName: "Add Result",
                  fields: generateFormFields(removeUpload),
                },
              ];
              setFormIds(tempFormIds);
              setMetaDataFieldIds(tempMetaDataIds);
              // setChildMetaDataFieldIds(tempChildMetaDataIds);
              formsTemp.forEach((formTemp) => {
                tempMetaDataIds.forEach((metaDataField) => {
                  let metaField = formTemp.fields.find(
                    (field) => field.name === metaDataField.id
                  );
                  if (metaField)
                    if (!metaDataField?.parentFieldId)
                      metaField.meta = metaDataField?.dropDownValues?.map(
                        (item) => (item.value ? item.value : item.formName)
                      );
                    else console.log("Meta undefined");
                });
              });
              setForms(formsTemp);
              let tempParentChildMetaIds = parentChildMetaIds;
              try {
                tempMetaDataIds.forEach((metaFieldId) => {
                  if (metaFieldId.parentFieldId) {
                    if (tempParentChildMetaIds[metaFieldId.parentFieldId])
                      tempParentChildMetaIds[metaFieldId.parentFieldId].push(
                        metaFieldId.id
                      );
                    else
                      tempParentChildMetaIds = {
                        ...tempParentChildMetaIds,
                        [metaFieldId.parentFieldId]: [metaFieldId.id],
                      };
                  }
                });
              } catch (err) {
                console.log(err);
                Sentry.captureException(err);
              }
              setParentChildMetaIds(tempParentChildMetaIds);
              setFormFetchLoading(false);
            } else message.error(data.body);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setLoading(false);
        });
    else {
      message.error("Sorry! something went wrong");
      history.push("/dashboard");
    }
    fetchData();
  }, []);

  const fetchData = () => {
    setTableLoading(true);
    var tableDataForTest;
    if (recordsType == "Laboratory")
      if (type == "ipd") {
        tableDataForTest = fetchTableDataForTestIPD(
          ipdId,
          selectedRow.testDataId
        );
      } else {
        tableDataForTest = fetchTableDataForTest(
          parentAppointmentId,
          selectedRow.testDataId
        );
      }
    else if (recordsType == "Radiology")
      if (type == "ipd") {
        tableDataForTest = fetchTableDataForTestRadiologyIPD(
          ipdId,
          selectedRow.testDataId
        );
      } else {
        tableDataForTest = fetchTableDataForTestRadiology(
          parentAppointmentId,
          selectedRow.testDataId
        );
      }

    if (tableDataForTest)
      tableDataForTest
        .then((response) => {
          if (response.data.status == 1) {
            setTableData(response.data.body.data);
            if (response.data.body.documents?.length > 0) {
              setDocuments(response.data.body.documents);
              setFiles(response.data.body.documents);
            }
          } else message.error(response.data.body);
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setTableLoading(false);
        });
    else {
      message.error("Sorry! something went wrong");
      history.push("/dashboard");
    }
  };

  useEffect(() => {
    let deletedExistingFiles = files
      .filter((item) => item.id)
      .map((item) => item.id);
    let deletedFiles = documents
      .filter((item) => item.id)
      .map((item) => item.id)
      .map((item) => {
        if (!deletedExistingFiles.includes(item)) {
          return documents.filter((document) => document.id == item)[0];
        }
      })
      .filter((item) => item)
      .map((item) => item.id);
    setDeletedDocuments(deletedFiles);
  }, [files]);

  const onFinish = (values) => {
    var data = {};
    var isTableUpdated = false;
    Object.keys(values).forEach((value) => {
      let field = fields.filter((field) => field.id == value)[0];
      if (values[field.id]) isTableUpdated = true;
      data[field.formName] = values[field.id];
      data.id = keyForFormRequest;
    });
    if (isTableUpdated) {
      setTableData([data, ...tableData]);
      values.id = keyForFormRequest;
      setKeyForFormRequest(keyForFormRequest + 1);
      setFormDataRequests([...formDataRequests, values]);
      setIsSaveChanges(true);
    }
    form.resetFields();
  };
  const submitForm = (values) => {
    console.log(values, files);
    var formDataRequest = [];
    formDataRequests.forEach((formData) => {
      let field = Object.keys(formData)
        .filter((item) => item !== "id")
        .map((field) => {
          return { fieldValue: formData[field], formFieldId: field };
        });
      let fields = [{ fields: field }];
      formDataRequest = [...formDataRequest, ...fields];
    });
    var order = documents.length - deletedDocuments.length - 1;
    let documentUrls = files
      .filter((item) => !item.id)
      .map((item) => {
        order = order + 1;
        return {
          documentUrl: item.documentUrl,
          fileName: item.fileName,
          order: order,
          testId: selectedRow.testId,
        };
      });
    var data = {
      appointmentId: parentAppointmentId,
      ipdId: ipdId,
      deletedFieldDataId: deletedRows,
      deletedMedicalDocuments: deletedDocuments,
      documents: documentUrls,
      formDataRequests: formDataRequest,
      patientId: pId,
      testDataId: selectedRow.testDataId,
      timeZone: moment.tz.guess(),
    };
    var addTestField;
    if (recordsType == "Laboratory") addTestField = addTestFieldData(data);
    else if (recordsType == "Radiology")
      addTestField = addTestFieldDataRadiology(data);
    if (addTestField)
      addTestField
        .then((response) => {
          if (response.data.status == 1) {
            console.log(response.data.body);
            message.success(response.data.body);
            setIsSaveChanges(false);
          } else message.error(response.data.body);
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setFormDataRequests([]);
          setDeletedRows([]);
          fetchData();
        });
    else {
      message.error("Sorry! something went wrong");
      history.push("/dashboard");
    }
  };

  const onValuesChange = (changed, all) => {
    console.log(changed, all);
    let changedParentKey = Object.keys(changed)[0];
    let parentField = metaDataFieldIds.find(
      (item) => item.id == changedParentKey
    );
    forms.forEach((formTemp) => {
      metaDataFieldIds.forEach((metaDataField) => {
        let metaField = formTemp.fields.find(
          (field) => field.name === metaDataField.id
        );
        if (metaField)
          if (metaDataField?.parentFieldId == changedParentKey) {
            metaField.meta = metaDataField?.dropDownValues
              ?.filter((item) => {
                let values = parentField?.dropDownValues?.filter(
                  (parentValues) => parentValues.id == item.parentFieldId
                )[0];
                if (values.value == changed[changedParentKey])
                  return item.value ? item.value : item.formName;
              })
              .map((item) => (item.value ? item.value : item.formName));
          } else console.log("Meta undefined");
      });
    });
    setForms([...forms]);
    if (parentChildMetaIds[changedParentKey]) {
      let dependedChilds = {};
      parentChildMetaIds[changedParentKey].forEach((item) => {
        var intValue = parseInt(item);
        dependedChilds[intValue] = undefined;
      });
      form.setFieldsValue(dependedChilds);
    }
  };

  return loading ? (
    <Spinner height="calc(100vh - 200px)" />
  ) : (
    <Row>
      <Row style={{ width: "100%" }}>
        <StyledCol>
          <Row>
            <StyledCol>
              <div style={{ padding: "20px" }}>
                <h3
                  style={{
                    color: theme["@primary-color"],
                    padding: "0 0 10px 10px",
                    fontWeight: "600",
                  }}
                >
                  Test Result
                </h3>
                {tableLoading ? (
                  <Spinner />
                ) : (
                  <BlueBgTable
                    scroll={{ x: "max-content" }}
                    dataSource={tableData}
                    columns={columnsTest(
                      fields,
                      theme,
                      deletedRows,
                      setDeletedRows,
                      tableData,
                      setTableData,
                      formDataRequests,
                      setFormDataRequests,
                      setIsSaveChanges
                    )}
                    pagination={false}
                    color={theme["@primary-color"]}
                  />
                )}
                {/* </Form.Item> */}
              </div>
            </StyledCol>
          </Row>
        </StyledCol>
      </Row>
      <StyledRow style={{ marginBottom: "20px" }}>
        <Col style={{ width: "100%" }}>
          <Row style={{ width: "100%" }}>
            <StyledCol>
              <Form
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                form={form}
                scrollToFirstError={true}
              >
                {forms && (
                  <>
                    {forms.map(
                      (subForm) =>
                        subForm.fields.length > 0 && (
                          <>
                            <FormBuilder
                              formConfig={[
                                {
                                  inputType: "heading",
                                  label: subForm.formName,
                                },
                                ...subForm.fields,
                              ]}
                            />
                            <Row
                              justify="end"
                              style={{
                                paddingBottom: "40px",
                                paddingTop: "20px",
                              }}
                            >
                              <StyledSubmit
                                // disabled={!readyToSubmit}
                                htmlType="submit"
                                type="primary"
                                className="btn-save"
                              >
                                Add
                              </StyledSubmit>
                            </Row>
                          </>
                        )
                    )}

                    {isDocumentUpload && (
                      <div>
                        <DocumentUpload
                          setIsSaveChanges={setIsSaveChanges}
                          onChange={setFiles}
                          value={files}
                          selectedItem={{ documentUrls: files }}
                          deleteSelected={true}
                          validFormats={isDocumentUpload.validFormats?.map(
                            (item) => item.value
                          )}
                          isMultiple={
                            isDocumentUpload.fieldType == "MULTIPLE_UPLOAD"
                              ? true
                              : false
                          }
                        />
                      </div>
                    )}
                  </>
                )}
                <Row justify="end">
                  <StyledSubmit
                    className="btn-cancel"
                    type="primary"
                    ghost
                    style={{ border: "none", boxShadow: "none" }}
                    onClick={() => setEditTestResults(false)}
                  >
                    Cancel
                  </StyledSubmit>
                  {/* <StyledSubmit
                    type="primary"
                    ghost
                    style={{ minWidth: "10vw" }}
                    // onClick={() => saveChanges()}
                    // htmlType="submit"
                  >
                    Preview
                  </StyledSubmit> */}
                  <StyledSubmit
                    disabled={!(forms && forms.length > 0) || !isSaveChanges}
                    // htmlType="submit"
                    onClick={() => submitForm()}
                    type="primary"
                    className="btn-save"
                  >
                    Save Changes
                  </StyledSubmit>
                </Row>
              </Form>
            </StyledCol>
          </Row>
        </Col>
      </StyledRow>
    </Row>
  );
};
export default EditTestResults;
