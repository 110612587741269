import React, { useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { BlueBgTable, EmptyMessageContainer } from "../styledComponents";
import { Empty } from "antd";

import Column from "./TableConfig";
import Spinner from "../../../utils/Spinner";

const ChiefComplaintTable = ({
  dataSource,
  loading,
  pagination,
  setEditModal,
  setselectedItem,
  setDeleteModal,
  columns,
  setDataSource,
  draggable,
  preventLast,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  return loading ? (
    <Spinner height="100px" />
  ) : dataSource && dataSource.length > 0 ? (
    <BlueBgTable
      // headBg={theme["@primary-color"]}
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
    />
  ) : (
    <EmptyMessageContainer>
      <Empty />
    </EmptyMessageContainer>
  );
};
export default ChiefComplaintTable;
