import React, { useState, useEffect } from "react";
import { NewButton } from "./styledComponents";
import { UserAddOutlined } from "@ant-design/icons";
import { Row, Col, message } from "antd";
import {
  SectionHeading,
  SectionHeadContainer,
  StyledDiv,
} from "../styledComponents";
import { getWardCategory as fetchWardCategorys } from "../../../../apis/wardCategory";
import WardCategoriesTable from "./WardCategoriesTable";
import NewDocumentModal from "./NewDocumentModal";
import ConfirmationModal from "./ConfirmationModal";
import { BodyContainer } from "../Sections/styledComponents";
import { useSelector } from "react-redux";
import { getHospitalId } from "../../../utils/LocalStorage";
const WardCategory = () => {    
  const [wardCat, setWardCats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setselectedItem] = useState();
  const theme = useSelector((state) => state.themeReducer);
  const getWardCategorys = () => {
    setLoading(true);
    fetchWardCategorys(getHospitalId())
      .then(({ data, status }) => {
        // if (status === 200) {
        //   if (data.status == 1) setWardCats(data.body);
        // } else {
        //   message.error("Unable to fetch Ward Category");
        // }
        setWardCats(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const newWardCatClickHandler = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    getWardCategorys();
  }, []);
  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading>Ward Categories</SectionHeading>
            </Col>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <NewButton
                type="primary"
                // ghost
                // size="large"
                icon={<UserAddOutlined />}
                onClick={newWardCatClickHandler}
              >
                New Ward Category
              </NewButton>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <StyledDiv bgcolor={theme["@primary-color"]}>
            <div>&nbsp;&nbsp;Ward Categories</div>
            <div>&nbsp;&nbsp;</div>
          </StyledDiv>
          <WardCategoriesTable
            wardCat={wardCat}
            loading={loading}
            setEditModal={setModalVisible}
            setDeleteModal={setDeleteModal}
            setselectedItem={setselectedItem}
          />
        </BodyContainer>
      </Col>
      <NewDocumentModal
        visible={modalVisible}
        setVisible={setModalVisible}
        refetch={() => getWardCategorys()}
        selectedItem={selectedItem}
        setSelectedItem={setselectedItem}
      />
      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        selectedItem={selectedItem}
        setSelectedItem={setselectedItem}
        refetch={() => getWardCategorys()}
      />
    </Row>
  );
};

export default WardCategory;
