import {
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  Radio,
  Checkbox,
  InputNumber,
  Divider,
  Form,
  Tooltip,
} from 'antd';
import {
  CaretDownOutlined,
  PlusOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Option } from 'antd/lib/mentions';
import { getCountries } from '../../../apis/publicApis';
import { InputComponents } from './InputComponents';
import { DocumentUpload } from '../../Content/PatientForm/DocumentUpload';
import { InputComponentForSearchDropDown } from './InputComponentForSearchDropDown';

const { TextArea } = Input;
const capitalize = (camelCaseText) => {
  return (
    camelCaseText.charAt(0).toUpperCase() +
    camelCaseText
      .slice(1)
      .split(/(?=[A-Z])/)
      .join(' ')
  );
};
const StyledTextArea = styled(TextArea)`
  min-height: 110px !important;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const StyledInputNumber = styled(InputNumber)`
  && {
    width: 100%;
    border-color: ${(props) => (props.isExceptional ? 'red' : '')};
    border-width: ${(props) => (props.isExceptional ? '2px' : '')};
    color: ${(props) => (props.isExceptional ? 'red' : '')};
  }
`;
const StyledInputNumber1 = styled(InputNumber)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  && {
    width: 100%;
    height: 40%;
    border-color: ${(props) => (props.isExceptional ? 'red' : '')};
    border-width: ${(props) => (props.isExceptional ? '2px' : '')};
    color: ${(props) => (props.isExceptional ? 'red' : '')};
  }
`;

const StyledCheckBox = styled(Checkbox)`
  width: 100%;
  padding: 5px 5px 5px 10px;
  &.ant-checkbox-wrapper {
    border: 1px solid #d5d5d5;
  }
  &.ant-checkbox-wrapper-checked {
    background: #bee7ff;
    border: none;
  }
  & .ant-checkbox {
    display: none;
  }
  & .ant-checkbox-checked {
    display: inline-block;
    border-radius: 50%;
    /* overflow: hidden; */
  }
`;

const DefaultCheckBox = styled(Checkbox)`
  & .ant-checkbox + span {
    color: black !important;
    font-weight: normal;
    padding: 0 5px !important;
  }
  & .ant-checkbox + span {
    color: rgb(0, 0, 0);
    /* font-weight: 600; */
  }
`;

const StyledInput = styled(Input)`
  && {
    border-color: ${(props) => (props.isExceptional ? 'red' : '')} !important;
    border-width: ${(props) => (props.isExceptional ? '2px' : '')} !important;
    color: ${(props) => (props.isExceptional ? 'red' : '')} !important;
  }
  &&:focus,
  &&:hover {
    border-right-width: ${(props) =>
      props.isExceptional ? '2px' : ''} !important;
  }
`;

const StyledCheckGroup = styled(Checkbox.Group)`
  & .ant-checkbox-wrapper {
    padding: 10px 15px;
    border-radius: 5px;
    border: 2px solid #d8d8d8;
    margin-right: 13px;
    text-align: center;
  }

  & .ant-checkbox {
    display: none;
  }
  & .ant-checkbox-wrapper-checked {
    border: 2px solid ${(props) => props.bgColor};
    color: ${(props) => props.bgColor};
  }
  & .ant-checkbox-checked {
    display: inline-block;
    border-radius: 50%;
    /* overflow: hidden; */
  }
  & .ant-checkbox-checked .ant-checkbox-inner::after {
    /* border-color: ${(props) => props.bgColor}!important; */
    border-color: red;
    border-color: ${(props) => props.bgColor}!important;
    top: 3px;
    left: 4px;
    background: #fff;
    width: 5px;
    height: 12px;
  }
  & .ant-checkbox-inner {
    border-radius: 50%;
    padding: 7px;
    border-width: 2px;
    border-top: none;
    border-color: ${(props) => props.bgColor};
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff;
  }
  @media (max-width: 767px) {
    & .ant-checkbox-wrapper {
      margin-bottom: 13px;
    }
    & .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px !important;
    }
  }
`;

const StyledSelectPhone = styled(Select)`
  &.tag-select .ant-select-selector .ant-select-selection-item {
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: clip;
  }
  &.ant-select-selection-item {
    overflow: hidden;
    text-overflow: clip;
    white-space: break-spaces;
  }
`;

const StyledMultiSelect = styled(Select)`
  &.tag-select .ant-select-selector .ant-select-selection-item {
    line-height: 26px !important;
    min-height: 50px;
    display: flex;
    align-items: center;
  }
`;
const codeList = [];
const AddItem = ({ formInstance, fieldId }) => {
  const [val, setVal] = useState('');

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
      <Input
        size="small"
        value={val}
        onChange={(e) => {
          setVal(e.target.value);
        }}
      />
      <a
        style={{
          flex: 'none',
          padding: '8px',
          display: 'block',
          cursor: 'pointer',
        }}
        onClick={() => {
          formInstance.setFieldsValue({ [fieldId]: val });
        }}
      >
        <PlusOutlined /> Add
      </a>
    </div>
  );
};

export const getFormItem = (
  item,
  softValidators,
  formInstance,
  theme,
  disabled
) => {
  console.log('prop heere', item.disabled);

  const StyledRadioButton = styled(Radio)`
    padding: 10px;
    & .ant-radio-checked .ant-radio-inner::after {
      background-color: ${theme['@primary-color']};
      /* border: 2px solid white; */
      /* box-shadow: 0px 0px 5px ${theme['@primary-color']}; */
      /* outline: 1px solid ${theme['@primary-color']}; */
    }
    & .ant-radio-inner::after {
      background-color: ${theme['@primary-color']} !important;
      box-shadow: 0px 0px 5px ${theme['@primary-color']};
      outline: 1px solid ${theme['@primary-color']};
    }
    & .ant-radio-inner::before {
      background-color: ${theme['@primary-color']} !important;
      box-shadow: 0px 0px 5px ${theme['@primary-color']};
      outline: 1px solid ${theme['@primary-color']};
    }
    .ant-radio-input {
      opacity: 1;
    }
    .ant-radio-inner {
      opacity: 0;
    }
  `;
  const disabledDate = (current, past) => {
    // Can not select days before today and today
    if (past) {
      return current && current > moment().endOf('day');
    } else {
      return current && current < moment().endOf('day');
    }
  };

  const CheckboxContainer = (props) => {
    const { value, onChange, item, checked } = props;
    useEffect(() => {}, [value]);
    return (
      <StyledCheckBox.Group
        style={{ width: '100%' }}
        size="large"
        bgColor={theme['@primary-color']}
        value={checked}
        // checked={checked}
        onChange={onChange}
      >
        <Row gutter={[24, 24]}>
          {item.meta.map((item) => (
            <Col span={6}>
              <StyledCheckBox size="large" value={item}>
                {item}
              </StyledCheckBox>
            </Col>
          ))}
        </Row>
      </StyledCheckBox.Group>
    );
  };

  const CheckboxContainerDefault = (props) => {
    const { value, onChange, item, checked } = props;
    useEffect(() => {}, [value]);
    return (
      <DefaultCheckBox.Group
        style={{ width: '100%', color: 'black' }}
        size="large"
        // bgColor={theme["@primary-color"]}
        value={checked}
        // checked={checked}
        onChange={onChange}
      >
        <Row gutter={[24, 24]} style={{ marginLeft: '10px' }}>
          {item.meta.map((item) => (
            <Col span={6}>
              <DefaultCheckBox size="large" value={item}>
                {item}
              </DefaultCheckBox>
            </Col>
          ))}
        </Row>
      </DefaultCheckBox.Group>
    );
  };
  const CheckboxContainerWithInputField = (props) => {
    const { value, onChange, item, checked } = props;

    const handleCheckboxChange = (newChecked) => {
      let temp = {};
      newChecked.forEach((dat) => {
        temp[dat] =
          checked && typeof checked === 'object' ? checked[dat] ?? '' : '';
      });
      console.log('temp is', temp);
      onChange(temp);
    };

    const handleInputChange = (newValue, item) => {
      let temp = { ...checked };
      temp[item] = newValue;
      onChange(temp);
    };

    return (
      <DefaultCheckBox.Group
        style={{ width: '100%', color: 'black' }}
        size="large"
        bgColor={theme['@primary-color']}
        value={
          checked && typeof checked === 'object'
            ? Object.keys(checked)
            : undefined
        }
        onChange={handleCheckboxChange}
      >
        <Row gutter={[24, 24]} style={{ marginLeft: '10px' }}>
          {item.meta.map((item) => (
            <Col span={6}>
              <DefaultCheckBox size="large" value={item}>
                {item}
              </DefaultCheckBox>
              <StyledInputNumber1
                size="large"
                type="number"
                value={
                  checked && typeof checked === 'object'
                    ? checked[item] ?? ''
                    : ''
                }
                disabled={
                  checked && typeof checked === 'object'
                    ? checked.hasOwnProperty(item)
                      ? false
                      : true
                    : true
                }
                onChange={(newValue) => handleInputChange(newValue, item)}
              />
            </Col>
          ))}
        </Row>
      </DefaultCheckBox.Group>
    );
  };

  const CheckboxContainerWithTooltip = (props) => {
    const { value, onChange, item, checked } = props;
    console.log('item in checkbox', item.meta);
    useEffect(() => {}, [value]);
    return (
      <DefaultCheckBox.Group
        style={{ width: '100%', color: 'black' }}
        size="large"
        // bgColor={theme["@primary-color"]}
        value={checked}
        // checked={checked}
        onChange={onChange}
      >
        <Row gutter={[24, 24]} style={{ marginLeft: '10px' }}>
          {item.meta.map((item) => (
            <Col span={6} key={item.value}>
              <Tooltip title={item.description}>
                <DefaultCheckBox size="large" value={item.value}>
                  {item.value}
                </DefaultCheckBox>
              </Tooltip>
            </Col>
          ))}
        </Row>
      </DefaultCheckBox.Group>
    );
  };
  switch (item.inputType) {
    case 'date':
      return (
        <StyledDatePicker
          getPopupContainer={(trigger) => trigger.parentNode}
          disabledDate={(current) =>
            disabledDate(current, item.past === undefined ? true : item.past)
          }
          size="large"
          format="DD-MM-YYYY"
          placeholder={item.placeholder || 'Select Date'}
          disabled={item.disabled}
        />
      );
    case 'select':
      return (
        <Select
          size="large"
          suffixIcon={<CaretDownOutlined showSearch />}
          placeholder={item.placeholder || item.label}
          disabled={item.disabled}
          showSearch
          getPopupContainer={(trigger) => trigger.parentNode}
          dropdownRender={(menu) => (
            <div>
              {menu}
              {formInstance && (
                <>
                  <Divider style={{ margin: '4px 0' }} />
                  <AddItem formInstance={formInstance} fieldId={item.name} />
                </>
              )}
            </div>
          )}
        >
          {item.meta.map((item) => (
            <Select.Option
              value={typeof item === 'string' ? item : item.code}
              key={typeof item === 'string' ? item : item.code}
            >
              {typeof item === 'string' ? item : item.text}
            </Select.Option>
          ))}
        </Select>
      );
    case 'select-scroll':
      return <InputComponents item={item} formInstance={formInstance} />;
    case 'select-scroll-search':
      return (
        <InputComponentForSearchDropDown
          item={item}
          formInstance={formInstance}
        />
      );

    case 'select-multi':
      return (
        <StyledMultiSelect
          className="tag-select"
          size="large"
          suffixIcon={<CaretDownOutlined showSearch />}
          placeholder={item.placeholder || item.label}
          getPopupContainer={(trigger) => trigger.parentNode}
          removeIcon={
            <CloseCircleOutlined
              style={{ fontSize: '13px', color: theme['@primary-color'] }}
            />
          }
          mode="multiple"
        >
          {item.meta.map((item) => (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          ))}
        </StyledMultiSelect>
      );
    case 'input':
      return (
        <StyledInput
          size="large"
          placeholder={item.placeholder || item.label}
          disabled={item.disabled}
          type={item.name === 'age' && 'number'}
          isExceptional={softValidators ? softValidators[item.name] : false}
        />
      );
    case 'radio':
      return (
        <StyledRadioButton.Group
          disabled={item.disabled}
          defaultValue={item.defaultValue ? item.defaultValue : ''}
        >
          {(item.meta.length ? item.meta : ['Yes', 'No']).map((value) => (
            // {item.meta?.map((value) => (
            <StyledRadioButton value={value} name={item.name}>
              {value == 'copay' ? 'CoPay' : capitalize(value)}
            </StyledRadioButton>
          ))}
        </StyledRadioButton.Group>
      );
    case 'text-area':
      return (
        <StyledTextArea
          placeholder={item.placeholder || item.label}
          allowClear
          size="large"
          disabled={item.disabled}
        />
      );
    case 'check-box':
      return <CheckboxContainer item={item} />;

    case 'check-box-default':
      return <CheckboxContainerDefault item={item} />;
    case 'check-box-with-input-field':
      return <CheckboxContainerWithInputField item={item} />;
    case 'check-box-with-tooltip':
      return <CheckboxContainerWithTooltip item={item} />;
    case 'button-check-box':
      return (
        <StyledCheckGroup bgColor={theme['@primary-color']}>
          {item.meta.map((item) => (
            <Checkbox value={item}>{item}</Checkbox>
          ))}
        </StyledCheckGroup>
      );
    case 'inputNumber':
      return (
        <Input
          size="large"
          placeholder={item.placeholder || item.label}
          disabled={item.disabled}
          addonBefore={<Select />}
          type="number"
        />
      );
    case 'number':
      return (
        <StyledInputNumber
          size="large"
          placeholder={item.placeholder || item.label}
          disabled={item.disabled}
          type="number"
          isExceptional={softValidators ? softValidators[item.name] : false}
        />
      );
    case 'phoneNumber':
      return <PhoneNumber item={item} />;
    default:
      return null;
  }
};

const PhoneNumber = ({ item, value = {}, onChange }) => {
  return (
    <>
      <Form.Item noStyle>
        <StyledSelectPhone
          size="large"
          suffixIcon={<CaretDownOutlined showSearch />}
          placeholder={'Country Code'}
          autosize={true}
          style={{ width: '30%' }}
          showSearch
          value={value && value.countryCode}
          onChange={(val) => {
            onChange({ ...value, countryCode: val });
          }}
          getPopupContainer={(trigger) => trigger.parentNode}
          disabled={item.disabled == true ? true : false}
          // optionFilterProp="children"
          // filterOption={(input, option) =>
          //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          //   || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // }
        >
          {item.meta.map((item) => (
            <Select.Option
              value={'+' + item.code + ' ' + item.name}
              key={item.name}
            >
              {'+' + item.code + ' ' + capitalize(item.name)}
            </Select.Option>
          ))}
        </StyledSelectPhone>
      </Form.Item>
      <Form.Item noStyle>
        <Input
          value={value && value.phoneNumber}
          style={{ width: '65%', marginLeft: '2%' }}
          placeholder={item.placeholder || item.label}
          onChange={(e) => {
            onChange({ ...value, phoneNumber: e.target.value });
          }}
          disabled={item.disabled == true ? true : false}
        />
      </Form.Item>
    </>
  );
};
