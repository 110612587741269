import React from "react";
import { Modal, Row, Col } from "antd";
import styled from "styled-components";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
  &.pop-modal {
    max-width: 100% !important;
  }
  &.pop-modal .ant-modal-body {
    padding: 15px 15px;
  }
  &.ant-modal {
    top: 20px;
    padding: 22px 15px;
  }
  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    font-size: 12px;
    text-transform: capitalize;
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }

  .popup-form-data .ant-col label {
    color: #8e8c8c;
    font-size: 15px;
  }
  .popup-form-data .ant-col label:before {
    display: none;
  }
  .popup-form-data .ant-col label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    font-weight: 700;
    content: "*";
  }
  .popup-form-data .ant-col .ant-input {
    border-radius: 5px;
    border: 1px solid #cccccc;
  }
  .popup-form-data .ant-col textarea {
    resize: none;
  }
  button.submit-btn {
    height: 44px;
    font-weight: 600;
    letter-spacing: 0.02em;
    background-color: #0f70bc !important;
    border: 1px solid transparent;
    width: 100%;
    font-size: 18px;
    width: 100px;
    border-radius: 5px;
  }
  button.submit-btn.w-100 {
    width: 100%;
  }
  .btn-send-bx {
    margin: 0px;
  }
  .cover-width {
    margin: 20px 20px;
  }
  .cover-width h2 {
    text-align: center;
  }

  @media (max-width: 767px) {
    .popup-box-content {
      width: 100% !important;
    }
  }
  @media (max-width: 600px) {
    .cover-width {
      margin: 20px 0px;
    }
  }
`;

const CustomModal = ({
  visible,
  setVisible,
  antdMOdalProps,
  children,
  isClosable,
  type,
}) => {
  const history = useHistory();
  return (
    <StyledModal
      visible={visible}
      {...antdMOdalProps}
      closable={false}
      footer={null}
      width={"max-content"}
      destroyOnClose
      afterClose={() => {
        if (type == "submitReport") history.push("/");
      }}
      className="pop-modal"
    >
      {isClosable && (
        <Row justify="end" style={{ height: "25px" }}>
          <Col>
            <CloseCircleOutlined
              style={{
                fontSize: "20px",
                color: "red",
                position: "absolute",
                right: "-7px",
                top: "-7px",
              }}
              onClick={() => {
                setVisible(false);
              }}
            />
          </Col>
        </Row>
      )}
      {children}
    </StyledModal>
  );
};

export default CustomModal;
