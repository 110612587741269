import React, { useEffect, useCallback } from "react";
import { Button, Col, Row, message, Select } from "antd";
import { BodyContainer } from "../Sections/styledComponents";
import {
  SectionHeadContainer,
  SectionHeading,
  StyledDiv,
} from "../styledComponents";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/Import.svg";
import {
  DownloadOutlined,
  SearchOutlined,
  ExportOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import DictionaryTable from "./DictionaryTable";
import { useState } from "react";
import ImportModal from "./ImportModal";
import * as Sentry from "@sentry/react";
import {
  importDrugs,
  getDrugsList,
  removeDrug,
  addOrModifyDrug,
  exportDrugs,
  exportDictionary,
  importDictionary,
  getDictionaryList,
  addOrModifyDictionary,
  removeAllergy,
} from "../../../../apis/Admin";
import {
  StyledSearch,
  SearchContainer,
  AllergyContainer,
  UserTypeSelect,
} from "./styledComponents";
import { useDebounce } from "../../HealthRecords/ConcentForm/helper";
import DictionaryDetailsModal from "./DictionaryDetailsModal";
import DeleteModal from "./DeleteModal";
import Spinner from "../../../utils/Spinner";
import { codeTypes } from "../../../Datasets/codeTypes";
import { useSelector } from "react-redux";

const Allergies = () => {
  const [dataSource, setDataSource] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [total, setTotal] = useState();
  const [codeType, setCodetype] = useState();
  const theme = useSelector((state) => state.themeReducer);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newMedicineModal, setNewMedicineModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const getDictionary = (page, size, name) => {
    console.log(page, size, name, "page,size,name");
    setLoading(true);
    getDictionaryList(page, size, name)
      .then((resp) => {
        if (resp.status === 200) {
          console.log("list::", resp.data.body);
          setDataSource(resp.data.body);
          setTotal(resp.data.total);
          setLoading(false);
        } else {
          message.error("resp.data.body");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    getDictionary(pageNo, 15, codeType);
  }, [codeType, pageNo]);

  const onChangeHandler = (value) => {
    setCodetype(value);
  };

  const uploadFile = (data) => {
    setLoading(true);
    setImportLoading(true);
    importDictionary(data)
      .then((resp) => {
        if (resp.data.status === 1) {
          message.success(resp.data.body);
          setModalVisible(false);
          getDictionary(pageNo, 15, codeType);
        } else {
          setLoading(false);
          message.error(resp.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      })
      .finally(() => {
        setImportLoading(false);
      });
  };

  const debounceOnChange = useCallback(useDebounce(onChangeHandler, 1000), []);

  const deleteDictionary = () => {
    removeAllergy(selectedItem.id)
      .then((resp) => {
        if (resp.data.status === 1) {
          message.success("Removed dictionary");
          setDeleteModal(false);
          setSelectedItem(null);
          getDictionary(pageNo, 15, codeType);
        } else {
          message.error("Could not remove dictionary, Try Again");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const submitHandler = (values) => {
    let payload = values;
    if (editModal) {
      payload.id = selectedItem.id;
    }

    addOrModifyDictionary(payload)
      .then((resp) => {
        console.log("response::", resp);
        if (resp.data.status === 1) {
          message.success(
            `Successfully ${editModal ? "modified" : "added"} Dictionary`
          );
          setEditModal(false);
          setNewMedicineModal(false);
          setSelectedItem(null);
          getDictionary(pageNo, 15, codeType);
        } else {
          message.error(
            `Could not ${editModal ? "modify" : "add"} Dictionary, Try Again`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const exportData = () => {
    setExportLoading(true);
    exportDictionary()
      .then((res) => {
        var fileDownload = require("js-file-download");
        fileDownload(res.data, "dictionary.xlsx");
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      })
      .finally(() => {
        setExportLoading(false);
      });
  };
  const camelCaseText = (list) => {
    var formattedList = [];

    list.forEach((code) => {
      var result = "";
      for (var i = 0; i < code.length; i++) {
        if (i === 0) {
          result += code[i];
        } else {
          if (code[i] === "_") {
            result += " ";
          } else {
            result += code[i].toLowerCase();
          }
        }
      }
      formattedList.push(result);
    });
    return formattedList;
  };
  // return loading ? (
  //   <Spinner />
  // ) : (
  useEffect(() => {
    getDictionary(0, 15, codeType);
    setPageNo(0);
  }, [codeType]);
  return (
    <AllergyContainer>
      <Row>
        <Col span={24}>
          <SectionHeadContainer>
            <Row justify="space-between" align="middle">
              <Col>
                <SectionHeading>Dictionary</SectionHeading>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  onClick={() => setModalVisible(true)}
                  icon={<DownloadOutlined />}
                  style={{ marginRight: "10px", borderRadius: "5px" }}
                >
                  Import
                </Button>

                <Button
                  type="primary"
                  size="large"
                  onClick={() => exportData()}
                  icon={<ExportOutlined />}
                  style={{ borderRadius: "5px" }}
                  loading={exportLoading}
                >
                  Export
                </Button>
              </Col>
            </Row>
          </SectionHeadContainer>
        </Col>
        <Col span={24}>
          <BodyContainer>
            <Row gutter={[24, 24]}>
              <Col span={24} style={{ padding: "15px 15px 0 15px" }}>
                <Row justify="space-between">
                  <Col span={4}>
                    <SearchContainer>
                      <UserTypeSelect
                        size="large"
                        suffixIcon={<CaretDownOutlined showSearch />}
                        placeholder={"Filter"}
                        showSearch
                        // style={{ width: "100%" }}
                        onChange={(value) => {
                          let val = value === "ALL" ? undefined : value;
                          // debounceOnChange(value);
                          setCodetype(val);
                          // getDictionary(0, 15, val);
                          setPageNo(0);
                        }}
                      >
                        {camelCaseText(codeTypes()).map((item, index) => (
                          <Select.Option value={codeTypes()[index]} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </UserTypeSelect>
                    </SearchContainer>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      ghost
                      style={{ border: 0, boxShadow: "none" }}
                      onClick={() => {
                        setNewMedicineModal(true);
                      }}
                    >
                      Add New Dictionary
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <StyledDiv bgcolor={theme["@primary-color"]}>
                  <div>&nbsp;&nbsp;Dictionary</div>
                  <div>&nbsp;&nbsp;</div>
                </StyledDiv>
                {!loading ? (
                  <DictionaryTable
                    loading={loading}
                    dataSource={dataSource}
                    pageSize={15}
                    setPageNo={setPageNo}
                    pageNo={pageNo}
                    total={total}
                    setEditModal={setEditModal}
                    setDeleteModal={setDeleteModal}
                    setSelectedItem={setSelectedItem}
                  />
                ) : (
                  <Spinner height={"100px"} />
                )}
              </Col>
            </Row>
          </BodyContainer>
        </Col>
      </Row>
      <ImportModal
        visible={modalVisible}
        setVisible={setModalVisible}
        uploadFile={uploadFile}
        loading={importLoading}
      />
      <DictionaryDetailsModal
        visible={editModal ? editModal : newMedicineModal}
        setVisible={editModal ? setEditModal : setNewMedicineModal}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        edit={editModal}
        submitHandler={submitHandler}
        camelCaseText={camelCaseText}
      />

      <DeleteModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        deleteDrug={deleteDictionary}
        loading={deleteLoading}
      />
    </AllergyContainer>
  );
};

export default Allergies;
