import { ADD_IMAGES } from "../actions/appImages";

const initialState = {};
export const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_IMAGES:
      return action.payload;
    default:
      return state;
  }
};
