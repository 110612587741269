import React, { useState, useEffect } from "react";
import { BodyContainer } from "../Sections/styledComponents";
import {
  Tabs,
  Button,
  message,
  Col,
  Row,
  Dropdown,
  Menu,
  Space,
  Modal,
  Radio,
  Divider,
  InputNumber,
  Table,
  Input,
} from "antd";
import { useSelector } from "react-redux";
import { NewButton } from "./styledComponents";
import { UserAddOutlined } from "@ant-design/icons";
import DiscountTable from "./DiscountTable";
import {
  getWaitingDiscount as fetchDiscount,
  submitRequestDiscount,
} from "../../../../apis/healthRecordIPD";

import {
  SectionHeading,
  SectionHeadContainer,
  StyledDiv,
} from "../styledComponents";

const Discount = () => {
  const theme = useSelector((state) => state.themeReducer);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setselectedItem] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [discount, setDiscount] = useState();

  const getDiscount = () => {
    setLoading(true);
    fetchDiscount()
      .then(({ data, status }) => {
        setDiscounts(data.body);
        // setLoading(false); // Move this inside the .then block
      })
      .catch(() => {
        // Handle error
      })
      .finally(() => {
        setLoading(false);
        // Move this inside the .finally block
      });
  };

  useEffect(() => {
    getDiscount(); // Call getDiscount inside useEffect to ensure it runs after component mounts
  }, []);

  useEffect(() => {
    // Use this useEffect to observe changes in discounts
    console.log("Updated discounts:", discounts);
  }, [discounts]);

  const handleModalSubmit = (status) => {
    if (status == 0) {
      // Rejected
      submitRequestDiscount({
        ipdId: selectedItem.id,
        discountPrcnt: discount,
        discountStatus: "Declined",
      })
        .then((response) => {
          message.success("Succesfully Discount Declined.");
          getDiscount();
        })
        .catch((err) => {
          console.log(err);
          message.error("Something went wrong!");
        });
    } else {
      submitRequestDiscount({
        ipdId: selectedItem.id,
        discountPrcnt: discount,
        discountStatus: "Approved",
      })
        .then((response) => {
          message.success("Succesfully Discount Approved.");
          getDiscount();
        })
        .catch((err) => {
          console.log(err);
          message.error("Something went wrong!");
        });
    }
    setModalVisible(false);
  };

  const handleDiscountChange = (value) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    setDiscount(numericValue);
  };

  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading>Pending Discount</SectionHeading>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <StyledDiv bgcolor={theme["@primary-color"]}>
            <div>&nbsp;&nbsp;Discount Request</div>
            <div>&nbsp;&nbsp;</div>
          </StyledDiv>
          <DiscountTable
            discounts={discounts}
            loading={loading}
            setEditModal={setModalVisible}
            setselectedItem={setselectedItem}
            setDiscount={setDiscount}
          />
        </BodyContainer>
      </Col>
      <Modal
        title="Input Discount"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="reject"
            type="primary"
            style={{ backgroundColor: "red", borderColor: "red" }}
            onClick={() => handleModalSubmit(0)}
          >
            Reject
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleModalSubmit(1)}
          >
            Approve
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter discount"
          value={discount}
          onChange={(e) => handleDiscountChange(e.target.value)}
        />
      </Modal>
    </Row>
  );
};

export default Discount;
