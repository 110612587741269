import React, { useRef } from "react";
import styled from "styled-components";
import { Table, Row, Col, Empty } from "antd";
import CustomTable from "../../utils/CustomTable";
import { useSelector } from "react-redux";

const StyledRow = styled(Row)`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
`;

const EmptyMessageContainer = styled.div`
  height: calc(100vh - 450px);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
`;

const Heading = styled.h3`
  font-weight: 600;
  color: ${(props) => props.color};
`;
const TableContainer = ({ tableProps, title, tableTitle, isPrintable }) => {
  const theme = useSelector((state) => state.themeReducer);
  const componentRef = useRef();
  return (
    <StyledRow ref={componentRef}>
      <Col span={24}>
        <Heading color={theme["@primary-color"]}>{title}</Heading>
      </Col>
      <Col span={24}>
        <div>
          {tableProps.dataSource.length > 0 ? (
            <CustomTable
              ref={componentRef}
              {...tableProps}
              tableTitle={tableTitle}
              isPrintable={isPrintable}
              componentRef={componentRef}
            />
          ) : (
            <EmptyMessageContainer>
              <Empty />
            </EmptyMessageContainer>
          )}
        </div>
      </Col>
    </StyledRow>
  );
};
export default TableContainer;
