import axiosInstance from "../config/axiosInstance";

const WARD_CATEGORY_PREFIX = "/category";

export const getWardCategory = () => {
  return axiosInstance.get(`${WARD_CATEGORY_PREFIX}/fetchWardCategoriesByType/ALL`);
};

export const addNewWardCategory = (data) => {
  return axiosInstance.post(`${WARD_CATEGORY_PREFIX}/addCategory`, data);
};

export const updateWardCategory = (id, categoryName) => {
  var data = {
    "categoryName": categoryName
  };
  return axiosInstance.put(`${WARD_CATEGORY_PREFIX}/editCategory/${id}`, data);
};

export const editWardCategory = (data, id) => {
  return axiosInstance.put(`${WARD_CATEGORY_PREFIX}/editCategory/${id}`, data);
};

export const getWardCategoryDetail = (id) => {
  return axiosInstance.get(`${WARD_CATEGORY_PREFIX}/getWard/${id}`);
};

export const deleteWardCategory = (id) => {
  return axiosInstance.delete(`${WARD_CATEGORY_PREFIX}/deleteWardCategory/${id}`);
};
