import React, { useState, useEffect } from "react";
import {
  SectionHeadContainer,
  SectionHeading,
  StyledDiv,
} from "../styledComponents";
import { BodyContainer } from "./StyledComponent";
import RadiologyManagementTable from "./RadiologyManagementTable";
import { fieldsColumn } from "./TableConfig";

import { Row, Col, Button, message, notification } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import {
  fetchLabTests,
  addLabTests,
  fetchRadiologyTests,
  addRadiologyTests,
  updateRadiologyTests,
} from "../../../../apis/Admin";
import NewRadiologyTypeModal from "./NewRadiologyTypeModal";

import ConfirmationModal from "./ConfirmationModal";
import { useSelector } from "react-redux";

const FieldManagement = ({
  setTab,
  selectedForm,
  selectedSection,
  setSelectedField,
}) => {
  const [loading, setLoading] = useState(false);
  const [newFormModal, setNewFormModal] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newFieldModal, setNewFieldModal] = useState(false);
  const [tests, setTests] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedType, setSelectedType] = useState({});
  const [errorModal, setErrorModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [changesOrderStatus, setChangeOrderStatus] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const theme = useSelector((state) => state.themeReducer);

  const newTypeHandler = () => {
    setNewModal(true);
  };

  const getTestType = () => {
    setLoading(true);
    var searchQuery = "type=RADIOLOGY_TEST&parentId=" + selectedForm.id;

    fetchRadiologyTests(searchQuery)
      .then(({ data, status }) => {
        if (data) {
          if (data.status === 1) {
            setTests(data.body);
          } else message.error(data.body);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTestType();
  }, []);
  const addType = (type) => {
    // api to add lab
    const payload = {
      name: type.type,
      sectionId: selectedForm.id,
      type: "RADIOLOGY_TEST",
      fee: type.fee,
    };
    addRadiologyTests(payload).then((data) => {
      // success mesage
      getTestType();
      if (data.data.status === 1) {
        notification.success({
          message: data.data.body,
        });
        getTestType();
      } else {
        notification.error({
          message: data.data.body,
        });
      }
    });
    setNewModal(false);
  };
  const update = (type) => {
    const payload = {
      name: type.type,
      id: selectedItem.id,
      type: "RADIOLOGY_TEST",
      fee: type.fee,
    };

    updateRadiologyTests(payload).then((data) => {
      // success mesage
      getTestType();
      if (data.data.status === 1) {
        notification.success({
          message: data.data.body,
        });
        getTestType();
      } else {
        notification.error({
          message: data.data.body,
        });
      }
    });
    setNewModal(false);
  };

  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row>
            <Col span={24}>
              <SectionHeading>
                <span style={{ color: "#999" }}>
                  Radiology
                  <LeftCircleOutlined
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                  />
                  Radiology Test Type
                  <LeftCircleOutlined
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                    onClick={() => {
                      setTab("radiology_test_type");
                    }}
                  />
                  Radiology Test
                </span>
              </SectionHeading>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <Row gutter={[0, 18]}>
            <Col span={24} style={{ padding: "0" }}>
              <Row justify="space-between">
                <Col span={24} style={{ padding: "2.5px 0" }}>
                  <Row gutter={12} justify="end" align="right">
                    <Button
                      type="primary"
                      ghost
                      size="large"
                      style={{ border: 0, boxShadow: "none" }}
                      onClick={newTypeHandler}
                    >
                      Create New Test
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ paddingTop: "0" }}>
              <StyledDiv bgcolor={theme["@primary-color"]}>
                <div>&nbsp;&nbsp;Tests</div>
                <div>&nbsp;&nbsp;</div>
              </StyledDiv>
              <RadiologyManagementTable
                dataSource={tests}
                columns={fieldsColumn(
                  setTab,
                  setSelectedField,
                  setSelectedItem,
                  setSelectedType,
                  setConfirmationModal,
                  setEdit,

                  setNewModal,

                  setDeleteModal
                )}
                loading={loading}
                draggable={changesOrderStatus}
                setDataSource={setTests}
              />
            </Col>
          </Row>
        </BodyContainer>
      </Col>

      <NewRadiologyTypeModal
        visible={newModal}
        setVisible={setNewModal}
        addType={addType}
        edit={edit}
        setEdit={setEdit}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        update={update}
        head={"Test"}
      />
      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        selectedItem={selectedItem}
        refetch={getTestType}
      />
    </Row>
  );
};
export default FieldManagement;
