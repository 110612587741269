import {
  Button,
  Col,
  Form,
  Popconfirm,
  Row,
  message,
  Tag,
  Radio,
  Input,
  Space,
  Divider,
  Table,
  Select,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  addHealthRecordIPD,
  getHealthRecordForms,
  getPreviousRecords,
} from '../../../../apis/healthRecordIPD';
import * as Sentry from '@sentry/react';
import {
  deserialize,
  generateFormFields,
} from '../../../utils/FormBuilder/valuesSerialisation';
import {
  generatePreSubmitFormFields,
  generatePreviousInfoTableColumns,
  migrateIntoTableFormat,
} from '../helper';
import { tableColumns, tableColumnsPrint } from './columns';
import { dosageData, syrupData, frequencyData } from './prescriptionData';

import { CloseCircleOutlined } from '@ant-design/icons';
import CustomeTableForRquests from '../Laboratory/customTable';
import { DiscardSubmitWrapper } from '../VitalInformation';
import FormBuilder from '../../../utils/FormBuilder';
import NoRecords from '../../../utils/NoRecord';
import Spinner from '../../../utils/Spinner';
import TableContainer from '../TableContainer';
import ViewDetailsModal from '../ViewDetailsModal';
import { fetchFormFieldMeta } from '../../../../apis/forms';
import { fetchMenuSections } from '../../../../apis/healthRecord';
import { getCodes } from '../../../../apis/healthRecord';
import moment from 'moment';
import { softValidate } from '../helper';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getAppointmentDetails } from '../../../../apis/Appointment';
const { Option } = Select;
// import ViewDetailsModal from "./ViewDetailsModal";

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-right: 20px;
  border-radius: 6px !important;
`;
const typos = {
  Allergies: {
    tableContainer: 'Previous Allergy Report',
    tableHead: 'Allergy Report',
  },
  'Medical History': {
    tableContainer: 'Past Medical History',
    tableHead: 'Medical History',
  },
};

const StyledCol = styled(Col)`
  background: #fff;
  margin: 20px 0;
  padding: ${(props) => (props.padding ? props.padding : 'unset')};
  border-radius: 10px;
`;

const StyledH3 = styled.h2`
  color: ${(props) => props.color};
  font-weight: 600;
  margin-left: 20px;
  cursor: pointer;
`;

const MedicalDetails = () => {
  const { sectionId } = useParams();

  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitPharmacyLoading, setSubmitPharmacyLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedInformation, setSelectedInformation] = useState([]);
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const user = useSelector((state) => state.userReducer);
  const theme = useSelector((state) => state.themeReducer);
  const history = useHistory();
  console.log('Patient', user);

  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [preSubmitTableData, setPreSubmitTableData] = useState([]);
  const [preSubmitVisibleTableData, setPreSubmitVisibleTableData] = useState(
    []
  );
  const [preSubmitTableColumns, setPreSubmitTableColumns] = useState([]);
  const [previousDataColumns, setPreviousDataColumns] = useState([]);
  const [previousDataPrintColumns, setPreviousDataPrintColumns] = useState([]);
  const [visible, setVisible] = useState(false);
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const [tableFetchLoading, setTableFetchLoading] = useState(false);
  const [dropDownParentIds, setDropDownParentIds] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);
  const [validationStatus, setValidationStatus] = useState({});
  const [validationCriterias, setValidationCriterias] = useState([]);
  const [retestList, setRetestList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [drugCodes, setDrugCodes] = useState([]);
  const [pageSize, setPageSize] = useState(2);
  const [prescriptionsLoading, setPrescriptionsLoading] = useState(false);
  const [isPrescription, setIsPrescription] = useState(true);
  const [allergySectionId, setAllergySectionId] = useState();
  const [allergyTableData, setAllergyTableData] = useState([]);
  const [allergyPageSize, setAllergyPageSize] = useState();
  const [allergyLoading, setAllergyLoading] = useState(false);
  const [allergyColumns, setAllergyColumns] = useState([]);
  const [printData, setPrintData] = useState();
  const [appointmentDetails, setAppointmentDetails] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [selectedQuantityValue, setSelectedQuantityValue] = useState(null);
  const [selectedFrequencyValue, setSelectedFrequencyValue] = useState(null);
  const [selectedMealOption, setSelectedMealOption] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  const labels = ['Breakfast', 'Lunch', 'Dinner'];
  const timingLabels = ['Morning', 'Lunch', 'Evening', 'Dinner'];

  const [frequencyTagValue, setFrequencyTagValue] = useState('');
  const [labelValues, setLabelValues] = useState({});
  const [timingValues, setTimingValues] = useState({});
  const [fieldNames, setFieldNames] = useState({
    DOSAGE: '',
    FREQUENCY: '',
    INTAKETIMING: '',
    COMPOSITION: '',
    DRUG_CODE: '',
  });
  const columns = [
    {
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = 'blue';

            return (
              <a
                href="#"
                onClick={(e) => handleTagClick(tag, e)} // Add your click handler here
                key={tag}
              >
                <Tag
                  color={color}
                  style={{ marginBottom: '15px' }}
                  className="hover-tags"
                >
                  {tag.toUpperCase()}
                </Tag>
              </a>
            );
          })}
        </>
      ),
    },
  ];

  const frequencyColumns = [
    {
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = 'blue';

            return (
              <a
                href="#"
                onClick={(e) => handleFrequencyTagClick(tag, e)} // Add your click handler here
                key={tag}
              >
                <Tag
                  color={color}
                  style={{ marginBottom: '15px' }}
                  className="hover-tags"
                >
                  {tag.toUpperCase()}
                </Tag>
              </a>
            );
          })}
        </>
      ),
    },
  ];

  const handleTagClick = (tag, event) => {
    if (fieldNames.DOSAGE !== '') {
      const fieldValue = tag; // The value you want to set for the field

      form.setFieldsValue({ [fieldNames.DOSAGE]: fieldValue });
    }
    event.preventDefault();
    console.log(`Tag '${tag}' clicked`);
  };

  const handleMealOptionChange = (e) => {
    setSelectedMealOption(e.target.value);
    // form.setFieldsValue({ inTakeTimingDetail: e.target.value });
  };
  const handleFrequencyTagClick = (tag, event) => {
    setFrequencyTagValue(tag);
    event.preventDefault();
    setTimingValues({});
    setLabelValues({});
  };
  console.log('in take timing', labelValues);

  const handleChange = (label, value) => {
    setLabelValues((prevLabelValues) => ({
      ...prevLabelValues,
      [label]: value,
    }));
    // Set the form field value directly using the updated label and value
    // form.setFieldsValue({ inTakeTimingDetail: { [label]: value } });
    setFrequencyTagValue('');
    setTimingValues({});
    setSelectedMealOption('');
  };

  const handleTimingChange = (label, value) => {
    setTimingValues((prevTimingValues) => ({
      ...prevTimingValues,
      [label]: value,
    }));

    setLabelValues({});
    setFrequencyTagValue('');
  };

  console.log('timing value', timingValues);

  const handleButtonClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
  };

  const handleSelectChangeQuantity = (value) => {
    console.log('quantity', value);
    setSelectedQuantityValue(value);
  };
  const handleSelectChangeFrequency = (value) => {
    setSelectedFrequencyValue(value);
  };

  const appendColumns = (data) => {
    var append = [
      user.userType === 'PATIENT'
        ? {
            width: '0',
          }
        : {
            title: 'Actions',
            render: (e) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '10px',
                  padding: '0 0 !important',
                }}
              >
                <Button
                  size="small"
                  type="primary"
                  style={{ fontSize: '11px', padding: '0 7px' }}
                  onClick={() => repeatPrescriptions(e)}
                >
                  Repeat
                </Button>
              </div>
            ),
          },
      {
        title: 'Sl. No',
        width: '4vw',
        dataIndex: 'Sl No',
        align: 'center',
        key: 'slNo',
      },
      {
        title: 'Date',
        width: '7vw',
        key: 'date',
        dataIndex: 'Date',
        align: 'center',
      },
    ];
    return [...append, ...data];
  };

  const rowSelection = {
    preserveSelectedRowKeys: false,
    selectedRowKeys: selectedRowKeys,
    columnWidth: '3vw',
    onChange: (selectedRowKeys, selectedRows) => {
      // setIsCustomModalForRetestLoading(true)
      setRetestList(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      console.log(tableData);
      // setIsCustomModalForRetestLoading(false)
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  const appendCloseButton = (data, columns, slNo) => {
    var close = [
      {
        width: '3vw',
        render: (value, record, index) => {
          return (
            <Row justify="space-between" gutter={36}>
              {/* <Col>{Array.isArray(value) ? value.join(", ") : value}</Col> */}
              <Col>
                <Popconfirm
                  title="Please confirm to remove"
                  onConfirm={() => {
                    setPreSubmitTableData(
                      data.filter((item) => item.uuid !== record.uuid)
                    );
                    setPreSubmitVisibleTableData(
                      data.filter((item) => item.uuid !== record.uuid)
                    );
                  }}
                >
                  <CloseCircleOutlined
                    style={{
                      fontSize: '20px',
                      color: 'red',
                      marginRight: '1vw',
                    }}
                  />
                </Popconfirm>
              </Col>
            </Row>
          );
        },
      },
    ];
    if (!slNo) {
      var index = [
        {
          title: 'Sl. No',
          width: '5vw',
          align: 'center',
          render: (value, record, index) => index + 1,
        },
      ];
      return [...index, ...columns, ...close];
    } else return [...columns, ...close];
  };

  const getForms = () => {
    var isData = false;

    getHealthRecordForms(sectionId, patient.pId)
      .then(({ data, status }) => {
        console.log('health record', data);
        if (status === 200) {
          if (data.status === 1) {
            const tempFormIds = {};
            var tempMetaDataIds = [];
            var validationList = [];
            var tempParentIds = [];
            data.body.forEach((item) => {
              //getting formIds and corresponding field IDs for submit request Generation
              tempFormIds[item.id] = item.fields.map((field) => field.id);

              //finding fields which needs to call external API for fetching values eg:dropdown
              tempMetaDataIds = [
                ...tempMetaDataIds,
                ...item.fields.filter(
                  (field) =>
                    field.dropDownType !== null &&
                    field.dropDownType !== undefined &&
                    Object.keys(field.dropDownType).length !== 0
                ),
              ];
              //collecting parentIds to call dropdown values api when change value of dependend dropdown, eg: fetching filtered medicine doses after selecting a medicine
              tempParentIds = [
                ...tempParentIds,
                ...item.fields.filter(
                  (field) =>
                    field.parentField !== null &&
                    field.parentField !== undefined
                ),
              ];
              //COLLECTING FIELDS HAVING SOFT VALIDATORS
              validationList = [
                ...validationList,
                ...item.fields
                  .filter(
                    (field) =>
                      field.softValidator !== null &&
                      field.softValidator !== undefined &&
                      field.softValidator.length !== 0
                  )
                  .map((item) => {
                    return {
                      fieldId: item.id,
                      validationCrieteria: item.softValidator.map((val) => ({
                        type: val.validator,
                        value: val.value,
                      })),
                    };
                  }),
              ];
            });

            // columns for presubmit table
            var tempTableColumns = [];
            var tempPreviousDataColumn = [];
            var tempPreviousDataPrintColumn = [];

            //converting forms into formBuilder readable format
            console.log(
              !isData ? data.body : data.body[0].dynamicHealthRecord,
              'fields dynamic'
            );
            let FormsTemp = (
              !isData ? data.body : data.body[0].dynamicHealthRecord
            ).map((item) => {
              //generating presubmit table columns
              tempTableColumns = [
                ...tempTableColumns,
                ...generatePreSubmitFormFields(item.fields),
              ];

              tempPreviousDataColumn = [
                ...tempPreviousDataColumn,
                ...generatePreviousInfoTableColumns(item.fields),
              ];

              tempPreviousDataPrintColumn = item.fields?.map((item) => {
                console.log(item, 'fields');
                return { title: item.formName, dataIndex: item.formName };
              });
              return {
                formName: isData ? item.form.formName : item.formName,
                fields: generateFormFields(item.fields),
              };
            });
            setFormIds(tempFormIds);
            setMetaDataFieldIds(tempMetaDataIds);
            setForms(FormsTemp);
            setPreSubmitTableColumns(tempTableColumns);
            setPreviousDataColumns(tempPreviousDataColumn);
            setPreviousDataPrintColumns(tempPreviousDataPrintColumn);
            setDropDownParentIds(tempParentIds);
            setValidationCriterias(validationList);
            setFormFetchLoading(false);
          } else {
            message.error(data.body);
            setFormFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        setFormFetchLoading(false);

        console.log(err);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    if (fieldNames.FREQUENCY !== '') {
      const frequencydetails =
        Object.keys(labelValues).length === 0 &&
        frequencyTagValue.length === 0 &&
        Object.keys(timingValues).length === 0
          ? ''
          : frequencyTagValue.length !== 0
          ? `${frequencyTagValue}`
          : Object.keys(timingValues).length !== 0
          ? ` ${Object.values(timingValues).join(' - ')}`
          : Object.keys(labelValues).length !== 0
          ? 0
          : '';
      const fieldValue = frequencydetails; // The value you want to set for the field

      form.setFieldsValue({ [fieldNames.FREQUENCY]: fieldValue });
    }
    // Assuming you have a reference to the form, you can use setFieldsValue like this:
  }, [frequencyTagValue, timingValues, labelValues, fieldNames]);

  useEffect(() => {
    if (fieldNames.INTAKETIMING !== '') {
      let inTakeTimingDetail = '';
      console.log('selected meal', selectedMealOption);
      switch (selectedMealOption) {
        case 'before food':
          inTakeTimingDetail = selectedMealOption;
          break;
        case 'after food':
          inTakeTimingDetail = selectedMealOption;
          break;
        case 'empty stomach':
          inTakeTimingDetail = selectedMealOption;
          break;
        case 'bed time':
          inTakeTimingDetail = selectedMealOption;
          break;
        default:
          if (Object.keys(labelValues).length !== 0) {
            inTakeTimingDetail = Object.entries(labelValues)
              .map(([name, value]) => `${name}-${value}`)
              .join(', ');
          }
          console.log();
      }
      const fieldValue = inTakeTimingDetail;
      form.setFieldsValue({ [fieldNames.INTAKETIMING]: fieldValue });
    }
  }, [labelValues, selectedMealOption, fieldNames]);
  console.log('lavbel', labelValues);

  useEffect(() => {
    // Simulate a change event to trigger the rendering of the default table
    handleSelectChangeQuantity('tablet');
    handleSelectChangeFrequency('frequency');
  }, []);

  useEffect(() => {
    fetchMenuSections().then(({ data, status }) => {
      console.log('allergy ', data);

      if (status === 200) {
        if (data.status === 1) {
          console.log(data);
          setAllergySectionId(
            data?.body?.filter((item) => {
              if (item.formName == 'Allergies') return item.id;
            })
          );
        }
      }
    });

    getAppointmentDetails(patient.appointmentId).then((res) => {
      if (res.data.status == 1) setAppointmentDetails(res.data.body);
    });
  }, []);

  const getAllergyTableData = (size) => {
    if (allergySectionId) {
      console.log('allergy scetionId', allergySectionId);
      getPreviousRecords(
        allergySectionId[0]?.id,
        patient.pId,
        patient.appointmentId,
        size,
        0
      )
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.status === 1) {
              getHealthRecordForms(allergySectionId[0]?.id, patient.pId)
                .then((res) => {
                  if (res.data.status == 1) {
                    var columnsData = res.data.body;
                    if (columnsData[0]) {
                      if (columnsData[0].fields) {
                        var tempColumns = generatePreviousInfoTableColumns(
                          columnsData[0].fields
                        );
                        setAllergyColumns(tempColumns);
                      }
                    }
                  }
                })
                .catch((err) => console.log(err));

              var temp = migrateIntoTableFormat(data.body);
              temp.map((item) => {
                item.key = item['Sl No'];
              });
              setAllergyTableData(temp);
              setAllergyPageSize(data.total);
              setTableFetchLoading(false);
              setAllergyLoading(false);
            } else {
              // message.error("No Form Found");
              setTableFetchLoading(false);
              setAllergyLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          setTableFetchLoading(false);
          setPrescriptionsLoading(false);
        });
    }
  };

  const getTableData = (size) => {
    console.log('section id is', sectionId);
    getPreviousRecords(sectionId, patient.pId, patient.appointmentId, size, 0)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            var temp = migrateIntoTableFormat(data.body);
            temp.map((item) => {
              item.key = item['Sl No'] + '' + moment().valueOf();
            });
            setTableData(temp);
            setPageSize(data.total);
            setTableFetchLoading(false);
            setPrescriptionsLoading(false);
          } else {
            // message.error("No Form Found");
            setTableFetchLoading(false);
            setPrescriptionsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setTableFetchLoading(false);
        setPrescriptionsLoading(false);
      });
  };

  const getPrintTableData = () => {
    getPreviousRecords(sectionId, patient.pId, patient.appointmentId)
      .then(({ data, status }) => {
        if (status == 200) {
          if (data.status == 1) {
            var temp = data.body;
            temp.map((item) => {
              item.key = item['Sl No.'];
            });
            setPrintData(temp);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const submitFormData = (emergencyPrescription) => {
    if (emergencyPrescription) setSubmitPharmacyLoading(true);
    else setSubmitLoading(true);
    var dynamicFormData = [];
    preSubmitTableData.forEach((item) => {
      let data = Object.keys(formIds).map((key) => {
        const filtered = {};
        formIds[key].forEach((k) => {
          filtered[k] = item[k];
        });
        return { formId: key, fields: deserialize(filtered) };
      });
      dynamicFormData = [...dynamicFormData, ...data];
    });

    addHealthRecordIPD(
      sectionId,
      patient.pId,
      dynamicFormData,
      patient.appointmentId,
      emergencyPrescription
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            message.success('Health Record added 2 ');
            getTableData(10);
            setPreSubmitTableData([]);
            setPreSubmitVisibleTableData([]);
          } else {
            message.error("couldn't add Vital Informations!");
          }
        }
        if (emergencyPrescription) setSubmitPharmacyLoading(false);
        else setSubmitLoading(false);
        // getTableData();
      })
      .catch((err) => {
        if (emergencyPrescription) setSubmitPharmacyLoading(false);
        else setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const formSubmitHandler = (values) => {
    setPreSubmitTableData([
      ...preSubmitTableData,
      { ...values, uuid: uuidv4() },
    ]);
    setPreSubmitVisibleTableData([
      ...preSubmitVisibleTableData,
      { ...values, uuid: uuidv4() },
    ]);
    form.resetFields();

    // submitFormData(data);
  };

  useEffect(() => {
    console.log('forms are', forms);
    if (forms?.length > 0) {
      getCodes(forms[0]?.fields[0]?.codetype, 0, '').then((res) => {
        console.log('response in codes', res.data.body);
        setDrugCodes(res.data.body);

        console.log(res);
      });
      forms[0].fields.forEach((form) => {
        if (
          form.codetype !== null &&
          fieldNames.hasOwnProperty(form.codetype)
        ) {
          setFieldNames((fieldNames) => ({
            ...fieldNames,
            [form.codetype]: form.name,
          }));
        } else if (form.label === 'Intake Timing') {
          setFieldNames((fieldNames) => ({
            ...fieldNames,
            INTAKETIMING: form.name,
          }));
        } else if (form.label === 'Composition') {
          setFieldNames((fieldNames) => ({
            ...fieldNames,
            COMPOSITION: form.name,
          }));
        }
      });
    }
  }, [forms]);

  const onClear = () => {
    // setFormInitialValues({});
    form.resetFields();
    setFormFetchLoading(true);
    setValidationStatus({});
    getForms();
  };

  useEffect(() => {
    setPrescriptionsLoading(true);
    setFormFetchLoading(true);
    setTableFetchLoading(true);
    getForms();
    getTableData(10);
    getAllergyTableData(10);
    getPrintTableData();
  }, []);
  useEffect(() => {
    if (forms.length && metaDataFieldIds.length) {
      let fieldMetaPromises = metaDataFieldIds.map((item) => {
        return fetchFormFieldMeta(
          item.dropDownType.dropDownType,
          item.sectionId
        );
      });
      let tempDropDownValues = [...dropDownValues];
      console.log('drop down values', tempDropDownValues);
      Promise.all(fieldMetaPromises)
        .then((responses) => {
          responses.forEach(({ data, status }, index) => {
            if (status == 200) {
              if (data.status == 1)
                tempDropDownValues = [...tempDropDownValues, ...data.body];
              //Injecting meta data for corresponding  formfields
              forms.forEach((form) => {
                let metaField = form.fields.find(
                  (field) => field.name === metaDataFieldIds[index].id
                );
                if (metaField) {
                  // setDropDownValues([...dropDownValues, ...data.body]);
                  metaField.meta = data.body.map((item) => item.value);
                } else console.log('Meta undefined');
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setMetaDataFieldIds([]);
          setDropDownValues(tempDropDownValues);
          setForms([...forms]);
        });
    }
  }, [metaDataFieldIds, forms]);

  const onValuesChange = (changed) => {
    for (let i = 0; i < drugCodes.length; i++) {
      if (drugCodes[i].composition !== null) {
        console.log('dugcodes ', drugCodes[i]);
      }
    }
    console.log('changed in form', changed);
    if (changed.hasOwnProperty(fieldNames.DRUG_CODE)) {
      form.setFieldsValue({
        [fieldNames.COMPOSITION]:
          changed[fieldNames.DRUG_CODE].composition !== null
            ? changed[fieldNames.DRUG_CODE].composition
            : 'no composition available',
      });
    }

    Object.entries(changed).map(([key, value]) => {
      let changedField = dropDownParentIds.find(
        (item) => item.parentField == key
      );
      if (changedField) {
        let dropDownItem = dropDownValues.filter((item) => item.value == value);

        if (dropDownItem[dropDownItem.length - 1]) {
          changedField.sectionId = dropDownItem[dropDownItem.length - 1].id;

          setMetaDataFieldIds([changedField]);
        }
      }
    });
    Object.keys(changed).forEach((chngd) => {
      let validator = validationCriterias.find((item) => {
        return item.fieldId == chngd;
      });

      if (validator) {
        validationStatus[chngd] = softValidate(validator, changed[chngd]);
      }
    });

    setValidationStatus({ ...validationStatus });
  };

  const repeatPrescriptions = (e) => {
    var list = [];
    if (typeof e == 'object') {
      list = [...retestList, e];
    } else {
      list = retestList;
    }
    console.log(retestList);
    console.log(drugCodes);
    // setPreSubmitTableData(retestList)
    var repeatList = [];
    var fields = [];
    forms.map((subForm) => {
      console.log(subForm.fields);
      fields = subForm.fields;
    });
    list.forEach((repeat) => {
      var filtered = {};
      fields.forEach((field) => {
        filtered[field.name] = drugCodes.filter((codes) => {
          if (codes.text == repeat[field.label]) return codes;
        });
        if (filtered[field.name]?.length > 0)
          filtered[field.name] = filtered[field.name][0];
        if (field.inputType == 'text-area' || field.inputType == 'input')
          filtered[field.name] = repeat[field.label]
            ? repeat[field.label]
            : undefined;
        console.log(filtered);
      });
      console.log(repeat);
      filtered.uuid = uuidv4();
      repeatList = [...repeatList, filtered];
      console.log(repeatList);
    });
    setPreSubmitTableData([...preSubmitTableData, ...repeatList]);
    setPreSubmitVisibleTableData([...preSubmitVisibleTableData, ...list]);
    setRetestList([]);
    setSelectedRowKeys([]);
  };

  return !formFetchLoading && !tableFetchLoading ? (
    <Row>
      <StyledCol padding="25px 0" span={24}>
        {user.userType === 'PATIENT' ? (
          <Row>
            <Col onClick={() => setIsPrescription(true)}>
              <StyledH3
                color={
                  isPrescription ? theme['@primary-color'] : 'rgba(0,0,0,0.25)'
                }
              >
                Prescriptions
              </StyledH3>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col onClick={() => setIsPrescription(true)}>
              <StyledH3
                color={
                  isPrescription ? theme['@primary-color'] : 'rgba(0,0,0,0.25)'
                }
              >
                Prescriptions
              </StyledH3>
            </Col>
            <Col
              onClick={() => {
                setIsPrescription(false);
                setAllergyLoading(true);
                getAllergyTableData(10);
              }}
            >
              <StyledH3
                color={
                  !isPrescription ? theme['@primary-color'] : 'rgba(0,0,0,0.25)'
                }
              >
                Allergy Report
              </StyledH3>
            </Col>
          </Row>
        )}
        <>
          {isPrescription ? (
            <CustomeTableForRquests
              // title={"Previous Medicine"}
              tableTitle={'Tests'}
              columns={previousDataColumns}
              printColumns={previousDataPrintColumns}
              printData={printData}
              setRetestModal={repeatPrescriptions}
              dataSource={tableData}
              isPrintable={true}
              rowSelection={user.userType !== 'PATIENT' && rowSelection}
              retestList={retestList}
              retest={user.userType !== 'PATIENT'}
              isViewMore={true}
              getPreviousTabledata={getTableData}
              repeat={user.userType !== 'PATIENT'}
              pageSize={pageSize}
              isLoading={prescriptionsLoading}
              appointmentDetails={appointmentDetails}
            />
          ) : user.userType === 'PATIENT' ? (
            <></>
          ) : (
            <CustomeTableForRquests
              tableTitle={'Allergy Report'}
              dataSource={allergyTableData}
              columns={allergyColumns}
              isLoading={allergyLoading}
              getPreviousTabledata={getAllergyTableData}
              pageSize={allergyPageSize}
              isViewMore={true}
            />
          )}
        </>
      </StyledCol>
      {preSubmitTableData.length > 0 && (
        <StyledCol padding="0 0 25px 0" span={24}>
          <CustomeTableForRquests
            columns={
              preSubmitTableColumns.length
                ? appendCloseButton(
                    preSubmitTableData,
                    preSubmitTableColumns,
                    false
                  )
                : []
            }
            dataSource={preSubmitTableData}
            tableTitle={'Medicine Request'}
            title={section.sectionName}
          />
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <div style={{ width: 'min-content' }}>
              <div style={{ width: 'max-content' }}>
                <RightButton
                  type="primary"
                  className="btn-save"
                  loading={submitLoading}
                  onClick={() => submitFormData(false)}
                  disabled={submitPharmacyLoading}
                >
                  Submit
                </RightButton>
                <RightButton
                  type="primary"
                  ghost
                  className="btn-save"
                  loading={submitPharmacyLoading}
                  disabled={submitLoading}
                  onClick={() => submitFormData(true)}
                >
                  Submit to Pharmacy
                </RightButton>
              </div>
              <p
                style={{
                  fontSize: '13px',
                  color: '#6d6d6d',
                  display: 'inline-flex',
                }}
              >
                <p style={{ color: 'red' }}>*&nbsp;</p>
                Pharmacy submissions are advised to emergencies/ temporary
                cases.
              </p>
            </div>
          </div>
        </StyledCol>
      )}
      {user.userType !== 'PATIENT' && (
        <Row>
          <StyledCol xs={24} sm={24} md={16}>
            <FormContainer>
              <RightButton
                type="primary"
                className="btn-save"
                loading={submitLoading}
                onClick={() =>
                  history.push(
                    `/draw-canvas?pName=${patient.pName}&docName=${user.fullName}`
                  )
                }
              >
                Scratch Pad
              </RightButton>
              <Form
                layout="vertical"
                onFinish={formSubmitHandler}
                form={form}
                onValuesChange={onValuesChange}
                scrollToFirstError={true}
              >
                {forms.map((subForm) => (
                  <FormBuilder
                    formInstance={form}
                    formConfig={[
                      {
                        inputType: 'heading',
                        label: subForm.formName,
                      },
                      ...subForm.fields,
                    ]}
                    softValidationFields={validationStatus}
                  />
                ))}
                <Form.Item>
                  {forms.length !== 0 && (
                    <DiscardSubmitWrapper>
                      <Button
                        type="primary"
                        ghost
                        className="btn-save"
                        onClick={onClear}
                      >
                        Discard
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-save"
                      >
                        Add
                      </Button>
                    </DiscardSubmitWrapper>
                  )}
                </Form.Item>
              </Form>
            </FormContainer>
          </StyledCol>

          <StyledCol
            xs={24}
            sm={24}
            md={8}
            style={{ borderLeft: '1px solid #D3D3D3' }}
          >
            <div style={{ marginBottom: '16px' }}>
              {/* Select dropdown */}
              <h3 style={{ color: '#26B1FF', marginLeft: '10px' }}>
                Select Dosage
              </h3>

              <Select
                placeholder="Select an option"
                onChange={handleSelectChangeQuantity}
                style={{ width: 200, margin: '10px' }}
                defaultValue="tablet" // Set the default value her
              >
                <Option value="tablet">Tablet</Option>
                <Option value="syrup">Syrup</Option>
                {/* Add more options as needed */}
              </Select>
              {selectedQuantityValue === 'tablet' && (
                <Table
                  dataSource={dosageData}
                  columns={columns}
                  pagination={false}
                  showHeader={false}
                />
              )}
              {selectedQuantityValue === 'syrup' && (
                <Table
                  dataSource={syrupData}
                  columns={columns}
                  pagination={false}
                  showHeader={false}
                />
              )}
            </div>
            <div style={{ paddingBottom: '15px' }}>
              {/* Select dropdown */}
              <h3 style={{ marginLeft: '10px', marginBottom: '15px' }}>
                Default Timings{' '}
                <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                  <Button onClick={handleButtonClick}>Switch</Button>
                </span>
              </h3>

              {/* <Select
          placeholder="Frequency"
          onChange={handleSelectChangeFrequency}
          style={{ width: 200, margin: '10px' }}
          defaultValue="frequency"
        >
          <Option value="frequency">Frequency</Option>

        </Select> */}

              {currentIndex === 0 ? (
                <>
                  <h3 style={{ marginLeft: '10px', marginBottom: '15px' }}>
                    Frequency{' '}
                  </h3>
                  <Table
                    dataSource={frequencyData}
                    columns={frequencyColumns}
                    pagination={false}
                    showHeader={false}
                  />
                  <div
                    style={{
                      marginTop: '16px',
                      textAlign: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <Radio.Group
                      onChange={handleMealOptionChange}
                      value={selectedMealOption}
                    >
                      <Radio value="before food" style={{ margin: '15px' }}>
                        Before Food
                      </Radio>
                      <Radio
                        value="after food"
                        style={{ marginBottom: '15px', marginRight: '20px' }}
                      >
                        After Food
                      </Radio>
                      <Radio
                        value="empty stomach"
                        style={{ marginRight: '5px' }}
                      >
                        Empty Stomach
                      </Radio>

                      <Radio value="bed time">Bed Time</Radio>
                    </Radio.Group>
                  </div>
                </>
              ) : currentIndex === 1 ? (
                <div style={{ margin: '25px', textAlign: 'center' }}>
                  {labels.map((label) => (
                    <div key={label}>
                      <h3 style={{ margin: '15px' }}>{label}</h3>
                      <Radio.Group
                        onChange={(e) => handleChange(label, e.target.value)}
                        value={labelValues[label] || ''}
                      >
                        <Radio.Button value="AFTER">AFTER</Radio.Button>
                        <Radio.Button value="BEFORE">BEFORE</Radio.Button>
                      </Radio.Group>
                    </div>
                  ))}
                </div>
              ) : currentIndex === 2 ? (
                <>
                  <h3 style={{ marginLeft: '10px', marginBottom: '15px' }}>
                    Frequency{' '}
                  </h3>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '10px',
                      margin: '10px',
                    }}
                  >
                    <div style={{ marginRight: '20px' }}>
                      {timingLabels.slice(0, 2).map((label) => (
                        <div key={label} style={{ textAlign: 'center' }}>
                          <h3 style={{ marginTop: '15px' }}>{label}</h3>
                          <input
                            type="text"
                            id={label}
                            style={{
                              maxWidth: '8rem',
                              border: '1px solid #D3D3D3',
                            }}
                            onChange={(e) =>
                              handleTimingChange(label, e.target.value)
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <div>
                      {timingLabels.slice(2).map((label) => (
                        <div key={label} style={{ textAlign: 'center' }}>
                          <h3 style={{ marginTop: '15px' }}>{label}</h3>
                          <input
                            type="text"
                            id={label}
                            style={{
                              maxWidth: '8rem',
                              border: '1px solid #D3D3D3',
                            }}
                            onChange={(e) =>
                              handleTimingChange(label, e.target.value)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ marginTop: '16px', textAlign: 'center' }}>
                    <Radio.Group
                      onChange={handleMealOptionChange}
                      value={selectedMealOption}
                    >
                      <Radio value="before food">Before Food</Radio>
                      <Radio value="after food">After Food</Radio>
                    </Radio.Group>
                  </div>
                </>
              ) : null}
            </div>
          </StyledCol>
        </Row>
      )}
      {/* {!tableData.length && !allergyTableData.length && user.userType === "PATIENT" && (
        <NoRecords message="No Previous Records" />
      )} */}
      <ViewDetailsModal
        visible={visible}
        setVisible={setVisible}
        dataSource={selectedInformation}
      />
    </Row>
  ) : (
    <Spinner />
  );
};
export default MedicalDetails;
