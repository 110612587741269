export const ADD_USER = "ADD_USER";
export const CLEAR_USER = "CLEAR_USER";
export const addUser = (data) => {
  return {
    type: ADD_USER,
    payload: data,
  };
};
export const clearUser = () => {
  return {
    type: CLEAR_USER,
  };
};
