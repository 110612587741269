export const citizenship = () => [
  {
    text: "Afghanistan",
    code: "AFG",
  },
  {
    text: "Åland Islands",
    code: "ALA",
  },
  {
    text: "Albania",
    code: "ALB",
  },
  {
    text: "Algeria",
    code: "DZA",
  },
  {
    text: "American Samoa",
    code: "ASM",
  },
  {
    text: "Andorra",
    code: "AND",
  },
  {
    text: "Angola",
    code: "AGO",
  },
  {
    text: "Anguilla",
    code: "AIA",
  },
  {
    text: "Antarctica",
    code: "ATA",
  },
  {
    text: "Antigua and Barbuda",
    code: "ATG",
  },
  {
    text: "Argentina",
    code: "ARG",
  },
  {
    text: "Armenia",
    code: "ARM",
  },
  {
    text: "Aruba",
    code: "ABW",
  },
  {
    text: "Australia",
    code: "AUS",
  },
  {
    text: "Austria",
    code: "AUT",
  },
  {
    text: "Azerbaijan",
    code: "AZE",
  },
  {
    text: "Bahamas",
    code: "BHS",
  },
  {
    text: "Bahrain",
    code: "BHR",
  },
  {
    text: "Bangladesh",
    code: "BGD",
  },
  {
    text: "Barbados",
    code: "BRB",
  },
  {
    text: "Belarus",
    code: "BLR",
  },
  {
    text: "Belgium",
    code: "BEL",
  },
  {
    text: "Belize",
    code: "BLZ",
  },
  {
    text: "Benin",
    code: "BEN",
  },
  {
    text: "Bermuda",
    code: "BMU",
  },
  {
    text: "Bhutan",
    code: "BTN",
  },
  {
    text: "Bolivia (Plurinational State of)",
    code: "BOL",
  },
  {
    text: "Bonaire, Sint Eustatius and Saba",
    code: "BES",
  },
  {
    text: "Bosnia and Herzegovina",
    code: "BIH",
  },
  {
    text: "Botswana",
    code: "BWA",
  },
  {
    text: "Bouvet Island",
    code: "BVT",
  },
  {
    text: "Brazil",
    code: "BRA",
  },
  {
    text: "British Indian Ocean Territory",
    code: "IOT",
  },
  {
    text: "United States Minor Outlying Islands",
    code: "UMI",
  },
  {
    text: "Virgin Islands (British)",
    code: "VGB",
  },
  {
    text: "Virgin Islands (U.S.)",
    code: "VIR",
  },
  {
    text: "Brunei Darussalam",
    code: "BRN",
  },
  {
    text: "Bulgaria",
    code: "BGR",
  },
  {
    text: "Burkina Faso",
    code: "BFA",
  },
  {
    text: "Burundi",
    code: "BDI",
  },
  {
    text: "Cambodia",
    code: "KHM",
  },
  {
    text: "Cameroon",
    code: "CMR",
  },
  {
    text: "Canada",
    code: "CAN",
  },
  {
    text: "Cabo Verde",
    code: "CPV",
  },
  {
    text: "Cayman Islands",
    code: "CYM",
  },
  {
    text: "Central African Republic",
    code: "CAF",
  },
  {
    text: "Chad",
    code: "TCD",
  },
  {
    text: "Chile",
    code: "CHL",
  },
  {
    text: "China",
    code: "CHN",
  },
  {
    text: "Christmas Island",
    code: "CXR",
  },
  {
    text: "Cocos (Keeling) Islands",
    code: "CCK",
  },
  {
    text: "Colombia",
    code: "COL",
  },
  {
    text: "Comoros",
    code: "COM",
  },
  {
    text: "Congo",
    code: "COG",
  },
  {
    text: "Congo (Democratic Republic of the)",
    code: "COD",
  },
  {
    text: "Cook Islands",
    code: "COK",
  },
  {
    text: "Costa Rica",
    code: "CRI",
  },
  {
    text: "Croatia",
    code: "HRV",
  },
  {
    text: "Cuba",
    code: "CUB",
  },
  {
    text: "Curaçao",
    code: "CUW",
  },
  {
    text: "Cyprus",
    code: "CYP",
  },
  {
    text: "Czech Republic",
    code: "CZE",
  },
  {
    text: "Denmark",
    code: "DNK",
  },
  {
    text: "Djibouti",
    code: "DJI",
  },
  {
    text: "Dominica",
    code: "DMA",
  },
  {
    text: "Dominican Republic",
    code: "DOM",
  },
  {
    text: "Ecuador",
    code: "ECU",
  },
  {
    text: "Egypt",
    code: "EGY",
  },
  {
    text: "El Salvador",
    code: "SLV",
  },
  {
    text: "Equatorial Guinea",
    code: "GNQ",
  },
  {
    text: "Eritrea",
    code: "ERI",
  },
  {
    text: "Estonia",
    code: "EST",
  },
  {
    text: "Ethiopia",
    code: "ETH",
  },
  {
    text: "Falkland Islands (Malvinas)",
    code: "FLK",
  },
  {
    text: "Faroe Islands",
    code: "FRO",
  },
  {
    text: "Fiji",
    code: "FJI",
  },
  {
    text: "Finland",
    code: "FIN",
  },
  {
    text: "France",
    code: "FRA",
  },
  {
    text: "French Guiana",
    code: "GUF",
  },
  {
    text: "French Polynesia",
    code: "PYF",
  },
  {
    text: "French Southern Territories",
    code: "ATF",
  },
  {
    text: "Gabon",
    code: "GAB",
  },
  {
    text: "Gambia",
    code: "GMB",
  },
  {
    text: "Georgia",
    code: "GEO",
  },
  {
    text: "Germany",
    code: "DEU",
  },
  {
    text: "Ghana",
    code: "GHA",
  },
  {
    text: "Gibraltar",
    code: "GIB",
  },
  {
    text: "Greece",
    code: "GRC",
  },
  {
    text: "Greenland",
    code: "GRL",
  },
  {
    text: "Grenada",
    code: "GRD",
  },
  {
    text: "Guadeloupe",
    code: "GLP",
  },
  {
    text: "Guam",
    code: "GUM",
  },
  {
    text: "Guatemala",
    code: "GTM",
  },
  {
    text: "Guernsey",
    code: "GGY",
  },
  {
    text: "Guinea",
    code: "GIN",
  },
  {
    text: "Guinea-Bissau",
    code: "GNB",
  },
  {
    text: "Guyana",
    code: "GUY",
  },
  {
    text: "Haiti",
    code: "HTI",
  },
  {
    text: "Heard Island and McDonald Islands",
    code: "HMD",
  },
  {
    text: "Holy See",
    code: "VAT",
  },
  {
    text: "Honduras",
    code: "HND",
  },
  {
    text: "Hong Kong",
    code: "HKG",
  },
  {
    text: "Hungary",
    code: "HUN",
  },
  {
    text: "Iceland",
    code: "ISL",
  },
  {
    text: "India",
    code: "IND",
  },
  {
    text: "Indonesia",
    code: "IDN",
  },
  {
    text: "Côte d'Ivoire",
    code: "CIV",
  },
  {
    text: "Iran (Islamic Republic of)",
    code: "IRN",
  },
  {
    text: "Iraq",
    code: "IRQ",
  },
  {
    text: "Ireland",
    code: "IRL",
  },
  {
    text: "Isle of Man",
    code: "IMN",
  },
  {
    text: "Israel",
    code: "ISR",
  },
  {
    text: "Italy",
    code: "ITA",
  },
  {
    text: "Jamaica",
    code: "JAM",
  },
  {
    text: "Japan",
    code: "JPN",
  },
  {
    text: "Jersey",
    code: "JEY",
  },
  {
    text: "Jordan",
    code: "JOR",
  },
  {
    text: "Kazakhstan",
    code: "KAZ",
  },
  {
    text: "Kenya",
    code: "KEN",
  },
  {
    text: "Kiribati",
    code: "KIR",
  },
  {
    text: "Kuwait",
    code: "KWT",
  },
  {
    text: "Kyrgyzstan",
    code: "KGZ",
  },
  {
    text: "Lao People's Democratic Republic",
    code: "LAO",
  },
  {
    text: "Latvia",
    code: "LVA",
  },
  {
    text: "Lebanon",
    code: "LBN",
  },
  {
    text: "Lesotho",
    code: "LSO",
  },
  {
    text: "Liberia",
    code: "LBR",
  },
  {
    text: "Libya",
    code: "LBY",
  },
  {
    text: "Liechtenstein",
    code: "LIE",
  },
  {
    text: "Lithuania",
    code: "LTU",
  },
  {
    text: "Luxembourg",
    code: "LUX",
  },
  {
    text: "Macao",
    code: "MAC",
  },
  {
    text: "Macedonia (the former Yugoslav Republic of)",
    code: "MKD",
  },
  {
    text: "Madagascar",
    code: "MDG",
  },
  {
    text: "Malawi",
    code: "MWI",
  },
  {
    text: "Malaysia",
    code: "MYS",
  },
  {
    text: "Maldives",
    code: "MDV",
  },
  {
    text: "Mali",
    code: "MLI",
  },
  {
    text: "Malta",
    code: "MLT",
  },
  {
    text: "Marshall Islands",
    code: "MHL",
  },
  {
    text: "Martinique",
    code: "MTQ",
  },
  {
    text: "Mauritania",
    code: "MRT",
  },
  {
    text: "Mauritius",
    code: "MUS",
  },
  {
    text: "Mayotte",
    code: "MYT",
  },
  {
    text: "Mexico",
    code: "MEX",
  },
  {
    text: "Micronesia (Federated States of)",
    code: "FSM",
  },
  {
    text: "Moldova (Republic of)",
    code: "MDA",
  },
  {
    text: "Monaco",
    code: "MCO",
  },
  {
    text: "Mongolia",
    code: "MNG",
  },
  {
    text: "Montenegro",
    code: "MNE",
  },
  {
    text: "Montserrat",
    code: "MSR",
  },
  {
    text: "Morocco",
    code: "MAR",
  },
  {
    text: "Mozambique",
    code: "MOZ",
  },
  {
    text: "Myanmar",
    code: "MMR",
  },
  {
    text: "Namibia",
    code: "NAM",
  },
  {
    text: "Nauru",
    code: "NRU",
  },
  {
    text: "Nepal",
    code: "NPL",
  },
  {
    text: "Netherlands",
    code: "NLD",
  },
  {
    text: "New Caledonia",
    code: "NCL",
  },
  {
    text: "New Zealand",
    code: "NZL",
  },
  {
    text: "Nicaragua",
    code: "NIC",
  },
  {
    text: "Niger",
    code: "NER",
  },
  {
    text: "Nigeria",
    code: "NGA",
  },
  {
    text: "Niue",
    code: "NIU",
  },
  {
    text: "Norfolk Island",
    code: "NFK",
  },
  {
    text: "Korea (Democratic People's Republic of)",
    code: "PRK",
  },
  {
    text: "Northern Mariana Islands",
    code: "MNP",
  },
  {
    text: "Norway",
    code: "NOR",
  },
  {
    text: "Oman",
    code: "OMN",
  },
  {
    text: "Pakistan",
    code: "PAK",
  },
  {
    text: "Palau",
    code: "PLW",
  },
  {
    text: "Palestine, State of",
    code: "PSE",
  },
  {
    text: "Panama",
    code: "PAN",
  },
  {
    text: "Papua New Guinea",
    code: "PNG",
  },
  {
    text: "Paraguay",
    code: "PRY",
  },
  {
    text: "Peru",
    code: "PER",
  },
  {
    text: "Philippines",
    code: "PHL",
  },
  {
    text: "Pitcairn",
    code: "PCN",
  },
  {
    text: "Poland",
    code: "POL",
  },
  {
    text: "Portugal",
    code: "PRT",
  },
  {
    text: "Puerto Rico",
    code: "PRI",
  },
  {
    text: "Qatar",
    code: "QAT",
  },
  {
    text: "Republic of Kosovo",
    code: "KOS",
  },
  {
    text: "Réunion",
    code: "REU",
  },
  {
    text: "Romania",
    code: "ROU",
  },
  {
    text: "Russian Federation",
    code: "RUS",
  },
  {
    text: "Rwanda",
    code: "RWA",
  },
  {
    text: "Saint Barthélemy",
    code: "BLM",
  },
  {
    text: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SHN",
  },
  {
    text: "Saint Kitts and Nevis",
    code: "KNA",
  },
  {
    text: "Saint Lucia",
    code: "LCA",
  },
  {
    text: "Saint Martin (French part)",
    code: "MAF",
  },
  {
    text: "Saint Pierre and Miquelon",
    code: "SPM",
  },
  {
    text: "Saint Vincent and the Grenadines",
    code: "VCT",
  },
  {
    text: "Samoa",
    code: "WSM",
  },
  {
    text: "San Marino",
    code: "SMR",
  },
  {
    text: "Sao Tome and Principe",
    code: "STP",
  },
  {
    text: "Saudi Arabia",
    code: "SAU",
  },
  {
    text: "Senegal",
    code: "SEN",
  },
  {
    text: "Serbia",
    code: "SRB",
  },
  {
    text: "Seychelles",
    code: "SYC",
  },
  {
    text: "Sierra Leone",
    code: "SLE",
  },
  {
    text: "Singapore",
    code: "SGP",
  },
  {
    text: "Sint Maarten (Dutch part)",
    code: "SXM",
  },
  {
    text: "Slovakia",
    code: "SVK",
  },
  {
    text: "Slovenia",
    code: "SVN",
  },
  {
    text: "Solomon Islands",
    code: "SLB",
  },
  {
    text: "Somalia",
    code: "SOM",
  },
  {
    text: "South Africa",
    code: "ZAF",
  },
  {
    text: "South Georgia and the South Sandwich Islands",
    code: "SGS",
  },
  {
    text: "Korea (Republic of)",
    code: "KOR",
  },
  {
    text: "South Sudan",
    code: "SSD",
  },
  {
    text: "Spain",
    code: "ESP",
  },
  {
    text: "Sri Lanka",
    code: "LKA",
  },
  {
    text: "Sudan",
    code: "SDN",
  },
  {
    text: "Suriname",
    code: "SUR",
  },
  {
    text: "Svalbard and Jan Mayen",
    code: "SJM",
  },
  {
    text: "Swaziland",
    code: "SWZ",
  },
  {
    text: "Sweden",
    code: "SWE",
  },
  {
    text: "Switzerland",
    code: "CHE",
  },
  {
    text: "Syrian Arab Republic",
    code: "SYR",
  },
  {
    text: "Taiwan",
    code: "TWN",
  },
  {
    text: "Tajikistan",
    code: "TJK",
  },
  {
    text: "Tanzania, United Republic of",
    code: "TZA",
  },
  {
    text: "Thailand",
    code: "THA",
  },
  {
    text: "Timor-Leste",
    code: "TLS",
  },
  {
    text: "Togo",
    code: "TGO",
  },
  {
    text: "Tokelau",
    code: "TKL",
  },
  {
    text: "Tonga",
    code: "TON",
  },
  {
    text: "Trinidad and Tobago",
    code: "TTO",
  },
  {
    text: "Tunisia",
    code: "TUN",
  },
  {
    text: "Turkey",
    code: "TUR",
  },
  {
    text: "Turkmenistan",
    code: "TKM",
  },
  {
    text: "Turks and Caicos Islands",
    code: "TCA",
  },
  {
    text: "Tuvalu",
    code: "TUV",
  },
  {
    text: "Uganda",
    code: "UGA",
  },
  {
    text: "Ukraine",
    code: "UKR",
  },
  {
    text: "United Arab Emirates",
    code: "ARE",
  },
  {
    text: "United Kingdom of Great Britain and Northern Ireland",
    code: "GBR",
  },
  {
    text: "United States of America",
    code: "USA",
  },
  {
    text: "Uruguay",
    code: "URY",
  },
  {
    text: "Uzbekistan",
    code: "UZB",
  },
  {
    text: "Vanuatu",
    code: "VUT",
  },
  {
    text: "Venezuela (Bolivarian Republic of)",
    code: "VEN",
  },
  {
    text: "Viet Nam",
    code: "VNM",
  },
  {
    text: "Wallis and Futuna",
    code: "WLF",
  },
  {
    text: "Western Sahara",
    code: "ESH",
  },
  {
    text: "Yemen",
    code: "YEM",
  },
  {
    text: "Zambia",
    code: "ZMB",
  },
  {
    text: "Zimbabwe",
    code: "ZWE",
  },
];
