import React from "react";
import { useSelector } from "react-redux";
import { EmptyMessageContainer } from "./styledComponents";
import { columns } from "./tableConfig";
import { Empty } from "antd";
import Spinner from "../../../utils/Spinner";
import { BlueBgTable } from "../styledComponents";

const DepartmentsTable = ({
  departments,
  loading,
  setEditModal,
  setselectedItem,
  setDeleteModal,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  return loading ? (
    <Spinner height={"100px"} />
  ) : departments.length > 0 ? (
    <BlueBgTable
      // headBg={theme["@primary-color"]}
      columns={columns(setEditModal, setDeleteModal, setselectedItem)}
      dataSource={departments}
      loading={loading}
      pagination={false}
    />
  ) : (
    <EmptyMessageContainer>
      <Empty />
    </EmptyMessageContainer>
  );
};
export default DepartmentsTable;
