import React from "react";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/DeleteIcon.svg";

const insuranceTypes = { SELF: "Self", COPAY: "Copay", BOTH: "Both" };

export const columns = (
  setModal,
  setEdit,
  setSelectedItem,
  setDeleteModal,
  pageNo
) => [
  {
    width: "1vw",
  },
  {
    title: "Sl. No",
    width: "4vw",
    render: (value, item, index) => {
      return pageNo * 10 + index + 1;
    },
  },
  { title: "Complaints", width: "50vw", dataIndex: "name" },

  {
    title: "Actions",
    width: "8vw",
    render: (record) => (
      <Row gutter={12}>
        <Col>
          {" "}
          <Icon
            style={{ fontSize: "20px" }}
            component={EditIcon}
            onClick={() => {
              setSelectedItem(record);
              setEdit(true);
              setModal(true);
            }}
          />
        </Col>
        <Col>
          <Icon
            style={{ fontSize: "22px" }}
            component={DeleteIcon}
            onClick={() => {
              setSelectedItem(record);
              //   setselectedItem(record);
              setDeleteModal(true);
            }}
          />
        </Col>
      </Row>
    ),
  },
];
