import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Wrapper,
  BodyContainer,
  StyledContent,
  LogoContainer,
} from './styledComponents';
import { Divider } from 'antd';
import NewPatientCreateForm from './NewPatientCreateForm';
import AppointmentFormPatient from './AppointmentFormPatient';
import Hospital from '../../assets/icons/Hospital.svg';
import { useQuery } from '../utils/UseQuery';
import md5 from 'md5';
import { getHospitalDetailWithHospitalId } from '../../apis/Public';
const BookVisitPage = () => {
  const history = useHistory();
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);
  const user = useSelector((state) => state.newPatientReducer);

  const {
    patientId: patientId,
    appointmentId: appointmentId,
    patientName: patientName,
  } = useParams();
  const x = useParams();
  const [hospitalDetail, sethospitalDetail] = useState({});

  let query = useQuery();
  const hospId = query.get('hId');
  const hospitalId = hospId ? md5(hospId) : '';

  useEffect(() => {
    getHospitalDetailWithHospitalId(hospitalId, '')
      .then((res) => {
        if (res.status == 200) {
          sethospitalDetail(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Wrapper justify="center">
      <StyledContent className="container">
        <BodyContainer className="px-6">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {Object.keys(hospitalDetail).length !== 0 ? (
              <>
                <span>
                  <img
                    src={Hospital}
                    alt="hospital"
                    height={'130px'}
                    width={'130px'}
                  />
                </span>
                <h2 style={{ marginTop: '-25px' }}>
                  {hospitalDetail?.hospital?.name ?? ''}{' '}
                </h2>
                <h4>{hospitalDetail?.hospital?.address ?? ''}</h4>
                <h4>
                  Phone Number :{' '}
                  {hospitalDetail?.hospital?.phonenumber?.phoneNumber ?? ''}
                </h4>
              </>
            ) : (
              'No hospital data'
            )}
          </div>
          <Divider />
          <AppointmentFormPatient
            patientId={patientId}
            appointmentId={appointmentId}
            user={user}
          />
        </BodyContainer>
      </StyledContent>
    </Wrapper>
  );
};

export default BookVisitPage;
