import React, { useEffect, useState } from "react";
import { Col, Row, message, Tooltip } from "antd";
import CameraModal from "../PatientForm/CameraModal";
import { uploadImage } from "../../../apis/uploadImages";
import { getOneByID, updatePatient } from "../../../apis/patients";
import { LeftCircleOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from "./ImageUpload";
import moment from "moment";
import Spinner from "../../utils/Spinner/index";
import PatientProfileForm from "./PatientProfileForm";
import { editProfile } from "../../../apis/User";
import * as Sentry from "@sentry/react";
import {
  ChangePasswordButton,
  ChangePasswordCol,
  EditButton,
  FormContainer,
  Heading,
  HeadingRow,
  PatientIdP,
  ProfileDetailsCol,
  ProfileDetailsP,
  StyledEditIcon,
} from "./styledComponents";
import ResetPassword from "./ResetPassword";
import { PROFILE_IMAGE_PREFIX } from "../../../constants/StringConstants";
import { clearUser, addUser } from "../../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import PatientDetailsForm from "../AdminDashboard/UserManagement/PatientDetailsForm";
import { deleteUploadedFile } from "../../../apis/uploadPdfOrImage";

const PatientProfile = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer);

  const [profilePic, setProfilePic] = useState();
  const [profilePicURL, setProfilePicURL] = useState();
  const [patientId, setPatientId] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [cameraModalVisible, setCameraModalVisible] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [passwordReset, setPasswordReset] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [countryCode, setCountryCode] = useState();

  useEffect(() => {
    fetchProfileDetails();
  }, []);

  const fetchProfileDetails = () => {
    setLoading(true);
    getOneByID(user.id)
      .then((response) => {
        if (response.data.status === 1) {
          let body = response.data.body;
          body.bloodGroup = body.bloodGroup?.id;
          setSelectedItem(body);
          console.log("user profile", body);
          body.dob = moment(body.dob);
          body.age = moment().diff(body.dob, "years", false);
          setFormInitialValues(body);

          if (body.profilePic) {
            const url = PROFILE_IMAGE_PREFIX + body.profilePic;
            setProfilePicURL(url);
          }
          setLoading(false);
        } else {
          message.error("Could not load profile,Try again");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setLoading(false);
        message.error("Could not load profile,Try again");
      });
  };

  const onSubmit = async (values) => {
    var profilePicUrl;
    setDisabled(!disabled);
    if (profilePic) {
      let image = await uploadImage(null, profilePic);
      values.profilePic = image.data.body;
      profilePicUrl = image.data.body;
    } else if (profilePicURL) {
      values.profilePic = formInitialValues.profilePic;
      profilePicUrl = formInitialValues.profilePic;
    } else if (formInitialValues.profilePic) {
      deleteUploadedFile(formInitialValues.profilePic)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 1) {
              console.log(res.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
      values.profilePic = undefined;
      profilePicUrl = undefined;
    }
    values.mobileNo = formInitialValues.mobileNo;
    values.alternateContactNo = formInitialValues.alternateContactNo;
    values.email = formInitialValues.email;
    values.gender = values.gender.toUpperCase();
    values.paymentType = formInitialValues.paymentType;
    values.insuranceData = formInitialValues.insuranceData;
    values.insuranceDeductibleType = formInitialValues.insuranceDeductibleType;
    values.userType = "PATIENT";
    values.roleType = "ROLE_PATIENT";

    const payload = {
      profilePic: profilePicUrl,
      additionalInfo: {
        alternateContactDetails: values.alternateContactDetails,
        phoneType: values.phoneType,
        citizenship: values.citizenship,
        emailType: values.emailType,
        id: 0,
        maritalStatus: values.maritalStatus,
        nationality: values.nationality,
        primaryLanguage: values.primaryLanguage,
        race: values.race,
        religion: values.religion,
        deathStatus: values.deathStatus === "Yes" ? true : false,
        deathDate: values.deathDate,
        // vip: values.vip,
      },
      addresses: values.addresses,
      phoneNumbers: values.phoneNumbers.map((number) => {
        return {
          phoneType: number.phoneType,
          ...{
            countryCode: number.phoneNumber.countryCode,
            phoneNumber: number.phoneNumber.phoneNumber,
          },
        };
      }),
      dob: values.dob,
      identityDocuments: values.identityDocuments,
      email: values.email,
      firstName: values.firstName,
      gender: values.gender,
      lastName: values.lastName,
      mobileNo: values.mobileNo,
      nationality: values.nationality,
      bloodGroupId: values.bloodGroup,
      captcha: values.captcha,
      roleType: values.roleType,
      status: values.status,
      userType: values.userType,
      userTypeId: values.idType,
      // userTypeId: selectedRole.id,
      password: "abcd",
    };
    console.log("values**", values, "payload**", payload);
    updatePatient(user.id, payload)
      .then((response) => {
        if (response.data.status === 1) {
          history.go(0);
          let userNew = user;
          userNew.profilePic = response.data.body.profilePic;
          userNew.fullName =
            response.data.body.firstName + " " + response.data.body.lastName;
          dispatch(addUser({ ...userNew }));
          localStorage.setItem("user", JSON.stringify(userNew));
          message.success("Profile updated successfully.");
          setSubmitLoading(false);
          fetchProfileDetails();
        } else {
          message.error("Couldn't update profile,Try agian");
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        message.error("Couldn't update profile,Try agian");
        setSubmitLoading(false);
      });
  };

  const logOut = () => {
    localStorage.clear();
    dispatch(clearUser());
    history.push("/login");
  };

  return (
    <>
      <h2 className="h2-base-600">
        <LeftCircleOutlined
          onClick={() => {
            history.goBack();
          }}
        />
        {"  "}
        Back To Dashboard
      </h2>

      <FormContainer>
        <HeadingRow>
          <Heading
            color={theme["@primary-color"]}
            // style={{ marginBottom: "5px", marginTop: "0px" }}
          >
            My Profile
          </Heading>
          {!passwordReset && (
            <Tooltip title="Edit Profile">
              <EditButton
                style={{ border: "0px", paddingTop: "5px" }}
                shape="circle"
                icon={
                  <StyledEditIcon
                    disabled={disabled}
                    color={theme["@primary-color"]}
                  />
                }
                onClick={() => setDisabled(!disabled)}
              />
            </Tooltip>
          )}
        </HeadingRow>

        {loading ? (
          <Spinner />
        ) : (
          <>
            <Row justify="left" className="first_row_prof">
              <Col
                style={{ textAlign: "center" }}
                span={5}
                className="prof_detail_upload"
              >
                <ImageUpload
                  disabled={disabled}
                  profilePic={profilePic}
                  setProfilePic={setProfilePic}
                  setCameraModalVisible={setCameraModalVisible}
                  profilePicURL={profilePicURL}
                  setProfilePicURL={setProfilePicURL}
                  setDisabled={setDisabled}
                />
              </Col>
              <ProfileDetailsCol span={5} className="prof_detail_wrap col-cnt1">
                <PatientIdP className="pat_id">
                  Patient ID: {formInitialValues.id}
                </PatientIdP>
                {/* <ProfileDetailsP>EmailId: </ProfileDetailsP> */}
                <ProfileDetailsP>{formInitialValues.email}</ProfileDetailsP>
              </ProfileDetailsCol>
              <ProfileDetailsCol span={9} className="prof_detail_wrap col-cnt2">
                <ProfileDetailsP>
                  Username:{" "}
                  {formInitialValues.firstName +
                    " " +
                    formInitialValues.lastName}
                </ProfileDetailsP>
                <ProfileDetailsP>
                  Contact Number:{" "}
                  {formInitialValues.phoneNumbers &&
                    formInitialValues.phoneNumbers[0].countryCode.split(
                      " "
                    )[0]}{" "}
                  {formInitialValues.phoneNumbers &&
                    formInitialValues.phoneNumbers[0].phoneNumber}
                </ProfileDetailsP>
                {/* <ProfileDetailsP>
                  Alternative Contact Number:{" "}
                  {formInitialValues.alternateContactNo &&
                    formInitialValues.alternateContactNo.countryCode}{" "}
                  {formInitialValues.alternateContactNo &&
                    formInitialValues.alternateContactNo.phoneNumber}
                </ProfileDetailsP> */}
              </ProfileDetailsCol>
              {!passwordReset && (
                <ProfileDetailsCol span={5} className="align-end">
                  <ChangePasswordButton
                    type="primary"
                    onClick={() => setPasswordReset(true)}
                    className="pwd_btn"
                  >
                    Change Password
                  </ChangePasswordButton>
                </ProfileDetailsCol>
              )}
            </Row>

            {passwordReset ? (
              <ResetPassword id={user.id} setPasswordReset={setPasswordReset} />
            ) : (
              <>
                <PatientProfileForm
                  formInitialValues={formInitialValues}
                  disabled={disabled}
                  onSubmit={(values) => onSubmit(values)}
                  submitLoading={submitLoading}
                  logOut={logOut}
                  selectedItem={selectedItem}
                  setSelectedItem={selectedItem}
                />
                {/* <PatientDetailsForm
                  register={false}
                  submitLoading={false}
                  selectedItem={selectedItem}
                  submitHandler={(values) => onSubmit(values)}
                  setSelectedItem={setSelectedItem}
                  disabled={disabled}
                  noDocumentView={true}
                /> */}
              </>
            )}
          </>
        )}
      </FormContainer>
      <CameraModal
        visible={cameraModalVisible}
        setVisible={setCameraModalVisible}
        setProfilePic={setProfilePic}
      />
    </>
  );
};
export default PatientProfile;
