import styled from 'styled-components';
import { Row, Button } from 'antd';
import { ReactComponent as EditIcon } from '../../../assets/icons/Edit.svg';

export const FormContainer = styled(Row)`
  background-color: #fff;
  padding: 20px 100px;
`;

export const ImageUploadContainer = styled.div`
  width: 170px;
  padding: 20px;
`;
export const ImageContainer = styled.div`
  width: 90px;
  height: 90px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;
export const DeleteButton = styled(Button)`
  float: right;
  margin-left: 10px;
  color: #f2f2f2;
`;

export const BodyContainer = styled.div`
  background: #fff;
  padding: 45px 100px;
`;
export const UploadTitle = styled.h3`
  font-size: 16px;
  color: ${(props) => props.color};
  text-align: left;
`;

export const DocumentDetailsDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledContent = styled.div`
  // min-height: calc(100vh - 90px);
  &.container {
    width: 100%;
    max-width: 1560px;
  }

  h2.h2-base-600 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.02em;
    color: #504c4c;
    margin-bottom: 25px;
  }
  .ant-picker-input > input {
    color: #333333;
  }
  h2.h2-base-600 span {
    margin-right: 10px;
  }
  .form-m2-style h3 {
    margin: 15px 0 35px;
    font-size: 20px;
  }
  .form-m2-style .ant-form-item-label label {
    font-size: 16px;
    color: #818181;
  }
  .form-m2-style .ant-form-item-label label:before {
    display: none;
  }
  .form-m2-style .ant-form-item-label label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff0003;
    font-size: 18px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    font-weight: 700;
  }
  .form-m2-style .ant-form-item-control-input-content .form-col .ant-input {
    padding-left: 10px !important;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-input,
  .form-m2-style .ant-form-item-control-input-content .ant-select-selector,
  .form-m2-style .ant-form-item-control-input-content .ant-picker {
    height: 60px;
    border: 1px solid #d6d6d6;
    border-radius: 5px !important;
    font-size: 18px;
    color: #333333;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-select + .ant-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select:hover
    .ant-select-selector {
    border: 1px solid #d6d6d6;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-search-input {
    height: 60px !important;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-placeholder,
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-item {
    line-height: 56px !important;
  }
  .form-m2-style .ant-form-item-explain div {
    font-size: 12px;
    text-transform: capitalize;
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }
  .form-m2-style
    .ant-form-item-has-error
    .ant-select:not(.ant-select-borderless)
    .ant-select-selector {
    border-color: #d6d6d6 !important;
  }
  .form-m2-style .ant-btn-background-ghost.ant-btn-danger {
    padding: 3px 10px;
    height: auto;
    font-weight: 600;
  }
  .form-m2-style .add-btn {
    padding: 9px 10px;
    height: auto;
    font-weight: 600;
    margin: 12px 0 0;
  }
  .form-check-tag {
    margin: 5px 0 25px;
  }
  .form-check-tag span {
    font-size: 18px;
  }
  .form-check-tag .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
  .btn-save,
  .btn-cancel {
    min-width: 120px;
    font-size: 18px;
    height: 50px;
    border: 2px solid #26b1ff;
    padding: 0px 10px;
    font-weight: 500;
    border-radius: 6px;
  }

  @media (max-width: 1520px) {
    &.container {
      max-width: 1310px;
    }
    .px-6 {
      padding: 45px 60px;
    }
  }
  @media (max-width: 1439px) {
    &.container {
      max-width: 1240px;
    }
    .px-6 {
      padding: 45px 60px;
    }
  }
  @media (max-width: 1359px) {
    &.container {
      max-width: 1170px;
    }
    .px-6 {
      padding: 45px 40px;
    }
    h2.h2-base-600 {
      padding: 0px 15px;
    }
  }
  @media (max-width: 1100px) {
    .form-m2-style .ant-form-item-label label {
      font-size: 14px;
    }
    .form-m2-style .ant-form-item-control-input-content .ant-input,
    .form-m2-style .ant-form-item-control-input-content .ant-select-selector,
    .form-m2-style .ant-form-item-control-input-content .ant-picker {
      height: 50px;
      font-size: 15px;
    }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-search-input {
      height: 50px !important;
    }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-placeholder,
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-item {
      line-height: 46px !important;
    }
    .form-check-tag span {
      font-size: 15px;
    }
    .form-m2-style h3 {
      margin: 12px 0 24px;
      font-size: 18px;
    }
    h2.h2-base-600 {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    .form-m2-style {
      max-width: 420px;
      margin: 0 auto;
    }
    .form-m2-style > .ant-row {
      display: block;
    }
    .form-m2-style .form-col {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .form-m2-style .flex-end-btn .ant-row {
      display: flex;
    }
    .flex-end-btn .ant-col {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      align-items: center;
    }
    .flex-end-btn .ant-form-item-control {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .btn-save,
    .btn-cancel {
      min-width: 110px;
      font-size: 16px;
      height: 42px;
    }
    .form-m2-style .ant-form-item-explain div {
      font-size: 11px;
    }
  }
  @media (max-width: 600px) {
    .px-6 {
      padding: 45px 15px;
    }
  }
  @media (max-width: 480px) {
    h2.h2-base-600 {
      font-size: 17px;
    }
    .reset-fm-row > .reset-fm-col {
      width: max-content !important;
    }

    min-height: max-content;
  }
`;
export const StyledEditIcon = styled(EditIcon)`
  height: 20px;
  width: 20px;
  color: 'red';
`;

export const StyledButton = styled(Button)`
  && {
    background: ${(props) => (props.type == 'history' ? props.color : 'white')};
    color: ${(props) => (props.type == 'history' ? 'white' : props.color)};
    border: none;
    border-radius: 20px;
    font-weight: 505;
    font-size: 15px;
  }
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: ${(props) => (props.type == 'history' ? props.color : 'white')};
    color: ${(props) => (props.type == 'history' ? 'white' : props.color)};
    border: ${(props) =>
      props.type == 'history' ? '' : `1px solid${props.color}`};
  }
  height: 40px;
  width: 240px;
`;
