import {
  AppoinmentFormContainer,
  CancelSaveBookButtonWrapper,
  RightButton,
  StyledPayment,
} from "./styledComponents";
import { Col, Row, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  confirmLabRadiologyAppointment,
  deleteAppointment,
  fetchAppointmentOrders,
  saveAppointmentOrders,
  updateAppointment,
} from "../../../apis/Appointment";

import FormBuilder from "../../utils/FormBuilder";
import StatusModal from "./StatusModal";
import { getHospitalId } from "../../utils/LocalStorage/index";
import { appointmentRquestDetailsColumns } from "./columns";
import { getSettings } from "../../../apis/settings";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { BlueBgTable } from "../AdminDashboard/styledComponents";

const AppointmentRequestDetails = ({ appointmentId, visitType }) => {
  const [appointmentTableData, setAppointmentTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState();
  const [settings, setSettings] = useState();
  const [cancel, setCancel] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [bookDisable, setBookDisable] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cashPaidList, setCashPaidList] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const theme = useSelector((state) => state.themeReducer);
  const history = useHistory();

  const bookingStatusModalVisible = (cancel) => {
    setCancel(cancel);
    setStatusModalVisible(true);
  };

  const saveBookVisit = () => {
    var payload = {
      appointmentId: appointmentId,
      cashPaidTestDataId: cashPaidList,
    };
    saveAppointmentOrders(payload)
      .then((res) => {
        if (res.data.status == 1) {
          message.success(res.data.body);
          getAppointmentDetails();
          setCashPaidList([]);
          setIsSubmit(false);
        } else message.error(res.data.body);
      })
      .catch(console.log)
      .finally(() => {});
  };

  const bookVisit = () => {
    confirmLabRadiologyAppointment(appointmentId)
      .then((res) => {
        if (res.data.status == 1) {
          message.success(res.data.body);
          history.push("/dashboard");
        } else message.error(res.data.body);
      })
      .catch(console.log)
      .finally(() => {});
  };

  const cancelAppointment = () => {
    if (appointmentId) {
      setCancelLoading(true);
      deleteAppointment(appointmentId)
        .then((response) => {
          if (response.data.status === 1) {
            setCancelLoading(false);
            setStatusModalVisible(false);
            history.push("/dashboard");
            message.success("Appointment Cancelled Successfully");
          } else {
            setCancelLoading(false);
            setStatusModalVisible(false);
            message.error("Could not delete appointment,Try again");
          }
        })
        .catch((err) => {
          setCancelLoading(false);
          setStatusModalVisible(false);
          message.error("Could not delete appointment,Try again");
        });
    } else {
      setCancelLoading(false);
      setStatusModalVisible(false);
      history.push("/dashboard");
    }
  };

  const getAppointmentDetails = () => {
    setTableLoading(true);
    fetchAppointmentOrders(appointmentId)
      .then((res) => {
        if (res.data.status == 1) {
          var tableData = res.data.body.table;
          var submit = tableData.filter((element) => {
            if (
              element.insuranceStatus == "Pending" ||
              element.insuranceStatus == "Insurance Claim Rejected"
            )
              return true;
          });
          if (submit.length > 0) setBookDisable(true);
          else setBookDisable(false);
          setAppointmentTableData(tableData);

          setAppointmentDetails(res.data.body.paymentResponse);
        } else {
          message.error(res.data.body);
        }
      })
      .catch(console.log)
      .finally(() => {
        setTableLoading(false);
      });
  };

  useEffect(() => {
    getSettings(null, getHospitalId()).then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          setSettings(data.body);
        }
      }
    });

    getAppointmentDetails();
  }, []);

  return (
    <div>
      <AppoinmentFormContainer>
        <FormBuilder
          formConfig={[
            {
              inputType: "heading",
              label: "All Orders",
            },
          ]}
        />
        <BlueBgTable
          dataSource={appointmentTableData}
          loading={tableLoading}
          columns={appointmentRquestDetailsColumns({
            theme: theme,
            cashPaidList: cashPaidList,
            setCashPaidList: setCashPaidList,
            settings: settings,
            setIsSubmit: setIsSubmit,
          })}
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </AppoinmentFormContainer>
      <AppoinmentFormContainer>
        <Col lg={12} md={16} sm={24} xs={24}>
          <FormBuilder
            formConfig={[
              {
                inputType: "heading",
                label: "Payment Summary",
              },
            ]}
          />
          <Row gutter={[36, 24]} style={{ marginTop: "10px" }}>
            <>
              <Col span="12">
                <StyledPayment>&nbsp;Total Order Amount: </StyledPayment>
              </Col>
              <Col span="12">
                <StyledPayment>
                  <b>
                    {settings?.currency}&nbsp;
                    {parseFloat(
                      appointmentDetails?.totalAmount
                        ? appointmentDetails.totalAmount
                        : 0
                    ).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
            </>
            <>
              <Col span="12">
                <StyledPayment>&nbsp;Insurance Claim: </StyledPayment>
              </Col>
              <Col span="12">
                <StyledPayment>
                  <b>
                    {settings?.currency}&nbsp;
                    {parseFloat(
                      appointmentDetails?.claimableAmount
                        ? appointmentDetails.claimableAmount
                        : 0
                    ).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
            </>
            <>
              <Col span="12">
                <StyledPayment>&nbsp;In Cash: </StyledPayment>
              </Col>
              <Col span="12">
                <StyledPayment>
                  <b>
                    {settings?.currency}&nbsp;
                    {parseFloat(
                      appointmentDetails?.totalAmountOnCashOrder
                        ? appointmentDetails.totalAmountOnCashOrder
                        : 0
                    ).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
            </>
            <>
              <Col span="12">
                <StyledPayment>&nbsp;VAT: </StyledPayment>
              </Col>
              <Col span="12">
                <StyledPayment>
                  <b>
                    {settings?.currency}&nbsp;
                    {parseFloat(
                      appointmentDetails?.vat ? appointmentDetails.vat : 0
                    ).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
            </>
            <>
              <Col span="12">
                <StyledPayment>&nbsp;Amount Payable: </StyledPayment>
              </Col>
              <Col span="12">
                <StyledPayment>
                  <b>
                    {settings?.currency}&nbsp;
                    {parseFloat(
                      appointmentDetails?.payable
                        ? appointmentDetails.payable
                        : 0
                    ).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
            </>
          </Row>
        </Col>
        <CancelSaveBookButtonWrapper>
          <RightButton
            className="btn-cancel"
            type="primary"
            ghost
            style={{ border: "none", boxShadow: "none" }}
            onClick={() => {
              bookingStatusModalVisible(true);
            }}
          >
            Cancel
          </RightButton>
          <RightButton
            type="primary"
            ghost
            className="btn-save"
            disabled={!isSubmit}
            onClick={saveBookVisit}
          >
            Save
          </RightButton>
          <RightButton
            type="primary"
            className="btn-save"
            disabled={bookDisable || cashPaidList.length > 0}
            onClick={bookVisit}
          >
            Book Appointment
          </RightButton>
        </CancelSaveBookButtonWrapper>
      </AppoinmentFormContainer>
      <StatusModal
        cancel={cancel}
        cancelAppointment={cancelAppointment}
        visible={statusModalVisible}
        closeModal={() => setStatusModalVisible(false)}
        cancelLoading={cancelLoading}
      />
    </div>
  );
};

export default AppointmentRequestDetails;
