import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Form,
  Button,
  message,
  Tag,
  Collapse,
  Divider,
  Container,
  Descriptions,
  Table,
} from 'antd';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOneByID } from '../../../../apis/patients';
import { ReactComponent as CollapIcon } from '../../../../assets/icons/collapsIcon.svg';
import { PrinterOutlined } from '@ant-design/icons';
import Icon, { CloseCircleOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../../../assets/icons/Edit.svg';
import * as Sentry from '@sentry/react';
import FormBuilder from '../../../utils/FormBuilder';
import { genderList } from '../../../Datasets/basicDetails';
import {
  getHealthRecordForms,
  addHealthRecord,
  getSectionData,
  fetchConsulationReportItems,
  getPreviousRecords,
} from '../../../../apis/healthRecord';
import { getProfile } from '../../../../apis/User';
import {
  generateFormFields,
  deserialize,
} from '../../../utils/FormBuilder/valuesSerialisation';
import Spinner from '../../../utils/Spinner';
import CollapsBody from './CollapsBody';
import SuccessModal from '../../../utils/StatusModals/SuccessModals';
import ConfirmationModal from './ConfirmationModal';
import { fetchFormFieldMeta } from '../../../../apis/forms';
import { fetchBillingDetails } from '../../../../apis/Appointment';
import { softValidate } from '../helper';
import moment from 'moment';
import { getAppointmentDetails } from '../../../../apis/Appointment';
import RequestSummary from '../RequestSummary';
import { useReactToPrint } from 'react-to-print';
import { getHospitalName } from '../../../utils/LocalStorage/index';
import Moment from 'react-moment';
import MeditagLogo from '../../../../assets/images/hospital_pic_1671612999007.png';
import TableContainer from '../TableContainer';
import { tablePrescriptionPrint } from '../MedicalDetails/columns';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { getHospitalDetailWithHospitalId } from '../../../../apis/Public';
import md5 from 'md5';
import { createRef } from 'react';

const UserProfileContainer = styled(Col)`
  .user-profile-head-mobile {
    display: none;
  }
  @media (max-width: 767px) {
    .user-profile-head-mobile {
      display: flex;
      flex-direction: column;
    }
    .user-profile-head-desktop {
      display: none;
    }
  }
`;

const ExaminationsContainer = styled.div`
  background: #fff;
  padding: 40px 20px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    padding-top: 15px !important;
  }
`;
const { Panel } = Collapse;

const ExaminationsPrintContainer = styled.div`
  background: #fff;
  padding: 10px 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    padding-top: 15px !important;
  }
  @media print {
    & .divFooter {
      position: fixed;
      bottom: 0;
      background-color: 'black';
    }
  }
`;

const StyledCollaps = styled(Collapse)`
  && {
    background: #fff;
  }
  & .ant-collapse-item {
    border: none;
    margin-bottom: 20px;
  }
  & .ant-collapse-item:last-child {
    border: none;
  }

  & .ant-collapse-header {
    border: 2px solid ${(props) => props.color};
    border-radius: 7px !important;
    color: ${(props) => props.color} !important;
    font-weight: 600;
  }
  & .ant-collapse-item:last-child .ant-collapse-header {
    border: 2px solid ${(props) => props.color};
    border-radius: 7px;
  }
  & .scd-1 {
    fill: ${(props) => props.color};
  }

  & .ant-collapse-item-active .ant-collapse-header {
    border: 2px solid ${(props) => props.color};
    border-radius: 7px !important;
    color: #fff !important;
    font-weight: 600;
    background: ${(props) => props.color};
    & .scd-1 {
      fill: #fff;
    }
  }
`;

const StyledRow = styled(Row)`
  font-weight: 700;
  color: rgb(93, 93, 93);
  font-size: '12px';
`;

const StyledHeading = styled.h3`
  color: 'black',
  margin-left: '33px',
 font-size: '16px',
  font-family: 'Urbanist';`;

const StyledP = styled.p`
  display: contents;
  font-size: 12px;
  font-weight: normal;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;
const ConsultationReport = ({ setVisibility }) => {
  const { pId, pName, appointmentId, sectionId } = useParams();
  console.log('params', useParams());
  console.log('pid n pname', pId, pName);
  const componentRef = useRef();
  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const user = useSelector((state) => state.userReducer);
  console.log('user in consultation', user);
  console.log('patient in consultation', patient);

  const history = useHistory();
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [consultationReportSections, setConsultationReportSections] = useState(
    []
  );
  const [successModal, setSuccessModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [apiRequestData, setApiRequestData] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [dropDownParentIds, setDropDownParentIds] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);
  const [validationStatus, setValidationStatus] = useState({});
  const [validationCriterias, setValidationCriterias] = useState([]);
  const [form] = Form.useForm();
  const theme = useSelector((state) => state.themeReducer);
  const location = useLocation();
  const [appointmentData, setAppointmentData] = useState();
  const [userData, setUserData] = useState();
  const [age, setAge] = useState();
  /*print */
  const [printPrescription, setPrintPrescription] = useState([]);
  const [brushColor, setBrushColor] = useState('#000000');
  const [showCanvas, setshowCanvas] = useState(true);
  const [doctorDetail, setDoctorDetails] = useState({});
  const [doctorPhone, setDoctorPhone] = useState([]);
  const [hospitalDetail, sethospitalDetail] = useState({});

  const [scratchpad, setScratchPad] = useState(
    JSON.parse(localStorage.getItem(`${patient.pId}-${patient.pName}`))
      ?.length > 0
      ? JSON.parse(localStorage.getItem(`${patient.pId}-${patient.pName}`)).map(
          () => createRef()
        )
      : []
  );
  // const firstCanvas = useRef(null);
  // console.log('firstCanvas', firstCanvas);

  const getGender = (code) => {
    var gender;
    gender = genderList().find((item) => item.code === code);
    if (gender?.text)
      if (gender.text == 'Not Available') return 'NA';
      else return gender.text;
    else return code;
  };
  const ProfileHead = styled.div``;

  const styles = {
    marginTop: '50px',
  };

  const BgPrimeColor = styled.div`
    background-color: ${(props) => props.color};
    width: fit-content;
    align-content: center;
    border-radius: 5px;
    display: flex;
    padding: ${(props) => (props.noPadding ? '2px 0px 2px 10px' : '2px 10px')};
    margin-left: 5px;
    justify-content: center;
    color: ${(props) => props.text};
  `;

  const BgPrimeColorMobile = styled.div`
    color: ${(props) => props.text};
    background-color: ${(props) => props.color};
    width: fit-content;
    align-content: center;
    border-radius: 5px;
    display: flex;
    padding: 0;
    margin-left: 5px;
    justify-content: center;
  `;

  const StyledCol = styled(Col)`
    display: flex;
    height: 100px;
    justify-content: center;
    flex-direction: column;
    padding-left: 10px;
  `;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'emp-data',

    // onAfterPrint: () => alert('print success'),
  });

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 17 && e.keyCode === 80) {
        handlePrint();
      }
    });
  }, []);

  const renderTypes = {
    'Vital Information': 'report',
    Laboratory: 'laboratory',
    Radiology: 'radiology',
    Examination: 'report',
    'Care Plan': 'report',
    Observation: 'observation',
    Prescriptions: 'prescriptions',
  };

  const Hospitalname = styled.h2`
    color: black;
    text-transform: capitalize;
  `;
  const onValuesChange = (changed) => {
    Object.entries(changed).map(([key, value]) => {
      let changedField = dropDownParentIds.find(
        (item) => item.parentField == key
      );
      if (changedField) {
        let dropDownItem = dropDownValues.filter((item) => item.value == value);

        if (dropDownItem[dropDownItem.length - 1]) {
          changedField.sectionId = dropDownItem[dropDownItem.length - 1].id;

          setMetaDataFieldIds([changedField]);
        }
      }
    });
    Object.keys(changed).forEach((chngd) => {
      let validator = validationCriterias.find((item) => {
        return item.fieldId == chngd;
      });

      if (validator) {
        validationStatus[chngd] = softValidate(validator, changed[chngd]);
      }
    });

    setValidationStatus({ ...validationStatus });
  };
  useEffect(() => {
    getProfile(user.id)
      .then((response) => {
        if (response.data.status === 1) {
          console.log('data in', response.data.body);
          console.log(
            'data in',
            response.data.body.departments[0].departmentName
          );
          console.log('data in ph', response.data.body.mobileNo.phoneNumber);

          setDoctorDetails(response.data.body.departments[0].departmentName);
          setDoctorPhone(response.data.body.mobileNo.phoneNumber);
        } else {
          message.error('Could not load profile,Try again');
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Could not load profile,Try again');
      });
  }, []);
  const submitFormData = (dynamicFormData) => {
    setSubmitLoading(true);
    addHealthRecord(
      sectionId,
      patient.pId,
      dynamicFormData,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            setSuccessModal(true);
          } else {
            message.error("Couldn't save health records");
          }
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const formSubmitHandler = (values) => {
    let data = Object.keys(formIds).map((key) => {
      const filtered = {};
      formIds[key].forEach((k) => {
        filtered[k] = values[k];
      });
      return {
        formId: key,
        fields: deserialize(filtered),
      };
    });
    setApiRequestData(data);
    setConfirmationModal(true);
  };
  // fetch formFields
  const getForms = useCallback(() => {
    var isData = false;
    getHealthRecordForms(sectionId, patient.pId)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            const tempFormIds = {};
            var tempMetaDataIds = [];
            var validationList = [];
            var tempParentIds = [];
            isData = data.body[0].date ? true : false;
            (!isData ? data.body : data.body[0].dynamicHealthRecord).forEach(
              (item) => {
                //getting formIds and corresponding field IDs for submit request Generation
                tempFormIds[item.id] = (isData ? item.form.fields : item.fields)
                  .filter((field) => field.fieldType !== 'TAG_SELECT')
                  .map((field) => field.id);

                //finding fields which needs to call external API for fetching values eg:dropdown
                tempMetaDataIds = [
                  ...tempMetaDataIds,
                  ...item.fields.filter(
                    (field) =>
                      field.dropDownType !== null &&
                      field.dropDownType !== undefined &&
                      Object.keys(field.dropDownType).length !== 0
                  ),
                ];
                //collecting parentIds to call dropdown values api when change value of dependend dropdown, eg: fetching filtered medicine doses after selecting a medicine
                tempParentIds = [
                  ...tempParentIds,
                  ...item.fields.filter(
                    (field) =>
                      field.parentField !== null &&
                      field.parentField !== undefined
                  ),
                ]; //COLLECTING FIELDS HAVING SOFT VALIDATORS
                validationList = [
                  ...validationList,
                  ...item.fields
                    .filter(
                      (field) =>
                        field.softValidator !== null &&
                        field.softValidator !== undefined &&
                        field.softValidator.length !== 0
                    )
                    .map((item) => {
                      return {
                        fieldId: item.id,
                        validationCrieteria: item.softValidator.map((val) => ({
                          type: val.validator,
                          value: val.value,
                        })),
                      };
                    }),
                ];
              }
            );

            //converting forms into formBuilder readable format
            let FormsTemp = (
              !isData ? data.body : data.body[0].dynamicHealthRecord
            ).map((item) => {
              return {
                formName: isData ? item.form.formName : item.formName,
                fields: generateFormFields(item.fields).map((field) => ({
                  ...field,
                  disabled: user.userType === 'DOCTOR' ? false : true,
                })),
              };
            });
            setFormIds(tempFormIds);
            setMetaDataFieldIds(tempMetaDataIds);
            setForms(FormsTemp);
            setValidationCriterias(validationList);
            setDropDownParentIds(tempParentIds);
            setFormFetchLoading(false);
          } else {
            message.error(data.body);
            setFormFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        setFormFetchLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  }, [sectionId]);

  const getSectiondetails = () => {
    fetchConsulationReportItems(sectionId)
      .then(({ data, status }) => {
        console.log('data is', data);
        if (status == 200) {
          if (data.status == 1) {
            setConsultationReportSections(data.body);
          }
        }
      })
      .catch();
  };

  const getPreviousData = () => {
    getSectionData(
      sectionId,
      user.useType == 'PATIENT' ? user.id : patient.pId,
      location?.state?.id ? location.state.id : patient.appointmentId
    )
      .then(({ data, status }) => {
        console.log(location);
        console.log('previous', data);
        if (status == 200) {
          if (data.status == 1) {
            console.log(data.body);
            let initialValues = {};
            data.body.forEach((item) => {
              item.fields.forEach((item) => {
                initialValues[item.fieldId] = item.value;
              });
            });
            setFormInitialValues(initialValues);
          }
        }
      })
      .catch(console.err);
  };
  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    return age_now;
  };

  const getSessionData = () => {
    getAppointmentDetails(
      location?.state?.id ? location.state.id : patient.appointmentId
    )
      .then((res) => {
        if (res.data.status === 1) {
          let record = res.data.body;
          let item = {
            appointmentId: record.id,
            type: record.appointmentType.name,
            status: record.status,
            date: record.date,
            hospital: 'Emirates Hospital',
            doctor: record.doctor.firstName + ' ' + record.doctor.lastName,
            startTime: record.startTime,
            endTime: record.endTime,
          };
          setAppointmentData(item);
        } else {
          message.error('Could not fetch appointment details, try again');
        }
      })
      .catch((err) => {
        message.error('Could not fetch appointment details, try again');
      });
  };
  useEffect(() => {
    getOneByID(patient.pId)
      .then(({ status, data }) => {
        if (status == 200) {
          if (data.status == 1) {
            let body = data.body;
            setUserData(data.body);

            let age = calculate_age(data.body.dob);
            if (age) setAge(age);
          } else {
            message.error('Unable to fetch Patient');
          }
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Unable to fetch Patient');
      });
  }, []);

  useEffect(() => {
    setFormFetchLoading(true);
    getForms();
    getSectiondetails();
    getPreviousData();
    getSessionData();
  }, [location?.state?.id]);

  useEffect(() => {
    if (forms.length && metaDataFieldIds.length) {
      let fieldMetaPromises = metaDataFieldIds.map((item) => {
        return fetchFormFieldMeta(
          item.dropDownType.dropDownType,
          item.sectionId
        );
      });
      let tempDropDownValues = [...dropDownValues];

      Promise.all(fieldMetaPromises)
        .then((responses) => {
          responses.forEach(({ data, status }, index) => {
            if (status == 200) {
              if (data.status == 1)
                tempDropDownValues = [...tempDropDownValues, ...data.body];
              //Injecting meta data for corresponding  formfields
              forms.forEach((form) => {
                let metaField = form.fields.find(
                  (field) => field.name === metaDataFieldIds[index].id
                );
                if (metaField) {
                  // setDropDownValues([...dropDownValues, ...data.body]);
                  metaField.meta = data.body.map((item) => item.value);
                } else console.log('Meta undefined');
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setMetaDataFieldIds([]);
          setDropDownValues(tempDropDownValues);
          setForms([...forms]);
        });
    }
  }, [metaDataFieldIds, forms]);

  useEffect(() => {
    getHospitalDetailWithHospitalId(
      md5(`${user.hospitalId}`),
      md5(`${user.id}`)
    )
      .then((res) => {
        if (res.status == 200) {
          sethospitalDetail(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  /*useEffect for print prescription*/
  useEffect(() => {
    if (
      consultationReportSections.filter(
        (name) => name.formName === 'Prescriptions'
      )[0]?.id
    ) {
      getPreviousRecords(
        consultationReportSections.filter(
          (name) => name.formName === 'Prescriptions'
        )[0]?.id,
        patient.pId,
        patient.appointmentId,
        undefined,
        undefined,
        true
      )
        .then(({ data, status }) => {
          console.log('getPrevious prescription data', data);
          if (status === 200) {
            if (data.status === 1) {
              setPrintPrescription(data.body);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    consultationReportSections.filter(
      (name) => name.formName === 'Prescriptions'
    )[0]?.id,
  ]);
  console.log('prescrption is ', printPrescription);

  useEffect(() => {
    const items = JSON.parse(
      localStorage.getItem(`${patient.pId}-${patient.pName}`)
    );
    console.log('items is', items);
    if (Array.isArray(items) && items.length > 0) {
      setshowCanvas(true);
      setTimeout(() => {
        items.forEach((item, index) => {
          scratchpad[index].current.loadPaths(item);
        });
      }, 2000);
    } else {
      setshowCanvas(false);
      console.log('inside else');
    }
  }, []);

  return formFetchLoading ? (
    <Spinner />
  ) : (
    <>
      {user.userType === 'DOCTOR' && appointmentData ? (
        <ExaminationsContainer style={{ paddingTop: '0' }}>
          <Row>
            <Col span={1}></Col>
            <h3 style={{ color: theme['@primary-color'] }}>Visit Summary</h3>
          </Row>
          <Row>
            <Col lg={1} md={1} sm={1} xs={1}></Col>
            <Col lg={8} md={8} sm={8} xs={23}>
              <StyledRow>
                Date:&nbsp;&nbsp;
                <StyledP>
                  {moment(appointmentData?.date).format('DD-MM-YYYY')}
                </StyledP>
              </StyledRow>
              <StyledRow>
                Time:&nbsp;&nbsp;
                <StyledP>
                  {moment(appointmentData?.startTime).format('hh:mm a')}
                  &nbsp;-&nbsp;
                  {moment(appointmentData?.endTime).format('hh:mm a')}
                </StyledP>
              </StyledRow>
            </Col>
            <Col lg={0} md={0} sm={0} xs={1}></Col>
            <Col lg={8} md={8} sm={8} xs={23}>
              <StyledRow>
                Type:&nbsp;&nbsp;<StyledP>{appointmentData?.type}</StyledP>
              </StyledRow>
              <StyledRow>
                Status:&nbsp;&nbsp;<StyledP>{appointmentData?.status}</StyledP>
              </StyledRow>
            </Col>
            <Col lg={0} md={0} sm={0} xs={1}></Col>
            <Col lg={7} md={7} sm={7} xs={23}>
              <StyledRow>
                Provider:&nbsp;&nbsp;
                <StyledP>Dr. {appointmentData?.doctor}</StyledP>
              </StyledRow>
              <Row></Row>
            </Col>
          </Row>
        </ExaminationsContainer>
      ) : (
        <></>
      )}
      {user.userType == 'INSURANCE' && <RequestSummary />}
      <ExaminationsContainer>
        <Row gutter={[24, 12]}>
          <Col span={24}>
            <StyledCollaps
              expandIconPosition="right"
              bordered={false}
              expandIcon={() => (
                <Icon component={CollapIcon} style={{ fontSize: '25px' }} />
              )}
              color={theme['@primary-color']}
            >
              {consultationReportSections.map((item) => (
                <Panel header={item.formName} key={item.id}>
                  <Row justify="end">
                    {user.userType === 'DOCTOR' ? (
                      location?.state?.id ? (
                        <></>
                      ) : (
                        <Col>
                          {item.formName !== 'Observation' && (
                            <Icon
                              component={EditIcon}
                              style={{ fontSize: '25px', paddingTop: '10px' }}
                              onClick={() => {
                                section.redirect(item.id);
                              }}
                            />
                          )}
                        </Col>
                      )
                    ) : (
                      <></>
                    )}
                    <Col span={24}>
                      <CollapsBody
                        sectionName={item.formName}
                        sectionId={item.id}
                        renderType={
                          renderTypes[item.formName]
                            ? renderTypes[item.formName]
                            : 'table'
                        }
                      />
                    </Col>
                  </Row>
                </Panel>
              ))}
            </StyledCollaps>
          </Col>
          <Col span={24}>
            {' '}
            <Form
              layout="vertical"
              onFinish={formSubmitHandler}
              initialValues={formInitialValues}
              key={Object.keys(formInitialValues).length}
              onValuesChange={onValuesChange}
              form={form}
              scrollToFirstError={true}
            >
              {forms.map((subForm) => (
                <FormBuilder
                  formConfig={subForm.fields}
                  formInstance={form}
                  softValidationFields={validationStatus}
                />
              ))}
              {user.userType === 'DOCTOR' &&
                (location?.state?.id ? (
                  <Form.Item>
                    {forms.length !== 0 && (
                      <>
                        <RightButton
                          type="primary"
                          htmlType="submit"
                          loading={submitLoading}
                          className="btn-save"
                          disabled="true"
                        >
                          Submit
                        </RightButton>
                      </>
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item>
                    {forms.length !== 0 && (
                      <>
                        <RightButton
                          type="primary"
                          htmlType="submit"
                          loading={submitLoading}
                          className="btn-save"
                        >
                          Submit
                        </RightButton>
                        <button
                          className="btn-save"
                          onClick={handlePrint}
                          type="button"
                        >
                          Print &nbsp;
                          <PrinterOutlined
                            style={{
                              fontSize: '20px',
                              color: theme['@primary-color'],
                            }}
                          />
                        </button>
                      </>
                    )}
                  </Form.Item>
                ))}
            </Form>
          </Col>
        </Row>
      </ExaminationsContainer>
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          {/* <div
            style={{
              borderTop: `1px solid #f0f0f0`,
              marginLeft: 20,
              marginRight: 20,
              height: '2px',
              width: '100%',
              marginBottom: '30px',
            }}
          ></div> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'black',
              padding: '20px',
            }}
          >
            <Hospitalname
              style={{ color: 'white', fontSize: '16px', marginLeft: '38px' }}
            >
              {getHospitalName()}
              <p style={{ color: 'white', fontSize: '16px' }}>
                {hospitalDetail?.hospital?.address} <br />
                Date : {moment(appointmentData?.date).format('DD-MM-YYYY')}
              </p>
            </Hospitalname>

            <img src={MeditagLogo} width="20%" height="20%" />
          </div>

          <ExaminationsPrintContainer style={{ paddingTop: '10px' }}>
            <Row>
              <Col span={12}>
                {' '}
                <h3
                  style={{
                    color: 'black',
                    marginLeft: '38px',
                    fontSize: '16px',
                    fontFamily: 'Urbanist',
                    fontWeight: '700',
                  }}
                >
                  Doctor Details :
                </h3>
                <StyledRow>
                  <Col span={12}>
                    {/* {' '}
                    <h3
                      style={{
                        marginLeft: '38px',
                        fontSize: '14px',
                      }}
                    >
                      Name:&nbsp;&nbsp; Dr. {appointmentData?.doctor}
                    </h3> */}
                    <StyledRow
                      style={{
                        marginLeft: '38px',
                        fontSize: '12px',
                      }}
                    >
                      Name:&nbsp;&nbsp;
                      <StyledP>
                        Dr. {appointmentData?.doctor}
                        &nbsp;&nbsp;
                      </StyledP>
                    </StyledRow>
                  </Col>
                  <Col span={12}>
                    <StyledRow
                      style={{
                        marginLeft: '38px',
                        fontSize: '12px',
                      }}
                    >
                      Phone:&nbsp;&nbsp;
                      <StyledP>{`${doctorPhone}`}</StyledP>
                      &nbsp;&nbsp;
                    </StyledRow>
                  </Col>
                </StyledRow>
                <StyledRow
                  style={{
                    marginLeft: '38px',
                    fontSize: '12px',
                    marginTop: '3px',
                  }}
                >
                  Speciality:&nbsp;&nbsp;<StyledP>{`${doctorDetail}`}</StyledP>{' '}
                  &nbsp;&nbsp;
                </StyledRow>
              </Col>
              <Col span={12}>
                {' '}
                <h3
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    // marginLeft: '33px',
                    fontFamily: 'Urbanist',
                    fontWeight: '700',
                  }}
                >
                  Patient Details :
                </h3>
                <StyledRow>
                  <Col span={12}>
                    {/* {' '}
                    <h3
                      style={{
                        marginLeft: '38px',
                        fontSize: '14px',
                      }}
                    >
                      Name:&nbsp;&nbsp; Dr. {appointmentData?.doctor}
                    </h3> */}
                    <StyledRow
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      Name:&nbsp;&nbsp;
                      <StyledP>
                        {patient.pName}
                        &nbsp;&nbsp;
                      </StyledP>
                    </StyledRow>
                  </Col>
                  <Col span={12}>
                    <StyledRow
                      style={{
                        marginLeft: '38px',
                        fontSize: '12px',
                      }}
                    >
                      Patient Id:&nbsp;&nbsp;
                      <StyledP>{patient.pId}</StyledP>
                      &nbsp;&nbsp;
                    </StyledRow>
                  </Col>
                </StyledRow>
                <StyledRow
                  style={{
                    fontSize: '12px',
                    marginTop: '5px',
                  }}
                >
                  Age:&nbsp;&nbsp;
                  {age}
                </StyledRow>
              </Col>
            </Row>
          </ExaminationsPrintContainer>
          <Divider />
          <ExaminationsPrintContainer>
            <Row>
              <Col span={1}></Col>
              <h3
                style={{
                  color: 'black',
                  fontSize: '16px',
                  fontFamily: 'Urbanist',
                  fontWeight: '700',
                }}
              >
                Visit Summary
              </h3>
            </Row>
            <Row>
              <Col lg={1} md={1} sm={1} xs={1}></Col>
              <Col lg={8} md={8} sm={8} xs={23}>
                <StyledRow>
                  Date:&nbsp;&nbsp;
                  <StyledP>
                    {moment(appointmentData?.date).format('DD-MM-YYYY')}
                  </StyledP>
                </StyledRow>
                <StyledRow style={{ marginTop: '3px' }}>
                  Time:&nbsp;&nbsp;
                  <StyledP>
                    {moment(appointmentData?.startTime).format('hh:mm a')}
                    &nbsp;-&nbsp;
                    {moment(appointmentData?.endTime).format('hh:mm a')}
                  </StyledP>
                </StyledRow>
              </Col>
              <Col lg={0} md={0} sm={0} xs={1}></Col>
              <Col lg={8} md={8} sm={8} xs={23}>
                <StyledRow>
                  Type:&nbsp;&nbsp;<StyledP>{appointmentData?.type}</StyledP>
                </StyledRow>
                <StyledRow style={{ marginTop: '3px' }}>
                  Status:&nbsp;&nbsp;
                  <StyledP>{appointmentData?.status}</StyledP>
                </StyledRow>
              </Col>
              <Col lg={0} md={0} sm={0} xs={1}></Col>
              <Col lg={7} md={7} sm={7} xs={23}>
                <StyledRow>
                  Provider:&nbsp;&nbsp;
                  <StyledP>Dr. {appointmentData?.doctor}</StyledP>
                </StyledRow>
                <Row></Row>
              </Col>
            </Row>
          </ExaminationsPrintContainer>
          <Divider />
          <ExaminationsPrintContainer style={{ paddingTop: '1px' }}>
            <h3
              style={{
                color: 'black',
                fontSize: '16px',
                fontFamily: 'Urbanist',
                fontWeight: '700',
                marginLeft: '38px',
              }}
            >
              Prescription
            </h3>
            <Row style={{ padding: '10px', marginLeft: '30px' }}>
              {/* {JSON.stringify(user)}
            {JSON.stringify(selectedData)} */}

              <Table
                columns={tablePrescriptionPrint()}
                dataSource={printPrescription}
                pagination={false}
              />
            </Row>
          </ExaminationsPrintContainer>

          <ExaminationsPrintContainer>
            <Row>
              <Col>
                <div style={{ marginLeft: '38px', marginTop: '10px' }}>
                  {consultationReportSections
                    .filter((item) => item.formName === 'Care Plan')
                    .map((item) => (
                      <>
                        <h3
                          style={{
                            color: 'black',
                            fontSize: '16px',
                            fontFamily: 'Urbanist',
                            fontWeight: '700',
                          }}
                        >
                          {item.formName}{' '}
                        </h3>
                        <div>
                          <CollapsBody
                            sectionName={item.formName}
                            sectionId={item.id}
                            renderType={
                              renderTypes[item.formName]
                                ? renderTypes[item.formName]
                                : 'table'
                            }
                          />
                        </div>
                      </>
                    ))}
                </div>
              </Col>
            </Row>
            <div
              className="divFooter"
              style={{
                backgroundColor: 'black',
                width: '100%',
                marginLeft: '-8px',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px',
              }}
            >
              <p style={{ color: 'white', marginLeft: '10px' }}>
                <PhoneOutlined /> &nbsp; +91
                {hospitalDetail?.hospital?.phonenumber?.phoneNumber}
              </p>
              <p style={{ color: 'white', marginRight: '10px' }}>
                <MailOutlined />
                {/* &nbsp; Email:{' '} */}
              </p>
            </div>
            {showCanvas
              ? scratchpad.map((item, index) => (
                  <ReactSketchCanvas
                    style={styles}
                    width="700px"
                    height="900px"
                    strokeWidth={5}
                    strokeColor={brushColor}
                    eraseMode={true}
                    ref={item}
                    eraserWidth={18}
                    key={index}
                  />
                ))
              : null}
          </ExaminationsPrintContainer>

          <Divider />
        </div>
      </div>
      <SuccessModal
        message="Successfully saved"
        visible={successModal}
        setVisible={setSuccessModal}
        okText="Done"
        type="submitReport"
        onOk={() => {
          setSuccessModal(false);
        }}
      />
      <ConfirmationModal
        visible={confirmationModal}
        setVisible={setConfirmationModal}
        onConfirm={() => submitFormData(apiRequestData)}
      />
    </>
  );
};

export default ConsultationReport;
