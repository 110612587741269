import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Button, Popover, Form, Collapse, message } from "antd";
import Icon, { DeleteOutlined } from "@ant-design/icons";
import ObservationsPopOver from "./ObservationsPopOver";
import {
  generateFormFields,
  deserialize,
} from "../../../../utils/FormBuilder/valuesSerialisation";
import * as Sentry from "@sentry/react";
import FormBuilder from "../../../../utils/FormBuilder";
import { ReactComponent as CollapIcon } from "../../../../../assets/icons/collapsIcon.svg";
import {
  addHealthRecord,
  deleteObservation,
  fetchMenuSections,
  getHealthRecordForms,
  getSectionData,
} from "../../../../../apis/healthRecord";
import { useSelector } from "react-redux";
import "./popoverStyle.css";
import NoRecords from "../../../../utils/NoRecord";
import Spinner from "../../../../utils/Spinner";
import { fetchFormFieldMeta } from "../../../../../apis/forms";
import { softValidate } from "../../helper";
import { useParams } from "react-router-dom";

const { Panel } = Collapse;

const ObservationContainer = styled.div`
  background: #fff;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  min-height: calc(100vh - 200px);

  .ant-input[disabled] {
    color: #6d6d6d;
    background: #fff;
    cursor: not-allowed;
    opacity: 1;
  }
  .form-m2-style h3 {
    margin: 15px 0 25px;
    font-size: 20px;
  }
  .form-m2-style .ant-form-item-label label {
    font-size: 16px;
    color: #818181;
  }
  .form-m2-style .ant-form-item-label label:before {
    display: none;
  }
  .form-m2-style .ant-form-item-label label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff0003;
    font-size: 18px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    font-weight: 700;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-input,
  .form-m2-style .ant-form-item-control-input-content .ant-select-selector,
  .form-m2-style .ant-form-item-control-input-content .ant-picker {
    height: 60px;
    border: 1px solid #d6d6d6;
    border-radius: 5px !important;
    font-size: 18px;
    color: #333333;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .appointment_form_style1.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .form-m2-style .ant-form-item-control-input-content .ant-select + .ant-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select:hover
    .ant-select-selector {
    border: 1px solid #d6d6d6;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-search-input,
  .form-m2-style .ant-form-item-control-input-content .ant-input-number-input {
    height: 60px !important;
  }
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-placeholder,
  .form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-item {
    line-height: 56px !important;
  }
  .form-m2-style .ant-form-item-explain div {
    font-size: 12px;
    text-transform: capitalize;
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }
  .form-m2-style
    .ant-form-item-has-error
    .ant-select:not(.ant-select-borderless)
    .ant-select-selector {
    border-color: #d6d6d6 !important;
  }
  .form-m2-style .ant-btn-background-ghost.ant-btn-danger {
    padding: 3px 10px;
    height: auto;
    font-weight: 600;
  }
  .form-m2-style .add-btn {
    padding: 9px 10px;
    height: auto;
    font-weight: 600;
    margin: 12px 0 0;
  }
  .form-check-tag {
    margin: 5px 0 25px;
  }
  .form-check-tag span {
    font-size: 18px;
  }
  .form-check-tag .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
  .btn-save,
  .btn-cancel {
    min-width: 120px;
    font-size: 18px;
    height: 50px;
    border: 2px solid #26b1ff;
    padding: 0px 10px;
    font-weight: 500;
    border-radius: 6px;
  }
  @media (max-width: 1100px) {
    .form-m2-style .ant-form-item-label label {
      font-size: 14px;
    }
    .form-m2-style .ant-form-item-control-input-content .ant-input,
    .form-m2-style .ant-form-item-control-input-content .ant-select-selector,
    .form-m2-style .ant-form-item-control-input-content .ant-picker,
    .form-m2-style .ant-form-item-control-input-content .ant-input-number {
      height: 50px;
      font-size: 15px;
    }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-search-input {
      height: 50px !important;
    }
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-placeholder,
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-item {
      line-height: 46px !important;
    }
    .form-check-tag span {
      font-size: 15px;
    }
    .form-m2-style h3 {
      margin: 12px 0 24px;
      font-size: 18px;
    }
    h2.h2-base-600 {
      font-size: 20px;
    }
    .form-m2-style .forminput_timepick {
      height: 50px;
      border-radius: 5px;
    }
    .available_time_field .ant-form-item-control-input,
    .form-m2-style
      .ant-form-item-control-input-content
      .ant-input-number-input {
      height: 50px !important;
      border-radius: 5px;
    }
  }
  @media (max-width: 600px) {
    .form-m2-style h3 {
      margin: 0px 0 16px;
    }
  }
`;

const CustomPopover = styled(Popover)``;
export const DiscardSubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledCollaps = styled(Collapse)`
  && {
    background: #fff;
  }
  & .ant-collapse-item {
    border: none;
    margin-bottom: 20px;
  }
  & .ant-collapse-item:last-child {
    border: none;
  }

  & .ant-collapse-header {
    border: 2px solid ${(props) => props.color};
    border-radius: 7px;
    color: ${(props) => props.color} !important;
    font-weight: 600;
  }
  & .ant-collapse-item:last-child .ant-collapse-header {
    border: 2px solid ${(props) => props.color};
    border-radius: 7px !important;
  }
  & .scd-1 {
    fill: ${(props) => props.color};
  }

  & .ant-collapse-item-active .ant-collapse-header {
    border: 2px solid ${(props) => props.color};
    border-radius: 7px;
    color: #fff !important;
    font-weight: 600;
    background: ${(props) => props.color};
    & .scd-1 {
      fill: #fff;
    }
  }
`;

const Observations = ({ setVisibility, setFrom }) => {
  const tempFormIds = {};
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [observationsForms, setObservationForms] = useState([]);
  console.log("observation form", observationsForms);
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  console.log("section in observation,", section);
  var tempMetaDataIds = [];
  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  console.log("selectedForm", selectedForms);
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const observation = useSelector((stat) => stat.examinationObservationReducer);
  console.log("observation is----------------", observation);
  const user = useSelector((state) => state.userReducer);
  const [dropDownParentIds, setDropDownParentIds] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);
  const [validationStatus, setValidationStatus] = useState({});
  const [validationCriterias, setValidationCriterias] = useState([]);
  const [observationId, setobservationId] = useState(0);
  const theme = useSelector((state) => state.themeReducer);
  console.log("theme is", theme);
  const { sectionId } = useParams();
  console.log("section id", sectionId);
  const ObservationButton = styled(Button)`
    float: right;
    /* margin-top: -70px; */
    width: 250px;
    border-radius: 20px !important;
    font-weight: 505;
    font-size: 15px;
    color: ${theme["@primary-color"]} !important;
    height: 40px;
  `;

  const onBack = () => {
    setVisibility("Chief");
    setFrom("Observations");
  };

  const getPreviousData = () => {
    let previousForms = [];
    setFormLoading(true);
    console.log("getprevous id", observation.id);
    getSectionData(observationId, patient.pId, patient.appointmentId)
      .then(({ data, status }) => {
        if (status == 200) {
          if (data.status == 1) {
            let initialValues = {};
            data.body.forEach((item) => {
              let tempForm = observationsForms.find(
                (form) => form.id == item.subSectionId
              );
              console.log("subsection id is", item.subSectionId);
              console.log(form.id, item.subSectionId);
              if (tempForm) {
                previousForms = [...previousForms, tempForm];
              }

              item.fields.forEach((item) => {
                initialValues[item.fieldId] = item.value;
              });
            });
            setFormLoading(false);
            setFormInitialValues(initialValues);
            setSelectedForms(previousForms);
          } else setFormLoading(false);
        }
      })
      .catch((err) => {
        setFormLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };
  const formSubmitHandler = (values) => {
    let data = Object.keys(formIds).map((key) => {
      const filtered = {};
      formIds[key].forEach((k) => {
        filtered[k] = values[k];
      });
      return { formId: key, fields: deserialize(filtered) };
    });

    submitFormData(data);
  };

  const onError = ({ values, errorFields, outOfDate }) => {
    let fieldIds = errorFields.map((item) => item.name[0]);
    var exapandable = [];

    forms.forEach((item, index) => {
      let temp = item.fields.find((field) => fieldIds.includes(field.name));
      if (temp) {
        exapandable = [...exapandable, index + 1];
      }
    });

    setExpanded(exapandable);
  };
  const getForms = () => {
    setLoading(true);
    console.log("observation------", observation.id);
    console.log("searchParam in observatio form is", searchParam);
    fetchMenuSections()
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) {
            data.body.filter((item) => {
              if (item.sectionType === "OBSERVATION") {
                setobservationId(item.id);
                getHealthRecordForms(item.id, searchParam)
                  .then(({ data, status }) => {
                    if (status === 200) {
                      if (data.status === 1) {
                        setObservationForms(data.body);
                      } else {
                        message.error(data.body);
                      }
                      setLoading(false);
                    }
                  })
                  .catch((err) => {
                    setLoading(false);
                    console.log(err);
                    Sentry.captureException(err);
                  });
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (observationsForms.length) {
      getPreviousData();
    }
  }, [observationsForms]);
  useEffect(() => {
    section.setSubSectionUrl("Observations", onBack);
    getForms();
  }, []);

  useEffect(() => {
    getForms();
  }, [searchParam]);

  useEffect(() => {
    if (forms.length && metaDataFieldIds.length) {
      let fieldMetaPromises = metaDataFieldIds.map((item) => {
        return fetchFormFieldMeta(
          item.dropDownType.dropDownType,
          item.sectionId
        );
      });
      let tempDropDownValues = [...dropDownValues];

      Promise.all(fieldMetaPromises)
        .then((responses) => {
          responses.forEach(({ data, status }, index) => {
            if (status == 200) {
              if (data.status == 1)
                tempDropDownValues = [...tempDropDownValues, ...data.body];
              //Injecting meta data for corresponding  formfields
              forms.forEach((form) => {
                let metaField = form.fields.find(
                  (field) => field.name === metaDataFieldIds[index].id
                );
                if (metaField) {
                  // setDropDownValues([...dropDownValues, ...data.body]);
                  metaField.meta = data.body.map((item) => item.value);
                } else console.log("Meta undefined");
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setMetaDataFieldIds([]);
          setDropDownValues(tempDropDownValues);
          setForms([...forms]);
        });
    }
  }, [metaDataFieldIds, forms]);

  const onValuesChange = (changed) => {
    Object.entries(changed).map(([key, value]) => {
      let changedField = dropDownParentIds.find(
        (item) => item.parentField == key
      );
      if (changedField) {
        let dropDownItem = dropDownValues.filter((item) => item.value == value);

        if (dropDownItem[dropDownItem.length - 1]) {
          changedField.sectionId = dropDownItem[dropDownItem.length - 1].id;
          setMetaDataFieldIds([changedField]);
        }
      }
    });
    Object.keys(changed).forEach((chngd) => {
      let validator = validationCriterias.find((item) => {
        return item.fieldId == chngd;
      });
      if (validator) {
        validationStatus[chngd] = softValidate(validator, changed[chngd]);
      }
    });

    setValidationStatus({ ...validationStatus });
  };

  const submitFormData = (dynamicFormData) => {
    setSubmitLoading(true);
    addHealthRecord(
      observationId,
      patient.pId,
      dynamicFormData,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            message.success("Health Record added");
            // form.resetFields();
          } else {
            message.error("Unable to add Observations!");
          }
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const onClear = () => {
    // setFormInitialValues({});
    form.resetFields();
    // setFormFetchLoading(true);
    getForms();
  };
  const deleteAddedObservation = (subSectionId) => {
    console.log("subsectionId", subSectionId);

    deleteObservation(patient.appointmentId, observationId, subSectionId)
      .then((res) => {
        if (res.status == 200) message.success("Deleted Observation");
        else message.error("Unable to delete observation");
      })
      .catch((err) => {
        message.error("Failed to delete the observation");
        console.log(err);
        Sentry.captureException(err);
      });
  };
  useEffect(() => {
    if (Object.keys(formInitialValues).length === 0) form.resetFields();
  }, [formInitialValues]);

  useEffect(() => {
    var tempMetaDataIds = [];
    var validationList = [];
    var tempParentIds = [];
    selectedForms.forEach((item) => {
      //getting formIds and corresponding field IDs for submit request Generation
      tempFormIds[item.id] = item.fields.map((field) => field.id);

      //finding fields which needs to call external API for fetching values eg:dropdown
      tempMetaDataIds = [
        ...tempMetaDataIds,
        ...item.fields.filter(
          (field) =>
            field.dropDownType !== null &&
            field.dropDownType !== undefined &&
            field.parentField == null &&
            Object.keys(field.dropDownType).length !== 0
        ),
      ];
      //collecting parentIds to call dropdown values api when change value of dependend dropdown, eg: fetching filtered medicine doses after selecting a medicine
      tempParentIds = [
        ...tempParentIds,
        ...item.fields.filter(
          (field) =>
            field.parentField !== null && field.parentField !== undefined
        ),
      ];
      //COLLECTING FIELDS HAVING SOFT VALIDATORS
      validationList = [
        ...validationList,
        ...item.fields
          .filter(
            (field) =>
              field.softValidator !== null &&
              field.softValidator !== undefined &&
              field.softValidator.length !== 0
          )
          .map((item) => {
            return {
              fieldId: item.id,
              validationCrieteria: item.softValidator.map((val) => ({
                type: val.validator,
                value: val.value,
              })),
            };
          }),
      ];
    });

    //converting forms into formBuilder readable format
    let expandable = [];
    let FormsTemp = selectedForms.map((item, index) => {
      expandable = [...expandable, index + 1];
      return {
        formName: item.formName,
        fields:
          user.userType === "PATIENT"
            ? generateFormFields(item.fields).map((item) => ({
                ...item,
                disabled: true,
              }))
            : generateFormFields(item.fields),
      };
    });
    setFormIds(tempFormIds);
    setValidationCriterias(validationList);
    setMetaDataFieldIds(tempMetaDataIds);
    setDropDownParentIds(tempParentIds);
    setForms(FormsTemp);
    setExpanded(expandable);
  }, [selectedForms]);
  const genExtra = (index) => (
    <DeleteOutlined
      style={{ marginRight: "10px" }}
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        setSelectedForms(
          selectedForms.filter((item, i) => {
            return i !== index;
          })
        );
        selectedForms.filter((item, i) => {
          if (i == index) deleteAddedObservation(item.id);
        });
        event.stopPropagation();
      }}
    />
  );

  return (
    <ObservationContainer>
      <Row gutter={[12, 24]}>
        {user.userType !== "PATIENT" && (
          <Col span={24}>
            <CustomPopover
              overlayClassName="pop-content"
              content={() => (
                <ObservationsPopOver
                  selected={selectedForms}
                  setSelected={setSelectedForms}
                  forms={observationsForms}
                  searchParam={searchParam}
                  setSearchParam={setSearchParam}
                />
              )}
              placement="bottomRight"
              trigger="click"
              // onVisibleChange={() => {
              //   setExpanded([]);
              // }}
            >
              <ObservationButton>Add Observations</ObservationButton>
            </CustomPopover>
          </Col>
        )}
        {loading || formLoading ? (
          <Col span={24}>
            <Spinner />
          </Col>
        ) : !forms.length > 0 ? (
          <NoRecords message="Add Observations here" />
        ) : (
          <Col span={24}>
            <Form
              className="form-m2-style"
              layout="vertical"
              onFinish={formSubmitHandler}
              form={form}
              onFinishFailed={onError}
              onValuesChange={onValuesChange}
              initialValues={formInitialValues}
              key={Object.keys(formInitialValues).length}
              scrollToFirstError={true}
            >
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <StyledCollaps
                    activeKey={expanded}
                    expandIconPosition="right"
                    bordered={false}
                    onChange={(val) => {
                      setExpanded(val);
                    }}
                    expandIcon={() => (
                      <Icon
                        component={CollapIcon}
                        style={{ fontSize: "25px" }}
                      />
                    )}
                    color={theme["@primary-color"]}
                  >
                    {forms.map((subForm, i) => (
                      <Panel
                        header={subForm.formName}
                        key={i + 1}
                        extra={genExtra(i)}
                      >
                        <Form.Item></Form.Item>
                        <FormBuilder
                          formConfig={subForm.fields}
                          formInstance={form}
                          softValidationFields={validationStatus}
                        />
                      </Panel>
                    ))}
                  </StyledCollaps>
                </Col>
                {user.userType !== "PATIENT" && (
                  <Col span={24}>
                    <Form.Item>
                      {forms.length !== 0 && (
                        <DiscardSubmitWrapper>
                          <Button
                            type="primary"
                            ghost
                            htmlType="submit"
                            className="btn-save"
                            onClick={onClear}
                          >
                            Discard
                          </Button>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={submitLoading}
                            className="btn-save"
                          >
                            Submit
                          </Button>
                        </DiscardSubmitWrapper>
                      )}
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
        )}
      </Row>
    </ObservationContainer>
  );
};

export default Observations;
