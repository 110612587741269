import {
    SET_ACTIVE_COUNT,
    SET_INQUEUE_COUNT
} from "../actions/activeCount";

export const activeCountReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_ACTIVE_COUNT:
            return { ...state, ...action.payload };
        case SET_INQUEUE_COUNT:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
