import React, { useState, useEffect } from "react";
import {
  SectionHeadContainer,
  SectionHeading,
  StyledDiv,
} from "../styledComponents";
import { BodyContainer } from "./StyledComponent";
import SectionManagementTable from "./LabManagementTable";
import { fieldsColumn } from "./TableConfig";
import * as Sentry from "@sentry/react";
import { Row, Col, Button, message, notification } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import {
  fetchLabTests,
  addLabTests,
  updateLabTests,
} from "../../../../apis/Admin";
import NewLabTypeModal from "./NewLabTypeModal";

import ConfirmationModal from "./ConfirmationModal";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { changeSectionOrder } from "../../../../apis/healthRecord";

const FieldManagement = ({
  setTab,
  selectedForm,
  selectedSection,
  setSelectedField,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.themeReducer);
  const [newModal, setNewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newFieldModal, setNewFieldModal] = useState(false);
  const [tests, setTests] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedType, setSelectedType] = useState({});
  const [changesOrderStatus, setChangeOrderStatus] = useState(false);

  const [edit, setEdit] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const newTypeHandler = () => {
    setNewModal(true);
  };

  const getTestType = () => {
    setLoading(true);
    var searchQuery = "type=LAB_TEST&parentId=" + selectedForm.id;

    fetchLabTests(searchQuery)
      .then(({ data, status }) => {
        if (data) {
          if (data.status === 1) {
            setTests(data.body);
          } else message.error(data.body);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTestType();
  }, []);
  const addType = (type) => {
    // api to add lab
    const payload = {
      name: type.type,
      sectionId: selectedForm.id,
      type: "LAB_TEST",
      fee: type.fee,
    };
    addLabTests(payload).then((data) => {
      // success mesage
      getTestType();
      if (data.data.status === 1) {
        notification.success({
          message: data.data.body,
        });
        getTestType();
      } else {
        notification.error({
          message: data.data.body,
        });
      }
    });
    setNewModal(false);
  };

  const handleOrderChangeClick = () => {
    if (changesOrderStatus) {
      let newOrder = tests.map((item, index) => ({
        formId: item.id,
        formOrder: index + 1,
      }));
      changeSectionOrder({ formOrderChanges: newOrder })
        .then(({ data, status }) => {
          if (data.status == 1) {
            notification.success({
              message: "New Order saved!",
            });
            setChangeOrderStatus(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    } else {
      setChangeOrderStatus(true);
    }
  };

  const update = (type) => {
    // api to add lab
    const payload = {
      name: type.type,
      id: selectedItem.id,
      type: "LAB_TEST",
      fee: type.fee,
    };

    updateLabTests(payload).then((data) => {
      // success mesage
      getTestType();
      if (data.data.status === 1) {
        notification.success({
          message: data.data.body,
        });
        getTestType();
      } else {
        notification.error({
          message: data.data.body,
        });
      }
    });
    setNewModal(false);
  };

  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row>
            <Col span={24}>
              <SectionHeading>
                <span style={{ color: "#999" }}>
                  Laboratory
                  <LeftCircleOutlined
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                  />
                  Laboratory Test Type
                  <LeftCircleOutlined
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                    onClick={() => {
                      setTab("lab_test_type");
                    }}
                  />
                  Lab Test
                </span>
              </SectionHeading>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24} style={{ padding: "0" }}>
        <BodyContainer>
          <Row gutter={[0, 18]}>
            <Col span={24} style={{ padding: "2.5px 0" }}>
              <Row justify="end">
                <Col>
                  <Row gutter={12} justify="end" align="right">
                    <Button
                      type="primary"
                      ghost
                      style={{ border: 0, boxShadow: "none" }}
                      size="large"
                      onClick={newTypeHandler}
                    >
                      Create New Test
                    </Button>
                  </Row>
                </Col>
                {/* <Col>
                  <Row gutter={12} justify="end" align="right">
                    <Button
                      type="primary"
                      ghost
                      style={{ border: 0, boxShadow: "none" }}
                      size="large"
                      onClick={handleOrderChangeClick}
                    >
                      {changesOrderStatus ? "Save Order" : "Change Order"}
                    </Button>
                  </Row>
                </Col> */}
              </Row>
            </Col>
            <Col span={24} style={{ paddingTop: "0" }}>
              <StyledDiv bgcolor={theme["@primary-color"]}>
                <div>&nbsp;&nbsp;Tests</div>
                <div>&nbsp;&nbsp;</div>
              </StyledDiv>
              <SectionManagementTable
                dataSource={tests}
                columns={fieldsColumn(
                  setTab,
                  setSelectedField,
                  setSelectedItem,
                  setSelectedType,
                  setConfirmationModal,
                  setNewFieldModal,
                  setDeleteModal,
                  setEdit,
                  setNewModal
                )}
                loading={loading}
                setDataSource={setTests}
                draggable={changesOrderStatus}
              />
            </Col>
          </Row>
        </BodyContainer>
      </Col>
      <NewLabTypeModal
        visible={newModal}
        setVisible={setNewModal}
        addType={addType}
        edit={edit}
        update={update}
        selectedSection={selectedItem}
        setEdit={setEdit}
        setSelectedSection={setSelectedItem}
        head={"Test"}
      />

      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        selectedItem={selectedItem}
        refetch={getTestType}
      />
    </Row>
  );
};
export default FieldManagement;
