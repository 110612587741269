import axiosInstance from "../config/axiosInstance";
const PHARMACY_PREFIX = "/pharmacy";

export const getAppointmentOrders = (appointmentId, pharmacyRequestId) => {
  return axiosInstance.get(`${PHARMACY_PREFIX}/appointment-orders?appointmentId=${appointmentId}&pharmacyRequestId=${pharmacyRequestId}`);
};

export const getAppointmentOrdersIPD = (ipdId, pharmacyRequestId) => {
  return axiosInstance.get(`${PHARMACY_PREFIX}/appointment-orders?ipdId=${ipdId}&pharmacyRequestId=${pharmacyRequestId}`);
};

export const calculatePayment = (data) => {
  return axiosInstance.post(`${PHARMACY_PREFIX}/calculate-payment`, data);
};

export const submitAppointment = (data) => {
  return axiosInstance.post(`${PHARMACY_PREFIX}/submit`, data);
};

export const submitAppointmentIPD = (data) => {
  return axiosInstance.post(`${PHARMACY_PREFIX}/submit`, data);
};
