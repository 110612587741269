import React, { useRef } from "react";
import { CustomTableHeader, StyledTable } from "./styledComponents";
import { ReactComponent as CollapIcon } from "../../../assets/icons/collapsIcon.svg";
import Icon, { PrinterOutlined, ShrinkOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import { useState } from "react";
import { useSelector } from "react-redux";

const CustomTable = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const theme = useSelector((state) => state.themeReducer);

  const handlePrint = useReactToPrint({
    content: () => props.componentRef.current,
  });
  return (
    <StyledTable
      {...props}
      size="small"
      locale={{ emptyText: "No Records" }}
      bordered={true}
      pagination={false}
      isCollapsed={isCollapsed}
      headBg={theme["@primary-color"]}
      title={() => (
        <CustomTableHeader isCollapsed={isCollapsed}>
          <div>{props.tableTitle}</div>
          <div>
            {props.isPrintable && (
              isCollapsed ? <PrinterOutlined
                style={{ fontSize: "20px", marginRight: "10px" }}
                onClick={handlePrint}
                rotate={270}
              /> : <PrinterOutlined
                style={{ fontSize: "20px", marginRight: "10px" }}
                onClick={handlePrint}
              />
            )}
            {isCollapsed ?
              <ArrowsAltOutlined
                style={{ fontSize: "25px" }}
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }} />
              :
              <ShrinkOutlined
                style={{ fontSize: "25px" }}
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }} />}
          </div>
        </CustomTableHeader>
      )}
    />
  );
};

export default CustomTable;
