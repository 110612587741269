import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Image } from 'antd';

import { useSelector } from 'react-redux';

import { getHospitalDetailWithHospitalId } from '../../apis/Public';
import md5 from 'md5';
import { useParams } from 'react-router-dom';
import SendOtpForm from './SendOtpForm';
import { FaHospital } from 'react-icons/fa';
import Hospital from '../../assets/icons/otp.svg';
import MeditagLogo from '../../assets/icons/meditaglogo.svg';

import VerifyOTPForm from './VerifyOtpForm';
import { MdHideSource } from 'react-icons/md';
import './otpform.css';

const PhoneNumberLoginForm = () => {
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);
  //to get id from url using useParams
  const { hId, id } = useParams();
  const [hospitalDetail, sethospitalDetail] = useState({});
  const [status, setStatus] = useState('otp-page');
  const hospitalId = md5(hId);
  const doctorid = id ? md5(id) : '';
  const [otpResponse, setOtpResponse] = useState({});
  const [phoneNumber, setPhoneNumber] = useState([]);

  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour >= 0 && currentHour < 12) {
      setGreeting('Good morning');
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting('Good afternoon');
    } else {
      setGreeting('Good evening');
    }
  }, []);
  useEffect(() => {
    getHospitalDetailWithHospitalId(hospitalId, doctorid)
      .then((res) => {
        if (res.status == 200) {
          sethospitalDetail(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="loginContainer">
      <div
        style={{
          backgroundColor: '#56AEF8',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={MeditagLogo} alt="hospital" height={'25px'} width={'109px'} />
      </div>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xl={7} lg={12} md={12} sm={18} xs={23}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div className="loginFormContainer">
                <div className="loginFormDiv">
                  {Object.keys(hospitalDetail).length !== 0 ? (
                    <>
                      <span>
                        <img
                          src={Hospital}
                          alt="hospital"
                          height={'200px'}
                          width={'330px'}
                          style={{ marginBottom: '25px' }}
                        />
                      </span>
                      <h1
                        style={{
                          marginTop: '-25px',
                          lineHeight: '1.2',
                          fontSize: '32px',
                          fontWeight: '700',
                        }}
                      >
                        {greeting}, {hospitalDetail?.hospital?.name ?? ''}{' '}
                      </h1>
                    </>
                  ) : (
                    'No hospital data'
                  )}
                </div>
                {/* <SendOtpForm hId={hId} /> */}
                {status === 'otp-page' ? (
                  <SendOtpForm
                    setStatus={setStatus}
                    hId={hId}
                    setOtpResponse={setOtpResponse}
                    setPhoneNumber={setPhoneNumber}
                  />
                ) : (
                  <VerifyOTPForm
                    setStatus={setStatus}
                    hId={hId}
                    setOtpResponse={setOtpResponse}
                    otpResponse={otpResponse}
                    phoneNumber={phoneNumber}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PhoneNumberLoginForm;
