import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Form,
  Button,
  message,
  Tag,
  Collapse,
  Divider,
  Container,
  Descriptions,
  Table,
} from 'antd';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { UserProfileHead } from '../../Appoinment/userProfileHead';
import {
  fetchConsulationReportItems,
  getHealthRecordForms,
  getPreviousRecords,
  getSectionData,
} from '../../../../apis/healthRecord';
import { ReactComponent as CollapIcon } from '../../../../assets/icons/collapsIcon.svg';
import { PrinterOutlined } from '@ant-design/icons';
import Icon, { CloseCircleOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../../../assets/icons/Edit.svg';
import { useSelector } from 'react-redux';
import CollapsBody from '../ConsultationReport/CollapsBody';
import {
  generateFormFields,
  deserialize,
} from '../../../utils/FormBuilder/valuesSerialisation';
import FormBuilder from '../../../utils/FormBuilder';

const UserProfileContainer = styled(Col)`
  .user-profile-head-mobile {
    display: none;
  }
  @media (max-width: 767px) {
    .user-profile-head-mobile {
      display: flex;
      flex-direction: column;
    }
    .user-profile-head-desktop {
      display: none;
    }
  }
`;

const ExaminationsContainer = styled.div`
  background: #fff;
  padding: 40px 20px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    padding-top: 15px !important;
  }
`;
const { Panel } = Collapse;

const ExaminationsPrintContainer = styled.div`
  background: #fff;
  padding: 10px 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    padding-top: 15px !important;
  }
`;

const StyledCollaps = styled(Collapse)`
  && {
    background: #fff;
  }
  & .ant-collapse-item {
    border: none;
    margin-bottom: 20px;
  }
  & .ant-collapse-item:last-child {
    border: none;
  }

  & .ant-collapse-header {
    border: 2px solid ${(props) => props.color};
    border-radius: 7px !important;
    color: ${(props) => props.color} !important;
    font-weight: 600;
  }
  & .ant-collapse-item:last-child .ant-collapse-header {
    border: 2px solid ${(props) => props.color};
    border-radius: 7px;
  }
  & .scd-1 {
    fill: ${(props) => props.color};
  }

  & .ant-collapse-item-active .ant-collapse-header {
    border: 2px solid ${(props) => props.color};
    border-radius: 7px !important;
    color: #fff !important;
    font-weight: 600;
    background: ${(props) => props.color};
    & .scd-1 {
      fill: #fff;
    }
  }
`;

const StyledRow = styled(Row)`
  font-weight: 600;
  color: rgb(93, 93, 93);
`;

const StyledP = styled.p`
  display: contents;
  font-size: 12px;
  font-weight: normal;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;
const PreviousConsultationRecords = ({ setVisibility }) => {
  const { pId, pName } = useParams();
  const location = useLocation();
  const [form] = Form.useForm();
  const theme = useSelector((state) => state.themeReducer);
  const user = useSelector((state) => state.userReducer);
  const section = useSelector((state) => state.sectionReducer);
  console.log('section in prev', section);
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [consultationReportSections, setConsultationReportSections] = useState(
    []
  );
  const [validationStatus, setValidationStatus] = useState({});
  const [validationCriterias, setValidationCriterias] = useState([]);
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [dropDownParentIds, setDropDownParentIds] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState({});

  useEffect(() => {
    getForms();
    getSectiondetails();
    getPreviousData();
  }, []);
  // fetch formFields
  const getForms = useCallback(() => {
    var isData = false;
    getHealthRecordForms(89, pId)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            const tempFormIds = {};
            var tempMetaDataIds = [];
            var validationList = [];
            var tempParentIds = [];
            isData = data.body[0].date ? true : false;
            (!isData ? data.body : data.body[0].dynamicHealthRecord).forEach(
              (item) => {
                //getting formIds and corresponding field IDs for submit request Generation
                tempFormIds[item.id] = (isData ? item.form.fields : item.fields)
                  .filter((field) => field.fieldType !== 'TAG_SELECT')
                  .map((field) => field.id);

                //finding fields which needs to call external API for fetching values eg:dropdown
                tempMetaDataIds = [
                  ...tempMetaDataIds,
                  ...item.fields.filter(
                    (field) =>
                      field.dropDownType !== null &&
                      field.dropDownType !== undefined &&
                      Object.keys(field.dropDownType).length !== 0
                  ),
                ];
                //collecting parentIds to call dropdown values api when change value of dependend dropdown, eg: fetching filtered medicine doses after selecting a medicine
                tempParentIds = [
                  ...tempParentIds,
                  ...item.fields.filter(
                    (field) =>
                      field.parentField !== null &&
                      field.parentField !== undefined
                  ),
                ]; //COLLECTING FIELDS HAVING SOFT VALIDATORS
                validationList = [
                  ...validationList,
                  ...item.fields
                    .filter(
                      (field) =>
                        field.softValidator !== null &&
                        field.softValidator !== undefined &&
                        field.softValidator.length !== 0
                    )
                    .map((item) => {
                      return {
                        fieldId: item.id,
                        validationCrieteria: item.softValidator.map((val) => ({
                          type: val.validator,
                          value: val.value,
                        })),
                      };
                    }),
                ];
              }
            );

            //converting forms into formBuilder readable format
            let FormsTemp = (
              !isData ? data.body : data.body[0].dynamicHealthRecord
            ).map((item) => {
              return {
                formName: isData ? item.form.formName : item.formName,
                fields: generateFormFields(item.fields).map((field) => ({
                  ...field,
                  disabled: user.userType === 'DOCTOR' ? false : true,
                })),
              };
            });
            setFormIds(tempFormIds);
            setMetaDataFieldIds(tempMetaDataIds);
            setForms(FormsTemp);
            setValidationCriterias(validationList);
            setDropDownParentIds(tempParentIds);
            setFormFetchLoading(false);
          } else {
            message.error(data.body);
            setFormFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        setFormFetchLoading(false);
        console.log(err);
      });
  }, []);

  const getSectiondetails = () => {
    fetchConsulationReportItems(89)
      .then(({ data, status }) => {
        console.log('data is', data);
        if (status == 200) {
          if (data.status == 1) {
            setConsultationReportSections(data.body);
          }
        }
      })
      .catch((err) => {
        console.log('error here in 141', err);
      });
  };

  const getPreviousData = () => {
    getSectionData(
      89,
      user.useType == 'PATIENT' ? user.id : pId,
      location?.state?.id ? location.state.id : 198
    )
      .then(({ data, status }) => {
        console.log(location);
        console.log('previous', data);
        if (status == 200) {
          if (data.status == 1) {
            console.log(data.body);
            let initialValues = {};
            data.body.forEach((item) => {
              item.fields.forEach((item) => {
                initialValues[item.fieldId] = item.value;
              });
            });
            setFormInitialValues(initialValues);
          }
        }
      })
      .catch(console.err);
  };

  const renderTypes = {
    'Vital Information': 'report',
    Laboratory: 'laboratory',
    Radiology: 'radiology',
    Examination: 'report',
    'Care Plan': 'report',
    Observation: 'observation',
    Prescriptions: 'prescriptions',
  };
  return (
    <Col span={24}>
      <StyledRow justify="center">
        <UserProfileHead
          // user={user}
          patientId={pId}
          patientName={pName}
        />
      </StyledRow>

      <ExaminationsContainer>
        <Row gutter={[24, 12]}>
          <Col span={24}>
            <StyledCollaps
              expandIconPosition="right"
              bordered={false}
              expandIcon={() => (
                <Icon component={CollapIcon} style={{ fontSize: '25px' }} />
              )}
              color={theme['@primary-color']}
            >
              {consultationReportSections.map((item) => (
                <Panel header={item.formName} key={item.id}>
                  <Row justify="end">
                    {user.userType === 'DOCTOR' ? (
                      location?.state?.id ? (
                        <></>
                      ) : (
                        <Col>
                          {item.formName !== 'Observation' && (
                            <Icon
                              component={EditIcon}
                              style={{ fontSize: '25px', paddingTop: '10px' }}
                              //   onClick={() => {
                              //     section.redirect(item.id);
                              //   }}
                            />
                          )}
                        </Col>
                      )
                    ) : (
                      <></>
                    )}
                    <Col span={24}>
                      <CollapsBody
                        sectionName={item.formName}
                        sectionId={item.id}
                        renderType={
                          renderTypes[item.formName]
                            ? renderTypes[item.formName]
                            : 'table'
                        }
                      />
                    </Col>
                  </Row>
                </Panel>
              ))}
            </StyledCollaps>
          </Col>
        </Row>
      </ExaminationsContainer>
    </Col>
  );
};

export default PreviousConsultationRecords;
