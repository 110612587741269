import { Button, Col, Row, Spin, message } from 'antd';
import { HealthRecordMenu, HealthRecordMenuItem } from './styledComponents';
import Icon, {
  LeftCircleOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { addSection, setObservationInfo } from '../../../redux/actions/section';
import { getAppointmentDetails, moveToQueue } from '../../../apis/Appointment';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import HasPrivilege from '../../utils/privilegeHandlers/HasPrivilege.jsx';
import HealthRecordContentRouter from './HealthRecordContentRouter';
import { ReactComponent as LeftArrow } from '../../../assets/icons/LeftArrow.svg';
import Spinner from '../../utils/Spinner';
import { UserProfileHead } from '../Appoinment/userProfileHead';
import { addPatient } from '../../../redux/actions/patient';
import { fetchMenuSections } from '../../../apis/healthRecord';
import { getOneByID } from '../../../apis/patients';
import { hasPrivilege } from '../../utils/privilegeHandlers/hasPrivilege';
import moment from 'moment';
import { sidebarIcons } from '../../../constants/sideBarIcons';
import styled from 'styled-components';
import { useQuery } from '../../utils/UseQuery';

const StyledIcon = styled(Icon)`
  && {
    font-size: 30px;
  }
  ${(props) =>
    props.isActive &&
    `.cls-1 {
      fill: ${(props) => props.color}; 
  }`}
`;
export const SectionContent = styled(Col)`
  min-height: calc(100vh - 200px);
  /* background: #fff; */
  &.scroll_right_panel h3 {
    margin-bottom: 20px;
  }
  &.scroll_right_panel .ant-spin-container .ant-table .ant-table-title {
    font-size: 17px;
    padding: 12px 15px;
  }
  &.scroll_right_panel .ant-spin-container .ant-table thead tr th,
  &.scroll_right_panel .ant-spin-container .ant-table tbody tr td {
    padding: 10px 15px;
    font-size: 17px;
  }
  &.scroll_right_panel .ant-spin-container .ant-table tbody tr td .ant-col {
    align-self: center;
  }

  &.scroll_right_panel .ant-spin-container .ant-table .ant-btn {
    height: auto;
    padding: 8px 12px;
    font-weight: 600;
  }
  @media (max-width: 1199px) {
    &.scroll_right_panel .ant-spin-container {
      overflow: auto;
      overflow-x: scroll;
    }
  }

  &.scroll_right_panel .d_flex_col {
    display: flex;
    justify-content: flex-end;
  }

  &.scroll_right_panel .sidebar_menu_tag {
    display: none;
    padding: 10px 10px 10px 10px;
    height: 40px;
    cursor: pointer;
    background-color: #fff;
    margin-left: 15px;
  }
  &.scroll_right_panel .sidebar_menu_tag .menu_bar {
    width: 24px;
    height: 20px;
    position: relative;
  }
  &.scroll_right_panel .sidebar_menu_tag .menu_bar span,
  &.scroll_right_panel .sidebar_menu_tag .menu_bar span:after,
  &.scroll_right_panel .sidebar_menu_tag .menu_bar span:before {
    width: 24px;
    border-radius: 2px;
    height: 2px;
    background-color: #a7a7a7;
    display: block;
  }
  &.scroll_right_panel .sidebar_menu_tag .menu_bar span {
    margin: 9px 0;
  }
  &.scroll_right_panel .sidebar_menu_tag .menu_bar span:after,
  &.scroll_right_panel .sidebar_menu_tag .menu_bar span:before {
    content: '';
    position: absolute;
  }
  &.scroll_right_panel .sidebar_menu_tag .menu_bar span:before {
    top: 0px;
  }
  &.scroll_right_panel .sidebar_menu_tag .menu_bar span:after {
    bottom: 0px;
  }
  @media (max-width: 1199px) {
    &.scroll_right_panel {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    &.scroll_right_panel .sidebar_menu_tag {
      display: flex;
    }
  }
  @media (max-width: 767px) {
    &.scroll_right_panel .d_flex_col {
      margin: 10px 0 5px;
      width: 100%;
    }
    &.scroll_right_panel
      .ant-spin-container
      .ant-table
      .ant-table-title
      .anticon-printer {
      display: none;
    }
  }
`;

export const StyledHead = styled.h1`
  font-size: 24px;
  color: #5d5d5d;
  font-weight: 600;
  @media (max-width: 767px) {
    font-size: 18px;
  }
  @media (max-width: 640px) {
    font-size: 16px;
  }
`;
export const StyledRouterContainer = styled.div`
  &.form-m2-style h3 {
    margin: 15px 0 5px;
    font-size: 20px;
  }
  &.form-m2-style .ant-form-item-label label {
    font-size: 16px;
    color: #818181;
  }
  &.form-m2-style .ant-form-item-label label:before {
    display: none;
  }
  &.form-m2-style .ant-form-item-label label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff0003;
    font-size: 18px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    font-weight: 700;
  }
  &.form-m2-style .ant-form-item-control-input-content .ant-input,
  &.form-m2-style .ant-form-item-control-input-content .ant-select-selector,
  &.form-m2-style .ant-form-item-control-input-content .ant-picker,
  &.form-m2-style .custom-tag-container {
    min-height: 60px;
    border: 1px solid #d6d6d6;
    border-radius: 5px !important;
    font-size: 18px;
    color: #333333;
  }
  &.form-m2-style .ant-form-item-control-input-content .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  &.appointment_form_style1.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selector {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select
    + .ant-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select:hover
    .ant-select-selector {
    border: 1px solid #d6d6d6;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-search-input,
  &.form-m2-style .ant-form-item-control-input-content .ant-input-number-input {
    height: 60px !important;
  }
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-placeholder,
  &.form-m2-style
    .ant-form-item-control-input-content
    .ant-select-selection-item {
    line-height: 56px !important;
  }
  &.form-m2-style .ant-form-item-explain div {
    font-size: 12px;
    /* text-transform: capitalize; */
    font-family: monospace;
    letter-spacing: -0.03em;
    color: #e06e6e;
  }
  &.form-m2-style
    .ant-form-item-has-error
    .ant-select:not(.ant-select-borderless)
    .ant-select-selector {
    border-color: #d6d6d6 !important;
  }
  &.form-m2-style .ant-btn-background-ghost.ant-btn-danger {
    padding: 3px 10px;
    height: auto;
    font-weight: 600;
  }
  &.form-m2-style .add-btn {
    padding: 9px 10px;
    height: auto;
    font-weight: 600;
    margin: 12px 0 0;
  }
  &.form-check-tag {
    margin: 5px 0 25px;
  }
  &.form-check-tag span {
    font-size: 18px;
  }
  &.form-check-tag .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
  .btn-save,
  .btn-cancel {
    min-width: 120px;
    font-size: 18px;
    height: 50px;
    border: 2px solid #26b1ff;
    padding: 0px 10px;
    font-weight: 500;
    border-radius: 6px;
  }
  &.form-m2-style .tag-select .ant-select-selector .ant-select-selection-item {
    line-height: 26px !important;
  }
  @media (max-width: 1100px) {
    &.form-m2-style .ant-form-item-label label {
      font-size: 14px;
    }
    &.form-m2-style .ant-form-item-control-input-content .ant-input,
    &.form-m2-style .ant-form-item-control-input-content .ant-select-selector,
    &.form-m2-style .ant-form-item-control-input-content .ant-picker,
    &.form-m2-style .ant-form-item-control-input-content .ant-input-number,
    &.form-m2-style .custom-tag-container {
      min-height: 50px;
      font-size: 15px;
    }
    &.form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-search-input {
      height: 50px !important;
    }
    &.form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-placeholder,
    &.form-m2-style
      .ant-form-item-control-input-content
      .ant-select-selection-item {
      line-height: 46px !important;
    }
    .form-check-tag span {
      font-size: 15px;
    }
    &.form-m2-style h3 {
      font-size: 18px;
    }
    h2.h2-base-600 {
      font-size: 20px;
    }
    &.form-m2-style .forminput_timepick {
      height: 50px;
      border-radius: 5px;
    }
    .available_time_field .ant-form-item-control-input,
    &.form-m2-style
      .ant-form-item-control-input-content
      .ant-input-number-input {
      height: 50px !important;
      border-radius: 5px;
    }
  }
  @media (max-width: 600px) {
    &.form-m2-style h3 {
      margin: 0 0 10px;
    }
  }
`;

export const SidebarCol = styled(Col)`
  &.fixed_sidebar_panel {
    margin-top: 85px;
    padding-right: 20px;
  }
  &.fixed_sidebar_panel .sidebar_menu_tag {
    padding: 0px 0px !important;
  }
  @media (max-width: 1199px) {
    &.fixed_sidebar_panel {
      visibility: hidden;
      opacity: 0;
      margin-top: 0px;
      position: fixed;
      width: 300px;
      z-index: 15;
      max-width: 300px;
      flex: 0 0 300px;
      top: 70px;
      right: 0px;
      padding: 0px 0px !important;
      height: calc(100vh - 62px);
      background-color: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
      -webkit-transform: translateX(350px);
      -moz-transform: translateX(350px);
      -ms-transform: translateX(350px);
      transform: translateX(350px);
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      transition: all 0.3s linear;
    }
    &.fixed_sidebar_panel.mob-menu-open {
      visibility: visible;
      opacity: 1;
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
    }
    ::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #fcfcfc;
      border-radius: 5px;
    }

    &.fixed_sidebar_panel .sidebar_menu_tag {
      display: block;
      padding: 3px 5px !important;
      height: 34px;
      cursor: pointer;
      position: absolute;
      left: 13px;
      top: 8px;
      background-color: #fff;
    }
    &.fixed_sidebar_panel .sidebar_menu_tag .menu_bar {
      width: 24px;
      height: 20px;
      position: relative;
    }
    &.fixed_sidebar_panel .sidebar_menu_tag .menu_bar:after,
    &.fixed_sidebar_panel .sidebar_menu_tag .menu_bar:before {
      width: 24px;
      border-radius: 2px;
      height: 2px;
      background-color: #a7a7a7;
      display: block;
    }
    &.fixed_sidebar_panel .sidebar_menu_tag .menu_bar:after,
    &.fixed_sidebar_panel .sidebar_menu_tag .menu_bar:before {
      content: '';
      position: absolute;
    }
    &.fixed_sidebar_panel .sidebar_menu_tag .menu_bar:before {
      top: 13px;
      transform: rotate(45deg);
    }
    &.fixed_sidebar_panel .sidebar_menu_tag .menu_bar:after {
      top: 13px;
      transform: rotate(-225deg);
    }
  }

  @media (max-width: 767px) {
    &.fixed_sidebar_panel {
      top: 60px;
    }
  }

  @media (max-width: 359px) {
    &.fixed_sidebar_panel {
      width: 290px;
    }
  }
`;

const StyledRow = styled(Row)`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;

const tableTypes = {
  'Medical History': 'MEDICAL_HISTORY',
  'Vitals Information': 'VITAL_INFORMATION',
  Allergy: 'ALLERGY',
  'Patient Details': 'PATIENT_DETAILS',
  Laboratory: 'LABORATORY',
  Radiology: 'RADIOLOGY',
  Examination: 'EXAMINATION',
};

const compare = (a, b) => {
  let comparison = 0;
  if (a.formOder > b.formOder) {
    comparison = 1;
  } else if (a.formOder < b.formOder) {
    comparison = -1;
  }
  return comparison;
};

export const StyledButton = styled(Button)`
  && {
    background: ${(props) => (props.type == 'history' ? props.color : 'white')};
    color: ${(props) => (props.type == 'history' ? 'white' : props.color)};
    border: none;
    border-radius: 20px;
    font-weight: 505;
    font-size: 15px;
  }
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: ${(props) => (props.type == 'history' ? props.color : 'white')};
    color: ${(props) => (props.type == 'history' ? 'white' : props.color)};
    border: ${(props) =>
      props.type == 'history' ? '' : `1px solid${props.color}`};
  }
  height: 40px;
  width: 240px;
`;

const HealthReacords = () => {
  const history = useHistory();
  const location = useLocation();
  const { pId, pName, appointmentId, sectionId } = useParams();
  const { url } = useRouteMatch();
  let query = useQuery();
  const sidemenu = query.get('sidemenu');

  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const user = useSelector((state) => state.userReducer);

  const bookAppointmentHandler = () => {
    history.push(`/appointment/${user.id}/${user.fullName}`);
  };
  const [subSectionName, setSubsectionName] = useState();

  const { url: healthRecordBaseUrl } = useRouteMatch();
  console.log('use Route match', useRouteMatch);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedSection, setSelectedSection] = useState({});
  const [redirectionId, setRedirectionId] = useState();
  const [selectedKey, setSelectedKey] = useState(['0']);
  const [loading, setLoading] = useState(false);
  const [
    consultationHistoryButtonVisible,
    setConsultationHistoryButtonVisible,
  ] = useState(true);

  const [subsectionCallBack, setSubsectionCallBack] = useState();
  const theme = useSelector((state) => state.themeReducer);

  const [mobileMenuVisible, setmobileMenuVisible] = useState(false);

  const handlemobileMenu = (value) => {
    setmobileMenuVisible(!mobileMenuVisible);
  };
  const getMenuItem = () => {
    console.log(history);
    console.log(history.action);
    setLoading(true);
    fetchMenuSections()
      .then(({ data, status }) => {
        if (status === 200) {
          console.log('data in menu', data);
          if (data.status === 1) {
            var sideBarMenuItems = [
              ...data.body.sort(compare).filter((item) => {
                if (item.sectionType === 'OBSERVATION')
                  dispatch(setObservationInfo(item));

                return item.sectionType !== 'OBSERVATION';
              }),
              {
                sectionId: 100,
                sectionName: 'Consultation History',
                formName: 'Consultation History',
                id: 100,
                baseUrl: healthRecordBaseUrl,
                redirect: () => {
                  setRedirectionId(this.sectionId);
                },
                setSubSectionUrl: (name, callback) => {
                  setSubsectionName(name);
                  setSubsectionCallBack(() => callback);
                },
              },
            ];
            if (history.location?.state?.preApproval)
              sideBarMenuItems = sideBarMenuItems.filter(
                (item) => item.formName != 'Consultation Report'
              );
            if (history.location?.state?.hospital)
              sideBarMenuItems = sideBarMenuItems.filter(
                (item) => item.formName != 'Consultation Report'
              );
            if (history.location?.state?.visitType)
              sideBarMenuItems = sideBarMenuItems.filter(
                (item) => item.formName != 'Consultation Report'
              );
            if (history.location?.state?.pharmacy)
              sideBarMenuItems = sideBarMenuItems.filter(
                (item) => item.formName != 'Consultation Report'
              );
            setMenuItems(sideBarMenuItems);
            let paths = location.pathname.split('/');
            console.log(history);
            if (
              paths[paths.length - 1] &&
              paths[paths.length - 1] === 'dashboard'
            ) {
            } else {
              let activeIndex = sideBarMenuItems.findIndex((item) => {
                return paths[paths.length - 1] == item.formName;
              });
              if (activeIndex > -1) {
                setSelectedSection(sideBarMenuItems[activeIndex]);
                dispatch(
                  addSection({
                    sectionId: sideBarMenuItems[activeIndex].id,
                    sectionName: sideBarMenuItems[activeIndex].formName,
                    dableDataTypes:
                      tableTypes[sideBarMenuItems[activeIndex].formName],
                    baseUrl: healthRecordBaseUrl,
                    redirect: (id) => {
                      setRedirectionId(id);
                    },
                    setSubSectionUrl: (name, callback) => {
                      setSubsectionName(name);
                      setSubsectionCallBack(() => callback);
                    },
                  })
                );
                setSelectedKey([activeIndex.toString()]);
              } else {
                setSelectedSection(data.body[0]);
                dispatch(
                  addSection({
                    sectionId: data.body[0].id,
                    sectionName: data.body[0].formName,
                    dableDataTypes: tableTypes[data.body[0].formName],
                    baseUrl: healthRecordBaseUrl,
                    redirect: (id) => {
                      setRedirectionId(id);
                    },
                    setSubSectionUrl: (name, callback) => {
                      setSubsectionName(name);
                      setSubsectionCallBack(() => callback);
                    },
                  })
                );
                setSelectedKey(['0']);
                history.push({
                  pathname: `${healthRecordBaseUrl}/${data.body[0].id}/${data.body[0].formName}`,
                  state: history.location.state,
                });
              }
            }
          } else message.error(data.body);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  const menuChangeHandler = ({ key, push }) => {
    if (key !== menuItems.length - 1) setConsultationHistoryButtonVisible(true);
    else setConsultationHistoryButtonVisible(false);
    setSelectedSection(menuItems[key]);

    setSubsectionName(undefined);
    dispatch(
      addSection({
        sectionId: menuItems[key].id,
        sectionName: menuItems[key].formName,
        dableDataTypes: tableTypes[menuItems[key].formName],
        baseUrl: healthRecordBaseUrl,
        redirect: (id) => {
          setRedirectionId(id);
        },
        setSubSectionUrl: (name, callback) => {
          setSubsectionName(name);
          setSubsectionCallBack(() => callback);
        },
      })
    );
    if (push !== true) {
      history.push({
        pathname: `${healthRecordBaseUrl}/${menuItems[key].id}/${menuItems[key].formName}`,
        state: { previous: window.location.href },
      });
    }
    handlemobileMenu(false);
    setSelectedKey([key + '']);
  };

  const redirectToConsultationHistory = () => {
    setConsultationHistoryButtonVisible(false);
    menuChangeHandler({ key: menuItems.length - 1 });
  };

  const onClickMoveToQueue = () => {
    let searchQuery = `appointmentId=${appointmentId}&timeZone=${moment.tz.guess()}`;
    moveToQueue(searchQuery)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 1) {
            window.localStorage.setItem('tabKey', true);
            history.push('/dashboard');
            message.success(res.data.body);
          } else message.error(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    if (window?.location?.pathname?.split('/')?.length < 6)
      window?.event?.type == 'popstate' && history.goBack();
    var key =
      window?.location?.pathname?.split('/')[
        window?.location?.pathname?.split('/').length - 2
      ];
    var temp = 0;
    var index;
    menuItems.map((i) => {
      if (i.id == key) {
        index = temp;
      } else temp++;
    });
    if (menuItems.length > 0)
      if (index || index == 0) menuChangeHandler({ key: index, push: true });
  }, [window?.location?.pathname]);

  useEffect(() => {
    if (section.isConsultationReport == true) {
      dispatch(
        addSection({
          sectionId: section.sectionId,
          sectionName: section.sectionName,
          baseUrl: section.baseUrl,
          redirect: (id) => {
            setRedirectionId(id);
          },
          setSubSectionUrl: (name, callback) => {
            setSubsectionName(name);
            setSubsectionCallBack(() => callback);
          },
        })
      );
    }
  }, [section]);

  useEffect(() => {
    if (user.userType === 'PATIENT') {
      console.log('appointment id is 1', appointmentId);
      dispatch(
        addPatient({
          pId: pId,
          pName: pName,
          appointmentId: appointmentId,
        })
      );
      getAppointmentDetails(appointmentId)
        .then((res) => {
          if (res.data.status === 1) {
            let record = res.data.body;
            let item = {
              appointmentId: record.id,
              date: record.date,
              hospital: 'Emirates Hospital',
              doctor: record.doctor.firstName + ' ' + record.doctor.lastName,
              department: record.department.departmentName,
            };
            sessionStorage.setItem('appointmentDetails', JSON.stringify(item));
          } else {
            message.error('Could not fetch appointment details, try again');
          }
        })
        .catch((err) => {
          message.error('Could not fetch appointment details, try again');
        });
    } else if (user.userType === 'INSURANCE') {
      getOneByID(pId)
        .then((res) => {
          if (res.data.status === 1) {
            const data = res.data.body;
            console.log('appointment id is 2', appointmentId);

            dispatch(
              addPatient({
                pId: data.id,
                appointmentId: appointmentId,
                ...data,
              })
            );
          } else {
            message.error("Couldn't get profile, try again");
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          message.error("Couldn't get profile, try again");
        });
    } else {
      getOneByID(pId)
        .then((res) => {
          if (res.data.status === 1) {
            const data = res.data.body;
            console.log('appointment id is 3', appointmentId);

            dispatch(
              addPatient({
                pId: data.id,
                pName: data.firstName + ' ' + data.lastName,
                appointmentId: appointmentId,
                profilePic: data.profilePic,
                dob: data.dob,
                gender: data.gender,
              })
            );
          } else {
            message.error("Couldn't get profile, try again");
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          message.error("Couldn't get profile, try again");
        });
    }

    getMenuItem();
  }, [user]);

  useEffect(() => {
    if (redirectionId) {
      let temp = menuItems.findIndex((item) => item.id == redirectionId);
      setRedirectionId(undefined);
      history.push(
        `${healthRecordBaseUrl}/${menuItems[temp].id}/${menuItems[temp].formName}`
      );
      setSelectedSection(menuItems[temp]);
      setSelectedKey([temp + '']);
      dispatch(
        addSection({
          sectionId: menuItems[temp].id,
          sectionName: menuItems[temp].formName,
          dableDataTypes: tableTypes[menuItems[temp].formName],
          baseUrl: healthRecordBaseUrl,
          redirect: (id) => {
            setRedirectionId(id);
          },
          setSubSectionUrl: (name, callback) => {
            setSubsectionName(name);
            setSubsectionCallBack(() => callback);
          },
        })
      );
    }
  }, [redirectionId]);
  return loading ? (
    <Spinner height="calc(100vh - 200px)" />
  ) : (
    <Row>
      {/* <Col span={5}></Col>
      <Col span={19} className="title_right_panel">
        <Row justify="space-between">
          
        </Row>
      </Col> */}

      <SidebarCol
        span={5}
        className={
          mobileMenuVisible
            ? 'fixed_sidebar_panel mob-menu-open' //case when the menu is triggered
            : 'fixed_sidebar_panel' // case when the menu is closed
        }
      >
        <Col
          className="sidebar_menu_tag"
          onClick={() => {
            handlemobileMenu(false);
          }}
        >
          <div className="menu_bar">
            <span></span>
          </div>
        </Col>
        <HealthRecordMenu
          className="sidepanel_menu_list"
          mode="vertical"
          selectedKeys={selectedKey}
          onSelect={menuChangeHandler}
        >
          {menuItems
            .filter((item, i) => i !== menuItems.length - 1)
            .map((item, i) => (
              <HealthRecordMenuItem
                key={i}
                icon={
                  sidebarIcons[item.icon] ? (
                    <StyledIcon
                      color={theme['@primary-color']}
                      component={sidebarIcons[item.icon]}
                      isActive={item.id === selectedSection.id}
                    />
                  ) : (
                    <ReconciliationOutlined style={{ fontSize: '25px' }} />
                  )
                }
              >
                {item.formName}
              </HealthRecordMenuItem>
            ))}
        </HealthRecordMenu>
      </SidebarCol>

      <SectionContent span={19} className="scroll_right_panel">
        <Col style={{ padding: '0px 0px 20px', marginTop: '15px' }}>
          <Row className="panel_row">
            <Col>
              <StyledHead>
                {/* {consultationHistoryButtonVisible == true && ( */}
                <LeftCircleOutlined
                  onClick={() => {
                    try {
                      if (history.location.state?.previous)
                        if (
                          history.location.state?.previous
                            .split('/')
                            .slice(-1)[0] == 'Examination'
                        ) {
                          console.log(history);
                          let paths =
                            history.location.state.previous.split('/');
                          console.log(history);
                          let activeIndex = menuItems.findIndex((item) => {
                            return paths[paths.length - 1] == item.formName;
                          });
                          if (activeIndex > -1) {
                            setSelectedSection(menuItems[activeIndex]);
                            dispatch(
                              addSection({
                                sectionId: menuItems[activeIndex].id,
                                sectionName: menuItems[activeIndex].formName,
                                dableDataTypes:
                                  tableTypes[menuItems[activeIndex].formName],
                                baseUrl: healthRecordBaseUrl,
                                redirect: (id) => {
                                  setRedirectionId(id);
                                },
                                setSubSectionUrl: (name, callback) => {
                                  setSubsectionName(name);
                                  setSubsectionCallBack(() => callback);
                                },
                              })
                            );
                            setSelectedKey([activeIndex.toString()]);
                          }
                          history.push(
                            `${healthRecordBaseUrl}/${history.location.state?.previous
                              .split('/')
                              .slice(-2)
                              .join('/')}`
                          );
                        } else {
                          console.log(history);
                          history.goBack();
                        }
                      else {
                        console.log(history);
                        history.goBack();
                      }
                    } catch (err) {
                      console.log(history);
                      history.goBack();
                    }
                  }}
                />
                {/*)} */}
                {'  '}
                {selectedSection.formName}
              </StyledHead>
            </Col>

            <Col>
              {subSectionName && (
                <StyledHead>
                  <LeftCircleOutlined
                    style={{ marginLeft: '20px' }}
                    onClick={() => {
                      setSubsectionName(undefined);
                      subsectionCallBack();
                    }}
                  />
                  {'  '}
                  {subSectionName}
                </StyledHead>
              )}
            </Col>
            <div className="d_flex_col" style={{ marginLeft: 'auto' }}>
              {/* <HasPrivilege role="PATIENT">
                <Col>
                  <Button
                    className="btn-book"
                    type="primary"
                    size="large"
                    onClick={bookAppointmentHandler}
                  >
                    Book Visit
                  </Button>
                </Col>
              </HasPrivilege> */}
              {user.userType == 'DOCTOR' &&
                consultationHistoryButtonVisible == true && (
                  <Row style={{ gap: '20px' }}>
                    <StyledButton
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      color={theme['@primary-color']}
                      onClick={onClickMoveToQueue}
                    >
                      <Icon
                        style={{ fontSize: '20px' }}
                        component={LeftArrow}
                      />
                      Move To Queue
                    </StyledButton>
                    <StyledButton
                      color={theme['@primary-color']}
                      type={'history'}
                      onClick={redirectToConsultationHistory}
                    >
                      View Consultation History
                    </StyledButton>
                  </Row>
                )}
              <Col
                className="sidebar_menu_tag"
                onClick={() => {
                  handlemobileMenu(true);
                }}
              >
                <div className="menu_bar">
                  <span></span>
                </div>
              </Col>
            </div>
            <Col span={24}>
              <StyledRow justify="center">
                <UserProfileHead
                  // user={user}
                  patientId={patient?.pId ? patient.pId : pId}
                  patientName={patient?.pName ? patient.pName : pName}
                />
              </StyledRow>
            </Col>
          </Row>
        </Col>

        {patient.pId && section.sectionId && (
          <StyledRouterContainer className="form-m2-style">
            <HealthRecordContentRouter />
          </StyledRouterContainer>
        )}
      </SectionContent>
    </Row>
  );
};

export default HealthReacords;
