import React, { useState, useEffect } from "react";
import { NewButton } from "./styledComponents";
import { UserAddOutlined } from "@ant-design/icons";
import { Row, Col, message } from "antd";
import {
  SectionHeading,
  SectionHeadContainer,
  StyledDiv,
} from "../styledComponents";
import { getWards as fetchWards } from "../../../../apis/wards";
import WardsTable from "./WardsTable";
import NewDocumentModal from "./NewDocumentModal";
import ConfirmationModal from "./ConfirmationModal";
import { BodyContainer } from "../Sections/styledComponents";
import { useSelector } from "react-redux";
import { getDepartments, getWardsCategory } from '../../../../apis/Appointment';


import { getHospitalId } from "../../../utils/LocalStorage";
const Wards = () => {
  const [wards, setWards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setselectedItem] = useState();
  const [wardsCategory, setWardsCategory] = useState([]);
  const [departments, setDepartments] = useState([]);
  const theme = useSelector((state) => state.themeReducer);

  const getWards = () => {
    setLoading(true);
    fetchWards()
      .then(({ data, status }) => {
        // if (status === 200) {
        //   if (data.status == 1) setWards(data.body);
        // } else {
        //   message.error("Unable to fetch wards");
        // }
        if (data) setWards(data);;
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const newWardClickHandler = () => {
    setModalVisible(true);
  };

  const getWardCategory = () => {
    getWardsCategory()
      .then((response) => {
        console.error(response.data);
        if (response.data) setWardsCategory(response.data);
        setLoading(false);
      })
      .catch((err) => {
        // setDoctorsLoading(false);
        console.log(err);
        message.error('Could not fetch Wards Category List, Try Again');
      });
  };

  const getDepartment = () => {
    getDepartments(getHospitalId())
      .then((response) => {
        setDepartments(response.data.body);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Could not fetch departments, Try Again');
      });
  };


  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getWards();
      getWardCategory();
      getDepartment();
    }

    // Cleanup function to set the flag to false when the component is unmounted
    return () => {
      isMounted = false;
    };

  }, []);
  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading>Wards</SectionHeading>
            </Col>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <NewButton
                type="primary"
                // ghost
                // size="large"
                icon={<UserAddOutlined />}
                onClick={newWardClickHandler}
              >
                New Ward
              </NewButton>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <StyledDiv bgcolor={theme["@primary-color"]}>
            <div>&nbsp;&nbsp;Wards</div>
            <div>&nbsp;&nbsp;</div>
          </StyledDiv>
          <WardsTable
            wards={wards}
            loading={loading}
            setEditModal={setModalVisible}
            setDeleteModal={setDeleteModal}
            setselectedItem={setselectedItem}
          />
        </BodyContainer>
      </Col>
      <NewDocumentModal
        visible={modalVisible}
        setVisible={setModalVisible}
        refetch={() => getWards()}
        selectedItem={selectedItem}
        setSelectedItem={setselectedItem}
        departments={departments}
        wardsCategory={wardsCategory}
      />
      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        selectedItem={selectedItem}
        setSelectedItem={setselectedItem}
        refetch={() => getWards()}
      />
    </Row>
  );
};

export default Wards;
