import React from "react";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/DeleteIcon.svg";

const insuranceTypes = { SELF: "Self", COPAY: "Copay", BOTH: "Both" };

export const columns = (
  setEditModal,
  setDeleteModal,
  setselectedItem,
  setSelected
) => [
  {
    width: "1vw",
  },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
    width: "5vw",
  },
  {
    title: "Insurance Provider",
    width: "15vw",
    dataIndex: "name",
  },
  {
    title: "Visit Type",
    dataIndex: ["visitTypes"],
    width: "20vw",
    render: (visitType) => {
      return visitType.map((item) => item).join(", ");
    },
  },
  {
    title: "Date Created",
    width: "8vw",
    dataIndex: "createdDate",
  },
  {
    title: "Actions",
    width: "5vw",
    render: (record) => (
      <Row gutter={12}>
        <Col>
          {" "}
          <Icon
            style={{ fontSize: "20px" }}
            component={EditIcon}
            onClick={() => {
              setselectedItem(record);
              setSelected(true);
              setEditModal(true);
            }}
          />
        </Col>
        <Col>
          <Icon
            style={{ fontSize: "22px" }}
            component={DeleteIcon}
            onClick={() => {
              setselectedItem(record);
              setDeleteModal(true);
            }}
          />
        </Col>
      </Row>
    ),
  },
];
