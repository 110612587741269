import axiosInstance from "../config/axiosInstance";
const USER_PRFIX = "/user";
const PATIENT_PRFIX = "/patient";
const DEPARTMENT_PRFIX = "/departments";

export const createPatient = (data) => {
  return axiosInstance.post(`${USER_PRFIX}/add-patient`, data);
};

export const getActivetPatients = (queryString) => {
  return axiosInstance.get(`${PATIENT_PRFIX}/search-active?${queryString}`);
};
export const getAllPatients = (queryString) => {
  return axiosInstance.get(`${PATIENT_PRFIX}/search?${queryString}`);
};
export const getOneByID = (id) => {
  return axiosInstance.get(`${PATIENT_PRFIX}/${id}`);
};
export const updatePatient = (id, data) => {
  return axiosInstance.patch(`${PATIENT_PRFIX}/${id}`, data);
};

export const resetPatientPassword = (id, password) => {
  return axiosInstance.put(
    `${PATIENT_PRFIX}/reset-password?id=${id}&password=${password}`
  );
};

export const deletePatient = (id) => {
  return axiosInstance.delete(`${PATIENT_PRFIX}?patientId=${id}`);
};

export const fetchPatientForms = (patientId) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/document-data?userId=${patientId}`
  );
};

export const getAppointmentRequests = (queryString) => {
  return axiosInstance.get(`${PATIENT_PRFIX}/search-pending?${queryString}`);
};

export const getReports = (queryString) => {
  return axiosInstance.get(`${PATIENT_PRFIX}/search-reports?${queryString}`);
};

export const getReportWaiting = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-report-waiting?${queryString}`
  );
};

export const getIPDPateint = () => {
  return axiosInstance.get(`ipd/getActiveIpdsByHospital`);
};

export const exportReport = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/export-Search-reports?${queryString}`,
    {
      headers: { "Content-Type": "application/octet-stream" },
      responseType: "blob",
    }
  );
};

export const exportAll = (queryString) => {
  return axiosInstance.get(`${PATIENT_PRFIX}/search-export?${queryString}`, {
    headers: { "Content-Type": "application/octet-stream" },
    responseType: "blob",
  });
};

export const fetchMedicalDocument = (queryString) => {
  return axiosInstance.get(`${PATIENT_PRFIX}/medical-document?${queryString}`);
};

export const saveMedicalDocument = (data) => {
  return axiosInstance.post(`${PATIENT_PRFIX}/save-medical-document`, data);
};

export const searchAppointmentUnderGivenDate = (data) => {
  return axiosInstance.post(
    `${PATIENT_PRFIX}/search-appointment-under-given-date`,
    data
  );
};
export const getDoctors = () => {
  return axiosInstance.get(`${DEPARTMENT_PRFIX}/doctors`);
};

export const getUsersByType = (type) => {
  return axiosInstance.get(`${USER_PRFIX}/by-user-type?userType=${type}`);
};

export const getActiveLaboratory = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-active-laboratory?${queryString}`
  );
};

export const getReportWaitingLaboratory = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-report-waiting-laboratory?${queryString}`
  );
};

export const getReportWaitingIPDLaboratory = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-report-waiting-laboratory-ipd?pageNo=0&size=15&timeZone=Asia/Calcutta`
  );
};

export const getTestHistoryLaboratory = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-test-history-laboratory?${queryString}`
  );
};

export const getActiveRadiology = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-active-radiology?${queryString}`
  );
};

export const getReportWaitingRadiology = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-report-waiting-radiology?${queryString}`
  );
};


export const getReportWaitingRadiologyIPD = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-report-waiting-radiology-ipd?${queryString}`
  );
};
export const getTestHistoryRadiology = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-test-history-radiology?${queryString}`
  );
};

export const getActivePharmacy = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-active-pharmacy?${queryString}`
  );
};

export const getActivePharmacyIPD = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-active-pharmacy-ipd?${queryString}`
  );
};

export const getHistoryPharmacy = (queryString) => {
  return axiosInstance.get(
    `${PATIENT_PRFIX}/search-history-pharmacy?${queryString}`
  );
};

let axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};

export const getPatientsByPhone = (data) => {
  return axiosInstance.post(
    `${PATIENT_PRFIX}/find-patients-by-phoneno`,
    data,
    axiosConfig
  );
};
