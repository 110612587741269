import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import { Row, Col, Space, Button } from "antd";
import styled from "styled-components";
import { SectionHeadContainer } from "../styledComponents";
import { StyledTextArea } from "../GeneralSettings/styledComponents";
import Icon from "@ant-design/icons";
import { useSelector } from "react-redux";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants.js";
import {
  ModalBodyContainer,
  SectionHeading,
  LogoContainer,
  SectionMessageContainer,
  SectionButtonContainer,
  SectionFooterContainer,
  StyledButton,
  Logo,
} from "./styledComponents";
const TemplatePreview = ({ visible, setVisible, templateValues }) => {
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);

  return (
    <CustomModal
      visible={visible}
      setVisible={setVisible}
      antdMOdalProps={{ centered: true }}
      isClosable
    >
      <ModalBodyContainer>
        <Row gutter={[12, 24]}>
          <Col span={6}></Col>
          <Col span={12}>
            {" "}
            <LogoContainer>
              <Logo src={PROFILE_IMAGE_PREFIX + images.logo} />
            </LogoContainer>
          </Col>
          <Col span={6}></Col>
          {templateValues && (
            <>
              {" "}
              <Col span={24}>
                <SectionHeading>{templateValues.header}</SectionHeading>
              </Col>
              <Col span={24}>
                <SectionMessageContainer>
                  {templateValues.message}
                </SectionMessageContainer>
              </Col>
              <Col span={24}>
                {" "}
                <SectionButtonContainer>
                  <StyledButton type="primary" size="large">
                    {templateValues.buttonName}
                  </StyledButton>
                </SectionButtonContainer>
              </Col>
              <Col span={24}>
                {" "}
                <SectionFooterContainer>
                  {" "}
                  {templateValues.footer}
                </SectionFooterContainer>
              </Col>{" "}
            </>
          )}
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default TemplatePreview;
