import styled from "styled-components";
import { Table } from "antd";
export const StyledTable = styled(Table)`
  & .ant-table.ant-table-bordered > .ant-table-title {
    border: none;
    background: ${(props) => props.headBg};
    border-radius: 10px 10px 0 0;
    font-size: 16px;
  }

  & .ant-table.ant-table-small .ant-table-title,
  & .ant-table.ant-table-small .ant-table-footer,
  & .ant-table.ant-table-small .ant-table-thead > tr > th,
  & .ant-table.ant-table-small .ant-table-tbody > tr > td,
  & .ant-table.ant-table-small tfoot > tr > th,
  & .ant-table.ant-table-small tfoot > tr > td {
    padding-left: 30px;
    border-color: rgba(0, 0, 0, 0);
  }

  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr
    > th,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > th,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > th,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > th,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > td,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > td,
  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > td {
    border-color: rgba(0, 0, 0, 0);
    font-size: 15px !important;
  }
  & .ant-table-small .ant-table-thead > tr > th {
    background-color: #fafafa;
    border-bottom: 1px solid #f0f0f0 !important;
    font-weight: 600;
    font-size: 15px !important;
    color: rgb(93, 93, 93) !important;
  }
  & .ant-table.ant-table-bordered > .ant-table-container {
    border-color: rgba(0, 0, 0, 0);
  }

  & .ant-table-container {
    ${(props) => props.isCollapsed && "visibility: collapse"}
  }
  & .ant-table-cell {
    text-transform: capitalize;
  }
`;

export const CustomTableHeader = styled.div`
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: space-between;

  & .anticon {
    ${(props) => props.isCollapsed && "transform: rotate(90deg)"};
  }
`;
