import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import styled from "styled-components";
import {
  Row,
  Col,
  Input,
  Button,
  Typography,
  Select,
  Checkbox,
  Radio,
  Divider,
  Form,
  InputNumber,
  message,
} from "antd";
import { addNewDepartment } from "../../../../apis/departments.js";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { formatTimeStr } from "antd/lib/statistic/utils";
import {
  CaretDownOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import {
  addFormFieldForTest,
  fetchFormFieldMeta,
} from "../../../../apis/forms.js";
import { fetchBloodGroup } from "../../../../apis/Auth.js";
const ModalBodyContainer = styled.div`
  width: 450px;
  padding: 10px 30px;
`;
const { Title } = Typography;

const StyledTitle = styled(Title)`
  &.ant-typography,
  &.ant-typography h3 {
    margin-bottom: 0;
    color: ${(props) => props.color};
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    text-align: center;
  }
`;

const NewFieldScrollContainer = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  /* border: 1px solid gray; */
  background: #f8f8f8;
  padding: 10px;
`;

const StyledForm = styled(Form)`
  width: 500px;
`;
const NewLabTypeFieldsModal = ({
  visible,
  setVisible,
  addType,
  edit,
  update,
  selectedSection,
  setEdit,
  setSelectedSection,
  refetch,
  selectedItem,
  setSelectedItem,
  selectedField,
  total,
  tests,
}) => {
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.themeReducer);
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState("");
  const [dropDownFields, setDropDownFields] = useState([]);
  const [parentValues, setParentValues] = useState([]);
  const [isRelated, setIsRelated] = useState(false);
  const [hasValidations, setHasValidations] = useState(false);
  const [uploadFormat, setUploadFormat] = useState();
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();

  const InputTypes = [
    { name: "Input Text", type: "TEXT" },
    { name: "Dropdown", type: "DROPDOWN" },
    { name: "Radio Button", type: "RADIO" },
    { name: "Single Upload", type: "SINGLE_UPLOAD" },
    { name: "Multiple Upload", type: "MULTIPLE_UPLOAD" },
    { name: "Input Number", type: "NUMBER" },
    { name: "Text Area", type: "TEXT_AREA" },
  ];

  const [inputTypes, setInputTypes] = useState(InputTypes);

  const InputContainerSize = [
    { name: "Full width", size: "FULL" },
    { name: "Half width", size: "HALF" },
  ];

  const typesWithDropdown = ["DROPDOWN", "RADIO", "MULTI_SELECT_DROP_DOWN"];

  const addNew = (data) => {
    setLoading(true);
    addFormFieldForTest(data)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) {
            if (edit) message.success("Updated");
            else message.success("Created");
            setVisible(false);
            form.resetFields();
            setSelectedType("");
            refetch();
          } else {
            message.error(data.body);
          }
          //   setSelectedItem(undefined);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    values.editable = true;
    values.testType = "RADIOLOGY";
    if (!values.width) values.width = "FULL";
    if (edit) {
      // update;
      values.formFieldId = selectedItem.id;
      values.order = selectedItem.formOrder;
    } else {
      // create;
      if (!values.order) values.order = total + 1;
    }
    form.getFieldValue("mandatory")
      ? form.setFieldsValue({ mandatory: true })
      : form.setFieldsValue({ mandatory: false });
    if (selectedField) {
      values.testId = selectedField.id;
    }
    if (selectedItem) {
      // if (values.fieldType == selectedItem.fieldType)
      values.dropDownType = selectedItem.dropDownType
        ? selectedItem.dropDownType.dropDownType
        : undefined;
    }
    if (values.fieldType == "NUMBER" && values.hasValidation) {
      if (values.minValue < values.maxValue) {
        addNew(values);
      } else {
        message.error("Min Value should be less than Max Value!");
      }
    } else addNew(values);
  };

  useEffect(() => {
    if (!edit) {
      form.resetFields();
      setSelectedType("");
      setEdit(false);
      setHasValidations(false);
    }
  }, [edit]);

  useEffect(() => {
    if (edit) {
      let uploadFormats = selectedItem?.validFormats?.map((item) => {
        return item.id;
      });
      form.setFieldsValue({
        type: selectedItem.name,
        validFormatIds: uploadFormats,
      });
    }
  }, [edit, selectedSection]);

  useEffect(() => {
    setDropDownFields(
      tests.filter((item) => {
        return (
          typesWithDropdown.includes(item.fieldType) && !item.parentFieldId
        );
      })
    );
    if (tests) {
      if (
        tests.find(
          (item) =>
            item.fieldType == "SINGLE_UPLOAD" ||
            item.fieldType == "MULTIPLE_UPLOAD"
        )
      )
        setInputTypes(
          inputTypes.filter(
            (item) =>
              item.type !== "SINGLE_UPLOAD" && item.type !== "MULTIPLE_UPLOAD"
          )
        );
      else setInputTypes(InputTypes);
    }
  }, [tests]);

  useEffect(() => {
    if (
      form.getFieldValue("fieldType") == "SINGLE_UPLOAD" ||
      form.getFieldValue("fieldType") == "MULTIPLE_UPLOAD"
    )
      fetchBloodGroup("UPLOAD_FORMATS")
        .then(({ data, status }) => {
          if (status == 200)
            if (data) {
              if (data.status === 1) {
                setUploadFormat(data.body);
              }
            }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
  }, [form.getFieldValue("fieldType")]);

  const generateSubDropDownValues = (dropDownValues, parentId) => {
    if (dropDownValues.length > 0) {
      if (parentId) {
        let parentIds = [
          ...new Set(dropDownValues.map((item) => item.parentFieldId)),
        ];

        return parentIds.map((id) => {
          // TODO: filter out the fields that are no longer exist in parentField
          return {
            parentValueId: id,
            values: dropDownValues
              .filter((field) => field.parentFieldId === id)
              .map((item) => item.value),
          };
        });
      } else {
        return [{ values: dropDownValues.map((item) => item.value) }];
      }
    }
  };

  const onFormValuesChange = (changed) => {
    if (changed.fieldType) {
      setSelectedType(changed.fieldType);
      if (changed.related !== true) form.setFieldsValue({ related: false });

      if (typesWithDropdown.includes(changed.fieldType)) {
        if (!changed.dropDownValues)
          form.setFieldsValue({ dropDownValues: [{ values: [""] }] });
      }
    }
    if (changed.related == true || changed.related == false) {
      setIsRelated(changed.related);
    }
    if (changed.parentFieldId) {
      let field = dropDownFields.find(
        (item) => item.id == changed.parentFieldId
      );
      if (field) {
        setParentValues(field.dropDownValues);
      }
    }
    if (changed.hasValidation !== undefined && changed.hasValidation !== null) {
      setHasValidations(changed.hasValidation);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue({
        ...selectedItem,
        name: selectedItem.formName,
        mandatory: selectedItem.required ? true : false,
        related: selectedItem.parentFieldId ? true : false,
        dropDownValues: generateSubDropDownValues(
          selectedItem.dropDownValues,
          selectedItem.parentFieldId
        ),
      });
      onFormValuesChange({
        ...selectedItem,
        name: selectedItem.formName,
        mandatory: selectedItem.required ? true : false,
        related: selectedItem.parentFieldId ? true : false,
        dropDownValues: generateSubDropDownValues(
          selectedItem.dropDownValues,
          selectedItem.parentFieldId
        ),
      });

      if (selectedItem.softValidator.length) {
        setHasValidations(true);
        let sofValidator = { hasValidation: true };
        let minItem = selectedItem.softValidator.find(
          (item) => item.validator == "LESS_THAN"
        );
        if (minItem) {
          sofValidator.minValue = minItem.value;
          setMinValue(minItem.value);
        }
        let maxItem = selectedItem.softValidator.find(
          (item) => item.validator == "GREATER_THAN"
        );
        if (maxItem) {
          sofValidator.maxValue = maxItem.value;
          setMaxValue(maxItem.value);
        }
        form.setFieldsValue(sofValidator);
      } else {
        form.setFieldsValue({ hasValidation: false });
        setHasValidations(false);
      }
    } else {
      form.setFieldsValue({ hasValidation: false });
      setHasValidations(false);
    }
  }, [dropDownFields, selectedItem]);

  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBodyContainer>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            {" "}
            <StyledTitle level={3} color={theme["@primary-color"]}>
              {edit ? "Edit Field" : "Create New Field"}
            </StyledTitle>
          </Col>
          <StyledForm
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={onFormValuesChange}
            form={form}
            scrollToFirstError={true}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Value is Required !",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Type"
              name="fieldType"
              rules={[
                {
                  required: true,
                  message: "Type is required",
                },
              ]}
            >
              <Select
                size="large"
                getPopupContainer={(trigger) => trigger.parentNode}
                suffixIcon={<CaretDownOutlined style={{ color: "#929292" }} />}
              >
                {form.getFieldValue("fieldType") == "MULTIPLE_UPLOAD" ||
                form.getFieldValue("fieldType") == "SINGLE_UPLOAD"
                  ? InputTypes.map((field) => (
                      <Select.Option value={field.type}>
                        {field.name}
                      </Select.Option>
                    ))
                  : inputTypes.map((field) => (
                      <Select.Option value={field.type}>
                        {field.name}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
            {form.getFieldValue("fieldType") == "SINGLE_UPLOAD" ||
            form.getFieldValue("fieldType") == "MULTIPLE_UPLOAD" ? (
              <></>
            ) : (
              <Form.Item label="" name="mandatory" valuePropName={"checked"}>
                <Checkbox>Mandatory</Checkbox>
              </Form.Item>
            )}
            {form.getFieldValue("fieldType") == "SINGLE_UPLOAD" ||
            form.getFieldValue("fieldType") == "MULTIPLE_UPLOAD" ? (
              <></>
            ) : (
              <Form.Item
                label={
                  <Title
                    level={4}
                    style={{ fontSize: "15px", marginBottom: "0" }}
                  >
                    Input Box Size
                  </Title>
                }
                name="width"
                rules={[
                  {
                    required: true,
                    message: "Size is required",
                  },
                ]}
              >
                <Radio.Group>
                  {InputContainerSize.filter((item) => {
                    if (
                      (form.getFieldValue("fieldType") == "SINGLE_UPLOAD" &&
                        item.size !== "FULL") ||
                      (form.getFieldValue("fieldType") == "MULTIPLE_UPLOAD" &&
                        item.size !== "FULL")
                    )
                      return false;
                    else return true;
                  }).map((item) => (
                    <Radio value={item.size}>{item.name}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            )}
            {typesWithDropdown.includes(selectedType) && (
              <>
                {selectedType !== "RADIO" && (
                  <Form.Item
                    label={
                      <Title
                        level={4}
                        style={{ fontSize: "15px", marginBottom: "0" }}
                      >
                        Is related to previous field?
                      </Title>
                    }
                    name="related"
                    rules={[
                      {
                        required: true,
                        message: "Size is required",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
                {(!isRelated || selectedType == "RADIO") && (
                  <Form.List name={["dropDownValues", 0, "values"]}>
                    {(fields, { add, remove }) => (
                      <div>
                        <Row gutter={[0, 6]} justify="space-between">
                          <Col>Values</Col>
                          <Col>
                            <Button
                              type="primary"
                              ghost
                              size="small"
                              onClick={() => {
                                add();
                              }}
                            >
                              Add New
                            </Button>
                          </Col>
                        </Row>
                        {fields.map((field, index) => (
                          <>
                            <div key={field.key}>
                              <Form.Item
                                // label="Name"
                                name={[index]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Form name is Required !",
                                  },
                                ]}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                            {fields.length > 1 && (
                              <Divider>
                                <Button
                                  size="small"
                                  danger
                                  onClick={() => remove(index)}
                                >
                                  Remove
                                </Button>
                              </Divider>
                            )}
                          </>
                        ))}
                      </div>
                    )}
                  </Form.List>
                )}
                {isRelated && selectedType !== "RADIO" && (
                  <>
                    <Form.Item
                      label="Select"
                      name="parentFieldId"
                      rules={[
                        {
                          required: true,
                          message: "Type is required",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        size="large"
                        suffixIcon={
                          <CaretDownOutlined style={{ color: "#929292" }} />
                        }
                      >
                        {dropDownFields.map((field) => (
                          <Select.Option value={field.id}>
                            {field.formName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.List name="dropDownValues">
                      {(fields, { add, remove }) => (
                        <div>
                          <Row gutter={[0, 6]} justify="space-between">
                            <Col>Values</Col>
                            <Col>
                              <Button
                                type="primary"
                                ghost
                                size="small"
                                onClick={() => {
                                  add();
                                }}
                              >
                                Add New
                              </Button>
                            </Col>
                          </Row>
                          <NewFieldScrollContainer>
                            {fields.map((field, index) => (
                              <div key={field.key}>
                                <Form.Item
                                  label="Choose Item"
                                  name={[index, "parentValueId"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Type is required",
                                    },
                                  ]}
                                >
                                  <Select
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                    size="large"
                                    suffixIcon={
                                      <CaretDownOutlined
                                        style={{ color: "#929292" }}
                                      />
                                    }
                                  >
                                    {parentValues.map((field) => (
                                      <Select.Option value={field.id}>
                                        {field.value}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <Form.List name={[index, "values"]}>
                                  {(fields, { add, remove }) => (
                                    <div>
                                      <Row
                                        gutter={[0, 6]}
                                        justify="space-between"
                                      >
                                        <Col>Values</Col>
                                        <Col>
                                          {fields.length < 1 && (
                                            <PlusCircleOutlined
                                              style={{
                                                fontSize: "20px",
                                                marginTop: "10px",
                                              }}
                                              onClick={() => {
                                                add();
                                              }}
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                      {fields.map((field, index) => (
                                        <div key={field.key}>
                                          <Row align="base-line" gutter={6}>
                                            <Col span={20}>
                                              <Form.Item
                                                // label="Name"
                                                name={[index]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Form name is Required !",
                                                  },
                                                ]}
                                              >
                                                <Input size="large" />
                                              </Form.Item>
                                            </Col>
                                            {fields.length > 1 && (
                                              <Col span={2}>
                                                <DeleteOutlined
                                                  style={{
                                                    color: "red",
                                                    fontSize: "20px",
                                                    marginTop: "10px",
                                                  }}
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                />
                                              </Col>
                                            )}
                                            {fields.length == index + 1 && (
                                              <Col span={2}>
                                                <PlusCircleOutlined
                                                  style={{
                                                    fontSize: "20px",
                                                    marginTop: "10px",
                                                  }}
                                                  onClick={() => {
                                                    add();
                                                  }}
                                                />
                                              </Col>
                                            )}
                                          </Row>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </Form.List>
                                {fields.length > 1 && (
                                  <Divider>
                                    <Button
                                      size="small"
                                      danger
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </Button>
                                  </Divider>
                                )}
                              </div>
                            ))}
                          </NewFieldScrollContainer>
                        </div>
                      )}
                    </Form.List>
                  </>
                )}
              </>
            )}
            {selectedType === "NUMBER" && (
              <>
                <Form.Item
                  label="Need to validate ?"
                  name="hasValidation"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                {hasValidations && (
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item
                        label="Min Value"
                        name="minValue"
                        rules={[
                          {
                            required: true,
                            message: "Minimum Value is required",
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Max Value"
                        name="maxValue"
                        rules={[
                          {
                            required: true,
                            message: "Maximum Value is required",
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </>
            )}
            {(selectedType === "SINGLE_UPLOAD" ||
              selectedType === "MULTIPLE_UPLOAD") && (
              <>
                <Form.Item
                  label="Supported file formats"
                  name="validFormatIds"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Row>
                      {uploadFormat?.map((item) => (
                        <Col span={6}>
                          <Checkbox value={item.id}>{item.value}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Row gutter={12} justify="end">
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    ghost
                    onClick={() => {
                      setVisible(false);
                      form.resetFields();
                      setSelectedType("");
                      setEdit(false);
                      setMinValue();
                      setMaxValue();
                      setHasValidations(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    htmlType="submit"
                  >
                    {!edit ? "Create" : "Update"}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </StyledForm>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};

export default NewLabTypeFieldsModal;
