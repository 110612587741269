import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'antd';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/Successful-icon.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/CancelIcon.svg';
import { useHistory } from 'react-router-dom';

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

const ModalContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledCancelIcon = styled(CancelIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledP = styled.p`
  font-size: 15px;
`;

const StyledPTitle = styled.p`
  font-size: 18px;
`;

const StatusModal = ({
  visible,
  closeModal,
  cancel,
  cancelAppointment,
  cancelLoading,
}) => {
  const history = useHistory();

  return (
    <StyledModal
      visible={visible}
      closable={false}
      centered
      maskClosable={true}
      footer={
        cancel
          ? [
              <Button
                key="ok"
                type="primary"
                ghost
                onClick={() => closeModal()}
              >
                Cancel
              </Button>,
              <Button
                key="ok"
                type="primary"
                onClick={() => cancelAppointment()}
                loading={cancelLoading}
              >
                Confirm
              </Button>,
            ]
          : [
              <Button key="ok" type="primary" onClick={() => history.push('/')}>
                Ok
              </Button>,
            ]
      }
    >
      {cancel ? (
        <ModalContentDiv>
          <StyledCancelIcon />
          <StyledPTitle>Cancel Appointment.</StyledPTitle>
          <StyledP>
            Please click confirm if you want to cancel the appointment
          </StyledP>
        </ModalContentDiv>
      ) : (
        <ModalContentDiv>
          <StyledSuccessIcon />
          <StyledPTitle>Appointment Booked Successfully.</StyledPTitle>
        </ModalContentDiv>
      )}
    </StyledModal>
  );
};

export default StatusModal;
