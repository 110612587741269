import React, { useState, useRef } from "react";
import { Table, Row, Col, message, Form, Button, Tooltip, Upload, Typography } from "antd";
import { deleteUploadedFile, uploadPdfOrImage } from "../../../apis/uploadPdfOrImage"
import { ConsoleSqlOutlined, DownloadOutlined, RocketTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import ReportManagementTable from "./reportManagementTable";
import { UploadOutlined, InboxOutlined, CloseOutlined, CloudUploadOutlined, CloseCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { PROFILE_IMAGE_PREFIX } from "../../../constants/StringConstants";
import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Spinner from "../../utils/Spinner";
import * as Sentry from "@sentry/react";

const { Text } = Typography;

const RNDContext = createDndContext(HTML5Backend);

const StyledH2 = styled.h2`
  // width: 200px;
  /* fontSize: 40px; */
  color: black;
  `;

export const DocumentUpload = ({ files, setFiles, selectedItem, isPdf, disabled }) => {
    const [documentUrl, setDocumentUrl] = useState();
    const [uploading, setUploading] = useState(false);
    const [docName, setDocName] = useState("");
    const [columnName, setColumnName] = useState([{
        render: (row, files) => {
            return (
                <Button
                    shape="circle"
                    type="link"
                    danger
                    icon={<FileTextOutlined style={{ color: theme["@primary-color"], fontSize: "25px" }} />}
                />
            )
        }
    }, {
        "dataIndex": "name",
        render: (value) => {
            if (value?.length > 75)
                return value?.slice(0, 72) + "..."
            else
                return value
        }
    }, {
        render: (row, files) => {
            return (
                <Tooltip title="Delete document">
                    <Button
                        shape="circle"
                        type="link"
                        danger
                        icon={<CloseCircleOutlined style={{ color: "grey" }} />}
                        onClick={() => clearDoc(row, files)}

                    />
                </Tooltip>
            )
        }
    }]);
    const theme = useSelector((state) => state.themeReducer);
    const manager = useRef(RNDContext);
    const setDocuments = (file) => {

        // onChange(temp);

    }

    const prop = {
        name: "files",
        multiple: true,
        showUploadList: false,
        beforeUpload: (file, fileList) => {
            setUploading(true)
            console.log("entered into prop in doc", fileList);
            const isJpgOrPng =
                file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === "application/jpg" ||
                file.type === 'application/pdf';
            if (!isJpgOrPng) {
                setUploading(false)
                message.error('You can only upload JPG/PNG/PDF file!');
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                setUploading(false)
                message.error('File must smaller than 5MB!');
            }
            if (isJpgOrPng && isLt2M)
                handlePdfUpload(file, fileList)
        },
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handlePdfUpload = (file, fileList) => {
        uploadPdfOrImage(null, file).then((doc) => {
            if (doc.status === 200) {
                if (doc.data.status === 1) {
                    // setDocName(doc.data.body.fileName);
                    file.url = doc.data.body.documentUrl
                    var temp = [...files]
                    temp = temp.concat(fileList)
                    setFiles(temp)
                    console.log("handle document upload in doc component", fileList, "name", doc);
                    setUploading(false)
                } else {
                    message.error(doc.data.body);
                    setUploading(false)
                    //         setLoading(false);
                }
            }
        })
            .catch((err) => {
                console.log(err);
                Sentry.captureException(err);
                message.error("Invalid username or Password");
                setUploading(false)
                //     setLoading(false);
            });

    };
    useEffect(() => {
        if (selectedItem) {
            setFiles(selectedItem.documentUrls)
        }
    })

    useEffect(() => {
        setColumnName([{
            render: (row, files) => {
                return (
                    <Button
                        shape="circle"
                        type="link"
                        danger
                        icon={<FileTextOutlined style={{ color: theme["@primary-color"], fontSize: "25px" }} />}
                    // onClick={() => clearDoc(row, files)}

                    />
                )
            }
        }, {
            "dataIndex": "name",
            render: (value) => {
                if (value?.length > 75)
                    return value?.slice(0, 72) + "..."
                else
                    return value
            }
        }, {
            render: (row, files) => {
                return (
                    <Tooltip title="Delete document">
                        <Button
                            shape="circle"
                            type="link"
                            danger
                            icon={<CloseCircleOutlined style={{ color: "grey" }} />}
                            onClick={() => clearDoc(row, files)}
                        />
                    </Tooltip>
                )
            }
        }])
        // onChange(files)
        // setFiles(files)
    }, [files])


    const clearDoc = (row) => {
        var temp = []
        if (row) {
            files.map((item) => {
                if (item.uid !== row.uid) {
                    temp = temp.concat(item)
                } else {
                    deleteUploadedFile(item?.url)
                        .then((res) => {
                            if (res.status === 200) {
                                if (res.data.status === 1) {
                                    console.log(res.data)
                                }
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            Sentry.captureException(err);
                        });
                }
            })
        }
        setFiles(temp)
        // onChange(temp)
    };

    return (
        <div>
            <Row style={{ justifyContent: "center" }}>
                <Col style={{ minWidth: "50vw" }}>
                    <Form.Item
                        rules={[{
                            required: true,
                            message: "Documents required"
                        }]}>
                        <Upload.Dragger {...prop}
                            action="/upload.do"
                            customRequest={dummyRequest}
                            disabled={disabled || uploading}>
                            {uploading ? <Spinner height={"100px"} /> :
                                <>
                                    <p className="ant-upload-hint"
                                        style={{ fontSize: "75px", color: "rgba(0, 0, 0, 0.25)" }}>
                                        <CloudUploadOutlined />
                                    </p>
                                    <Text className="ant-upload-text"
                                        style={{ color: "rgba(0, 0, 0, 0.25)", fontWeight: "bold" }}>
                                        Drag and Drop or
                                        <Text style={{ color: theme["@primary-color"], fontWeight: "bold" }}> Browse </Text>
                                        files</Text>
                                    <br />
                                    <Text className="ant-upload-text"
                                        style={{ color: theme["@primary-color"], fontWeight: "bold" }}>
                                        Supporting file formats: .jpg, .jpeg, .png, .pdf with max file size of 5MB each
                                    </Text>
                                </>}
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ color: "grey", marginTop: "5px", marginBottom: "10px" }}>
                <p style={{ color: "red", paddingRight: "5px" }}>*</p>You can reorder the document uploads before the final submit
            </Row>
            <ReportManagementTable
                dataSource={files}
                columns={columnName}
                setDataSource={setFiles}
                draggable={true}
                preventLast={false}
            // uploading={uploading}
            // loading={uploading}
            />
        </div >
    );
};
