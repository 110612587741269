import { Col, Dropdown, Menu, Row, message } from "antd";
import React, { useState } from "react";
import { generateInvoiceIPD } from "../../../apis/healthRecordIPD";
import HasPrivilege from "../../utils/privilegeHandlers/HasPrivilege.jsx";
import UploadReportModal from "./uploadReportModal";
import { genderList } from "../../Datasets/basicDetails";
import { hasPrivilege } from "../../utils/privilegeHandlers/hasPrivilege.js";
import moment from "moment";
import privilege from "../../../constants/privileges";

import { getUserRoles } from "../../utils/LocalStorage";

import MessageAllModal from "../Appoinment/MessageModal.jsx";

const getGender = (code) => {
  var gender;
  gender = genderList().find((item) => item.code === code);
  return gender.text;
};
const menuActivePatients = (row, patient, cancelModalVisible, history) => {
  const todaysDate = new Date().toLocaleDateString("en-US");

  const date = new Date(patient.date);
  const formattedDate = date.toLocaleDateString("en-US");

  // {
  //   patient.date.toLocaleDateString('en-US') ===
  //     new Date().toLocaleDateString('en-US');
  // }
  // console.log(
  //   'patient detail is',
  //   patient.patient?.phoneNumbers[0]?.phoneNumber,
  //   patient.patient?.phoneNumbers[0]?.countryCode
  // );
  // console.log(
  //   'patient number',
  //   `${patient.patient?.phoneNumbers[0]?.countryCode} ${patient.patient?.phoneNumbers[0]?.phoneNumber}`
  // );

  return (
    <Menu
      onClick={(props) => {
        props.domEvent.stopPropagation();
        props.key === "reassign" &&
          history.push(
            `/appointment/${row.id}/${patient.id}/${row.firstName} ${row.lastName}`
          );
        props.key === "cancel" && cancelModalVisible(patient.id);
      }}
    >
      <Menu.Item key="reassign">Reassign</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="cancel">Cancel</Menu.Item>
    </Menu>
  );
};

const menuAllPatients = (
  row,
  history,
  uploadReportModalVisible,
  setUploadReportModalVisible,
  setRow,
  setMessageModalVisible,
  messageModalVisible,
  cancelLoading
) => {
  // const [uploadReportModalVisible, setUploadReportModalVisible] = useState(false)
  console.log("row is in patientlist", row);
  return (
    <>
      <Menu
        onClick={(props) => {
          props.domEvent.stopPropagation();
          props.key === "update" &&
            history.push(`/update-patient/${row.id}/${row.firstName}`);
          props.key === "history" &&
            history.push(`/patient-history/${row.id}/${row.firstName}`);
          if (props.key === "upload") {
            setUploadReportModalVisible(true);
            setRow(row);
          }
          if (props.key === "message") {
            setMessageModalVisible(true);
            setRow(row);
          }
        }}
      >
        {hasPrivilege(privilege.UPDATE_PATIENT) && (
          <>
            <Menu.Item key="update">View Details</Menu.Item>
            <Menu.Divider />
          </>
        )}
        {hasPrivilege(privilege.SCHEDULE_APPOINTMENT) && (
          <>
            <Menu.Item key="history">Billing History</Menu.Item>
            <Menu.Divider />
          </>
        )}
        {hasPrivilege(privilege.UPDATE_PATIENT) && (
          <>
            <Menu.Item key="upload">Upload</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="message">Message</Menu.Item>
          </>
        )}

        {/* <UploadReportModal
        visible={uploadReportModalVisible}
        setVisible={setUploadReportModalVisible}
        row={row}
      /> */}
      </Menu>
      <MessageAllModal
        visible={messageModalVisible}
        closeModal={() => setMessageModalVisible(false)}
        cancelLoading={cancelLoading}
        row={row}
      />
    </>
  );
};
export const activePatientColumns = (
  history,
  cancelModalVisible,
  user,
  inTimeModalVisible,
  setAppointmentId,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  setNurseUploadReportModalVisible,
  setUploadNursePatient
) => [
  {
    title:
      (user.userType === "RECEPTIONIST" ||
        user.userType === "NURSE" ||
        user.userType === "DOCTOR") &&
      "Actions",
    dataIndex: ["patient"],
    fixed: "left",
    render: (row, patient) => {
      return (
        (user.userType === "RECEPTIONIST" ||
          user.userType === "NURSE" ||
          user.userType === "DOCTOR") && (
          <>
            <HasPrivilege role="RECEPTIONIST">
              <Row gutter={12}>
                <Col>
                  <StyledButton
                    color={theme["@primary-color"]}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(
                        `/appointment/${row.id}/${patient.id}/${row.firstName} ${row.lastName}`
                      );
                    }}
                  >
                    Reschedule
                  </StyledButton>
                </Col>
                <Col>
                  <Dropdown
                    overlay={menuActivePatients(
                      row,
                      patient,
                      cancelModalVisible,
                      history
                    )}
                    trigger={["click"]}
                  >
                    <a
                      style={{ fontWeight: "600" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      More Actions
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </HasPrivilege>
            <Row gutter={12}>
              {console.log(
                "date here",
                new Date(patient.date).toLocaleDateString("en-US"),
                new Date().toLocaleDateString("en-US")
              )}
              <HasPrivilege role="DOCTOR">
                {patient.inTimeDoc === null &&
                new Date(patient.date).toLocaleDateString("en-US") ===
                  new Date().toLocaleDateString("en-US") ? (
                  <Col>
                    <StyledButton
                      color={theme["@primary-color"]}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAppointmentId({
                          appointmentId: patient.id,
                          patientId: row.id,
                        });
                        inTimeModalVisible(true);
                      }}
                    >
                      In Time
                    </StyledButton>
                  </Col>
                ) : (
                  <Col>
                    {" "}
                    <StyledButton
                      color={theme["@primary-color"]}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("row id", patient);
                        history.push(
                          `/health-records/${row.id}/${
                            row.firstName + "-" + row.lastName
                          }/${patient.id}`
                        );
                      }}
                    >
                      Call/View
                    </StyledButton>
                  </Col>
                )}
              </HasPrivilege>

              <HasPrivilege role="NURSE">
                {patient.inTime === null || patient.inTime === undefined ? (
                  <Col>
                    <StyledButton
                      color={theme["@primary-color"]}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAppointmentId({
                          appointmentId: patient.id,
                          patientId: row.id,
                        });
                        inTimeModalVisible(true);
                      }}
                    >
                      In Time
                    </StyledButton>
                  </Col>
                ) : (
                  <Col>
                    {" "}
                    <StyledButton
                      color={theme["@primary-color"]}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(
                          `/health-records/${row.id}/${
                            row.firstName + "-" + row.lastName
                          }/${patient.id}`
                        );
                      }}
                    >
                      Call
                    </StyledButton>
                  </Col>
                )}
                <Col>
                  <StyledButton
                    color={theme["@primary-color"]}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setNurseUploadReportModalVisible(true);
                      setUploadNursePatient(row);
                    }}
                  >
                    Upload
                  </StyledButton>
                </Col>
              </HasPrivilege>
            </Row>
          </>
        )
      );
    },
  },
  {
    title: "Sl. No",
    dataIndex: "",
    key: "no",
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: "P ID",
    dataIndex: ["patient", "id"],
    key: "id",
  },
  {
    title: "F Name",
    dataIndex: ["patient", "firstName"],
    key: "firstName",
  },
  {
    title: "L Name",
    dataIndex: ["patient", "lastName"],
    key: "lastName",
  },
  {
    title: "Gender",
    dataIndex: ["patient", "gender"],
    key: "gender",
    render: (value) => {
      if (value !== null) return getGender(value);
      else return <div>-</div>;
    },
  },
  {
    title: "Mobile No",
    dataIndex: ["patient", "phoneNumbers"],
    key: "phone",
    render: (value) => {
      if (value !== null) {
        return (
          value &&
          value[0] &&
          value[0].phoneNumber && (
            <div>
              {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
            </div>
          )
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Doctor Name",
    dataIndex: ["doctor"],
    key: "doctor",
    render: (doctor) => {
      if (doctor !== null) {
        return (
          <div>
            Dr. {doctor.firstName} {doctor.lastName}
          </div>
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Visit Type",
    dataIndex: "visitType",
    key: "visitType",
    render: (visitType) => {
      if (visitType !== null) return <div>{visitType.value}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date) => {
      if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Time",
    dataIndex: "startTime",
    key: "time",
    render: (startTime) => {
      if (startTime !== null)
        return <div>{moment(startTime).format("hh:mm A")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Payment Method",
    dataIndex: ["paymentType"],
    key: "paymentMethod",
    render: (paymentMethod) => {
      if (paymentMethod !== null) return <div>{paymentMethod}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Status",
    dataIndex: ["status"],
    key: "status",
    render: (status) => {
      if (status !== null) return <div>{status.toUpperCase()}</div>;
      else return <div>-</div>;
    },
  },
  {
    width: 10,
  },
];
export const allPatientColumns = (
  history,
  user,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  uploadReportModalVisible,
  setUploadReportModalVisible,
  setRow,
  setMessageModalVisible,
  patient
) => [
  {
    title:
      (user.userType === "RECEPTIONIST" ||
        user.userType === "NURSE" ||
        (user.userType === "DOCTOR" &&
          getUserRoles().includes("ADD_PATIENT"))) &&
      "Actions",
    fixed: "left",
    render: (val, row) => {
      return (
        (user.userType === "RECEPTIONIST" ||
          user.userType === "NURSE" ||
          (hasPrivilege(undefined, ["DOCTOR"]) &&
            getUserRoles().includes("ADD_PATIENT"))) && (
          <Row gutter={12}>
            <Col>
              <HasPrivilege privilege={privilege.SCHEDULE_APPOINTMENT}>
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  onClick={() => {
                    history.push(
                      `/appointment/${row.id}/${row.firstName} ${row.lastName}`
                    );
                  }}
                >
                  Book Visit
                </StyledButton>
              </HasPrivilege>
            </Col>
            <Col>
              <Dropdown
                overlay={menuAllPatients(
                  row,
                  history,
                  uploadReportModalVisible,
                  setUploadReportModalVisible,
                  setRow,
                  setMessageModalVisible
                )}
                trigger={["click"]}
              >
                <a
                  style={{ fontWeight: "600" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  More Actions
                </a>
              </Dropdown>
            </Col>
          </Row>
        )
      );
    },
  },
  {
    title: "Sl. No",
    dataIndex: "",
    key: "no",
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: "P ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "F Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "L Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
    render: (value) => {
      if (value !== null) return getGender(value);
      else return <div>-</div>;
    },
  },
  {
    title: "Mobile No",
    dataIndex: ["phoneNumbers"],
    key: "phone",
    render: (value) => {
      if (value !== null) {
        return (
          value &&
          value[0] &&
          value[0].phoneNumber && (
            <div>
              {value[0] &&
                value[0].countryCode &&
                value[0].countryCode.split(" ")[0]}
              -{value[0].phoneNumber}
            </div>
          )
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (value) => (
      <p style={{ textTransform: "none", margin: 0 }}>{value}</p>
    ),
  },
  {
    title: "Registered Date",
    dataIndex: "registeredDate",
    key: "registeredDate",
    render: (registeredDate) => {
      if (registeredDate !== null)
        return <div>{moment(registeredDate).format("DD-MM-YYYY")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Last Visit",
    dataIndex: "lastVisit",
    key: "lastVisit",
    render: (lastVisit) => {
      if (lastVisit !== null)
        return <div>{moment(lastVisit).format("DD-MM-YYYY")}</div>;
      else return <div>-</div>;
    },
  },
  {
    width: 10,
  },
];

export const appointmentRequestsColumns = (
  history,
  acceptAppointment,
  declineAppointment,
  pageNo,
  pageSize,
  StyledButton,
  theme
) => [
  {
    title: "Actions",
    fixed: "left",
    render: (text, row, appointmentType) => {
      return (
        <>
          {/* <HasPrivilege role="RECEPTIONIST"> */}
          {hasPrivilege(undefined, ["DOCTOR", "RECEPTIONIST"]) && (
            <Row gutter={12}>
              <Col>
                {" "}
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push({
                      pathname: `/appointment/${row.patient.id}/${row.id}/${row.patient.firstName} ${row.patient.lastName}`,
                      state: {
                        visitType:
                          row.paymentType == "SELF"
                            ? undefined
                            : row.visitType?.value,
                      },
                    });
                  }}
                >
                  Update
                </StyledButton>
              </Col>

              <Col>
                {" "}
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  disabled={
                    row.visitType.value !== "Hospital" &&
                    row.paymentType == "INSURANCE"
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    acceptAppointment(row.id);
                  }}
                >
                  Accept
                </StyledButton>
              </Col>
              <Col>
                {" "}
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  disabled={
                    row.visitType.value !== "Hospital" &&
                    row.paymentType == "INSURANCE"
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    declineAppointment(row.id);
                  }}
                >
                  Decline
                </StyledButton>
              </Col>
            </Row>
          )}
          {/* </HasPrivilege> */}
        </>
      );
    },
  },
  {
    title: "Sl. No",
    dataIndex: "",
    key: "no",
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: "P ID",
    dataIndex: ["patient", "id"],
    key: "id",
  },
  {
    title: "F Name",
    dataIndex: ["patient", "firstName"],
    key: "firstName",
  },
  {
    title: "L Name",
    dataIndex: ["patient", "lastName"],
    key: "lastName",
  },
  {
    title: "Gender",
    dataIndex: ["patient", "gender"],
    key: "gender",
    render: (value) => {
      if (value !== null) return getGender(value);
      else return <div>-</div>;
    },
  },
  {
    title: "Mobile No",
    dataIndex: ["patient", "phoneNumbers"],
    key: "phone",
    render: (value) => {
      if (value !== null) {
        return (
          value &&
          value[0] &&
          value[0].phoneNumber && (
            <div>
              {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
            </div>
          )
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Doctor Name",
    dataIndex: ["doctor"],
    key: "doctor",
    render: (doctor) => {
      if (doctor !== null) {
        return (
          <div>
            Dr. {doctor.firstName} {doctor.lastName}
          </div>
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Visit Type",
    dataIndex: "visitType",
    key: "visitType",
    render: (visitType) => {
      if (visitType !== null) return <div>{visitType.value}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date) => {
      if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Time",
    dataIndex: "startTime",
    key: "time",
    render: (startTime) => {
      if (startTime !== null)
        return <div>{moment(startTime).format("hh:mm A")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Payment Method",
    dataIndex: ["paymentType"],
    key: "paymentMethod",
    render: (paymentMethod) => {
      if (paymentMethod !== null) return <div>{paymentMethod}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Insurance Status",
    dataIndex: ["insuranceStatus"],
    key: "insuranceStatus",
    render: (insuranceStatus) => {
      if (insuranceStatus !== null) return <div>{insuranceStatus}</div>;
      else return <div>-</div>;
    },
  },
  {
    width: 10,
  },
];

export const reportsColumns = (history, pageNo, pageSize) => [
  {
    width: 10,
  },
  {
    title: "Sl. No",
    dataIndex: "",
    key: "no",
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: "P ID",
    dataIndex: ["patient", "id"],
    key: "id",
  },
  {
    title: "F Name",
    dataIndex: ["patient", "firstName"],
    key: "firstName",
  },
  {
    title: "L Name",
    dataIndex: ["patient", "lastName"],
    key: "lastName",
  },
  {
    title: "Gender",
    dataIndex: ["patient", "gender"],
    key: "gender",
    render: (value) => {
      if (value !== null) return getGender(value);
      else return <div>-</div>;
    },
  },
  {
    title: "Mobile No",
    dataIndex: ["patient", "phoneNumbers"],
    key: "phone",
    render: (value) => {
      if (value !== null) {
        return (
          value &&
          value[0] &&
          value[0].phoneNumber && (
            <div>
              {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
            </div>
          )
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Doctor Name",
    dataIndex: ["doctor"],
    key: "doctor",
    render: (doctor) => {
      if (doctor !== null) {
        return (
          <div>
            Dr. {doctor.firstName} {doctor.lastName}
          </div>
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date) => {
      if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Time",
    dataIndex: "startTime",
    key: "time",
    render: (startTime) => {
      if (startTime !== null)
        return <div>{moment(startTime).format("hh:mm A")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Payment Method",
    dataIndex: ["paymentType"],
    key: "paymentMethod",
    render: (paymentMethod) => {
      if (paymentMethod !== null) return <div>{paymentMethod}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Appointment Status",
    dataIndex: ["status"],
    key: "status",
    render: (status) => {
      if (status !== null) return <div>{status}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Insurance Status",
    dataIndex: "insuranceStatus",
    key: ["insuranceStatus"],
    render: (insuranceStatus) => {
      if (insuranceStatus !== null) return <div>{insuranceStatus}</div>;
      else return <div>-</div>;
    },
  },
  {
    width: 10,
  },
];

export const reportWaitingColumns = (
  history,
  user,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  setUploadNursePatient,
  setNurseUploadReportModalVisible
) => [
  {
    title: "Actions",
    dataIndex: ["patient"],
    fixed: "left",
    render: (row, patient) => {
      return (
        <>
          {user.userType == "PATIENT" ? (
            <StyledButton
              color={theme["@primary-color"]}
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                history.push(
                  `/health-records/${row.id}/${
                    row.firstName + "-" + row.lastName
                  }/${patient.id}`
                );
              }}
            >
              View Details
            </StyledButton>
          ) : (
            <Row>
              <Col>
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(
                      `/health-records/${row.id}/${
                        row.firstName + "-" + row.lastName
                      }/${patient.id}`
                    );
                  }}
                >
                  Call
                </StyledButton>
              </Col>
              <HasPrivilege role="NURSE">
                <Col style={{ marginLeft: "15px" }}>
                  <StyledButton
                    color={theme["@primary-color"]}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log(row, patient);
                      setNurseUploadReportModalVisible(true);
                      setUploadNursePatient(row);
                    }}
                  >
                    Upload
                  </StyledButton>
                </Col>
              </HasPrivilege>
            </Row>
          )}
        </>
      );
    },
  },
  {
    title: "Sl. No",
    dataIndex: "",
    key: "no",
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: "P ID",
    dataIndex: ["patient", "id"],
    key: "id",
  },
  {
    title: "F Name",
    dataIndex: ["patient", "firstName"],
    key: "firstName",
  },
  {
    title: "L Name",
    dataIndex: ["patient", "lastName"],
    key: "lastName",
  },
  {
    title: "Gender",
    dataIndex: ["patient", "gender"],
    key: "gender",
    render: (value) => {
      if (value !== null) return getGender(value);
      else return <div>-</div>;
    },
  },
  {
    title: "Mobile No",
    dataIndex: ["patient", "phoneNumbers"],
    key: "phone",
    render: (value) => {
      if (value !== null) {
        return (
          value &&
          value[0] &&
          value[0].phoneNumber && (
            <div>
              {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
            </div>
          )
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Doctor Name",
    dataIndex: ["doctor"],
    key: "doctor",
    render: (doctor) => {
      if (doctor !== null) {
        return (
          <div>
            Dr. {doctor.firstName} {doctor.lastName}
          </div>
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date) => {
      if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Time",
    dataIndex: "startTime",
    key: "time",
    render: (startTime) => {
      if (startTime !== null)
        return <div>{moment(startTime).format("hh:mm A")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Payment Method",
    dataIndex: ["paymentType"],
    key: "paymentMethod",
    render: (paymentMethod) => {
      if (paymentMethod !== null) return <div>{paymentMethod}</div>;
      else return <div>-</div>;
    },
  },
  {
    width: 10,
  },
];

const renderButtonOrText = (patient) => {
  return null; // default case
};

export const ipdPatientColumns = (
  history,
  user,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  setUploadNursePatient,
  setNurseUploadReportModalVisible,
  setIsModalVisible,
  setselectedItem,
  generateInvoiceIPDFun,
  fetchIPDPatient


) => [
  {
    title: "Actions",
    dataIndex: ["patient"],
    fixed: "left",
    render: (row, patient) => {
      return (
        <>
          {user.userType === "PATIENT" && (
            <StyledButton
              color={theme["@primary-color"]}
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                history.push(
                  `/health-records-ipd/${row.id}/${
                    row.firstName + "-" + row.lastName
                  }/${patient.id}`
                );
              }}
            >
              View Details
            </StyledButton>
          )}

          {user.userType === "RECEPTIONIST" && (
            <>
              {patient.discountStatus === "Not applicable" ||
              patient.discountStatus === "Declined" ? (
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsModalVisible(true);
                    setselectedItem(patient.id);
                    fetchIPDPatient();
                  }}
                >
                  Generate Invoice
                </StyledButton>
              ) : patient.discountStatus === "Pending" ? (
                <p>Pending for Discount Approval</p>
              ) : patient.discountStatus === "Approved" ? (
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setselectedItem(patient.id);
                    generateInvoiceIPDFun(patient.id);
                    fetchIPDPatient();
                  }}
                >
                  Generate Invoice
                </StyledButton>
              ) : (
                {}
              )}
            </>
          )}

          {user.userType !== "PATIENT" && user.userType !== "RECEPTIONIST" && (
            <Row>
              <Col>
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(
                      `/health-records-ipd/${row.id}/${
                        row.firstName + "-" + row.lastName
                      }/${patient.id}`
                    );
                  }}
                >
                  Details
                </StyledButton>
              </Col>
              <HasPrivilege role="NURSE">
                <Col style={{ marginLeft: "15px" }}>
                  <StyledButton
                    color={theme["@primary-color"]}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log(row, patient);
                      setNurseUploadReportModalVisible(true);
                      setUploadNursePatient(row);
                    }}
                  >
                    Upload
                  </StyledButton>
                </Col>
              </HasPrivilege>
            </Row>
          )}
        </>
      );
    },
  },
  {
    title: "Sl. No",
    dataIndex: "",
    key: "no",
    render: (text, record, index) => {
      return pageNo * pageSize + index + 1;
    },
  },
  {
    title: "P ID",
    dataIndex: ["patient", "id"],
    key: "id",
  },
  {
    title: "F Name",
    dataIndex: ["patient", "firstName"],
    key: "firstName",
  },
  {
    title: "L Name",
    dataIndex: ["patient", "lastName"],
    key: "lastName",
  },
  {
    title: "Gender",
    dataIndex: ["patient", "gender"],
    key: "gender",
    render: (value) => {
      if (value !== null) return getGender(value);
      else return <div>-</div>;
    },
  },
  {
    title: "Mobile No",
    dataIndex: ["patient", "phoneNumbers"],
    key: "phone",
    render: (value) => {
      if (value !== null) {
        return (
          value &&
          value[0] &&
          value[0].phoneNumber && (
            <div>
              {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
            </div>
          )
        );
      } else return <div>-</div>;
    },
  },
  {
    title: "Date",
    dataIndex: "admissionTime",
    key: "date",
    render: (date) => {
      if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Time",
    dataIndex: "admissionTime",
    key: "time",
    render: (startTime) => {
      if (startTime !== null)
        return <div>{moment(startTime).format("hh:mm A")}</div>;
      else return <div>-</div>;
    },
  },
  {
    title: "Payment Method",
    dataIndex: ["paymentType"],
    key: "paymentMethod",
    render: (paymentMethod) => {
      if (paymentMethod !== null) return <div>{paymentMethod}</div>;
      else return <div>-</div>;
    },
  },
  {
    width: 10,
  },
];
