export const ADD_SECTION = "ADD_SECTION";
export const CLEAR_SECTION = "CLEAR_SECTION";
export const OBSERVATION_INFO = "OBSERVATION_INFO";

export const addSection = (data) => {
  return {
    type: ADD_SECTION,
    payload: data,
  };
};
export const clearSection = () => {
  return {
    type: CLEAR_SECTION,
  };
};

export const setObservationInfo = (data) => {
  return {
    type: OBSERVATION_INFO,
    payload: data,
  };
};
