import {
  ADD_PATIENT,
  CLEAR_PATIENT,
  SET_ACTIVE_COUNT,
} from "../actions/patient";

export const patientReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PATIENT:
      return action.payload;
    case SET_ACTIVE_COUNT:
      return { ...state, ...action.payload };
    case CLEAR_PATIENT:
      return {};
    default:
      return state;
  }
};
