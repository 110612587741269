import React, { useState, useEffect } from 'react';
import Icon, {
  LeftCircleOutlined,
  UploadOutlined,
  CameraOutlined,
} from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import {
  Col,
  Row,
  Upload,
  Form,
  Button,
  Divider,
  Empty,
  message,
  Input,
  Collapse,
} from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import styled from 'styled-components';
import FormBuilder from '../../utils/FormBuilder';
import moment from 'moment';
import {
  config as personalDetailsConfig,
  documenType,
  paymentType,
  insuranceFields as insuranceTypes,
  copayOrSelf,
  addresses,
  nextOfKinType,
  staticDocumentType,
  phoneNumberType,
  deathStatus,
  optionalDetailsFields,
} from './formConfig';
import CameraModal from './CameraModal';
import MessageAllModal from '../Appoinment/MessageModal';
import { getCountries } from '../../../apis/publicApis';
import { ReactComponent as Avatar } from '../../../assets/icons/image-upload-avatar.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/SettingsIcon.svg';
import {
  createPatient,
  getOneByID,
  updatePatient,
  fetchPatientForms,
} from '../../../apis/patients';
import { fetchFormByType, fetchFormFieldMeta } from '../../../apis/forms';
import {
  FormContainer,
  ImageUploadContainer,
  ImageContainer,
  RightButton,
  StyledContent,
  StyledEditIcon,
  StyledButton,
} from './styledComponents';

import {
  generateFormFields,
  deserialize,
  serialize,
  serializeInsurance,
} from '../../utils/FormBuilder/valuesSerialisation';
import { uploadImage } from '../../../apis/uploadImages';
import { withPrivilege } from '../../utils/privilegeHandlers/hoc/withPrivilege';
import { PROFILE_IMAGE_PREFIX } from '../../../constants/StringConstants';
import { fetchInsuranceProviders } from '../../../apis/Insurance';
import { useSelector } from 'react-redux';
import { DocumentUpload } from './DocumentUpload';
import { idType } from '../../Datasets/basicDetails';
import { deleteUploadedFile } from '../../../apis/uploadPdfOrImage';
import { MailOutlined } from '@ant-design/icons';
import MessageSinglePersonModal from '../Appoinment/MessageSinglePerson';
import AdmitPatientModal from '../Appoinment/AdmitPatient';

const { Panel } = Collapse;
const PatientForm = () => {
  const history = useHistory();
  const user = useSelector((state) => state.userReducer);
  const { pId: patientId, pName: patientFirstName } = useParams();

  const location = useLocation();
  // const values = queryString.parse(location.search)
  console.log(location) // "top"

  const [formConfig, setFormConfig] = useState(personalDetailsConfig);
  // console.log('form config is', formConfig);
  const [copayOrSelfFormConfig, setCopayOrSelfFormConfig] =
    useState(copayOrSelf);
  const [documents, setDocuments] = useState(documenType);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [documentUrls, setDocumentUrls] = useState([]);
  const [identityDocuments, setIdentityDocuments] = useState([]);
  const [address, setAddress] = useState(addresses);
  const [paymentTypes, setPaymentMethods] = useState(paymentType);
  const [profilePic, setProfilePic] = useState();
  const [cameraModalVisible, setCameraModalVisible] = useState(false);
  const [userName, setUserName] = useState(null);
  const [forms, setForm] = useState();
  const [insuranceFields, setInsuranceFields] = useState(insuranceTypes);
  const [dynamicDocumentTypes, setDynamicDocumentTypes] = useState([]);
  const [dynamicAddressTypes, setDynamicAddressTypes] = useState([]);
  const [isEdited, setIsEdited] = useState(true);
  const [form] = Form.useForm();
  const [formInitialValues, setFormInitialValues] = useState({});
  const [generatedPatientId, setGeneratedPatientId] = useState();
  const [generatedPatientName, setGeneratedPatientName] = useState();
  const [profilePicURL, setProfilePicURL] = useState();
  const [countries, setCountries] = useState();
  const [insuranceProviders, setInsuranceProviders] = useState([]);
  const theme = useSelector((state) => state.themeReducer);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [validFormats, setValidFormats] = useState(false);
  const [isMultiple, setIsMultiple] = useState(true);
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const [showAdditionalInfo, setshowAdditionalInfo] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [
    consultationHistoryButtonVisible,
    setConsultationHistoryButtonVisible,
  ] = useState(true);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  //firstname and phonenumber for message singleperson modal
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [patientAdmitType, setPatientAdmitType] = useState('opd');
  const [patientAdmitModalVisible, setPatientAdmitModalVisible] = useState(false);
  const uploadProps = {
    beforeUpload: (file) => {
      setProfilePic(file);
      return false;
    },
    showUploadList: false,
  };

  // const redirectToConsultationHistory = () => {
  //   setConsultationHistoryButtonVisible(false);
  //   menuChangeHandler({ key: menuItems.length - 1 });
  // };

  const fetchPatient = () => {
    getOneByID(patientId)
      .then(({ status, data }) => {
        if (status == 200) {
          if (data.status == 1) {
            let body = data.body;
            // console.log('body of patient detail fetching', data.body);
            body.bloodGroup = body.bloodGroup ? body.bloodGroup.id : null;
            body.dob = moment(body.dob);
            body.age = moment().diff(body.dob, 'years', false);

            // body.documents = {};
            // body.insurance = {};
            let documentData = {};
            let documentsTemp = [];

            // fetchPatientForms(patientId)
            //   .then((response) => {
            //     console.log(
            //       "response for fetching",
            //       response.data.body,
            //       response.data.body.additionalInfo.alternateContactDetails
            //     );
            //     if (response.data.status === 1) {
            //       documentData = response.data.body;

            //       // preprocessing dynamic document forms data

            //       documentsTemp = documentData.documents.map((document) => {
            //         let fields = {};
            //         document.sampleFormFieldDataList.forEach((field) => {
            //           fields = { ...fields, ...serializeInsurance(field) };
            //         });
            //         return {
            //           type: document.documentData.document.formName,
            //           ...fields,
            //         };
            //       });
            //       body.documents = documentsTemp;
            //       console.log(
            //         "documents fetched",
            //         documentsTemp,
            //         body.documents
            //       );

            //       if (body.profilePic) {
            //         setProfilePicURL(PROFILE_IMAGE_PREFIX + body.profilePic);
            //       }
            //       // setFormInitialValues(body);
            //       setUserName(`${data.body.firstName} ${data.body.lastName}`);
            //     } else {
            //       message.error("Could not fetch forms, Try again");
            //     }
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //     message.error("Could not fetch forms, Try again");
            //   });

            setIdentityDocuments(body.identityDocuments);
            setProfilePicURL(body.profilePic);

            //for getting phonenumber and firstname for message modal
            if (body.phoneNumbers.length > 0) {
              setPhoneNumber(
                body.phoneNumbers[0].countryCode.split(' ')[0] +
                  body.phoneNumbers[0].phoneNumber
              );
            }
            setFirstName(body.firstName);

            form.setFieldsValue({
              firstName: body.firstName,
              lastName: body.lastName,
              gender: body.gender,
              dob: moment(body.dob),
              age: moment().diff(body.dob, 'years', false),
              bloodGroup: body.bloodGroup ? body.bloodGroup : '',
              maritalStatus: body.additionalInfo?.maritalStatus
                ? body.additionalInfo?.maritalStatus
                : 'Single',
              race: body.additionalInfo?.race ? body.additionalInfo?.race : '',
              religion: body.additionalInfo?.religion
                ? body.additionalInfo?.religion
                : '',
              nationality: body.additionalInfo?.nationality
                ? body.additionalInfo?.nationality
                : '',
              citizenship: body.additionalInfo?.citizenship
                ? body.additionalInfo?.citizenship
                : '',
              primaryLanguage: body.additionalInfo?.primaryLanguage
                ? body.additionalInfo?.primaryLanguage
                : '',
              emailType: body.additionalInfo?.emailType
                ? body.additionalInfo?.emailType
                : '',
              mobileNo: body.mobileNo ? body.mobileNo : '',
              email: body.email ? body.email : '',
              phoneNumbers: body.phoneNumbers.map((number) => {
                return {
                  phoneType: number.phoneType,
                  phoneNumber: {
                    countryCode: number.countryCode,
                    phoneNumber: number.phoneNumber,
                  },
                };
              }),
              deathStatus: body.additionalInfo?.deathStatus ? 'Yes' : 'No',
              deathDate: body.additionalInfo?.deathDate
                ? moment(body.additionalInfo?.deathDate)
                : undefined,
              alternateContactDetails:
                body.additionalInfo?.alternateContactDetails,
              addresses: body.addresses.map((item) => {
                if (item.id) delete item.id;
                return item;
              }),
              identityDocuments: body.identityDocuments.map((doc) => {
                return {
                  documentType: doc.documentType,
                  number: doc.number,
                  expiryDate: moment(doc.expiryDate),
                  documentUrl: doc.documentUrl,
                  documentUrls: doc.documentUrls,
                };
              }),
            });

            // set country code
          } else {
            message.error('Unable to fetch Patient');
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        message.error('Unable to fetch Patient----------');
      });
  };

  //when form values changes
  const onFormValueChange = (changed, all) => {
    var status =
      (changed.firstName && changed.firstName.length > 0) ||
      (changed.lastName && changed.lastName.length > 0)
        ? 'true'
        : 'false';

    if (changed.dob) {
      form.setFieldsValue({ age: moment().diff(changed.dob, 'years', false) });
    }
    if (!all.dob) {
      form.setFieldsValue({ age: undefined });
    }
    if (changed.nationality) {
      // formConfig.find(
      //   (item) => item.name === "mobileNo"
      // ).prefix = "+91";
      setFormConfig(formConfig.map((item) => ({ ...item })));
    }
    setIsEdited(true);
    if (status) {
      const name = [
        all.firstName && all.firstName,
        all.lastName && all.lastName,
      ].join(' ');
      setUserName(status ? name : '');
    }
    if (changed.firstName || changed.lastName) {
      const name = [
        all.firstName && all.firstName,
        all.lastName && all.lastName,
      ].join(' ');
      // setUserName(status ? name : "");
    }

    if (changed.documents) {
      const index = changed.documents.findIndex((item) => item !== undefined);

      if (
        index !== -1 &&
        changed.documents[index] &&
        changed.documents[index].type
      ) {
        let temp = generateFormFields(
          forms.security.find(
            (type) =>
              type.formName ===
              (changed.documents[index] && changed.documents[index].type)
          ).fields,
          undefined
        );
        dynamicDocumentTypes[index] = temp;
        setDynamicDocumentTypes([...dynamicDocumentTypes]);
      }
    }
  };

  useEffect(() => {
    onFormValueChange(formInitialValues, formInitialValues);
  }, [formInitialValues]);

  const clearFiles = () => {
    documentUrl.map((item) => {
      deleteUploadedFile(item?.documentUrl)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 1) {
            }
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    });
  };

  useEffect(() => {
    if (location?.state) {
      var data = location?.state?.data;
      var dob;
      if (data?.dob) {
        if (data.dob.includes('/')) {
          dob = data.dob.split('/');
          if (dob[2].length == 4) {
            dob = dob.reverse();
            dob = dob.join('-');
          } else {
            dob = dob.join('-');
          }
        }
      }
      dob = moment(dob);
      form.setFieldsValue({
        identityDocuments: [
          {
            documentType: idType().filter((item) => {
              if (data.id_type == item.text) {
                return item.code;
              }
            })[0]?.code,
            number: data.id_no,
            documentUrls: data.documentUrls,
          },
        ],
        phoneNumbers: [
          {
            phoneNumber: {
              phoneNumber: data.phone_no,
            },
          },
        ],
        firstName: data.name,
        age: data.age
          ? data.age
          : data.dob && moment().diff(moment(dob), 'years', false),
        gender: data.sex
          ?.split(' ')
          ?.map((item) => item.charAt(0))
          ?.join(''),
        dob: data.dob && moment(dob),
        nationality: data.nationality,
      });
    }
    if(location?.search){
      var search = location?.search;
      var arg = search.split('?type=');
      setPatientAdmitType(arg[1])
    }
  }, [location]);

  //regenerating dynamic form fields in case of update
  useEffect(() => {
    // console.log('values', formInitialValues);
    if (Object.keys(formInitialValues).length > 0) {
      //documents fields
      if (
        Object.keys(formInitialValues).length > 0 &&
        formInitialValues.documents &&
        formInitialValues.documents.length
      ) {
        setDynamicDocumentTypes(
          formInitialValues.documents.map((doc) => {
            return generateFormFields(
              forms.security.find((type) => type.formName === doc.type).fields,
              undefined
            );
          })
        );
      }
    } else if (location?.state) {
      form.setFieldsValue({
        addresses: [{}],
        alternateContactDetails: [{}],
      });
    } else {
      form.setFieldsValue({
        addresses: [{}],
        identityDocuments: [{}],
        alternateContactDetails: [{}],
        phoneNumbers: [{}],
      });
    }
  }, [formInitialValues]);

  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res.data);
        // formConfig.find(
        //   (item) => Array.isArray(item.name) && item.name[1] === "country"
        // ).meta = res.data.map((country) => country.name);
        setFormConfig(formConfig.map((item) => ({ ...item })));
        nextOfKinType.find((item) => item.name === 'phoneNumber').meta =
          res.data.map((country) => ({
            code: country.callingCodes[0],
            name: country.name,
          }));
        phoneNumberType.find((item) => item.name === 'phoneNumber').meta =
          res.data.map((country) => ({
            code: country.callingCodes[0],
            name: country.name,
          }));
        let indices = [];
        let fields = formConfig
          .filter((item, index) => {
            if (item.inputType === 'phoneNumber') {
              indices.push(index);
              return true;
            }
            return false;
          })
          .map((item) => ({
            ...item,
            meta: res.data.map((country) => ({
              code: country.callingCodes[0],
              name: country.name,
            })),
          }));
        // console.log('index', indices);
        indices.forEach((item, index) => {
          formConfig[item] = fields[index];
        });
        setFormConfig(formConfig.map((item) => ({ ...item })));
      })
      .catch(console.err);

    fetchInsuranceProviders()
      .then((resp) => {
        setInsuranceProviders(resp.data.body);
        console.log(resp.data.body);
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });

    //fetching blood group
    fetchFormFieldMeta('BLOOD_GROUP')
      .then((res) => {
        if (res.data.status === 1) {
          const bloodGrpData = res.data.body.map((eachItem) => ({
            code: eachItem.id,
            text: eachItem.value,
          }));
          formConfig.find((item) => item.name == 'bloodGroup').meta =
            bloodGrpData;
          setFormConfig([...formConfig]);
          console.log(bloodGrpData);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });

    // fetching dynamic forms

    const dynamicFormRequest = ['SECURITY'].map((item) =>
      fetchFormByType(item)
    );

    Promise.all(dynamicFormRequest)
      .then((values) => {
        console.log('values', values);
        setForm({
          security: values[0].data.body,
        });
        if (patientId) {
          fetchPatient();
        } else {
          form.setFieldsValue({ documents: [{}] });
          setDisabled(false);
        }
      })
      .catch((err) => {
        message.error('Unable to fetch document types and insurance forms');
      });
  }, []);

  //setting documents and insurance types
  useEffect(() => {
    if (forms) {
      documents[1].meta = forms.security.map((item) => item.formName);
      setDocuments(documents);
      // console.log('document in useseffect', documents);
    }
    // console.log('forms', forms);
  }, [forms]);

  const handleDocUpload = (doc) => {
    setDocumentUrls([...documentUrls, doc]);
    console.log('doc list in form', [...documentUrls, doc]);
  };

  const onChange = (key) => {
    if (key.includes('3')) {
      setshowAdditionalInfo(true);
    } else {
      setshowAdditionalInfo(false);
    }
  };

  //On form finish
  const onFinish = async (values) => {
    if (values.documents)
      values.documents = values.documents.map((item) => {
        return {
          formId: forms.security.find((form) => form.formName === item.type).id,
          fields: deserialize(item),
        };
      });
    if (profilePic) {
      let image = await uploadImage(null, profilePic);
      values.profilePic = image.data.body;
    } else {
      if (profilePicURL) {
        let proPic = profilePicURL.replace(PROFILE_IMAGE_PREFIX, '');
        values.profilePic = proPic;
      }
    }
    values.password = 'abcd';
    values.gender = values.gender.toUpperCase();
    values.userType = 'PATIENT';
    values.roleType = 'ROLE_PATIENT';

    const additionalInformation = {
      alternateContactDetails: values.alternateContactDetails,
      phoneType: values.phoneType,
      citizenship: values.citizenship,
      emailType: values.emailType,
      id: 0,
      maritalStatus: values.maritalStatus,
      nationality: values.nationality,
      primaryLanguage: values.primaryLanguage,
      race: values.race,
      religion: values.religion,
      deathStatus: values.deathStatus === 'Yes' ? true : false,
      deathDate: values.deathDate,
      // vip: values.vip,
    };

    const payload = {
      additionalInfo: showAdditionalInfo ? additionalInformation : {},
      addresses: values.addresses,
      phoneNumbers: values.phoneNumbers.map((number) => {
        return {
          phoneType: number.phoneType,
          ...{
            countryCode: number.phoneNumber.countryCode,
            phoneNumber: number.phoneNumber.phoneNumber,
          },
        };
      }),
      dob: values.dob,
      identityDocuments: values.identityDocuments,
      email: values.email,
      firstName: values.firstName,
      gender: values.gender,
      lastName: values.lastName,
      mobileNo: values.mobileNo,
      profilePic: values.profilePic,
      nationality: values.nationality,
      bloodGroupId: values.bloodGroup,
    };
    if (!patientId) {
      if (
        values.mobileNo &&
        values.alternateContactNo &&
        values.mobileNo.phoneNumber === values.alternateContactNo.phoneNumber
      ) {
        message.error(
          'Mobile Number and alternate contact phone number cannot be same'
        );
      } else if (
        values.mobileNo &&
        values.guardianPhoneNo &&
        values.mobileNo.phoneNumber === values.guardianPhoneNo.phoneNumber
      ) {
        message.error('Mobile Number and guardian phone number cannot be same');
      } else {
        createPatient(payload)
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status !== 0) {
                setGeneratedPatientId(response.data.body.id);
                setGeneratedPatientName(
                  response.data.body.firstName +
                    ' ' +
                    response.data.body.lastName
                );
                message.success('Patient created!');
                setIsEdited(false);
                if(patientAdmitType === 'ipd'){
                  history.push(`/update-patient/${response.data.body.id}/${response.data.body.firstName}?type=ipd`)
                }
              } else {
                message.error(response.data.body);
              }
            } else {
              message.error('Unable to created Patient!');
            }
          })
          .catch((err) => {
            message.error('Unable to create Patient!');
          });
      }
    } else {
      // console.log('patient id isssss', patientId);
      // console.log('payload is', payload);
      updatePatient(patientId, payload)
        .then((response) => {
          // console.log('response from update is', response);
          if (response.status == 200) {
            if (response.data.status !== 0) {
              setGeneratedPatientId(response.data.body.id);
              setGeneratedPatientName(
                response.data.body.firstName + ' ' + response.data.body.lastName
              );
              message.success('Patient Record Updated');
              setIsEdited(false);
            } else {
              message.error(response.data.body);
            }
          } else {
            message.error('Unable to update Patient!');
            console.log('error here');
          }
        })
        .catch((err) => {
          message.error('Unable to update Patient!');
          console.log('error', err);
        });
    }
  };
  // console.log('phonenUmber us', phoneNumber);

  const ImageUpload = () => {
    return (
      <ImageUploadContainer>
        <Row align="middle" justify="center">
          <Col span={16}>
            <ImageContainer
              bgImage={
                profilePic
                  ? URL.createObjectURL(profilePic)
                  : PROFILE_IMAGE_PREFIX + profilePicURL
              }
              color={theme['@primary-color']}
            >
              {!profilePic && !profilePicURL && (
                <Icon
                  component={Avatar}
                  style={{ fontSize: '70px', margin: 'auto' }}
                />
              )}
            </ImageContainer>
          </Col>
          <Col span={8}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Button
                  icon={<CameraOutlined />}
                  type="primary"
                  onClick={() => setCameraModalVisible(true)}
                  size="small"
                />
              </Col>
              <Col span={24}>
                {' '}
                <ImgCrop rotate>
                  <Upload {...uploadProps}>
                    <Button
                      icon={<UploadOutlined />}
                      type="primary"
                      ghost
                      size="small"
                    />
                  </Upload>
                </ImgCrop>
              </Col>
              {patientId && (
                <Col span={24}>
                  <Button
                    icon={<SettingsIcon />}
                    type="ghost"
                    onClick={() =>
                      history.push(`/patient/reset-password/${patientId}`)
                    }
                    size="small"
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </ImageUploadContainer>
    );
  };
  return (
    <StyledContent>
      <h2>
        <LeftCircleOutlined
          onClick={() => {
            history.push('/dashboard/?tab=4');
          }}
        />
        {'  '}
        {patientId ? (
          <>
            View Details &nbsp;
            <Row
              style={{
                gap: '20px',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <StyledButton
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                color={theme['@primary-color']}
                onClick={() => setDisabled(!disabled)}
              >
                Edit
                <Icon style={{ fontSize: '20px' }} component={StyledEditIcon} />
              </StyledButton>
              <StyledButton
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                color={theme['@primary-color']}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setMessageModalVisible(true);
                }}
              >
                Message
                <Icon style={{ fontSize: '20px' }} component={MailOutlined} />
              </StyledButton>

              {user.userType == 'DOCTOR' && (
                <StyledButton
                  color={theme['@primary-color']}
                  type={'history'}
                  // onClick={redirectToConsultationHistory}
                  onClick={() =>
                    history.push(
                      `/health-records/${patientId}/${patientFirstName}`
                    )
                  }
                >
                  View Consultation History
                </StyledButton>
              )}
              {patientAdmitType === 'ipd' && (
                <StyledButton
                  color={theme['@primary-color']}
                  // type={'history'}
                  // onClick={redirectToConsultationHistory}
                  onClick={(e) =>{
                    e.preventDefault();
                    e.stopPropagation();
                    setPatientAdmitModalVisible(true);
                  }
                  }
                >
                  Admit Patient
                </StyledButton>
              )}
            </Row>
          </>
        ) : (
          'Create Patient'
        )}
      </h2>

      <Row justify="center">
        <Col style={{ textAlign: 'center' }}>
          <ImageUpload />
          <h3>{userName}</h3>
        </Col>
      </Row>
      <FormContainer justify="end" className="px-6">
        <Col span={24}>
          <Form
            layout="vertical"
            onValuesChange={onFormValueChange}
            onFinish={onFinish}
            initialValues={formInitialValues}
            key={Object.keys(formInitialValues).length !== 0}
            form={form}
            className="form-m2-style"
          >
            <FormBuilder
              formConfig={formConfig}
              formInstance={form}
              disabled={disabled}
            />
            <Form.List name="phoneNumbers">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <FormBuilder
                        formConfig={phoneNumberType}
                        index={index}
                        // formInstance={form}
                        disabled={disabled}
                      />
                      {fields.length > 1 && (
                        <Form.Item k>
                          <Divider>
                            <Button
                              onClick={() => {
                                remove(field.name);
                              }}
                              type="danger"
                              ghost
                              size="small"
                            >
                              Remove
                            </Button>
                          </Divider>
                        </Form.Item>
                      )}
                    </div>
                  ))}
                  <Form.Item>
                    {forms && (
                      <RightButton
                        onClick={() => {
                          add();
                        }}
                        type="primary"
                        ghost
                        size="small"
                      >
                        Add New Phone Number
                      </RightButton>
                    )}
                  </Form.Item>
                </div>
              )}
            </Form.List>
            <Collapse
              onChange={onChange}
              style={{
                marginBottom: '25px',
                fontFamily:
                  '"Open Sans", "Helvetica Neue", sans-serif !important',
              }}
            >
              <Panel header="Additional Pesonal Details" key="1">
                <FormBuilder
                  formConfig={optionalDetailsFields}
                  formInstance={form}
                  disabled={disabled}
                />
              </Panel>
              <Panel header="Address Details" key="2">
                <Form.List name="addresses">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <FormBuilder
                            formConfig={addresses}
                            index={index}
                            // formInstance={form}
                            disabled={disabled}
                          />
                          {fields.length > 1 && (
                            <Form.Item>
                              <Divider>
                                <Button
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                  type="danger"
                                  ghost
                                  size="small"
                                >
                                  Remove
                                </Button>
                              </Divider>
                            </Form.Item>
                          )}
                        </div>
                      ))}
                      <Form.Item>
                        {forms && (
                          <RightButton
                            onClick={() => {
                              add();
                            }}
                            type="primary"
                            ghost
                            size="small"
                          >
                            Add New Address
                          </RightButton>
                        )}
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </Panel>
              <Panel header="Next of Kin/Emergency" key="3">
                <Form.List name="alternateContactDetails">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <FormBuilder
                            formConfig={nextOfKinType}
                            index={index}
                            // formInstance={form}
                            disabled={disabled}
                          />
                          {fields.length > 1 && (
                            <Form.Item k>
                              <Divider>
                                <Button
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                  type="danger"
                                  ghost
                                  size="small"
                                >
                                  Remove
                                </Button>
                              </Divider>
                            </Form.Item>
                          )}
                        </div>
                      ))}
                      <Form.Item>
                        {forms && (
                          <RightButton
                            onClick={() => {
                              add();
                            }}
                            type="primary"
                            ghost
                            size="small"
                          >
                            Add New Relationship
                          </RightButton>
                        )}
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </Panel>
              <Panel header="Add Documents" key="4">
                <Form.List name="identityDocuments">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <FormBuilder
                            formConfig={staticDocumentType}
                            index={index}
                            // formInstance={form}
                            disabled={disabled}
                          />
                          <Form.Item
                            name={[index, 'documentUrls']}
                            label="Upload Document"
                            rules={[{ required: false }]}
                            className="not-required"
                          >
                            <DocumentUpload
                              value={documentUrl}
                              onChange={setDocumentUrl}
                              selectedItem={
                                form.getFieldValue('identityDocuments')[index]
                              }
                              deleteSelected={deleteSelected}
                              validFormats={validFormats}
                              isMultiple={isMultiple}
                              setIsSaveChanges={setIsSaveChanges}
                            />
                          </Form.Item>

                          {fields.length > 1 && (
                            <Form.Item k>
                              <Divider>
                                <Button
                                  onClick={() => {
                                    remove(field.name);
                                    form.validateFields();
                                  }}
                                  type="danger"
                                  ghost
                                  size="small"
                                >
                                  Remove
                                </Button>
                              </Divider>
                            </Form.Item>
                          )}
                        </div>
                      ))}
                      <Form.Item>
                        {forms && (
                          <RightButton
                            onClick={() => {
                              add();
                            }}
                            type="primary"
                            ghost
                            size="small"
                          >
                            Add New Document
                          </RightButton>
                        )}
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </Panel>
            </Collapse>

            {patientId && <FormBuilder formConfig={deathStatus} />}

            <Form.Item>
              <RightButton
                type="primary"
                disabled={isEdited}
                onClick={() =>
                  history.push(
                    `/appointment/${generatedPatientId}/${generatedPatientName}`
                  )
                }
              >
                Book a Visit
              </RightButton>
              {isEdited && (
                <RightButton htmlType="submit" type="primary" ghost>
                  {patientId ? 'Update' : 'Save'}
                </RightButton>
              )}
              <RightButton
                type="link"
                onClick={() => {
                  history.goBack();
                  clearFiles();
                }}
              >
                Cancel
              </RightButton>
            </Form.Item>
          </Form>
        </Col>
      </FormContainer>
      <CameraModal
        visible={cameraModalVisible}
        setVisible={setCameraModalVisible}
        setProfilePic={setProfilePic}
      />
      <MessageSinglePersonModal
        visible={messageModalVisible}
        closeModal={() => setMessageModalVisible(false)}
        cancelLoading={cancelLoading}
        phoneNumber={phoneNumber}
        firstName={firstName}
        patientId={patientId}
      />
      <AdmitPatientModal
        visible={patientAdmitModalVisible}
        closeModal={() => setPatientAdmitModalVisible(false)}
        cancelLoading={cancelLoading}
        phoneNumber={phoneNumber}
        firstName={firstName}
        patientId={patientId}
      />
    </StyledContent>
  );
};

export default withPrivilege(PatientForm, 'ADD_PATIENT');
