import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Button, Form, message, Row, Col, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/Successful-icon.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/CancelIcon.svg";
import { useHistory } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { getHospitalId } from "../../utils/LocalStorage/index";
import {
  getDepartments,
  getDoctors,
  getWards,
  getBeds,
  getAdmitPatient,
  getWardsCategoryNonOT,
  getNurses,
} from "../../../apis/Appointment";

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

const ModalContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledCancelIcon = styled(MailOutlined)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledP = styled.p`
  font-size: 15px;
`;

const StyledPTitle = styled.p`
  font-size: 28px;
  font-family: bold;
  color: #56aef8;
`;

const StyledRow = styled(Row)`
  padding: 24px;
`;

const AdmitPatientModal = ({
  visible,
  closeModal,
  phoneNumber,
  firstName,
  patientId,
}) => {
  const history = useHistory();

  const [departments, setDepartments] = useState([]);
  const [wards, setWards] = useState([]);
  const [wardsCategory, setWardsCategory] = useState([]);
  const [beds, setBeds] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [admitPatient, setAdmitPatient] = useState([]);
  const [doctorsLoading, setDoctorsLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);

  const [form] = Form.useForm();
  useEffect(() => {
    getDepartments(getHospitalId())
      .then((response) => {
        if (response.data.status === 1) {
          setDepartments(response.data.body);
        } else {
          message.error("Could not fetch departments, Try Again");
        }
      })
      .catch((err) => {
        message.error("Could not fetch departments, Try Again");
      });

    getWardsCategoryNonOT()
      .then((response) => {
        console.error(response.data);
        if (response.data) setWardsCategory(response.data);
      })
      .catch((err) => {
        // setDoctorsLoading(false);
        console.log(err);
        message.error("Could not fetch Wards Category List, Try Again");
      });

    getNurses()
      .then((response) => {
        console.error(response.data.body);
        if (response.data) setNurses(response.data.body);
      })
      .catch((err) => {
        console.log(err);
        message.error("Could not fetch Nurses List, Try Again");
      });
  }, []);

  const onSubmit = (values) => {
    // console.log('Form values:', values);
    const data = {
      patientId: patientId,
      bedId: values.bed,
      remark: "Mild cough and cold, Pain in the eyes",
      doctorIds: values.doctor,
      nurseIds: values.nurse,
      timeZone: "Asia/Calcutta",
    };
    getAdmitPatient(data)
      .then((response) => {
        if (response.data.status === 1) {
          message.success("Success");
        } else {
          message.error(response.data.body);
        }
      })
      .catch((err) => {});
  };

  const departmentChange = (departmentId) => {
    getDoctors(departmentId)
      .then((response) => {
        if (response.data.status === 1) {
          setDoctorsLoading(false);
          if (response.data.body) setDoctors(response.data.body);
        } else {
          setDoctorsLoading(false);
          message.error("Could not fetch Doctors List, Try Again");
        }
      })
      .catch((err) => {
        setDoctorsLoading(false);
        console.log(err);
        message.error("Could not fetch Doctors List, Try Again");
      });
  };

  const wardCategoryChange = (wardID) => {
    getWards(wardID)
      .then((response) => {
        // if (response.data) setWards(response.data);
        // if (response.data.status === 1) {
        //   // setDoctorsLoading(false);
        // } else {
        //   // setDoctorsLoading(false);
        //   message.error('Could not fetch Wards List, Try Again');
        // }
      })
      .catch((err) => {
        // setDoctorsLoading(false);
        console.log(err);
        message.error("Could not fetch Doctors List, Try Again");
      });

    getBeds(wardID)
      .then((response) => {
        if (response.data && Array.isArray(response.data)) {
          setBeds(response.data);
        } else {
          setBeds("");
        }
      })
      .catch((err) => {
        // setDoctorsLoading(false);
        console.log(err);
        message.error("Could not fetch Doctors List, Try Again");
      });
  };

  return (
    <StyledModal
      visible={visible}
      closable={true}
      centered
      maskClosable={true}
      footer={null}
      onCancel={() => closeModal()}
    >
      <ModalContentDiv>
        {/* <MailOutlined style={{ width: '60px' }} /> */}
        <StyledPTitle>Admit Patient</StyledPTitle>
        <Form
          layout="vertical"
          onFinish={(values) => onSubmit(values)}
          form={form}
          style={{ width: "100%" }}
          scrollToFirstError={true}
        >
          <Form.Item
            label="Department"
            name="department"
            rules={[{ required: true, message: "Department cannot be empty" }]}
            style={{ width: "100%" }}
          >
            <Select
              size="large"
              placeholder="Select Department"
              //   mode="multiple"
              onChange={(value) => departmentChange(value)}
            >
              {departments &&
                departments.map((department) => {
                  return (
                    <Option value={department.id} key={department.id}>
                      {department.departmentName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="doctor"
            label="Doctor"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              size="large"
              loading={doctorsLoading}
              placeholder="Select Doctor"
              onSelect={(value) => {
                console.log("value of doc", value);
                //   setDocId(value);
              }}
              mode="multiple"
            >
              {doctors &&
                doctors.map((doctor) => {
                  return (
                    <Option value={doctor.id}>
                      {doctor.firstName + " " + doctor.lastName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="nurse" label="Assign Nurse">
            <Select
              size="large"
              placeholder="Select Nurse"
              onSelect={(value) => {}}
              mode="multiple"
            >
              {nurses &&
                nurses.map((nurse) => {
                  return (
                    <Option value={nurse.id}>
                      {nurse.firstName + " " + nurse.lastName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Category Ward"
            name="category_ward"
            rules={[
              { required: true, message: "Ward Category cannot be empty" },
            ]}
            style={{ width: "100%" }}
          >
            <Select
              size="large"
              placeholder="Select Ward Category"
              //   mode="multiple"
              onChange={(value) => wardCategoryChange(value)}
            >
              {wardsCategory &&
                wardsCategory.map((category) => {
                  return (
                    <Option value={category.id} key={category.id}>
                      {category.categoryName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Bed"
            name="bed"
            rules={[{ required: true, message: "Bed cannot be empty" }]}
            style={{ width: "100%" }}
          >
            <Select
              size="large"
              placeholder="Select Bed"
              //   mode="multiple"
              // onChange={(value) => departmentChange(value)}
            >
              {beds &&
                beds.length > 0 &&
                beds.map((bed) => (
                  <Option value={bed.id} key={bed.bedNo}>
                    {bed.bedNo}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Button justify="center" key="ok" type="primary" htmlType="submit">
            Admit Patient
          </Button>
          <></>
        </Form>
      </ModalContentDiv>
    </StyledModal>
  );
};

export default AdmitPatientModal;
