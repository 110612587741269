export const routeOfAdministration = () => [
  {
    code: "AP",
    text: "Apply Externally",
  },
  {
    code: "B",
    text: "Buccal",
  },
  {
    code: "DT",
    text: "Dental",
  },
  {
    code: "EP",
    text: "Epidural",
  },
  {
    code: "ET",
    text: "Endotrachial Tube",
  },
  {
    code: "GTT",
    text: "Gastrostomy Tube",
  },
  {
    code: "GU",
    text: "GU Irrigant",
  },

  {
    code: "IMR",
    text: "Immerse (Soak) Body Part",
  },

  {
    code: "IA",
    text: "Intra-arterial",
  },
  {
    code: "IB",
    text: "Intrabursal",
  },
  {
    code: "IC",
    text: "Intracardiac",
  },
  {
    code: "ICV",
    text: "Intracervical (uterus)",
  },
  {
    code: "ID",
    text: "Intradermal",
  },
  {
    code: "IH",
    text: "Inhalation",
  },
  {
    code: "IHA",
    text: "Intrahepatic Artery",
  },
  {
    code: "IM",
    text: "Intramuscular",
  },

  {
    code: "IN",
    text: "Intranasal",
  },
  {
    code: "IO",
    text: "Intraocular",
  },
  {
    code: "IP",
    text: "Intraperitoneal",
  },
  {
    code: "IS",
    text: "Intrasynovial",
  },
  {
    code: "IT",
    text: "Intrathecal",
  },

  {
    code: "IU",
    text: "Intrauterine",
  },
  {
    code: "IV",
    text: "Intravenous",
  },
  {
    code: "MTH",
    text: "Mouth/Throat",
  },
  {
    code: "MM",
    text: "Mucous Membrane",
  },

  {
    code: "NS",
    text: "Nasal",
  },
  {
    code: "NG",
    text: "Nasogastric",
  },

  {
    code: "NP",
    text: "Nasal Prongs",
  },

  {
    code: "NT",
    text: "Nasotrachial Tube",
  },
  {
    code: "OP",
    text: "Ophthalmic",
  },
  {
    code: "OT",
    text: "Otic",
  },

  {
    code: "OTH",
    text: "Other/Miscellaneous",
  },
  {
    code: "PF",
    text: "Perfusion",
  },
  {
    code: "PO",
    text: "Oral",
  },
  {
    code: "PR",
    text: "Rectal",
  },
  {
    code: "RM",
    text: "Rebreather Mask",
  },
  {
    code: "SD",
    text: "Soaked Dressing",
  },
  {
    code: "SC",
    text: "Subcutaneous",
  },

  {
    code: "SL",
    text: "Sublingual",
  },

  {
    code: "TP",
    text: "Topical",
  },
  {
    code: "TRA",
    text: "Tracheostomy",
  },
  {
    code: "TD",
    text: "Transdermal",
  },
  {
    code: "TL",
    text: "Translingual",
  },
  {
    code: "UR",
    text: "Urethral",
  },
  {
    code: "VG",
    text: "Vaginal",
  },
  {
    code: "VM",
    text: "Ventimask",
  },
  {
    code: "WND",
    text: "Wound",
  },

  {
    code: "IVESIC",
    text: "Intravesicular",
  },
  {
    code: "ICAUD",
    text: "Intracaudal",
  },
  {
    code: "IARTIC",
    text: "Intra-articular",
  },
  {
    code: "IBIL",
    text: "Intra-biliary",
  },
  {
    code: "ICAV",
    text: "Intra-cavernoasal",
  },

  {
    code: "ICERE",
    text: "Intracerebral",
  },
  {
    code: "ILYMPH",
    text: "Intralymphatic",
  },
  {
    code: "IPLEUR",
    text: "Intrapleural",
  },
  {
    code: "RB",
    text: "Retrobulbar",
  },

  {
    code: "PN",
    text: "Perineural",
  },
  {
    code: "SM",
    text: "Submucosal",
  },

  {
    code: "NA",
    text: "Not Available",
  },
];
