import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { Row, Col } from "antd";
import {
  HospitalAdminMenu,
  HospitalAdminMenuItem,
  StyledIcon,
} from "./styledComponents";
import { sidebarConfig as sideBarDetails } from "./sideMenuConfig";

import DashboardRouter from "./DashboardRouter";
import { useSelector } from "react-redux";
const AdminDashboard = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const theme = useSelector((state) => state.themeReducer);
  const user = useSelector((state) => state.userReducer);

  const [selectedSection, setSelectedSection] = useState({});
  const [activeItemKey, setActiveItemKey] = useState(0);
  const [sidebarConfig, setSidebarConfig] = useState(
    sideBarDetails.filter((item) => user.roles.includes(item.privilege))
  );
  const menuChangeHandler = ({ key }, some) => {
    setSelectedSection(sidebarConfig[key]);
    setActiveItemKey(key);
    history.push(`${url}/${sidebarConfig[key].path}`);
  };
  useEffect(() => {
    let paths = location.pathname.split("/");

    if (
      paths[paths.length - 1] &&
      paths[paths.length - 1] === "admin-dashboard"
    ) {
      setActiveItemKey(0);
      setSelectedSection(sidebarConfig[0]);
      history.push(`${url}/${sidebarConfig[0].path}`);
    } else {
      let activeIndex = sidebarConfig.findIndex((item) => {
        return paths[paths.length - 1] == item.path;
      });
      if (activeIndex > -1) {
        setActiveItemKey(activeIndex);
        setSelectedSection(sidebarConfig[activeIndex]);
      }
    }
  }, []);
  return (
    <Row gutter={36}>
      <Col span={5} style={{ padding: "0" }}>
        <HospitalAdminMenu
          mode="vertical"
          selectedKeys={[activeItemKey + ""]}
          onSelect={menuChangeHandler}
        >
          {sidebarConfig.map(({ name, icon }, i) => (
            <HospitalAdminMenuItem
              key={i}
              icon={
                <StyledIcon
                  component={icon}
                  isactive={name === selectedSection.name ? 1 : 0}
                  iconColor={theme["@primary-color"]}
                />
              }
            >
              {name}
            </HospitalAdminMenuItem>
          ))}
        </HospitalAdminMenu>
      </Col>
      <Col span={19}>
        <DashboardRouter />
      </Col>
    </Row>
  );
};

export default AdminDashboard;
