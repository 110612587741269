import React, { useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { BlueBgTable } from "../styledComponents";
import { EmptyMessageContainer } from "../styledComponents";
import { Empty } from "antd";
import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import Spinner from "../../../utils/Spinner";
const RNDContext = createDndContext(HTML5Backend);
// import { columns } from "./tableConfig";
const type = "DragableBodyRow";
const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const SectionManagementTable = ({
  dataSource,
  loading,
  setEditModal,
  setselectedItem,
  setDeleteModal,
  columns,
  setDataSource,
  draggable,
  preventLast,
}) => {
  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (preventLast) {
        if (
          dragIndex !== dataSource.length - 1 &&
          hoverIndex !== dataSource.length - 1
        ) {
          const dragRow = dataSource[dragIndex];
          setDataSource(
            update(dataSource, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
              ],
            })
          );
        }
      } else {
        const dragRow = dataSource[dragIndex];
        setDataSource(
          update(dataSource, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          })
        );
      }
    },
    [dataSource]
  );
  const manager = useRef(RNDContext);

  const theme = useSelector((state) => state.themeReducer);
  return loading ? (
    <Spinner height={"100px"} />
  ) : dataSource.length > 0 ? (
    draggable ? (
      <DndProvider manager={manager.current.dragDropManager}>
        <BlueBgTable
          // headBg={theme["@primary-color"]}
          loading={loading}
          columns={columns}
          dataSource={dataSource}
       
          // pagination={{ pageSize: 15 }}
          pagination={false}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>
    ) : (
      <BlueBgTable
        // headBg={theme["@primary-color"]}
        loading={loading}
        columns={columns}
        dataSource={dataSource}
     
        pagination={false}
      />
    )
  ) : (
    <EmptyMessageContainer>
      <Empty />
    </EmptyMessageContainer>
  );
};
export default SectionManagementTable;
