import { combineReducers } from 'redux';
import { createStore } from 'redux';
import { userReducer } from './reducers/userReducer';
import { patientReducer } from './reducers/patientReducer';
import {
  sectionReducer,
  observationReducer,
  examinationObservationReducer,
} from './reducers/sectionReducer';
import { themeReducer } from './reducers/themReducer';
import { imageReducer } from './reducers/imageReducer';
import { activeCountReducer } from './reducers/activeCountReducer';
import { settingsReducer } from './reducers/settingsReducer';
import { newPatientReducer } from './reducers/newPatientReducer';

const rootReducer = combineReducers({
  userReducer,
  patientReducer,
  sectionReducer,
  observationReducer,
  examinationObservationReducer,
  themeReducer,
  imageReducer,
  activeCountReducer,
  settingsReducer,
  newPatientReducer,
});
const store = createStore(rootReducer);
export default store;
