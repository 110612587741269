import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Form,
  Radio,
  Button,
  Select,
  Checkbox,
  message,
  Divider,
  InputNumber,
} from 'antd';
import CustomModal from '../../../utils/CustomModal.jsx/index.jsx';
import {
  ModalBodyContainer,
  StyledTitle,
  StyledForm,
  StyledBodyTitle,
  NewFieldScrollContainer,
} from './styledComponents';
import {
  CaretDownOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { addNewFormfield } from '../../../../apis/healthRecord.js';
import { fetchFormFieldMeta } from '../../../../apis/forms';
import { useSelector } from 'react-redux';

const InputTypes = [
  { name: 'Input Text', type: 'TEXT' },
  { name: 'Dropdown', type: 'DROPDOWN' },
  { name: 'Radio Button', type: 'RADIO' },
  // { name: "Date Picker", type: "DATE" },
  { name: 'Input Number', type: 'NUMBER' },
  {
    name: 'Multi Select DropDown',
    type: 'MULTI_SELECT_DROP_DOWN',
  },
  { name: 'Text Area', type: 'TEXT_AREA' },
  { name: 'Checkbox', type: 'CHECKBOX' },
];

const InputContainerSize = [
  { name: 'Full width', size: 'FULL' },
  { name: 'Half width', size: 'HALF' },
];
// const { InputNumber } = Input;

const typesWithDropdown = [
  'DROPDOWN',
  'RADIO',
  'MULTI_SELECT_DROP_DOWN',
  'CHECKBOX',
];
const city = ['a', 'b', 'c'];
const typesofCheckbox = ['CHECKBOX'];
const ModalBody = ({
  setVisible,
  selectedForm,
  refetch,
  selectedItem,
  total,
  allFields,
}) => {
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState('');
  const [loading, setLoading] = useState(false);
  const [dropDownFields, setDropDownFields] = useState([]);
  const [parentValues, setParentValues] = useState([]);
  const [isRelated, setIsRelated] = useState(false);
  const [hasValidations, setHasValidations] = useState(false);

  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);
  const generateSubDropDownValues = (dropDownValues, parentId) => {
    console.log('dropdwn', dropDownValues);
    if (dropDownValues.length > 0) {
      if (parentId) {
        let parentIds = [
          ...new Set(dropDownValues.map((item) => item.parentFieldId)),
        ];

        return parentIds.map((id) => {
          // TODO: filter out the fields that are no longer exist in parentField
          return {
            parentValueId: id,
            values: dropDownValues
              .filter((field) => field.parentFieldId === id)
              .map((item) => item.value),
          };
        });
      } else {
        return [{ values: dropDownValues.map((item) => item.value) }];
      }
    }
  };

  const addNew = (data) => {
    setLoading(true);
    addNewFormfield(data)
      .then(({ data, status }) => {
        console.log('data in api addnew', data);
        if (status === 200) {
          if (data.status == 1) {
            message.success('Created');
            setVisible(false);
            refetch();
          } else {
            message.error(data.body);
          }
          //   setSelectedItem(undefined);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
    console.log('data in addnew', data);
  };

  const onFormValuesChange = (changed) => {
    console.log('change', changed);
    if (changed.fieldType) {
      setSelectedType(changed.fieldType);
      if (changed.related !== true) form.setFieldsValue({ related: false });

      if (typesWithDropdown.includes(changed.fieldType)) {
        if (!changed.dropDownValues)
          form.setFieldsValue({ dropDownValues: [{ values: [''] }] });
      }
    }
    if (changed.related == true || changed.related == false) {
      setIsRelated(changed.related);
    }
    if (changed.parentFieldId) {
      let field = dropDownFields.find(
        (item) => item.id == changed.parentFieldId
      );
      if (field) {
        setParentValues(field.dropDownValues);
      }
    }
    if (changed.hasValidation !== undefined && changed.hasValidation !== null) {
      setHasValidations(changed.hasValidation);
    }
  };
  const onFinish = (values) => {
    console.log('values are', values);
    values.editable = true;
    if (!values.order) values.order = total + 1;
    if (selectedForm) {
      values.subSectionId = selectedForm.id;
    }
    if (selectedItem) {
      values.formFieldId = selectedItem.id;
    }
    if (selectedItem) {
      values.dropDownType = selectedItem.dropDownType
        ? selectedItem.dropDownType.dropDownType
        : null;
    }
    addNew(values);
  };

  useEffect(() => {
    setDropDownFields(
      allFields.filter((item) => {
        return typesWithDropdown.includes(item.fieldType);
      })
    );
  }, [allFields]);

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue({
        ...selectedItem,
        name: selectedItem.formName,
        mandatory: selectedItem.required,
        related: selectedItem.parentFieldId ? true : false,
        dropDownValues: generateSubDropDownValues(
          selectedItem.dropDownValues,
          selectedItem.parentFieldId
        ),
      });
      onFormValuesChange({
        ...selectedItem,
        name: selectedItem.formName,
        mandatory: selectedItem.required,
        related: selectedItem.parentFieldId ? true : false,
        dropDownValues: generateSubDropDownValues(
          selectedItem.dropDownValues,
          selectedItem.parentFieldId
        ),
      });

      if (selectedItem.softValidator.length) {
        setHasValidations(true);
        let sofValidator = { hasValidation: true };
        let minItem = selectedItem.softValidator.find(
          (item) => item.validator == 'LESS_THAN'
        );
        if (minItem) {
          sofValidator.minValue = minItem.value;
        }
        let maxItem = selectedItem.softValidator.find(
          (item) => item.validator == 'GREATER_THAN'
        );
        if (maxItem) {
          sofValidator.maxValue = maxItem.value;
        }
        form.setFieldsValue(sofValidator);
      } else {
        form.setFieldsValue({ hasValidation: false });
      }
    } else {
      form.setFieldsValue({ hasValidation: false });
    }
  }, [dropDownFields]);

  return (
    <ModalBodyContainer>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <StyledTitle level={3} color={theme['@primary-color']}>
            {!selectedItem ? 'Create New Field' : 'Update Field'}
          </StyledTitle>
        </Col>
        <Col span={24}>
          <StyledForm
            name="admin-login-form"
            onFinish={onFinish}
            onValuesChange={onFormValuesChange}
            layout="vertical"
            size="large"
            wrapperCol={{ gutter: 36 }}
            form={form}
            scrollToFirstError={true}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Value is Required !',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Type"
              name="fieldType"
              rules={[
                {
                  required: true,
                  message: 'Type is required',
                },
              ]}
            >
              <Select
                size="large"
                suffixIcon={<CaretDownOutlined style={{ color: '#929292' }} />}
              >
                {InputTypes.map((field) => (
                  <Select.Option value={field.type}>{field.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="" name="mandatory" valuePropName={'checked'}>
              <Checkbox>Mandatory</Checkbox>
            </Form.Item>
            <Form.Item
              label={
                <StyledBodyTitle
                  level={4}
                  style={{ fontSize: '15px', marginBottom: '0' }}
                >
                  Input Box Size
                </StyledBodyTitle>
              }
              name="width"
              rules={[
                {
                  required: true,
                  message: 'Size is required',
                },
              ]}
            >
              <Radio.Group>
                {InputContainerSize.map((item) => (
                  <Radio value={item.size}>{item.name}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            {typesWithDropdown.includes(selectedType) && (
              <>
                {selectedType !== 'RADIO' && selectedType !== 'CHECKBOX' && (
                  <Form.Item
                    label={
                      <StyledBodyTitle
                        level={4}
                        style={{ fontSize: '15px', marginBottom: '0' }}
                      >
                        Is related to previous field?
                      </StyledBodyTitle>
                    }
                    name="related"
                    rules={[
                      {
                        required: true,
                        message: 'Size is required',
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
                {(!isRelated ||
                  selectedType == 'RADIO' ||
                  selectedType === 'CHECKBOX') && (
                  <Form.List name={['dropDownValues', 0, 'values']}>
                    {(fields, { add, remove }) => (
                      <div>
                        <Row gutter={[0, 6]} justify="space-between">
                          <Col>Values</Col>
                          <Col>
                            <Button
                              type="primary"
                              ghost
                              size="small"
                              onClick={() => {
                                add();
                              }}
                            >
                              Add New
                            </Button>
                          </Col>
                        </Row>
                        {fields.map((field, index) => (
                          <>
                            <div key={field.key}>
                              <Form.Item
                                // label="Name"
                                name={[index]}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Form name is Required !',
                                  },
                                ]}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                            {fields.length > 1 && (
                              <Divider>
                                <Button
                                  size="small"
                                  danger
                                  onClick={() => remove(index)}
                                >
                                  Remove
                                </Button>
                              </Divider>
                            )}
                          </>
                        ))}
                      </div>
                    )}
                  </Form.List>
                )}

                {isRelated && selectedType !== 'RADIO' && (
                  <>
                    <Form.Item
                      label="Select"
                      name="parentFieldId"
                      rules={[
                        {
                          required: true,
                          message: 'Type is required',
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        suffixIcon={
                          <CaretDownOutlined style={{ color: '#929292' }} />
                        }
                      >
                        {dropDownFields.map((field) => (
                          <Select.Option value={field.id}>
                            {field.formName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.List name="dropDownValues">
                      {(fields, { add, remove }) => (
                        <div>
                          <Row gutter={[0, 6]} justify="space-between">
                            <Col>Values</Col>
                            <Col>
                              <Button
                                type="primary"
                                ghost
                                size="small"
                                onClick={() => {
                                  add();
                                }}
                              >
                                Add New
                              </Button>
                            </Col>
                          </Row>
                          <NewFieldScrollContainer>
                            {fields.map((field, index) => (
                              <div key={field.key}>
                                <Form.Item
                                  label="Choose Item"
                                  name={[index, 'parentValueId']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Type is required',
                                    },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    suffixIcon={
                                      <CaretDownOutlined
                                        style={{ color: '#929292' }}
                                      />
                                    }
                                  >
                                    {parentValues.map((field) => (
                                      <Select.Option value={field.id}>
                                        {field.value}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <Form.List name={[index, 'values']}>
                                  {(fields, { add, remove }) => (
                                    <div>
                                      <Row
                                        gutter={[0, 6]}
                                        justify="space-between"
                                      >
                                        <Col>Values</Col>
                                        <Col>
                                          {fields.length < 1 && (
                                            <PlusCircleOutlined
                                              style={{
                                                fontSize: '20px',
                                                marginTop: '10px',
                                              }}
                                              onClick={() => {
                                                add();
                                              }}
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                      {fields.map((field, index) => (
                                        <div key={field.key}>
                                          <Row align="base-line" gutter={6}>
                                            <Col span={20}>
                                              <Form.Item
                                                // label="Name"
                                                name={[index]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      'Form name is Required !',
                                                  },
                                                ]}
                                              >
                                                <Input size="large" />
                                              </Form.Item>
                                            </Col>
                                            {fields.length > 1 && (
                                              <Col span={2}>
                                                <DeleteOutlined
                                                  style={{
                                                    color: 'red',
                                                    fontSize: '20px',
                                                    marginTop: '10px',
                                                  }}
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                />
                                              </Col>
                                            )}
                                            {fields.length == index + 1 && (
                                              <Col span={2}>
                                                <PlusCircleOutlined
                                                  style={{
                                                    fontSize: '20px',
                                                    marginTop: '10px',
                                                  }}
                                                  onClick={() => {
                                                    add();
                                                  }}
                                                />
                                              </Col>
                                            )}
                                          </Row>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </Form.List>
                                {fields.length > 1 && (
                                  <Divider>
                                    <Button
                                      size="small"
                                      danger
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </Button>
                                  </Divider>
                                )}
                              </div>
                            ))}
                          </NewFieldScrollContainer>
                        </div>
                      )}
                    </Form.List>
                  </>
                )}
              </>
            )}

            {selectedType === 'NUMBER' && (
              <>
                <Form.Item
                  label="Need to validate ?"
                  name="hasValidation"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                {hasValidations && (
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item label="Min Value" name="minValue">
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Max Value" name="maxValue">
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </>
            )}
            {/* {selectedType == 'CHECKBOX' &&
              city.map((option) => (
                <div key={option}>
                  <label>
                    <input
                      type="checkbox"
                      style={{ margin: '5px' }}
                      // onChange={() => handleCheckboxChange(option)}
                    />
                    {option}
                  </label>

                  <input
                    placeholder="Enter value"
                    maxLength={2}
                    style={{
                      marginLeft: '10px',
                      width: '40px',
                      height: '20px',
                    }}
                    // onChange={(e) => handleInputChange(e, option)}
                  />
                </div>
              ))} */}
            <Form.Item>
              <Row gutter={12} justify="end">
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    ghost
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    // loading={loading}
                    htmlType="submit"
                  >
                    {!selectedItem ? 'Create' : 'Update'}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </StyledForm>
        </Col>
      </Row>
    </ModalBodyContainer>
  );
};

const NewFieldModal = ({
  visible,
  setVisible,
  selectedForm,
  refetch,
  selectedItem,
  total,
  allFields,
}) => {
  console.log('selected item', selectedItem);
  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBody
        setVisible={setVisible}
        selectedForm={selectedForm}
        refetch={refetch}
        selectedItem={selectedItem}
        total={total}
        allFields={allFields}
      />
    </CustomModal>
  );
};
export default NewFieldModal;
