import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import styled from "styled-components";
// import StyledCol from "../UserManagement/styledComponents";

import {
  StyledRow,
  UploadTitle,
  SectionTtile,
  StyledCheckbox,
  StyledCol,
} from "./styledComponents";
// import { Row, Col, Input, Button,  } from "antd";
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Typography,
  message,
  Select,
  Radio,
  Collapse,
} from "antd";
import {
  addNewWardCategory,
  editWardCategory,
} from "../../../../apis/wardCategory";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getHospitalId } from "../../../utils/LocalStorage/index.js";

const ModalBodyContainer = styled.div`
  width: 450px;
  padding: 10px 30px;
`;
const { Title } = Typography;

const StyledTitle = styled(Title)`
  &.ant-typography,
  &.ant-typography h3 {
    margin-bottom: 0;
    color: ${(props) => props.color};
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    text-align: center;
  }
`;

const NewDocumentModal = ({
  visible,
  setVisible,
  refetch,
  selectedItem,
  setSelectedItem,
}) => {
  const [wardCatName, setWardCatName] = useState("");
  const [wardCatFees, setWardCatFees] = useState("");
  const [loading, setLoading] = useState(false);
  const [isot, isotChecked] = useState(false);

  const theme = useSelector((state) => state.themeReducer);

  const addDepartment = () => {
    setLoading(true);
    if (selectedItem) {
      editWardCategory(
        {
          categoryName: wardCatName,
          charges: wardCatFees,
          isOT: isot
        },
        selectedItem.id
      )
        .then(({ data, status }) => {
          if (status === 200) {
            message.success("Edited");
            refetch();
            setWardCatName("");
            setSelectedItem(undefined);
            setVisible(false);
            //   setSelectedItem(undefined);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      if (wardCatName == "") {
        message.error("Please enter ward category name");
        setLoading(false);
      } else if (wardCatFees == "") {
        message.error("Please enter ward category fees");
        setLoading(false);
      } else {
        addNewWardCategory({
          categoryName: wardCatName,
          isOT: isot,
          charges: wardCatFees
        })
          .then(({ data, status }) => {
            if (status === 200) {
              message.success("Created");
              refetch();
              setWardCatName("");
              setWardCatFees("");
              setSelectedItem(undefined);
              setVisible(false);
              //   setSelectedItem(undefined);
              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  useEffect(() => {
    if (selectedItem){
      setWardCatName(selectedItem.categoryName);
      setWardCatFees(selectedItem.charges);
    } 
  }, [selectedItem]);

  const handleChange = (e) => {
    isotChecked(e.target.checked);
  };

  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBodyContainer>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            {" "}
            <StyledTitle level={3} color={theme["@primary-color"]}>
              {!selectedItem
                ? "Create New Ward Cateory"
                : "Update Ward Cateory"}
            </StyledTitle>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 6]}>
              <Col span={24}>Name</Col>
              <Col span={24}>
                <Input
                  value={wardCatName}
                  size="large"
                  onChange={(event) => {
                    setWardCatName(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 6]}>
              <Col span={24}>Charges</Col>
              <Col span={24}>
                <Input
                  value={wardCatFees}
                  size="large"
                  onChange={(event) => {
                    const inputText = event.target.value.replace(/\D/g, "");
                    setWardCatFees(inputText);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form>
              <Col span={24}>
                <Form.Item name="privilegesId">
                  <StyledCheckbox.Group size="large">
                    <Col span={12} key="test">
                      <StyledCheckbox
                        value="test"
                        checked={isot}
                        onChange={handleChange}
                        size="large"
                      >
                        Is OT?
                      </StyledCheckbox>
                    </Col>
                  </StyledCheckbox.Group>
                </Form.Item>
              </Col>
            </Form>
          </Col>
          <Col span={24}>
            <Row gutter={12} justify="end">
              <Col>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  onClick={() => {
                    setWardCatName("");
                    setSelectedItem(undefined);
                    setVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => {
                    addDepartment();
                  }}
                >
                  {!selectedItem ? "Create" : "Update"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};

export default NewDocumentModal;
