import React from "react";
import { Checkbox, Button, Popconfirm } from "antd";
import Icon, { DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as DownloadOutside } from "../../../../assets/icons/downloadoutside.svg";
import styled from "styled-components";

const StyledButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
`;

export const columns = () => [
  {
    title: "Sl. No",
    dataIndex: "",
    key: "no",
    width: "3vw",
    align: "center",
    render: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "id",
    width: "3vw",
    align: "center",
  },
  {
    title: "Common Test",
    dataIndex: "commonTest",
    key: "id",
    width: "2vw",
  },
  {
    title: "Test Name",
    dataIndex: "testName",
    key: "id",
    width: "4vw",
  },
];


export const tableColumns = ({
  isMain,
  retestSelectedRow,
  downloadFile,
  theme,
  user,
  setSelectedDetails,
  setVisible,
  downloadLoading,
}) => [
    user.userType === "PATIENT" ? { width: "2vw" } : { width: 0 },
    {
      title: "Actions",
      width: "7vw",
      key: "actions",
      render: (e) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px",
            padding: "0 0 !important",
          }}
        >
          <Button
            size="small"
            type="primary"
            disabled={e.status !== "Report Uploaded"}
            style={{ fontSize: "11px", padding: "0 7px" }}
            onClick={() => {
              setSelectedDetails(e);
              setVisible(true);
            }}
          >
            View Details
          </Button>
        </div>
      ),
    },
    isMain
      ? user.userType !== "PATIENT"
        ? {
          render: (e) => (
            <Button
              size="small"
              type="primary"
              disabled={e.status !== "Report Uploaded" || e.testStatus !== "ACTIVE"}
              style={{
                fontSize: "11px",
                padding: "0 7px",
                width: "100%",
                cursor:
                  e.status !== "Report Uploaded" || e.testStatus !== "ACTIVE"
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={() => retestSelectedRow(e)}
            >
              Retest
            </Button>
          ),
          width: "5vw",
          key: "retest",
        }
        : { width: 0 }
      : { width: 0 },
    {
      title: "Sl. No",
      width: "4vw",
      dataIndex: "Sl No.",
      align: "center",
      key: "slNo",
    },
    {
      title: "Date",
      width: "7vw",
      key: "date",
      dataIndex: "Date",
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "type",
      width: "5vw",
      align: "center",
    },
    {
      title: "Common Test",
      dataIndex: "Common Test",
      key: "commonTest",
      width: "10vw",
      align: "center",
    },
    {
      title: "Test Name",
      // dataIndex: "Test Name",
      key: "testName",
      width: "8vw",
      align: "center",
      render: (item) => (
        <>
          {item.testStatus == "DELETED" ? (
            <div style={{ display: "contents" }}>
              {item["Test Name"]}&nbsp;
              <p style={{ fontSize: "10px", display: "contents" }}>(Deleted)</p>
            </div>
          ) : (
            item["Test Name"]
          )}{" "}
        </>
      ),
    },
    {
      title: "Status",
      width: user.userType === "PATIENT" ? "14vw" : "10vw",
      key: "status",
      align: "center",
      render: (e) =>
        e.status == "Report Uploaded" ? (
          <Button
            size="small"
            style={{
              fontSize: "11px",
              padding: "0 7px",
              minWidth: "120px",
              border: "1px solid green",
              color: "green",
              borderRadius: "5px",
              cursor: "default",
            }}
          >
            Report available
          </Button>
        ) : (
          <Button
            size="small"
            style={{
              fontSize: "11px",
              padding: "0 7px",
              minWidth: "120px",
              border: "1px solid rgb(118,118,255)",
              color: "rgb(118,118,255)",
              borderRadius: "5px",
              cursor: "default",
            }}
          >
            Waiting for Report
          </Button>
        ),
    },
    isMain
      ? {
        // width: "4vw",
        key: "download",
        render: (e) =>
          e.documents?.length > 0 ? (
            e.status === "Third-Party Report Uploaded" ? (
              <Popconfirm
                title="Sure to download all files from third party upload?"
                onConfirm={() => {
                  downloadFile(e);
                }}
              >
                <StyledButton disabled={downloadLoading}>
                  <Icon
                    component={DownloadOutside}
                    style={{
                      color: downloadLoading
                        ? "rgba(0,0,0,0.25)"
                        : theme["@primary-color"],
                      cursor: downloadLoading ? "not-allowed" : "pointer",
                      color: theme["@primary-color"],
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                  />
                </StyledButton>
              </Popconfirm>
            ) : (
              <StyledButton
                onClick={() => downloadFile(e)}
                disabled={downloadLoading}
              >
                <DownloadOutlined
                  style={{
                    color: downloadLoading
                      ? "rgba(0,0,0,0.25)"
                      : theme["@primary-color"],
                    cursor: downloadLoading ? "not-allowed" : "pointer",
                    fontSize: "18px",
                  }}
                />
              </StyledButton>
            )
          ) : (
            <DownloadOutlined
              style={{
                color: "rgba(0,0,0,0.25",
                cursor: "not-allowed",
                fontSize: "18px",
              }}
            />
          ),
      }
      : { width: 0 },
  ];

export const tableColumnsPrint = () => [
  {
    title: "Sl. No",
    width: "4vw",
    dataIndex: "Sl No.",
    align: "center",
    key: "slNo",
  },
  {
    title: "Date",
    width: "7vw",
    key: "date",
    dataIndex: "Date",
    align: "center",
  },
  {
    title: "Type",
    dataIndex: "Type",
    key: "type",
    width: "5vw",
    align: "center",
  },
  {
    title: "Common Test",
    dataIndex: "Common Test",
    key: "commonTest",
    width: "10vw",
    align: "center",
  },
  {
    title: "Test Name",
    dataIndex: "Test Name",
    key: "testName",
    width: "8vw",
    align: "center",
  },
];
