import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Table, Row, Col, Empty, Button } from "antd";
import Icon, {
  PrinterOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Collapse } from "react-collapse";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import "./CustomTable.css";
import Spinner from "../../../utils/Spinner";
import Logo from "../../../../assets/images/logo.png";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";
import moment from "moment";
import JSZipUtils from "jszip-utils";
import { BlueBgTable } from "../../AdminDashboard/styledComponents";
import { getSettings } from "../../../../apis/settings";
import { getOneByID } from "../../../../apis/patients";
import { genderList } from "../../../Datasets/basicDetails";

const StyledRow = styled(Row)`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
`;

const ViewMore = styled(Row)`
  cursor: ${(props) => props.cursor};
  margin-top: 10px;
  color: ${(props) => props.color};
`;

const EmptyMessageContainer = styled.div`
  height: 30vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
`;

const Heading = styled.h3`
  font-weight: 600;
  color: ${(props) => props.color};
`;

const StyledDiv = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.bgcolor};
  font-size: 16px !important;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  font-weight: 600;
  height: 50px;
  border-radius: ${(props) => (props.isCollapsed ? "10px 10px 0 0" : "10px")};
`;

const StyledTable = styled(Table)`
  & .ant-table-measure-row {
    display: none;
  }

  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    padding: 10px 5px !important;
  }

  & .ant-table-tbody > tr > td {
    font-size: 14px !important;
    overflow-wrap: normal;
    color: #6d6d6d !important;
    border-color: rgba(0, 0, 0, 0);
  }
  & .ant-table-thead > tr > th {
    font-size: 17px !important;
    /* padding-left: 30px !important; */
    /* padding: 0 0 !important; */
    overflow-wrap: normal;
    font-weight: 600;
    color: #5d5d5d !important;
  }
`;

const StyledTablePrint = styled(Table)`
  & .ant-table-tbody > tr > td {
    font-size: 13px !important;
    overflow-wrap: normal;
    color: #6d6d6d !important;
  }
  & .ant-table-tbody > tr {
    height: max-content;
  }
  & .ant-table-thead > tr > th {
    font-size: 12px !important;
    padding: 0 0 !important;
    overflow-wrap: normal;
    background-color: transparent !important;
    font-weight: 600;
    color: #5d5d5d !important;
  }
  & .ant-table-row-level-0 {
    display: ${(props) => (props.header ? "none" : "x")};
  }

  & .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:first-child {
    border-left: 1px solid #f0f0f0;
  }

  /* & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #9e9292;
  } */
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #f0f0f0;
  }
`;

const StyledCol = styled(Col)`
  @media (max-width: 1100px) {
    max-width: 100vw;
  }
  /* &.ant - row.scroll_right_panel.ant - spin - container.ant - table thead tr th,
  &.ant - row.scroll_right_panel.ant - spin - container.ant - table tbody tr td {
    padding: 0 0!important;
} */
`;

const CustomeTableForRquests = ({
  columns,
  dataSource,
  title,
  tableTitle,
  rowSelection,
  isPrintable,
  setRetestModal,
  getPreviousTabledata,
  collapse,
  retest,
  pageSize,
  isViewMore,
  retestList,
  isLoading,
  printColumns,
  printData,
  repeat,
  appointmentDetails
}) => {
  const theme = useSelector((state) => state.themeReducer);
  const user = useSelector((state) => state.userReducer);
  const images = useSelector((state) => state.imageReducer);
  const componentRef = useRef();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [viewDataSource, setViewDataSource] = useState(false);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  const [printPackData, setPrintPackData] = useState([]);
  const [hospitalLogo, setHospitalLogo] = useState();
  const [settings, setSettings] = useState();
  const [patient, setPatient] = useState();


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (dataSource?.length < pageSize) {
      setViewDataSource(false);
    } else {
      setViewDataSource(true);
    }
    getSettings().then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          setSettings(data.body);
        }
      }
    });
    if (user.userType == "PATIENT")
      getOneByID(user?.id).then((res) => {
        if (res.data.status == 1) {
          setPatient(res.data.body)
        }
      })
  }, []);

  useEffect(() => {
    setViewMoreLoading(false);
  }, [dataSource]);

  useEffect(() => {
    var packetList = [];
    var packet = [];
    if (printData?.length > 20)
      for (let index = 0; index < printData.length; index++) {
        packet.push(printData[index]);
        if ((index + 1) % 20 == 0 || index + 1 == printData.length) {
          packetList.push({ packet: packet });
          packet = [];
        }
      }
    else packetList.push({ packet: printData });
    console.log(packet, packetList);
    setPrintPackData(packetList);

    const objectURL = PROFILE_IMAGE_PREFIX + images.logo;
    preloadImage(objectURL);

    fetch(objectURL, {
      method: "GET",
      headers: { "Content-Type": "application/octet-stream" },
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        setHospitalLogo(url);
      });
  }, [printData]);

  const preloadImage = (url, callback) => {
    var img = new Image();
    img.src = url;
    img.onload = callback;
  };

  useEffect(() => {
    console.log(hospitalLogo);
  }, [hospitalLogo]);

  const viewMore = () => {
    if (dataSource?.length < pageSize) {
      setViewDataSource(false);
    } else {
      setViewDataSource(true);
    }
    getPreviousTabledata(dataSource?.length + 10);
  };

  return !isLoading ? (
    dataSource?.length > 0 ? (
      <StyledRow>
        {title && (
          <Col span={24}>
            <Heading color={theme["@primary-color"]}>{title}</Heading>
          </Col>
        )}
        <Col span={24}>
          <StyledDiv
            bgcolor={theme["@primary-color"]}
            isCollapsed={isCollapsed}
          >
            <div>{tableTitle}</div>
            <div>
              {isPrintable &&
                user.userType == "PATIENT" &&
                (printData?.length > 0 ? (
                  <PrinterOutlined onClick={handlePrint} />
                ) : (
                  <LoadingOutlined />
                ))}
              &nbsp;&nbsp;
              {collapse ? (
                isCollapsed ? (
                  <ShrinkOutlined
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                      getPreviousTabledata(10);
                    }}
                  />
                ) : (
                  <ArrowsAltOutlined
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  />
                )
              ) : (
                <></>
              )}
            </div>
          </StyledDiv>
        </Col>
        {/* <Collapse isOpened={isCollapsed}> */}
        {/* <div> */}
        <Col span={24}>
          {dataSource?.length > 0 ? (
            <StyledTable
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              headBg={theme["@primary-color"]}
              rowSelection={rowSelection}
              style={{ width: "100%" }}
              // size={"small"}
              scroll={{ x: "max-content" }}
            // tableLayout={"fixed"}
            />
          ) : (
            <EmptyMessageContainer>
              <Empty />
            </EmptyMessageContainer>
          )}
        </Col>
        <div style={{ width: "100%" }}>
          {isViewMore ? (
            viewDataSource ? (
              dataSource?.length > 10 ? (
                <ViewMore
                  justify="center"
                  align="middle"
                  color={
                    viewDataSource
                      ? "rgba(255,0,0,0.25)"
                      : theme["@primary-color"]
                  }
                  cursor={viewMoreLoading ? "not-allowed" : "pointer"}
                >
                  {viewMoreLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <b
                      onClick={() => {
                        setViewMoreLoading(true);
                        getPreviousTabledata(10);
                      }}
                    >
                      View Less
                    </b>
                  )}
                </ViewMore>
              ) : (
                <></>
              )
            ) : (
              <ViewMore
                justify="center"
                align="middle"
                color={
                  viewDataSource ? "rgba(0,0,0,0.25)" : theme["@primary-color"]
                }
                cursor={
                  viewDataSource
                    ? "not-allowed"
                    : viewMoreLoading
                      ? "not-allowed"
                      : "pointer"
                }
              >
                {viewMoreLoading ? (
                  <LoadingOutlined />
                ) : (
                  <b
                    onClick={() => {
                      setViewMoreLoading(true);
                      viewMore();
                    }}
                  >
                    View More
                  </b>
                )}
              </ViewMore>
            )
          ) : (
            <></>
          )}
          {retest ? (
            <Row justify="end">
              <Button
                type="primary"
                className="btn-save"
                style={{ marginTop: "20px", height: "40px" }}
                onClick={() => setRetestModal(true)}
                disabled={retestList?.length > 0 ? false : true}
              >
                {repeat ? "Repeat" : "Retest"}
              </Button>
            </Row>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            height: "0",
            padding: "15px",
            display: "none",
          }}
        >
          <Row style={{ padding: "15px", minWidth: "90vw" }} ref={componentRef}>
            <Row justify="space-between">
              <img src={hospitalLogo} style={{ width: "20%" }} />
              <h2 style={{ margin: "auto" }}>{settings?.hospital?.name}</h2>
              <div style={{ alignItems: "center", display: "flex" }}>
                Date: {moment().format("DD-MM-YYYY")}
              </div>
            </Row>
            <Row
              style={{
                borderTop: `2px solid ${theme["@primary-color"]}`,
                height: "20px",
                width: "100%",
              }}
            ></Row>
            {patient ?
              <Row justify="space-between" style={{ width: "100%", padding: "0 20px" }}>
                <div>
                  <p>Patient Name: <strong>{patient?.userName}</strong></p>
                  <p>Age: <strong>{moment().diff(patient?.dob, "years")}</strong></p>
                  {/* <p>Gender: <strong>{genderList().find((item) => item.code == patient?.gender).text}</strong></p> */}
                </div>
                <div>
                  <p>Doctor Name: <strong>{appointmentDetails?.doctor?.userName}</strong></p>
                  <p>Appointment Date: <strong>{moment(appointmentDetails?.date).format("DD-MM-YYYY")}</strong></p>
                </div>
              </Row> : <></>}
            <StyledTablePrint
              header={true}
              columns={printColumns}
              dataSource={[{}]}
              pagination={false}
              size={"small"}
              tableLayout={"fixed"}
              sticky={false}
            />
            {printPackData.map((item) => (
              <StyledTablePrint
                columns={printColumns}
                dataSource={item.packet}
                pagination={false}
                size={"small"}
                tableLayout={"fixed"}
                style={{ height: "100vh" }}
                showHeader={false}
                sticky={false}
              />
            ))}
          </Row>
        </div>
      </StyledRow>
    ) : (
      <EmptyMessageContainer>
        <Empty />
      </EmptyMessageContainer>
    )
  ) : (
    <Spinner />
  );
};
export default CustomeTableForRquests;
