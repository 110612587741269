import React, { useState, useEffect } from 'react';

import {
  Wrapper,
  BodyContainerSuccessMsg,
  StyledContent,
  LogoContainer,
} from './styledComponents';

import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { useQuery } from '../utils/UseQuery';
import { getHospitalDetailWithHospitalId } from '../../apis/Public';
import md5 from 'md5';
import Hospital from '../../assets/icons/Hospital.svg';

const AppointmentSuccessPage = () => {
  let query = useQuery();
  const history = useHistory();

  const hospId = query.get('hId');
  const doctId = query.get('dId');
  const hId = md5(hospId);
  const dId = md5(doctId);

  const [hospitalDetail, sethospitalDetail] = useState({});

  useEffect(() => {
    getHospitalDetailWithHospitalId(hId, dId)
      .then((res) => {
        if (res.status == 200) {
          sethospitalDetail(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Wrapper justify="center">
      <LogoContainer span={24} style={{ textAlign: 'center' }}>
        {Object.keys(hospitalDetail).length !== 0 ? (
          <>
            <span>
              <img
                src={Hospital}
                alt="hospital"
                height={'100px'}
                width={'100px'}
              />
            </span>
            <h1>{hospitalDetail?.hospital?.name} </h1>
          </>
        ) : (
          'Loading...'
        )}
      </LogoContainer>

      <StyledContent className="container">
        <BodyContainerSuccessMsg
          className="px-6"
          style={{
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
          }}
        >
          <h2 style={{ margin: '0 auto', lineHeight: '35px' }}>
            Thank you for making an appointment with Doctor &nbsp;
            {hospitalDetail.doctor?.name.toUpperCase()}.
            <br />
            Please, arrive 10 minutes before the scheduled time for
            Consultation.
          </h2>
          <div style={{ margin: 'auto', padding: '15px' }}>
            <Button
              style={{ display: 'block', margin: '0 auto' }}
              key="ok"
              type="primary"
              onClick={() => history.push('/login')}
            >
              Back to Login
            </Button>
          </div>
        </BodyContainerSuccessMsg>
      </StyledContent>
    </Wrapper>
  );
};

export default AppointmentSuccessPage;
