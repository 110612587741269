import { Col, Row, Avatar, message, Divider, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getOneByID } from '../../../apis/patients';
import { PROFILE_IMAGE_PREFIX } from '../../../constants/StringConstants';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { genderList } from '../../Datasets/basicDetails';
import { UserProfileContainer } from './styledComponents';
import moment from 'moment';
import * as Sentry from '@sentry/react';

const ProfileHead = styled.div``;

const BgPrimeColor = styled.div`
  background-color: ${(props) => props.color};
  width: fit-content;
  align-content: center;
  border-radius: 5px;
  display: flex;
  padding: ${(props) => (props.noPadding ? '2px 0px 2px 10px' : '2px 10px')};
  margin-left: 5px;
  justify-content: center;
  color: ${(props) => props.text};
`;

const BgPrimeColorMobile = styled.div`
  color: ${(props) => props.text};
  background-color: ${(props) => props.color};
  width: fit-content;
  align-content: center;
  border-radius: 5px;
  display: flex;
  padding: 0;
  margin-left: 5px;
  justify-content: center;
`;

const StyledCol = styled(Col)`
  display: flex;
  height: 100px;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
`;

export const StyledButton = styled(Button)`
  && {
    background: ${(props) => (props.type == 'history' ? props.color : 'white')};
    color: ${(props) => (props.type == 'history' ? 'white' : props.color)};
    border: none;
    border-radius: 20px;
    font-weight: 505;
    font-size: 15px;
  }
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: ${(props) => (props.type == 'history' ? props.color : 'white')};
    color: ${(props) => (props.type == 'history' ? 'white' : props.color)};
    border: ${(props) =>
      props.type == 'history' ? '' : `1px solid${props.color}`};
  }
  height: 40px;
  width: 240px;
`;

export const UserProfileHead = ({ patientId, patientName }) => {
  const theme = useSelector((state) => state.themeReducer);
  const user = useSelector((state) => state.userReducer);

  const [userData, setUserData] = useState();
  const [emiratesId, setEmiratesId] = useState();
  const [age, setAge] = useState();

  const capitalize = (camelCaseText) => {
    camelCaseText = camelCaseText.replace('-', ' ');
    var listName = [];
    // console.log(useRedux)
    listName = camelCaseText.split(' ');
    var name = '';
    for (const i in listName) {
      name =
        name +
        listName[i].charAt(0).toUpperCase() +
        listName[i]
          .slice(1)
          .split(/(?=[A-Z])/)
          .join(' ') +
        ' ';
    }
    return name;
  };

  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    return age_now;
  };

  const getGender = (code) => {
    var gender;
    gender = genderList().find((item) => item.code === code);
    if (gender?.text)
      if (gender.text == 'Not Available') return 'NA';
      else return gender.text;
    else return code;
  };

  useEffect(() => {
    getOneByID(patientId)
      .then(({ status, data }) => {
        if (status == 200) {
          if (data.status == 1) {
            let body = data.body;
            setUserData(data.body);
            setEmiratesId(
              data.body.identityDocuments.filter(
                (item) => item.documentType == 2
              )
            );
            let age = calculate_age(data.body.dob);
            if (age) setAge(age);
          } else {
            message.error('Unable to fetch Patient');
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        message.error('Unable to fetch Patient');
      });
  }, []);
  return (
    <UserProfileContainer span={24}>
      <Row
        className="user-profile-head-desktop"
        style={{ alignItems: 'center' }}
      >
        <Col span={5} style={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar
            className="profile-pic-circle"
            size={100}
            src={PROFILE_IMAGE_PREFIX + userData?.profilePic}
            icon={<UserOutlined />}
          />
        </Col>
        <StyledCol span={5}>
          <h3 style={{ margin: '0', color: '#5d5d5d' }}>
            <b style={{ textTransform: 'capitalize' }}>{patientName}</b>
          </h3>
          <div style={{ color: `${theme['@primary-color']}` }}>
            Patient ID: {patientId}
          </div>
          {/* <div style={{ color: `${theme["@primary-color"]}` }}>
            Emirates ID:{" "}
            {emiratesId?.length > 0
              ? emiratesId.map((item) => item.number)
              : "_____"}
          </div> */}
        </StyledCol>
        <Col>
          <Divider type="vertical" style={{ height: '100px' }} />
        </Col>
        <StyledCol span={5}>
          <div style={{ display: 'flex', color: '#5d5d5d' }}>
            <BgPrimeColor noPadding={true}>
              <b>DOB: </b>
            </BgPrimeColor>
            <BgPrimeColor>
              <Moment format="DD-MM-YYYY">{userData?.dob}</Moment>
            </BgPrimeColor>
          </div>
          <div style={{ display: 'flex', color: '#5d5d5d' }}>
            <BgPrimeColor>
              <b>Gender: </b>
            </BgPrimeColor>
            <BgPrimeColor color={theme['@primary-color']} text={'white'}>
              {getGender(userData?.gender)}
            </BgPrimeColor>
          </div>
        </StyledCol>
        <StyledCol span={5}>
          <div style={{ display: 'flex', color: '#5d5d5d' }}>
            <BgPrimeColor noPadding={true}>
              <b>Age: </b>
            </BgPrimeColor>
            <BgPrimeColor>{age}</BgPrimeColor>
          </div>
          <div style={{ display: 'flex', color: '#5d5d5d' }}>
            <BgPrimeColor>
              <b>Blood Group: </b>
            </BgPrimeColor>
            <BgPrimeColor color={theme['@primary-color']} text={'white'}>
              {userData?.bloodGroup ? userData.bloodGroup.value : '___'}
            </BgPrimeColor>
          </div>
        </StyledCol>
        
      </Row>
      <div className="user-profile-head-mobile">
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <Avatar
              className="profile-pic-circle"
              size={75}
              src={PROFILE_IMAGE_PREFIX + userData?.profilePic}
              icon={<UserOutlined />}
            />
          </div>
          <div style={{ padding: '0 10px' }}>
            <h3 style={{ margin: '0', color: '#5d5d5d' }}>
              <b style={{ textTransform: 'capitalize' }}>{patientName}</b>
            </h3>
            <div style={{ color: `${theme['@primary-color']}` }}>
              Patient ID: {patientId}
            </div>
            <div style={{ color: `${theme['@primary-color']}` }}>
              Emirates ID:{' '}
              {emiratesId?.length > 0
                ? emiratesId.map((item) => item.number)
                : '_____'}
            </div>
          </div>
        </div>
        <Divider
          type="horizontal"
          style={{ height: '0px', margin: '10px 0' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                color: '#5d5d5d',
              }}
            >
              <BgPrimeColorMobile>
                <b style={{ fontSize: '12px' }}>DOB: </b>
              </BgPrimeColorMobile>
              <BgPrimeColorMobile>
                <p format="DD-MM-YYYY" style={{ margin: '0' }}>
                  {moment(userData?.dob).format('DD-MM-YYYY')}
                </p>
              </BgPrimeColorMobile>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                color: '#5d5d5d',
              }}
            >
              <BgPrimeColorMobile>
                <b style={{ fontSize: '12px' }}>Gender: </b>
              </BgPrimeColorMobile>
              <BgPrimeColorMobile
                color={theme['@primary-color']}
                text={'white'}
                style={{ padding: '2px 5px' }}
              >
                {getGender(userData?.gender)}
              </BgPrimeColorMobile>
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                color: '#5d5d5d',
              }}
            >
              <BgPrimeColorMobile noPadding={true}>
                <b style={{ fontSize: '12px' }}>Age: </b>
              </BgPrimeColorMobile>
              <p style={{ margin: 0 }}>&nbsp;&nbsp;{age}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                color: '#5d5d5d',
              }}
            >
              <BgPrimeColorMobile>
                <b style={{ fontSize: '12px' }}>Blood Group: </b>
              </BgPrimeColorMobile>
              <BgPrimeColorMobile
                color={theme['@primary-color']}
                text={'white'}
                style={{ padding: '2px 5px' }}
              >
                {userData?.bloodGroup ? userData.bloodGroup.value : '___'}
              </BgPrimeColorMobile>
            </div>
          </div>
        </div>
      </div>
    </UserProfileContainer>
  );
};
