import { APPLY_THEME } from '../actions/theme';
import { ADD_USER, CLEAR_USER } from '../actions/user';

const initialState = {
  '@primary-color': '#26b1ff',
  '@text-color': '#6d6d6d',
  '@text-color-secondary': '#6d6d6d',
  '@label-color': '#6d6d6d',
};
export const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_THEME:
      return { ...state, ...action.color };
    default:
      return state;
  }
};
