import React from "react";
import { useSelector } from "react-redux";
import { EmptyMessageContainer } from "../Departments/styledComponents";
import { columns } from "./TableConfig";
import { Empty } from "antd";
import Spinner from "../../../utils/Spinner";
import { BlueBgTable } from "../styledComponents";

const Hl7Table = ({
  forms,
  loading,
  setSelectedItem,
  setDeleteModal,
  deleteModal,
  setVisible,
  handleEdit,
  edit,
}) => {
  const theme = useSelector((state) => state.themeReducer);

  return (
    <>
      {!loading ? (
        <>
          {forms && forms.length > 0 ? (
            <BlueBgTable
              // headBg={theme["@primary-color"]}
              columns={columns(
                setSelectedItem,
                setDeleteModal,
                deleteModal,
                setVisible,
                edit,
                handleEdit
              )}
              dataSource={forms}
              pagination={false}
              scroll={{ x: "max-content" }}
            />
          ) : (
            <EmptyMessageContainer>
              <Empty />
            </EmptyMessageContainer>
          )}
        </>
      ) : (
        <Spinner height="100px" />
      )}
    </>
  );
};
export default Hl7Table;
