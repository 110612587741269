import React, { useEffect } from "react";
import { Row, Col, message, Button } from "antd";
import { SectionHeading, SectionHeadContainer } from "../styledComponents";
import {
  BodyContainer,
  BodyContainerSection,
  UserBodyContainer,
} from "../Sections/styledComponents";
import { useState } from "react";
import UserRolesList from "./UserRolesList";
import { LeftCircleOutlined, UserAddOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import RoleBasedPrivileges from "./RoleBasedPrivileges";
import styled from "styled-components";
import CreateRoleModal from "./CreateRoleModal";

export const NewUserButton = styled(Button)`
  height: 40px;
  width: 200px;
  font-size: 17px;
  border-radius: 5px !important;
`;

const UserRoles = () => {
  const [selected, setSelected] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [createRoleModal, setCreateRoleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});
  const [trigger, setTrigger] = useState(true);

  useEffect(() => {}, [trigger]);

  const history = useHistory();

  return (
    <>
      <Row>
        <Col span={24}>
          <SectionHeadContainer>
            <Row justify="space-between">
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <SectionHeading>
                  {selected && (
                    <LeftCircleOutlined
                      onClick={() => {
                        setSelected(false);
                        setSelectedId(null);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  User Roles
                </SectionHeading>
              </Col>
              <Col style={{ display: "flex", alignItems: "flex-end" }}>
                <NewUserButton
                  type="primary"
                  size="large"
                  icon={<UserAddOutlined />}
                  onClick={() => setCreateRoleModal(true)}
                >
                  Create Role
                </NewUserButton>
              </Col>
            </Row>
          </SectionHeadContainer>
        </Col>
        <BodyContainerSection span={24}>
          {!selected ? (
            <UserBodyContainer>
              <UserRolesList
                setSelected={setSelected}
                setSelectedId={setSelectedId}
                trigger={trigger}
                setSelectedRole={setSelectedRole}
              />
            </UserBodyContainer>
          ) : (
            <BodyContainer>
              <RoleBasedPrivileges
                selectedRole={selectedRole}
                selectedId={selectedId}
                setSelected={setSelected}
                setSelectedId={setSelectedId}
              />
            </BodyContainer>
          )}
        </BodyContainerSection>
      </Row>
      <CreateRoleModal
        visible={createRoleModal}
        setVistible={setCreateRoleModal}
        trigger={trigger}
        setTrigger={setTrigger}
      />
    </>
  );
};

export default UserRoles;
