import { Button, Col, InputNumber, Row, message } from "antd";

import React from "react";
import { StyledButton } from "../Appoinment/styledComponents";
import moment from "moment";
import styled from "styled-components";
import ReadMoreReact from 'read-more-react';


export const StyledDivInput = styled.div`
  display: flex;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  & .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    /* background-color: #eee; */
    border: 1px solid #ccc;
    /* width: 1%; */
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
  }

  & .input-group-addon:active {
    background-color: #e9e9e9;
  }

  input {
    /* position: relative; */
    /* z-index: 2; */
    /* float: left; */
    width: 50px;
    text-align: center;
    /* margin-bottom: 0; */
    /* display: table-cell; */
    /* padding: 6px 12px; */
    border: 1px solid #ccc;
    border-left: 0;
    border-right: 0;
    margin: 0;
  }
  input:focus {
    outline: 1px solid white;
  }

  & .suffix input {
    border-radius: 4px 0px 0px 4px;
    border-left: 0;
  }
  & .suffix .input-group-addon {
    border-left: 0;
    border-radius: 0px 4px 4px 0px;
  }
  & .prefix input {
    border-radius: 0px 4px 4px 0px;
    border-right: 0;
  }
  & .prefix .input-group-addon {
    border-right: 0;
    border-radius: 4px 0px 0px 4px;
  }
  & .presuffix input {
    border-radius: 0px;
  }
  & .input-group-addon.prefix {
    border-radius: 4px 0px 0px 4px;
    border-right: 0;
  }
  & .input-group-addon.suffix {
    border-radius: 0px 4px 4px 0px;
    border-left: 0;
  }
  & .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
  }
`;

const PharmacyRequestType = styled.div`
  color: ${(props) => requestTypeColour[props.requestType]};
`;
const requestTypeColour = {
  EMERGENCY: "red",
};
export const activePharmacyColumns = (
  history,
  pageNo,
  pageSize,
  StyledButton,
  theme,
  inTimeModalVisible,
  setAppointmentId
) => [
    {
      title: "Actions",
      fixed: "left",
      render: (row) => {
        return (
          <Row gutter={12}>
            <Col>
              <StyledButton
                color={theme["@primary-color"]}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/pharmacy-records/${row.patient.id}/${row.patient.firstName + row.patient.lastName
                    }/${row?.appointment?.id}/${row.id}`
                  );
                }}
              >
                Call
              </StyledButton>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "P ID",
      dataIndex: ["patient", "id"],
      key: "id",
    },
    {
      title: "F Name",
      dataIndex: ["patient", "firstName"],
      key: "firstName",
    },
    {
      title: "L Name",
      dataIndex: ["patient", "lastName"],
      key: "lastName",
    },
    {
      title: "Gender",
      dataIndex: ["patient", "gender"],
      key: "gender",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: ["patient", "phoneNumbers"],
      key: "phone",
      render: (value) =>
        value && (
          <div>
            {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
          </div>
        ),
    },
    {
      title: "Visit Type",
      dataIndex: ["appointment", "visitType", "value"],
      key: "visitType",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Visit Booked Date",
      dataIndex: "date",
      key: "visitBookedDate",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Doctor Name",
      dataIndex: ["appointment", "doctor"],
      key: "doctor",
      render: (doctor) => {
        if (doctor !== null) {
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        } else return <div>-</div>;
      },
    },
    {
      title: "Request Type",
      dataIndex: ["requestType"],
      key: "requestType",
      render: (requestType) => {
        if (requestType !== null)
          return (
            <PharmacyRequestType requestType={requestType}>
              {requestType}
            </PharmacyRequestType>
          );
        else return <div>-</div>;
      },
    },
  ];

  export const activePharmacyColumnsIPD = (
    history,
    pageNo,
    pageSize,
    StyledButton,
    theme,
    inTimeModalVisible,
    setAppointmentId
  ) => [
      {
        title: "Actions",
        fixed: "left",
        render: (row) => {
          return (
            <Row gutter={12}>
              <Col>
                <StyledButton
                  color={theme["@primary-color"]}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(
                      `/pharmacy-records-ipd/${row.patient.id}/${row.patient.firstName + row.patient.lastName
                      }/${row?.ipd.id}/${row.id}`
                    );
                  }}
                >
                  Call
                </StyledButton>
              </Col>
            </Row>
          );
        },
      },
      {
        title: "Sl. No",
        dataIndex: "",
        key: "no",
        render: (text, record, index) => {
          return pageNo * pageSize + index + 1;
        },
      },
      {
        title: "P ID",
        dataIndex: ["patient", "id"],
        key: "id",
      },
      {
        title: "F Name",
        dataIndex: ["patient", "firstName"],
        key: "firstName",
      },
      {
        title: "L Name",
        dataIndex: ["patient", "lastName"],
        key: "lastName",
      },
      {
        title: "Gender",
        dataIndex: ["patient", "gender"],
        key: "gender",
        render: (value) => {
          if (value !== null) return <div>{value}</div>;
          else return <div>-</div>;
        },
      },
      {
        title: "Mobile No",
        dataIndex: ["patient", "phoneNumbers"],
        key: "phone",
        render: (value) =>
          value && (
            <div>
              {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
            </div>
          ),
      },
      {
        title: "Visit Type",
        dataIndex: ["appointment", "visitType", "value"],
        key: "visitType",
        render: (value) => {
          if (value !== null) return <div>{value}</div>;
          else return <div>-</div>;
        },
      },
      {
        title: "Visit Booked Date",
        dataIndex: "date",
        key: "visitBookedDate",
        render: (date) => {
          if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
          else return <div>-</div>;
        },
      },
      {
        title: "Doctor Name",
        dataIndex: ["appointment", "doctor"],
        key: "doctor",
        render: (doctor) => {
          if (doctor !== null) {
            return (
              <div>
                Dr. {doctor.firstName} {doctor.lastName}
              </div>
            );
          } else return <div>-</div>;
        },
      },
      {
        title: "Request Type",
        dataIndex: ["requestType"],
        key: "requestType",
        render: (requestType) => {
          if (requestType !== null)
            return (
              <PharmacyRequestType requestType={requestType}>
                {requestType}
              </PharmacyRequestType>
            );
          else return <div>-</div>;
        },
      },
    ];

export const historyPharmacyColumns = (
  history,
  pageNo,
  pageSize,
  StyledButton,
  theme
) => [
    {
      title: "Actions",
      key: "id",
      fixed: "left",
      render: (row) => {
        return (
          <Row gutter={12}>
            <Col>
              <StyledButton
                color={theme["@primary-color"]}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push({
                    pathname: `/pharmacy-records/${row.patient.id}/${row.patient.firstName + row.patient.lastName
                      }/${row?.appointment?.id}/${row.id}`,
                    state: { complete: true },
                  });
                }}
              >
                View Details
              </StyledButton>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Sl. No",
      dataIndex: "",
      key: "no",
      render: (text, record, index) => {
        return pageNo * pageSize + index + 1;
      },
    },
    {
      title: "P ID",
      dataIndex: ["patient", "id"],
      key: "id",
    },
    {
      title: "F Name",
      dataIndex: ["patient", "firstName"],
      key: "firstName",
    },
    {
      title: "L Name",
      dataIndex: ["patient", "lastName"],
      key: "lastName",
    },
    {
      title: "Gender",
      dataIndex: ["patient", "gender"],
      key: "gender",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: ["patient", "phoneNumbers"],
      key: "id",
      render: (value) =>
        value && (
          <div>
            {value[0].countryCode.split(" ")[0]}-{value[0].phoneNumber}
          </div>
        ),
    },
    {
      title: "Visit Type",
      dataIndex: ["appointment", "visitType", "value"],
      key: "visitType",
      render: (value) => {
        if (value !== null) return <div>{value}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Visit Booked Date",
      dataIndex: "date",
      key: "visitBookedDate",
      render: (date) => {
        if (date !== null) return <div>{moment(date).format("DD-MM-YYYY")}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Doctor Name",
      dataIndex: ["appointment", "doctor"],
      key: "doctor",
      render: (doctor) => {
        if (doctor !== null) {
          return (
            <div>
              Dr. {doctor.firstName} {doctor.lastName}
            </div>
          );
        } else return <div>-</div>;
      },
    },
    {
      title: "Payment Method",
      dataIndex: ["paymentType"],
      key: "paymentMethod",
      render: (paymentMethod) => {
        if (paymentMethod !== null) return <div>{paymentMethod}</div>;
        else return <div>-</div>;
      },
    },
    {
      title: "Request Type",
      dataIndex: ["requestType"],
      key: "requestType",
      render: (requestType) => {
        if (requestType !== null)
          return (
            <PharmacyRequestType requestType={requestType}>
              {requestType}
            </PharmacyRequestType>
          );
        else return <div>-</div>;
      },
    },
  ];

  

export const paymentColumns = (
  changeDataForDispense,
  payInCashList,
  setPayInCashList,
  insuranceList,
  setInsuranceList,
  isInsurance,
  history,
  isReadMore
) => [
    history.location?.state
      ? { dataIndex: "Sl No", title: "Sl. No" }
      : {
        render: (row) => (
          <div>
            <StyledButton
              type="primary"
              ghost={!payInCashList.includes(row["subSectionDataId"])}
              value="cash"
              onClick={() => {
                let list = payInCashList;
                if (!payInCashList.includes(row["subSectionDataId"])) {
                  list = list.concat(row["subSectionDataId"]);
                  setPayInCashList(list);
                  var index = insuranceList.indexOf(row["subSectionDataId"]);
                  if (index > -1) {
                    insuranceList.splice(index, 1);
                  }
                  setInsuranceList(insuranceList);
                }
              }}
            >
              Pay in Cash
            </StyledButton>
            <StyledButton
              type="primary"
              ghost={!insuranceList.includes(row["subSectionDataId"])}
              value="insurance"
              disabled={!isInsurance}
              onClick={() => {
                let list = insuranceList;
                if (!insuranceList.includes(row["subSectionDataId"])) {
                  list = list.concat(row["subSectionDataId"]);
                  setInsuranceList(list);
                  var index = payInCashList.indexOf(row["subSectionDataId"]);
                  if (index > -1) {
                    payInCashList.splice(index, 1);
                  }
                  setPayInCashList(payInCashList);
                }
              }}
            >
              Claim via Insurance
            </StyledButton>
          </div>
        ),
      },
    !history.location?.state
      ? {
        dataIndex: "dispense",
        title: "Dispense Qty",
        render: (text, data, index) => (
          <StyledDivInput>
            <span
              class="input-group-addon prefix"
              style={{ cursor: "pointer" }}
              onClick={() => {
                text && changeDataForDispense(false, data);
              }}
            >
              -
            </span>
            <input type="number" id="dispense" value={text} />
            <span
              class="input-group-addon suffix"
              style={{ cursor: "pointer" }}
              onClick={() => {
                changeDataForDispense(true, data);
              }}
            >
              +
            </span>
          </StyledDivInput>
        ),
      }
      : {
        dataIndex: "dispense",
        title: "Dispense Qty",
      },
    !history.location?.state
      ? { dataIndex: "Total Dispense", title: "Total Dispense" }
      : { width: 0 },
    {
      title: "Drugs/ GenericTotal Dispense Code",
      dataIndex: "Drugs/ Generic code",
    },
    { title: "Dose", dataIndex: "Dose" },
    { title: "Frequency", dataIndex: "Frequency" },
    { title: "Duration", dataIndex: "No of Days" },
    {
      title: "Notes", dataIndex: "Notes", render: (value) => {
        return isReadMore ? <p style={{ maxWidth: "15vw" }}>
          <ReadMoreReact
            text={value ? value : ""}
            min={35}
            readMoreText={<a>...</a>}
          />
        </p> : value
      }
    },
  ];
