import CustomModal from "../../utils/CustomModal.jsx/index";
import Icon, { CaretDownOutlined, UserOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  saveMedicalDocument,
  searchAppointmentUnderGivenDate,
} from "../../../apis/patients";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import {
  Input,
  Row,
  Col,
  Form,
  Radio,
  Button,
  message,
  DatePicker,
} from "antd";
import moment from "moment";
import Moment from "moment-timezone";
import StatusModal from "./confirmModal";
import { DocumentUpload } from "./uploadPdfImageDocument";
import { deleteUploadedFile } from "../../../apis/uploadPdfOrImage";
import { ReactComponent as History } from "../../../assets/icons/History.svg";
import { ReactComponent as History_Inactive } from "../../../assets/icons/History_Inactive.svg";
import { ReactComponent as Lab } from "../../../assets/icons/Lab.svg";
import { ReactComponent as Lab_Inactive } from "../../../assets/icons/Lab_Inactive.svg";
import { ReactComponent as Radiology } from "../../../assets/icons/RadiologyR.svg";
import { ReactComponent as Radiology_Inactive } from "../../../assets/icons/Radiology_Inactive.svg";
// import { values } from "lodash";

const BodyContainer = styled.div`
  text-align: left;
  min-width: 50vw;
  margin: 0px 25px;
  padding: 10px;

  @media (max-width: 991px) {
    && {
      width: 90%;
      margin: auto;
    }
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  &.ant-picker {
    border: ${(props) =>
    props.isActive == "active"
      ? "1px solid green"
      : props.isActive == "inactive"
        ? "1px solid red"
        : ""};
  }
  &.ant-picker-input > input {
    color: red;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  /* font-weight: 700; */
  height: max-content;
  border-radius: 5px;
  margin-left: 15px;
`;

const StyledB = styled.b`
  width: 100px;
  /* font-size: 30px; */
`;

const StyledRadioButton = styled(Radio.Button)`
  width: 140px;
  height: max-content;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 0 15px 0 3px;
  &.ant-radio-button-wrapper-checked {
    margin: 1px;
  }
`;

const StyledH2 = styled.h2`
  /* font-size: 40px; */
  /* color: black; */
`;

const StyledIcon = styled(Icon)`
  & .cls-1 {
    fill: ${(props) => props.isActive};
  }
`;

const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current > moment().endOf("day");
};

const dateFormat = "DD-MM-YYYY";
const customFormatActive = (value) => `${value.format(dateFormat)} Active`;
const customFormatInactive = (value) => `${value.format(dateFormat)} Inactive`;

const UploadReportModal = ({ visible, setVisible, row }) => {
  const [reportType, setReportType] = useState("");
  const [form] = Form.useForm();
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);
  const user = useSelector((state) => state.userReducer);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [appointmentFetch, setAppointmentFetch] = useState({});
  const [consultationDate, setConsultationDate] = useState();
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [submit, setSubmit] = useState(true);
  const [activeDate, setActiveDate] = useState("");
  const openConfirmModal = (values) => {
    setStatusModalVisible(true);
    console.log(values);
    setFormValues(values);
  };

  const consultationDateFinder = (e) => {
    message.destroy();
    setAppointmentFetch({});
    setReportType(form.getFieldValue("type"));
    // setSubmit(true)
    var date = new Date(form.getFieldValue("consultation"));
    var milliseconds = date.getTime();
    form.getFieldValue("type") == "LABORATORY"
      ? (e = "Laboratory")
      : form.getFieldValue("type") == "RADIOLOGY"
        ? (e = "Radiology")
        : (e = "HISTORY");

    if (e !== "HISTORY") {
      var data = {
        requestedDate: milliseconds,
        visitType: e,
        timeZone: Moment.tz.guess(),
        patientId: row.id,
      };
      searchAppointmentUnderGivenDate(data)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 1) {
              console.log(res);
              setAppointmentFetch(res.data.body);
              // message.success("Appointment available")
              // setSubmit(false)
              setActiveDate("(Active)");
            } else {
              // message.error(res.data.body);
              setActiveDate("(No Active Consultation)");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          message.error("Invalid Data");
          //     setLoading(false);
        });
    } else {
      // documentUrl?.length > 0 && setSubmit(false);
      setActiveDate("");
    }
  };
  const submitHandler = () => {
    console.log(formValues);
    var data = formValues;
    data.documents = [];
    documentUrl.map((file, i) => {
      console.log(file, i);
      data.documents[i] = {
        fileName: file.name,
        documentUrl: file.url,
        order: i,
        // uid: file.uid,
        // size: file.size,
        type: file.type,
        // lastModified: file.lastModified
      };
    });
    data.patientId = row.id;
    if (formValues.type !== "HISTORY") {
      if (appointmentFetch.appointment) {
        data.appointmentId = appointmentFetch.appointment.id;
        saveMedicalDocument(data)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 1) {
                console.log(res);
                message.success(res.data.body);
                setReportType("");
                setDocumentUrl([]);
                form.resetFields();
                closeModal(true);
                setSubmit(true);
              } else {
                message.error(res.data.body);
                closeModal(false);
                setSubmit(true);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
            message.error("Invalid data");
            closeModal(false);
            setSubmit(true);
          });
        setStatusModalVisible(false);
        setSubmit(true);
        // closeModal(false)
      } else {
        setStatusModalVisible(false);
        message.error("Appoinment not availble on that date");
        setSubmit(true);
        setFormValues({});
        closeModal(false);
      }
    } else {
      // consultationDate ?
      //   data.consultationDate = consultationDate :
      //   data.consultationDate = moment()
      var date = new Date(form.getFieldValue("consultation"));
      var milliseconds = date.getTime();
      data.consultationDate = milliseconds;
      saveMedicalDocument(data)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 1) {
              console.log(res);
              message.success(res.data.body);
              setFormValues({});
              setReportType("");
              setDocumentUrl([]);
              setSubmit(true);
              form.resetFields();
              closeModal(true);
            } else {
              message.error(res.data.body);
              setFormValues({});
              closeModal(false);
              setSubmit(true);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          message.error("Invalid data");
          setFormValues({});
          closeModal(false);
          setSubmit(true);
        });
      setStatusModalVisible(false);
      setSubmit(true);
      setFormValues({});
      // closeModal(false)
    }
  };

  useEffect(() => {
    if (reportType == "LABORATORY" || reportType == "RADIOLOGY")
      if (form.getFieldValue("consultation"))
        if (activeDate == "(Active)")
          if (documentUrl?.length > 0) setSubmit(false);
          else setSubmit(true);
        else setSubmit(true);
      else setSubmit(true);
    else if (documentUrl?.length > 0 && reportType == "HISTORY")
      setSubmit(false);
    else setSubmit(true);
    if (user.userType === "NURSE") {
      form.setFieldsValue({ type: "HISTORY" });
      setReportType("HISTORY");
    }
  }, [reportType, activeDate, documentUrl]);

  const closeModal = (visibility) => {
    form.resetFields();
    setActiveDate("");
    setConsultationDate("");
    if (!visibility)
      documentUrl.map((item) => {
        deleteUploadedFile(item?.url)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 1) {
                console.log(res.data);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
          });
      });
    setDocumentUrl([]);
    setReportType("");
    setTimeout(() => {
      setVisible(false);
    }, 300);
  };

  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true }}
      isClosable={true}
      setVisible={closeModal}
    >
      <BodyContainer className="popup-box-content">
        <Row style={{ justifyContent: "space-between", margin: "0 0 30px 0" }}>
          <Col span={100}>
            <StyledB style={{ color: "black" }}>Upload Report</StyledB>
          </Col>
          <Col span={100} justify="right">
            <StyledB style={{ color: theme["@primary-color"] }}>
              Patient ID: {row?.id}
            </StyledB>
          </Col>
        </Row>
        <Row>
          <StyledH2>Choose Consultation</StyledH2>
        </Row>
        <Col>
          <Form
            layout="vertical"
            onFinish={(values) => openConfirmModal(values)}
            form={form}
            className="form-m2-style"
            style={{ width: "100%" }}
            // onValuesChange={valuesChangeHandler}
            scrollToFirstError={true}
          >
            <Row>
              <h3>Report Type</h3>
            </Row>
            <Row>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Report type is required",
                  },
                ]}
              >
                <Radio.Group
                  name="radiogroup"
                  optionType="button"
                  buttonStyle="outline"
                  onChange={(e) => {
                    form.getFieldValue("consultation") &&
                      consultationDateFinder(e.target.value);
                    setReportType(e.target.value);
                    e.target.value !== "HISTORY" &&
                      setActiveDate("(No Active Consultation)");
                    e.target.value == "HISTORY" && setActiveDate("");
                  }}
                // value={reportType}
                >
                  {user.userType !== "NURSE" ? (
                    <>
                      <StyledRadioButton
                        value="LABORATORY"
                        style={{ margin: "0 15px 0 3px" }}
                      // disabled={consultationDate == "" ? true : false}
                      >
                        <Row style={{ justifyContent: "center" }}>
                          {
                            <StyledIcon
                              isActive={
                                reportType == "LABORATORY"
                                  ? theme["@primary-color"]
                                  : "#d9d9d9"
                              }
                              component={Lab_Inactive}
                              style={{ fontSize: "50px" }}
                            />
                          }
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                          Laboratory
                        </Row>
                      </StyledRadioButton>
                      <StyledRadioButton
                        value="RADIOLOGY"
                        // disabled={consultationDate == "" ? true : false}
                        style={{ margin: "0 15px 0 3px" }}
                      >
                        <Row style={{ justifyContent: "center" }}>
                          {
                            <StyledIcon
                              isActive={
                                reportType == "RADIOLOGY"
                                  ? theme["@primary-color"]
                                  : "#d9d9d9"
                              }
                              component={Radiology_Inactive}
                              style={{ fontSize: "50px" }}
                            />
                          }
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                          Radiology
                        </Row>
                      </StyledRadioButton>
                    </>
                  ) : (
                    <></>
                  )}
                  <StyledRadioButton
                    value="HISTORY"
                    style={{ margin: "0 15px 0 3px" }}
                  >
                    <Row style={{ justifyContent: "center" }}>
                      {
                        <StyledIcon
                          isActive={
                            reportType == "HISTORY"
                              ? theme["@primary-color"]
                              : "#d9d9d9"
                          }
                          component={History_Inactive}
                          style={{ fontSize: "50px" }}
                        />
                      }
                    </Row>
                    <Row style={{ justifyContent: "center" }}>
                      Medical History
                    </Row>
                  </StyledRadioButton>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label="Consultation"
                  name="consultation"
                // rules={[
                //   {
                //     // required: true,
                //     message: "Consultation cannot be empty.",
                //   },
                // ]}
                >
                  <StyledDatePicker
                    size="medium"
                    disabledDate={disabledDate}
                    // format="DD-MM-YYYY"
                    allowClear={false}
                    onChange={consultationDateFinder}
                    isActive={
                      activeDate == "(Active)"
                        ? "active"
                        : activeDate == ""
                          ? ""
                          : reportType == "HISTORY"
                            ? ""
                            : "inactive"
                    }
                    format={`DD-MM-YYYY [${activeDate}]`}
                    disabled={reportType == ""}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              style={{ width: "100%", marginTop: "20px", marginBottom: "0" }}
            >
              <DocumentUpload
                files={documentUrl}
                setFiles={setDocumentUrl}
                isPdf={true}
                disabled={
                  reportType == "" || activeDate == "(No Active Consultation)"
                }
              />
            </Row>
            <Form.Item style={{ width: "100%" }}>
              <Row style={{ justifyContent: "flex-end", marginTop: "15px" }}>
                <Col>
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    className="btn-save"
                    onClick={() => {
                      closeModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    htmlType="submit"
                    size="large"
                    type="primary"
                    style={{ marginLeft: "15px" }}
                    className="btn-save"
                    disabled={submit}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </BodyContainer>
      <StatusModal
        cancel={true}
        cancelAppointment={submitHandler}
        visible={statusModalVisible}
        closeModal={() => setStatusModalVisible(false)}
      // cancelLoading={cancelLoading}
      />
    </CustomModal>
  );
};

export default UploadReportModal;
