import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Form, Input, message } from "antd";
import FullWidthButton from "../utils/FullWidthButton";
import { generatePasswordResetLink } from "../../apis/Auth";
import * as Sentry from "@sentry/react";

const BodyContainer = styled.div`
  text-align: center;
  max-width: 350px;
  margin: 20px 50px;
`;
const StyledH2 = styled.h2`
  color: #6d6d6d;
`;
const StyledDescription = styled.p`
  font-size: 12px;
  color: #6d6d6d;
`;
const RedirectToLogin = styled.a`
  color: black;
  text-decoration: underline;
`;
const StyledForm = styled(Form)`
  & .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }
`;

const PasswordResetForm = ({ onFinish, loading }) => (
  <StyledForm
    layout="vertical"
    onFinish={onFinish}
    style={{ textAlign: "left" }}
    className="popup-form-data"
    scrollToFirstError={true}
  >
    <Form.Item
      label="Username"
      name="email"
      rules={[
        { required: true, type: "email", message: "Enter a valid email !" },
      ]}
    >
      <Input size="large" />
    </Form.Item>
    <Form.Item>
      <FullWidthButton
        htmlType="submit"
        loading={loading}
        size="large"
        className="submit-btn w-100"
      >
        Send
      </FullWidthButton>
    </Form.Item>
  </StyledForm>
);

const ForgotPasswordBody = ({ setVisible }) => {
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    {
      setLoading(true);

      generatePasswordResetLink(values)
        .then((res) => {
          if (res.status === 200)
            if (res.data.status === 1)
              message.success("Password reset link is sent to your email");
            else message.error(res.data.body);
        })
        .catch((err) => {
          setLoading(false);
          if (
            typeof err.response.data.body !== "object" &&
            !Array.isArray(err.response.data.body)
          )
            message.error(err.response.data.body);
          else message.error("Service temparly unavailable");
          console.log(err);
          Sentry.captureException(err);
        });
    }
  };
  const onRedirectHandler = () => {
    setVisible(false);
  };
  return (
    <BodyContainer className="cover-width">
      <Row gutter={[36, 24]}>
        <Col span={24}>
          <StyledH2>Forgot Password ?</StyledH2>
        </Col>
        <Col span={24} style={{ padding: "0 15px" }}>
          <StyledDescription>
            Enter your username you're using for your account below and we will
            send you a password reset link to your mail
          </StyledDescription>
        </Col>
        <Col span={24}>
          <PasswordResetForm onFinish={onFinish} />
        </Col>
      </Row>
      <Col span={24}>
        <RedirectToLogin
          onClick={onRedirectHandler}
          loading={loading}
          style={{ color: "#525252" }}
        >
          Back to Login
        </RedirectToLogin>
      </Col>
    </BodyContainer>
  );
};
export default ForgotPasswordBody;
