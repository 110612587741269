import React, { useEffect, useState } from "react";
import { Col, Row, message } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getAppointmentDetails } from "../../../apis/Appointment.js";
import moment from "moment";
import * as Sentry from "@sentry/react";

const StyledSummaryCol = styled(Col)`
  background: #fff;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0 0 15px 0;
  border-radius: 10px;
  @media (max-width: 600px) {
    padding: 30px 0;
  }
  @media (max-width: 480px) {
    padding: 30px 15px;
  }
`;

const StyledSummaryRow = styled(Row)`
  font-weight: 600;
  color: rgb(93, 93, 93);
`;

const StyledP = styled.p`
  display: contents;
  font-size: 12px;
  font-weight: normal;
`;

const RequestSummary = ({
  appointmentId,
  insuranceData,
  pharmacyRequestSummary,
  insuranceDeductibleType,
}) => {
  const patient = useSelector((state) => state.patientReducer);
  const theme = useSelector((state) => state.themeReducer);
  const [patientData, setPatientData] = useState();

  useEffect(() => {
    getAppointmentDetails(appointmentId ? appointmentId : patient.appointmentId)
      .then((response) => {
        if (response.data.status === 1) setPatientData(response.data.body);
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        message.error("Could not fetch appointment details,Try again");
      });
  }, []);

  return (
    <Row>
      <StyledSummaryCol>
        <Row>
          <Col span={1}></Col>
          <h3 style={{ color: theme["@primary-color"] }}>Request Summary</h3>
        </Row>
        <Row>
          <Col span={1}></Col>
          <Col xl={5} lg={5} md={11} sm={11} xs={23}>
            <StyledSummaryRow>
              Date:&nbsp;&nbsp;
              <StyledP>
                {moment(
                  pharmacyRequestSummary
                    ? pharmacyRequestSummary?.date
                    : patientData?.date
                ).format("DD-MM-YYYY")}
              </StyledP>
            </StyledSummaryRow>
            <StyledSummaryRow>
              Insurance Plan:&nbsp;&nbsp;
              <StyledP>
                {insuranceData
                  ? insuranceData?.planName
                  : patientData?.insuranceData?.planName}
              </StyledP>
            </StyledSummaryRow>
          </Col>
          <Col xl={0} lg={0} md={1} sm={1} xs={1}></Col>
          <Col xl={5} lg={5} md={11} sm={11} xs={23}>
            <StyledSummaryRow>
              Visit:&nbsp;&nbsp;
              <StyledP>
                {pharmacyRequestSummary
                  ? pharmacyRequestSummary?.visitType
                  : patientData?.visitType?.value}
              </StyledP>
            </StyledSummaryRow>
            <StyledSummaryRow>
              Insurance Type:&nbsp;&nbsp;
              <StyledP style={{ textTransform: "capitalize" }}>
                {insuranceDeductibleType ? insuranceDeductibleType : "-"}
              </StyledP>
            </StyledSummaryRow>
          </Col>
          <Col xl={0} lg={0} md={1} sm={1} xs={1}></Col>
          <Col xl={5} lg={5} md={11} sm={11} xs={23}>
            <StyledSummaryRow>
              Time:&nbsp;&nbsp;
              <StyledP>
                {moment(
                  pharmacyRequestSummary
                    ? pharmacyRequestSummary?.date
                    : patientData?.startTime
                ).format("hh:mm a")}
              </StyledP>
            </StyledSummaryRow>
            <StyledSummaryRow>
              Insurance Number:&nbsp;&nbsp;
              <StyledP>
                {insuranceData
                  ? insuranceData.number
                  : patientData?.insuranceData?.number}
              </StyledP>
            </StyledSummaryRow>
          </Col>
          <Col xl={0} lg={0} md={1} sm={1} xs={1}></Col>
          <Col>
            <StyledSummaryRow>
              Insurance Provider:&nbsp;&nbsp;
              <StyledP>
                {insuranceData
                  ? insuranceData?.insuranceProvider?.name
                  : patientData?.insuranceData?.insuranceProvider?.name}
              </StyledP>
            </StyledSummaryRow>
            <StyledSummaryRow>
              Expiry:&nbsp;&nbsp;
              <StyledP>
                {moment(
                  insuranceData
                    ? insuranceData?.expireDate
                    : patientData?.insuranceData?.expireDate
                ).format("DD-MM-YYYY")}
              </StyledP>
            </StyledSummaryRow>
          </Col>
        </Row>
      </StyledSummaryCol>
    </Row>
  );
};
export default RequestSummary;
