import axiosInstance from "../config/axiosInstance";

const WARD_CATEGORY_PREFIX = "/ward";

export const getWards = () => {
  return axiosInstance.get(`${WARD_CATEGORY_PREFIX}/fetchAllWard`);
};

export const addNewWard = (data) => {
  return axiosInstance.post(`${WARD_CATEGORY_PREFIX}/addWard`, data);
};

export const insertNewWard = (data) => {
  return axiosInstance.post(`${WARD_CATEGORY_PREFIX}/addWard`, data);
};

export const editWard = (data, id) => {
  return axiosInstance.put(`${WARD_CATEGORY_PREFIX}/editWard/${id}`, data);
};

export const getWardDetail = (id) => {
  return axiosInstance.get(`${WARD_CATEGORY_PREFIX}/getWard/${id}`);
};

export const deleteWard = (id) => {
  return axiosInstance.delete(`${WARD_CATEGORY_PREFIX}/deleteWard/${id}`);
};
