export const getHospitalId = () => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      console.log('user us', user.hospitalId);

      return user.hospitalId;
    }
  }
};

export const getUserRoles = () => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));

      return user.roles;
    }
  }
};

export const getHospitalName = () => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));

      return user.hospitalName;
    }
  }
};

export const getDoctorId = () => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));

      return user.id;
    }
  }
};
