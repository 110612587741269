import React, { useState, useEffect } from "react";
import {
  SectionHeading,
  SectionHeadContainer,
  StyledDiv,
} from "../styledComponents";
import { UserAddOutlined } from "@ant-design/icons";
import { NewButton, BodyContainer } from "./StyledComponent";
import { Row, Col, message, Button, notification } from "antd";
import {
  changeSectionOrder,
  fetchMenuSections,
} from "../../../../apis/healthRecord";
import RadiologyManagementTable from "./RadiologyManagementTable";
import SectionColumns from "./TableConfig";
import {
  fetchLabTests,
  addLabTests,
  fetchRadiologyTests,
  addRadiologyTests,
  updateRadiologyTests,
} from "../../../../apis/Admin";
import NewRadiologyTypeModal from "./NewRadiologyTypeModal";
import ConfirmationModal from "./ConfirmationModal";
import styled from "styled-components";
import { useSelector } from "react-redux";

const LabTypes = ({
  setTab,
  setSelectedSection,
  setTotalSections,
  selectedLabTestTypes,
  totalSections,
  selectedSection,
}) => {
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [changesOrderStatus, setChangeOrderStatus] = useState(false);
  const [edit, setEdit] = useState();
  const theme = useSelector((state) => state.themeReducer);

  const getMenuItem = () => {
    setLoading(true);
    var searchQuery = "type=RADIOLOGY";
    fetchRadiologyTests(searchQuery)
      .then(({ data, status }) => {
        if (data) {
          if (data.status === 1) {
            setSections(data.body);

            setTotalSections(data.body.length);
          } else message.error(data.body);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const newSectionHandler = () => {
    setEdit(false);
    setNewModal(true);
  };
  const update = (type) => {
    // api to add lab
    const payload = {
      name: type.type,
      type: "RADIOLOGY",
      id: selectedSection.id,
      fee: type.fee,
    };

    updateRadiologyTests(payload).then((data) => {
      // success mesage

      getMenuItem();
      if (data.data.status === 1) {
        notification.success({
          message: data.data.body,
        });
        getMenuItem();
      } else {
        notification.error({
          message: data.data.body,
        });
      }
    });
    setNewModal(false);
  };
  const addType = (type) => {
    // api to add lab
    const payload = {
      name: type.type,
      type: "RADIOLOGY",
      fee: type.fee,
    };
    addRadiologyTests(payload).then((data) => {
      // success mesage
      getMenuItem();
      if (data.data.status === 1) {
        notification.success({
          message: data.data.body,
        });
        getMenuItem();
      } else {
        notification.error({
          message: data.data.body,
        });
      }
    });
    setNewModal(false);
  };
  useEffect(() => {
    getMenuItem();
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <SectionHeadContainer>
            <Row justify="space-between">
              {/* <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}> */}
              <Col>
                <SectionHeading>
                  <span style={{ color: "#999" }}>Radiology</span>
                </SectionHeading>
              </Col>
              {/* <Col style={{ alignItems: "flex-end", display: "flex" }}>
                <Button
                  type="primary"
                  // ghost
                  size="large"
                  style={{ borderRadius: "5px" }}
                  // icon={<UserAddOutlined />}
                  onClick={newSectionHandler}
                >
                  New Type
                </Button>
              </Col> */}
            </Row>
          </SectionHeadContainer>
        </Col>
        <Col span={24}>
          <BodyContainer>
            <Row gutter={[0, 18]} style={{ marginBottom: "0" }}>
              <Col span={24} style={{ padding: "0" }}>
                <Row justify="space-between">
                  <Col span={24} style={{ padding: "2.5px 0" }}>
                    <Row gutter={12} justify="end">
                      <Button
                        type="primary"
                        ghost
                        size="large"
                        style={{
                          // borderRadius: "5px",
                          border: 0,
                          boxShadow: "none",
                        }}
                        // icon={<UserAddOutlined />}
                        onClick={newSectionHandler}
                      >
                        New Type
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <StyledDiv bgcolor={theme["@primary-color"]}>
                <div>&nbsp;&nbsp;Types</div>
                <div>&nbsp;&nbsp;</div>
              </StyledDiv>
            </Row>
            <RadiologyManagementTable
              dataSource={sections}
              columns={SectionColumns(
                setTab,
                setSelectedSection,
                setConfirmationModal,
                setNewModal,
                setEdit
              )}
              loading={loading}
              setDataSource={setSections}
              draggable={changesOrderStatus}
              preventLast={true}
            />
            <NewRadiologyTypeModal
              visible={newModal}
              setVisible={setNewModal}
              addType={addType}
              edit={edit}
              setEdit={setEdit}
              selectedItem={selectedSection}
              setSelectedItem={setSelectedSection}
              update={update}
              head={"Type"}
            />
            <ConfirmationModal
              visible={confirmationModal}
              setVisible={setConfirmationModal}
              selectedItem={selectedSection}
              refetch={getMenuItem}
            />
          </BodyContainer>
        </Col>
      </Row>
    </>
  );
};
export default LabTypes;
