import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  CollapseButton,
  ImmediateDiv,
  RightButton,
  StyledDatePicker,
  StyledDiv,
  StyledH3,
  StyledIcon,
  StyledInputNumber,
  StyledNoActive,
  StyledP,
  StyledPayment,
  TimeDiv,
  TimeP,
  BookButton,
  BookBtnDiv,
} from '../Content/Appoinment/styledComponents';

import Icon, {
  ArrowsAltOutlined,
  ClockCircleOutlined,
  ShrinkOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import FormBuilder from '../utils/FormBuilder';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../utils/Spinner';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Moment from 'moment-timezone';
import { getSettings } from '../../apis/settings';
import SelectTimeModal from '../../components/Content/Appoinment/SelectTimeModal';
import { useQuery } from '../utils/UseQuery';
import {
  getHospitalDetail,
  getAvailableTimes,
  createAppointment,
  searchAppointmentUnderGivenDate,
  getHospitalDetailWithHospitalId,
  getDoctors,
} from '../../apis/Public';
import md5 from 'md5';
import StatusModal from './StatusModal';
import { LeftCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

const AppointmentFormPatient = ({ patientId, appointmentId, user }) => {
  console.log('patientId', patientId);
  const patient = useSelector((state) => state.newPatientReducer);
  console.log('reduc pat,', patient);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [formInitialValues, setFormInitialValues] = useState([]);
  const [docConsultationFee, setdocConsultationFee] = useState(0);

  const [departmentsLoading, setDepartmentsLoading] = useState(false);
  const [doctorsLoading, setDoctorsLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [docId, setDocId] = useState(0);
  const [allSlots, setAllSlots] = useState([]);
  const [availableSlots, setAvailableSlots] = useState(null);
  const [firstAvailableSlot, setFirstAvailableSlot] = useState(null);
  const [secondAvailableSlot, setSecondAvailableSlot] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [settings, setSettings] = useState();
  const [amountPayable, setAmountPayable] = useState(0);
  const [underFollowUpPeriod, setUnderFollowUpPeriod] = useState();
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [consultationFees, setConsultationFees] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [doctorName, setDoctorName] = useState([]);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  console.log('consultationfee', consultationFees);

  //insurance
  const [isInsurance, setIsInsurance] = useState(false);
  const [insuranceClaim, setInsuranceClaim] = useState(0);

  const [readOnly, setReadOnly] = useState(false);
  const [bookDisable, setBookDisable] = useState(false);

  const [identity, setIdentity] = useState('');
  const [followupDate, setFollowupDate] = useState('');

  let query = useQuery();
  const hospId = query.get('hId');
  // const doctId = query.get("dId");
  const hId = hospId ? md5(hospId) : '';
  // const dId = md5(doctId);

  const appointmentStatus = ['New', 'Follow Up'];
  const history = useHistory();
  const theme = useSelector((state) => state.themeReducer);

  const handleChange = (changed, all) => {
    console.log('changed is', changed, all);
    if (changed.identity === 'Follow Up') {
      setIsFollowUp(true);
      setIdentity(changed.identity);
      findAmountPayable();
    }
    if (changed.identity === 'New') {
      setIsFollowUp(false);
      setUnderFollowUpPeriod();
      setIdentity(changed.identity);
      form.setFieldsValue({ doctorFee: consultationFees });
      form.setFieldsValue({ followUpDate: null });
    }
    if (changed.followUpDate && all.identity === 'Follow Up') {
      console.log('date follow', changed?.followUpDate?._d);
      setFollowupDate(changed?.followUpDate?._d);
    }
    if (changed.department) {
      let changeDepartment;

      if (changed.department != changeDepartment) {
        console.log('changeDepartment in if', changeDepartment);
        console.log('changed.department in if', changed.department);
        form.setFieldsValue({
          doctor: '',
          date: '',
          identity: '',
          doctorFee: '',
          followUpDate: '',
        });
        setAmountPayable(0);
        changeDepartment = changed.department;
        console.log('changeDepartment', changeDepartment);
        console.log('changed.department', changed.department);
      }
    }
    if (changed.doctor) {
      let changedDoctor;

      if (changed.doctor != changedDoctor) {
        console.log('changeDepartment in if', changedDoctor);
        console.log('changed.doctor in if', changed.doctor);
        form.setFieldsValue({
          date: '',
          identity: '',
          doctorFee: '',
          followUpDate: '',
        });
        changedDoctor = changed.doctor;
        setAmountPayable(0);
        console.log('changedDoctor', changedDoctor);
        console.log('changed.doctor', changed.doctor);
      }
    }
  };

  /*calculating payment*/
  const findAmountPayable = (claim, consultationFee, tempSettings) => {
    var vat;
    var payable;
    if (!claim) claim = insuranceClaim;
    if (consultationFee) {
      vat = (tempSettings?.vat / 100) * (consultationFee - claim);
      payable = parseFloat(vat) + parseFloat(consultationFee);
    }
    if (payable)
      if (payable < 0) setAmountPayable(0);
      else setAmountPayable(payable);
  };

  const underFolloUpDaysCalculation = (tempUnderFollowUp) => {
    if (tempUnderFollowUp) {
      form.setFieldsValue({
        doctorFee: 0,
      });
      setAmountPayable(0);
    } else {
      form.setFieldsValue({ doctorFee: consultationFees });
      findAmountPayable(null, consultationFees, settings);
    }
  };
  // useEffect(() => {
  //   findAmountPayable();
  // }, [form.getFieldValue("doctorFee")]);

  useEffect(() => {
    var tempSettings;
    getSettings(null, hId).then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          setSettings(data.body);
          tempSettings = data.body;
        }
      }
    });

    getHospitalDetailWithHospitalId(hId)
      .then((response) => {
        console.log('resp in getHosp', response);
        if (response.data.status === 1) {
          setDepartments(response.data.body.departments);
          setAppointmentTypes(response.data.body.appointmentTypes);
        } else {
          message.error('Could not fetch departments, Try Again');
        }
      })
      .catch((err) => {
        message.error('Could not fetch departments, Try Again-catch');
        console.log('catch err', err);
      });
  }, []);

  useEffect(() => {
    formInitialValues.initial = true;
    handleChange(formInitialValues, formInitialValues);
  }, [formInitialValues]);

  const disabledDateFollowUp = (current) => {
    return current > moment().startOf('day');
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  /*time function called when date is selected*/
  const getTimes = (e) => {
    var tempUnderFollowUp;
    console.log('e is', e);
    let doc = doctors.find((item) => item.id === docId);
    if (doc) {
      form.setFieldsValue({ doctorFee: doc.fee });
      let vat = (settings?.vat / 100) * (doc.fee - insuranceClaim);
      setAmountPayable(doc.fee + vat - insuranceClaim);
      // findInsuranceClaim(
      //   form.getFieldValue('insuranceDeductibleType')?.toLowerCase()
      // );
      setConsultationFees(`${doc.fee ? doc.fee : '0'} `);
    }
    const doctorId = md5(`${docId}`);

    const querryString = `doctor/${doctorId}/hospital/${hId}?timeZone=${Moment.tz.guess()}&date=${new Date(
      e._d
    ).getTime()}`;
    getAvailableTimes(querryString)
      .then((response) => {
        console.log('respo', response);
        if (response.data.status === 1) {
          const allSlots = response.data.body;
          const availableSlots = allSlots.filter(
            (slot) => slot.booked === false
          );
          const first = availableSlots[0];
          const second = availableSlots[1];
          setAllSlots(allSlots);
          setAvailableSlots(availableSlots);
          setFirstAvailableSlot(first);
          setSecondAvailableSlot(second);
          setBookDisable(false);
        } else if (response.status === 400) {
          setAvailableSlots('not available');
          setBookDisable(true);
        } else {
          message.error('Could not fetch appointment times, Try agian');
        }
      })
      .catch((err) => {
        message.error('Could not fetch appointment times, Try agian');
        console.error(err);
      });

    if (identity === 'Follow Up') {
      var data = {
        requestedDate: new Date(followupDate).getTime(),
        scheduleDate: new Date(e._d).getTime(),
        visitType: 'Hospital',
        timeZone: Moment.tz.guess(),
        patientId: patientId,
      };
      searchAppointmentUnderGivenDate(data)
        .then((res) => {
          if (res.status === 200) {
            tempUnderFollowUp = res.data.body.underFollowUpPeriod;
            underFolloUpDaysCalculation(tempUnderFollowUp);

            if (res.data.status === 1) {
              setUnderFollowUpPeriod(res.data.body);
            } else {
              message.error(res.data.body);
              form.setFieldsValue({ followUpDate: undefined });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          message.error('error in followup catch');
        });
    }
  };
  const getDoctorsList = (departmentId, initial) => {
    if (departmentId) {
      const depId = md5(`${departmentId}`);
      getDoctors(depId, hId)
        .then((response) => {
          if (response.data.status === 1) {
            setDoctorsLoading(false);
            if (response.data.body) setDoctors(response.data.body);
            setDocId(docId);
          } else {
            setDoctorsLoading(false);
            message.error('Could not fetch Doctors List, Try Again');
          }
        })
        .catch((err) => {
          setDoctorsLoading(false);
          console.log(err);
          message.error('Could not fetch Doctors List, Try Again');
        });
    }
  };
  const bookingStatusModalVisible = (cancel) => {
    setCancel(cancel);
    setStatusModalVisible(true);
  };

  const moreClicked = (e) => {
    setModalVisible(true);
  };
  const handleOk = (e) => {
    setModalVisible(false);
  };

  const handleCancel = (e) => {
    setModalVisible(false);
  };

  const changeTime = (index) => {
    const first = allSlots[index];
    setFirstAvailableSlot(first);
  };

  const cancelAppointment = () => {
    setCancelLoading(false);
    setStatusModalVisible(false);
    // history.push(`/${hospId}/${doctId}/new-patient-form`);
    message.success('Appointment cancelled succesfully');
    history.push(`/${hospId}/new-patient-form`);
  };
  const submitForm = (values) => {
    setBookDisable(true);
    var payload;
    payload = {
      hospitalId: hospId,
      appointmentIdentityType: values.identity,
      appointmentTypeId: values.appointmentType,
      date: values.date?.format('x'),

      departmentId: values.department,
      doctorId: values.doctor,
      // doctorId: doctId,
      endTime: firstAvailableSlot.endTime,
      patientId: patientId,
      reason: values.reason,
      startTime: firstAvailableSlot.startTime,
      doctorFee: parseFloat(consultationFees),
      visitType: 1,
      paymentType: 'SELF',
      vatPercentage: settings?.vat,
      totalFees: amountPayable,
      insuranceClaim: insuranceClaim,
      timeZone: Moment.tz.guess(),
    };
    createAppointment(payload)
      .then((response) => {
        if (response.data.status === 1) {
          bookingStatusModalVisible(false);
          message.success('Appointment booked succesfully');
          localStorage.removeItem('phone');
        } else {
          setBookDisable(false);
          message.error('Could not book appointment, Try again');
        }
      })
      .catch((err) => {
        setBookDisable(false);
        console.log(err);
        message.error('Could not book appointment, Try again');
      });
  };

  return (
    <>
      <Form
        className="appointment_form_style1 form-m2-style"
        layout="vertical"
        onFinish={(values) => {
          submitForm(values);
        }}
        initialValues={formInitialValues}
        // key={Object.keys(formInitialValues).length !== 0}
        form={form}
        scrollToFirstError={true}
        onValuesChange={handleChange}
      >
        <Row justify="start">
          <Col span={24} style={{ marginBottom: '10px' }}>
            <StyledH3 color={theme['@primary-color']}>
              <LeftCircleOutlined
                onClick={() => {
                  history.goBack();
                }}
              />
              &nbsp;Book an Appointment :
            </StyledH3>
          </Col>
        </Row>
        <Row gutter={[36, 0]}>
          <Col span="12" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="department"
              label="Department"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                loading={departmentsLoading}
                placeholder="Select Department"
                onSelect={(value) => getDoctorsList(value)}
              >
                {departments &&
                  departments.map((department) => {
                    return (
                      <Option value={department.id}>
                        {department.departmentName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="doctor"
              label="Doctor"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                loading={doctorsLoading}
                placeholder="Select Doctor"
                onSelect={(value) => setDocId(value)}
              >
                {doctors &&
                  doctors.map((doctor) => {
                    return (
                      <Option value={doctor.id}>
                        {doctor.firstName + ' ' + doctor.lastName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[36, 0]}>
          <Col span="12" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="identity"
              label="Type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select size="large">
                {appointmentStatus &&
                  appointmentStatus.map((type) => {
                    return <Option value={type}>{type}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          {isFollowUp && (
            <Col span="12" xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="followUpDate"
                label="Previous Consultation Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <StyledDatePicker
                  showToday={false}
                  // onChange={(date, dateString) =>
                  //   setDate(date?.format("x"))
                  // }

                  size="large"
                  disabledDate={disabledDateFollowUp}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
          )}
          <Col span="12" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="date"
              label="Consultation Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <StyledDatePicker
                // onChange={(date, dateString) => setDate(date?.format("x"))}
                onChange={getTimes}
                size="large"
                disabledDate={disabledDate}
                format="DD-MM-YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[36, 0]}>
          <Col span="12" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="doctorFee"
              label="Consultation Fee"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <StyledInputNumber
                size="large"
                disabled={user.userType === 'PATIENT'}
              />
            </Form.Item>
          </Col>
          <Col span="12" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="time" label="Time">
              <TimeDiv className="forminput_timepick">
                <TimeP>
                  {availableSlots === null
                    ? 'Select Date, Department and Doctor to view slot'
                    : availableSlots === 'not available'
                    ? 'No Slots available for the day'
                    : firstAvailableSlot
                    ? `${moment(firstAvailableSlot.startTime)?.format(
                        'hh:mm A'
                      )} -
                      ${moment(firstAvailableSlot.endTime)?.format('hh:mm A')} `
                    : 'No Slots available for the day'}
                </TimeP>
                <ClockCircleOutlined />
              </TimeDiv>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[36, 0]}>
          <Col span="12" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name=" " label=" " className="available_time_field">
              <StyledDiv>
                <StyledP>
                  {availableSlots === null
                    ? 'Select Date, Department and Doctor to view slots'
                    : availableSlots === 'not available'
                    ? 'No Slots available for the day'
                    : secondAvailableSlot
                    ? `Next Available Time:
                  ${moment(secondAvailableSlot.startTime)?.format('hh:mm A')} -
                  ${moment(secondAvailableSlot.endTime)?.format('hh:mm A')} `
                    : 'No Other slots available'}
                </StyledP>
                <Button
                  onClick={moreClicked}
                  disabled={
                    (availableSlots && availableSlots.length <= 0) ||
                    availableSlots === null
                  }
                >
                  More
                </Button>
              </StyledDiv>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[36, 0]}>
          <Col span={24}>
            <StyledH3 color={theme['@primary-color']}>
              Appointment Details
            </StyledH3>
          </Col>
        </Row>
        <Row>
          <Col span="12" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="appointmentType"
              label="Type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select size="large">
                {appointmentTypes &&
                  appointmentTypes.map((type) => {
                    return <Option value={type.id}>{type.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span="24" xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name="reason"
              label="Reason"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea rows={4} size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormBuilder
              formConfig={[
                {
                  inputType: 'heading',
                  label: 'Payment Summary',
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <StyledPayment style={{ lineHeight: '2' }}>
                  (A)&nbsp;Consultation Fee:{' '}
                </StyledPayment>
              </Col>
              <Col>
                <StyledPayment style={{ lineHeight: '2' }}>
                  <b>
                    {settings?.currency}&nbsp;
                    {parseFloat(
                      form?.getFieldValue('doctorFee')
                        ? form?.getFieldValue('doctorFee')
                        : 0
                    ).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <StyledPayment style={{ lineHeight: '2' }}>
                  ({isInsurance ? 'C' : 'B'})&nbsp;VAT&nbsp;
                  <p style={{ fontSize: '13px', display: 'contents' }}>
                    {isInsurance
                      ? `(A-B) * ${settings?.vat}%`
                      : `A * ${settings?.vat}%`}{' '}
                    :
                  </p>
                </StyledPayment>
              </Col>
              <Col>
                <StyledPayment style={{ lineHeight: '2' }}>
                  <b>
                    {settings?.currency}{' '}
                    {parseFloat(
                      insuranceClaim > form?.getFieldValue('doctorFee')
                        ? 0
                        : (settings?.vat / 100) *
                            (form?.getFieldValue('doctorFee')
                              ? form?.getFieldValue('doctorFee') -
                                insuranceClaim
                              : 0)
                    ).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
            </Row>

            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <StyledPayment style={{ lineHeight: '2' }}>
                  ({isInsurance ? 'D' : 'C'}) Amount Payable
                  <p style={{ fontSize: '13px', display: 'contents' }}>
                    {isInsurance ? '(A - B) + C' : 'A + B'}
                  </p>
                </StyledPayment>
              </Col>
              <Col>
                <StyledPayment style={{ lineHeight: '2' }}>
                  <b>
                    {settings?.currency}&nbsp;
                    {parseFloat(amountPayable).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
            </Row>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item>
              <BookBtnDiv>
                <BookButton
                  htmlType="submit"
                  type="primary"
                  className="btn-save"
                  disabled={bookDisable}
                  // onClick={() => setBookDisable(true)}
                >
                  Book
                </BookButton>

                <RightButton
                  className="btn-cancel"
                  type="primary"
                  ghost
                  onClick={() => {
                    bookingStatusModalVisible(true);
                  }}
                >
                  Cancel
                </RightButton>
              </BookBtnDiv>
            </Form.Item>{' '}
          </Col>
        </Row>
      </Form>
      <SelectTimeModal
        modalVisible={modalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        availableSlots={allSlots}
        changeTime={changeTime}
      />
      <StatusModal
        cancel={cancel}
        cancelAppointment={cancelAppointment}
        visible={statusModalVisible}
        closeModal={() => setStatusModalVisible(false)}
        cancelLoading={cancelLoading}
        hId={hospId}
        dId={docId}
      />
    </>
  );
};
export default AppointmentFormPatient;
