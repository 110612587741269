import React, { useState, useEffect } from "react";
import { Row, Col, message, Button, Tooltip, Card } from "antd";
import CustomModal from "../../utils/CustomModal.jsx/index.jsx";
import styled from "styled-components";
import { getBedStatus } from "../../../apis/bed.js";
import {
  getHospitalId,
  getDoctorId,
  getHospitalName,
} from "../../utils/LocalStorage/index";

const StatisticsWidget = () => {

  const [statisticsData, setStatisticsData] = useState([
    { title: "No of Active Wards", key: "noOfActiveWards", color: "#1890ff" },
    { title: "No of Beds", key: "noOfBeds", color: "#52c41a" },
    { title: "No Of Vacant Beds", key: "noOfVacccantBeds", color: "#faad14" },
    { title: "No of Occupied Beds", key: "noOfOccupiedBeds", color: "#ff4d4f" },
    { title: "No of Active Ipd", key: "noOfActiveIpd", color: "#597ef7" },
    {
      title: "OT Bookings (Next 30 Days)",
      key: "otBookingsInNext30Days",
      color: "#faad14",
    },
  ]);

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = () => {
    getBedStatus()
      .then((resp) => {
        // setStatisticsData1(resp.data.body);
        console.log("API Response:", resp.data.body);
        const updatedStatisticsData = statisticsData.map((item) => {
          return {
            ...item,
            value: resp.data.body?.[item.key] ?? 0,
          };
        });
        setStatisticsData(updatedStatisticsData);

        console.log("print" + resp.data);
      })
      .catch((err) => {
        message.error("Could not fetch total revenue, Try again");
        console.log("error is", err);
      });
  };

  return(
    <>
      <Row gutter={[16, 12]}>
      <Col span={8}>
        <Card
          style={{
            backgroundColor: "#52c41a", // You can customize the color
            height: "160px",
          }}
        >
          <h3 style={{ color: "white" }}>Beds Data</h3>
          <p style={{ color: "white", fontSize: "18px" }}>
            {statisticsData[3].value ?? 0} / {statisticsData[1].value ?? 0}
          </p>
          <p style={{ color: "white", fontSize: "18px" }}>
            Vacant beds: {statisticsData[2].value}
          </p>
        </Card>
      </Col>
      {statisticsData.map((statistic, index) =>
        index === 1 || index === 3 || index === 2 ? (
          // Beds Data Card
          <></>
        ) : (
          // Other Cards
          <Col span={5} key={index}>
            <Card
              style={{
                backgroundColor: statistic.color,
                height: "160px",
              }}
            >
              <h3 style={{ color: "white", fontSize: "14px" }}>
                {statistic.title}
              </h3>
              <p style={{ color: "white", fontSize: "18px" }}>
                {statistic.value}
              </p>
            </Card>
          </Col>
        )
      )}
    </Row>
    </>
  );
};

export default StatisticsWidget;
