import "./billing.css";

import {
  Avatar,
  Button,
  Col,
  Comment,
  Form,
  Modal,
  Row,
  Table,
  Tooltip,
  Typography,
  message,
  notification,
} from "antd";
import * as Sentry from "@sentry/react";
import React, { useEffect, useRef, useState } from "react";
import {
  changeInsuranceStatus,
  fetchInsuranceBilling,
  preApproveOrders,
  submitInsuranceClaim,
  submitPreApprovalRequest,
} from "../../../../apis/Insurance";
import {
  fetchBillingDetails,
  getAppointmentDetails,
} from "../../../../apis/Appointment";
import { useHistory, useParams } from "react-router-dom";

import ConfirmationModal from "./ConfirmationModal";
import CustomTable from "../../../utils/CustomTable";
import DefaultAvatar from "../../../../assets/images/avatarUser.png";
import { ReactComponent as DoctorIcon } from "../../../../assets/icons/304919-black.svg";
import FormBuilder from "../../../utils/FormBuilder";
import HospitalLogo from "../../../Login/HospitalLogo";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";
import { PrinterOutlined } from "@ant-design/icons";
import RequestSummary from "../RequestSummary";
import Spinner from "../../../utils/Spinner";
import { StyledDiv } from "../../AdminDashboard/styledComponents";
import { StyledPayment } from "./styledComponents";
import SuccessModal from "../../../utils/StatusModals/SuccessModals";
import TableContainer from "../TableContainer";
import TextArea from "antd/lib/input/TextArea";
import { approvalColumns } from "./billingsColumns";
import { getSettings } from "../../../../apis/settings";
import { migrateIntoTableFormat } from "../helper";
import moment from "moment";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";

const { Text } = Typography;

const StyledModal = styled(Modal)`
  width: 920px !important;
`;

const BillContainer = styled.div`
  padding: 20px;
  width: 95%;
  height: 100%;
`;

const SubmitButtonCol = styled(Col)`
  margin-top: 20px;
`;

const StyledDocIcon = styled(DoctorIcon)`
  height: 60px;
  width: 50px;
`;

const Hospitalname = styled.h2`
  color: black;
  text-transform: capitalize;
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
`;

const BillDetailsDiv = styled.div`
  margin: 100px 50px;
  display: flex;
  flex-direction: column;
  & p {
    font-size: 20px;
    font-weight: 500;
  }
`;

const StyledTableRow = styled(Row)`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
`;

const Heading = styled.h3`
  font-weight: 600;
  padding-bottom: 20px;
  color: ${(props) => props.color};
  @media (max-width: 1440px) {
    font-size: 20px;
  }
`;

const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;

const BottomContainer = styled(Col)`
  margin-top: 20px;
`;

const CommentLabel = styled.p``;

const StyledAvatar = styled(Avatar)`
  & .cls-1 {
    fill: black;
  }
`;

const BlueBgTable = styled(Table)`
  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    /* position: relative; */
    font-size: 14px !important;
    padding: 8px !important;
    /* overflow-wrap: break-word; */
    margin: 0px;
  }
  & .ant-table-thead > tr > th {
    /* padding: 8px 0 !important; */
    background: white;
    font-weight: 600;
  }
  & .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:first-child {
    border-left: 1px solid #f0f0f0;
  }

  /* & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #9e9292;
  } */
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #f0f0f0;
    font-size: 12px !important;
  }
  & .ant-table-tbody > tr > td.total {
    font-size: 18px !important;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #f0f0f0;
  }
`;

const StyledSummaryCol = styled(Col)`
  background: #fff;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0 0 15px 0;
  border-radius: 10px;
  @media (max-width: 600px) {
    padding: 30px 0 0 0;
  }
  @media (max-width: 480px) {
    padding: 30px 10px 0;
  }
`;

const StyledSummaryRow = styled(Row)`
  font-weight: 600;
  color: rgb(93, 93, 93);
`;

const StyledP = styled.p`
  display: contents;
  font-size: 12px;
  font-weight: normal;
`;

const ClaimStatusContainer = styled(Row)`
  height: 30px;
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 10px;
  background-color: ${(props) =>
    props.color == "green"
      ? "#00800038"
      : props.color == "red"
      ? "#ff000038"
      : ""};
  @media (max-width: 600px) {
    text-align: center;
  }
`;

const BillingSection = () => {
  const patient = useSelector((state) => state.patientReducer);
  const user = useSelector((state) => state.userReducer);
  const previousDataColumns = [
    { width: "1vw" },
    { title: "Date", dataIndex: "Date" },
    { title: "Bill No", dataIndex: "Bill No" },
    { title: "Hospital Name", dataIndex: "Hospital Name" },
    { title: "Visit Type", dataIndex: "Visit Type" },
    { title: "Doctor Name", dataIndex: "Doctor Name" },
    { title: "Department", dataIndex: "Department" },
    {
      title: "Bill Amount",
      dataIndex: "Bill Amount",
      render: (data) => settings?.currency + data,
    },
    // { width: "1vw" },
  ];
  const insuranceBillColumns = [
    { width: "1vw" },
    { title: "Date", dataIndex: "Date" },
    {
      title: "Visit Type",
      // dataIndex: "Visit Type",
      render: (data) => {
        if (data.Department == "Laboratory" || data.Department == "Radiology")
          return "Hospital";
        else return data["Visit Type"];
      },
    },
    { title: "Doctor", dataIndex: "Doctor Name" },
    { title: "Department", dataIndex: "Department" },
    {
      title: "Bill Amount",
      // dataIndex: "Bill Amount",
      render: (data) => {
        if (data["Bill Amount"])
          return settings?.currency + " " + data["Bill Amount"];
        else return settings?.currency + " " + data.fee;
      },
    },
    // { width: "1vw" },
  ];
  const billDataColumns = [
    { width: "1vw" },
    {
      title: "Sl. No",
      render: (value, item, index) => {
        return index + 1;
      },
      width: "3vw",
    },
    // { title: "Date", dataIndex: "Date" },
    // { title: "Bill No", dataIndex: "Bill No" },
    {
      title: "Appointment Type",
      dataIndex: ["appointment", "appointmentType", "name"],
    },
    {
      title: "Time",
      dataIndex: ["appointment", "inTimeDoc"],
    },
    { title: "Visit Type", dataIndex: "Visit Type" },
    // { title: "Doctor Name", dataIndex: "Doctor Name" },
    // { title: "Department", dataIndex: "Department" },
    {
      title: "Bill Amount",
      // dataIndex: ["appointment", "bill"],
      width: "30vw",
      render: (item) => (
        <div style={{ fontSize: "12px" }}>
          <Row>
            <Col span={14}>Fee </Col>
            <Col>
              : {settings?.currency} {item?.appointment?.bill.fee}
            </Col>
          </Row>
          <Row>
            <Col span={14}>Insurance Claim </Col>
            <Col>
              : {settings?.currency} {item?.appointment?.bill.insuranceClaim}
            </Col>
          </Row>
          <Row>
            <Col span={14}>VAT({item?.appointment?.bill.vatPercentage}%) </Col>
            <Col>
              : {settings?.currency}{" "}
              {(item?.appointment?.bill.fee -
                item?.appointment?.bill.insuranceClaim) *
                (item?.appointment?.bill.vatPercentage / 100)}
            </Col>
          </Row>
          <Row>
            <Col span={14} className="total">
              Total{" "}
            </Col>
            <Col className="total">
              : {settings?.currency} {item?.appointment?.bill.totalFee}
            </Col>
          </Row>
        </div>
      ),
    },
  ];
  const [formFetchLoading, setFormFetchLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [billingData, setBillingData] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState(null);
  const [changedStatus, setChangedStatus] = useState("");
  const theme = useSelector((state) => state.themeReducer);
  const images = useSelector((state) => state.imageReducer);
  const [comments, setComments] = useState([]);
  const params = useParams();
  const componentRef = useRef();
  const history = useHistory();
  const [form] = Form.useForm();
  const [hospitalLogo, setHospitalLogo] = useState();
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [settings, setSettings] = useState();
  const [acceptedList, setAcceptedList] = useState([]);
  const [declinedList, setDeclinedList] = useState([]);
  const [appointmentId, setAppointmentId] = useState();
  const [preApprovalSubmit, setPreApprovalSubmit] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const useeffectFunctions = () => {
    if (user.userType === "INSURANCE") {
      setInsurance(true);
      const insuranceRequestId = history.location?.state?.insuranceRequestId
        ? history.location.state.insuranceRequestId
        : localStorage.getItem("insuranceRequestId");
      var appointmentId;
      try {
        appointmentId = history.location.pathname?.split("/")?.slice(-3)[0];
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      }
      appointmentId = appointmentId ? appointmentId : patient.appointmentId;
      if (appointmentId) setAppointmentId(appointmentId);
      else setAppointmentId(patient.appointmentId);
      if (history.location.state?.pharmacy) appointmentId = undefined;
      fetchInsuranceBilling(insuranceRequestId, appointmentId)
        .then((res) => {
          if (res.data.status === 1) {
            setComments(res.data.body.comments);
            setStatus(res.data.body.status);

            let data = res.data.body.tableData;

            if (!res.data.body?.totalAmount) {
              var totalAmount = 0;
              data.forEach((element) => {
                totalAmount += element.fee;
              });
              res.data.body.totalAmount = totalAmount;
            }
            setBillingData(res.data.body);
            var declained = [];
            var accepted = [];
            data = data.map((item) => {
              item.Date = moment(item.Date).format("DD-MM-YYYY");
              if (!item.testDataId) item.testDataId = item.subSectionId;
              if (!item.fee) item.fee = item.drugInfo?.sellingPrice;
              if (
                item.paymentStatus
                  ? item.paymentStatus == "Insurance Claim Rejected"
                  : item.additionalInfo?.paymentStatus ==
                    "Insurance Claim Rejected"
              )
                declained = declained.concat(item.testDataId);
              else if (
                item.paymentStatus
                  ? item.paymentStatus == "Insurance Claimed"
                  : item.additionalInfo?.paymentStatus == "Insurance Claimed"
              )
                accepted = accepted.concat(item.testDataId);

              return item;
            });
            setTableData(migrateIntoTableFormat(data));
            if (declained.length > 0) setDeclinedList(declained);
            if (accepted.length > 0) setAcceptedList(accepted);
            // if (history?.location?.state?.preApproval == true)
            // form.setFieldsValue({ comment: res.data.body.preApprovalNote });
            setFormFetchLoading(false);
            if (
              declained.length + accepted.length ==
              res.data.body.tableData?.length
            )
              setPreApprovalSubmit(true);
          } else {
            setFormFetchLoading(false);
            message.error("Could not fetch billing details,Try again");
          }
        })
        .catch((err) => {
          setFormFetchLoading(false);
          console.log(err);
          Sentry.captureException(err);
          message.error("Could not fetch billing details,Try again");
        });
    } else {
      fetchBillingDetails(patient.appointmentId, patient.pId)
        .then((res) => {
          setFormFetchLoading(false);
          if (res.data.status === 1) {
            let data = res.data.body;
            data.map((item) => {
              item.Date = moment(item.Date).format("DD-MM-YYYY");
            });
            setTableData(migrateIntoTableFormat(data));
          } else {
            message.error("Could not fetch billing details,Try again");
          }
        })
        .catch((err) => {
          setFormFetchLoading(false);
          console.log(err);
          Sentry.captureException(err);
          message.error("Could not fetch billing details,Try again");
        });
    }
  };
  useEffect(() => {
    getSettings().then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          setSettings(data.body);
        }
      }
    });
    var appointmentId;
    try {
      appointmentId = history.location.pathname?.split("/")?.slice(-3)[0];
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
    if (appointmentId) setAppointmentId(appointmentId);
    else setAppointmentId(patient.appointmentId);

    getAppointmentDetails(appointmentId ? appointmentId : patient.appointmentId)
      .then((response) => {
        if (response.data.status === 1)
          setAppointmentDetails(response.data.body);
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        message.error("Could not fetch appointment details,Try again");
      });

    const objectURL = PROFILE_IMAGE_PREFIX + images.logo;
    fetch(objectURL, {
      method: "GET",
      headers: { "Content-Type": "application/octet-stream" },
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        setHospitalLogo(url);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (user) {
      useeffectFunctions();
    }
  }, [user]);

  useEffect(() => {
    if (history.location.state?.waitingForPreApproval) {
      try {
        setPreApprovalSubmit(false);
        var totalAmountOnApprovedOrder = 0;
        var payable = 0;
        var claimableAmount = 0;
        acceptedList.forEach((element) => {
          var row = tableData.find((item) => item.testDataId == element);
          totalAmountOnApprovedOrder += row?.fee;
        });
        declinedList.forEach((element) => {
          var row = tableData.find((item) => item.testDataId == element);
          payable += row?.fee;
        });

        var insuranceDeductibleType =
          appointmentDetails.insuranceDeductibleType;
        var visitType = appointmentDetails.visitType?.value.toLowerCase();
        let providerData =
          appointmentDetails.insuranceData?.insuranceProvider?.[
            `${visitType}InsuranceProviderData`
          ];
        if (insuranceDeductibleType == "OWN") {
          if (providerData.selfType == "Fixed Amount")
            claimableAmount = providerData.selfAmount;
          else if (providerData.selfType == "Percentage")
            claimableAmount =
              totalAmountOnApprovedOrder * (providerData.selfAmount / 100);
        } else {
          if (providerData.copayType == "Fixed Amount")
            claimableAmount = providerData.copayAmount;
          else if (providerData.copayType == "Percentage")
            claimableAmount =
              totalAmountOnApprovedOrder * (providerData.copayAmount / 100);
        }

        if (totalAmountOnApprovedOrder < claimableAmount)
          claimableAmount = totalAmountOnApprovedOrder;
        billingData.claimableAmount = claimableAmount;
        let vat =
          (billingData.totalAmount - claimableAmount) * (settings?.vat / 100);
        billingData.vat = vat;
        billingData.payable = billingData.totalAmount - claimableAmount + vat;
        billingData.payInCash = payable;
        billingData.totalAmountOnApprovedOrder = totalAmountOnApprovedOrder;
        var newBillingData = Object.assign({}, billingData);
        console.log(newBillingData, "billing");
        setBillingData(newBillingData);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      }
    }
  }, [acceptedList, declinedList]);

  const appendCloseButton = (data, columns) => {
    columns[columns.length - 1].render = (value, record, index) => {
      return (
        <Row justify="space-between" gutter={36}>
          <Col>{value}</Col>
          {!insurance ? (
            <Col>
              <Tooltip title="Print">
                <PrinterOutlined
                  style={{
                    fontSize: "20px",
                    color: theme["@primary-color"],
                  }}
                  onClick={() => {
                    setSelectedData(record);
                    setModalVisible(true);
                    setTimeout(() => {
                      handlePrint();
                    }, 2000);
                    console.log(record);
                  }}
                />
              </Tooltip>
            </Col>
          ) : (
            <></>
          )}
          <Col style={{ padding: "5px" }}></Col>
        </Row>
      );
    };
    return columns;
  };

  const summary = () => {
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true}>Total Amount</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true}>
              {settings?.currency}{" "}
              {parseFloat(billingData?.totalAmount).toFixed(2)}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true}> VAT</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true}>
              {settings?.currency} {parseFloat(billingData?.vat).toFixed(2)}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true}> Payable Amount</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true}>
              {settings?.currency} {parseFloat(billingData?.payable).toFixed(2)}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true} style={{ color: theme["@primary-color"] }}>
              Claimable Amount
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true} style={{ color: theme["@primary-color"] }}>
              {settings?.currency}{" "}
              {parseFloat(billingData?.claimableAmount).toFixed(2)}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const statusMap = {
    WAITING: "Claim Waiting",
    DECLINE: "Declined",
    ACCEPT: "Paid",
  };

  const preApprovalOrders = (status) => {
    if (status == "SAVE") {
      var payload = {
        appointmentId: appointmentId,
        insuranceRequestId: history.location?.state?.insuranceRequestId
          ? history.location.state.insuranceRequestId
          : localStorage.getItem("insuranceRequestId"),
        notes: form.getFieldValue("comment"),
        testIdsOfInsurancePayment: acceptedList,
        testIdsOfInsuranceRejected: declinedList,
        visitType: appointmentDetails.visitType?.value,
      };
      preApproveOrders(payload)
        .then((res) => {
          if (res.data.status == 1) {
            message.success(res.data.body);
            if (tableData.length == acceptedList.length + declinedList.length)
              setPreApprovalSubmit(true);
          } else {
            message.error(res.data.body);
          }
        })
        .catch(console.log)
        .finally(() => {
          setSubmitLoading(false);
        });
    } else if (status == "SUBMIT") {
      const insuranceRequestId = history.location?.state?.insuranceRequestId
        ? history.location.state.insuranceRequestId
        : localStorage.getItem("insuranceRequestId");
      submitPreApprovalRequest(appointmentId, insuranceRequestId)
        .then((res) => {
          if (res.data.status == 1) {
            message.success(res.data.body);
            history.push("/dashboard");
          } else {
            message.error(res.data.body);
          }
        })
        .catch(console.log)
        .finally(() => {
          setSubmitLoading(false);
          setPreApprovalSubmit(false);
        });
    }
  };

  const changeStatus = (insuranceStatus) => {
    const insuranceRequestId = history.location?.state?.insuranceRequestId
      ? history.location.state.insuranceRequestId
      : localStorage.getItem("insuranceRequestId");
    const status = statusMap[insuranceStatus];
    const payload = {
      comment: comment,
      insuranceRequestId: insuranceRequestId,
      status: status,
    };
    changeInsuranceStatus(payload)
      .then((resp) => {
        setSubmitLoading(false);
        if (resp.data.status === 1) {
          setComment("");
          form.resetFields();
          setConfirmationModal(false);
          setSuccessModal(true);

          // switch (insuranceStatus) {
          //   case "WAITING":
          //     notification.success({
          //       message: "Successfully submitted claim.",
          //     });
          //     break;
          //   case "ACCEPT":
          //     notification.success({ message: "Successfully accepted claim." });
          //     break;
          //   default:
          //     notification.success({ message: "Claim declained" });
          // }
          useeffectFunctions();
        } else {
          setConfirmationModal(false);
          message.error("Something went wrong, Try again.");
        }
      })
      .catch((err) => {
        setConfirmationModal(false);
        console.log(err);
        Sentry.captureException(err);
        message.error("Something went wrong, Try again.");
        setSubmitLoading(false);
      });
  };

  const submitClaim = (insuranceStatus) => {
    setSubmitLoading(true);
    changeStatus(insuranceStatus);
  };

  return !formFetchLoading ? (
    <>
      <RequestSummary
        appointmentId={appointmentId}
        insuranceData={billingData?.insuranceData}
        pharmacyRequestSummary={billingData?.pharmacyRequestSummary}
        insuranceDeductibleType={billingData?.insuranceDeductibleType}
      />
      <Row>
        <StyledSummaryCol ref={componentRef}>
          <Row>
            <Col span={1}></Col>
            <h3 style={{ color: theme["@primary-color"] }}>
              {insurance ? "Billing" : "Billing History"}
            </h3>
          </Row>
          <Col span={24} style={{ padding: "10px 20px" }}>
            {billingData?.status == "Waiting for Approval" ||
            history?.location?.state?.visitType ||
            history?.location?.state?.pharmacy ? (
              <>
                <StyledDiv bgcolor={theme["@primary-color"]}>
                  <div>
                    &nbsp;&nbsp;
                    {"Tests"}
                  </div>
                  <div>&nbsp;&nbsp;</div>
                </StyledDiv>
                <BlueBgTable
                  pagination={false}
                  columns={approvalColumns(
                    theme,
                    settings,
                    acceptedList,
                    setAcceptedList,
                    declinedList,
                    setDeclinedList,
                    history?.location?.state?.visitType
                      ? history?.location?.state?.visitType
                      : billingData?.status == "Pending" ||
                        billingData?.status == "Paid" ||
                        billingData?.status == "Declined" ||
                        history.location.state?.pharmacy
                      ? true
                      : false,
                    history.location.state?.pharmacy
                  )}
                  dataSource={tableData}
                />
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  <FormBuilder
                    formConfig={[
                      {
                        inputType: "heading",
                        label: "Payment Summary",
                      },
                    ]}
                  />
                  <Row justify="space-between">
                    <Col xl={18} lg={18} md={18} sm={18} xs={23}>
                      <StyledPayment>&nbsp;Total Amount: </StyledPayment>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={23}>
                      <StyledPayment>
                        <b>
                          {settings?.currency}&nbsp;
                          {parseFloat(
                            billingData?.totalAmount
                              ? billingData?.totalAmount
                              : 0
                          ).toFixed(2)}
                        </b>
                      </StyledPayment>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col xl={18} lg={18} md={18} sm={18} xs={23}>
                      <StyledPayment>
                        &nbsp;Insurance Claim Amount:{" "}
                      </StyledPayment>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={23}>
                      <StyledPayment>
                        <b>
                          {settings?.currency}&nbsp;
                          {parseFloat(
                            billingData?.totalAmountOnApprovedOrder
                              ? billingData.totalAmountOnApprovedOrder
                              : 0
                          ).toFixed(2)}
                        </b>
                      </StyledPayment>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col xl={18} lg={18} md={18} sm={18} xs={23}>
                      <StyledPayment>&nbsp;Paid in Cash: </StyledPayment>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={23}>
                      <StyledPayment>
                        <b>
                          {settings?.currency}&nbsp;
                          {parseFloat(
                            billingData?.payInCash ? billingData.payInCash : 0
                          ).toFixed(2)}
                        </b>
                      </StyledPayment>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col xl={18} lg={18} md={18} sm={18} xs={23}>
                      <StyledPayment>&nbsp;VAT: </StyledPayment>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={23}>
                      <StyledPayment>
                        <b>
                          {settings?.currency}&nbsp;
                          {parseFloat(
                            billingData?.vat ? billingData.vat : 0
                          ).toFixed(2)}
                        </b>
                      </StyledPayment>
                    </Col>
                  </Row>

                  <Row justify="space-between">
                    <Col xl={18} lg={18} md={18} sm={18} xs={23}>
                      <StyledPayment>&nbsp;Payable Amount: </StyledPayment>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={23}>
                      <StyledPayment>
                        <b>
                          {settings?.currency}&nbsp;
                          {parseFloat(
                            billingData?.payable ? billingData.payable : 0
                          ).toFixed(2)}
                        </b>
                      </StyledPayment>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col xl={18} lg={18} md={18} sm={18} xs={23}>
                      <StyledPayment color={theme["@primary-color"]}>
                        &nbsp;Claimable Amount:{" "}
                      </StyledPayment>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={23}>
                      <StyledPayment color={theme["@primary-color"]}>
                        <b>
                          {settings?.currency}&nbsp;
                          {parseFloat(
                            billingData?.claimableAmount
                              ? billingData.claimableAmount
                              : 0
                          ).toFixed(2)}
                        </b>
                      </StyledPayment>
                    </Col>
                  </Row>
                  <ClaimStatusContainer
                    justify="space-between"
                    align="middle"
                    color={
                      billingData?.status == "Paid"
                        ? "green"
                        : billingData?.status == "Declined"
                        ? "red"
                        : ""
                    }
                  >
                    <Col></Col>
                    <Col xl={4} lg={4} md={5} sm={6} xs={24}>
                      {billingData?.status == "Paid"
                        ? "Claim Approved"
                        : billingData?.status == "Declined"
                        ? "Claim Declined"
                        : ""}
                    </Col>
                  </ClaimStatusContainer>
                </div>
              </>
            ) : insurance ? (
              <>
                <StyledDiv bgcolor={theme["@primary-color"]}>
                  <div>
                    &nbsp;&nbsp;
                    {"Billing Info"}
                  </div>
                  <div>&nbsp;&nbsp;</div>
                </StyledDiv>
                {insurance ? (
                  <BlueBgTable
                    pagination={false}
                    columns={insuranceBillColumns}
                    dataSource={tableData}
                    summary={summary}
                  />
                ) : (
                  <BlueBgTable
                    pagination={false}
                    columns={insuranceBillColumns}
                    dataSource={tableData}
                  />
                )}
                <ClaimStatusContainer
                  justify="space-between"
                  align="middle"
                  color={
                    billingData?.status == "Paid"
                      ? "green"
                      : billingData?.status == "Declined"
                      ? "red"
                      : ""
                  }
                >
                  <Col></Col>
                  <Col xl={4} lg={4} md={5} sm={6} xs={24}>
                    {billingData?.status == "Paid"
                      ? "Claim Approved"
                      : billingData?.status == "Declined"
                      ? "Claim Declined"
                      : ""}
                  </Col>
                </ClaimStatusContainer>
              </>
            ) : (
              <>
                <StyledDiv bgcolor={theme["@primary-color"]}>
                  <div>&nbsp;&nbsp;Billing History</div>
                  <div>&nbsp;&nbsp;</div>
                </StyledDiv>
                {insurance ? (
                  <BlueBgTable
                    pagination={false}
                    columns={
                      previousDataColumns.length
                        ? appendCloseButton(tableData, previousDataColumns)
                        : []
                    }
                    dataSource={tableData}
                    summary={summary}
                    tableTitle={"Billing History"}
                  />
                ) : (
                  <BlueBgTable
                    pagination={false}
                    columns={
                      previousDataColumns.length
                        ? appendCloseButton(tableData, previousDataColumns)
                        : []
                    }
                    dataSource={tableData}
                    tableTitle={"Billing History"}
                  />
                )}
                <ClaimStatusContainer
                  justify="space-between"
                  align="middle"
                  color={
                    billingData?.status == "Paid"
                      ? "green"
                      : billingData?.status == "Declined"
                      ? "red"
                      : ""
                  }
                >
                  <Col></Col>
                  <Col xl={4} lg={4} md={5} sm={6} xs={24}>
                    {billingData?.status == "Paid"
                      ? "Claim Approved"
                      : billingData?.status == "Declined"
                      ? "Claim Declined"
                      : ""}
                  </Col>
                </ClaimStatusContainer>
              </>
            )}
          </Col>
        </StyledSummaryCol>
      </Row>
      {billingData?.status == "Waiting for Approval" ? (
        // history?.location?.state?.visitType ? (
        <></>
      ) : (
        billingData?.showPreApprovalInfo == true && (
          <Row>
            <StyledSummaryCol>
              <Row>
                <Col span={1}></Col>
                <h3 style={{ color: theme["@primary-color"] }}>
                  Pre-approval Information
                </h3>
              </Row>
              <Row>
                <Col span={1}></Col>
                <Col lg={7} md={8} sm={11} xs={23}>
                  <StyledSummaryRow>
                    Approved By:&nbsp;&nbsp;
                    <StyledP>{billingData?.preApprovedBy?.userName}</StyledP>
                  </StyledSummaryRow>
                </Col>
                <Col lg={0} md={0} sm={0} xs={1}></Col>
                <Col lg={16} md={15} sm={12} xs={23}>
                  <StyledSummaryRow>
                    Approval Date:&nbsp;&nbsp;
                    <StyledP>
                      {moment(billingData?.preApprovedDate).format(
                        "DD-MM-YYYY"
                      )}
                    </StyledP>
                  </StyledSummaryRow>
                </Col>
              </Row>
              {history?.location?.state?.visitType && (
                <>
                  <Row>
                    <Col span={1}></Col>
                    <Col span={23}>
                      <StyledSummaryRow>Notes:</StyledSummaryRow>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={1}></Col>
                    <Col span={23}>
                      {billingData?.preApprovedBy && (
                        <Comment
                          author={billingData.preApprovedBy?.userName}
                          avatar={
                            <StyledAvatar
                              src={
                                billingData.preApprovedBy?.profilePic
                                  ? billingData.preApprovedBy.profilePic
                                  : DefaultAvatar
                              }
                              alt="Han Solo"
                            />
                          }
                          content={<p>{billingData.preApprovalNote}</p>}
                          datetime={
                            <span>
                              {moment(billingData.preApprovedDate).fromNow()}
                            </span>
                          }
                        />
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </StyledSummaryCol>
          </Row>
        )
      )}
      {insurance ? (
        <Row>
          <StyledSummaryCol>
            <Row>
              <Col span={1}></Col>
              <h3 style={{ color: theme["@primary-color"] }}>
                {history.location.state?.waitingForPreApproval == true
                  ? "Notes"
                  : "Comments"}
              </h3>
            </Row>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                {comments.map((comment) => {
                  return (
                    <Comment
                      author={comment.updatedBy.userName}
                      avatar={
                        <StyledAvatar
                          src={
                            comment.updatedBy.profilePic
                              ? comment.updatedBy.profilePic
                              : DefaultAvatar
                          }
                          alt="Han Solo"
                        />
                      }
                      content={<p>{comment.comment}</p>}
                      datetime={
                        <span>{moment(comment.updatedDate).fromNow()}</span>
                      }
                    />
                  );
                })}
                <Form
                  layout={"vertical"}
                  onFinish={(values) => {
                    setComment(values.comment);
                    // if (!billingData?.status == "Waiting for Approval")
                    setChangedStatus("WAITING");
                    setConfirmationModal(true);
                    form.setFieldsValue({ comment: undefined });
                  }}
                  form={form}
                  scrollToFirstError={true}
                >
                  {status !== "Paid" &&
                    status !== "Declined" &&
                    status !== "Partially Accepted" && (
                      <Form.Item
                        label={
                          billingData?.status == "Pending" ? "Comment" : "Note"
                        }
                        name="comment"
                        rules={[
                          {
                            required: true,
                            message: "Note cannot be empty",
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          size="large"
                          placeholder={
                            history.location.state?.waitingForPreApproval ==
                            true
                              ? "Add notes here"
                              : "Add comments here"
                          }
                        />
                      </Form.Item>
                    )}
                  <Form.Item>
                    {status !== "Paid" &&
                      status !== "Declined" &&
                      status !== "Partially Accepted" && (
                        <SubmitButtonCol span={24}>
                          {status === "Pending" && (
                            <RightButton htmlType="submit" type="primary">
                              Claim
                            </RightButton>
                          )}
                          {status === "Claim Waiting" && (
                            <>
                              <RightButton
                                type="primary"
                                onClick={() => {
                                  form
                                    .validateFields()
                                    .then((response) => {
                                      setComment(
                                        form.getFieldsValue(["comment"]).comment
                                      );
                                      setChangedStatus("DECLINE");
                                      setConfirmationModal(true);
                                    })
                                    .catch((error) => {
                                      console.log("error", error);
                                    });
                                }}
                              >
                                Decline
                              </RightButton>
                              <RightButton
                                type="primary"
                                onClick={() => {
                                  form
                                    .validateFields()
                                    .then((re) => {
                                      setComment(
                                        form.getFieldsValue(["comment"]).comment
                                      );
                                      setChangedStatus("ACCEPT");
                                      setConfirmationModal(true);
                                    })
                                    .catch((err) => {
                                      console.log("error", err);
                                    });
                                }}
                              >
                                Accept
                              </RightButton>
                            </>
                          )}
                          {billingData?.status == "Waiting for Approval" && (
                            <>
                              <RightButton
                                type="primary"
                                disabled={!preApprovalSubmit}
                                onClick={() => {
                                  setChangedStatus("SUBMIT");
                                  preApprovalOrders("SUBMIT");
                                }}
                              >
                                Submit
                              </RightButton>
                              <RightButton
                                type="primary"
                                onClick={() => {
                                  setChangedStatus("SAVE");
                                  preApprovalOrders("SAVE");
                                }}
                              >
                                Save
                              </RightButton>
                            </>
                          )}
                          <RightButton
                            type="primary"
                            ghost
                            onClick={() => history.push("/")}
                          >
                            Cancel
                          </RightButton>
                        </SubmitButtonCol>
                      )}
                  </Form.Item>
                </Form>
              </Col>
              <Col span={1}></Col>
            </Row>
          </StyledSummaryCol>
        </Row>
      ) : (
        <></>
      )}

      <div style={{ display: "none" }}>
        <BillContainer ref={componentRef}>
          <div
            style={{
              borderTop: `1px solid #f0f0f0`,
              marginLeft: 20,
              marginRight: 20,
              height: "2px",
              width: "100%",
              marginBottom: "30px",
            }}
          ></div>
          <Row justify="space-between">
            <img src={hospitalLogo} style={{ width: "20%" }} />
            <Hospitalname style={{ alignItems: "center", display: "flex" }}>
              {selectedData["Hospital Name"]}
            </Hospitalname>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                width: "20%",
                justifyContent: "end",
              }}
            >
              Bill No : <b>&nbsp;{selectedData["Bill No"]}</b>
            </p>
          </Row>
          <div
            style={{
              borderTop: `1px solid ${theme["@primary-color"]}`,
              marginLeft: 20,
              marginRight: 20,
              height: "2px",
              width: "100%",
            }}
          ></div>
          <div style={{ textAlign: "center", fontSize: "17px" }}>
            Bill Details
          </div>
          <div
            style={{
              borderTop: `1px solid ${theme["@primary-color"]}`,
              marginLeft: 20,
              marginRight: 20,
              height: "20px",
              width: "100%",
            }}
          ></div>
          <Row justify="space-between" style={{ padding: "20px" }}>
            <Col>
              <p>Patient Name : {user.fullName}</p>
              <p>Date : {selectedData["Date"]}</p>
            </Col>
            <Col>
              <p>
                Doctor Name:{" "}
                {selectedData?.appointment?.doctor?.firstName +
                  " " +
                  selectedData?.appointment?.doctor?.lastName}
              </p>
              <p>Department: {selectedData["Department"]}</p>
            </Col>
          </Row>
          <div
            style={{
              borderTop: `1px solid #f0f0f0`,
              marginLeft: 20,
              marginRight: 20,
              height: "2px",
              width: "100%",
              marginBottom: "50px",
            }}
          ></div>
          <BlueBgTable
            style={{
              width: "100%",
              margin: "0 20px",
            }}
            dataSource={tableData}
            columns={billDataColumns}
            pagination={false}
          />
          <div
            style={{
              borderTop: `1px solid #f0f0f0`,
              marginLeft: 20,
              marginRight: 20,
              height: "2px",
              bottom: 0,
              width: "90%",
              marginBottom: "50px",
              position: "absolute",
            }}
          ></div>
        </BillContainer>
      </div>

      <SuccessModal
        message="Successfully saved"
        visible={successModal}
        setVisible={setSuccessModal}
        okText="Done"
        onOk={() => {
          setSuccessModal(false);
        }}
      />
      <ConfirmationModal
        visible={confirmationModal}
        status={changedStatus}
        setVistible={setConfirmationModal}
        onConfirm={(insuranceStatus) => submitClaim(insuranceStatus)}
        loading={submitLoading}
      />
    </>
  ) : (
    <Spinner />
  );
};

export default BillingSection;
