import React from "react";
import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  height: fit-content;
  margin-right: 10px;
  width: 100px;
  text-align: center;
  border: 1px solid ${(props) => props.color} !important;
  border-radius: 5px !important;
  font-size: small;
`;

export const StyledPayment = styled.p`
  color: ${(props) => props.color ? props.color : "gray"};
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0;
  text-align: ${props => props.right ? "end" : "start"};
`;