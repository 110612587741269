import axiosInstance from "../config/axiosInstance";

const WARD_CATEGORY_PREFIX = "/bed";

export const getBed = (id) => {
  return axiosInstance.get(`${WARD_CATEGORY_PREFIX}/getAllBeds/${id}`);
};

export const addNewBed = (data) => {
  return axiosInstance.post(`${WARD_CATEGORY_PREFIX}/addBed`, data);
};

export const editBed = (data, id) => {
  return axiosInstance.put(`${WARD_CATEGORY_PREFIX}/editBed/${id}`, data);
};

export const deleteBed = (id) => {
  return axiosInstance.delete(`${WARD_CATEGORY_PREFIX}/deleteBeds/${id}`);
};

export const getBedDetail = (id) => {
  return axiosInstance.get(`${WARD_CATEGORY_PREFIX}/getAllBeds/${id}`);
};

export const getBedStatus = () => {
  return axiosInstance.post(`/hospital/getStatistics`, {
    timeZone: "Asia/Kolkata",
  });
};
