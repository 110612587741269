import React, { useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Empty, Table, Row } from "antd";
import { DndProvider, useDrag, useDrop, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import Spinner from "../../utils/Spinner";


const RNDContext = createDndContext(HTML5Backend);
const type = "DragableBodyRow";
const StyledH2 = styled.h2`
  // width: 200px;
  /* fontSize: 40px; */
  color: black;
  `;

const EmptyMessageContainer = styled.div`
  /* height: 20vh; */
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
  width: 100%;
`;

const DragableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
}) => {
    const ref = React.useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName:
                    dragIndex < index ? " drop-over-downward" : " drop-over-upward",
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        item: { type, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ""}`}
            style={{ cursor: "move", ...style }}
            {...restProps}
        />
    );
};

const ReportManagementTable = ({
    dataSource,
    loading,
    // uploading,
    setEditModal,
    setselectedItem,
    setDeleteModal,
    columns,
    setDataSource,
    draggable,
    preventLast,
}) => {
    const components = {
        body: {
            row: DragableBodyRow,
        },
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            if (preventLast) {
                if (
                    dragIndex !== dataSource.length - 1 &&
                    hoverIndex !== dataSource.length - 1
                ) {
                    const dragRow = dataSource[dragIndex];
                    setDataSource(
                        update(dataSource, {
                            $splice: [
                                [dragIndex, 1],
                                [hoverIndex, 0, dragRow],
                            ],
                        })
                    );
                }
            } else {
                const dragRow = dataSource[dragIndex];
                setDataSource(
                    update(dataSource, {
                        $splice: [
                            [dragIndex, 1],
                            [hoverIndex, 0, dragRow],
                        ],
                    })
                );
            }
        },
        [dataSource]
    );
    const manager = useRef(RNDContext);

    const theme = useSelector((state) => state.themeReducer);
    return !loading ? (
        draggable ? (
            <DndProvider manager={manager.current.dragDropManager}>
                <Row>
                    <StyledH2>
                        Uploaded Documents
                    </StyledH2>
                </Row>
                <Row>
                    {dataSource?.length > 0 ?
                        <Table
                            headBg={theme["@primary-color"]}
                            loading={loading}
                            columns={columns}
                            dataSource={dataSource}
                            // loading={loading}
                            tableLayout={"auto"}
                            showHeader={false}
                            pagination={false}
                            components={components}
                            onRow={(record, index) => ({
                                index,
                                moveRow,
                            })}
                        /> :
                        <EmptyMessageContainer>
                            <Empty />
                        </EmptyMessageContainer>
                    }
                </Row>
            </DndProvider>
        ) : (
            <Table
                headBg={theme["@primary-color"]}
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                // loading={loading}
                pagination={false}
            />
        )
    ) : (
        <Spinner height={"30px"} />
    );
};
export default ReportManagementTable;