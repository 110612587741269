export const dosageForms = () => [
  "Cream",
  "Ointment",
  "Coated Tablets",
  "Coated Tablets (Caplets)",
  "Capsules (Soft Gelatin)",
  "Film Coated Tablets",
  "Device",
  "Nasal Spray Pump",
  "Liquid for Inhalation",
  "Tablets",
  "Topical Spray (Powder)",
  "Concentrate for Infusion",
  "Granules",
  "Capsules",
  "Topical Lotion",
  "Baby Milk Powder",
  "Eye Drops (Solution)",
  "Vaginal Ovules",
  "Solution for Infusion",
  "Eye Drops",
  "Injection",
  "Solution for Injection",
  "Oral Solution",
  "Effervescent Tablets",
  "Contact Lens Solution",
  "Nasal Spray",
  "Syrup",
  "Capsules (Hard Gelatin)",
  "Shampoo",
  "Intratracheal Suspension",
  "Suspension (Intratracheal)",
  "Aerosol Inhaler",
  "I.V. Infusion",
  "Topical Liquid",
  "Powder For Suspension",
  "Powder for Drops (Oral)",
  "Hosiery",
  "Eye Ointment",
  "IV / IM Injection",
  "Eye / Nose Drops (Solution)",
  "Oral Drops",
  "Powder (Oral)",
  "Powder for Infusion",
  "Pre-Filled Syringe for Injection",
  "Pre-Filled Syringe for Intraarticular Injection",
  "Lotion",
  "Powder",
  "Soluble Tablets",
  "Tablets (Soluble)",
  "Chewable Tablets",
  "Enteric Coated Tablets",
  "Tablets (Enteric Coated)",
  "Oral Syrup",
  "Solution For Nebulization",
  "Metered Dose Aerosol Inhaler",
  "Dispersible Tablets",
  "Modified Release Tablets",
  "Solution For I.V. Infusion",
  "Powder for Injection",
  "Metered Dose Inhaler (Aerosol)",
  "Balm",
  "Suspension",
  "Vaginal Cream",
  "Intravesical Powder",
  "Inhaler (Aerosol)",
  "Nasal Aerosol Spray",
  "Drops",
  "Powder for Solution for Infusion",
  "Gel",
  "Topical Solution",
  "Solution",
  "Lozenges",
  "Cough Syrup",
  "Capsules (Hard Gelatin) for Inhalation (Dry Powder)",
  "Ophthalmic Solution",
  "Oral Suspension",
  "Granules for Suspension",
  "Sustained Release Tablets",
  "Paste",
  "Turbuhaler",
  "Nebulisation Solution",
  "Cough Mixture",
  "Effervescent Granules",
  "Suspension for Nebulization",
  "Dividose Tablets",
  "Drops (Oral)",
  "Teething Gel",
  "Topical Oil for Massage",
  "Dusting Topical Powder",
  "Spray (Pump)",
  "Vaginal Tablets",
  "Eye Drops (Emulsion)",
  "Topical",
  "Infusion",
  "Liquid for Mouthwash & Gargle",
  "Suspension for Injection",
  "Suspension (Alcohol Free)",
  "Liqiud Gel Eye Drops",
  "Ear Drops",
  "Tablets (Homeopathy)",
  "Eye / Ear Drops",
  "Rectal Foam Aerosol",
  "Enteric Coated Capsules (Hard Gelatin)",
  "Tablets (Pellets)",
  "Solution for Rectal Enema",
  "Nasal Drops",
  "Eye Cream",
  "Minims",
  "Nasal Drops (Solution)",
  "Vaginal Pessaries",
  "Suppositories",
  "Eye / Ear Drops (Solution)",
  "Massage Liniment",
  "Scalp Application Solution",
  "Solution (Oral)",
  "Rectal Solution",
  "Dialysis Fluid",
  "Oral Rinse Solution",
  "Liquid (Oral)",
  "Emulsion for Injection",
  "Modified Release Capsules (Hard Gelatin)",
  "Rectal Suppositories",
  "Syrup (Alcohol Free)",
  "Topical Aerosol Spray",
  "Sugar Coated Tablets",
  "Liquid",
  "Sustained Release Capsules (Hard Gelatin)",
  "Powder for Syrup",
  "Powder for I.V. Injection",
  "Single Dose Injection",
  "Transdermal Patches",
  "Tablets (Caplets)",
  "Inhaler (Dry Powder)",
  "Syrup (Sugar Free)",
  "Solutabs Tablets",
  "Dry Powder Inhaler",
  "Powder For I.V. Infusion",
  "Retard Tablets",
  "Prolonged Release Tablets",
  "Cartridge for Injection",
  "Orodispersible Tablets",
  "Intrauterine Device",
  "Vaginal Ovules + Cream",
  "Scalp Liquid (Cream Rinse)",
  "Scalp Solution",
  "Rectal Ointment",
  "Liniment",
  "Powder for Dialysis Fluid",
  "Liquid for Mouthwash",
  "Powder For Drops",
  "Water Soluble Powder (Sachets)",
  "Eye Gel",
  "Long Acting Capsules (Hard Gelatin)",
  "Spray Aerosol",
  "Solution for Irrigation",
  "Sublingual Tablets",
  "Spray",
  "Spray (No gas)",
  "Powder For Concentrate For Solution For Infusion",
  "Oral Paste",
  "Chewable Dispersible Tablets",
  "Gel (Emulsion)",
  "Syrup (Elixir)",
  "Oral / Nasal Inhalation Solution",
  "Gauze (Dressing)",
  "Oral Lyophilisates (Wafers)",
  "Crystals",
  "Pre-Filled Syringe",
  "Intranasal Solution",
  "Sublingual Tablets (Lyophilized)",
  "Intrauterine Implant System",
  "Injection (Multipuncture)",
  "Sustained Release Capsules",
  "Spray (Meterd Dose)",
  "IM Injection",
  "Chewing Gums",
  "IUD",
  "Vaginal Ring",
  "Topical Spray",
  "Concentrate For Dilution For Infusion",
  "Suspension (Oral)",
  "Chewable Cubes",
  "Controlled Release Capsules (Hard Gelatin)",
  "Nasal Gel",
  "Elixir",
  "Caplets",
  "Rectal Gel",
  "Compress Gauze (Dressing)",
  "Gastro-Resistant Tablets",
  "Lyophilized Powder For Injection",
  "Powder For Oral Suspension",
  "Skin Lotion",
  "Foaming Solution (Shampoo)",
  "Linctus",
  "Vaginal Solution",
  "Pre-Filled Pen",
  "Eye Drops (Suspension)",
  "Vaginal Gel",
  "Oral Tablets",
  "Intraocular Injection",
  "Delayed Release Capsules (Hard Gelatin)",
  "Inhalation Capsules",
  "Nebulization Suspension",
  "Tablets (Dividose)",
  "Subcutaneous Injection",
  "Concentrate For Solution For Infusion",
  "Inhaler Device",
  "Soap",
  "Implant/Fleece for",
  "Eye / Ear Ointment",
  "Jelly",
  "Dental Adhesive Paste",
  "Inhalation Capsules (Hard Gelatin)",
  "Liquid for Irrigation",
  "Eye Drops (Systane Lubricating)",
  "Laquered Tablets",
  "Vaginal Tablets + Tablets",
  "Nebulizing Solution",
  "Inhalation Solution",
  "Sprinkle Capsules",
  "Liquid for Gargle",
  "Extended Release Tablets",
  "Extended Release Tablets (Caplets)",
  "Nasal Ointment",
  "Concentrate For I.V. Infusion",
  "Patches",
  "Gel (Emulgel)",
  "Powder for Solution (Oral)",
  "Spray Pump",
  "Oral Viscous Solution",
  "Freeze-dried-pellet",
  "Drops (Solution)",
  "Solution for Spray",
  "Intraarticular Injection",
];
