import React, { useState } from "react";

import { Button } from "antd";
import { AcceptDeclineButton, StyledButton } from "./styledComponents";

const insuranceStatusList = {
  Pending: "Pending",
  "Cash Paid": "Cash Paid",
  "Insurance Claimed": "Accepted",
  "Insurance Claim Rejected": "Declined",
};

export const columns = ({
  settings,
  visitType,
  immediateBooking,
  payInCashList,
  setPayInCashList,
  insuranceList,
  setInsuranceList,
}) => [
    {
      title: "Sl. No",
      render: (value, item, index) => {
        return index + 1;
      },
      width: "5vw",
    },
    {
      title: "Orders",
      dataIndex: "Test Name",
      width: "20vw",
    },
    {
      title: "Department",
      render: () => {
        return visitType;
      },
      width: "10vw",
    },
    {
      title: "Fee",
      dataIndex: "fee",
      render: (value) => {
        return `${settings?.currency} ${value ? value : 0}`;
      },
      width: "10vw",
    },
    {
      render: (row) => {
        return immediateBooking ? (
          // <Radio.Group
          //   // options={options}
          //   onChange={(e) => {
          //     console.log(e);
          //   }}
          //   // value={value3}
          //   buttonStyle="solid"
          //   // defaultValue="cash"
          //   // optionType="button"
          //   // style={{ margin: "10px" }}
          // >
          <div>
            <StyledButton
              type="primary"
              ghost={!payInCashList.includes(row.testDataId)}
              value="cash"
              // disabled={!immediateBooking}
              onClick={() => {
                // selectPaymentType(row, "cash");
                let list = payInCashList;
                if (!payInCashList.includes(row.testDataId)) {
                  list = list.concat(row.testDataId);
                  setPayInCashList(list);
                  var index = insuranceList.indexOf(row.testDataId);
                  if (index > -1) {
                    insuranceList.splice(index, 1);
                  }
                  setInsuranceList(insuranceList);
                }
              }}
            >
              Pay in Cash
            </StyledButton>
            <StyledButton
              type="primary"
              ghost={!insuranceList.includes(row.testDataId)}
              value="insurance"
              // disabled={!immediateBooking}
              onClick={() => {
                // selectPaymentType(row, "insurance");
                let list = insuranceList;
                if (!insuranceList.includes(row.testDataId)) {
                  list = list.concat(row.testDataId);
                  setInsuranceList(list);
                  var index = payInCashList.indexOf(row.testDataId);
                  if (index > -1) {
                    payInCashList.splice(index, 1);
                  }
                  setPayInCashList(payInCashList);
                }
              }}
            >
              Claim via Insurance
            </StyledButton>
          </div>
        ) : (
          <p></p>
        );
      },
    },
  ];

export const appointmentRquestDetailsColumns = ({
  theme,
  cashPaidList,
  setCashPaidList,
  settings,
  setIsSubmit
}) => [
    { width: "30px" },
    {
      title: "Sl. No",
      render: (value, record, index) => index + 1,
    },
    {
      title: "Orders",
      dataIndex: "orders",
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Fee",
      dataIndex: "fee",
      render: (value) => settings?.currency + " " + value,
    },
    {
      title: "Insurance/Cash Status",
      dataIndex: "insuranceStatus",
      render: (value) => (
        <AcceptDeclineButton
          color={
            insuranceStatusList[value] == "Accepted"
              ? "green"
              : insuranceStatusList[value] == "Declined"
                ? "red"
                : theme["@primary-color"]
          }
        >
          <Button
            ghost
            type="primary"
            style={{ borderRadius: "5px", minWidth: "60%" }}
          >
            {insuranceStatusList[value]}
          </Button>
        </AcceptDeclineButton>
      ),
    },
    {
      dataIndex: "insuranceStatus",
      title: "Action",
      render: (value, row, index) =>
        value !== "Insurance Claim Rejected" ? (
          <></>
        ) : (
          <Button
            style={{ borderRadius: "5px" }}
            type="primary"
            ghost={
              !(value == "Insurance Claim Rejected" &&
                cashPaidList.includes(row.testDataId))
            }
            onClick={() => {
              // record
              let list = cashPaidList;
              if (!cashPaidList.includes(row.testDataId)) {
                list = list.concat(row.testDataId);
                setCashPaidList([...list]);
                setIsSubmit(true);

              } else {
                var index = cashPaidList.indexOf(row.testDataId);
                if (index > -1) {
                  cashPaidList.splice(index, 1);
                }
                setCashPaidList([...cashPaidList]);
                setIsSubmit(true);

              }
            }}
            disabled={value !== "Insurance Claim Rejected"}
          >
            Pay in Cash
          </Button>
        ),
    },
  ];
