import React, { useEffect, useRef } from "react";
import { Row, Col, message, Button, Tooltip, Card } from "antd";
import { SectionHeading, SectionHeadContainer } from "../styledComponents";

import { useState } from "react";
import { LeftCircleOutlined, UserAddOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";

import { getBedStatus } from "../../../../apis/bed";
// import {StatisticsWidget} from "../../Statistics";

import StatisticsWidget from "../../Statistics";

import {
  getHospitalId,
  getDoctorId,
  getHospitalName,
} from "../../../utils/LocalStorage/index";
import QRcode from "react-qr-code";
import moment from "moment";
import {
  BodyContainerSection,
  StyledDivParent,
  StyledDivQrCode,
  StyledDivPrint,
} from "./styledComponents";
import DasboardCard from "./DashboardCard";
import Analytics from "./Analytics";

const Dashboard = () => {
  const componentRef = useRef();

  const [selected, setSelected] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [trigger, setTrigger] = useState(true);
  // const [statisticsData1, setStatisticsData1] = useState();
  const [qrcode, setqrCode] = useState(
    `https://app.meditagg.com/${getHospitalId()}/new-patient-form`
  );

  const [statisticsData, setStatisticsData] = useState([
    { title: "No of Active Wards", key: "noOfActiveWards", color: "#1890ff" },
    { title: "No of Beds", key: "noOfBeds", color: "#52c41a" },
    { title: "No Of Vacant Beds", key: "noOfVacccantBeds", color: "#faad14" },
    { title: "No of Occupied Beds", key: "noOfOccupiedBeds", color: "#ff4d4f" },
    { title: "No of Active Ipd", key: "noOfActiveIpd", color: "#597ef7" },
    {
      title: "OT Bookings (Next 30 Days)",
      key: "otBookingsInNext30Days",
      color: "#faad14",
    },
  ]);

  useEffect(() => {
    // getStatus();
  }, [trigger]);

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = () => {
    getBedStatus()
      .then((resp) => {
        // setStatisticsData1(resp.data.body);
        console.log("API Response:", resp.data.body);
        const updatedStatisticsData = statisticsData.map((item) => {
          return {
            ...item,
            value: resp.data.body?.[item.key] ?? 0, // Use 0 as a default value if the property is undefined
          };
        });
        setStatisticsData(updatedStatisticsData);

        console.log("print" + resp.data);
      })
      .catch((err) => {
        message.error("Could not fetch total revenue, Try again");
        console.log("error is", err);
      });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "emp-data",
    // onAfterPrint: () => alert('print success'),
  });

  const history = useHistory();

  return (
    <>
      <Row>
        <Col span={24}>
          <SectionHeadContainer>
            <Row justify="space-between">
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <SectionHeading>
                  {selected && (
                    <LeftCircleOutlined
                      onClick={() => {
                        setSelected(false);
                        setSelectedId(null);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  Dashboard
                </SectionHeading>
              </Col>
              <Col style={{ display: "flex", alignItems: "flex-end" }}></Col>
            </Row>
          </SectionHeadContainer>
        </Col>

        <BodyContainerSection span={24}>
          <>
            <StatisticsWidget />
            <Col style={{ marginTop: "20px" }}></Col>
            <Row gutter={10} justify="space">
              <Col xs={24} sm={24} md={12} style={{ marginBottom: "10px" }}>
                {" "}
                <Analytics />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <StyledDivParent>
                  <StyledDivQrCode>
                    <QRcode
                      size={350}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      value={qrcode}
                      viewBox={`0 0 350 350`}
                    />
                  </StyledDivQrCode>
                </StyledDivParent>
                <StyledDivParent>
                  <Tooltip title="Print QRCode" placement="bottom">
                    <Button
                      type="primary"
                      onClick={handlePrint}
                      style={{ width: "100px" }}
                    >
                      {" "}
                      Print
                    </Button>
                  </Tooltip>
                </StyledDivParent>
              </Col>
            </Row>

            <Row></Row>
            {/* <StyledDivParent>
              <DasboardCard
                icon={<UserAddOutlined />}
                title={'Patient'}
                value={1235}
              />
            </StyledDivParent> */}
          </>
        </BodyContainerSection>
        <div style={{ display: "none" }}>
          <div ref={componentRef}>
            <StyledDivPrint>
              <h1 style={{ marginTop: "30px" }}>{getHospitalName()}</h1>
              <h4>Scan this QRCode to book an Appointment</h4>

              <div
                style={{
                  height: "auto",
                  margin: "15% 0 0 0",
                }}
              >
                <QRcode value={qrcode} />
              </div>
            </StyledDivPrint>
          </div>
          <div></div>
        </div>
      </Row>
    </>
  );
};

export default Dashboard;
