import React from "react";

import { Button, Row, Col } from "antd";
import Icon from "@ant-design/icons";

import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/DeleteIcon.svg";

const SectionColumns = (
  setTab,
  setSelectedSection,
  setDeleteModal,
  setNewModal,
  setEdit
) => [
  {
    width: "1vw",
  },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
    width: "4vw",
  },
  {
    title: "Sections",
    dataIndex: "name",
    width: "30vw",
  },
  {
    title: "Actions",
    width: "20vw",
    render: (record) => {
      return (
        <Row gutter={24} style={{ alignItems: "center" }}>
          <Col>
            <Button
              type="primary"
              //   size="small"
              ghost
              style={{ borderRadius: "5px" }}
              onClick={() => {
                setSelectedSection(record);
                setTab("radiology_test_type");
              }}
            >
              Manage Test Type
            </Button>
          </Col>
          <Col>
            {" "}
            <Icon
              style={{ fontSize: "20px" }}
              component={EditIcon}
              onClick={() => {
                setSelectedSection(record);
                setEdit(true);
                setNewModal(true);
              }}
            />
          </Col>
          <Col>
            <Icon
              style={{ fontSize: "22px" }}
              component={DeleteIcon}
              onClick={() => {
                setSelectedSection(record);
                setDeleteModal(true);
              }}
            />
          </Col>
        </Row>
      );
    },
  },
];

export const formColumns = (
  setTab,
  setSelectedRadiologyTestTypes,
  setConfirmationModal,
  setNewModal,
  setEdit
) => [
  {
    width: "1vw",
    render: () => {
      return <>&nbsp;</>;
    },
  },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
    width: "4vw",
  },
  { title: "Forms", dataIndex: "name", width: "30vw" },
  {
    title: "Actions",
    width: "20vw",
    render: (record) => (
      <Row gutter={24} style={{ alignItems: "center" }}>
        <Col>
          <Button
            type="primary"
            //   size="small"
            ghost
            style={{ borderRadius: "5px" }}
            onClick={() => {
              setSelectedRadiologyTestTypes(record);
              setTab("radiology_test");
            }}
          >
            Manage Tests
          </Button>
        </Col>
        <Col>
          {" "}
          <Icon
            style={{ fontSize: "20px" }}
            component={EditIcon}
            onClick={() => {
              setEdit(true);
              setSelectedRadiologyTestTypes(record);

              setNewModal(true);
            }}
          />
        </Col>
        <Col style={{ marginLeft: "5px" }}>
          <Icon
            style={{ fontSize: "22px" }}
            component={DeleteIcon}
            onClick={() => {
              setSelectedRadiologyTestTypes(record);
              setConfirmationModal(true);
            }}
          />
        </Col>
      </Row>
    ),
  },
];
export const fieldsColumn = (
  setTab,
  setSelectedField,
  setSelectedItem,
  setSelectedType,
  setConfirmationModal,
  setEdit,
  setNewModal,
  setDeleteModal
) => [
  {
    width: "1vw",
    render: () => {
      return <>&nbsp;</>;
    },
  },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
    width: "4vw",
  },
  { title: "Types", dataIndex: "name", width: "20vw" },
  { title: "Fee", dataIndex: "fee", width: "10vw" },
  {
    title: "Actions",
    width: "10vw",
    render: (record) => {
      return (
        <Row gutter={12} align="middle">
          <Col>
            <Button
              type="primary"
              //   size="small"
              style={{ borderRadius: "5px" }}
              ghost
              onClick={() => {
                setSelectedField(record);
                setTab("radiology_test_fields");
              }}
            >
              Manage Fields
            </Button>
          </Col>
          <Col>
            {" "}
            <Icon
              style={{ fontSize: "20px" }}
              component={EditIcon}
              onClick={() => {
                setEdit(true);
                setSelectedItem(record);
                setNewModal(true);
              }}
            />
          </Col>

          <Col>
            <Icon
              style={{ fontSize: "22px" }}
              component={DeleteIcon}
              onClick={() => {
                setSelectedItem(record);
                setSelectedType(record);
                setDeleteModal(true);
                setConfirmationModal(true);
              }}
            />
          </Col>
        </Row>
      );
    },
  },
];

export const newFieldColumns = (
  setSelectedItem,
  setNewFieldModal,
  setConfirmationModal,
  setEdit
) => [
  {
    width: "1vw",
    render: () => {
      return <>&nbsp;</>;
    },
  },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return index + 1;
    },
    width: "4vw",
  },
  { title: "Fields", dataIndex: "formName" },
  {
    title: "Type",
    dataIndex: "fieldType",
    render: (value) => {
      let list = value.split("_");
      list = list.map((item) => {
        return item[0] + item.slice(1).toLowerCase();
      });
      list = list.join(" ");
      return list;
    },
  },
  {
    title: "Mandatory Field",
    dataIndex: "required",
    render: (val) => (val ? "Yes" : "No"),
  },
  {
    title: "Input Box",
    dataIndex: "width",
    render: (item) => {
      return item[0] + item.slice(1).toLowerCase();
    },
  },
  {
    title: "Actions",
    width: "8vw",
    render: (record) => {
      return (
        <Row gutter={12}>
          <Col>
            {" "}
            <Icon
              style={{ fontSize: "20px" }}
              component={EditIcon}
              onClick={() => {
                setSelectedItem(record);
                if (record.editable) {
                  setNewFieldModal(true);
                  setEdit(true);
                }
                // else setErrorModal(true);
              }}
            />
          </Col>
          <Col>
            <Icon
              style={{ fontSize: "22px" }}
              component={DeleteIcon}
              onClick={() => {
                setSelectedItem(record);
                if (record.editable) {
                  setConfirmationModal(true);
                  setEdit(false);
                }
                // else setErrorModal(true);
              }}
            />
          </Col>
        </Row>
      );
    },
  },
];

export default SectionColumns;
