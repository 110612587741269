import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button, notification } from "antd";
import styled from "styled-components";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";
import { deleteChiefComplaint } from "../../../../apis/Admin";
const ModalBodyContainer = styled.div`
  width: 500px;
`;
const ConfirmationModal = ({
  visible,
  setVisible,
  selectedItem,
  loading,
  refetch,
}) => {
  const onConfirmHandler = () => {
    deleteChiefComplaint(selectedItem.id).then(({ data, status }) => {
      if (status === 200) {
        notification.success({
          message: data.body,
        });
      }
      setVisible(false);
      refetch();
    });
  };

  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <CustomAlerts
              type="delete"
              title="Delete this Chief Complaint ?"
              description={`This action will permanently delete the type ${
                selectedItem ? selectedItem.name : ""
              }.`}
            />
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVisible(false);
              }}
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                onConfirmHandler();
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default ConfirmationModal;
