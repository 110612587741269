import React from "react";
import {
  Wrapper,
  BodyContainer,
  StyledContent,
  LogoContainer,
} from "./styledComponents";
import { Row, Col, notification } from "antd";
import HospitalLogo from "../Login/HospitalLogo";
import { useSelector } from "react-redux";
import { ReactComponent as DoctorIcon } from "../../assets/icons/304919.svg";
import { PROFILE_IMAGE_PREFIX } from "../../constants/StringConstants";
import PatientDetailsForm from "../Content/AdminDashboard/UserManagement/PatientDetailsForm.jsx";
import { LeftCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { patientRegistration } from "../../apis/Auth";
import * as Sentry from "@sentry/react";

function SelfRegistration() {
  const history = useHistory();
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);

  const submitHandler = (values) => {
    const payload = {
      additionalInfo: {
        alternateContactDetails: values.alternateContactDetails,
        phoneType: values.phoneType,
        citizenship: values.citizenship,
        emailType: values.emailType,
        id: 0,
        maritalStatus: values.maritalStatus,
        nationality: values.nationality,
        primaryLanguage: values.primaryLanguage,
        race: values.race,
        religion: values.religion,
        deathStatus: values.deathStatus === "Yes" ? true : false,
        deathDate: values.deathDate,
        // vip: values.vip,
      },
      addresses: values.addresses,
      phoneNumbers: values.phoneNumbers.map((number) => {
        return {
          phoneType: number.phoneType,
          ...{
            countryCode: number.phoneNumber.countryCode,
            phoneNumber: number.phoneNumber.phoneNumber,
          },
        };
      }),
      dob: values.dob,
      bloodGroupId: values.bloodGroup,
      identityDocuments: values.identityDocuments,
      email: values.email,
      firstName: values.firstName,
      gender: values.gender,
      lastName: values.lastName,
      mobileNo: values.mobileNo,
      nationality: values.nationality,
      captcha: values.captcha,
    };
    patientRegistration(payload)
      .then((resp) => {
        if (resp.data.status === 1) {
          notification.success({
            message: "Registration Complete.",
            description: "Use your credentials to login.",
          });
          history.push("/login");
        } else {
          notification.error({
            message: "Something went wrong.",
            description: "Could not register user, Try again.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
Sentry.captureException(err);
      });
  };

  return (
    <Wrapper justify="center">
      <LogoContainer span={24} style={{ textAlign: "center" }}>
        <HospitalLogo
          title="Hospital Logo"
          icon={<DoctorIcon style={{ height: "84px" }} />}
          imageUrl={
            images.logo ? PROFILE_IMAGE_PREFIX + images.logo : undefined
          }
        />
      </LogoContainer>

      <StyledContent className="container">
        <h2 className="h2-base-600">
          <LeftCircleOutlined
            onClick={() => {
              history.goBack();
            }}
          />
          Back To Login
        </h2>
        <BodyContainer className="px-6">
          <PatientDetailsForm register={true} submitHandler={submitHandler} />
        </BodyContainer>
      </StyledContent>
    </Wrapper>
  );
}

export default SelfRegistration;
