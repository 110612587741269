import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import styled from "styled-components";
import { Row, Col, Input, Button, Typography, message, Select } from "antd";
import { Option } from "antd/lib/mentions";
// import { Modal, Button, Form, message, Row, Col,  } from 'antd';
import { insertNewWard, editWard } from "../../../../apis/wards.js";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getHospitalId } from "../../../utils/LocalStorage/index.js";


const ModalBodyContainer = styled.div`
  width: 450px;
  padding: 10px 30px;
`;
const { Title } = Typography;

const StyledTitle = styled(Title)`
  &.ant-typography,
  &.ant-typography h3 {
    margin-bottom: 0;
    color: ${(props) => props.color};
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    text-align: center;
  }
`;

const NewDocumentModal = ({
  visible,
  setVisible,
  refetch,
  selectedItem,
  setSelectedItem,
  departments,
  wardsCategory
}) => {
  const [wardName, setWardName] = useState("");
  const [wardFloor, setWardFloor] = useState("");
  const [loading, setLoading] = useState(false);

  const theme = useSelector((state) => state.themeReducer);

  const [selectedWardsCategory, setSelectedWardsCategory] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const addWard = () => {
    setLoading(true);
    var data = {
      "wardNo": wardName,
      "wardCategory": {
        "id": selectedWardsCategory
      },
      "hospital": {
        "id": getHospitalId()
      },
      "department": {
        "id": selectedDepartments
      },
      "floorNumber": wardFloor,
      "isVacant": true,
      "status": "active"
    };

    if (selectedItem) {
      editWard(data, selectedItem.id)
        .then(({ data, status }) => {
          if (status === 200) {
            message.success("Edited");
            refetch();
            setWardName("");
            setSelectedItem(undefined);
            setVisible(false);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      insertNewWard(data)
        .then(({ data, status }) => {
          if (status === 200) {
            message.success("Created");
            refetch();
            setWardName("");
            setSelectedItem(undefined);
            setVisible(false);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }

  };

  useEffect(() => {
    if (selectedItem) {
      setWardName(selectedItem.wardNo);
      setWardFloor(selectedItem.floorNumber);
      setSelectedDepartments(selectedItem.department.id);
      setSelectedWardsCategory(selectedItem.wardCategory.id)
    }

  }, [selectedItem]);

  const wardCategoryChange = (Id) => {
    setSelectedWardsCategory(Id)
  }

  const departmentChange = (Id) => {
    setSelectedDepartments(Id)
  }

  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBodyContainer>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            {" "}
            <StyledTitle level={3} color={theme["@primary-color"]}>
              {!selectedItem ? "Create New Ward" : "Update Ward"}
            </StyledTitle>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 6]}>
              <Col span={24}>Select Ward Category</Col>
              <Col span={24}>
                <Select
                  size="large"
                  placeholder="Select Ward Category"
                  value={selectedWardsCategory}
                  onChange={(value) => wardCategoryChange(value)}
                >
                  {wardsCategory &&
                    wardsCategory.map((category) => {
                      return (
                        <Option value={category.id} key={category.id}>
                          {category.categoryName}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 6]}>
              <Col span={24}>Select Department</Col>
              <Col span={24}>
                <Select
                  size="large"
                  placeholder="Select Department"
                  value={selectedDepartments}
                  onChange={(value) => departmentChange(value)}
                >
                  {departments &&
                    departments.map((department) => {
                      return (
                        <Option value={department.id} key={department.id}>
                          {department.departmentName}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
          </Col>



          <Col span={24}>
            <Row gutter={[0, 6]}>
              <Col span={24}>Name</Col>
              <Col span={24}>
                <Input
                  value={wardName}
                  size="large"
                  onChange={(event) => {
                    setWardName(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 6]}>
              <Col span={24}>Floor</Col>
              <Col span={24}>
                <Input
                  value={wardFloor}
                  size="large"
                  onChange={(event) => {
                    setWardFloor(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={12} justify="end">
              <Col>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  onClick={() => {
                    setWardName("");
                    setSelectedItem(undefined);
                    setVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => {
                    addWard();
                  }}
                >
                  {!selectedItem ? "Create" : "Update"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};

export default NewDocumentModal;
