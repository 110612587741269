import { Table, Row, Button, Col, Input, Select } from "antd";
import styled from "styled-components";
export const BlueBgTable = styled(Table)`
  & .ant-table-thead > tr > th {
    /* color: #fff; */
    /* background: ${(props) => props.headBg}; */
    font-size: 14px;
    font-weight: 600;
  }

  & .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:first-child {
    border-left: 1px solid #f0f0f0;
  }

  /* & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #9e9292;
  } */
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #f0f0f0;
  } 

  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    position: relative;
    padding: 8px;
    overflow-wrap: break-word;
  }
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  color: ${(props) => props.color};
  text-align: center;
`;
export const AllergyContainer = styled.div`
  margin-bottom: 50px;
`;
export const UploaderPlaceHolder = styled.div`
  width: 200px;
  /* padding: 20px; */
  border-width: 2px;
  border-color: #cacaca;
  border-style: dashed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 140px;
`;

export const StyledRow = styled(Row)`
  padding: 24px;
`;

export const ImportButton = styled(Button)`
  width: 240px;
  border-radius: 7px;
  margin-top: 10px;
`;

export const ButtonContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SearchContainer = styled.div``;

export const StyledSearch = styled(Input)`
  & .ant-input-group-addon {
    border-radius: 6px 0px 0px 6px;
    background: #fff;
    padding-left: 31px;
    color: #aeaeae;
    font-size: 20px;
  }

  & .ant-input {
    border-radius: 0px 6px 6px 0px;
    background: #fff;
    border-left: 0;
  }
`;

export const UserTypeSelect = styled(Select)`
  width: 175px;
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 40px;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #e3e3e3;
    border-radius: 5px;
  }

  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector::after,
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 40px;
    font-size: 17px;
    /* padding-left: 17px; */
  }
`;
