import React from 'react';

import { Button, Row, Col } from 'antd';
import Icon from '@ant-design/icons';
import { sidebarIcons } from '../../../../constants/sideBarIcons';
import { ReactComponent as EditIcon } from '../../../../assets/icons/Edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/DeleteIcon.svg';

export const sectionColumns = (setTab, setSelectedSection, setDeleteModal) => [
  { width: '1vw' },
  {
    title: 'Section Name',
    dataIndex: 'formName',
    render: (value, record) => {
      return (
        <Row gutter={24}>
          <Col>
            <Icon
              style={{ fontSize: '25px' }}
              component={
                sidebarIcons[record.icon] || sidebarIcons['consent-form']
              }
            />
          </Col>
          {value}
          <Col></Col>
        </Row>
      );
    },
  },
  {
    title: 'Actions',
    width: '12vw',
    render: (record) => {
      return (
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Button
              type="primary"
              //   size="small"
              style={{
                borderRadius: '5px',
              }}
              ghost
              onClick={() => {
                setSelectedSection(record);
                setTab('forms');
              }}
            >
              Manage Forms
            </Button>
          </Col>
          {/* <Col style={{ marginLeft: "5px" }}>
            {record.editable && (
              <Icon
                style={{ fontSize: "22px" }}
                component={DeleteIcon}
                onClick={() => {
                  setSelectedSection(record);
                  setDeleteModal(true);
                }}
              />
            )}
          </Col> */}
        </Row>
      );
    },
  },
];

export const formColumns = (setTab, setSelectedForm, setDeleteModal) => [
  {
    width: '1vw',
  },
  {
    width: '5vw',
    title: 'Sl. No',
    render: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: 'Forms',
    dataIndex: 'formName',
    render: (value) => {
      return <div style={{ textTransform: 'capitalize' }}>{value}</div>;
    },
  },
  {
    title: 'Actions',
    width: '200px',
    render: (record) => (
      <Row style={{ alignItems: 'center' }}>
        <Col>
          <Button
            type="primary"
            //   size="small"
            ghost
            style={{
              borderRadius: '5px',
            }}
            onClick={() => {
              setSelectedForm(record);
              setTab('form_fields');
            }}
          >
            Manage Fields
          </Button>
        </Col>
        <Col style={{ marginLeft: '5px' }}>
          {record.editable && (
            <Icon
              style={{ fontSize: '22px' }}
              component={DeleteIcon}
              onClick={() => {
                setSelectedForm(record);
                setDeleteModal(true);
              }}
            />
          )}
        </Col>
      </Row>
    ),
  },
];
export const fieldsColumn = (
  setSelectedItem,
  setConfirmationModal,
  setNewFieldModal,
  setErrorModal
) => [
  {
    width: '1vw',
  },
  {
    width: '5vw',
    title: 'Sl. No',
    render: (value, item, index) => {
      return index + 1;
    },
  },
  { title: 'Fields', dataIndex: 'formName' },
  { title: 'Type', dataIndex: 'fieldType' },
  {
    title: 'Mandatory Field',
    dataIndex: 'required',
    render: (val) => (val ? 'Yes' : 'No'),
  },
  { title: 'Input Box', dataIndex: 'width' },
  {
    title: 'Actions',
    width: '150px',
    render: (record) => {
      return (
        <Row gutter={12}>
          {console.log('record here @', record)}

          <Col>
            {' '}
            <Icon
              style={{ fontSize: '20px' }}
              component={EditIcon}
              onClick={() => {
                setSelectedItem(record);
                if (record.editable) setNewFieldModal(true);
                else setErrorModal(true);
              }}
            />
          </Col>
          <Col>
            <Icon
              style={{ fontSize: '22px' }}
              component={DeleteIcon}
              onClick={() => {
                setSelectedItem(record);
                if (record.editable) setConfirmationModal(true);
                else setErrorModal(true);
              }}
            />
          </Col>
        </Row>
      );
    },
  },
];
