import { Button, Result } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

const NotFoundPage = () => {
    const history = useHistory();

    return <div>  <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={() => history.push("/")}>Back Home</Button>}
    /></div>
}

export default NotFoundPage