import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment, { isMoment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  getHealthRecordForms,
  getPreviousRecords,
  addHealthRecordIPD,
  getSectionData,
} from '../../../../apis/healthRecordIPD';
import { Row, Col, Button, message, Form, Popconfirm } from 'antd';
import TableContainer from '../TableContainer';
import {
  generateFormFields,
  deserialize,
} from '../../../utils/FormBuilder/valuesSerialisation';
import FormBuilder from '../../../utils/FormBuilder';
import { fetchFormFieldMeta } from '../../../../apis/forms';
import {
  generatePreSubmitFormFields,
  generatePreviousInfoTableColumns,
  migrateIntoTableFormat,
  softValidate,
} from '../helper';
import ViewDetailsModal from '../ViewDetailsModal';
import Spinner from '../../../utils/Spinner';
import NoRecords from '../../../utils/NoRecord';
import CustomeTableForRquests from '../Laboratory/customTable';
import { DiscardSubmitWrapper } from '../CustomSection';
import { ButtonContainer } from '../styledComponents';

// import ViewDetailsModal from "./ViewDetailsModal";

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-right: 10px;
  border-radius: 6px !important;
`;

const StyledCol = styled(Col)`
  margin: 10px 0;
  padding-bottom: ${(props) => (props.padding ? props.padding : 'unset')};
`;

const typos = {
  Allergies: {
    tableContainer: 'Previous Allergy Report',
    tableHead: 'Allergy Report',
  },
  'Medical History': {
    tableContainer: 'Past Medical History',
    tableHead: 'Medical History',
  },
};

const ThreeTierFormSection = () => {
  const { sectionId } = useParams();
  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedInformation, setSelectedInformation] = useState([]);
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const user = useSelector((state) => state.userReducer);

  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [preSubmitTableData, setPreSubmitTableData] = useState([]);
  const [preSubmitTableColumns, setPreSubmitTableColumns] = useState([]);
  const [previousDataColumns, setPreviousDataColumns] = useState([]);
  const [visible, setVisible] = useState(false);
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const [tableFetchLoading, setTableFetchLoading] = useState(false);
  const [dropDownParentIds, setDropDownParentIds] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);
  const [validationStatus, setValidationStatus] = useState({});
  const [validationCriterias, setValidationCriterias] = useState([]);
  const [pageSize, setPageSize] = useState(2);

  const onValuesChange = (changed) => {
    Object.entries(changed).map(([key, value]) => {
      let changedField = dropDownParentIds.find(
        (item) => item.parentField == key
      );
      if (changedField) {
        let dropDownItem = dropDownValues.filter((item) => item.value == value);

        if (dropDownItem[dropDownItem.length - 1]) {
          changedField.sectionId = dropDownItem[dropDownItem.length - 1].id;

          setMetaDataFieldIds([changedField]);
        }
      }
    });
    Object.keys(changed).forEach((chngd) => {
      let validator = validationCriterias.find((item) => {
        return item.fieldId == chngd;
      });

      if (validator) {
        validationStatus[chngd] = softValidate(validator, changed[chngd]);
      }
    });

    setValidationStatus({ ...validationStatus });
  };

  const appendCloseButton = (data, columns) => {
    var closeButton = [
      {
        width: '3vw',
        render: (value, record, index) => {
          return (
            <Row justify="space-between" gutter={36}>
              <Col>
                <Popconfirm
                  title="Please confirm to delete"
                  onConfirm={() => {
                    setPreSubmitTableData(
                      data.filter((item) => item.uuid !== record.uuid)
                    );
                  }}
                >
                  <CloseCircleOutlined
                    style={{
                      fontSize: '20px',
                      color: 'red',
                    }}
                  />
                </Popconfirm>
              </Col>
            </Row>
          );
        },
      },
    ];
    return [...appendSlNo(columns), ...closeButton];
  };

  const appendSlNo = (columns) => {
    var slNo = [
      { width: '2vw' },
      {
        title: 'Sl. No',
        render: (value, record, index) => index + 1,
        width: '4vw',
      },
    ];
    return [...slNo, ...columns];
  };

  useEffect(() => {}, [preSubmitTableData]);

  const getForms = () => {
    var isData = false;
    getHealthRecordForms(sectionId, patient.pId)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            const tempFormIds = {};
            var tempMetaDataIds = [];
            var validationList = [];
            var tempParentIds = [];
            data.body.forEach((item) => {
              //getting formIds and corresponding field IDs for submit request Generation
              tempFormIds[item.id] = item.fields.map((field) => field.id);

              //finding fields which needs to call external API for fetching values eg:dropdown
              tempMetaDataIds = [
                ...tempMetaDataIds,
                ...item.fields.filter(
                  (field) =>
                    field.dropDownType !== null &&
                    field.dropDownType !== undefined &&
                    Object.keys(field.dropDownType).length !== 0
                ),
              ];
              //collecting parentIds to call dropdown values api when change value of dependend dropdown, eg: fetching filtered medicine doses after selecting a medicine
              tempParentIds = [
                ...tempParentIds,
                ...item.fields.filter(
                  (field) =>
                    field.parentField !== null &&
                    field.parentField !== undefined
                ),
              ]; //COLLECTING FIELDS HAVING SOFT VALIDATORS
              validationList = [
                ...validationList,
                ...item.fields
                  .filter(
                    (field) =>
                      field.softValidator !== null &&
                      field.softValidator !== undefined &&
                      field.softValidator.length !== 0
                  )
                  .map((item) => {
                    return {
                      fieldId: item.id,
                      validationCrieteria: item.softValidator.map((val) => ({
                        type: val.validator,
                        value: val.value,
                      })),
                    };
                  }),
              ];
            });

            // columns for presubmit table
            var tempTableColumns = [];
            var tempPreviousDataColumn = [];

            //converting forms into formBuilder readable format
            let FormsTemp = (
              !isData ? data.body : data.body[0].dynamicHealthRecord
            ).map((item) => {
              //generating presubmit table columns
              tempTableColumns = [
                ...tempTableColumns,
                ...generatePreSubmitFormFields(item.fields),
              ];

              tempPreviousDataColumn = [
                ...tempPreviousDataColumn,
                ...generatePreviousInfoTableColumns(item.fields),
              ];
              return {
                formName: isData ? item.form.formName : item.formName,
                fields: generateFormFields(item.fields),
              };
            });
            setFormIds(tempFormIds);
            setMetaDataFieldIds(tempMetaDataIds);
            setForms(FormsTemp);
            setPreSubmitTableColumns(tempTableColumns);
            setPreviousDataColumns(tempPreviousDataColumn);
            setDropDownParentIds(tempParentIds);
            setValidationCriterias(validationList);
            setFormFetchLoading(false);
          } else {
            message.error(data.body);
            setFormFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        setFormFetchLoading(false);

        console.log(err);
        Sentry.captureException(err);
      });
  };
  const getTableData = (size) => {
    getPreviousRecords(sectionId, patient.pId, patient.appointmentId, size, 0)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            setTableData(migrateIntoTableFormat(data.body));
            setPageSize(data.total);
            setTableFetchLoading(false);
          } else {
            // message.error("No Form Found");
            setTableFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setTableFetchLoading(false);
      });
  };

  const submitFormData = () => {
    setSubmitLoading(true);
    var dynamicFormData = [];

    preSubmitTableData.forEach((item) => {
      let data = Object.keys(formIds).map((key) => {
        const filtered = {};
        formIds[key].forEach((k) => {
          if (isMoment(item[k])) {
            filtered[k] = moment(item[k]).format('DD-MM-YYYY');
          } else {
            filtered[k] = item[k];
          }
        });

        return { formId: key, fields: deserialize(filtered) };
      });
      dynamicFormData = [...dynamicFormData, ...data];
    });

    addHealthRecordIPD(
      sectionId,
      patient.pId,
      dynamicFormData,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            message.success('Health Record added');
            getTableData(10);
            setPreSubmitTableData([]);
          } else {
            message.error("couldn't add Vital Informations!");
          }
        }
        setSubmitLoading(false);
        // getTableData();
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const formSubmitHandler = (values) => {
    form.resetFields();
    Object.keys(values).map((key) => {
      if (isMoment(values[key])) {
        values[key] = moment(values[key]).format('DD-MM-YYYY');
      }
    });
    setPreSubmitTableData([
      ...preSubmitTableData,
      { ...values, uuid: uuidv4() },
    ]);
  };

  const onClear = () => {
    // setFormInitialValues({});
    form.resetFields();
    setFormFetchLoading(true);
    setValidationStatus({});
    getForms();
  };

  useEffect(() => {
    setFormFetchLoading(true);
    setTableFetchLoading(true);
    getForms();
    getTableData(10);
  }, []);
  useEffect(() => {
    if (forms.length && metaDataFieldIds.length) {
      let fieldMetaPromises = metaDataFieldIds.map((item) => {
        return fetchFormFieldMeta(
          item.dropDownType.dropDownType,
          item.sectionId
        );
      });
      let tempDropDownValues = [...dropDownValues];

      Promise.all(fieldMetaPromises)
        .then((responses) => {
          responses.forEach(({ data, status }, index) => {
            if (status == 200) {
              if (data.status == 1)
                tempDropDownValues = [...tempDropDownValues, ...data.body];
              //Injecting meta data for corresponding  formfields
              forms.forEach((form) => {
                let metaField = form.fields.find(
                  (field) => field.name === metaDataFieldIds[index].id
                );
                if (metaField) {
                  // setDropDownValues([...dropDownValues, ...data.body]);
                  metaField.meta = data.body.map((item) => item.value);
                } else console.log('Meta undefined');
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setMetaDataFieldIds([]);
          setDropDownValues(tempDropDownValues);
          setForms([...forms]);
        });
    }
  }, [metaDataFieldIds, forms]);
  useEffect(() => {}, [previousDataColumns]);

  return !formFetchLoading && !tableFetchLoading ? (
    <Row>
      <StyledCol span={24}>
        {tableData.length > 0 && (
          <CustomeTableForRquests
            columns={appendSlNo(previousDataColumns)}
            dataSource={tableData}
            tableTitle={
              typos[section.sectionName]
                ? typos[section.sectionName].tableHead
                : section.sectionName
            }
            title={
              typos[section.sectionName]
                ? typos[section.sectionName].tableContainer
                : section.sectionName
            }
            isViewMore={true}
            getPreviousTabledata={getTableData}
            pageSize={pageSize}
          />
        )}
      </StyledCol>
      {preSubmitTableData.length > 0 && (
        <StyledCol padding="25px" span={24}>
          <Row>
            <Col span={24}>
              <CustomeTableForRquests
                tableTitle={
                  typos[section.sectionName]
                    ? typos[section.sectionName].tableHead
                    : 'New Entries'
                }
                title={section.sectionName}
                columns={
                  preSubmitTableColumns.length
                    ? appendCloseButton(
                        preSubmitTableData,
                        preSubmitTableColumns
                      )
                    : []
                }
                dataSource={preSubmitTableData}
              />
            </Col>
            <Col span={24}>
              <ButtonContainer>
                <RightButton
                  type="primary"
                  loading={submitLoading}
                  onClick={submitFormData}
                >
                  Submit
                </RightButton>
              </ButtonContainer>
            </Col>
          </Row>
        </StyledCol>
      )}
      {user.userType !== 'PATIENT' && (
        <StyledCol span={24}>
          <FormContainer>
            <Form
              layout="vertical"
              onFinish={formSubmitHandler}
              form={form}
              onValuesChange={onValuesChange}
              scrollToFirstError={true}
            >
              {forms.map((subForm) => (
                <FormBuilder
                  formInstance={form}
                  formConfig={[
                    {
                      inputType: 'heading',
                      label: subForm.formName,
                    },
                    ...subForm.fields,
                  ]}
                  softValidationFields={validationStatus}
                />
              ))}
              <Form.Item>
                {forms.length !== 0 && (
                  <DiscardSubmitWrapper>
                    <Button
                      type="primary"
                      ghost
                      htmlType="submit"
                      className="btn-save"
                      onClick={onClear}
                    >
                      Discard
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-save"
                    >
                      Add
                    </Button>
                  </DiscardSubmitWrapper>
                )}
              </Form.Item>
            </Form>
          </FormContainer>
        </StyledCol>
      )}
      {!tableData.length && user.userType === 'PATIENT' && (
        <NoRecords bgColor="#fff" message="No details for this visit" />
      )}
      <ViewDetailsModal
        visible={visible}
        setVisible={setVisible}
        dataSource={selectedInformation}
        titleText={
          typos[section.sectionName]
            ? typos[section.sectionName].tableHead
            : section.sectionName
        }
      />
      <Col span={24}>
        <div
          style={{
            background: 'rgba(0, 0, 0, 0)',
            padding: '20px',
            height: '65px',
          }}
        ></div>
      </Col>
    </Row>
  ) : (
    <Spinner />
  );
};
export default ThreeTierFormSection;
