import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../../utils/Spinner";
import * as Sentry from "@sentry/react";
import {
  getHealthRecordForms,
  addHealthRecordIPD,
  getSectionData,
} from "../../../../apis/healthRecordIPD";
import { fetchFormFieldMeta } from "../../../../apis/forms";
import {
  generateFormFields,
  deserialize,
} from "../../../utils/FormBuilder/valuesSerialisation";
import FormBuilder from "../../../utils/FormBuilder";
import { Row, Col, Button, message, Form } from "antd";
import moment from "moment";
import { softValidate } from "../helper";

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
`;

export const DiscardSubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledH3 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
`;

const PatientDetailsDiv = styled.div`
  margin: 40px 0px;
  margin-top: 10px;
`;

const StyledForm = styled(Form)`
  &
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #6d6d6d;
    background: #fff;
    cursor: not-allowed;
  }

  & .ant-input[disabled] {
    color: #6d6d6d;
    background: #fff;
    cursor: not-allowed;
    opacity: 1;
  }
  &.form-m2-style h3 {
    margin: 15px 0 25px;
    font-size: 20px;
  }
  &.form-m2-style .ant-form-item-label label {
    font-size: 16px;
    color: #818181;
  }
  &.form-m2-style .ant-form-item-label label:before {
    display: none;
  }
  &.form-m2-style .ant-form-item-label label:after {
    display: inline-block;
    margin-right: 4px;
    color: #ff0003;
    font-size: 18px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    font-weight: 700;
  }
  &.form-m2-style .ant-form-item-control-input textarea {
    height: 160px;
    font-size: 16px;
  }
`;

const CarePlan = () => {
  const { sectionId } = useParams();
  const [form] = Form.useForm();
  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedVitalInformation, setSelectedVitalInformation] = useState({});
  const patient = useSelector((state) => state.patientReducer);
  const user = useSelector((state) => state.userReducer);
  const section = useSelector((state) => state.sectionReducer);
  const [visible, setVisible] = useState(false);
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [tableFetchLoading, setTableFetchLoading] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [validationStatus, setValidationStatus] = useState({});
  const [validationCriterias, setValidationCriterias] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [dropDownParentIds, setDropDownParentIds] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);
  const theme = useSelector((state) => state.themeReducer);
  const onValuesChange = (changed) => {
    Object.entries(changed).map(([key, value]) => {
      let changedField = dropDownParentIds.find(
        (item) => item.parentField == key
      );
      if (changedField) {
        let dropDownItem = dropDownValues.filter((item) => item.value == value);

        if (dropDownItem[dropDownItem.length - 1]) {
          changedField.sectionId = dropDownItem[dropDownItem.length - 1].id;

          setMetaDataFieldIds([changedField]);
        }
      }
    });
    Object.keys(changed).forEach((chngd) => {
      let validator = validationCriterias.find((item) => {
        return item.fieldId == chngd;
      });

      if (validator) {
        validationStatus[chngd] = softValidate(validator, changed[chngd]);
      }
    });

    setValidationStatus({ ...validationStatus });
  };
  const getForms = useCallback(() => {
    var isData = false;
    getHealthRecordForms(section.sectionId, patient.pId)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            const tempFormIds = {};
            var tempMetaDataIds = [];
            var validationList = [];
            var tempParentIds = [];
            isData = data.body[0].date ? true : false;
            (!isData ? data.body : data.body[0].dynamicHealthRecord).forEach(
              (item) => {
                //getting formIds and corresponding field IDs for submit request Generation
                tempFormIds[item.id] = (isData ? item.form.fields : item.fields)
                  .filter((field) => field.fieldType !== "TAG_SELECT")
                  .map((field) => field.id);

                //finding fields which needs to call external API for fetching values eg:dropdown
                tempMetaDataIds = [
                  ...tempMetaDataIds,
                  ...item.fields.filter(
                    (field) =>
                      field.dropDownType !== null &&
                      field.dropDownType !== undefined &&
                      Object.keys(field.dropDownType).length !== 0
                  ),
                ];
                //collecting parentIds to call dropdown values api when change value of dependend dropdown, eg: fetching filtered medicine doses after selecting a medicine
                tempParentIds = [
                  ...tempParentIds,
                  ...item.fields.filter(
                    (field) =>
                      field.parentField !== null &&
                      field.parentField !== undefined
                  ),
                ]; //COLLECTING FIELDS HAVING SOFT VALIDATORS
                validationList = [
                  ...validationList,
                  ...item.fields
                    .filter(
                      (field) =>
                        field.softValidator !== null &&
                        field.softValidator !== undefined &&
                        field.softValidator.length !== 0
                    )
                    .map((item) => {
                      return {
                        fieldId: item.id,
                        validationCrieteria: item.softValidator.map((val) => ({
                          type: val.validator,
                          value: val.value,
                        })),
                      };
                    }),
                ];
              }
            );

            //converting forms into formBuilder readable format
            let FormsTemp = (
              !isData ? data.body : data.body[0].dynamicHealthRecord
            ).map((item) => {
              return {
                formName: isData ? item.form.formName : item.formName,
                fields: generateFormFields(item.fields).map((field) => ({
                  ...field,
                  disabled: user.userType === "DOCTOR" ? false : true,
                })),
              };
            });
            setFormIds(tempFormIds);
            setMetaDataFieldIds(tempMetaDataIds);
            setForms(FormsTemp);
            setValidationCriterias(validationList);
            setDropDownParentIds(tempParentIds);
            setFormFetchLoading(false);
          } else {
            message.error(data.body);
            setFormFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        setFormFetchLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  }, [sectionId]);

  const getPreviousData = () => {
    getSectionData(
      section.sectionId,
      user.useType == "PATIENT" ? user.id : patient.pId,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status == 200) {
          if (data.status == 1) {
            let initialValues = {};
            data.body.forEach((item) => {
              item.fields.forEach((item) => {
                initialValues[item.fieldId] = item.value;
              });
            });
            setFormInitialValues(initialValues);
          }
        }
      })
      .catch(console.err);
  };

  const submitFormData = (dynamicFormData) => {
    setSubmitLoading(true);
    addHealthRecordIPD(
     section.sectionId,
      patient.pId,
      dynamicFormData,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            message.success("Health Record added");
            // form.resetFields();
            useefectFunctions();
          } else {
            message.error("couldn't add Vital Informations!");
          }
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const formSubmitHandler = (values) => {
    let data = Object.keys(formIds).map((key) => {
      console.log("Objecct key",Object.keys(formIds))
      console.log("key in form is",key)
      const filtered = {};
      formIds[key].forEach((k) => {
        filtered[k] = values[k];
      });
      return { formId: key, fields: deserialize(filtered) };
    });
    console.log("data in submithandler",data)
    submitFormData(data);
  };

  const onClear = () => {
    // setFormInitialValues({});
    form.resetFields();
    setFormFetchLoading(true);
    setValidationStatus({});
    getForms();
  };

  const useefectFunctions = () => {
    setFormFetchLoading(true);
    getForms();
    getPreviousData();
    if (user.userType === "PATIENT") {
      let appointment = JSON.parse(
        sessionStorage.getItem("appointmentDetails")
      );
      setAppointmentDetails(appointment);
    }
  };

  useEffect(() => {
    useefectFunctions();
  }, []);

  useEffect(() => {
    if (forms.length && metaDataFieldIds.length) {
      let fieldMetaPromises = metaDataFieldIds.map((item) => {
        return fetchFormFieldMeta(
          item.dropDownType.dropDownType,
          item.sectionId
        );
      });
      let tempDropDownValues = [...dropDownValues];

      Promise.all(fieldMetaPromises)
        .then((responses) => {
          responses.forEach(({ data, status }, index) => {
            if (status == 200) {
              if (data.status == 1)
                tempDropDownValues = [...tempDropDownValues, ...data.body];
              //Injecting meta data for corresponding  formfields
              forms.forEach((form) => {
                let metaField = form.fields.find(
                  (field) => field.name === metaDataFieldIds[index].id
                );
                if (metaField) {
                  // setDropDownValues([...dropDownValues, ...data.body]);
                  metaField.meta = data.body.map((item) => item.value);
                } else console.log("Meta undefined");
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setMetaDataFieldIds([]);
          setDropDownValues(tempDropDownValues);
          setForms([...forms]);
        });
    }
  }, [metaDataFieldIds, forms]);

  useEffect(() => {
    if (Object.keys(formInitialValues).length === 0) form.resetFields();
  }, [formInitialValues]);

  return !formFetchLoading ? (
    <Row gutter={[24, 12]}>
      <Col span={24}>
        <FormContainer>
          {user.userType !== "PATIENT" && (
            <StyledH3 color={theme["@primary-color"]}>
              Patient Care plan
            </StyledH3>
          )}

          {user.userType === "PATIENT" ? (
            <PatientDetailsDiv className="mt-10">
              <StyledH3 color="#5d5d5d">
                Date : {moment(appointmentDetails.date).format("DD-MM-YYYY")}
              </StyledH3>
              <StyledH3 color="#5d5d5d">
                Hospital Name : {appointmentDetails.hospital}
              </StyledH3>
              <StyledH3 color="#5d5d5d">
                Doctor Name : Dr.{appointmentDetails.doctor}
              </StyledH3>
              <StyledH3 color="#5d5d5d">
                Department : {appointmentDetails.department}
              </StyledH3>
            </PatientDetailsDiv>
          ) : (
            <PatientDetailsDiv>
              <StyledH3 color="#5d5d5d">Patient ID : {patient.pId}</StyledH3>
              <StyledH3 color="#5d5d5d">
                Patient Name : {patient.pName}
              </StyledH3>
              <StyledH3 color="#5d5d5d">
                Age : {moment().diff(moment(patient.dob), "years", false)}
              </StyledH3>
              <StyledH3 color="#5d5d5d">Gender : {patient.gender}</StyledH3>
            </PatientDetailsDiv>
          )}
          <StyledForm
            className="form-m2-style"
            layout="vertical"
            onFinish={formSubmitHandler}
            form={form}
            initialValues={formInitialValues}
            key={Object.keys(formInitialValues).length}
            onValuesChange={onValuesChange}
            scrollToFirstError={true}
          >
            {forms.map((subForm) => (
              <FormBuilder
                formConfig={subForm.fields}
                formInstance={form}
                softValidationFields={validationStatus}
              />
            ))}

            {user.userType !== "PATIENT" && (
              <Form.Item>
                {forms.length !== 0 && (
                  <DiscardSubmitWrapper>
                    <Button
                      type="primary"
                      ghost
                      htmlType="submit"
                      className="btn-save"
                      onClick={onClear}
                    >
                      Discard
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-save"
                      loading={submitLoading}
                    >
                      Save
                    </Button>
                  </DiscardSubmitWrapper>
                )}
              </Form.Item>
            )}
          </StyledForm>
        </FormContainer>
      </Col>
    </Row>
  ) : (
    <Spinner />
  );
};

export default CarePlan;
