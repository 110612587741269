export const dosageData = [
  {
    key: '1',
    tags: ['1/4', '1/2', '3/4', '1', '1 1/2', '2', '2 1/2', '3'],
  },
];

export const syrupData = [
  {
    key: '1',
    tags: ['1ml', '250ml', '300ml', '400ml', '600ml'],
  },
];

export const frequencyData = [
  {
    key: '1',
    tags: ['4h', '6h', '8h', 'once', 'twice', 'thrice', '4times', '5times'],
  },
];
