import React, { useEffect, useState } from 'react';

import { Select, Divider, Input } from 'antd';

import { getCodes } from '../../../apis/healthRecord';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const AddItem = ({ formInstance, fieldId }) => {
  const [val, setVal] = useState('');

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
      <Input
        size="small"
        value={val}
        onChange={(e) => {
          setVal(e.target.value);
        }}
      />
      <a
        style={{
          flex: 'none',
          padding: '8px',
          display: 'block',
          cursor: 'pointer',
        }}
        onClick={() => {
          formInstance.setFieldsValue({ [fieldId]: val });
        }}
      >
        <PlusOutlined /> Add
      </a>
    </div>
  );
};

export const InputComponents = ({ value, onChange, item, formInstance }) => {
  console.log('value,  item, formInstance', value, item, formInstance);
  const [codeList, setCodeList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [searchParam, setSearchParam] = useState('');
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();
  const fetchCodeList = (code, pageNo, scroll) => {
    if (scroll) {
      getCodes(item.codetype, pageNo, searchParam).then((res) => {
        var totalPages = res.data.total / 20;

        setTotal(Math.floor(totalPages));
        setLoading(false);
        setCodeList([...codeList, ...res.data.body]);
      });
    } else {
      getCodes(item.codetype, 0, searchParam).then((res) => {
        var totalPages = res.data.total / 20;

        setTotal(Math.floor(totalPages));
        setLoading(false);
        setCodeList(res.data.body);
      });
    }
  };
  useEffect(() => {
    fetchCodeList(item.codeType, pageNo, searchParam, false);
  }, []);
  useEffect(() => {
    fetchCodeList(item.codeType, pageNo, searchParam, false);
  }, [searchParam]);
  const onScroll = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      setPageNo(pageNo + 1);

      e.target.scrollTo(0, e.target.scrollHeight);

      if (total > pageNo + 1) {
        fetchCodeList(item.codeType, pageNo + 1, true);
        setLoading(true);
      }
    }
  };
  console.log('codelist', codeList);
  return (
    <div>
      <Select
        showSearch
        placeholder={'Select ' + item.label}
        optionFilterProp="children"
        onPopupScroll={(e) => {
          onScroll(e);
        }}
        loading={loading}
        onChange={(p) => {
          onChange(JSON.parse(p));
          setLoading(false);
        }}
        value={JSON.stringify(value)}
        // onFocus={() => setLoading(true)}
        onBlur={() => setLoading(false)}
        onSearch={(value) => {
          setLoading(true);
          setSearchParam(value);

          // ALLERGY_SEVERITY;
        }}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {formInstance && (
              <>
                <Divider style={{ margin: '4px 0' }} />
                <AddItem formInstance={formInstance} fieldId={item.name} />
              </>
            )}
          </div>
        )}
      >
        {codeList.map((item) => (
          <Option value={JSON.stringify(item)}>{item.text}</Option>
        ))}
      </Select>
    </div>
  );
};
