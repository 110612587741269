import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import {
  Row,
  Col,
  Button,
  Upload,
  notification,
  Form,
  Input,
  Select,
} from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ModalTitle, StyledRow } from "./styledComponents";
import { useState } from "react";
import { useEffect } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { codeTypes } from "../../../Datasets/codeTypes";

const ModalBodyContainer = styled.div`
  width: 500px;
`;

const DictionaryDetailsModal = ({
  visible,
  setVisible,
  selectedItem,
  setSelectedItem,
  edit,
  submitHandler,
  camelCaseText,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  const [form] = Form.useForm();

  useEffect(() => {
    if (edit) form.setFieldsValue({ ...selectedItem });
  }, [selectedItem]);

  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <ModalTitle color={theme["@primary-color"]}>
              Add new dictionary
            </ModalTitle>
          </Col>
        </Row>
        <Form
          layout="vertical"
          onFinish={(values) => submitHandler(values)}
          form={form}
          style={{ width: "100%" }}
          scrollToFirstError={true}
        >
          <StyledRow justify="center" gutter={["24"]}>
            <Row justify="center">
              <Col span={24}>
                <Form.Item
                  label="Code"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Code cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input size="large" placeholder="Enter Code" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Code Type"
                  name="codeType"
                  rules={[
                    {
                      required: true,
                      message: "code Type cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Select
                    size="large"
                    suffixIcon={<CaretDownOutlined showSearch />}
                    placeholder={"Enter Code Type"}
                    showSearch
                  >
                    {camelCaseText(codeTypes()).map((item, index) => (
                      <Select.Option value={codeTypes()[index]} key={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Text"
                  name="text"
                  rules={[
                    {
                      required: true,
                      message: "Text cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input size="large" placeholder="Enter Text" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Description cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input size="large" placeholder="Enter Description" />
                </Form.Item>
              </Col>
            </Row>
          </StyledRow>
          <StyledRow gutter={12} justify="end">
            <Col>
              <Button
                onClick={() => {
                  setVisible(false);
                  setSelectedItem(null);
                  form.resetFields();
                }}
                danger
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {}}
                htmlType="submit"
                // disabled={selectedFile ? false : true}
                // loading={loading}
              >
                {edit ? "Save" : "Add"}
              </Button>
            </Col>
          </StyledRow>
        </Form>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default DictionaryDetailsModal;
