import React from "react";
import { useSelector } from "react-redux";
import { EmptyMessageContainer } from "./styledComponents";
import { columns } from "./tableConfig";
import { Empty } from "antd";
import Spinner from "../../../utils/Spinner";
import { BlueBgTable } from "../styledComponents";

const DiscountTable = ({ discounts, loading, setEditModal, setselectedItem, setDiscount }) => {
  const theme = useSelector((state) => state.themeReducer);

  return loading ? (
    <Spinner height={"100px"} />
  ) : discounts != null && discounts.length > 0 ? (
    <BlueBgTable
      columns={columns(setEditModal, setselectedItem, setDiscount)}
      dataSource={discounts}
      loading={loading}
      pagination={false}
    />
  ) : (
    <EmptyMessageContainer>
      <Empty />
    </EmptyMessageContainer>
  );
};
export default DiscountTable;
