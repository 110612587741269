import React, { useState, useEffect } from "react";
import { NewButton } from "./styledComponents";
import { UserAddOutlined } from "@ant-design/icons";
import { Row, Col, message } from "antd";
import {
  SectionHeading,
  SectionHeadContainer,
  StyledDiv,
} from "../styledComponents";
import { getAllBedsInHospital } from "../../../../apis/Appointment";
import BedsTable from "./BedsTable";
import NewDocumentModal from "./NewDocumentModal";
import ConfirmationModal from "./ConfirmationModal";
import { BodyContainer } from "../Sections/styledComponents";
import { useSelector } from "react-redux";
import { getHospitalId } from "../../../utils/LocalStorage";
const Beds = () => {
  const [beds, setBeds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setselectedItem] = useState();
  const theme = useSelector((state) => state.themeReducer);
  const getBeds = () => {
    setLoading(true);
    getAllBedsInHospital()
      .then(({ data, status }) => {
        // if (status === 200) {
        //   if (data.status == 1) setBeds(data.body);
        // } else {
        //   message.error("Unable to fetch beds");
        // }
        setBeds(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const newBedClickHandler = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getBeds();
    }

    // Cleanup function to set the flag to false when the component is unmounted
    return () => {
      isMounted = false;
    };

  }, []);
  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading>Beds</SectionHeading>
            </Col>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <NewButton
                type="primary"
                // ghost
                // size="large"
                icon={<UserAddOutlined />}
                onClick={newBedClickHandler}
              >
                New Bed
              </NewButton>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <StyledDiv bgcolor={theme["@primary-color"]}>
            <div>&nbsp;&nbsp;Beds</div>
            <div>&nbsp;&nbsp;</div>
          </StyledDiv>
          <BedsTable
            beds={beds}
            loading={loading}
            setEditModal={setModalVisible}
            setDeleteModal={setDeleteModal}
            setselectedItem={setselectedItem}
          />
        </BodyContainer>
      </Col>
      <NewDocumentModal
        visible={modalVisible}
        setVisible={setModalVisible}
        refetch={() => getBeds()}
        selectedItem={selectedItem}
        setSelectedItem={setselectedItem}
      />
      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        selectedItem={selectedItem}
        setSelectedItem={setselectedItem}
        refetch={() => getBeds()}
      />
    </Row>
  );
};

export default Beds;
