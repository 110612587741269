import CustomModal from "../utils/CustomModal.jsx/index.jsx";

import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Form, Input, message } from "antd";
import { contactUsRequest } from "../../apis/Public";
import TextArea from "antd/lib/input/TextArea";
import { RightButton } from "./styledElements.js";
import * as Sentry from "@sentry/react";

const BodyContainer = styled.div`
  text-align: center;
  max-width: 750px;
  margin: 0px 10px;

  @media (max-width: 991px) {
    && {
      width: 90%;
      margin: auto;
    }
  }
`;
const StyledH2 = styled.h2`
  color: #6d6d6d;
`;

const PasswordResetForm = ({ onFinish, loading, form }) => (
  <Form
    layout="vertical"
    onFinish={onFinish}
    style={{ textAlign: "left" }}
    form={form}
    className="popup-form-data"
    scrollToFirstError={true}
  >
    <Row gutter={[24, 0]}>
      <Col lg={8} sm={24} xs={24}>
        <Form.Item
          label="Full Name"
          name="fullName"
          rules={[{ required: true }]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>
      <Col lg={8} sm={24} xs={24}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please Enter a Valid Email !",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>
      <Col lg={8} sm={24} xs={24}>
        <Form.Item
          label="Mobile No"
          name="mobileNumber"
          rules={[{ required: true }]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>

      <Col lg={24} sm={24} xs={24}>
        <Form.Item label="Reason" name="reason" rules={[{ required: true }]}>
          <TextArea size="large" rows={5} />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item className="btn-send-bx">
          <RightButton
            htmlType="submit"
            type="primary"
            loading={loading}
            size="large"
            className="submit-btn"
          >
            Send
          </RightButton>
        </Form.Item>
      </Col>
    </Row>
  </Form>
);

const ContactUsModal = ({ visible, setVisible }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    {
      setLoading(true);

      contactUsRequest(values)
        .then((res) => {
          if (res.status === 200)
            message.success("Request submitted successfully.");
          setVisible(false);
          setLoading(false);
          form.resetFields();
        })
        .catch((err) => {
          setLoading(false);
          if (
            typeof err.response.data.body !== "object" &&
            !Array.isArray(err.response.data.body)
          )
            message.error(err.response.data.body);
          else message.error("Service temparly unavailable");
          console.log(err);
Sentry.captureException(err);
        });
    }
  };

  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true }}
      isClosable={true}
      setVisible={setVisible}
    >
      <BodyContainer className="popup-box-content">
        <Row gutter={[10, 24]}>
          <Col span={24}>
            <StyledH2>Contact Us</StyledH2>
          </Col>

          <Col span={24}>
            <PasswordResetForm
              onFinish={onFinish}
              loading={loading}
              form={form}
            />
          </Col>
        </Row>
      </BodyContainer>
    </CustomModal>
  );
};

export default ContactUsModal;
