import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchBillingDetails } from "../../../apis/Appointment";
import {
  message,
  Row,
  Col,
  Tooltip,
  Modal,
  Table,
  Typography,
  Button,
  Comment,
  Avatar,
  Form,
  notification,
} from "antd";
import TableContainer from "./TableContainer";
import * as Sentry from "@sentry/react";
import { migrateIntoTableFormat } from "../HealthRecords/helper";
import moment from "moment";
import { LeftCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import Spinner from "../../utils/Spinner";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";
import HospitalLogo from "../../Login/HospitalLogo";
import { ReactComponent as DoctorIcon } from "../../../assets/icons/304919-black.svg";
import CustomTable from "../../utils/CustomTable";
import SuccessModal from "../../utils/StatusModals/SuccessModals";
import ConfirmationModal from "../HealthRecords/Billing/ConfirmationModal";
import TextArea from "antd/lib/input/TextArea";
import DefaultAvatar from "../../../assets/images/avatarUser.png";
import { StyledContent } from "./styledComponents";
import { PROFILE_IMAGE_PREFIX } from "../../../constants/StringConstants";
import { BlueBgTable, StyledDiv } from "../AdminDashboard/styledComponents";
import {
  billHospitalDataColumns,
  billLabOrRadiologyDataColumns,
  billPharmacyDataColumns,
} from "./columns";
import { getSettings } from "../../../apis/settings";

const { Text } = Typography;

const StyledModal = styled(Modal)`
  width: 920px !important;
`;

const BillContainer = styled.div`
  padding: 20px;
  width: 95%;
  height: 100%;
`;

const SubmitButtonCol = styled(Col)`
  margin-top: 20px;
`;

const StyledDocIcon = styled(DoctorIcon)`
  height: 60px;
  width: 50px;
`;

const Hospitalname = styled.h2`
  color: black;
  text-transform: capitalize;
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
`;

const BillDetailsDiv = styled.div`
  margin: 100px 50px;
  display: flex;
  flex-direction: column;
  & p {
    font-size: 20px;
    font-weight: 500;
  }
`;

const StyledTableRow = styled(Row)`
  background: #fff;
  padding: 20px;
`;

const Heading = styled.h3`
  font-weight: 600;
  color: ${(props) => props.color};
  padding-bottom: 10px;
  @media (max-width: 1440px) {
    font-size: 20px;
  }
`;

const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;

const BottomContainer = styled(Col)`
  margin-top: 30px;
`;

const CommentLabel = styled.p``;

const StyledAvatar = styled(Avatar)`
  & .cls-1 {
    fill: black;
  }
`;

const BlueBgPrintTable = styled(Table)`
  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    /* position: relative; */
    font-size: 14px !important;
    padding: 8px !important;
    /* overflow-wrap: break-word; */
    margin: 0px;
  }
  & .ant-table-thead > tr > th {
    /* padding: 8px 0 !important; */
    background: white;
    font-weight: 600;
  }
  & .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:first-child {
    border-left: 1px solid #f0f0f0;
  }
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #f0f0f0;
    font-size: 12px !important;
  }
  & .ant-table-tbody > tr > td.total {
    font-size: 18px !important;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #f0f0f0;
  }
`;

const PatientHistory = () => {
  const patient = useSelector((state) => state.patientReducer);
  const user = useSelector((state) => state.userReducer);
  const previousDataColumns = [
    { width: "1vw" },
    {
      title: "Sl. No",
      render: (value, item, index) => {
        return index + 1;
      },
    },
    {
      title: "Date",
      dataIndex: "Date",
      render: (value) => moment(value).format("DD-MM-YYYY"),
    },
    { title: "Bill No", dataIndex: "Bill No" },
    { title: "Hospital Name", dataIndex: "Hospital Name" },
    { title: "Visit Type", dataIndex: "Visit Type" },
    { title: "Doctor Name", dataIndex: "Doctor Name" },
    { title: "Department", dataIndex: "Department" },
    {
      title: "Bill Amount",
      dataIndex: "Bill Amount",
      render: (data, row, index) =>
        row?.Currency + " " + parseFloat(data ? data : 0).toFixed(2),
    },
    { title: "Payment Type", dataIndex: "Payment type" },
  ];
  const [billingData, setBillingData] = useState([]);
  const [formFetchLoading, setFormFetchLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState(null);
  const [changedStatus, setChangedStatus] = useState("");
  const theme = useSelector((state) => state.themeReducer);
  const images = useSelector((state) => state.imageReducer);
  const [comments, setComments] = useState([]);
  const { pId } = useParams();
  console.log("pIdd", pId);
  const { pName } = useParams();
  const params = useParams();
  const componentRef = useRef();
  const history = useHistory();
  const [form] = Form.useForm();
  const [hospitalLogo, setHospitalLogo] = useState();
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [settings, setSettings] = useState();

  const fetchImageAndPrint = () => {
    const objectURL = PROFILE_IMAGE_PREFIX + images.logo;
    console.log("logo", objectURL);
    console.log("images", images);
    fetch(objectURL, {
      method: "GET",
      headers: { "Content-Type": "application/octet-stream" },
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        setHospitalLogo(url);
        console.log("url");
        console.log(url);
      })
      .catch(console.log)
      .finally(() => {
        handlePrint();
      });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const useeffectFunctions = () => {
    if (user.userType === "INSURANCE") {
      setInsurance(true);
      const insuranceRequestId = localStorage.getItem("insuranceRequestId");
    } else {
      fetchBillingDetails(null, pId)
        .then((res) => {
          console.log("data in billing", res.data.body);
          if (res.data.status === 1) {
            setBillingData(res.data.body);
            let data = res.data.body;
            setTableData(migrateIntoTableFormat(data));
          } else {
            message.error("Could not fetch billing details,Try again");
          }
        })
        .catch((err) => {
          setFormFetchLoading(false);
          console.log(err);
          Sentry.captureException(err);
          message.error("Could not fetch billing details,Try again");
        })
        .finally(() => {
          setFormFetchLoading(false);
        });
    }
  };

  useEffect(() => {
    getSettings().then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          setSettings(data.body);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      useeffectFunctions();
    }
  }, [user]);

  useEffect(() => {
    if (user.userType === "PATIENT") {
      let appointment = JSON.parse(
        sessionStorage.getItem("appointmentDetails")
      );
      setAppointmentDetails(appointment);
    }

    const objectURL = PROFILE_IMAGE_PREFIX + images.logo;
    console.log("logo", objectURL);
    console.log("images", images);
    fetch(objectURL, {
      method: "GET",
      headers: { "Content-Type": "application/octet-stream" },
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        setHospitalLogo(url);
        console.log("url");
        console.log(url);
      })
      .catch(console.log);
  }, [user]);

  const appendCloseButton = (data, columns) => {
    var column = [
      {
        render: (value, record, index) => {
          return (
            <Row>
              {!insurance ? (
                <Col>
                  <Tooltip title="Print">
                    <PrinterOutlined
                      style={{
                        fontSize: "20px",
                        color: theme["@primary-color"],
                      }}
                      onClick={() => {
                        setSelectedData(record);
                        setModalVisible(true);
                        fetchImageAndPrint();
                        console.log("record");
                      }}
                    />
                  </Tooltip>
                </Col>
              ) : (
                <></>
              )}
              <Col style={{ padding: "5px" }}></Col>
            </Row>
          );
        },
      },
    ];
    return [...columns, ...column];
  };

  const summary = () => {
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={6}>
            <Text strong={true}>Total Amount</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true}>AED {totalAmount}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={6}>
            <Text strong={true}> Remaining Amount</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text strong={true}>AED {remainingAmount}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const statusMap = {
    WAITING: "Claim Waiting",
    DECLINE: "Declined",
    ACCEPT: "Paid",
  };

  const changeStatus = (insuranceStatus) => {
    const insuranceId = history.location?.state?.insuranceRequestId
      ? history.location.state.insuranceRequestId
      : localStorage.getItem("insuranceRequestId");
    const status = statusMap[insuranceStatus];
    const payload = {
      comment: comment,
      insuranceRequestId: insuranceId,
      status: status,
    };
  };

  const submitClaim = (insuranceStatus) => {
    setSubmitLoading(true);
    changeStatus(insuranceStatus);
  };

  return !formFetchLoading ? (
    <StyledContent>
      <h2>
        <LeftCircleOutlined
          onClick={() => {
            history.goBack();
          }}
        />
        {"  "}
        Billing History
      </h2>
      <StyledTableRow>
        <Col span={24}>
          <Heading color={theme["@primary-color"]}>
            {" "}
            {insurance ? "Billing" : "Billing History"}
          </Heading>
        </Col>
        <Col span={24}>
          <div>
            <StyledDiv bgcolor={theme["@primary-color"]}>
              <div>&nbsp;&nbsp;Billing History</div>
              <div>&nbsp;&nbsp;</div>
            </StyledDiv>
            {console.log("tabledata", tableData)}
            {console.log("previousdta", previousDataColumns)}
            {console.log("summary", summary)}

            {insurance ? (
              <BlueBgTable
                pagination={false}
                {...{
                  columns: previousDataColumns.length
                    ? appendCloseButton(tableData, previousDataColumns)
                    : [],
                  dataSource: tableData,
                  summary: summary,
                }}
                tableTitle={insurance ? "Billing" : "Billing History"}
              />
            ) : (
              <BlueBgTable
                pagination={false}
                scroll={{ x: "max-content" }}
                {...{
                  columns: previousDataColumns.length
                    ? appendCloseButton(tableData, previousDataColumns)
                    : [],
                  dataSource: tableData,
                }}
                tableTitle={insurance ? "Billing" : "Billing History"}
              />
            )}
          </div>
        </Col>
        {insurance ? (
          <BottomContainer span={24}>
            <Col span={24}>
              <Heading color={theme["@primary-color"]}> Notes</Heading>
            </Col>
            <Col span={24}>
              {comments.map((comment) => {
                return (
                  <Comment
                    author={comment.updatedBy.userName}
                    avatar={
                      <StyledAvatar
                        src={
                          comment.updatedBy.profilePic
                            ? comment.updatedBy.profilePic
                            : DefaultAvatar
                        }
                        alt="Han Solo"
                      />
                    }
                    content={<p>{comment.comment}</p>}
                    datetime={
                      <span>{moment(comment.updatedDate).fromNow()}</span>
                    }
                  />
                );
              })}
            </Col>

            <Form
              layout={"vertical"}
              onFinish={(values) => {
                setComment(values.comment);
                setChangedStatus("WAITING");
                setConfirmationModal(true);
              }}
              form={form}
              scrollToFirstError={true}
            >
              {status !== "Paid" && (
                <Form.Item
                  label="Note"
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Note cannot be empty",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    size="large"
                    placeholder="Add comments here"
                  />
                </Form.Item>
              )}
              <Form.Item>
                <SubmitButtonCol span={24}>
                  {status === "Pending" && (
                    <RightButton htmlType="submit" type="primary">
                      Claim
                    </RightButton>
                  )}
                  {status === "Claim Waiting" && (
                    <>
                      <RightButton
                        type="primary"
                        onClick={() => {
                          form
                            .validateFields()
                            .then((response) => {
                              setComment(
                                form.getFieldsValue(["comment"]).comment
                              );
                              setChangedStatus("DECLINE");
                              setConfirmationModal(true);
                            })
                            .catch((error) => {
                              console.log("error", error);
                            });
                        }}
                      >
                        Decline
                      </RightButton>
                      <RightButton
                        type="primary"
                        onClick={() => {
                          form
                            .validateFields()
                            .then((re) => {
                              setComment(
                                form.getFieldsValue(["comment"]).comment
                              );
                              setChangedStatus("ACCEPT");
                              setConfirmationModal(true);
                            })
                            .catch((err) => {
                              console.log("error", err);
                            });
                        }}
                      >
                        Accept
                      </RightButton>
                    </>
                  )}

                  <RightButton
                    type="primary"
                    ghost
                    onClick={() => history.push("/")}
                  >
                    Cancel
                  </RightButton>
                </SubmitButtonCol>
              </Form.Item>
            </Form>
          </BottomContainer>
        ) : (
          <></>
        )}
      </StyledTableRow>
      <StyledModal
        title="Bill"
        // visible={modalVisible}
        footer={null}
        onOk={() => {
          setModalVisible();
          setSelectedData({});
        }}
        onCancel={() => {
          setModalVisible();
          setSelectedData({});
        }}
      >
        <BillContainer>
          <StyledRow>
            <StyledDocIcon />
            <Hospitalname>Hospital Logo</Hospitalname>
          </StyledRow>
          <Hospitalname style={{ textAlign: "center" }}>
            Bill Details
          </Hospitalname>
          <BillDetailsDiv>
            <p>Date : {moment(selectedData["Date"]).format("DD-MM-YYYY")}</p>
            <p>Bill No : {selectedData["Bill No"]}</p>
            <p>Department: {selectedData["Department"]}</p>
            <p>Doctor Name: {selectedData["Doctor Name"]}</p>
            <p>Bill Amount: {selectedData["Bill Amount"]}</p>
          </BillDetailsDiv>
        </BillContainer>
      </StyledModal>

      <div style={{ display: "none" }}>
        {/* <div> */}
        <BillContainer ref={componentRef}>
          <div
            style={{
              borderTop: `1px solid #f0f0f0`,
              marginLeft: 20,
              marginRight: 20,
              height: "2px",
              width: "100%",
              marginBottom: "30px",
            }}
          ></div>
          <Row justify="space-between">
            <img src={hospitalLogo} style={{ width: "20%" }} />
            <Hospitalname style={{ alignItems: "center", display: "flex" }}>
              {selectedData["Hospital Name"]}
            </Hospitalname>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                width: "20%",
                justifyContent: "end",
              }}
            >
              Bill No : <b>&nbsp;{selectedData["Bill No"]}</b>
            </p>
          </Row>
          <div
            style={{
              borderTop: `1px solid ${theme["@primary-color"]}`,
              marginLeft: 20,
              marginRight: 20,
              height: "2px",
              width: "100%",
            }}
          ></div>
          <div style={{ textAlign: "center", fontSize: "17px" }}>
            {selectedData["Visit Type"] + " Bill Details"}
          </div>
          <div
            style={{
              borderTop: `1px solid ${theme["@primary-color"]}`,
              marginLeft: 20,
              marginRight: 20,
              height: "20px",
              width: "100%",
            }}
          ></div>
          <Row justify="space-between" style={{ padding: "20px" }}>
            {/* {JSON.stringify(user)}
            {JSON.stringify(selectedData)} */}
            <Col>
              {console.log("user", billingData)}
              {billingData.length !== 0 ? (
                <p>
                  Patient Name :{" "}
                  {`${billingData[0].appointment.patient.firstName} ${billingData[0].appointment.patient.lastName}`}
                </p>
              ) : null}
              <p>Date : {moment(selectedData["Date"]).format("DD-MM-YYYY")}</p>
            </Col>
            <Col>
              {/* <p>Bill No : {selectedData["Bill No"]}</p> */}
              <p>Doctor Name: {selectedData["Doctor Name"]}</p>
              <p>Department: {selectedData["Department"]}</p>
            </Col>
          </Row>
          <div
            style={{
              borderTop: `1px solid #f0f0f0`,
              marginLeft: 20,
              marginRight: 20,
              height: "2px",
              width: "100%",
              marginBottom: "50px",
            }}
          ></div>
          {selectedData["Visit Type"] == "Pharmacy" ? (
            <>
              {selectedData?.ordersWithPaymentResponse?.table?.length > 0 && (
                <BlueBgPrintTable
                  style={{
                    width: "100%",
                    margin: "0 20px",
                  }}
                  dataSource={selectedData?.ordersWithPaymentResponse?.table}
                  columns={billPharmacyDataColumns(settings)}
                  pagination={false}
                />
              )}
              <div
                style={{
                  borderTop: `1px solid #f0f0f0`,
                  marginLeft: 20,
                  marginRight: 20,
                  height: "2px",
                  width: "100%",
                  marginBottom: "50px",
                  marginTop: "50px",
                }}
              ></div>

              <table
                style={{
                  width: "100%",
                  margin: "0 20px",
                  border: "1px solid #f0f0f0",
                  borderCollapse: "collapse",
                }}
              >
                {selectedData?.ordersWithPaymentResponse?.paymentResponse &&
                  Object.keys(
                    selectedData?.ordersWithPaymentResponse?.paymentResponse
                  ) &&
                  Object.keys(
                    selectedData?.ordersWithPaymentResponse?.paymentResponse
                  ).map((item) => (
                    <tr
                      style={{
                        border: "1px solid #f0f0f0",
                        borderCollapse: "collapse",
                      }}
                    >
                      <td
                        style={{
                          border: "1px solid #f0f0f0",
                          borderCollapse: "collapse",
                        }}
                      >
                        <div style={{ padding: "5px 0 5px 20px" }}>
                          {item
                            .replace(/([A-Z])/g, " $1")
                            .charAt(0)
                            .toUpperCase() +
                            item.replace(/([A-Z])/g, " $1").slice(1)}
                        </div>
                      </td>
                      <td
                        style={{
                          border: "1px solid #f0f0f0",
                          borderCollapse: "collapse",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                          }}
                        >
                          {selectedData?.Currency}{" "}
                          {parseFloat(
                            selectedData?.ordersWithPaymentResponse
                              ?.paymentResponse[item]
                              ? selectedData?.ordersWithPaymentResponse
                                  ?.paymentResponse[item]
                              : 0
                          ).toFixed(2)}
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
            </>
          ) : selectedData["Visit Type"] == "Hospital" ? (
            <BlueBgPrintTable
              style={{
                width: "100%",
                margin: "0 20px",
              }}
              dataSource={[selectedData]}
              columns={billHospitalDataColumns()}
              pagination={false}
            />
          ) : (
            <>
              {selectedData?.ordersWithPaymentResponse?.table?.length > 0 && (
                <BlueBgPrintTable
                  style={{
                    width: "100%",
                    margin: "0 20px",
                  }}
                  dataSource={selectedData?.ordersWithPaymentResponse?.table}
                  columns={billLabOrRadiologyDataColumns(settings)}
                  pagination={false}
                />
              )}
              <div
                style={{
                  borderTop: `1px solid #f0f0f0`,
                  marginLeft: 20,
                  marginRight: 20,
                  height: "2px",
                  width: "100%",
                  marginBottom: "50px",
                  marginTop: "50px",
                }}
              ></div>
              <table
                style={{
                  width: "100%",
                  margin: "0 20px",
                  border: "1px solid #f0f0f0",
                  borderCollapse: "collapse",
                }}
              >
                {selectedData?.ordersWithPaymentResponse?.paymentResponse &&
                  Object.keys(
                    selectedData?.ordersWithPaymentResponse?.paymentResponse
                  ) &&
                  Object.keys(
                    selectedData?.ordersWithPaymentResponse?.paymentResponse
                  ).map((item) => (
                    <tr
                      style={{
                        border: "1px solid #f0f0f0",
                        borderCollapse: "collapse",
                      }}
                    >
                      <td
                        style={{
                          border: "1px solid #f0f0f0",
                          borderCollapse: "collapse",
                        }}
                      >
                        <div style={{ padding: "5px 0 5px 20px" }}>
                          {item
                            .replace(/([A-Z])/g, " $1")
                            .charAt(0)
                            .toUpperCase() +
                            item.replace(/([A-Z])/g, " $1").slice(1)}
                        </div>
                      </td>
                      <td
                        style={{
                          border: "1px solid #f0f0f0",
                          borderCollapse: "collapse",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                          }}
                        >
                          {selectedData?.Currency}{" "}
                          {parseFloat(
                            selectedData?.ordersWithPaymentResponse
                              ?.paymentResponse[item]
                              ? selectedData?.ordersWithPaymentResponse
                                  ?.paymentResponse[item]
                              : 0
                          ).toFixed(2)}
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
            </>
          )}
          <div
            style={{
              borderTop: `1px solid #f0f0f0`,
              marginLeft: 20,
              marginRight: 20,
              height: "2px",
              bottom: 0,
              width: "90%",
              marginBottom: "50px",
              position: "absolute",
            }}
          ></div>
        </BillContainer>
      </div>

      <SuccessModal
        message="Successfully saved"
        visible={successModal}
        setVisible={setSuccessModal}
        okText="Done"
        onOk={() => {
          setSuccessModal(false);
        }}
      />
      <ConfirmationModal
        visible={confirmationModal}
        status={changedStatus}
        setVistible={setConfirmationModal}
        onConfirm={(insuranceStatus) => submitClaim(insuranceStatus)}
        loading={submitLoading}
      />
    </StyledContent>
  ) : (
    <Spinner />
  );
};

export default PatientHistory;
