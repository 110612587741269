import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Form, Button, message, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CloseCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import FormBuilder from '../../../utils/FormBuilder';
import {
  getHealthRecordForms,
  addHealthRecord,
  getSectionData,
} from '../../../../apis/healthRecord';
import {
  generateFormFields,
  deserialize,
} from '../../../utils/FormBuilder/valuesSerialisation';
import Spinner from '../../../utils/Spinner';
import ChiefComplaintModal from './ChiefComplaintModal';
import { fetchFormFieldMeta } from '../../../../apis/forms';
import { softValidate } from '../helper';
const ExaminationsContainer = styled.div`
  background: #fff;
  padding: 20px;
  padding-top: 40px;
`;

const ChiefComplaintsContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 6.5px 11px;
  min-height: 40px;
  padding-left: 30px;
`;

const StyledTag = styled(Tag)`
  background: #e9effd;
  margin-bottom: 8px;
  margin-top: 8px;

  padding: 4px 7px;
  font-size: 14px;
  & .ant-tag-close-icon {
    font-size: 17px;
    margin-left: 11px;
  }
  border: none;
`;

export const DiscardSubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const ChiefComplaints = ({ setVisibility, from }) => {
  const STRING_SEPERATOR = '#$%^&*&^%$';
  const { sectionId } = useParams();
  const [forms, setForms] = useState([]);
  const [formIds, setFormIds] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const user = useSelector((state) => state.userReducer);
  const [visible, setVisible] = useState(false);
  const [metaDataFieldIds, setMetaDataFieldIds] = useState([]);
  const [tagSelect, setTagSelect] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [formFetchLoading, setFormFetchLoading] = useState(false);
  const [selectedComplaints, setSelectedComplaintes] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [selectedComplaintsName, setSelectedComplaintsName] = useState([]);
  const [dropDownParentIds, setDropDownParentIds] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);
  const [validationStatus, setValidationStatus] = useState({});
  const [validationCriterias, setValidationCriterias] = useState([]);
  const [form] = Form.useForm();
  const [formfieldError, setformFieldError] = useState(false);
  const addComplaintClickHandler = () => {
    setVisible(true);
  };
  const ontagClose = (name) => {
    setSelectedComplaintes(
      selectedComplaints.filter((item) => item.name != name)
    );
  };

  const getPreviousData = () => {
    setDataLoading(true);
    getSectionData(
      section.sectionId,
      user.useType == 'PATIENT' ? user.id : patient.pId,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status == 200) {
          if (data.status == 1) {
            // if (from !== "Observations") setVisibility("Observations");
            let initialValues = {};
            let chiefComplaints = [];
            data.body.forEach((item) => {
              item.fields.forEach((item) => {
                if (tagSelect.field)
                  if (tagSelect.field.id == item.fieldId || item.fieldId == 0) {
                    chiefComplaints = item.value.split(STRING_SEPERATOR);
                  }
                initialValues[item.fieldId] = item.value;
              });
            });
            setFormInitialValues(initialValues);
            setSelectedComplaintsName(chiefComplaints);
          }
        }
      })
      .catch(console.err)
      .finally(() => {
        setDataLoading(false);
      });
  };

  const submitFormData = (dynamicFormData) => {
    setSubmitLoading(true);
    console.log('dynamic-----------', dynamicFormData);
    /*commenting cheif complaint field*/
    // if (dynamicFormData[0].fields[0].fieldValue === '') {
    //   // setformFieldError(true);
    //   setSubmitLoading(false);
    //   message.error('cheif complaint form field is required');
    // } else {
    // setformFieldError(false);
    addHealthRecord(
      sectionId,
      patient.pId,
      dynamicFormData,
      patient.appointmentId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            console.log(
              'data after submiting in chief complaint form is ',
              data
            );
            message.success('Health Record added');
            setVisibility('Observations');
          } else {
            message.error("couldn't add Vital Informations!");
          }
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const formSubmitHandler = (values) => {
    let chiefComplaints = {
      formFieldId: tagSelect.field?.id,
      fieldValue: selectedComplaints
        .map((item) => item.name)
        .join(STRING_SEPERATOR),
    };
    let data = Object.keys(formIds).map((key) => {
      const filtered = {};
      formIds[key].forEach((k) => {
        filtered[k] = values[k];
      });
      return {
        formId: key,
        fields:
          tagSelect.formId == key
            ? [chiefComplaints, ...deserialize(filtered)]
            : deserialize(filtered),
      };
    });

    console.log('data in submit is ----------', data);
    submitFormData(data);
  };

  const onClear = () => {
    // setFormInitialValues({});
    form.resetFields();
    setSelectedComplaintes([]);
    setFormFetchLoading(true);
    getForms();
  };
  useEffect(() => {
    if (Object.keys(formInitialValues).length === 0) form.resetFields();
  }, [formInitialValues]);

  // fetch formFields
  const getForms = useCallback(() => {
    var isData = false;
    console.log('sections is in useCallback', section.sectionId);
    getHealthRecordForms(section.sectionId, patient.pId)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            console.log('response is', data);

            var tempParentIds = [];
            var validationList = [];
            const tempFormIds = {};
            var tempMetaDataIds = [];
            isData = data.body[0].date ? true : false;
            (!isData ? data.body : data.body[0].dynamicHealthRecord).forEach(
              (item) => {
                //getting formIds and corresponding field IDs for submit request Generation
                tempFormIds[item.id] = (isData ? item.form.fields : item.fields)
                  .filter((field) => field.fieldType !== 'TAG_SELECT')
                  .map((field) => field.id);

                //finding fields which needs to call external API for fetching values eg:dropdown
                tempMetaDataIds = [
                  ...tempMetaDataIds,
                  ...item.fields.filter(
                    (field) =>
                      field.dropDownType !== null &&
                      field.dropDownType !== undefined &&
                      Object.keys(field.dropDownType).length !== 0
                  ),
                ];
                //collecting parentIds to call dropdown values api when change value of dependend dropdown, eg: fetching filtered medicine doses after selecting a medicine
                tempParentIds = [
                  ...tempParentIds,
                  ...item.fields.filter(
                    (field) =>
                      field.parentField !== null &&
                      field.parentField !== undefined
                  ),
                ];
                //COLLECTING FIELDS HAVING SOFT VALIDATORS
                validationList = [
                  ...validationList,
                  ...item.fields
                    .filter(
                      (field) =>
                        field.softValidator !== null &&
                        field.softValidator !== undefined &&
                        field.softValidator.length !== 0
                    )
                    .map((item) => {
                      return {
                        fieldId: item.id,
                        validationCrieteria: item.softValidator.map((val) => ({
                          type: val.validator,
                          value: val.value,
                        })),
                      };
                    }),
                ];
              }
            );

            //converting forms into formBuilder readable format
            let FormsTemp = (
              !isData ? data.body : data.body[0].dynamicHealthRecord
            ).map((item) => {
              let tagField = item.fields.find(
                (field) => field.fieldType === 'TAG_SELECT'
              );
              if (tagField)
                setTagSelect({
                  field: tagField,
                  formId: item.id,
                });
              return {
                formName: isData ? item.form.formName : item.formName,
                fields:
                  user.userType === 'PATIENT'
                    ? generateFormFields(
                        (isData ? item.form.fields : item.fields).filter(
                          (field) => field.fieldType !== 'TAG_SELECT'
                        )
                      ).map((item) => ({
                        ...item,
                        disabled: true,
                      }))
                    : generateFormFields(
                        (isData ? item.form.fields : item.fields).filter(
                          (field) => field.fieldType !== 'TAG_SELECT'
                        )
                      ),
              };
            });
            setFormIds(tempFormIds);
            console.log('temp', FormsTemp);
            setMetaDataFieldIds(tempMetaDataIds);
            setValidationCriterias(validationList);
            setDropDownParentIds(tempParentIds);
            setForms(FormsTemp);
            setFormFetchLoading(false);
          } else {
            message.error(data.body);
            setFormFetchLoading(false);
          }
        }
      })
      .catch((err) => {
        setFormFetchLoading(false);
        console.log(err);
        Sentry.captureException(err);
      });
  }, [sectionId]);

  useEffect(() => {
    setFormFetchLoading(true);
    getForms();
    getPreviousData();
  }, []);

  useEffect(() => {
    getPreviousData();
  }, [tagSelect]);

  const onValuesChange = (changed) => {
    Object.entries(changed).map(([key, value]) => {
      let changedField = dropDownParentIds.find(
        (item) => item.parentField == key
      );
      if (changedField) {
        let dropDownItem = dropDownValues.filter((item) => item.value == value);

        if (dropDownItem[dropDownItem.length - 1]) {
          changedField.sectionId = dropDownItem[dropDownItem.length - 1].id;

          setMetaDataFieldIds([changedField]);
        }
      }
    });
    Object.keys(changed).forEach((chngd) => {
      let validator = validationCriterias.find((item) => {
        return item.fieldId == chngd;
      });

      if (validator) {
        validationStatus[chngd] = softValidate(validator, changed[chngd]);
      }
    });

    setValidationStatus({ ...validationStatus });
  };

  useEffect(() => {
    if (forms.length && metaDataFieldIds.length) {
      let fieldMetaPromises = metaDataFieldIds.map((item) => {
        return fetchFormFieldMeta(
          item.dropDownType.dropDownType,

          item.sectionId
        );
      });
      let tempDropDownValues = [...dropDownValues];

      Promise.all(fieldMetaPromises)
        .then((responses) => {
          responses.forEach(({ data, status }, index) => {
            if (status == 200) {
              if (data.status == 1)
                tempDropDownValues = [...tempDropDownValues, ...data.body];
              //Injecting meta data for corresponding  formfields
              forms.forEach((form) => {
                let metaField = form.fields.find(
                  (field) => field.name === metaDataFieldIds[index].id
                );
                if (metaField) {
                  // setDropDownValues([...dropDownValues, ...data.body]);
                  metaField.meta = data.body.map((item) => item.value);
                } else console.log('Meta undefined');
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        })
        .finally(() => {
          setMetaDataFieldIds([]);
          setDropDownValues(tempDropDownValues);
          setForms([...forms]);
        });
    }
  }, [metaDataFieldIds, forms]);
  console.log('forms is', forms);

  return formFetchLoading || dataLoading ? (
    <Spinner />
  ) : (
    <ExaminationsContainer>
      <Row gutter={[24, 36]}>
        {forms.length > 0 && user.userType !== 'PATIENT' && (
          <Col span={24}>
            <Button
              size="large"
              type="primary"
              onClick={addComplaintClickHandler}
            >
              Add Chief Complaints
            </Button>
          </Col>
        )}
        <Col span={24}>
          <Form
            layout="vertical"
            onFinish={formSubmitHandler}
            initialValues={formInitialValues}
            key={Object.keys(formInitialValues).length}
            form={form}
            onValuesChange={onValuesChange}
            scrollToFirstError={true}
          >
            {forms.length > 0 && (
              <Form.Item label="Chief Complaints">
                <ChiefComplaintsContainer
                  className="custom-tag-container"
                  onClick={addComplaintClickHandler}
                >
                  {selectedComplaints.map((item) => (
                    <StyledTag
                      siz="large"
                      closable={user.userType !== 'PATIENT'}
                      closeIcon={<CloseCircleOutlined />}
                      key={item.id}
                      onClose={() => ontagClose(item.name)}
                    >
                      {item.name}
                    </StyledTag>
                  ))}
                </ChiefComplaintsContainer>
                {/* {formfieldError ? (
                  <p style={{ color: 'red', marginTop: '5px' }}>
                    Cheif complaint field is required
                  </p>
                ) : null} */}
              </Form.Item>
            )}
            {forms.map((subForm) => (
              <FormBuilder
                formConfig={subForm.fields}
                formInstance={form}
                softValidationFields={validationStatus}
              />
            ))}
            {user.userType !== 'PATIENT' && (
              <Form.Item>
                {forms.length !== 0 && (
                  <DiscardSubmitWrapper>
                    <Button
                      type="primary"
                      ghost
                      htmlType="submit"
                      className="btn-save"
                      onClick={onClear}
                    >
                      Discard
                    </Button>

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-save"
                      loading={submitLoading}
                    >
                      Submit
                    </Button>
                  </DiscardSubmitWrapper>
                )}
              </Form.Item>
            )}
          </Form>
        </Col>
      </Row>
      <ChiefComplaintModal
        setVisible={setVisible}
        visible={visible}
        selected={selectedComplaints}
        setSelected={setSelectedComplaintes}
        selectedComplaintsName={selectedComplaintsName}
        formInstance={form}
      />
    </ExaminationsContainer>
  );
};

export default ChiefComplaints;
