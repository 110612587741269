import React, { useState } from 'react';
import { Form, Input, Select, Button, message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { loginAPI } from '../../apis/Auth';
import FullWidthButton from '../utils/FullWidthButton';
import {
  StyledForm,
  StyledSelect,
  StyledInput,
  StyledPasswordInput,
  StyledFormItem,
} from './styledElements';
import { useDispatch, useSelector } from 'react-redux';
import { addUser } from '../../redux/actions/user';
import { getFrontUserRoles } from '../../apis/publicApis';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

const userTypes = ['Receptionist', 'Doctor', 'Nurse', 'Patient', 'Insurance'];

const LoginForm = ({ setForgotPasswordModalVisible }) => {
  const history = useHistory();
  const theme = useSelector((state) => state.themeReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const fetchRoles = () => {
    getFrontUserRoles()
      .then(({ data, status }) => {
        console.log('data is', data);
        if (status === 200) {
          if (data.status == 1) {
            setRoles(data.body);
          } else message.error('Unable to fetch User roles!');
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        message.error('Unable to fetch User roles!');
      });
  };

  const onFinish = (values) => {
    // values.userType = values.userType.replace("ROLE_", "");
    values.email = values.email.trim().toLowerCase();
    setLoading(true);

    loginAPI(values)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 1) {
            dispatch(addUser(res.data.body));
            localStorage.setItem('user', JSON.stringify(res.data.body));
            localStorage.setItem('token', res.data.body.token);

            history.push('/');
          } else {
            message.error(res.data.body);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        message.error('Invalid username or Password');
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {};
  const onForgotPassword = () => {
    setForgotPasswordModalVisible(true);
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <StyledForm
      name="login-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      size="large"
      wrapperCol={{ gutter: 36 }}
      validateTrigger={'onBlur'}
      scrollToFirstError={true}
    >
      {/* <Form.Item
        label="Login As"
        name="userType"
        rules={[
          {
            required: true,
            message: "Select a userTypes",
          },
        ]}
      >
        <StyledSelect
          bg={theme["@primary-color"]}
          placeholder="Select User type"
          suffixIcon={<CaretDownOutlined style={{ color: "white" }} />}
        >
          {roles.map((type) => (
            <Select.Option value={type.value}>{type.name}</Select.Option>
          ))}
        </StyledSelect>
      </Form.Item> */}

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Enter valid email !',
            type: 'email',
          },
        ]}
      >
        <StyledInput placeholder="abc@example.com" />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        style={{ marginBottom: '0px' }}
      >
        <StyledPasswordInput placeholder="*******" />
      </Form.Item>
      <Form.Item>
        <Button
          size="small"
          type="link"
          style={{
            color: 'white',
            float: 'right',
            marginTop: '10px',
            fontSize: '16px',
            padding: '0',
          }}
          onClick={onForgotPassword}
        >
          Forgot Password ?
        </Button>
      </Form.Item>
      <StyledFormItem>
        <FullWidthButton
          htmlType="submit"
          loading={loading}
          className="submit-btn"
        >
          Login
        </FullWidthButton>
      </StyledFormItem>
    </StyledForm>
  );
};
export default LoginForm;
