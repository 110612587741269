import React from "react";
import { Row, Col } from "antd";
import Icon, { DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/DeleteIcon.svg";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";

export const columns = (
  setSelectedItem,
  setDeleteModal,
  deleteModal,
  edit,
  setVisible,
  handleEdit
) => [
  { width: "1vw" },
  {
    title: "Sl. No",
    render: (text, record, index) => {
      return index + 1;
    },
  },
  { title: "Facility Name", dataIndex: "facilityName" },
  { title: "Application Name", dataIndex: "application" },
  { title: "IP Address", dataIndex: "ipAddress" },
  { title: "Port", dataIndex: "port" },
  { title: "Receiver  Namespace ID", dataIndex: "receiverNameSpaceId" },
  { title: "Receiver Universal ID", dataIndex: "receiverUniversalId" },
  {
    title: "Actions",
    // width: "150px",
    render: (record) => (
      <Row gutter={12}>
        <Col>
          {" "}
          <Icon
            style={{ fontSize: "20px", cursor: "pointer" }}
            component={EditIcon}
            onClick={() => {
              setSelectedItem(record);
              handleEdit(true);
            }}
          />
        </Col>
        <Col>
          <Icon
            style={{ fontSize: "22px", cursor: "pointer" }}
            component={DeleteIcon}
            onClick={() => {
              setSelectedItem(record);
              setDeleteModal(!deleteModal);
            }}
          />
        </Col>
      </Row>
    ),
  },
  { width: "1vw" },
];
