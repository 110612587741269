import React, { useState } from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button, Input, Form, message, Checkbox, Radio } from "antd";
import styled from "styled-components";
import { UserAddOutlined } from "@ant-design/icons";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";
import { addRole } from "../../../../apis/Admin.js";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

const ModalBodyContainer = styled.div`
  width: 500px;
  padding: 20px;
`;

const StyledRow = styled(Row)`
  margin-top: 20px;
`;

const ModalTitle = styled.div`
  font-size: 25px;
`;

const CreateRoleModal = ({ visible, setVistible, trigger, setTrigger }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [showDepartmentCheckBox, setShowDipartmentCheckbox] = useState(true);

  useEffect(() => {}, [trigger]);

  const addNewUserRole = (values) => {
    setLoading(true);
    addRole(values)
      .then((resp) => {
        if (resp.data.status === 1) {
          setTrigger(!trigger);
          message.success("Successfully created new User Role");
          setLoading(false);
          form.resetFields();
          setVistible(false);
        } else {
          message.error("Could not create User Role, Try Again");
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error("Could not create User Role, Try Again");
        console.log(err);
        Sentry.captureException(err);
        setLoading(false);
      });
    setVistible(false);
  };
  const onFormValueChange = (changed) => {
    setShowDipartmentCheckbox(!changed.backend);
  };

  useEffect(() => {}, [showDepartmentCheckBox]);

  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="left">
          <Col span={24}>
            <ModalTitle>
              <UserAddOutlined />
              Create User Role
            </ModalTitle>
          </Col>
        </Row>
        <Form
          layout="vertical"
          onFinish={(values) => addNewUserRole(values)}
          form={form}
          initialValues={{ relatedToDepartment: false, backend: false }}
          onValuesChange={onFormValueChange}
          scrollToFirstError={true}
        >
          <StyledRow justify="center">
            <Form.Item
              label="User Role"
              name="name"
              rules={[{ required: true, message: "Role name cannot be empty" }]}
              style={{ width: "100%" }}
            >
              <Col span={24}>
                <Input size="large" placeholder="Enter role name" />
              </Col>
            </Form.Item>
            <Form.Item
              label="Backoffice user ?"
              name="backend"
              style={{ width: "100%" }}
            >
              {/* <Col span={24}> */}
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
              {/* </Col> */}
            </Form.Item>
            {showDepartmentCheckBox && (
              <Form.Item
                // label="User Role"
                name="relatedToDepartment"
                style={{ width: "100%" }}
                valuePropName={"checked"}
              >
                {/* <Col span={24}> */}
                <Checkbox>Is Department related ?</Checkbox>
                {/* </Col> */}
              </Form.Item>
            )}
          </StyledRow>
          <Form.Item>
            <StyledRow gutter={12} justify="end">
              <Col>
                <Button
                  onClick={() => {
                    setVistible(false);
                  }}
                  danger
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button type="primary" loading={loading} htmlType="submit">
                  Save
                </Button>
              </Col>
            </StyledRow>
          </Form.Item>
        </Form>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default CreateRoleModal;
