import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button, Form, Input, Select } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ModalTitle, StyledRow } from "./styledComponents";
import { CaretDownOutlined } from "@ant-design/icons";

import { useEffect } from "react";
import { routeOfAdministration } from "../../../Datasets/routeOfAdministration";
import { dosageForms } from "../../../Datasets/dosageForms";
import { drugTypes } from "../../../Datasets/drugTypes";
const ModalBodyContainer = styled.div`
  width: 500px;
`;

const DrugDetailsModal = ({
  visible,
  setVisible,
  selectedItem,
  setSelectedItem,
  edit,
  submitHandler,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  const [form] = Form.useForm();
  const capitalize = (camelCaseText) => {
    return (
      camelCaseText.charAt(0).toUpperCase() +
      camelCaseText
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(" ")
    );
  };
  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        medicineName: selectedItem.text,
        code: selectedItem.code,
        drugType: selectedItem.drugInfo?.drugType,
        dosageForm: selectedItem.drugInfo?.dosageForm,
        routeOfAdministration: selectedItem.drugInfo?.routeOfAdministration,
        strength: selectedItem.drugInfo?.strength,
        volume: selectedItem.drugInfo.volume,
        description: selectedItem.description,
        brandName: selectedItem.drugInfo?.brandName,
        purchasePrice: selectedItem.drugInfo?.purchasePrice,
        sellingPrice: selectedItem.drugInfo?.sellingPrice,
      });
    }
  }, [selectedItem]);
  const onSubmit = (values) => {
    const data = {
      code: values.code,

      description: values.description,
      drugInfo: {
        dosageForm: values.dosageForm,
        drugType: values.drugType,
        id: selectedItem ? selectedItem.id : undefined,
        routeOfAdministration: values.routeOfAdministration,
        strength: values.strength,
        volume: values.volume,
        brandName: values.brandName,
        purchasePrice: values.purchasePrice,
        sellingPrice: values.sellingPrice,
      },
      text: values.medicineName,
    };
    console.log("entered into submit fn ");
    submitHandler(data);
    form.resetFields();
  };
  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <ModalTitle color={theme["@primary-color"]}>
              {edit ? "Update Drug" : "Add New Drug"}
            </ModalTitle>
          </Col>
        </Row>
        <Form
          layout="vertical"
          onFinish={(values) => onSubmit(values)}
          form={form}
          style={{ width: "100%" }}
          scrollToFirstError={true}
        >
          <StyledRow justify="center" gutter={["24"]}>
            <Row justify="center">
              <Col span={24}>
                <Form.Item
                  label="Code"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Code cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input size="large" placeholder="Enter Code" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Drug Type"
                  name="drugType"
                  rules={[
                    {
                      required: true,
                      message: "Drug Type cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Select
                    size="large"
                    suffixIcon={<CaretDownOutlined showSearch />}
                    placeholder={"Enter Drug Type"}
                    showSearch
                  >
                    {drugTypes().map((item) => (
                      <Select.Option
                        value={item}
                        key={item}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Medicine Name"
                  name="medicineName"
                  rules={[
                    {
                      required: true,
                      message: "Medicine Name cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input size="large" placeholder="Enter Medicine Name" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Brand Name"
                  name="brandName"
                  rules={[
                    {
                      required: true,
                      message: "Brand Name cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input size="large" placeholder="Enter Brand Name" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Dosage Form"
                  name="dosageForm"
                  rules={[
                    {
                      required: true,
                      message: "Dosage cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Select
                    size="large"
                    suffixIcon={<CaretDownOutlined showSearch />}
                    placeholder={"Enter Dosage Form"}
                    showSearch
                  >
                    {dosageForms().map((item) => (
                      <Select.Option
                        value={item}
                        key={item}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Route Of Administration"
                  name="routeOfAdministration"
                  rules={[
                    {
                      required: true,
                      message: "Route Of Administration cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  {/* <Input
                    size="large"
                    placeholder="Enter Rote Of Administration"
                  /> */}

                  <Select
                    size="large"
                    suffixIcon={<CaretDownOutlined showSearch />}
                    placeholder={"Enter Route Of Administration"}
                    showSearch
                  >
                    {routeOfAdministration().map((item) => (
                      <Select.Option value={item.code} key={item.text}>
                        {item.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Description cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input size="large" placeholder="Enter Description" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Strength"
                  name="strength"
                  rules={[
                    {
                      required: true,
                      message: "Strength cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    size="large"
                    placeholder="Enter Strength"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Volume"
                  name="volume"
                  rules={[
                    {
                      required: true,
                      message: "Volume cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    size="large"
                    placeholder="Enter Volume"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Purchase Price"
                  name="purchasePrice"
                  rules={[
                    {
                      required: true,
                      message: "Purchase Price cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    size="large"
                    placeholder="Enter Purchase Price"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Selling Price"
                  name="sellingPrice"
                  rules={[
                    {
                      required: true,
                      message: "Selling Price cannot be empty",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    size="large"
                    placeholder="Enter Selling Price"
                  />
                </Form.Item>
              </Col>
            </Row>
          </StyledRow>
          <StyledRow gutter={12} justify="end">
            <Col>
              <Button
                onClick={() => {
                  setVisible(false);
                  setSelectedItem(null);
                  form.resetFields();
                }}
                danger
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {}}
                htmlType="submit"
                // disabled={selectedFile ? false : true}
                // loading={loading}
              >
                {edit ? "Save" : "Add"}
              </Button>
            </Col>
          </StyledRow>
        </Form>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default DrugDetailsModal;
