import { Form, Select, Input, Row, Button, Col } from "antd";
import styled from "styled-components";
export const StyledForm = styled(Form)`
  text-align: left;
  &.ant-form-vertical .ant-form-item-label > label {
    height: auto;
    color: white;
  }
  & .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }
`;

export const StyledSelect = styled(Select)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${(props) => props.bg};
    border-color: white;
    color: white;
    text-align: left;
    border-radius: 4px;
    font-size: 13px;
    /* padding-bottom: 15px; */
    padding-top: 5px;
    height: 50px;
    padding-left: 25px;
  }
`;

export const StyledInput = styled(Input)`
  border: none;
  border-radius: 5px;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
`;
export const StyledPasswordInput = styled(Input.Password)`
  border: none;
  border-radius: 4px;
  font-size: 13px;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 25px;
`;

export const FulHeightROw = styled(Row)`
  height: 100vh;
  /* overflow: hidden; */
`;
export const LoginFormContainer = styled.div`
  background-color: ${(props) => props.bg};
  height: calc(100vh - 140px);
  color: white;
  text-align: center;
  padding: 20px 60px;
  display: flex;
  align-items: center;
  &.form_ui_component{
    height:100%;
    padding: 0;
    flex-wrap: wrap;
  }
  &.form_ui_component .form_inner_data{
    padding:30px 35px;
  }
  &.form_ui_component form{
    padding:0px;
  }
  &.form_ui_component .ant-form-item-label label{
    font-size:16px;
  } 
  &.form_ui_component .ant-form-item-control-input-content .ant-input,
  &.form_ui_component .ant-form-item-control-input-content span.ant-input-password{
    background-color:#f0f4f7;
  }
  &.form_ui_component .ant-form-item-control-input-content span.ant-input-password{
    padding:0px 0px;
    border:none;
  }
  &.form_ui_component .ant-form-item-control-input-content .ant-input,
  &.form_ui_component .ant-form-item-control-input-content .ant-select-selection-search-input{
    height:50px !important;
    border-radius:4px;
    border:1px solid #FFFFFF;
    padding:0px 15px;
    font-size:16px;
    color:#333333;
  }
  &.form_ui_component .ant-form-item-control-input-content .ant-select-selection-search{
    font-size:16px;
    padding:0px 5px !important;
  }
  &.form_ui_component .ant-form-item-control-input-content .ant-select-selector{
    padding-left:15px !important;
  }
  &.form_ui_component .ant-select-selection-placeholder{
    opacity:1;
    font-size:16px;
  }
  &.form_ui_component .ant-select-selection-item{
    font-size:16px;
  }
  &.form_ui_component ::-webkit-input-placeholder { /* Edge */
    color: #333333;
    opacity:0.2;
  }
  
  &.form_ui_component :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #333333;
    opacity:0.2;
  }
  
  &.form_ui_component ::placeholder {
    color: #333333;
    opacity:0.2;
  }
  &.form_ui_component .ant-input + .ant-input-suffix{
    position:absolute;
    right:0px;
    top:1px;
    padding:17px 13px;
  }
  &.form_ui_component .ant-input + .ant-input-suffix svg{
    fill:#5c5c5c;
  }
  &.form_ui_component button.submit-btn{
    height: 50px;
    font-weight: 600;
    letter-spacing: 0.020em;
    background-color:#0f70bc !important;
    border:1px solid transparent;
    width:100%;
    font-size:18px;
  }
  &.form_ui_component .submit-btn-reg{
    padding: 7px 18px;
    height: auto;
    font-size: 17px;
    font-weight: 500;
    border: 1px solid #fff !important;
    color:#fff !important;
    -webkit-transition:all 0.2s linear;
    -moz-transition:all 0.2s linear;
    -ms-transition:all 0.2s linear;
    transition:all 0.2s linear;
  }
  &.form_ui_component .submit-btn-reg:hover{
    background-color:#fff !important;
    color:#185180 !important;
  }
  @media (max-width:991px){
    &.form_ui_component .form_inner_data {
      padding: 30px 15px;
      max-width: 450px;
      margin: 0 auto;
    }
    &.form_ui_component .contact_box{
      width:100%;
    }
  }
`;

export const RightContainer = styled.div`
  height: 100vh;
  &.main_login_form{
    height:100%;
  }
`;

export const StyledContactButton = styled(Button)`
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 7px;
  border-radius: 10px;
`;

export const StyledPTag = styled.p`
  font-size: ${(props) => props.size || "10px"};
  margin-bottom: 0;
`;

export const ImageContainer = styled.div`
  height: 100%;
  background-image: url(${(props) => props.imageURL});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ContactUsContainer = styled(Row)`
  background: #fff;
  padding: 10px 60px;
  text-align: center;
  height: 140px;
  flex-direction: column;
  justify-content: space-evenly;
  &.contact_box{
    align-self: flex-end;
    height:auto;
    padding:30px;
  }
  &.contact_box button{
    border-radius:5px;
  }
  &.contact_box p{
    font-size: 13px;
    line-height: 1.6;
    color:#5a5b5c;
    padding-top:15px;
  }
`;

export const StyledCol = styled(Col)`
  padding: 0 !important;
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;