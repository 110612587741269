import axiosInstance from "../config/axiosInstance";

const BED_PREFIX = "/bed";

export const addNewBed = (d) => {
  return axiosInstance.post(`${BED_PREFIX}/addBed`, d);
};

export const editBed = (id, d) => {
  return axiosInstance.put(`${BED_PREFIX}/editBed/${id}`, d);
};

export const deleteBed = (wardid, bedid) => {
  return axiosInstance.delete(`${BED_PREFIX}/deleteBeds/${wardid}/${bedid}`);
};
