import React, { useState, useEffect } from "react";
import { Row, Col, message, Typography, Button, Input, Form } from "antd";
import { SectionHeading, SectionHeadContainer } from "../styledComponents";
import {
  StyledTitle,
  StyledTextArea,
  StyledInput,
  BodyContainer,
} from "./styledComponents";
import { useDispatch, useSelector } from "react-redux";
import { applyTheme } from "../../../../redux/actions/theme";
import ThemeSelector from "./ThemeSelector";
import ImageUpload from "./ImageUpload";
import {
  getSettings,
  uploadImage,
  saveSettings,
} from "../../../../apis/settings";
import { addImages } from "../../../../redux/actions/appImages";

const GeneralSettings = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("");
  const theme = useSelector((state) => state.themeReducer);
  const [logoBlob, setLogoBlob] = useState();
  const [coverBlob, setCoverBlob] = useState();
  const [settings, setSettings] = useState({});
  const dispatch = useDispatch();
  const changeColor = (colorCode) => {
    dispatch(applyTheme({ "@primary-color": colorCode }));
  };

  const changeappImage = (data) => {
    dispatch(addImages(data));
  };
  const onSaveHandler = (values) => {
    changeColor(primaryColor);
    const payload = settings;
    payload.contactUsEmail = values.contactUsEmail;
    payload.contactUsMessage = values.contactUsMessage;
    payload.theme = primaryColor;
    payload.currency = values.currency;
    payload.vat = values.vat;
    payload.followUpDaysForCash = values.cashFollowUp;
    payload.reportWaitingExpiryDays = values.reportWaitingExpiry;
    payload.reportWaitingExpiryDaysLaboratory =
      values.reportWaitingExpiryLaboratory;
    payload.reportWaitingExpiryDaysRadiology =
      values.reportWaitingExpiryRadiology;
    saveSettingsHandler(payload);
  };
  const fetchPreviousSettings = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const hospitalId = userData.hospitalId;
    getSettings(null, hospitalId)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            setSettings(data.body);
            setPrimaryColor(data.body.theme);
            changeColor(data.body.theme);
            let values = data.body;
            form.setFieldsValue({
              contactUsEmail: values.contactUsEmail,
              contactUsMessage: values.contactUsMessage,
              currency: values.currency,
              vat: values.vat,
              cashFollowUp: values.followUpDaysForCash,
              reportWaitingExpiry: values.reportWaitingExpiryDays,
              reportWaitingExpiryLaboratory:
                values.reportWaitingExpiryDaysLaboratory,
              reportWaitingExpiryRadiology:
                values.reportWaitingExpiryDaysRadiology,
            });
            changeappImage({
              logo: data.body.hospitalLogo,
              cover: data.body.coverPhoto,
            });
          }
        }
        setDiscardLoading(false);
      })
      .catch(() => {
        setDiscardLoading(false);
      });
  };
  const saveSettingsHandler = async (settings) => {
    setLoading(true);
    if (logoBlob) {
      let { data, status } = await uploadImage(logoBlob);
      if (status == 200)
        if (data.status === 1) {
          settings.hospitalLogo = data.body;
        } else message.error(data.body);
    }
    if (coverBlob) {
      let { data, status } = await uploadImage(coverBlob);
      if (status == 200)
        if (data.status === 1) {
          settings.coverPhoto = data.body;
        } else message.error(data.body);
    }
    settings.theme = primaryColor;

    saveSettings(settings)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            message.success("Settings Saved");
            fetchPreviousSettings();
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCancel = () => {
    setDiscardLoading(true);
    fetchPreviousSettings();
  };

  useEffect(() => {
    setPrimaryColor(theme["@primary-color"]);
  }, [theme]);

  useEffect(() => {
    fetchPreviousSettings();
  }, []);
  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <SectionHeading level={3}>General Settings</SectionHeading>
            </Col>
            <Col></Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <Row gutter={[12, 24]}>
            <Col span={24}>
              <StyledTitle color={theme["@primary-color"]} level={4}>
                Change Theme
              </StyledTitle>
            </Col>
            <Col span={24}>
              <ThemeSelector
                primaryColor={primaryColor}
                setPrimaryColor={setPrimaryColor}
              />
            </Col>
            <Col span={24}>
              <StyledTitle color={theme["@primary-color"]} level={4}>
                Change Logo
              </StyledTitle>
            </Col>
            <Col span={24}>
              <ImageUpload
                innerNode={<div style={{ opacity: 0.5 }}>Hospital Logo</div>}
                imageBlob={logoBlob}
                setImageBlob={setLogoBlob}
                // resolution={{ height: 1, width: 3 }}
                url={settings.hospitalLogo}
              />
            </Col>
            <Col span={24}>
              <StyledTitle color={theme["@primary-color"]} level={4}>
                Change Cover Photo
              </StyledTitle>
            </Col>
            <Col span={24}>
              <ImageUpload
                imageBlob={coverBlob}
                setImageBlob={setCoverBlob}
                // resolution={{ height: 994, width: 1427 }}
                url={settings.coverPhoto}
              />
            </Col>
            <Form
              layout="vertical"
              onFinish={(values) => onSaveHandler(values)}
              form={form}
              style={{ width: "100%" }}
            >
              <Col span={24}>
                <StyledTitle color={theme["@primary-color"]} level={4}>
                  Contact Us Email
                </StyledTitle>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="contactUsEmail"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please enter the E-mail!",
                    },
                  ]}
                >
                  <StyledInput placeholder={"Contact Us Email"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <StyledTitle color={theme["@primary-color"]} level={4}>
                  Contact Us Message
                </StyledTitle>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="contactUsMessage"
                  label="Message"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Message!",
                    },
                  ]}
                >
                  <StyledTextArea placeholder={"Contact Us Message"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <StyledTitle color={theme["@primary-color"]} level={4}>
                  Currency and VAT
                </StyledTitle>
              </Col>
              <Row span={24} justify="space-between">
                <Col span={11}>
                  <Form.Item
                    name="currency"
                    label="Currency Type"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the Currency Type!",
                      },
                    ]}
                  >
                    <StyledInput placeholder={"Currency Type"} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="vat"
                    label="VAT(%)"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the VAT!",
                      },
                      {
                        pattern: new RegExp(/^\d*\.?\d+$/),
                        message: "Invalid Input!",
                      },
                    ]}
                  >
                    <StyledInput placeholder={"VAT"} />
                  </Form.Item>
                </Col>
              </Row>
              <Col span={24}>
                <StyledTitle color={theme["@primary-color"]} level={4}>
                  Hospital Visit Type
                </StyledTitle>
              </Col>
              <Row span={24} justify="space-between">
                <Col span={11}>
                  <Form.Item
                    name="cashFollowUp"
                    label="For Cash Free Follow-up Day(s):"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the Cash Free Follow-up Days!",
                      },
                      {
                        pattern: new RegExp(/^\d+$/),
                        message: "Invalid Input!",
                      },
                    ]}
                  >
                    <StyledInput placeholder={"Cash Free Follow-up Days"} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="reportWaitingExpiry"
                    label="For Report Waiting Expiry Day(s):"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the Report Waiting Expiry Days!",
                      },
                      {
                        pattern: new RegExp(/^\d+$/),
                        message: "Invalid Input!",
                      },
                    ]}
                  >
                    <StyledInput placeholder={"Report Waiting Expiry Days"} />
                  </Form.Item>
                </Col>
              </Row>
              <Row span={24} justify="space-between">
                <Col span={11}>
                  <Col span={24} style={{ padding: "12px 6px" }}>
                    <StyledTitle color={theme["@primary-color"]} level={4}>
                      Laboratory Visit Type
                    </StyledTitle>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="reportWaitingExpiryLaboratory"
                      label="For Report Waiting Expiry Day(s):"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please enter the Report Waiting Expiry Days!",
                        },
                        {
                          pattern: new RegExp(/^\d+$/),
                          message: "Invalid Input!",
                        },
                      ]}
                    >
                      <StyledInput placeholder={"Report Waiting Expiry Days"} />
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={11}>
                  <Col span={24} style={{ padding: "12px 6px" }}>
                    <StyledTitle color={theme["@primary-color"]} level={4}>
                      Radiology Visit Type
                    </StyledTitle>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="reportWaitingExpiryRadiology"
                      label="For Report Waiting Expiry Day(s):"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please enter the Report Waiting Expiry Days!",
                        },
                        {
                          pattern: new RegExp(/^\d+$/),
                          message: "Invalid Input!",
                        },
                      ]}
                    >
                      <StyledInput placeholder={"Report Waiting Expiry Days"} />
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
              <Col span={24}>
                <Row justify="end" gutter={12}>
                  <Col>
                    <Button
                      type="primary"
                      ghost
                      loading={discardLoading}
                      onClick={onCancel}
                    >
                      Discard
                    </Button>
                  </Col>
                  <Col>
                    <Button type="primary" loading={loading} htmlType="submit">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Row>
        </BodyContainer>
      </Col>
    </Row>
  );
};

export default GeneralSettings;
