import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import styled from "styled-components";
import { Row, Col, Input, Button, Typography, message, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { addNewBed, editBed } from "../../../../apis/beds.js";
import { getWards } from "../../../../apis/wards";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getHospitalId } from "../../../utils/LocalStorage/index.js";
const ModalBodyContainer = styled.div`
  width: 450px;
  padding: 10px 30px;
`;
const { Title } = Typography;

const StyledTitle = styled(Title)`
  &.ant-typography,
  &.ant-typography h3 {
    margin-bottom: 0;
    color: ${(props) => props.color};
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    text-align: center;
  }
`;

const NewDocumentModal = ({
  visible,
  setVisible,
  refetch,
  selectedItem,
  setSelectedItem,
}) => {
  const [bedName, setBedName] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.themeReducer);
  const [wards, setWards] = useState([]);
  const [selectedWards, setSelectedWards] = useState([]);


  const addBed = () => {
    setLoading(true);
    var data = {
      "bedNo": bedName,
      "ward": { "id": selectedWards },
      "hospital": { "id": getHospitalId() },
      "status": "active",
      "isVacant": 1
    };
    if (selectedItem) {
      editBed(selectedItem.id, data)
        .then(({ data, status }) => {
          if (status === 200) {
            message.success("Edited");
            refetch();
            setBedName("");
            setSelectedItem(undefined);
            setVisible(false);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      addNewBed(data)
        .then(({ data, status }) => {
          if (status === 200) {
            // if (data.status == 1) {
            //   message.success("Created");
            //   refetch();
            //   setBedName("");
            //   setSelectedItem(undefined);
            //   setVisible(false);
            // } else {
            //   message.error("Could n't create bed");
            // }
            message.success("Created");
            refetch();
            setBedName("");
            setSelectedItem(undefined);
            setVisible(false);
            //   setSelectedItem(undefined);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }

  };

  const wardChange = (Id) => {
    setSelectedWards(Id)
  }

  useEffect(() => {
    if (selectedItem) setBedName(selectedItem.bedNo);
    if (selectedItem) setSelectedWards(selectedItem.ward.id);

    let isMounted = true;

    if (isMounted) {
      getWard();
    }

    // Cleanup function to set the flag to false when the component is unmounted
    return () => {
      isMounted = false;
    };

  }, [selectedItem]);

  const getWard = (Id) => {
    getWards()
    .then((response) => {
      if (response.data) setWards(response.data);
    })
    .catch((err) => {
      message.error('Could not fetch Wards Category List, Try Again');
    });
  };

  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBodyContainer>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            {" "}
            <StyledTitle level={3} color={theme["@primary-color"]}>
              {!selectedItem ? "Create New Bed" : "Update Bed"}
            </StyledTitle>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 6]}>
              <Col span={24}>Select Ward</Col>
              <Col span={24}>
                <Select
                  size="large"
                  placeholder="Select Ward"
                  value={selectedWards}
                  style={{ width: "100%" }}  // Set the width to 100%
                  onChange={(value) => wardChange(value)}
                >
                  {wards &&
                    wards.map((ward) => {
                      return (
                        <Option value={ward.id} key={ward.id}>
                          {ward.wardNo}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 6]}>
              <Col span={24}>Bed Name</Col>
              <Col span={24}>
                <Input
                  value={bedName}
                  size="large"
                  onChange={(event) => {
                    setBedName(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={12} justify="end">
              <Col>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  onClick={() => {
                    setBedName("");
                    setSelectedItem(undefined);
                    setVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => {
                    addBed();
                  }}
                >
                  {!selectedItem ? "Create" : "Update"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};

export default NewDocumentModal;
