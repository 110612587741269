import { Button, Col, DatePicker, InputNumber } from 'antd';

import Icon from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export const AcceptDeclineButton = styled.div`
  & .ant-btn-background-ghost.ant-btn-primary {
    color: ${(props) => props.color} !important;
    border-color: white !important;
    box-shadow: none;
  }
`;

export const AppoinmentFormContainer = styled.div`
  background-color: #fff;
  margin: 40px 0px;
  padding: 30px;
  border-radius: 10px;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledP = styled.p`
  color: red;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
`;

export const StyledPayment = styled.p`
  color: gray;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
`;

export const TimeDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 6.5px 11px;
`;

export const TimeP = styled.p`
  color: #6d6d6d;
  margin-bottom: 0;
  font-size: 16px;
`;

export const BookBtnDiv = styled.div`
  display: flex;
  justify-content: flex-end;
 
 
  align-items:flex-end;
  min-height:100px;
 
  @media (max-width: 600px) {
    margin: 0 auto,
  padding: 40px;
    display: flex;
    justify-content: center;
    min-height:50px;
  }
`;

export const StyledH3 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;
export const BookButton = styled(Button)`
  margin-left: 10px;
  float: 'right';
  margin-bottom: '10px';
`;

export const CollapseButton = styled(Button)`
  align-items: center;
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
`;
export const StyledIcon = styled(Icon)`
  width: 100%;
  font-size: 50px;
  margin: 10px 0;
  & .cls-1 {
    fill: rgba(0, 0, 0, 0.15);
  }
`;

export const StyledButton = styled(Button)`
  height: fit-content;
  margin-right: 10px;
  text-align: center;
  border: 1px solid ${(props) => props.color} !important;
  border-radius: 5px !important;
  font-size: small;
`;
export const ImmediateDiv = styled.div`
  width: 100%;
  background-color: rgba(255, 170, 0, 0.4);
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  & .cls-warning-1 {
    fill: rgba(255, 170, 0, 1);
  }
`;

export const StyledNoActive = styled.p`
  text-align: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.25);
`;

export const UserProfileContainer = styled(Col)`
  .user-profile-head-mobile {
    display: none;
  }
  @media (max-width: 767px) {
    .user-profile-head-mobile {
      display: flex;
      flex-direction: column;
    }
    .user-profile-head-desktop {
      display: none;
    }
  }
`;
export const CancelSaveBookButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: end;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
