import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from './redux';
import MyFallbackComponent from './MyFallback';

import ErrorBoundary from './ErrorBoundary';

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://9e88e82e2bff4df68101db01ab85b440@o1114409.ingest.sentry.io/6146963',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
if (
  process.env.NODE_ENV !== 'development' &&
  process.env.NODE_ENV !== 'local'
) {
  window['console']['log'] = function () {};
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
