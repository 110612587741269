import { Input, Typography } from "antd";
import React from "react";
import styled from "styled-components";
const { Title } = Typography;

export const StyledTitle = styled(Title)`
  && {
    color: ${(props) => props.color};
  }
`;
export const StyledInput = styled(Input)`
  border-radius: 5px;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
`;

export const BodyContainer = styled.div`
  background: #fff;
  padding: 30px 40px;
  min-height: calc(100vh - 200px);
  margin-bottom: 20px;
  border-radius: 10px;
`;

export const StyledTextArea = styled(Input.TextArea)`
  && {
    min-height: 100px;
    resize: none;
  }
`;

export const ThemeContainer = styled.div`
  /* width: 400px; */
  padding: 20px;

  /* width: max-content; */
`;

export const ColorBox = styled.span`
  height: 35px;
  width: 35px;
  margin: 3px;
  margin-bottom: 0px;
  background: ${(props) => props.primaryColor};
  display: inline-block;
  /* position: relative; */
  ${(props) => (props.isActive ? `border: 2px solid black;` : ``)}
`;
export const Colorcontainer = styled.div`
  width: 295px;
  background: ${(props) => props.primaryColor};
  border-radius: 5px;
  border: 1px solid #c7c7c7d9;
  /* display: inline-flex; */
  padding: 3px;
`;
export const ColorNameContainer = styled.div`
  width: 295px;
  background: ${(props) => props.primaryColor};
  border-radius: 5px;
  border: 1px solid #c7c7c7d9;
  /* display: inline-flex; */
  padding: 8px 23px;
  margin-bottom: 20px;
`;

export const SelectedColorBox = styled.div`
  height: 50px;
  width: 50px;
  background: ${(props) => props.primaryColor};
`;

export const UploaderPlaceHolder = styled.div`
  width: 200px;
  /* padding: 20px; */
  border-width: 2px;
  border-color: #cacaca;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
`;
