import React, { useState, useEffect } from 'react';
import { ServiceSelectDays } from './ServiceSelectDays';
import ServiceSelectedDays from './ServiceSelectedDays';

export const ServiceCreateForm = ({
  togglePanel,
  setAvailability,
  initialValues,
  setDisabled,
  availability,
}) => {
  const [selectedDays, setSelectedDays] = useState(
    initialValues.length > 0 ? [...initialValues] : [...ServiceSelectDays]
  ); //incase of array

  // const [selectedDays, setSelectedDays] = useState([...ServiceSelectDays]); //incase of array
  // const [selectedDays, setSelectedDays] = useState({ ...ServiceSelectDays }); //incase of object

  const [selectedDaysError, setSelectedDaysError] = useState('');

  return (
    <ServiceSelectedDays
      selectedDays={selectedDays}
      setSelectedDays={setSelectedDays}
      selectedDaysError={selectedDaysError}
      togglePanel={togglePanel}
      setAvailability={setAvailability}
      setDisabled={setDisabled}
      availability={availability}
    />
  );
};
