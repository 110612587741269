import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import styled from "styled-components";
import {
  Row,
  Col,
  Input,
  Button,
  Typography,
  message,
  Form,
  InputNumber,
} from "antd";
import { addNewDepartment } from "../../../../apis/departments.js";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { formatTimeStr } from "antd/lib/statistic/utils";
import { FooterButton } from "../styledComponents.js";

const { Title } = Typography;
const StyledForm = styled(Form)`
  width: 100%;
`;
const StyledTitle = styled(Title)`
  padding-bottom: 10px;
  &.ant-typography,
  &.ant-typography h3 {
    margin-bottom: 0;
    color: ${(props) => props.color};
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    text-align: center;
  }
`;

const NewRadiologyTypeModal = ({
  visible,
  setVisible,
  addType,
  update,
  refetch,
  edit,
  setEdit,
  selectedItem,
  setSelectedItem,
  head,
}) => {
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.themeReducer);
  const [form] = Form.useForm();

  const ModalBodyContainer = styled.div`
    width: ${head == "Test" ? "40vw" : "30vw"};
    padding: 10px 10px 0 10px;
  `;

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({ type: selectedItem.name, fee: selectedItem.fee });
      // setType(selectedItem.name);
    } else {
      form.setFieldsValue({ type: "" });
    }
  }, [edit]);
  const handleSubmit = (values) => {
    if (edit) {
      update(values);
    } else {
      addType(values);
    }
    setEdit(false);
    form.resetFields();
    //  setType("");
  };
  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBodyContainer fee={head == "Test" ? true : false}>
        <Row>
          <Col span={24}>
            {" "}
            <StyledTitle level={3} color={theme["@primary-color"]}>
              {edit ? `Edit ${head}` : `Create New ${head}`}
            </StyledTitle>
          </Col>
          <StyledForm
            layout="vertical"
            onFinish={(values) => handleSubmit(values)}
            form={form}
            scrollToFirstError={true}
          >
            <Row>
              {head != "Test" && <Col span={1}></Col>}
              <Col span={head == "Test" ? 11 : 20}>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: "Type cannot be empty" }]}
                  style={{ width: "100%" }}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              {head == "Test" && (
                <Col span={11}>
                  <Form.Item
                    label="Fee"
                    name="fee"
                    rules={[{ required: true, message: "Fee cannot be empty" }]}
                    style={{ width: "100%" }}
                  >
                    <InputNumber size="large" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Form.Item>
              <Col span={24}>
                <Row gutter={12} justify="end">
                  <Col>
                    <FooterButton
                      type="primary"
                      size="large"
                      ghost
                      onClick={() => {
                        setEdit(false);
                        setSelectedItem({});
                        form.resetFields();
                        setVisible(false);
                      }}
                    >
                      Cancel
                    </FooterButton>
                  </Col>
                  <Col>
                    <FooterButton
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={loading}
                    >
                      {edit ? "Update" : " Create"}
                    </FooterButton>
                  </Col>
                </Row>
              </Col>
            </Form.Item>
          </StyledForm>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};

export default NewRadiologyTypeModal;
