export const ADD_PATIENT = "ADD_PATIENT";
export const CLEAR_PATIENT = "CLEAR_PATIENT";
export const SET_ACTIVE_COUNT = "SET_ACTIVE_COUNT";
export const addPatient = (data) => {
  return {
    type: ADD_PATIENT,
    payload: data,
  };
};
export const clearPatient = () => {
  return {
    type: CLEAR_PATIENT,
  };
};
// export const setActiveCount = (data) => {
//   return {
//     type: SET_ACTIVE_COUNT,
//     payload: data,
//   };
// };
