import axiosInstance from "../config/axiosInstance";
const SETTINGS_PRFIX = "/settings";

export const uploadImage = async (fileBlob) => {
  const formData = new FormData();
  formData.append("imageFile", fileBlob, fileBlob.name);
  return axiosInstance.post(SETTINGS_PRFIX + `/upload-image`, formData, {
    "content-type": "multipart/form-data",
  });
};

export const saveSettings = (data) => {
  return axiosInstance.post(SETTINGS_PRFIX, data);
};

export const getSettings = (subdomain, hospitalId) => {
  let url = SETTINGS_PRFIX;
  if (hospitalId !== null && hospitalId !== undefined) {
    url = `${url}?hospital=${hospitalId}`;
  } else if (subdomain !== null && subdomain !== undefined) {
    url = `${url}?subdomain=${subdomain}`;
  }
  return axiosInstance.get(url);
};
