import React, { useState, useEffect } from 'react';
import {
  SectionHeadContainer,
  SectionHeading,
  StyledDiv,
} from '../styledComponents';
import { BodyContainer, StyledBodyTitle } from './styledComponents';
import SectionManagementTable from './SectionManageMentTable';
import { formColumns, fieldsColumn } from './tableConfig';
import { ReactComponent as EditIcon } from '../../../../assets/icons/Edit.svg';

import { Row, Col, Button, message, notification } from 'antd';
import Icon, { LeftCircleOutlined } from '@ant-design/icons';
import NewFormModal from './NewFormModal';
import NewFieldModal from './NewFieldModal';
import * as Sentry from '@sentry/react';
import {
  changeSectionOrder,
  fetchSubSectionFields,
} from '../../../../apis/healthRecord';
import ConfirmationModal from './ConfirmationModal';
import ErrorModal from './ErrorModal';
import DeleteErrorModal from './DeleteErrorModal';
import { useSelector } from 'react-redux';
import PreviewModal from './PreviewModal';
import { NewButton } from './FormManagement';
const FieldManamgement = ({ setTab, selectedForm, selectedSection }) => {
  const [loading, setLoading] = useState(false);
  const [newFormModal, setNewFormModal] = useState(false);
  const [newFieldModal, setNewFieldModal] = useState(false);
  const [fields, selectedFields] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [deleteErrorModal, setDeleteErrorModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [total, setTotals] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [changesOrderStatus, setChangeOrderStatus] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);

  const handleOrderChangeClick = () => {
    if (changesOrderStatus) {
      let newOrder = fields.map((item, index) => ({
        formId: item.id,
        formOrder: index + 1,
      }));
      changeSectionOrder({ formOrderChanges: newOrder })
        .then(({ data, status }) => {
          if (data.status == 1) {
            notification.success({
              message: 'New Order saved!',
            });
            setChangeOrderStatus(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    } else {
      setChangeOrderStatus(true);
    }
  };
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);

  const newFormClickHandler = () => {
    setNewFormModal(true);
  };
  const newFieldClickHandler = () => {
    setNewFieldModal(true);
  };
  const getFormFields = () => {
    setLoading(true);
    fetchSubSectionFields(selectedForm.id)
      .then(({ data, status }) => {
        console.log('fetch form ', data);

        if (status === 200) {
          if (data.status === 1) {
            selectedFields(data.body);
            setTotals(data.body.length);
          } else message.error(data.body);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!newFieldModal && !errorModal && !confirmationModal) {
      setSelectedItem(undefined);
    }
  }, [newFieldModal, errorModal, confirmationModal]);
  useEffect(() => {
    getFormFields();
  }, []);

  useEffect(() => {}, [selectedItem]);

  return (
    <Row>
      <Col span={24}>
        <SectionHeadContainer>
          <Row>
            <Col span={24}>
              <SectionHeading>
                <span style={{ color: 'gray' }}>
                  Sections
                  <LeftCircleOutlined
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                  />
                  Forms
                </span>
                <LeftCircleOutlined
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                  onClick={() => {
                    setTab('forms');
                  }}
                />
                Manage Fields
              </SectionHeading>
            </Col>
          </Row>
        </SectionHeadContainer>
      </Col>
      <Col span={24}>
        <BodyContainer>
          <Row gutter={[0, 18]}>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <StyledBodyTitle level={4} color={theme['@primary-color']}>
                    {selectedForm.formName}
                    {selectedForm.editable !== false && (
                      <Icon
                        style={{ fontSize: '20px', marginLeft: '20px' }}
                        component={EditIcon}
                        onClick={newFormClickHandler}
                      />
                    )}
                  </StyledBodyTitle>
                </Col>
                <Col>
                  <Row gutter={12} justify="end">
                    <Col>
                      <NewButton
                        type={'primary'}
                        ghost
                        onClick={() => {
                          setPreviewModal(true);
                        }}
                        size="large"
                      >
                        Preview
                      </NewButton>
                    </Col>
                    <Col>
                      <NewButton
                        type="primary"
                        ghost
                        size="large"
                        onClick={newFieldClickHandler}
                      >
                        Create New Field
                      </NewButton>
                    </Col>{' '}
                    <Col>
                      <NewButton
                        type={'primary'}
                        ghost={!changesOrderStatus}
                        onClick={handleOrderChangeClick}
                        size="large"
                      >
                        {changesOrderStatus ? 'Save Order' : 'Change Order'}
                      </NewButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <StyledDiv bgcolor={theme['@primary-color']}>
                <div>&nbsp;&nbsp;Fields</div>
                <div>&nbsp;&nbsp;</div>
              </StyledDiv>
              <SectionManagementTable
                dataSource={fields}
                columns={fieldsColumn(
                  setSelectedItem,
                  setConfirmationModal,
                  setNewFieldModal,
                  setErrorModal,
                  setPreviewModal
                )}
                loading={loading}
                draggable={changesOrderStatus}
                setDataSource={selectedFields}
              />
            </Col>
          </Row>
        </BodyContainer>
      </Col>
      <NewFormModal
        visible={newFormModal}
        setVisible={setNewFormModal}
        selectedSection={selectedSection}
        selectedForm={selectedForm}
      />
      <NewFieldModal
        visible={newFieldModal}
        setVisible={setNewFieldModal}
        selectedForm={selectedForm}
        setSelectedItem={setSelectedItem}
        refetch={() => getFormFields()}
        selectedItem={selectedItem}
        allFields={fields}
        total={total}
      />
      <ConfirmationModal
        visible={confirmationModal}
        setVisible={setConfirmationModal}
        selectedItem={selectedItem}
        refetch={() => getFormFields()}
        setDeleteErrorModal={setDeleteErrorModal}
        setErrorMessage={setErrorMessage}
        setSelectedItem={setSelectedItem}
      />
      <ErrorModal visible={errorModal} setVisible={setErrorModal} />
      <DeleteErrorModal
        visible={deleteErrorModal}
        setVisible={setDeleteErrorModal}
        message={errorMessage}
      />
      <PreviewModal
        visible={previewModal}
        setVisible={setPreviewModal}
        formName={selectedForm.formName}
        fields={fields}
      />
    </Row>
  );
};

export default FieldManamgement;
