import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  getHealthRecordForms,
  getPreviousRecords,
  addHealthRecord,
} from "../../../../apis/healthRecord";
import { Row, Col, Button, message, Form, Popconfirm } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as Avatar } from "../../../../assets/icons/image-upload-avatar.svg";
import TableContainer from "../TableContainer";
import {
  generateFormFields,
  deserialize,
} from "../../../utils/FormBuilder/valuesSerialisation";
import FormBuilder from "../../../utils/FormBuilder";
import { fetchFormFieldMeta } from "../../../../apis/forms";
import {
  generatePreSubmitFormFields,
  generatePreviousInfoTableColumns,
  migrateIntoTableFormat,
} from "../helper";
import Spinner from "../../../utils/Spinner";
import AppointmentForm from "./AppointmentForm";
import { PROFILE_IMAGE_PREFIX } from "../../../../constants/StringConstants";

// import ViewDetailsModal from "./ViewDetailsModal";

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
`;

export const RightButton = styled(Button)`
  float: right;
  margin-left: 10px;
`;
export const ImageContainer = styled.div`
  width: 90px;
  height: 90px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
const StyledIcon = styled(Icon)``;

const PatientDetails = () => {
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const [loading, setLoading] = useState();
  const [profilePic, setProfilePic] = useState();

  const [visible, setVisible] = useState(false);

  const submitFormData = () => {};
  const theme = useSelector((state) => state.themeReducer);

  useEffect(() => {
    // setFormFetchLoading(true);
  }, []);
  const ProfileDetailsContainer = styled.div`
    width: 200px;
  `;
  const formatUserName = (pName) => {
    return pName
      .split("-")
      .map((item) => {
        return item.charAt(0).toUpperCase() + item.slice(1);
      })
      .join(" ");
  };

  return !loading ? (
    <Row gutter={[24, 24]}>
      {/* <Col span={24}>
        <Row justify="center">
          <Col>
            <ProfileDetailsContainer>
              <Row justify="center">
                <Col span={24}>
                  <ImageContainer
                    bgImage={patient.profilePic && PROFILE_IMAGE_PREFIX + patient.profilePic}
                    color={theme["@primary-color"]}
                  >
                    {!patient.profilePic && (
                      <StyledIcon
                        component={Avatar}
                        style={{ fontSize: "70px", margin: "auto" }}
                      />
                    )}
                  </ImageContainer>
                </Col>
                <Col>
                  <h2 style={{ textAlign: "center" }}>
                    {formatUserName(patient.pName)}
                  </h2>
                  <h2 style={{ textAlign: "center" }}>{patient.pId}</h2>
                </Col>
              </Row>
            </ProfileDetailsContainer>
          </Col>
        </Row>
      </Col> */}
      <Col span={24}>
        <FormContainer>
          <AppointmentForm appointmentId={patient.appointmentId} />
        </FormContainer>
      </Col>
    </Row>
  ) : (
    <Spinner />
  );
};
export default PatientDetails;
