import CustomModal from "../../utils/CustomModal.jsx/index";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Upload, Row, Col, Form, Menu, Button, message, Typography, Dropdown, Select, Tooltip, Table } from "antd";
import { useHistory } from "react-router-dom";

const BodyContainer = styled.div`
  text-align: left;
  min-width: 40vw;
  margin: 0px 25px;
  padding: 10px;

  @media (max-width: 991px) {
    && {
      width: 90%;
      margin: auto;
    }
  }
`;

const StyledSelect = styled(Select)`
  background-color: "#fff";
  width: 100%;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  /* font-weight: 700; */
  height: max-content;
  border-radius: 5px;
  margin-left: 15px;
`;

const StyledB = styled.b`
  width: 100px;
  font-size: 30px;
  `;



const StyledH2 = styled.h2`
    margin: 0 0 30px 0;
    font-weight: bold;
  /* color: black; */
  `;

const PreviewOCRFiles = ({
    visible,
    setVisible,
    data
}) => {
    const [form] = Form.useForm();
    const theme = useSelector((state) => state.themeReducer);
    const [columns, setColumns] = useState([])
    const history = useHistory();

    const capitalize = (camelCaseText) => {
        camelCaseText = camelCaseText.replace("id_", "ID ")
            .split(" ")
            .map((word) =>
                word = word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        return camelCaseText
    };

    useEffect(() => {
        if (data) {
            var col = Object.keys(data)
            setColumns(col)
        }
    }, [data])

    return (
        <CustomModal
            visible={visible}
            antdMOdalProps={{ centered: true }}
            isClosable={true}
            setVisible={setVisible}
        >
            <BodyContainer className="popup-box-content">
                <Row>
                    <Col span={100}>
                        <StyledH2 style={{ color: "black" }}>Patient Data</StyledH2>
                    </Col>
                </Row>
                {columns?.map((item) =>
                    data[item] !== "" && typeof (data[item]) == "string" &&
                    <Row justify="space-around">
                        <Col style={{ textTransform: "capitalize" }} span={10}>{item}</Col>&nbsp;&nbsp;:&nbsp;&nbsp;<Col span={10}>{data[item]}</Col>
                    </Row>
                )}
            </BodyContainer>
            <Row style={{ justifyContent: "flex-end", marginTop: "15px", padding: "10px" }}>
                <Col>
                    <Button
                        type="primary"
                        ghost
                        size="large"
                        className="btn-save"
                        style={{ margin: "0 0 15px 15px", width: "150px" }}
                        onClick={() => { setVisible(false) }}
                    >
                        Cancel Upload
                    </Button>
                </Col>
                <Col>
                    <Button
                        htmlType="submit"
                        size="large"
                        type="primary"
                        style={{ margin: "0 0 15px 15px", width: "150px" }}
                        className="btn-save"
                        onClick={() => history.push({ pathname: '/new-patient', state: { data: data } })}
                    // disabled={submit}
                    >
                        Proceed
                    </Button>
                </Col>
            </Row>
        </CustomModal >
    );
};

export default PreviewOCRFiles;