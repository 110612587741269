import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Form, message, Row, Col, Select, DatePicker, Input, Table, Tooltip } from 'antd';
import { Option } from "antd/lib/mentions";
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/Successful-icon.svg';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/CancelIcon.svg';
import { useHistory } from 'react-router-dom';
import { MailOutlined, PrinterOutlined } from '@ant-design/icons';
import { getHospitalId } from '../../../utils/LocalStorage/index';
import { getAllDoctors, getWards, getBeds, getWardsCategory, getNurses } from '../../../../apis/Appointment';
import { bookOT, getOTWards, fetchAllOTByWardId } from '../../../../apis/healthRecordIPD';
import moment from 'moment';

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

const ModalContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledCancelIcon = styled(MailOutlined)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledP = styled.p`
  font-size: 15px;
`;

const StyledPTitle = styled.p`
  font-size: 28px;
  font-family: bold;
  color: #56aef8;
`;

const StyledRow = styled(Row)`
  padding: 24px;
`;

const BlueBgTable = styled(Table)`
  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    /* position: relative; */
    font-size: 14px !important;
    padding: 8px !important;
    /* overflow-wrap: break-word; */
    margin: 0px;
  }
  & .ant-table-thead > tr > th {
    /* padding: 8px 0 !important; */
    background: white;
    font-weight: 600;
  }
  & .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:last-child {
    border-right: 1px solid #f0f0f0;
  }
  & .ant-table-thead > tr > th:first-child {
    border-left: 1px solid #f0f0f0;
  }

  /* & .ant-table-tbody > tr > td {
    border-bottom: 1px solid #9e9292;
  } */
  & .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #f0f0f0;
    font-size: 12px !important;
  }
  & .ant-table-tbody > tr > td.total {
    font-size: 18px !important;
  }
  & .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #f0f0f0;
  }
`;

const BookOTModel = ({
  visible,
  closeModal,
  phoneNumber,
  firstName,
  patientId,
}) => {
  const history = useHistory();

  const [departments, setDepartments] = useState([]);
  const [wards, setWards] = useState([]);
  const [OTWards, setOTWards] = useState([]);
  const [beds, setBeds] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [admitPatient, setAdmitPatient] = useState([]);
  // const theme = useSelector((state) => state.themeReducer);
  const [doctorsLoading, setDoctorsLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [remarks, setRemarks] = useState('');

  const [form] = Form.useForm();
  useEffect(() => {
    getAllDoctors(1)
      .then((response) => {
        if (response.data.status === 1) {
          setDoctorsLoading(false);
          if (response.data.body) setDoctors(response.data.body);
        } else {
          setDoctorsLoading(false);
          message.error('Could not fetch Doctors List, Try Again');
        }
      })
      .catch((err) => {
        setDoctorsLoading(false);
        console.log(err);
        message.error('Could not fetch Doctors List, Try Again');
      });

    getOTWards(1)
      .then((response) => {
        // console.error(response.data);
        if (response.data) setWards(response.data);
      })
      .catch((err) => {
        console.log(err);
        message.error('Could not fetch Wards List, Try Again');
      });

    getNurses()
      .then((response) => {
        console.error(response.data.body);
        if (response.data) setNurses(response.data.body);
      })
      .catch((err) => {
        console.log(err);
        message.error('Could not fetch Nurses List, Try Again');
      });
  }, []);

  const onSubmit = (values) => {
    // console.log('Form values:', values);
    // const data = {
    //   "admissionId": 5,
    //   "doctorIds": values.doctor,
    //   "nurseIds": values.nurse
    // };

    const data = {
      "admissionId": 1,
      "startTime": startTime,
      "endTime": endTime,
      "remarks": remarks,
      "wardId": values.ward,
      "doctorIds": values.doctor,
      "nurseIds": values.nurse,
      "timeZone": "Asia/Kolkata"
    }
    // const data = {
    //   "userId": 1001,
    //   "patientId": patientId,
    //   "bedId": values.bed,
    //   "reason": "Mild cough and cold, Pain in the eyes",
    //   "doctorIds": values.doctor,
    //   "nurseIds": values.nurse
    // };
    bookOT(data)
      .then((response) => {
        if (response.data.status === 1) {
          message.success("Success");
        } else {
          message.error(response.data.body);
        }
        closeModal(false);

      })
      .catch((err) => {
      });
  };

  const wardChange = (wardID) => {
    fetchAllOTByWardId(wardID)
      .then((response) => {
        setOTWards(response.data.body)
      })
      .catch((err) => {
        console.log(err);
        message.error('Could not fetch Wards List, Try Again');
      });

  };

  const eventData = {
    "body": [
      {
        "id": 1,
        "startDateTime": "2023-12-07T15:00:00",
        "endDateTime": "2023-12-07T17:00:00",
        "status": true,
        "remarks": "Event 1 remarks"
      },
      {
        "id": 2,
        "startDateTime": "2023-12-07T20:00:00",
        "endDateTime": "2023-12-07T22:00:00",
        "status": true,
        "remarks": "Event 2 remarks"
      }
    ],
    "status": 1
  };

  const previousDataColumns = [
    { width: "1vw" },
    {
      title: "S. No", render: (value, item, index) => {
        return index + 1;
      }
    },
    { title: "Start Date", dataIndex: "startDateTime" },
    { title: "End date", dataIndex: "endDateTime" }
  ];

  // Function to format timestamp
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
    return `${formattedDate} ${formattedTime}`;
  };



  const appendCloseButton = (data, columns) => {
    columns[columns.length - 1].render = (value, record, index) => {
      return (
        <Row justify="space-between" gutter={36}>
          <Col>{value}</Col>
          {!OTWards ? (
            <Col>
              <Tooltip title="Print">
                <PrinterOutlined
                  style={{
                    fontSize: "20px",
                    // color: theme["@primary-color"],
                  }}
                />
              </Tooltip>
            </Col>
          ) : (
            <></>
          )}
          <Col style={{ padding: "5px" }}></Col>
        </Row>
      );
    };
    return columns;
  };

  return (
    <StyledModal
      visible={visible}
      closable={false}
      centered
      maskClosable={true}
      footer={null}
    >
      <ModalContentDiv>
        {/* <MailOutlined style={{ width: '60px' }} /> */}
        <StyledPTitle>Book OT</StyledPTitle>
        <Form
          layout="vertical"
          onFinish={(values) => onSubmit(values)}
          form={form}
          style={{ width: '100%' }}
          scrollToFirstError={true}
        >
          <Form.Item
            name="startTime"
            label="Start Time"
            rules={[
              {
                required: true,
                message: 'Please select start time',
              },
            ]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              // onChange={(value) => setStartTime(value)}
              onChange={(value) => {
                const formattedValue = value ? moment(value).format("YYYY-MM-DDTHH:mm:ss") : null;
                setStartTime(formattedValue);
              }}
            />
          </Form.Item>

          <Form.Item
            name="endTime"
            label="End Time"
            rules={[
              {
                required: true,
                message: 'Please select end time',
              },
            ]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(value) => {
                const formattedValue = value ? moment(value).format("YYYY-MM-DDTHH:mm:ss") : null;
                setEndTime(formattedValue);
              }}
            />
          </Form.Item>

          <Form.Item name="remarks" label="Remarks">
            <Input.TextArea
              rows={4}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="doctor"
            label="Doctor"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              size="large"
              loading={doctorsLoading}
              placeholder="Select Doctor"
              onSelect={(value) => {
              }}
              mode="multiple"
            >
              {doctors &&
                doctors.map((doctor) => {
                  return (
                    <Option value={doctor.id}>
                      {doctor.firstName + ' ' + doctor.lastName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item name="nurse" label="Assign Nurse">
            <Select
              size="large"
              placeholder="Select Nurse"
              onSelect={(value) => {
              }}
              mode="multiple">
              {nurses &&
                nurses.map((nurse) => {
                  return (
                    <Option value={nurse.id}>
                      {nurse.firstName + ' ' + nurse.lastName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Ward"
            name="ward"
            rules={[
              { required: true, message: "Ward cannot be empty" },
            ]}
            style={{ width: "100%" }}
          >
            <Select
              size="large"
              placeholder="Select Ward"
              onChange={(value) => wardChange(value)}
            >
              {wards &&
                wards.map((category) => {
                  return (
                    <Option value={category.id} key={category.id}>
                      {category.wardNo}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <div>
            <h2>OT Slot Booking</h2>
            {OTWards ? (<BlueBgTable
              pagination={false}
              columns={
                previousDataColumns.length
                  ? appendCloseButton(OTWards, previousDataColumns)
                  : []
              }
              dataSource={OTWards.map(item => ({
                ...item,
                startDateTime: formatTimestamp(item.startDateTime),
                endDateTime: formatTimestamp(item.endDateTime),
                // Add more properties if needed
              }))}
              tableTitle={"Billing History"}
            />
            ) : (<Col></Col>)}
          </div>

          <Button justify="center" key="ok" type="primary" htmlType="submit">
            Book
          </Button>
          <>
          </>
        </Form>
      </ModalContentDiv>
    </StyledModal>
  );
};

export default BookOTModel;
