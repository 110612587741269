import React from "react";
import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/DeleteIcon.svg";
import moment from "moment";
export const tableColumns = (
  setEditModal,
  setDeleteModal,
  setselectedItem,
  pageNo,
  settings
) => [
  { width: "1vw" },
  {
    title: "Sl. No",
    render: (value, item, index) => {
      return pageNo * 15 + (index + 1);
    },
  },
  {
    title: "Code",
    dataIndex: "code",
  },
  {
    title: "Drug Type",
    dataIndex: ["drugInfo", "drugType"],
  },
  {
    title: "Medicine Name",
    dataIndex: "text",
    width: "25vw",
  },
  {
    title: "Brand Name",
    dataIndex: ["drugInfo", "brandName"],
  },
  {
    title: "Dosage Form",
    dataIndex: ["drugInfo", "dosageForm"],
  },
  {
    title: "Route of Administration",
    dataIndex: ["drugInfo", "routeOfAdministration"],
  },
  {
    title: "Descriptions",
    dataIndex: "description",
    width: "25vw",
  },
  {
    title: "Strength",
    dataIndex: ["drugInfo", "strength"],
  },
  {
    title: "Volume",
    dataIndex: ["drugInfo", "volume"],
  },
  {
    title: "Created Date",
    dataIndex: "createdDate",
    render: (record) => (
      <Row>
        <Col>{moment(record).format("DD-MM-YYYY")}</Col>
      </Row>
    ),
  },
  {
    title: "Purchase Price",
    dataIndex: ["drugInfo", "purchasePrice"],
    render: (record) => settings?.currency + " " + record,
  },
  {
    title: "Selling Price",
    dataIndex: ["drugInfo", "sellingPrice"],
    render: (record) => settings?.currency + " " + record,
  },
  {
    title: "Actions",
    // width: "150px",
    render: (record) => (
      <Row gutter={12}>
        <Col>
          {" "}
          <Icon
            style={{ fontSize: "20px" }}
            component={EditIcon}
            onClick={() => {
              setselectedItem(record);
              setEditModal(true);
            }}
          />
        </Col>
        <Col>
          <Icon
            style={{ fontSize: "22px" }}
            component={DeleteIcon}
            onClick={() => {
              setselectedItem(record);
              setDeleteModal(true);
            }}
          />
        </Col>
      </Row>
    ),
  },
  { width: "1vw" },
];
