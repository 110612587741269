import React, { useEffect } from "react";
import Layout from "./Layout";
import { Route, Redirect, useHistory, Switch } from "react-router-dom";
import PatientsList from "./PatientsList";
import Profile from "./Profile";
import PatientForm from "./PatientForm";
import PatientHistory from "./PatientForm/PatientHistory";
// import LaboratoryIPDDetails from "/";
import HealthReacords from "./HealthRecords";
import Appointments from "./Appoinment";
import ResetPassword from "./PatientForm/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../redux/actions/user";
import AdminDashboard from "./AdminDashboard";
import Spinner from "../utils/Spinner";
import LaboratoryDetails from "./LaboratoryDashboard/LaboratoryDetails";
import LaboratoryDetailsIPD from "./LaboratoryDashboard/LaboratoryDetailsIPD";
import PharmacyDetails from "./PharmacyDashboard/PharmacyDetails";
import PharmacyDetailsIPD from "./PharmacyDashboard/PharmacyDetailsIPD";
import NotFoundPage from "../utils/NotFound/Notfound";
import DrawingCanvas from "../DrawingCanvas";
import PreviousConsultationReports from "./HealthRecords/ConsultationHistory/PreviousConsultationReports";
import PreviousConsultationRecords from "./HealthRecords/ConsultationHistory/PreviousConsultationRecords";
import SMSList from "../SMSList";
import OrganisationDashboard from "./OrganisationDashboard";
import HealthReacordsIPD from "./HealthRecordsIPD";


const Content = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userState = useSelector((state) => state.userReducer);
  useEffect(() => {
    console.log("hello world");
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (JSON.parse(user) && token && Object.entries(JSON.parse(user)).length) {
      dispatch(addUser(JSON.parse(user)));
    } else {
      localStorage.clear();
      history.push("/login");
    }
  }, []);
  useEffect(() => {
    console.log("userState is", userState);
  }, [userState]);

  return userState.userType !== undefined ? (
    <Layout>
      <Switch>
        <Route path="/dashboard" component={PatientsList} />
        <Route path="/draw-canvas" component={DrawingCanvas} />
        <Route exact path="/user-profile" component={Profile} />
        <Route path="/new-patient" component={PatientForm} />
        <Route path="/new-patient/:data" component={PatientForm} />
        <Route path="/update-patient/:pId/:pName" component={PatientForm} />
        <Route path="/patient-history/:pId/:pName" component={PatientHistory} />
        <Route exact path="/sms-List" component={SMSList} />

        <Route
          path="/health-records/:pId/:pName/:appointmentId"
          component={HealthReacords}
        />
        <Route
          path="/health-records-ipd/:pId/:pName/:appointmentId"
          component={HealthReacordsIPD}
        />
        <Route
          path="/health-records/:pId/:pName"
          component={PreviousConsultationReports}
        />
        <Route
          path="/consultion-report/:pId/:pName"
          component={PreviousConsultationRecords}
        />
        {userState.userType == "LABORATORY" ||
        userState.userType == "PATIENT" ? (
          <Route
            path="/laboratory-records/:pId/:pName/:appointmentId"
            component={LaboratoryDetails}
          />
        ) : (
          <Route
            path="/laboratory-records/:pId/:pName/:appointmentId"
            render={() =>
              userState.userType !== "LABORATORY" && <Redirect to="/" />
            }
          />
        )}
        {userState.userType == "LABORATORY" ||
        userState.userType == "PATIENT" ? (
          <Route
            path="/laboratory-records-ipd/:pId/:pName/:appointmentId"
            component={LaboratoryDetailsIPD}
          />
        ) : (
          <Route
            path="/laboratory-records-ipd/:pId/:pName/:appointmentId"
            render={() =>
              userState.userType !== "LABORATORY" && <Redirect to="/" />
            }
          />
        )}
        {userState.userType == "RADIOLOGY" ||
        userState.userType == "PATIENT" ? (
          <Route
            path="/radiology-records/:pId/:pName/:appointmentId"
            // render={() =>
            //   userState.userType !== "LABORATORY" && <Redirect to="/" />
            // }
            component={LaboratoryDetails}
          />
        ) : (
          <Route
            path="/radiology-records/:pId/:pName/:appointmentId"
            render={() =>
              userState.userType !== "RADIOLOGY" && <Redirect to="/" />
            }
          />
        )}
        {userState.userType == "RADIOLOGY" ||
        userState.userType == "PATIENT" ? (
          <Route
            path="/radiology-records-ipd/:pId/:pName/:appointmentId"
            // render={() =>
            //   userState.userType !== "LABORATORY" && <Redirect to="/" />
            // }
            component={LaboratoryDetailsIPD}
          />
        ) : (
          <Route
            path="/radiology-records-ipd/:pId/:pName/:appointmentId"
            render={() =>
              userState.userType !== "RADIOLOGY" && <Redirect to="/" />
            }
          />
        )}
        {userState.userType == "PHARMACY" ? (
          <Route
            path="/pharmacy-records/:pId/:pName/:appointmentId/:pharmacyRequestId"
            component={PharmacyDetails}
          />
        ) : (
          <Route
            path="/pharmacy-records/:pId/:pName/:appointmentId/:pharmacyRequestId"
            render={() =>
              userState.userType !== "PHARMACY" && <Redirect to="/" />
            }
          />
        )}
        {userState.userType == "PHARMACY" ? (
          <Route
            path="/pharmacy-records-ipd/:pId/:pName/:ipd/:pharmacyRequestId"
            component={PharmacyDetailsIPD}
          />
        ) : (
          <Route
            path="/pharmacy-records-ipd/:pId/:pName/:ipd/:pharmacyRequestId"
            render={() =>
              userState.userType !== "PHARMACY" && <Redirect to="/" />
            }
          />
        )}
        <Route
          exact
          path="/patient/reset-password/:pId"
          component={ResetPassword}
        />
        <Route
          exact
          path="/user-profile/reset-password/:pId"
          component={ResetPassword}
        />
        <Route
          exact
          path="/appointment/:patientId/:patientName"
          component={Appointments}
        />
        <Route
          exact
          path="/appointment/:patientId/:appointmentId/:patientName"
          component={Appointments}
        />
        <Route
          path="/org-dashboard"
          // render={() =>
          //   userState.userType == 'ORG_ADMIN' ? (
          //     <OrganisationDashboard />
          //   ) : (
          //     <Redirect to="/admin-login" />
          //   )
          // }
          component={OrganisationDashboard}
        />
        <Route path="/admin-dashboard" component={AdminDashboard} />
        <Route
          exact
          path="/"
          render={() =>
            userState.backend ? (
              <Redirect to="/admin-dashboard" />
            ) : (
              <Redirect to="/dashboard" />
            )
          }
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  ) : (
    <Spinner height={"100vh"} />
  );
};
export default Content;
