import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Button, Form, message, Row, Col, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/Successful-icon.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/CancelIcon.svg";
import { useHistory } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { getHospitalId } from "../../utils/LocalStorage/index";
import { useSelector } from "react-redux";

import {
  getDepartments,
  getDoctors,
  getWards,
  getBeds,
  getAllBedsInHospital,
  getWardsCategory,
  getNurses,
} from "../../../apis/Appointment";
import {
  updateAdmission,
  getLastIPDJourney,
} from "../../../apis/healthRecordIPD";

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

const ModalContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledCancelIcon = styled(MailOutlined)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledP = styled.p`
  font-size: 15px;
`;

const StyledPTitle = styled.p`
  font-size: 28px;
  font-family: bold;
  color: #56aef8;
`;

const StyledRow = styled(Row)`
  padding: 24px;
`;

const AdmitPatientModal = ({
  visible,
  closeModal,
  phoneNumber,
  firstName,
  patientId,
  pId,
}) => {
  const history = useHistory();

  const [departments, setDepartments] = useState([]);
  const [mainDate, setMainDate] = useState([]);
  const [wardsCategory, setWardsCategory] = useState([]);
  const [beds, setBeds] = useState([]);
  const patient = useSelector((state) => state.patientReducer);
  const [nurses, setNurses] = useState([]);
  const [admitPatient, setAdmitPatient] = useState([]);
  const [doctorsLoading, setDoctorsLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [selectedNurseIds, setSelectedNurseIds] = useState([]);
  const [selectedDoctorIds, setselectedDoctorIds] = useState([]);
  const [selectedBedId, setselectedBedId] = useState([]);
  const [selectedWardCategoryId, setselectedWardCategoryId] = useState([]);
  const [selectedDepartmentId, setselectedDepartmentId] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (departments == null || departments.length <= 0) {
      getDepartments(getHospitalId())
        .then((response) => {
          if (response.data.status === 1) {
            setDepartments(response.data.body);
            setMainDate("done");
            getLastIPDJourney(pId)
              .then((response) => {


                try {
                  // Case 1
                  setselectedDepartmentId(response.data.body.savedBy.departments[0].id);
                } catch (error) {
                  console.error("Error in Case 1:", error);
                  // Handle the error for Case 1
                }
                
                try {
                  // Case 2
                  const selectedDoctorsIds = response.data.body.assignedDoctors.map((doctor) => doctor.id);
                  fetchDoctors(response.data.body.savedBy.departments[0].id, selectedDoctorsIds);
                } catch (error) {
                  console.error("Error in Case 2:", error);
                  // Handle the error for Case 2
                }
                
                try {
                  // Case 3
                  const selectedNursesIds = response.data.body.assignedNurses.map((nurse) => nurse.id);
                  fetchNurses(selectedNursesIds);
                } catch (error) {
                  console.error("Error in Case 3:", error);
                  // Handle the error for Case 3
                }
                
                try {
                  // Case 4
                  fetchWards(response.data.body.assignedBed.ward.wardCategory.id, response.data.body.assignedBed.id);
                } catch (error) {
                  console.error("Error in Case 4:", error);
                  // Handle the error for Case 4
                }
                
                try {
                  // Case 5
                  if (wardsCategory && wardsCategory.length > 0) {
                    const selectedwardsCategoryId = response.data.body.assignedBed.ward.wardCategory.id;
                    setselectedWardCategoryId(selectedwardsCategoryId);
                    alert(selectedwardsCategoryId);
                  } else {
                    console.error("wardsCategory data is not available.");
                  }
                } catch (error) {
                  console.error("Error in Case 5:", error);
                  // Handle the error for Case 5
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            message.error("Could not fetch departments, Try Again");
          }
        })
        .catch((err) => {
          message.error("Could not fetch departments, Try Again");
        });
    }
  }, [selectedDepartmentId]);

  const fetchWards = (selectedWard, selectedBed) => {
    getWardsCategory()
      .then((response) => {
        console.error(response.data);
        if (response.data) setWardsCategory(response.data);
        setselectedWardCategoryId(selectedWard);
        fetchBeds(selectedWard, selectedBed);
      })
      .catch((err) => {
        console.log(err);
        message.error("Could not fetch Wards Category List, Try Again");
      });
  };

  const fetchBeds = (wardID, selectedBed) => {
    getAllBedsInHospital()
      .then((response) => {
        if (response.data) setBeds(response.data);
        setselectedBedId(selectedBed);
      })
      .catch((err) => {
        // setDoctorsLoading(false);
        console.log(err);
        message.error("Could not fetch Beds List, Try Again");
      });
  };

  const fetchNurses = (selectedNurses) => {
    getNurses()
      .then((response) => {
        if (response.data) setNurses(response.data.body);
        if (response.data.body && response.data.body.length > 0) {
          setSelectedNurseIds(selectedNurses);
        } else {
          console.error("Nurse data is not available.");
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Could not fetch Nurses List, Try Again");
      });
  };

  const onSubmit = () => {
    const data = {
      ipdId: pId,
      bedId: selectedBedId,
      remark: "Patient is better so shifting to Deluxe Private room",
      doctorIds: selectedDoctorIds,
      nurseIds: selectedNurseIds,
      timeZone: "Asia/Calcutta",
    };

    updateAdmission(data)
      .then((response) => {
        if (response.data.status === 1) {
          message.success("Success");
          closeModal();
        } else {
          message.error(response.data.body);
        }
      })
      .catch((err) => {});
  };

  const fetchDoctors = (departmartID, doctorList) => {
    getDoctors(departmartID)
      .then((response) => {
        if (response.data.status === 1) {
          setDoctorsLoading(false);
          if (response.data.body) setDoctors(response.data.body);
          if (doctorList != "") {
            setselectedDoctorIds(doctorList);
          }
        } else {
          setDoctorsLoading(false);
          message.error("Could not fetch Doctors List, Try Again");
        }
      })
      .catch((err) => {
        setDoctorsLoading(false);
        console.log(err);
        message.error("Could not fetch Doctors List, Try Again");
      });
  };

  const departmentChange = (departmentId) => {
    setselectedDepartmentId(departmentId);
    setselectedDoctorIds([]);
    fetchDoctors(departmentId, "");
  };

  const wardCategoryChange = (wardID) => {
    setselectedWardCategoryId(wardID);
    setselectedBedId([]);
    fetchBeds(wardID, "");
  };

  return (
    <StyledModal
      visible={visible}
      closable={true}
      centered
      maskClosable={true}
      footer={null}
      onCancel={() => closeModal()}
    >
      <ModalContentDiv>
        {/* <MailOutlined style={{ width: '60px' }} /> */}
        <StyledPTitle>Edit Patient Admission Details</StyledPTitle>
        <div style={{ marginBottom: "20px" }}>
          <Col span={24}>Select Department</Col>
          <Select
            size="large"
            placeholder="Select Department"
            style={{ width: "100%" }}
            value={selectedDepartmentId}
            onChange={(value) => departmentChange(value)}
          >
            {departments &&
              departments.map((department) => (
                <Option value={department.id} key={department.id}>
                  {department.departmentName}
                </Option>
              ))}
          </Select>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <Col span={24}>Select Doctors</Col>
          <Select
            size="large"
            loading={doctorsLoading}
            placeholder="Select Doctor"
            style={{ width: "100%" }}
            value={selectedDoctorIds}
            onSelect={(value) => {
              setselectedDoctorIds((prevIds) => [...prevIds, value]);
            }}
            onDeselect={(value) => {
              setselectedDoctorIds((prevIds) =>
                prevIds.filter((id) => id !== value)
              );
            }}
            mode="multiple"
          >
            {doctors &&
              doctors.map((doctor) => (
                <Option value={doctor.id} key={doctor.id}>
                  {doctor.firstName + " " + doctor.lastName}
                </Option>
              ))}
          </Select>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <Col span={24}>Select Nurses</Col>
          <Select
            size="large"
            placeholder="Select Nurse"
            onSelect={(value) => {
              setSelectedNurseIds((prevIds) => [...prevIds, value]);
            }}
            onDeselect={(value) => {
              setSelectedNurseIds((prevIds) =>
                prevIds.filter((id) => id !== value)
              );
            }}
            mode="multiple"
            style={{ width: "100%" }}
            value={selectedNurseIds}
          >
            {nurses &&
              nurses.map((nurse) => (
                <Option value={nurse.id} key={nurse.id}>
                  {nurse.firstName + " " + nurse.lastName}
                </Option>
              ))}
          </Select>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <Col span={24}>Select Ward</Col>
          <Select
            size="large"
            placeholder="Select Ward Category"
            value={selectedWardCategoryId}
            style={{ width: "100%" }}
            onChange={(value) => wardCategoryChange(value)}
          >
            {wardsCategory &&
              wardsCategory.map((category) => (
                <Option value={category.id} key={category.id}>
                  {category.categoryName}
                </Option>
              ))}
          </Select>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <Col span={24}>Select Bed</Col>
          <Select
            size="large"
            placeholder="Select Bed"
            style={{ width: "100%" }}
            value={selectedBedId}
            onChange={(value) => setselectedBedId(value)}
          >
            {beds &&
              beds.map((bed) => (
                <Option value={bed.id} key={bed.id}>
                  {bed.bedNo}
                </Option>
              ))}
          </Select>
        </div>

        <Button justify="center" key="ok" type="primary" onClick={onSubmit}>
          Update
        </Button>
        <></>
      </ModalContentDiv>
    </StyledModal>
  );
};

export default AdmitPatientModal;
