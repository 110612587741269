import React, { useEffect } from "react";
import ChiefComplaints from "./ChiefComplaints";
import { Switch, Route } from "react-router-dom";
import Observations from "./Observations";
import { useState } from "react";
import { getSectionData } from "../../../../apis/healthRecord";
import { useSelector } from "react-redux";

const Examinations = () => {
  const [visible, setVisible] = useState("");
  const [from, setFrom] = useState("");
  const patient = useSelector((state) => state.patientReducer);
  const section = useSelector((state) => state.sectionReducer);
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    console.log("section id  is",section.sectionId)
console.log("patient id ",patient.pId)
console.log("appoinment id", patient.appointmentId)
    getSectionData(
      section.sectionId,
      user.useType == "PATIENT" ? user.id : patient.pId,
      patient.appointmentId
    ).then(({ data, status }) => {
      console.log("data is",data)
      if (status == 200) {
        if (data.status == 1) {
          if (from !== "Observations") setVisible("Observations");
        } else setVisible("Chief");
      } else setVisible("Chief");
    });
  }, []);
  return (
    <>
      {visible === "Chief" && (
        <ChiefComplaints setVisibility={setVisible} from={from} />
      )}
      {visible === "Observations" && (
        <Observations setVisibility={setVisible} setFrom={setFrom} />
      )}
    </>
  );
};
export default Examinations;
