import React from "react";
import { useSelector } from "react-redux";
import CustomModal from "../../../utils/CustomModal.jsx";
import PreviewBuilder from "../../../utils/PreviewBuilder/index.jsx";
import { StyledBodyTitle } from "./styledComponents.js";

const PreviewModal = ({ visible, setVisible, formName, fields }) => {
  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);
  return (
    <CustomModal visible={visible} setVisible={setVisible} isClosable={true}>
      <div style={{ width: "50vw", padding: "20px" }}>
        <StyledBodyTitle level={4} color={theme["@primary-color"]}>
          {formName}
        </StyledBodyTitle>
        <PreviewBuilder formName={formName} fields={fields} />
      </div>
    </CustomModal>
  );
};

export default PreviewModal;
