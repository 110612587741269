import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  CollapseButton,
  ImmediateDiv,
  RightButton,
  StyledDatePicker,
  StyledDiv,
  StyledH3,
  StyledIcon,
  StyledInputNumber,
  StyledNoActive,
  StyledP,
  StyledPayment,
  TimeDiv,
  TimeP,
} from './styledComponents';
import Icon, {
  ArrowsAltOutlined,
  ClockCircleOutlined,
  ShrinkOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  copayOrSelf,
  insuranceFields as insuranceTypes,
  paymentType,
} from '../PatientForm/formConfig';
import {
  deleteAppointment,
  getAppointmentDetails,
  getAppointmentTypes,
  getAvailableTimes,
  getAvailableTimesForLabOrRadiology,
  getDepartments,
  getDoctors,
  scheduleAppointment,
  updateAppointment,
} from '../../../apis/Appointment';
import {
  getUsersByType,
  searchAppointmentUnderGivenDate,
} from '../../../apis/patients';
import * as Sentry from '@sentry/react';
import AppointmentRequestDetails from './AppointmentRequestDetails';
import { Collapse } from 'react-collapse';
import FormBuilder from '../../utils/FormBuilder';
import ImmediateBookingConfirm from './immediateBookingConfirm';
import Moment from 'moment-timezone';
// import { ReactComponent as ReportComponent } from "../../../assets/icons/ConsultationReport.svg";
import { ReactComponent as ReportComponent } from '../../../assets/icons/appointmentrequests.svg';
import SelectTimeModal from './SelectTimeModal';
import Spinner from '../../utils/Spinner';
import StatusModal from './StatusModal';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { columns } from './columns';
import { fetchFormFieldMeta } from '../../../apis/forms';
import { fetchInsuranceProviders } from '../../../apis/Insurance';
import { getSettings } from '../../../apis/settings';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getHospitalId } from '../../utils/LocalStorage/index';

const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
// const { Panel } = Collapse;

const AppointmentForm = ({ patientId, appointmentId, user }) => {
  const [selectedTime, setSelectedTime] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [departmentsLoading, setDepartmentsLoading] = useState(false);
  const [doctorsLoading, setDoctorsLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [date, setDate] = useState(0);
  const [docId, setDocId] = useState(0);
  const [allSlots, setAllSlots] = useState([]);
  const [availableSlots, setAvailableSlots] = useState(null);
  const [firstAvailableSlot, setFirstAvailableSlot] = useState(null);
  const [secondAvailableSlot, setSecondAvailableSlot] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [parentAppointmentId, setParentAppointmentId] = useState();
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [bookDisable, setBookDisable] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState([]);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [visitTypes, setVisitTypes] = useState([]);
  const [visitType, setVisitType] = useState('');
  const [paymentTypes, setPaymentMethods] = useState(paymentType);
  const [insuranceProviders, setInsuranceProviders] = useState([]);
  const [insuranceFields, setInsuranceFields] = useState(insuranceTypes);
  const [consultationFees, setConsultationFees] = useState(0);
  const [amountPayable, setAmountPayable] = useState(0);
  const [isInsurance, setIsInsurance] = useState(false);
  const [insuranceClaim, setInsuranceClaim] = useState(0);
  const [immediateBooking, setImmediateBooking] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);
  const [testsIfAny, setTestsIfAny] = useState([]);
  const [activeDate, setActiveDate] = useState('');
  const [payInCashList, setPayInCashList] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);
  const [settings, setSettings] = useState();
  const [immediateBookingConfirm, setImmediateBookingConfirm] = useState(false);
  const [insuranceProviderData, setInsuranceProviderData] = useState('');
  const [underFollowUpPeriod, setUnderFollowUpPeriod] = useState();
  const [isFollowUp, setIsFollowUp] = useState(false);

  const appointmentStatus = ['New', 'Follow Up'];

  const history = useHistory();
  const theme = useSelector((state) => state.themeReducer);
  // const settings = useSelector((state) => state.settingsReducer);

  const handleOk = (e) => {
    setModalVisible(false);
  };

  const handleCancel = (e) => {
    setModalVisible(false);
  };

  const moreClicked = (e) => {
    setModalVisible(true);
  };

  const bookingStatusModalVisible = (cancel) => {
    setCancel(cancel);
    setStatusModalVisible(true);
  };

  const removeEmptyOrNull = (obj) => {
    Object.keys(obj).forEach(
      (k) =>
        (obj[k] && typeof obj[k] === "object" && removeEmptyOrNull(obj[k])) ||
        (!obj[k] && obj[k] !== undefined && delete obj[k])
    );
    return obj;
  };

  useEffect(() => {
    if (history.location.state?.visitType)
      setVisitType(history.location.state?.visitType);
    getSettings(null, getHospitalId()).then(({ data, status }) => {
      if (status === 200) {
        if (data.status == 1) {
          setSettings(data.body);
        }
      }
    });
    setDepartmentsLoading(true);
    var tempVisitypes;
    fetchFormFieldMeta('VISIT_TYPE')
      .then((response) => {
        console.log('res in fetch field', response.data.body);
        if (response.data.status === 1) {
          setVisitTypes(response.data.body);
          tempVisitypes = response.data.body;
          if (!appointmentId) {
            setVisitType(response.data?.body[0]?.value);
            form.setFieldsValue({ visitType: response.data.body[0].id });
            getVisitType(response.data.body[0].id);
            setInsuranceProviderData(
              `${
                response.data.body[0].value.toLowerCase() +
                'InsuranceProviderData'
              }`
            );
          }
        } else {
          message.error('Could not fetch appointment types, Try Again');
        }
      })
      .catch((err) => {
        setDepartmentsLoading(false);
        console.log('err in catch', err);
        message.error(
          'Could not fetch appointment types, Try Again -catchblock'
        );
      });
    getDepartments(getHospitalId())
      .then((response) => {
        if (response.data.status === 1) {
          setDepartmentsLoading(false);
          setDepartments(response.data.body);
        } else {
          setDepartmentsLoading(false);
          message.error('Could not fetch departments, Try Again');
        }
      })
      .catch((err) => {
        setDepartmentsLoading(false);
        message.error('Could not fetch departments, Try Again');
      });

    getAppointmentTypes()
      .then((response) => {
        if (response.data.status === 1) {
          setAppointmentTypes(response.data.body);
        } else {
          message.error('Could not fetch appointment types, Try again ');
        }
      })
      .catch((err) => {
        message.error('Could not fetch appointment types, Try again ');
      });

    if (appointmentId) {
      getAppointmentDetails(appointmentId)
        .then((response) => {
          if (response.data.status === 1) {
            let data = response.data.body;

            data.patient = data.patient;
            data.date = moment(data.date);
            data.department = data.department?.id;
            var tempVisitType = tempVisitypes?.filter(
              (item) => item.id == data.visitType.id
            )[0];
            if (data.visitType?.value == 'Hospital') {
              getDoctorsList(data.department, true);
            } else {
              if (
                (data.visitType && data.visitType?.value) ||
                tempVisitType.value
              )
                getUsersByType(
                  (data.visitType?.value
                    ? data.visitType.value
                    : tempVisitType?.value
                  ).toUpperCase()
                )
                  .then((response) => {
                    if (response.data.status == 1) {
                      setAssistants(response.data.body);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    Sentry.captureException(err);
                  });
            }
            data.doctor = data.doctor?.id;
            data.labOrRadiologyUser = data.laboratoryUser
              ? data.laboratoryUser?.id
              : data.radiologyUser?.id;
            form.setFieldsValue({
              labOrRadiologyUser: data.labOrRadiologyUser,
            });
            data.appointmentType = data.appointmentType?.id;
            data.followUpDate = moment(data.parentAppointment?.date);
            data.visitType = data.visitType?.id;
            data.visitTypeValue = data.visitType?.value
              ? data.visitType?.value
              : tempVisitType?.value;
            setVisitType(
              data.visitTypeValue ? data.visitTypeValue : tempVisitType?.value
            );
            const selectedTime = {
              startTime: data.startTime,
              endTime: data.endTime,
            };
            if (data.insuranceDeductibleType)
              data.insuranceDeductibleType =
                data.insuranceDeductibleType?.toLowerCase();
            if (data.insuranceData) {
              let insuranceData = {
                type: data.insuranceData?.insuranceProvider?.name,
                expiry: moment(data.insuranceData?.expireDate),
                number: data.insuranceData?.number,
                insuranceProvider: data.insuranceData?.insuranceProvider,
                planName: data.insuranceData?.planName,
              };
              data.insuranceData = insuranceData;
            }
            data.paymentType = data.paymentType?.toLowerCase();
            console.log('selected Time is', selectedTime);
            setFirstAvailableSlot(selectedTime);
            var date = new Date(data.date._d);
            var milliseconds = date.getTime();
            data.doctor
              ? getTimes(milliseconds, data.doctor, true, tempVisitType?.value)
              : getTimes(
                  milliseconds,
                  data.labOrRadiologyUser,
                  true,
                  tempVisitType?.value
                );
            setDate(milliseconds);
            data = removeEmptyOrNull(data);

            if (
              data.visitTypeValue
                ? data.visitTypeValue
                : tempVisitType?.value == 'Hospital'
            ) {
              setFormInitialValues(data);
            } else {
              data.labOrRadiologyUser = data.labOrRadiologyUser?.id;
              data.scheduledDate = data.date;
              data.consultation = moment(data?.parentAppointment?.date);
              setFormInitialValues(data);
            }
            handleChange({ ...data, initial: true }, data);
            setLoading(false);
          } else {
            setLoading(false);
            message.error('Couldnt fetch appointment details, Try again');
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          Sentry.captureException(err);
          message.error('Couldnt fetch appointment details, Try again');
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    formInitialValues.initial = true;
    handleChange(formInitialValues, formInitialValues);
  }, [formInitialValues]);

  useEffect(() => {
    fetchInsuranceProviders(visitType).then((resp) => {
      insuranceFields[0].meta = resp.data.body?.map((item) => item.name);
      setInsuranceProviders(resp.data.body);
      var insurance = resp.data.body?.filter(
        (item) => item.name == form.getFieldValue(['insuranceData', 'type'])
      )[0];
      if (insurance)
        insuranceFields[1].meta = insurance?.insurancePlans.map(
          (item) => item.value
        );
      setInsuranceFields(insuranceFields);
      if (form.getFieldValue(['insuranceData', 'type']))
        setPaymentMethods([...insuranceFields, ...copayOrSelf]);
      else setPaymentMethods(insuranceFields);
    });
    form.setFieldsValue({
      insuranceDataPlan: undefined,
      insuranceData: undefined,
    });
  }, [visitType]);

  const getDoctorsList = (departmentId, initial) => {
    if (!initial) {
      setFirstAvailableSlot(null);
      setSecondAvailableSlot(null);
      form.setFieldsValue({ doctor: '' });
      setAvailableSlots(null);
    }
    setDoctorsLoading(true);
    if (departmentId)
      getDoctors(departmentId)
        .then((response) => {
          if (response.data.status === 1) {
            setDoctorsLoading(false);
            if (response.data.body) setDoctors(response.data.body);
          } else {
            setDoctorsLoading(false);
            message.error('Could not fetch Doctors List, Try Again');
          }
        })
        .catch((err) => {
          setDoctorsLoading(false);
          console.log(err);
          Sentry.captureException(err);
          message.error('Could not fetch Doctors List, Try Again');
        });
  };

  const getTimes = (date, docId, initial, initialVisitType, followPeriod) => {
    setDocId(docId);
    let doc = doctors.find((item) => item.id === docId);
    if (
      form.getFieldValue('identity') === 'Follow Up' && followPeriod
        ? followPeriod.underFollowUpPeriod
        : underFollowUpPeriod?.underFollowUpPeriod
    ) {
      form.setFieldsValue({ doctorFee: 0 });
      setConsultationFees(0);
    } else {
      if (doc) {
        form.setFieldsValue({ doctorFee: doc.fee });
        let vat = (settings?.vat / 100) * (doc.fee - insuranceClaim);
        setAmountPayable(doc.fee + vat - insuranceClaim);
        findInsuranceClaim(
          form.getFieldValue('insuranceDeductibleType')?.toLowerCase()
        );
        setConsultationFees(`${doc.fee ? doc.fee : '0'} `);
      }
    }
    if (initialVisitType == 'Hospital' || visitType == 'Hospital')
      if (date > 0 && docId > 0) {
        const querryString = `date=${date}&doctorId=${docId}&timeZone=${Moment.tz.guess()}`;
        getAvailableTimes(querryString)
          .then((response) => {
            console.log('respo in time', response);
            if (response.data.status === 1) {
              console.log('response in time', response.data.body);
              const allSlots = response.data.body;
              const availableSlots = allSlots.filter(
                (slot) => slot.booked === false
              );
              console.log('available solt', availableSlots);
              const first = availableSlots[0];
              const second = availableSlots[1];
              console.log('first is', first);
              console.log('second is', second);
              setAllSlots(allSlots);
              setAvailableSlots(availableSlots);
              setBookDisable(false);

              if (!initial) {
                setFirstAvailableSlot(first);
                setSecondAvailableSlot(second);
              } else setSecondAvailableSlot(first);
            } else if (response.status === 400) {
              console.log('reso');
              setAvailableSlots('not available');
              setBookDisable(true);
            } else {
              console.log('else2');
              message.error('Could not fetch appointment times, Try agian');
            }
          })
          .catch((err) => {
            message.error('Could not fetch appointment times, Try agian');
            console.error(err);
          });
      }
    if (
      initialVisitType == 'Laboratory' ||
      visitType == 'Laboratory' ||
      initialVisitType == 'Radiology' ||
      visitType == 'Radiology'
    )
      if (date > 0 && docId > 0) {
        const queryString = `date=${date}&userId=${docId}&timeZone=${Moment.tz.guess()}&visitType=${visitType?.toUpperCase()}`;
        getAvailableTimesForLabOrRadiology(queryString)
          .then((response) => {
            if (response.data.status == 1) {
              const allSlots = response.data.body;
              const availableSlots = allSlots.filter(
                (slot) => slot.booked === false
              );
              const first = availableSlots[0];
              const second = availableSlots[1];
              setAllSlots(allSlots);
              setAvailableSlots(availableSlots);
              setSecondAvailableSlot(second);
              if (!appointmentId) {
                setFirstAvailableSlot(first);
              } else if (!initial) {
                setFirstAvailableSlot(first);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
          });
      }
  };

  const changeTime = (index) => {
    const first = allSlots[index];
    setFirstAvailableSlot(first);
  };

  useEffect(() => {
    if (docId && (date || docId)) {
      getTimes(date, docId);
    }
  }, [date, docId]);

  const submitForm = (values) => {
    setBookDisable(true);
    var payload;
    if (visitType !== 'Hospital') {
      payload = {
        visitType: values.visitType,
        paymentType: values.paymentType.toUpperCase(),
        doctorFee: values.doctorFee
          ? values.doctorFee
          : form.getFieldValue('doctorFee'),
        date: values.scheduledDate?.format('x'),
        endTime: firstAvailableSlot.endTime,
        immediateBooking: immediateBooking ? true : false,
        parentAppointmentId: parentAppointmentId?.appointment?.id,
        patientId: parseInt(patientId),
        startTime: firstAvailableSlot.startTime,
        timeZone: Moment.tz.guess(),
        vatPercentage: settings?.vat,
        labOrRadiologyUserId: values.labOrRadiologyUser,
        totalFees: amountPayable,
        insuranceClaim: insuranceClaim,
      };
      let id;
      if (values.insuranceData)
        id = insuranceProviders.find(
          (item) => item.name === values.insuranceData.type
        );
      if (values.insuranceData) {
        payload = {
          ...payload,
          insuranceData: {
            expireDate: values?.insuranceData?.expiry?.format('x'),
            number: values.insuranceData?.number,
            insuranceProvider: {
              id: id.id,
              insurancePlans: id.insurancePlans,
            },
            planName: values.insuranceDataPlan,
          },
          insuranceDeductibleType:
            values.insuranceDeductibleType?.toUpperCase(),
        };
      }
      if (immediateBooking)
        payload = {
          ...payload,
          testIdsOfInsurancePayment: insuranceList,
          testIdsOfCashPayment: payInCashList,
        };
      else {
        let testIds = testsIfAny?.map((item) => item.testDataId);
        if (values.paymentType == 'self') {
          payload = {
            ...payload,
            testIdsOfInsurancePayment: [],
            testIdsOfCashPayment: testIds,
          };
        } else if (values.paymentType == 'insurance') {
          payload = {
            ...payload,
            testIdsOfInsurancePayment: testIds,
            testIdsOfCashPayment: [],
          };
        }
      }
      if (testsIfAny?.length > 0)
        if (appointmentId) {
          updateAppointment(payload, appointmentId)
            .then((response) => {
              if (response.data.status === 1) {
                bookingStatusModalVisible(false);
              } else {
                message.error('Could not book appointment, Try again');
                setBookDisable(false);
              }
            })
            .catch((err) => {
              message.error('Could not book appointment, Try again');
              setBookDisable(false);
              console.log(err);
              Sentry.captureException(err);
            });
        } else {
          scheduleAppointment(payload)
            .then((response) => {
              if (response.data.status === 1) {
                bookingStatusModalVisible(false);
              } else {
                setBookDisable(false);
                message.error('Could not book appointment, Try again');
              }
            })
            .catch((err) => {
              setBookDisable(false);
              console.log(err);
              Sentry.captureException(err);
              message.error('Could not book appointment, Try again');
            });
        }
      else {
        message.error('No orders on the selected appointment');
        setBookDisable(false);
      }
    } else {
      let id;
      if (values.insuranceData)
        id = insuranceProviders.find(
          (item) => item.name === values.insuranceData.type
        );
      payload = {
        appointmentIdentityType: values.identity,
        appointmentTypeId: values.appointmentType,
        date: values.date?.format('x'),
        departmentId: values.department,
        doctorId: values.doctor,
        endTime: firstAvailableSlot.endTime,
        patientId: parseInt(patientId),
        reason: values.reason,
        startTime: firstAvailableSlot.startTime,
        doctorFee: values.doctorFee,
        visitType: values.visitType,
        parentAppointmentId:
          values.identity == 'Follow Up'
            ? underFollowUpPeriod?.appointment?.id
            : undefined,
        paymentType: values.paymentType.toUpperCase(),
        vatPercentage: settings?.vat,
        totalFees: amountPayable,
        insuranceClaim: insuranceClaim,
      };
      if (values.insuranceData) {
        payload = {
          ...payload,
          insuranceData: {
            expireDate: values?.insuranceData?.expiry?.format('x'),
            number: values.insuranceData?.number,
            insuranceProvider: {
              id: id.id,
              insurancePlans: id.insurancePlans,
            },
            planName: values.insuranceDataPlan,
          },
          insuranceDeductibleType:
            values.insuranceDeductibleType?.toUpperCase(),
        };
      }
      if (appointmentId) {
        updateAppointment(payload, appointmentId)
          .then((response) => {
            if (response.data.status === 1) {
              bookingStatusModalVisible(false);
            } else {
              message.error('Could not book appointment, Try again');
              setBookDisable(false);
            }
          })
          .catch((err) => {
            message.error('Could not book appointment, Try again');
            setBookDisable(false);
            console.log(err);
            Sentry.captureException(err);
          });
      } else {
        scheduleAppointment(payload)
          .then((response) => {
            if (response.data.status === 1) {
              bookingStatusModalVisible(false);
            } else {
              message.error('Could not book appointment, Try again');
              setBookDisable(false);
            }
          })
          .catch((err) => {
            message.error('Could not book appointment, Try again');
            setBookDisable(false);
            console.log(err);
            Sentry.captureException(err);
          });
      }
    }
  };

  const cancelAppointment = () => {
    if (appointmentId) {
      setCancelLoading(true);
      deleteAppointment(appointmentId)
        .then((response) => {
          if (response.data.status === 1) {
            setCancelLoading(false);
            setStatusModalVisible(false);
            history.push('/dashboard');
            message.success('Appointment Cancelled Successfully');
          } else {
            setCancelLoading(false);
            setStatusModalVisible(false);
            message.error('Could not delete appointment,Try again');
          }
        })
        .catch((err) => {
          setCancelLoading(false);
          setStatusModalVisible(false);
          message.error('Could not delete appointment,Try again');
        });
    } else {
      setCancelLoading(false);
      setStatusModalVisible(false);
      history.push('/dashboard');
    }
  };

  const disabledDateFollowUp = (current) => {
    return current > moment().startOf('day');
  };

  const disabledDate = (current) => {
    if (
      form.getFieldValue('paymentType') == 'insurance' &&
      form.getFieldValue(['insuranceData', 'type']) &&
      visitType !== 'Hospital' &&
      !immediateBooking
    ) {
      const insurer = insuranceProviders.find((item) => {
        return item.name === form.getFieldValue(['insuranceData', 'type']);
      });
      return (
        current &&
        current <
          moment()
            .startOf('day')
            .add(insurer?.[insuranceProviderData]?.waitingPeriod, 'days')
      );
    } else return current && current < moment().startOf('day');
  };

  const disabledDateBefore = (current) => {
    return current && current > moment().endOf('day');
  };

  const findInsuranceClaim = (
    insuranceDeductibleType,
    laboratoryOrRadiology
  ) => {
    var providers = [];
    // if (!providers) {
    fetchInsuranceProviders(visitType)
      .then((resp) => {
        providers = resp.data.body;
      })
      .finally(() => {
        const insurer = providers.find((item) => {
          return item.name === form.getFieldValue(['insuranceData', 'type']);
        });
        let tempInsuranceProviderData = insuranceProviderData;
        if (insuranceProviderData == '') {
          setInsuranceProviderData(
            `${visitType?.toLowerCase() + 'InsuranceProviderData'}`
          );
          tempInsuranceProviderData = `${
            visitType?.toLowerCase() + 'InsuranceProviderData'
          }`;
        }
        if (insurer?.[tempInsuranceProviderData]) {
          if (
            insuranceDeductibleType == 'own' &&
            (insurer?.[tempInsuranceProviderData].insuranceType == 'BOTH' ||
              insurer?.[tempInsuranceProviderData].insuranceType == 'SELF')
          ) {
            if (
              insurer?.[tempInsuranceProviderData].selfType == 'Fixed Amount'
            ) {
              setInsuranceClaim(
                insurer?.[tempInsuranceProviderData].selfAmount
              );
              findAmountPayable(
                insurer?.[tempInsuranceProviderData].selfAmount
              );
            } else if (
              insurer?.[tempInsuranceProviderData].selfType == 'Percentage'
            ) {
              setInsuranceClaim(
                (laboratoryOrRadiology == 0 || laboratoryOrRadiology
                  ? laboratoryOrRadiology
                  : form?.getFieldValue('doctorFee')
                  ? form?.getFieldValue('doctorFee')
                  : 0) *
                  (insurer?.[tempInsuranceProviderData].selfAmount / 100)
              );
              findAmountPayable(
                (laboratoryOrRadiology == 0 || laboratoryOrRadiology
                  ? laboratoryOrRadiology
                  : form?.getFieldValue('doctorFee')
                  ? form?.getFieldValue('doctorFee')
                  : 0) *
                  (insurer?.[tempInsuranceProviderData].selfAmount / 100)
              );
            } else {
              setInsuranceClaim(0);
              findAmountPayable(0);
            }
          }
          if (
            insuranceDeductibleType == 'copay' &&
            (insurer?.[tempInsuranceProviderData].insuranceType == 'BOTH' ||
              insurer?.[tempInsuranceProviderData].insuranceType == 'COPAY')
          ) {
            if (
              insurer?.[tempInsuranceProviderData].copayType == 'Fixed Amount'
            ) {
              setInsuranceClaim(
                insurer?.[tempInsuranceProviderData].copayAmount
              );
              findAmountPayable(
                insurer?.[tempInsuranceProviderData].copayAmount
              );
            } else if (
              insurer?.[tempInsuranceProviderData].copayType == 'Percentage'
            ) {
              setInsuranceClaim(
                (laboratoryOrRadiology == 0 || laboratoryOrRadiology
                  ? laboratoryOrRadiology
                  : form?.getFieldValue('doctorFee')
                  ? form?.getFieldValue('doctorFee')
                  : 0) *
                  (insurer?.[tempInsuranceProviderData].copayAmount / 100)
              );
              findAmountPayable(
                (laboratoryOrRadiology == 0 || laboratoryOrRadiology
                  ? laboratoryOrRadiology
                  : form?.getFieldValue('doctorFee')
                  ? form?.getFieldValue('doctorFee')
                  : 0) *
                  (insurer?.[tempInsuranceProviderData].copayAmount / 100)
              );
            } else {
              setInsuranceClaim(0);
              findAmountPayable(0);
            }
          }
        }
      });
    // }
  };

  const getVisitType = (id) => {
    visitTypes.map((type) => {
      if (type.id == id) {
        setVisitType(type.value);
        setInsuranceProviderData(
          `${type.value.toLowerCase() + 'InsuranceProviderData'}`
        );
      }
    });
  };

  const handleChange = (changed, all) => {
    // alert(changed.paymentType ?? "");
    if (changed.identity === 'Follow Up') {
      setIsFollowUp(true);
      if (underFollowUpPeriod?.underFollowUpPeriod)
        form.setFieldsValue({ doctorFee: 0 });
      if (form.getFieldValue('insuranceDeductibleType'))
        findInsuranceClaim(
          form.getFieldValue('insuranceDeductibleType')?.toLowerCase()
        );
      else findAmountPayable();
      setConsultationFees(`${settings?.currency} 0`);
      setAmountPayable(0);
    }
    if (changed.identity === 'New') {
      setIsFollowUp(false);
      setUnderFollowUpPeriod();
      form.setFieldsValue({ followUpDate: undefined, doctorFee: 0 });
      setConsultationFees(0);
      if (all.date) {
        var date = new Date(all.date._d);
        var milliseconds = date.getTime();
        if (all.doctor) getTimes(undefined, all.doctor);
        else {
          form.setFieldsValue({ doctorFee: 0 });
          setAmountPayable(0);
        }
      } else {
        form.setFieldsValue({ doctorFee: 0 });
        setAmountPayable(0);
      }
      findAmountPayable();
      // changed.consultation;
    }
    if (changed.paymentType === 'insurance') {
      // setPaymentMethods([...paymentTypes, ...insuranceFields]);
      setIsInsurance(true);
      if (form.getFieldValue('insuranceData')?.type)
        handleChange(
          {
            insuranceData: { type: form.getFieldValue('insuranceData')?.type },
          },
          all
        );
      else setPaymentMethods(insuranceFields);
    }
    if (changed.paymentType === 'self') {
      setPaymentMethods(paymentType);
      setIsInsurance(false);
      setInsuranceClaim(0);
      findAmountPayable();
      setImmediateBooking(false);
    }

    if (changed?.visitType) {
      setBookDisable(false);
      getVisitType(changed.visitType);
      var visitTypeValue = visitTypes.filter(
        (item) => item.id === changed.visitType
      )[0];

      /* need to check on this */
      if (
        (visitTypeValue && visitTypeValue?.value === 'Laboratory') ||
        visitTypeValue?.value === 'Radiology'
      )
        getUsersByType(visitTypeValue?.value?.toUpperCase())
          .then((response) => {
            if (response.data.status == 1) {
              setAssistants(response.data.body);
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
          });
      if (all.paymentType == 'insurance') {
        setPaymentMethods(paymentTypes);
        handleChange(
          {
            insuranceData: {
              type: form.getFieldValue(['insuranceData', 'type']),
            },
          },
          all
        );
      } else {
        setPaymentMethods([...paymentTypes, ...insuranceFields]);
      }
      setInsuranceClaim(0);
      findAmountPayable();
      if (form.getFieldValue('consultation'))
        handleChange(
          {
            consultation: form.getFieldValue('consultation'),
            changedVisitType: changed.visitType,
          },
          all
        );
    }

    if (changed.immediateBooking == true || changed.immediateBooking == false) {
      //   setImmediateBooking(changed.immediateBooking)
      form.setFieldsValue({ scheduledDate: undefined });
    }

    if (changed.consultation) {
      var date = new Date(changed.consultation._d);
      var milliseconds = date.getTime();
      var data = {
        requestedDate: milliseconds,
        visitType: changed.changedVisitType
          ? visitTypes.filter((item) => changed.changedVisitType == item.id)[0]
              ?.value
          : visitType
          ? visitType
          : visitTypes.filter((item) => all.visitType == item.id)[0]?.value,
        timeZone: Moment.tz.guess(),
        patientId: patientId,
      };
      if (data.visitType)
        searchAppointmentUnderGivenDate(data)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 1) {
                setParentAppointmentId(res.data.body);
                if (res.data.body.testsIfAny)
                  setTestsIfAny(res.data.body.testsIfAny);
                else setTestsIfAny([]);
                setActiveDate('(Active)');
              } else {
                setTestsIfAny([]);
                setActiveDate('(No Active Consultation)');
                if (visitType !== 'Hospital') setBookDisable(true);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
            message.error('Invalid Data');
            //     setLoading(false);
          });
    }

    if (changed.insuranceData) {
      if (changed.insuranceData.type) {
        if (!changed.initial) {
          form.setFieldsValue({
            insuranceDeductibleType: undefined,
            scheduledDate: undefined,
          });
        }
      }
      var insurer = insuranceProviders.find((item) => {
        return item.name === changed.insuranceData.type;
      });
      if (!insurer)
        fetchInsuranceProviders(visitType)
          .then((resp) => {
            insurer = resp.data.body.find((item) => {
              return item.name === changed.insuranceData.type;
            });
          })
          .finally(() => {
            if (insurer) {
              if (!changed.initial)
                form.setFieldsValue({ insuranceDataPlan: undefined });
              else {
                form.setFieldsValue({
                  insuranceData: {
                    type: insurer['name'],
                    number: changed.insuranceData?.number,
                    expiry: changed.insuranceData?.expiry,
                  },
                  insuranceDataPlan: changed.insuranceData?.planName,
                });
                findInsuranceClaim(changed.insuranceDeductibleType);
              }
              var insurance = insuranceProviders.filter(
                (item) =>
                  item.name == form.getFieldValue(['insuranceData', 'type'])
              )[0];
              if (insurance)
                insuranceFields[1].meta = insurance?.insurancePlans.map(
                  (item) => item.value
                );
              setInsuranceFields(insuranceFields);
              setPaymentMethods([...insuranceFields, ...copayOrSelf]);
              if (insurer?.[insuranceProviderData]?.insuranceType === 'BOTH') {
                copayOrSelf.find(
                  (item) => item.name === 'insuranceDeductibleType'
                ).meta = ['own', 'copay'];

                setPaymentMethods([...insuranceFields, ...copayOrSelf]);
              } else if (
                insurer?.[insuranceProviderData]?.insuranceType === 'SELF'
              ) {
                copayOrSelf.find(
                  (item) => item.name === 'insuranceDeductibleType'
                ).meta = ['own'];
                setPaymentMethods([...insuranceFields, ...copayOrSelf]);
              } else if (
                insurer?.[insuranceProviderData]?.insuranceType === 'COPAY'
              ) {
                copayOrSelf.find(
                  (item) => item.name === 'insuranceDeductibleType'
                ).meta = ['copay'];
                setPaymentMethods([...insuranceFields, ...copayOrSelf]);
              }
              if (changed.insuranceData?.type)
                if (changed.insuranceDataPlan)
                  findInsuranceClaim(
                    all.insuranceDeductibleType?.toLowerCase()
                  );
                else setInsuranceClaim(0);
            }
          });
      if (insurer) {
        if (!changed.initial)
          form.setFieldsValue({ insuranceDataPlan: undefined });
        else {
          form.setFieldsValue({
            insuranceData: {
              type: insurer['name'],
              number: changed.insuranceData?.number,
              expiry: changed.insuranceData?.expiry,
            },
            insuranceDataPlan: changed.insuranceData?.planName,
          });
          findInsuranceClaim(changed.insuranceDeductibleType);
        }
        var insurance = insuranceProviders.filter(
          (item) => item.name == form.getFieldValue(['insuranceData', 'type'])
        )[0];
        if (insurance)
          insuranceFields[1].meta = insurance?.insurancePlans.map(
            (item) => item.value
          );
        setInsuranceFields(insuranceFields);
        setPaymentMethods([...insuranceFields, ...copayOrSelf]);
        if (insurer?.[insuranceProviderData]?.insuranceType === 'BOTH') {
          copayOrSelf.find(
            (item) => item.name === 'insuranceDeductibleType'
          ).meta = ['own', 'copay'];

          setPaymentMethods([...insuranceFields, ...copayOrSelf]);
        } else if (insurer?.[insuranceProviderData]?.insuranceType === 'SELF') {
          copayOrSelf.find(
            (item) => item.name === 'insuranceDeductibleType'
          ).meta = ['own'];
          setPaymentMethods([...insuranceFields, ...copayOrSelf]);
        } else if (
          insurer?.[insuranceProviderData]?.insuranceType === 'COPAY'
        ) {
          copayOrSelf.find(
            (item) => item.name === 'insuranceDeductibleType'
          ).meta = ['copay'];
          setPaymentMethods([...insuranceFields, ...copayOrSelf]);
        }
        if (changed.insuranceData?.type)
          if (changed.insuranceDataPlan)
            findInsuranceClaim(all.insuranceDeductibleType?.toLowerCase());
          else setInsuranceClaim(0);
      }
      // findAmountPayable();
    }
    if (changed.visitType) {
      if (!changed.initial) {
        insuranceFields[0].meta = [];
        insuranceFields[1].meta = [];
        setInsuranceFields(insuranceFields);
        form.setFieldsValue({
          consultation: undefined,
          scheduledDate: undefined,
          labOrRadiologyUser: undefined,
          doctor: undefined,
        });
        setDocId();
        setFirstAvailableSlot(null);
        setSecondAvailableSlot(null);
        setAllSlots([]);
        setTestsIfAny([]);
      }
    }
    if (changed.followUpDate && all.identity === 'Follow Up') {
      if (all.date) {
        var data = {
          requestedDate: new Date(changed.followUpDate._d).getTime(),
          scheduleDate: new Date(all.date._d).getTime(),
          visitType: changed.visitTypeValue
            ? changed.visitTypeValue
            : changed.changedVisitType
            ? visitTypes.filter(
                (item) => changed.changedVisitType == item.id
              )[0]?.value
            : visitType
            ? visitType
            : visitTypes.filter((item) => all.visitType == item.id)[0]?.value,
          timeZone: Moment.tz.guess(),
          patientId: patientId,
        };
        searchAppointmentUnderGivenDate(data)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 1) {
                setUnderFollowUpPeriod(res.data.body);
                getTimes(
                  new Date(all.date._d).getTime(),
                  form.getFieldValue('doctor'),
                  undefined,
                  undefined,
                  res.data.body
                );
              } else {
                message.error(res.data.body);
                form.setFieldsValue({ followUpDate: undefined });
              }
            }
          })
          .catch(console.log);
      }
    }
    if (changed.date) {
      if (all.followUpDate && all.identity === 'Follow Up') {
        var data = {
          requestedDate: new Date(all.followUpDate._d).getTime(),
          scheduleDate: new Date(changed.date._d).getTime(),
          visitType: changed.visitTypeValue
            ? changed.visitTypeValue
            : changed.changedVisitType
            ? visitTypes.filter(
                (item) => changed.changedVisitType == item.id
              )[0]?.value
            : visitType
            ? visitType
            : visitTypes.filter((item) => all.visitType == item.id)[0]?.value,
          timeZone: Moment.tz.guess(),
          patientId: patientId,
        };
        searchAppointmentUnderGivenDate(data)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 1) {
                setUnderFollowUpPeriod(res.data.body);
                getTimes(
                  new Date(changed.date._d).getTime(),
                  form.getFieldValue('doctor'),
                  undefined,
                  undefined,
                  res.data.body
                );
              } else {
                message.error(res.data.body);
                form.setFieldsValue({ followUpDate: undefined });
              }
            }
          })
          .catch(console.log);
      }
    }
    if (changed.doctorFee) {
      if (changed.initial == true) {
        form.setFieldsValue({
          doctorFee: changed.doctorFee,
          insuranceData: changed.insuranceData,
        });
        findInsuranceClaim(changed.insuranceDeductibleType);
      } else
        findInsuranceClaim(
          form.getFieldValue('insuranceDeductibleType')?.toLowerCase()
        );
      setConsultationFees(changed.doctorFee);
      findAmountPayable();
    }
    if (changed.department) {
      if (!changed.initial) form.setFieldsValue({ doctorFee: 0 });
      findAmountPayable();
    }
    if (changed.insuranceDeductibleType) {
      findInsuranceClaim(changed.insuranceDeductibleType?.toLowerCase());
      form.setFieldsValue({
        insuranceDeductibleType: changed.insuranceDeductibleType,
      });
    }
    if (changed.labOrRadiologyUser) {
      if (all.scheduledDate) {
        const queryString = `date=${all.scheduledDate?.format('x')}&userId=${
          changed.labOrRadiologyUser
        }&timeZone=${Moment.tz.guess()}&visitType=${visitType?.toUpperCase()}`;
        getAvailableTimesForLabOrRadiology(queryString)
          .then((response) => {
            if (response.data.status == 1) {
              const allSlots = response.data.body;
              const availableSlots = allSlots.filter(
                (slot) => slot.booked === false
              );
              const first = availableSlots[0];
              const second = availableSlots[1];
              setAllSlots(allSlots);
              setAvailableSlots(availableSlots);
              // if (!appointmentId) {
              setFirstAvailableSlot(first);
              setSecondAvailableSlot(second);
            }
          })
          .catch((err) => {
            console.log(err);
            Sentry.captureException(err);
          });
      }
    }
  };

  useEffect(() => {
    insuranceFields[0].meta = insuranceProviders.map((item) => item.name);
    setInsuranceFields(insuranceFields);
  }, [insuranceProviders]);

  useEffect(() => {
    fetchInsuranceProviders(visitType).then((resp) => {
      // setInsuranceProviders(resp.data.body);
      var insurance = insuranceProviders?.filter(
        (item) => item.name == form.getFieldValue(['insuranceData', 'type'])
      )[0];
      if (insurance)
        insuranceFields[1].meta = insurance?.insurancePlans.map(
          (item) => item.value
        );
      setInsuranceFields(insuranceFields);
      if (form.getFieldValue(['insuranceData', 'type']))
        setPaymentMethods([...insuranceFields, ...copayOrSelf]);
      else setPaymentMethods(insuranceFields);
    });
  }, [form.getFieldValue(['insuranceData', 'type'])]);

  useEffect(() => {
    if (visitType !== 'Hospital') {
      if (immediateBooking) {
        var insuranceAmount = 0;
        var payAmount = 0;
        for (let index = 0; index < insuranceList.length; index++) {
          var element = testsIfAny.filter(
            (item) => item.testDataId == insuranceList[index]
          )[0];
          insuranceAmount = insuranceAmount + (element.fee ? element.fee : 0);
        }
        for (let index = 0; index < testsIfAny.length; index++) {
          payAmount =
            payAmount + (testsIfAny[index]?.fee ? testsIfAny[index].fee : 0);
        }
        findInsuranceClaim(
          form.getFieldValue('insuranceDeductibleType')?.toLowerCase(),
          insuranceAmount
        );
        form.setFieldsValue({ doctorFee: payAmount });
      }
    }
  }, [insuranceList, payInCashList, testsIfAny]);

  useEffect(() => {
    if (visitType !== 'Hospital' && visitType !== '')
      if (!immediateBooking) {
        var insuranceAmount = 0;
        for (let index = 0; index < testsIfAny.length; index++) {
          insuranceAmount =
            insuranceAmount +
            (testsIfAny[index]?.fee ? testsIfAny[index].fee : 0);
        }
        // setInsuranceClaim(insuranceAmount);
        form.setFieldsValue({ doctorFee: insuranceAmount });
        if (form.getFieldValue('paymentType') == 'insurance') {
          findInsuranceClaim(
            form.getFieldValue('insuranceDeductibleType')?.toLowerCase()
          );
        } else if (form.getFieldValue('paymentType') == 'self') {
          setInsuranceClaim(0);
          findAmountPayable();
        }
      }
  }, [form.getFieldValue(), testsIfAny, visitType]);

  useEffect(() => {
    setInsuranceClaim(0);
  }, [immediateBooking]);

  const findAmountPayable = (claim) => {
    var vat;
    var payable;
    if (!claim) claim = insuranceClaim;
    if (form?.getFieldValue('doctorFee')) {
      vat = (settings?.vat / 100) * (form?.getFieldValue('doctorFee') - claim);
      payable =
        vat +
        (form?.getFieldValue('doctorFee')
          ? form?.getFieldValue('doctorFee')
          : 0) -
        claim;
    }
    if (payable)
      if (payable < 0) setAmountPayable(0);
      else setAmountPayable(payable);
  };

  useEffect(() => {
    findAmountPayable();
  }, [form.getFieldValue('doctorFee')]);

  useEffect(() => {
    findAmountPayable();
  }, [insuranceClaim]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Form
        className="appointment_form_style1 form-m2-style"
        layout="vertical"
        onFinish={(values) => {
          if (immediateBooking)
            if (
              insuranceList.length + payInCashList.length ==
              testsIfAny.length
            )
              submitForm(values);
            else message.error('Select all payment methods');
          else submitForm(values);
        }}
        // initialValues={formInitialValues}
        // key={Object.keys(formInitialValues).length !== 0}
        form={form}
        scrollToFirstError={true}
        onValuesChange={handleChange}
      >
        <Row gutter={[36, 0]}>
          <Col span="12">
            <FormBuilder
              formConfig={[
                {
                  inputType: 'heading',
                  label: 'Appointment Details',
                },
              ]}
            />
            <Form.Item
              name="visitType"
              label="Visit Type"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Select
                size="large"
                loading={departmentsLoading}
                placeholder="Select Visit Type"
                disabled={appointmentId}
              >
                {visitTypes &&
                  visitTypes.map((type) => {
                    return <Option value={type.id}>{type.value}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>

          <Col
            span="12"
            className="payment-type-radio"
            style={{ display: 'flex' }}
          >
            <FormBuilder
              formConfig={[
                {
                  inputType: 'heading',
                  label: 'Payment Type',
                },
                {
                  inputType: 'radio',

                  rules: [
                    { required: true, message: 'Payment Type is required' },
                  ],
                  name: 'paymentType',
                  initialValue: 'self',
                  meta: ['self', 'insurance'],
                  layout: { span: 24 },
                },
              ]}
            />
          </Col>
          {isInsurance && (
            <Col span={24}>
              <FormBuilder
                formConfig={paymentTypes}
                //  formInstance={form}
              />
            </Col>
          )}
          {visitType == 'Hospital' ? (
            <>
              <Col span={24}>
                <StyledH3 color={theme['@primary-color']}>
                  Appointment Identity
                </StyledH3>
              </Col>
              <Col span="12">
                <Form.Item
                  name="identity"
                  label="Type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select size="large">
                    {appointmentStatus &&
                      appointmentStatus.map((type) => {
                        return <Option value={type}>{type}</Option>;
                      })}
                  </Select>
                </Form.Item>
              </Col>
              {isFollowUp && (
                <Col span="12">
                  <Form.Item
                    name="followUpDate"
                    label="Previous Consultation Date"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <StyledDatePicker
                      showToday={false}
                      // onChange={(date, dateString) =>
                      //   setDate(date?.format("x"))
                      // }
                      size="large"
                      disabledDate={disabledDateFollowUp}
                      format="DD-MM-YYYY"
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span="12">
                <Form.Item
                  name="date"
                  label="Consultation Date"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <StyledDatePicker
                    onChange={(date, dateString) => setDate(date?.format('x'))}
                    size="large"
                    disabledDate={disabledDate}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  name="department"
                  label="Department"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    loading={departmentsLoading}
                    placeholder="Select Department"
                    onSelect={(value) => getDoctorsList(value)}
                  >
                    {departments &&
                      departments.map((department) => {
                        return (
                          <Option value={department.id}>
                            {department.departmentName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  name="doctor"
                  label="Doctor"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    loading={doctorsLoading}
                    placeholder="Select Doctor"
                    onSelect={(value) => {
                      console.log('value of doc', value);
                      setDocId(value);
                    }}
                  >
                    {doctors &&
                      doctors.map((doctor) => {
                        return (
                          <Option value={doctor.id}>
                            {doctor.firstName + ' ' + doctor.lastName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  name="doctorFee"
                  label="Consultation Fee"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <StyledInputNumber
                    formatter={(value) => {
                      const re = /^[0-9\b]+$/;
                      if (value != settings?.currency) {
                        if (!re.test(value)) {
                          value = value.replace(/[^\d.-]/g, '');
                        }
                        return `${settings?.currency} ${value}`;
                      } else if (settings?.currency.includes(value)) return ``;
                      else return `${settings?.currency} `;
                    }}
                    parser={(value) =>
                      value
                        .replace(`${settings?.currency} `, '')
                        .replace(`^[A-Za-z]\S*$`, '')
                    }
                    size="large"
                    disabled={user.userType === 'PATIENT'}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item name=" " label="Time">
                  <TimeDiv className="forminput_timepick">
                    <TimeP>
                      {availableSlots === null
                        ? 'Select Date, Department and Doctor to view slot'
                        : availableSlots === 'not available'
                        ? 'No Slots available for the day'
                        : firstAvailableSlot
                        ? `${moment(firstAvailableSlot.startTime)?.format(
                            'hh:mm A'
                          )} -
                          ${moment(firstAvailableSlot.endTime)?.format(
                            'hh:mm A'
                          )} `
                        : 'No Slots available for the day'}
                    </TimeP>
                    <ClockCircleOutlined />
                  </TimeDiv>
                </Form.Item>
              </Col>
              <Col span="12"></Col>
              <Col span="12">
                <Form.Item name=" " label=" " className="available_time_field">
                  <StyledDiv>
                    <StyledP>
                      {availableSlots === null
                        ? 'Select Date, Department and Doctor to view slots'
                        : availableSlots === 'not available'
                        ? 'No Slots available for the day'
                        : secondAvailableSlot
                        ? `Next Available Time:
                      ${moment(secondAvailableSlot.startTime)?.format(
                        'hh:mm A'
                      )} -
                      ${moment(secondAvailableSlot.endTime)?.format(
                        'hh:mm A'
                      )} `
                        : 'No Other slots available'}
                    </StyledP>
                    <Button
                      onClick={moreClicked}
                      disabled={
                        (availableSlots && availableSlots.length <= 0) ||
                        availableSlots === null
                      }
                    >
                      More
                    </Button>
                  </StyledDiv>
                </Form.Item>
              </Col>
              <Col span={24}>
                <StyledH3 color={theme['@primary-color']}>
                  Appointment Details
                </StyledH3>
              </Col>
              <Col span="12">
                <Form.Item
                  name="appointmentType"
                  label="Type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select size="large">
                    {appointmentTypes &&
                      appointmentTypes.map((type) => {
                        return <Option value={type.id}>{type.name}</Option>;
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item
                  name="reason"
                  label="Reason"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TextArea rows={4} size="large" />
                </Form.Item>
              </Col>
            </>
          ) : (
            <>
              <Col span={24}>
                <StyledH3 color={theme['@primary-color']}>
                  Appointment Identity
                </StyledH3>
              </Col>
              <Col span="12">
                <Form.Item
                  name="consultation"
                  label="Doctor Consultation Date"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <StyledDatePicker
                    onChange={(date, dateString) => setDate(date?.format('x'))}
                    size="large"
                    disabledDate={disabledDateBefore}
                    format={`DD-MM-YYYY  [${activeDate}]`}
                  />
                </Form.Item>
              </Col>
              {form.getFieldValue('paymentType') == 'insurance' && (
                <Col span="12">
                  {user.userType === 'PATIENT' ? (
                    <Row className="ant-form-item">
                      <Col
                        className="ant-form-item-label"
                        style={{ visibility: 'hidden' }}
                      >
                        head
                      </Col>
                      <Col className="ant-form-item-control">
                        <ImmediateDiv>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {/* <WarningIcon height="25px" fontWeight="bold" /> */}
                          <WarningOutlined
                            style={{
                              fontSize: '20px',
                              color: 'rgba(255,170,0,1)',
                            }}
                          />
                          &nbsp;&nbsp; Contact the Reception to make an
                          Immediate Booking
                        </ImmediateDiv>
                      </Col>
                    </Row>
                  ) : (
                    <Form.Item
                      name="immediateBooking"
                      valuePropName="checked"
                      style={{
                        marginTop: '30px',
                        fontSize: '16px',
                        color: '#818181',
                      }}
                    >
                      <input
                        type="checkbox"
                        disabled={
                          form.getFieldValue('paymentType') != 'insurance'
                        }
                        defaultChecked={false}
                        checked={immediateBooking}
                        value={immediateBooking}
                        onChange={() => {
                          if (!immediateBooking)
                            setImmediateBookingConfirm(
                              !immediateBookingConfirm
                            );
                          else {
                            setImmediateBooking(!immediateBooking);
                            form.setFieldsValue({ scheduledDate: undefined });
                          }
                        }}
                        style={{ margin: '0 15px' }}
                      />
                      Immediate Booking
                    </Form.Item>
                  )}
                </Col>
              )}
              <Col span="12">
                <Form.Item
                  name="scheduledDate"
                  label="Scheduled Date"
                  rules={[
                    {
                      required: true,
                      message: 'Scheduled date is required',
                    },
                  ]}
                >
                  <StyledDatePicker
                    onChange={(date, dateString) => setDate(date?.format('x'))}
                    size="large"
                    disabledDate={disabledDate}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  name="labOrRadiologyUser"
                  label={
                    (visitType == 'Laboratory' && 'Laboratory Assistant') ||
                    (visitType == 'Radiology' && 'Radiology Assistant') ||
                    ''
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    loading={doctorsLoading}
                    placeholder={
                      (visitType == 'Laboratory' &&
                        'Select Laboratory Assistant') ||
                      (visitType == 'Radiology' && 'Select Radiology Assistant')
                    }
                    onSelect={(value) => setDocId(value)}
                  >
                    {assistants &&
                      assistants.map((assistant) => {
                        return (
                          <Option value={assistant.id}>
                            {assistant.firstName + ' ' + assistant.lastName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item name=" " label="Time">
                  <TimeDiv className="forminput_timepick">
                    <TimeP>
                      {availableSlots === null
                        ? (visitType == 'Laboratory' &&
                            'Select Date and Laboratory Assistant to view slot') ||
                          (visitType == 'Radiology' &&
                            'Select Date and Radiology Assistant to view slot')
                        : firstAvailableSlot
                        ? `${moment(firstAvailableSlot.startTime)?.format(
                            'hh:mm A'
                          )} -
  ${moment(firstAvailableSlot.endTime)?.format('hh:mm A')} `
                        : 'No Slots available for the day'}
                    </TimeP>
                    <ClockCircleOutlined />
                  </TimeDiv>
                </Form.Item>
              </Col>
              <Col span="12"></Col>
              <Col span="12">
                <Form.Item name=" " label=" " className="available_time_field">
                  <StyledDiv>
                    <StyledP>
                      {availableSlots === null
                        ? (visitType == 'Laboratory' &&
                            'Select Date and Laboratory Assistant to view slot') ||
                          (visitType == 'Radiology' &&
                            'Select Date and Radiology Assistant to view slot')
                        : secondAvailableSlot
                        ? `Next Available Time:
${moment(secondAvailableSlot.startTime)?.format('hh:mm A')} -
  ${moment(secondAvailableSlot.endTime)?.format('hh:mm A')} `
                        : 'No Other slots available'}
                    </StyledP>
                    <Button
                      onClick={moreClicked}
                      disabled={
                        (availableSlots && availableSlots.length <= 0) ||
                        availableSlots === null
                      }
                    >
                      More
                    </Button>
                  </StyledDiv>
                </Form.Item>
              </Col>
              <Col span={24}>
                <CollapseButton
                  type="primary"
                  className="btn-save"
                  style={{ padding: '0 20px' }}
                  onClick={() => {
                    setIsCollapse(!isCollapse);
                  }}
                >
                  <b>Active Requests</b>
                  {isCollapse ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
                </CollapseButton>
              </Col>
              <Col span={24}>
                <Collapse isOpened={isCollapse}>
                  {testsIfAny?.length > 0 ? (
                    <Table
                      headBg={theme['@primary-color']}
                      showHeader={true}
                      columns={columns({
                        settings: settings,
                        visitType: visitType,
                        immediateBooking: immediateBooking,
                        payInCashList: payInCashList,
                        setPayInCashList: setPayInCashList,
                        insuranceList: insuranceList,
                        setInsuranceList: setInsuranceList,
                      })}
                      dataSource={testsIfAny}
                      pagination={false}
                    />
                  ) : (
                    <>
                      <StyledIcon component={ReportComponent} />
                      <StyledNoActive>No Active Requests</StyledNoActive>
                    </>
                  )}
                </Collapse>
              </Col>
            </>
          )}
          <Divider />
          <Col span="12">
            <FormBuilder
              formConfig={[
                {
                  inputType: 'heading',
                  label: 'Payment Summary',
                },
              ]}
            />
            <Row gutter={[36, 24]}>
              {visitType == 'Hospital' ? (
                <>
                  <Col span="12">
                    <StyledPayment>(A)&nbsp;Consultation Fee: </StyledPayment>
                  </Col>
                  <Col span="12">
                    <StyledPayment>
                      <b>
                        {settings?.currency}&nbsp;
                        {parseFloat(
                          form?.getFieldValue('doctorFee')
                            ? form?.getFieldValue('doctorFee')
                            : 0
                        ).toFixed(2)}
                      </b>
                    </StyledPayment>
                  </Col>
                </>
              ) : (
                <>
                  <Col span="12">
                    <StyledPayment>(A)&nbsp;Total Fee: </StyledPayment>
                  </Col>
                  <Col span="12">
                    <StyledPayment>
                      <b>
                        {settings?.currency}&nbsp;
                        {parseFloat(
                          form?.getFieldValue('doctorFee')
                            ? form?.getFieldValue('doctorFee')
                            : 0
                        ).toFixed(2)}
                      </b>
                    </StyledPayment>
                  </Col>
                </>
              )}
              {isInsurance ? (
                <>
                  <Col span="12">
                    <StyledPayment>(B)&nbsp;Insurance Claim: </StyledPayment>
                  </Col>
                  <Col span="12">
                    <StyledPayment>
                      <b>
                        {settings?.currency}{' '}
                        {parseFloat(insuranceClaim).toFixed(2)}
                      </b>
                    </StyledPayment>
                  </Col>
                </>
              ) : (
                <></>
              )}
              <Col span="12">
                <StyledPayment>
                  ({isInsurance ? 'C' : 'B'})&nbsp;VAT&nbsp;
                  <p style={{ fontSize: '13px', display: 'contents' }}>
                    {isInsurance
                      ? `(A-B) * ${settings?.vat}%`
                      : `A * ${settings?.vat}%`}
                  </p>
                  &nbsp;:
                </StyledPayment>
              </Col>
              <Col span="12">
                <StyledPayment>
                  <b>
                    {settings?.currency}{' '}
                    {parseFloat(
                      insuranceClaim > form?.getFieldValue('doctorFee')
                        ? 0
                        : (settings?.vat / 100) *
                            (form?.getFieldValue('doctorFee')
                              ? form?.getFieldValue('doctorFee') -
                                insuranceClaim
                              : 0)
                    ).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
              <Col span="12">
                <StyledPayment>
                  ({isInsurance ? 'D' : 'C'})&nbsp;Amount Payable&nbsp;
                  <p style={{ fontSize: '13px', display: 'contents' }}>
                    {isInsurance ? '(A - B) + C' : 'A + B'}
                  </p>
                  &nbsp;:
                </StyledPayment>
              </Col>
              <Col span="12">
                <StyledPayment>
                  <b>
                    {settings?.currency}&nbsp;
                    {parseFloat(amountPayable).toFixed(2)}
                  </b>
                </StyledPayment>
              </Col>
            </Row>
          </Col>

          <Col span="24" style={{ marginTop: '20px' }}>
            <Form.Item>
              <RightButton
                htmlType="submit"
                type="primary"
                className="btn-save"
                disabled={bookDisable}
                // onClick={() => setBookDisable(true)}
              >
                Book
              </RightButton>
              <RightButton
                className="btn-cancel"
                type="primary"
                ghost
                onClick={() => {
                  bookingStatusModalVisible(true);
                }}
              >
                Appointment Cancel
              </RightButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <SelectTimeModal
        modalVisible={modalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        availableSlots={allSlots}
        changeTime={changeTime}
      />
      <StatusModal
        cancel={cancel}
        cancelAppointment={cancelAppointment}
        visible={statusModalVisible}
        closeModal={() => setStatusModalVisible(false)}
        cancelLoading={cancelLoading}
      />
      <ImmediateBookingConfirm
        cancel={true}
        isInsurance={isInsurance}
        setImmediateBooking={setImmediateBooking}
        immediateBooking={immediateBooking}
        visible={immediateBookingConfirm}
        closeModal={() => {
          setImmediateBookingConfirm(false);
          form.setFieldsValue({ scheduledDate: undefined });
        }}
      />
    </>
  );
};

export default AppointmentForm;
