import React from "react";
import CameraModal from "../PatientForm/CameraModal";
import { ReactComponent as Avatar } from "../../../assets/icons/image-upload-avatar.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/SettingsIcon.svg";
import { DeleteOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import styled from "styled-components";

import Icon, {
  LeftCircleOutlined,
  UploadOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { Col, Row, Upload, Form, Button, Divider, Empty, message } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const ImageContainer = styled.div`
  width: 110px;
  height: 110px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1199px) {
    width: 90px;
    height: 90px;
  }
`;

export const ImageUploadContainer = styled.div`
  width: 220px;
  padding: 20px 20px 20px 0;
  @media (max-width: 1199px) {
    // width: inherit;
    width: 180px;
  }
`;

function ImageUpload({
  profilePic,
  setCameraModalVisible,
  setProfilePic,
  profilePicURL,
  setDisabled,
  disabled,
  setProfilePicURL,
}) {
  const uploadProps = {
    beforeUpload: (file) => {
      setProfilePic(file);
      // setDisabled(false);
      return false;
    },
    showUploadList: false,
  };
  const theme = useSelector((state) => state.themeReducer);

  useEffect(() => {}, []);
  return (
    <ImageUploadContainer className="img_port_col">
      <Row align="middle" justify="center">
        <Col lg={12} md={12} s={12} xs={12}>
          <ImageContainer
            className="profile_pic"
            bgImage={
              profilePic ? URL.createObjectURL(profilePic) : profilePicURL
            }
            color={theme["@primary-color"]}
          >
            {!profilePic && !profilePicURL && (
              <Icon
                component={Avatar}
                style={{ fontSize: "90px", margin: "auto" }}
              />
            )}
          </ImageContainer>
          {profilePic || ((profilePicURL || profilePic) && !disabled) ? (
            <DeleteOutlined
              style={{
                color: theme["@primary-color"],
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
              onClick={() => {
                setProfilePic();
                if (!disabled) {
                  setProfilePicURL();
                }
              }}
            />
          ) : (
            <></>
          )}
        </Col>
        <Col lg={12} md={12} s={12} xs={12}>
          <Row>
            <Col span={24} style={{ marginBottom: "10px" }}>
              <Button
                icon={<CameraOutlined />}
                type="primary"
                disabled={disabled}
                onClick={() => setCameraModalVisible(true)}
                size="middle"
              />
            </Col>
            <Col span={24} className="profile-image-upload">
              {" "}
              <ImgCrop rotate>
                <Upload {...uploadProps}>
                  <Button
                    disabled={disabled}
                    icon={<UploadOutlined />}
                    type="primary"
                    ghost
                    size="middle"
                  />
                </Upload>
              </ImgCrop>
            </Col>
          </Row>
        </Col>
      </Row>
    </ImageUploadContainer>
  );
}

export default ImageUpload;
