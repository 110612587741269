import React from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button, Upload, notification } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  ModalTitle,
  UploaderPlaceHolder,
  StyledRow,
  ButtonContainer,
  ImportButton,
} from "./styledComponents";
import { FileTextOutlined } from "@ant-design/icons";
import { useState } from "react";
import { DRUG_TEMPLATE } from "../../../../constants/StringConstants.js";

const ModalBodyContainer = styled.div`
  width: 500px;
`;

const ImportModal = ({ visible, setVisible, uploadFile, loading }) => {
  const theme = useSelector((state) => state.themeReducer);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [loading, setLoading] = useState(false);
  const props = {
    name: "file",
    beforeUpload: (file) => {
      if (file.name.endsWith(".xlsx")) {
        setSelectedFile(file);
      } else {
        notification.error({
          message: "Unable to upload",
          description: "Please select excel sheet  only.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }

      return false;
    },
    onRemove: () => {},
    accept: ".xlsx",
    showUploadList: false,
  };

  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <ModalTitle color={theme["@primary-color"]}>
              Import Excel Sheet
            </ModalTitle>
          </Col>
        </Row>
        <StyledRow justify="center" gutter={["24"]}>
          <ButtonContainer span={12}>
            <UploaderPlaceHolder>
              <FileTextOutlined
                style={{ fontSize: "35px", marginBottom: "10px" }}
              />
              {selectedFile ? selectedFile.name : "Select Document"}
            </UploaderPlaceHolder>
          </ButtonContainer>
          <ButtonContainer span={12}>
            <Upload {...props}>
              <ImportButton type="primary">Choose File</ImportButton>
            </Upload>
            <a href={`${DRUG_TEMPLATE}`}>
              <ImportButton>Download sample excel sheet</ImportButton>
            </a>
          </ButtonContainer>
        </StyledRow>
        <StyledRow gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVisible(false);
                setSelectedFile(null);
              }}
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                uploadFile(selectedFile);
                setSelectedFile(null)
              }}
              disabled={selectedFile ? false : true}
              loading={loading}
            >
              Import
            </Button>
          </Col>
        </StyledRow>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default ImportModal;
