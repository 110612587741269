import React, { useState } from "react";
import CustomModal from "../../../utils/CustomModal.jsx";
import { Row, Col, Button, message } from "antd";
import styled from "styled-components";
import CustomAlerts from "../../../utils/CustomAlerts/index.jsx";
import { deleteWardCategory } from "../../../../apis/wardCategory.js";

const ModalBodyContainer = styled.div`
  width: 500px;
`;
const ConfirmationModal = ({
  visible,
  setVisible,
  selectedItem,
  setSelectedItem,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const onConfirmHandler = () => {
    setLoading(true);
    deleteWardCategory(selectedItem.id)
      .then(({ data, status }) => {
        if (status === 200) {
          // if (data.status == 1) {
           
          // } else {
          //   message.error("Unable to delete!");
          // }
          message.success("Deleted!");
          refetch();
          setVisible(false);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <CustomModal visible={visible} antdMOdalProps={{ centered: true }}>
      <ModalBodyContainer>
        <Row justify="center">
          <Col span={24}>
            <CustomAlerts
              type="delete"
              title="Delete Ward Category ?"
              description={`This action will permanently delete the ward category ${
                selectedItem ? selectedItem.categoryName : ""
              }.`}
            />
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Button
              onClick={() => {
                setVisible(false);
              }}
              danger
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                onConfirmHandler();
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalBodyContainer>
    </CustomModal>
  );
};
export default ConfirmationModal;
