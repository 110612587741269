import { Row, Col, Empty, Button } from 'antd';
import Icon from '@ant-design/icons';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { addSection } from '../../../../redux/actions/section';
import styled from 'styled-components';
import { fetchMyMedicalHistory } from '../../../../apis/Appointment';
import { fetchMedicalDocument } from '../../../../apis/patients';
import NoRecords from '../../../utils/NoRecord';
import Spinner from '../../../utils/Spinner';
import CustomeTableForRquests from '../Laboratory/customTable';
import { medicalHistoryColumns, uploadedDocumentsColumns } from './columns';
import { previousMedicalHistoryColumns } from './previousMedicalHisoryColoumns';
import FileViewModal from '../Radiology/fileViewModal';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { PROFILE_IMAGE_PREFIX } from '../../../../constants/StringConstants';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { fetchMenuSections } from '../../../../apis/healthRecord';
import { UserProfileHead } from '../../Appoinment/userProfileHead';
const StyledH3 = styled.h2`
  color: ${(props) => props.color};
  font-weight: 600;
  margin-left: 20px;
  cursor: pointer;
`;

const StyledCol = styled(Col)`
  background: #fff;
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
`;

const EmptyMessageContainer = styled.div`
  height: calc(100vh - 450px);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
  @media (max-width: 480px) {
    height: calc(100vh - 420px);
  } ;
`;
const StyledButton = styled(Button)`
  && {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
    color: rgba(0, 0, 0, 0.4) !important;
    box-shadow: none;
    font-weight: 500 !important;
    font-size: 13px !important;
    padding: 4px 10px !important;
    width: 100%;
  }
  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: ${(props) => props.color} !important;
    color: white !important;
    border: 1px solid ${(props) => props.color} !important;
  }
`;
const StyledIcon = styled(Icon)`
  & .cls-1 {
    fill: rgba(0, 0, 0, 0.25);
  }
`;
const StyledRow = styled(Row)`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;
const PreviousConsultationReports = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { url: healthRecordBaseUrl } = useRouteMatch();
  const { pId: patientId, pName: patientFirstName } = useParams();

  const patient = useSelector((state) => state.patientReducer);
  const theme = useSelector((state) => state.themeReducer);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [loading, setLoading] = useState(false);
  const [defaultHistoryParams, setDefaultHistoryParams] = useState();
  const [defaultDocumentParams, setDefaultDocumentParams] = useState();
  const [historyLoading, setHistoryLoading] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [isHistory, setIsHistory] = useState(true);
  const [consultationHistoryData, setConsultationHistoryData] = useState([]);
  const [uploadedDocumentsData, setUploadedDocumentsData] = useState([]);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [documentsTotal, setDocumentsTotal] = useState(0);
  const [fileViewModal, setFileViewModal] = useState(false);
  const [documents, setDocuments] = useState();
  const [consultationReportSection, setConsultationReportSection] = useState();

  const getConsultationHistory = (queryString) => {
    fetchMyMedicalHistory(queryString)
      .then((res) => {
        if (res.status == 200) {
          console.log('response sss is', res.data.body);
          setConsultationHistoryData(res.data.body);
          setHistoryTotal(res.data.total);
        }
        setLoading(false);
        setHistoryLoading(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        setLoading(false);
        setHistoryLoading(false);
      });
  };

  const getUploadedDocuments = (queryString) => {
    fetchMedicalDocument(queryString)
      .then((res) => {
        if (res.status == 200) {
          console.log('res:', res.data.body);
          setUploadedDocumentsData(res.data.body);
          setDocumentsTotal(res.data.total);
        }
        setLoading(false);
        setDocumentsLoading(false);
      })
      .catch((err) => {
        console.log('hihdhewihfweio');
        console.log(err);
        Sentry.captureException(err);
        setLoading(false);
        setDocumentsLoading(false);
      });
  };

  const consultationViewMore = (count) => {
    // let myMedicalHistoryQuery = `pageNo=${0}&size=${count}&patientId=${
    //   patient.pId
    // }`;
    if (isHistory)
      getConsultationHistory(
        `pageNo=${0}&size=${count}&patientId=${patientId}`
      );
    else getUploadedDocuments(`pageNo=${0}&size=${count}&userId=${patientId}`);
  };

  const downloadFile = (e) => {
    var zip = new JSZip();
    var i = 0;
    if (e.documents) {
      e.historyDocuments = e.documents;
    }
    e.historyDocuments.forEach(function (file) {
      var url = PROFILE_IMAGE_PREFIX + file.documentUrl;
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          console.log(err);
          Sentry.captureException(err);
        }
        zip.file(`(${i})` + file.fileName, data, { binary: true });
        i++;
        if (i == e.historyDocuments.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(
              content,
              `${e['medicalDocumentType']}-${moment(
                e['consultationDate']
              ).format('DD-MM-YYYY')}`
            );
          });
        }
      });
    });
  };

  const fetchMenuItems = () => {
    fetchMenuSections()
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status === 1) {
            setConsultationReportSection(
              data.body.filter(
                (item) => item.formName == 'Consultation Report'
              )[0]
            );
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    setHistoryLoading(true);
    setDocumentsLoading(true);
    setDefaultHistoryParams(`pageNo=${pageNo}&size=10&patientId=${patientId}`);
    setDefaultDocumentParams(`pageNo=${pageNo}&size=10&userId=${patientId}`);
    fetchMenuItems();
  }, []);
  console.log('history param', defaultHistoryParams);
  console.log('history param', consultationHistoryData);

  useEffect(() => {
    if (defaultHistoryParams) {
      getConsultationHistory(defaultHistoryParams);
      // getUploadedDocuments(defaultDocumentParams);
    }
  }, [defaultHistoryParams]);

  return !loading ? (
    <Row>
      <Col span={24}>
        <StyledRow justify="center">
          <UserProfileHead
            // user={user}
            patientId={patientId}
            patientName={patientFirstName}
          />
        </StyledRow>
      </Col>
      <StyledCol span={24}>
        <Row>
          <Col
            onClick={() => {
              setIsHistory(true);
              setHistoryLoading(true);
              if (defaultHistoryParams)
                getConsultationHistory(defaultHistoryParams);
            }}
          >
            <StyledH3
              color={isHistory ? theme['@primary-color'] : 'rgba(0,0,0,0.25)'}
            >
              History
            </StyledH3>
          </Col>
          <Col
            onClick={() => {
              setIsHistory(false);
              setDocumentsLoading(true);
              if (defaultDocumentParams)
                getUploadedDocuments(defaultDocumentParams);
            }}
          >
            <StyledH3
              color={!isHistory ? theme['@primary-color'] : 'rgba(0,0,0,0.25)'}
            >
              Uploaded Documents
            </StyledH3>
          </Col>
        </Row>
        <>
          {isHistory ? (
            <CustomeTableForRquests
              // title={"History"}
              tableTitle={'Tests'}
              columns={previousMedicalHistoryColumns(
                pageNo,
                pageSize,
                StyledButton,
                theme,
                patient,
                patientId,
                patientFirstName,
                history,
                dispatch,
                addSection,
                consultationReportSection,
                healthRecordBaseUrl
              )}
              isLoading={historyLoading}
              dataSource={consultationHistoryData}
              getPreviousTabledata={consultationViewMore}
              isViewMore={true}
              pageSize={historyTotal}
            />
          ) : (
            <CustomeTableForRquests
              // title={"Uploaded Documents"}
              tableTitle={'Documents'}
              columns={uploadedDocumentsColumns(
                pageNo,
                pageSize,
                theme,
                setFileViewModal,
                setDocuments,
                downloadFile
              )}
              isLoading={documentsLoading}
              dataSource={uploadedDocumentsData}
              getPreviousTabledata={consultationViewMore}
              isViewMore={true}
              pageSize={documentsTotal}
            />
          )}
        </>
      </StyledCol>
      <FileViewModal
        closable={false}
        visible={fileViewModal}
        setVisible={setFileViewModal}
        isClosable={true}
        historyDate={true}
        data={documents}
        patientId={patientId}
        patientName={patientFirstName}
        downloadFile={downloadFile}
      />
    </Row>
  ) : (
    <Spinner />
  );
};
export default PreviousConsultationReports;
