import React, { useCallback } from 'react';
import { Row, Col, Input, Button, message } from 'antd';
import styled from 'styled-components';
import {
  SearchOutlined,
  PrinterOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
import { viewConsentForms } from '../../../../apis/ConsentForms';
import { useEffect } from 'react';
import Spinner from '../../../utils/Spinner';
import { useDebounce } from './helper';
import samplePDF from './asd.pdf';
import printJS from 'print-js';
import { PROFILE_IMAGE_PREFIX } from '../../../../constants/StringConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledRowContainer = styled.div`
  background: #fff;
  min-height: calc(100vh - 200px);
  /* padding: 40px 20px; */
`;

const forms = [
  'Brain Surgery',
  'Psychic Analysis',
  'Bone Marrow Test',
  'Stem Cell Test',
];
const StyledSearch = styled(Input)`
  /* && {
    border-radius: 10px;
    background: #f2f2f2;
  } */
  & .ant-input-group-addon {
    border-radius: 6px 0px 0px 6px;
    background: #f2f2f2;
    padding-left: 31px;
    color: #aeaeae;
    font-size: 20px;
  }

  & .ant-input {
    border-radius: 0px 6px 6px 0px;
    background: #f2f2f2;
    border-left: 0;
  }
  & .ant-input.ant-input-lg {
    border-left: 0 !important;
    border: 1px solid #dedede;
  }
  & .ant-input.ant-input-lg:hover {
    border-radius: 0px 6px 6px 0px;
    background: #f2f2f2;
    border-left: 0;
    border: 1px solid #dedede;
  }

  & .ant-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

const FormButton = styled(Button)`
  width: 200px;
  margin-left: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
`;
const SearchContainer = styled.div`
  margin-left: 30px;
  /* margin-bottom: 30px; */
  margin-top: 30px;
  margin-right: 30px;
`;

const PdfContainer = styled.div`
  background: #f2f2f2;
  padding: 30px;
  max-height: 600px;
  overflow-y: scroll;
  margin: 30px;

  & .react-pdf__Page__canvas {
    margin: auto;
    box-shadow: 5px 10px 8px #e9e9e9;
  }

  & .react-pdf__Page {
    margin-bottom: 30px;
  }
`;
const ConcentForm = () => {
  const [searchParam, setSearchParam] = useState('');
  const [formList, setFormList] = useState([]);
  const [selectedForm, setSelectedForm] = useState();
  const [loading, setLoading] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [printLoading, setPrintLoading] = useState(false);
  const [file, selectedFile] = useState();
  const history = useHistory();
  const getFormList = () => {
    setLoading(true);
    setShowPDF(false);
    viewConsentForms(searchParam)
      .then(({ data, status }) => {
        console.log('consentform', data);

        if (status == 200) {
          if (data.status === 1) {
            setFormList(data.body);
            setLoading(false);
          } else {
            setFormList([]);
          }
        }
      })
      .catch(console.log);
    setLoading(false);
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(numPages);
  };
  const fetchPdf = (formUrl) => {
    setPrintLoading(true);
    fetch(PROFILE_IMAGE_PREFIX + formUrl)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        selectedFile(blob);
        setPrintLoading(false);
      })
      .catch();
  };
  const onChangeHandler = (value) => {
    setSearchParam(value);
  };
  const debounceOnChange = useCallback(useDebounce(onChangeHandler, 1000), []);

  useEffect(() => {
    getFormList();
  }, [searchParam]);

  return (
    <StyledRowContainer>
      <Row>
        <Col span={24}>
          <Row gutter={[36, 36]} justify="space-between">
            <Col span={8}>
              <SearchContainer>
                <StyledSearch
                  addonBefore={<SearchOutlined />}
                  size="large"
                  placeholder="Search"
                  onChange={(e) => {
                    debounceOnChange(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') getFormList();
                  }}
                />
              </SearchContainer>
            </Col>
            <Col>
              <SearchContainer>
                {showPDF && (
                  <>
                    <Button
                      type="primary"
                      ghost
                      icon={<PrinterOutlined />}
                      onClick={() => {
                        // setPrintLoading(true);
                        printJS(window.URL.createObjectURL(file));
                      }}
                      // loading={printLoading}
                      style={{ marginRight: '5px' }}
                    >
                      Print
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      icon={<CloseOutlined />}
                      onClick={() => {
                        setShowPDF(false);
                      }}
                      // loading={printLoading}
                    >
                      Close
                    </Button>
                  </>
                )}
              </SearchContainer>
            </Col>
          </Row>
        </Col>
        {loading ? (
          <Col span={24}>
            <Spinner height="300px" />
          </Col>
        ) : (
          !showPDF && (
            <Col span={24}>
              {formList.map((item) => (
                <FormButton
                  size="large"
                  onClick={() => {
                    setSelectedForm(item);
                    setShowPDF(true);
                    fetchPdf(item.formUrl);
                  }}
                >
                  {item.name}
                </FormButton>
              ))}
            </Col>
          )
        )}
        {showPDF && (
          <Col span={24}>
            <PdfContainer>
              {printLoading ? (
                <Spinner />
              ) : (
                <Document
                  file={file ? window.URL.createObjectURL(file) : ''}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(Array(pageCount).keys()).map((page) => (
                    <Page pageNumber={page + 1} />
                  ))}
                </Document>
              )}
            </PdfContainer>
          </Col>
        )}
      </Row>
    </StyledRowContainer>
  );
};

export default ConcentForm;
