import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, Radio, Button, message } from "antd";
import CustomModal from "../../../utils/CustomModal.jsx/index.jsx";
import {
  ModalBodyContainer,
  StyledTitle,
  StyledForm,
  StyledIcon,
} from "./styledComponents";

import { addNewSubSection } from "../../../../apis/healthRecord.js";
import { useSelector } from "react-redux";

const ModalBody = ({
  setVisible,
  selectedSection,
  refetch,
  totalForms,
  selectedForm,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [theme, images] = useSelector((state) => [
    state.themeReducer,
    state.imageReducer,
  ]);

  const addNew = (data) => {
    setLoading(true);
    addNewSubSection(data)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data.status == 1) {
            message.success(
              selectedForm ? "Form Created Successfully" : "Form Updated!"
            );
            setVisible(false);
            refetch();
          } else {
            message.error(data.body);
          }
          //   setSelectedItem(undefined);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onFinish = (values) => {
    values.editable = true;
    if (!values.order) values.order = totalForms + 1;
    if (selectedSection) {
      values.sectionId = selectedSection.id;
    }
    if (selectedForm) {
      values.subSectionId = selectedForm.id;
    }
    addNew(values);
  };

  useEffect(() => {
    if (selectedForm)
      form.setFieldsValue({ ...selectedForm, name: selectedForm.formName });
  }, []);
  return (
    <ModalBodyContainer>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <StyledTitle level={3} color={theme["@primary-color"]}>
            {!selectedForm ? "Create New Form" : "Update Form"}
          </StyledTitle>
        </Col>
        <Col span={24}>
          <StyledForm
            name="admin-login-form"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            scrollToFirstError={true}
            layout="vertical"
            size="large"
            wrapperCol={{ gutter: 36 }}
            form={form}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Form name is Required !",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item>
              <Row gutter={12} justify="end">
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    ghost
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    htmlType="submit"
                  >
                    {!selectedForm ? "Create" : "Update"}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </StyledForm>
        </Col>
      </Row>
    </ModalBodyContainer>
  );
};

const NewFormModal = ({
  visible,
  setVisible,
  selectedSection,
  totalForms,
  selectedForm,
  refetch,
}) => {
  return (
    <CustomModal
      visible={visible}
      antdMOdalProps={{ centered: true, destroyOnClose: true }}
    >
      <ModalBody
        setVisible={setVisible}
        selectedSection={selectedSection}
        totalForms={totalForms}
        refetch={refetch}
        selectedForm={selectedForm}
      />
    </CustomModal>
  );
};
export default NewFormModal;
