import React from "react";
import { Modal, Radio, Row, Col } from "antd";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";

const StyledModal = styled(Modal)`
  width: 920px !important;
  &.time_select_popup .ant-col .ant-radio-button-wrapper {
    border-radius: 4px !important;
  }
  &.time_select_popup .ant-col .ant-radio-button-wrapper:hover {
    border-color: #4fc4ff;
  }
  @media (max-width: 991px) {
    width: 100% !important;
    padding: 0px 15px;
  }
  @media (max-width: 767px) {
    &.time_select_popup {
      max-width: 100%;
      margin: 0;
      padding-bottom: 60px;
    }
  }
  @media (max-width: 640px) {
    &.time_select_popup .ant-col .ant-radio-button-wrapper {
      height: auto;
      padding: 6px 5px;
      font-size: 12px;
      line-height: normal;
      letter-spacing: -0.04em;
    }
    &.time_select_popup .ant-modal-body,
    &.time_select_popup .ant-modal-header {
      padding: 16px 14px;
    }
  }
  @media (max-width: 480px) {
    &.time_select_popup .ant-col {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const StyledRadioButton = styled(Radio.Button)`
  width: 100%;
  text-align: center;
`;

const StyledH3 = styled.h3`
  color: ${(props) => props.color};
  font-weight: 600;
`;

function SelectTimeModal({
  modalVisible,
  handleOk,
  handleCancel,
  availableSlots,
  changeTime,
}) {
  const onChange = (e) => {
    changeTime(e.target.value);
  };

  const theme = useSelector((state) => state.themeReducer);

  return (
    <StyledModal
      className="time_select_popup"
      title="Date"
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <StyledH3 color={theme["@primary-color"]}>Available Time</StyledH3>
      <StyledRadioGroup onChange={onChange} defaultValue="a">
        <Row gutter={[16, 10]}>
          {availableSlots &&
            availableSlots.map((slot, index) => {
              return (
                <Col span="8">
                  <StyledRadioButton value={index} disabled={slot.booked}>
                    {moment(slot.startTime).format("hh:mm A")} -{" "}
                    {moment(slot.endTime).format("hh:mm A")}
                  </StyledRadioButton>
                </Col>
              );
            })}
        </Row>
      </StyledRadioGroup>
    </StyledModal>
  );
}

export default SelectTimeModal;
