import axiosInstance from '../config/axiosInstance';
import Moment from 'moment-timezone';

const HEALTH_RECORD_PREFIX = '/health-record';
const SAMPLE_FORM_PREFIX = '/sample-form';

//old
// export const fetchMenuSections = () => {
//   return axiosInstance.get(
//     HEALTH_RECORD_PREFIX + "/sections?formType=HEALTH_RECORD"
//   );
// };

//testing
export const fetchMenuSections = () => {
  return axiosInstance.get(SAMPLE_FORM_PREFIX + '/sections');
};
export const fetchObervationForms = () => {
  return axiosInstance.get(SAMPLE_FORM_PREFIX + '/observation-form');
};
export const addObervationForms = (data) => {
  return axiosInstance.post(SAMPLE_FORM_PREFIX + '/observation-form', data);
};
export const fetchSubSections = (id) => {
  return axiosInstance.get(`${SAMPLE_FORM_PREFIX}/sub-sections/${id}`);
};

export const fetchSubSectionFields = (id) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/section/subsection/${id}/fields`
  );
};
export const changeSectionOrder = (data) => {
  return axiosInstance.put(`${SAMPLE_FORM_PREFIX}/change-order`, data);
};
export const addNewSection = (data) => {
  return axiosInstance.post(SAMPLE_FORM_PREFIX + '/section', data);
};
export const addNewSubSection = (data) => {
  return axiosInstance.post(SAMPLE_FORM_PREFIX + '/section/subsection', data);
};
export const addNewFormfield = (data) => {
  return axiosInstance.post(
    SAMPLE_FORM_PREFIX + '/section/subsection/field',
    data
  );
};
export const deleteField = (id) => {
  return axiosInstance.delete(
    `${SAMPLE_FORM_PREFIX}/section/subsection/field/${id}`
  );
};
export const deleteSection = (id) => {
  return axiosInstance.delete(`${SAMPLE_FORM_PREFIX}/section?sectionId=${id}`);
};
export const getCodes = (codeType, pageNo, searchParam) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/coded-dropdowns?code_type=${codeType}&pageNo=${pageNo}&search_string=${searchParam}`
  );
};
export const deleteForm = (id) => {
  return axiosInstance.delete(
    `${SAMPLE_FORM_PREFIX}/section/subsection?subSectionId=${id}`
  );
};
export const deleteObservation = (appointmentId, sectionId, subSectionId) => {
  return axiosInstance.delete(
    `${SAMPLE_FORM_PREFIX}/delete-observation-data?appointmentId=${appointmentId}&observationSectionId=${sectionId}&observationSubSectionId=${subSectionId}`
  );
};
export const fetchConsulationReportItems = (sectionId) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/consultation-report?sectionId=${sectionId}`
  );
};

//old
// export const getHealthRecordForms = (sectionId, userId) => {
//   return axiosInstance.get(
//     `${HEALTH_RECORD_PREFIX}?sectionId=${sectionId}&userId=${userId}`
//   );
// };

export const getHealthRecordForms = (sectionId, searchParam) => {
  console.log('section id', sectionId);
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/sub-sections/fields?section=${sectionId}&formName=${
      searchParam ? searchParam : ''
    }`
  );
};

// export const getPreviousRecords = (sectionId, userId, type) => {
//   return axiosInstance.get(
//     `${HEALTH_RECORD_PREFIX}/table-data?sectionId=${sectionId}&userId=${userId}&type=${type}`
//   );
// };
export const getPreviousRecords = (
  sectionId,
  userId,
  appointmentId,
  size,
  pageNo,
  consultationReport
) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/table-data?section=${sectionId}&user=${userId}&appointment=${appointmentId}${
      size ? `&size=${size}&pageNo=${pageNo}` : ``
    }${
      consultationReport ? `&forConsultationReport=${consultationReport}` : ``
    }`
  );
};

export const getSectionData = (sectionId, userId, appointmentId) => {
  return axiosInstance.get(
    `${SAMPLE_FORM_PREFIX}/section-data?sectionId=${sectionId}&userId=${userId}&appointmentId=${appointmentId}`
  );
};
// export const addHealthRecord = (sectionId, patientId, dynamicFormData) => {
//   return axiosInstance.post(HEALTH_RECORD_PREFIX, {
//     patientId: patientId,
//     sectionId: sectionId,
//     dynamicFormData: dynamicFormData,
//   });
// };
export const addHealthRecord = (
  sectionId,
  patientId,
  dynamicFormData,
  appointmentId,
  emergencyPrescription
) => {
  return axiosInstance.post(`${SAMPLE_FORM_PREFIX}/save-data`, {
    appointmentId: appointmentId,
    patientId: patientId,
    sectionId: sectionId,
    dynamicFormData: dynamicFormData,
    emergencyPrescription: emergencyPrescription ? true : false,
    timeZone: Moment.tz.guess(),
  });
};

export const getConcentForm = (name) => {
  return axiosInstance.get(`${HEALTH_RECORD_PREFIX}/consent-form?name=${name}`);
};

export const getChiefComplaints = (name) => {
  return axiosInstance.get(`/chief-complaints${name ? '?name=' + name : ''}`);
};
