import React from "react";
import { useSelector } from "react-redux";
import {
  EmptyMessageContainer,
} from "../Sections/styledComponents";
import { tableColumns } from "./tableColumns";
import { Button, Empty } from "antd";
// import { BlueBgTable } from "./styledComponents";
import { BlueBgTable } from "../styledComponents";
const DictionaryTable = ({
  dataSource,
  pageSize,
  setPageNo,
  total,
  setEditModal,
  setDeleteModal,
  setSelectedItem,
  pageNo,
  loading,
}) => {
  const theme = useSelector((state) => state.themeReducer);

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <Button>Previous</Button>;
    }
    if (type === "next") {
      return <Button>Next</Button>;
    }
    return originalElement;
  };

  return dataSource.length > 0 ? (
    <BlueBgTable
      loading={loading}
      columns={tableColumns(setEditModal, setDeleteModal, setSelectedItem, pageNo)}
      dataSource={dataSource}
      // headBg={theme["@primary-color"]}
      pagination={{
        onChange: (cur) => {
          setPageNo(cur - 1);
          console.log(cur);
        },
        pageSize: pageSize,
        showSizeChanger: false,
        total: total,
        current: pageNo + 1,
        itemRender: itemRender,
      }}
    />
  ) : (
    <EmptyMessageContainer>
      <Empty />
    </EmptyMessageContainer>
  );
};

export default DictionaryTable;
